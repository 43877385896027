import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import { TPI18N } from "@/services/I18nService";
import { TaskTypeService } from "@/services/TaskTypeService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<TaskTypeViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

interface TaskTypeAdminInterface {
  callBackCommands: Function;
}

const TaskTypeAdmin = React.forwardRef(
  ({ callBackCommands }: TaskTypeAdminInterface, ref) => {
    //Functions called form parent TaskTypeAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    const componentFileName: string = "TaskTypeAdmin.tsx";
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);

    //Screen resources
    const ResourceSet: string = "TaskTypeAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");

    //Grid Columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [nameColumnLabel, setNameColumnLabel] = useState("");
    const [componentNameColumnLabel, setComponentNameColumnLabel] =
      useState("");
    const [parameterColumnLabel, setParameterColumnLabel] = useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    const [isDecsionTaskColumnLabel, setIsDecisionTaskColumnLabel] =
      useState("");
    const [expiresColumnLabel, setExpiresColumnLabel] = useState("");
    const [isAddableColumnLabel, setIsAddableColumnLabel] = useState("");
    const [isCommentRequiredLabel, setIsCommentRequiredLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemRecordColumnLabel] =
      useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
      columnsAreLoaded: false,
    };

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    //const [adminState, setAdminState] = useState(initialStateBLL);

    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndTaskTypeFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(ResourceSet, "FilterIsActiveLabel"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(ResourceSet, "Id"));
      setNameColumnLabel(await TPI18N.GetText(ResourceSet, "Name"));
      setComponentNameColumnLabel(
        await TPI18N.GetText(ResourceSet, "ComponentName"),
      );
      setParameterColumnLabel(await TPI18N.GetText(ResourceSet, "Parameter"));
      setIsActiveColumnLabel(await TPI18N.GetText(ResourceSet, "IsActive"));
      setIsDecisionTaskColumnLabel(
        await TPI18N.GetText(ResourceSet, "IsDecision"),
      );
      setExpiresColumnLabel(await TPI18N.GetText(ResourceSet, "Escalates"));
      setIsAddableColumnLabel(await TPI18N.GetText(ResourceSet, "IsAddable"));
      setIsCommentRequiredLabel(
        await TPI18N.GetText(ResourceSet, "IsCommentRequired"),
      );
      setIsSystemRecordColumnLabel(
        await TPI18N.GetText(ResourceSet, "IsSystemRecord"),
      );
      let newFilterKeyValue: Array<TPKeyValue> = [];

      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(ResourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(ResourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };
    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];

        //delete
        newColumns.push({
          name: "",
          width: "50px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            if (!row["isSystemRecord"]) {
              return (
                <div className="dropdown">
                  <TPButton
                    dataBsToggle={true}
                    type={TPButtonTypes.empty}
                    onClick={() => {
                      TPGlobal.foo();
                    }}
                    className={"menu-button"}
                  >
                    <TPIcon iconType={TPIconTypes.moreVert} />
                  </TPButton>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        onClick={(id: any) => handleDeleteClick(row["id"])}
                        className="dropdown-item"
                        href="#"
                      >
                        {deleteLabel}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={(id: any) => handleUpdateClick(row["id"])}
                        className="dropdown-item"
                        href="#"
                      >
                        {updateLabel}
                      </a>
                    </li>
                  </ul>
                </div>
              );
            } else {
              return null;
            }
          },
        });
        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) => {
            if (!row["isSystemRecord"]) {
              return (
                <TPButton
                  type={TPButtonTypes.primary}
                  onClick={(id: string) => handleUpdateClick(row["id"])}
                  className="update-button"
                >
                  <TPIcon iconType={TPIconTypes.chevronRight} />
                </TPButton>
              );
            } else {
              return null;
            }
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });
        //id
        newColumns.push({
          name: idColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["id"];
          },
          selector: (row: { [x: string]: any }) => row["id"],
          width: "150px",
          sortable: true,
        });
        //name primary language
        let languageColumnLabel: string;
        languageColumnLabel = nameColumnLabel;
        languageColumnLabel = (languageColumnLabel +
          " (" +
          TPGlobal.TPClientAvailableLanguages[0].name) as string;
        languageColumnLabel = languageColumnLabel + ")";
        newColumns.push({
          name: languageColumnLabel,
          selector: (row: { [x: string]: any }) => row["description"],
          sortable: true,
          width: "250px",
        });
        //name current language if it is different
        if (TPGlobal.TPClientAvailableLanguages[0].id !== TPGlobal.language) {
          languageColumnLabel = nameColumnLabel;
          languageColumnLabel = (languageColumnLabel +
            " (" +
            TPGlobal.getLanguageDescriptionByCode(TPGlobal.language)) as string;
          languageColumnLabel = languageColumnLabel + ")";
          newColumns.push({
            name: languageColumnLabel,
            selector: (row: { [x: string]: any }) =>
              row["localizedDescription"],
            sortable: true,
          });
        }
        //localizedComponent
        newColumns.push({
          name: componentNameColumnLabel,
          selector: (row: { [x: string]: any }) => row["localizedComponent"],
          sortable: true,
          width: "250px",
        });

        //Parameter 1
        newColumns.push({
          name: parameterColumnLabel,
          cell: (row: { [x: string]: any }) => {
            const requiredParameters = JSON.parse(row["jsonParameters"]);
            return row["localizedComponent"] === "Decision Type - Automatic" ? (
              <div>
                {requiredParameters?.map((rule: any, index: any) => (
                  <div key={index}>
                    <div>
                      Rule Number: {rule.RuleNumber}
                      Base Field: {rule.BaseField}
                      Comparison Operator: {rule.ComparisonOperator}
                      Reference Value: {rule.ReferenceValue}
                      {/* Connector: {rule.Connector} */}
                    </div>
                  </div>
                ))}
              </div>
            ) : null;
          },
          selector: (row: { [x: string]: any }) => row["id"],
          width: "250px",
          sortable: true,
        });

        //isActive
        newColumns.push({
          name: isActiveColumnLabel,
          selector: (row: { [x: string]: any }) => row["isActive"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isActive"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        //isDecision,escalates,isAddable,isCommentRequired,

        //isDecision
        newColumns.push({
          name: isDecsionTaskColumnLabel,
          selector: (row: { [x: string]: any }) => row["isDecision"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isDecision"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        //escalates
        newColumns.push({
          name: expiresColumnLabel,
          selector: (row: { [x: string]: any }) => row["escalates"],
          width: "180px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["escalates"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        //isAddable
        newColumns.push({
          name: isAddableColumnLabel,
          selector: (row: { [x: string]: any }) => row["isAddable"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isAddable"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        //isCommentRequired
        newColumns.push({
          name: isCommentRequiredLabel,
          selector: (row: { [x: string]: any }) => row["isCommentRequired"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isCommentRequired"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        //IsSystemRecord
        newColumns.push({
          name: isSystemRecordColumnLabel,
          selector: (row: { [x: string]: any }) => row["isSystemRecord"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isSystemRecord"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    //Get task type by Filter
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new TaskTypeService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getTaskTypeByFilter(
          selectedFilter,
          false,
          true,
          expectedCodes,
        );
        let newAdminState: AdminStateType = {
          ...adminState,
        };
        newAdminState.gridData = [...responseRequest];
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
      }
    };
    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };
    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Modal Question to delete
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete function after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new TaskTypeService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteTaskTypeById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

	//Filtered data based on selected pattern on search box
	const filteredData = () => {
		let searcheableColumns: Array<string> = ['id', 'localizedDescription'];
		let i: number;
		let search: string;
		search = adminState.searchPattern.trim();
		return adminState.gridData.filter(function (item, index) {
			if (search == '' || search.length <= 2) {
				return item;
			}
			for (i = 0; i <= searcheableColumns.length - 1; i++) {
				let itemany: any;
				itemany = item;
				if (
					itemany[searcheableColumns[i]] &&
					itemany[searcheableColumns[i]].toString().toLowerCase().includes(search.toLowerCase())
				) {
					return item;
				}
			}
		});
	};

    //Modified code
    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndTaskTypeFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndTaskTypeFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndTaskTypeFilter ex`,
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };
    return (
      <>
        <TPModalQuestion
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <TPPageFirstRow>
                <TPPageActions>
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewClick()}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() =>
                      exportToCSV(filteredData(), "task-admin-data")
                    }
                    text={exportLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                </TPPageActions>
                <TPFilterAndSearch>
                  <TPPageFilterContainer>
                    <TPSelect
                      id="IdSelect"
                      onChange={handleFilterChange}
                      dataSource={filterKeyValue}
                      value={adminState.selectedFilter}
                      labelText={filterIsActiveLabel}
                      isHorizontal={true}
                    ></TPSelect>
                  </TPPageFilterContainer>
                  <TPPageSearchContainer>
                    <TPTextBox
                      id="IdTextBox"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>

          <div className="row">
            <div className="col">
              {/* TableContainer,DataTableContainer,TableHeaderContainer,TableSearchContainer,TableToolbar */}
              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);

export default TaskTypeAdmin;
