import React, { CSSProperties, useEffect } from "react";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import {
  FormDesignerExportImportFormSlice,
  FormDesignerExportImportFormStateModel,
} from "./_redux/FormDesignerExportImportFormSlice";
import e from "./models/FormDesignerExportImportFormEnum";
import HotTable from "@handsontable/react";
import { ColumnModel } from "./FormDesignerExportImportForm";
import TPGlobal from "@/helpers/TPGlobal";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";

interface FormDesignerExportImportFormTableProps {
  datasource: any;
  data: Array<Array<any>>;
  setData: (value: Array<Array<any>>) => void;
  columns: Array<Array<any>>;
  setColumns: (value: Array<Array<ColumnModel>>) => void;
  languages: Array<LanguagesViewModel>;
}

export default function FormDesignerExportImportFormTable({
  datasource,
  data,
  setData,
  columns,
  setColumns,
  languages,
}: FormDesignerExportImportFormTableProps) {
  const { message: m } = useSelector(
    (s: StoreModel) => s[FormDesignerExportImportFormSlice.name],
  ) as FormDesignerExportImportFormStateModel;

  useEffect(() => {}, []);

  useEffect(() => {
    const coll = languages.map(({ id, name: label }) => ({
      id,
      label,
      colspan: 1,
    }));
    const cols = [
      [
        {
          id: "descriptions",
          label: `${m?.[e.TableColumnDescriptionsLabel]} *`,
          colspan: coll?.length,
        },
        {
          id: "formTypeId",
          label: `${m?.[e.TableColumnFormTypeIdLabel]} *`,
          colspan: 1,
        },
        {
          id: "jsonDesignerUT8",
          label: m?.[e.TableColumnJsonDesignerUT8Label],
          colspan: 1,
        },
        {
          id: "applyPersonalData",
          label: m?.[e.TableColumnApplyPersonalDataLabel],
          colspan: 1,
        },
        {
          id: "isActive",
          label: m?.[e.TableColumnIsActiveLabel],
          colspan: 1,
        },
      ],
      [
        ...coll,
        { label: "", colspan: 1 },
        { label: "", colspan: 1 },
        { label: "", colspan: 1 },
        { label: "", colspan: 1 },
      ],
    ];

    if (datasource) {
      let val: any = getJson(datasource.jsonDesignerUtf8);
      if (val) {
        val = JSON.stringify(val);
      }

      const dat = [
        [
          ...coll.map(
            ({ id }) =>
              datasource?.descriptions?.find((d: any) => d.languageId === id)
                ?.localizedValue ?? "",
          ),
          datasource.formTypeId,
          !datasource?.jsonDesignerUtf8 ? datasource?.jsonDesignerUtf8 : val,
          `${datasource.applyPersonalData}`,
          `${datasource.isActive}`,
        ],
      ];
      setData(dat);
    } else {
      setData([[...coll.map(() => ""), "", "", "", ""]]);
    }
    setColumns(cols);
  }, [datasource]);

  function getJson(
    encodeString: string,
  ): { [attribure: string]: string } | undefined {
    const result = TPGlobal.uint8ArrayToString(encodeString);

    if (result === undefined) {
      return;
    }

    try {
      return JSON.parse(result);
    } catch (error) {
      console.error(`The value ${result} could not be converted to json`);
      console.error(error);
      return getJson(result);
    }
  }

  return (
    <div style={styles.container}>
      <HotTable
        className="htCenter"
        colWidths={200}
        data={data}
        nestedHeaders={columns}
        contextMenu={["copy", "cut"]}
        licenseKey="non-commercial-and-evaluation"
        manualColumnResize
        autoWrapRow
        autoWrapCol
        selectionMode="multiple"
        stretchH="last"
        rowHeaders
        colHeaders
      />
    </div>
  );
}

const styles = {
  container: {
    width: "100%",
    minHeight: "300px",
    maxHeight: "500px",
    height: "max-content",
    overflowX: "auto",
    overflowY: "hidden",
    marginBottom: "20px",
  } as CSSProperties,
};
