import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPGlobal from "@/helpers/TPGlobal";
import { SchedulesService } from "@/services/SchedulesService";
import { FC, ReactElement, useEffect, useState } from "react";

import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";

import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPBranchSelection2, {
  TPBranchSelectionValue,
} from "@/components/TPBranchSelection/TPBranchSelection2";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPLanguageFilterEnum,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import {
  UserInputDTO,
  UserInputDTOValidator,
  ValidationUserGroupModeEnum,
} from "@/models/Users/UserInputDTO";
import { UserViewModel } from "@/models/Users/UserModels";
import { BranchService } from "@/services/BranchService";
import { CalendarsService } from "@/services/CalendarsService";
import { TPI18N } from "@/services/I18nService";
import LanguageService from "@/services/LanguageService";
import { ProfileService } from "@/services/ProfileService";
import { TimeZonesService } from "@/services/TimeZonesService";
import { UserService } from "@/services/UserService";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  login: string;
  firstName: string;
  lastName: string;
  initials: string;
  profileId: string;
  superiorObj: Array<TPKeyValue>;
  email: string;
  currentDescriptionTeamId: string;
  currentHierarchyDescriptionTeamId: string;
  functionId: string;
  phone: string;
  mobile: string;
  typistIdGuid: string;
  calendarId: string;
  timeZoneId: string;
  scheduleId: string;
  authenticationMethodId: string;
  microsoftIdentity: string;
  defaultLanguage: string;
  maxSimultaneousChat: number | null;
  isActive: boolean;
  isSystem: boolean;
  //validator
  idErrorMessage: string;
  areaIdErrorMessage: string;
  authenticationMethodIdErrorMessage: string;
  calendarIdErrorMessage: string;
  defaultLanguageErrorMessage: string;
  emailErrorMessage: string;
  firstNameErrorMessage: string;
  functionIdErrorMessage: string;
  initialsErrorMessage: string;
  lastNameErrorMessage: string;
  loginErrorMessage: string;
  maxSimultaneousChatErrorMessage: string;
  microsoftIdentityErrorMessage: string;
  mobileErrorMessage: string;
  phoneErrorMessage: string;
  profileIdErrorMessage: string;
  scheduleIdErrorMessage: string;
  superiorObjErrorMessage: string;
  teamIdErrorMessage: string;
  timeZoneIdErrorMessage: string;
  typistIdGuidErrorMessage: string;
  isActiveErrorMessage: string;
  [key: string]: any;
};

const UserInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "UserInsertUpdate.tsx";
  const areaTreeJson: any = JSON.parse(TPGlobal.userTreeBranchAreaMapping);
  const teamTreeJson: any = JSON.parse(TPGlobal.userTreeBranchTeamMapping);
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const ResourceSet: string = "UsersInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [usersPersonalInfoSection, setUsersPersonalInfoSection] = useState("");
  const [loginName, setLoginName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initials, setInitials] = useState("");
  const [profile, setProfile] = useState("");
  const [superior, setSuperior] = useState("");
  const [email, setEmail] = useState("");
  const [teams, setTeams] = useState("");
  const [functions, setFunctions] = useState("");
  const [areas, setAreas] = useState("");
  const [phone, setPhone] = useState("");
  const [
    phoneNumberShouldContainOnlyNumbersLabel,
    setPhoneNumberShouldContainOnlyNumbersLabel,
  ] = useState("");
  const [
    mobileNumberShouldContainOnlyNumbersLabel,
    setMobileNumberShouldContainOnlyNumbersLabel,
  ] = useState("");
  const [mobile, setMobile] = useState("");
  const [calendar, setCalendar] = useState("");
  const [timezone, setTimezone] = useState("");
  const [schedule, setSchedule] = useState("");
  const [authenticationMethod, setAuthenticationMethod] = useState("");
  const [microsoftIdentity, setMicrosoftIdentity] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [maximumNumberSimultaneous, setMaximumNumberSimultaneous] =
    useState("");

  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [profilesToSelect, setProfilesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [calendarsToSelect, setCalendarsToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [schedulesToSelect, setSchedulesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [languagesToSelect, setLanguagesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [timezonesToSelect, setSTimeZonesToSelect] = useState<
    Array<TPKeyValue>
  >([]);

  const [functionsToSelect, setFunctionsToSelect] = useState<Array<TPKeyValue>>(
    [],
  );

  const [superiorsToSelect, setSuperiorsToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [authenticationMethodsToSelect, setAuthenticationMethodsToSelect] =
    useState<Array<TPKeyValue>>([]);

  //TPBranchSelect Resources
  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");
  const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
  const [modalTitleLabel, setmodalTitleLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");

  //Teams state
  const initialSelectedTeamBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [selectedTeamBranchListValue, setSelectedTeamBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedTeamBranch);

  //Areas State
  const initialSelectedAreaBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };
  const [selectedAreaBranchListValue, setSelectedAreaBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedAreaBranch);

  //Screen state
  let insertUpdateInitialState: InsertUpdateStateType = {
    //areaId: "",
    authenticationMethodId: "",
    calendarId: "",
    defaultLanguage: "",
    email: "",
    firstName: "",
    functionId: "",
    initials: "",
    lastName: "",
    login: "",
    maxSimultaneousChat: 0,
    microsoftIdentity: "",
    mobile: "",
    phone: "",
    profileId: "",
    scheduleId: "",
    superiorObj: [],
    //teamId: "",
    timeZoneId: "",
    typistIdGuid: TPGlobal.currentUserGuid,
    isActive: true,
    idErrorMessage: "",
    areaIdErrorMessage: "",
    authenticationMethodIdErrorMessage: "",
    calendarIdErrorMessage: "",
    defaultLanguageErrorMessage: "",
    emailErrorMessage: "",
    firstNameErrorMessage: "",
    functionIdErrorMessage: "",
    initialsErrorMessage: "",
    lastNameErrorMessage: "",
    loginErrorMessage: "",
    maxSimultaneousChatErrorMessage: "",
    microsoftIdentityErrorMessage: "",
    mobileErrorMessage: "",
    phoneErrorMessage: "",
    profileIdErrorMessage: "",
    scheduleIdErrorMessage: "",
    superiorObjErrorMessage: "",
    teamIdErrorMessage: "",
    timeZoneIdErrorMessage: "",
    isSystem: false,
    currentDescriptionTeamId: "",
    currentHierarchyDescriptionTeamId: "",
    isActiveErrorMessage: "",
    typistIdGuidErrorMessage: "",
  };
  const [insertUpdateState, setInsertUpdateState] =
    useState<InsertUpdateStateType>(insertUpdateInitialState);

  const [autocompleteSuperiorOptions, setAutocompleteSuperiorOptions] =
    useState<Array<TPKeyValue>>([]);

  //top n Superior options for arrow icon
  const [autocompleteSuperiorTopNOptions, setAutocompleteSuperiorTopNOptions] =
    useState<Array<TPKeyValue>>([]);
  //#endregion

  const getProfiles = async () => {
    const profileClient = new ProfileService();
    const expectedCodes: Array<number> = [200, 404];
    const profiles = await profileClient.getProfilesByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const profilesToSelectConverted: Array<TPKeyValue> = profiles.map((k) => {
      return {
        key: k.id,
        value: k.description,
      };
    });
    profilesToSelectConverted.unshift({ key: "", value: "--" });
    setProfilesToSelect(profilesToSelectConverted);
  };

  const getCalendars = async () => {
    const calendarClient = new CalendarsService();
    const expectedCodes: Array<number> = [200, 404];
    const schedules = await calendarClient.getCalendarsByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const calendarsToSelectConverted: Array<TPKeyValue> = schedules
      .filter((k) => k.isActive)
      .map((k) => {
        return {
          key: k.id,
          value: k.description,
        };
      });
    calendarsToSelectConverted.unshift({ key: "", value: "--" });
    setCalendarsToSelect(calendarsToSelectConverted);
  };

  const getSchedules = async () => {
    const scheduleClient = new SchedulesService();
    const expectedCodes: Array<number> = [200, 404];
    const schedules = await scheduleClient.getSchedulesByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const schedulesToSelectConverted: Array<TPKeyValue> = schedules
      .filter((k) => k.isActive)
      .map((k) => {
        return {
          key: k.id,
          value: k.description,
        };
      });
    schedulesToSelectConverted.unshift({ key: "", value: "--" });
    setSchedulesToSelect(schedulesToSelectConverted);
  };

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false,
      true,
      expectedCodes,
    );

    const languages = response.responseData.data;

    const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
      (k: any) => {
        return {
          key: k.id,
          value: k.name,
        };
      },
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguagesToSelect(languagesToSelectConverted);
  };

  const getTimezones = async () => {
    const TimeZoneClient = new TimeZonesService();
    const expectedCodes: Array<number> = [200, 404];
    const timezones = await TimeZoneClient.getTimeZonesCollection(
      false,
      true,
      expectedCodes,
    );
    const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
      return {
        key: k.id,
        value: k.displayName,
      };
    });
    timezonesToSelectConverted.unshift({ key: "", value: "--" });
    setSTimeZonesToSelect(timezonesToSelectConverted);
  };

  const getFunctions = async () => {
    const branchClient = new BranchService();
    const expectedCodes: Array<number> = [200, 404];
    //TODO: Guardar esta constante en un archivo "TEXTTREE"
    const functions = await branchClient.getFirstLevelBranches(
      "TEXTTREEE",
      TPActiveOptions.ALL,
      1,
      false,
      true,
      expectedCodes,
    );
    const functionsToSelectConverted: Array<TPKeyValue> = functions.map((k) => {
      return {
        key: k.id,
        value: k.description,
      };
    });
    functionsToSelectConverted.unshift({ key: "", value: "--" });
    setFunctionsToSelect(functionsToSelectConverted);
  };

  const getSuperiors = async () => {
    const userClient = new UserService();
    const expectedCodes: Array<number> = [200, 404];
    const superiors: Array<UserViewModel> = await userClient.getUsersByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const superiorsToSelectConverted: Array<TPKeyValue> = superiors.map((k) => {
      return {
        key: k.userGuid,
        value: k.name,
      };
    });
    //superiorsToSelectConverted.unshift({ key: "", value: "--" });
    setSuperiorsToSelect(superiorsToSelectConverted);
  };

  const handleSuperiorOnAutocompleteQuery = async (query: string) => {
    let newSuperiorKeyValueList: Array<TPKeyValue>;

    newSuperiorKeyValueList = superiorsToSelect.filter((superior) =>
      superior.value.toLowerCase().includes(query.toLowerCase()),
    );

    setAutocompleteSuperiorOptions(newSuperiorKeyValueList);
    return newSuperiorKeyValueList;
  };

  const handleSuperiorOnAutocompleteKeyDown = (event: any) => {
    if (
      event.keyCode != 37 && //left arrow
      event.keyCode != 38 && //down arrow
      event.keyCode != 39 && //right arrow
      event.keyCode != 40 && //up arrow
      event.keyCode != 13 && //enter
      event.keyCode != 35 && //home
      event.keyCode != 36 //end
    ) {
      setAutocompleteSuperiorOptions([]);
      handleSuperiorChange([]);
    }
  };

  const handleAutoCompleteTopNSuperiorClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteSuperiorTopNOptions.length === 0) {
      newTopNOptions = await handleSuperiorOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteSuperiorTopNOptions([...newTopNOptions]);
        setAutocompleteSuperiorOptions([...newTopNOptions]);
        handleSuperiorChange([]);
      }
    } else {
      //use cached values;
      setAutocompleteSuperiorOptions([...autocompleteSuperiorTopNOptions]);
      handleSuperiorChange([]);
    }
  };

  const handleSuperiorChange = (newSelectedValue: Array<TPKeyValue>) => {
    handleInputTextChange("superiorObj", newSelectedValue);
  };

  const GetSuperiorIdGuidSelected = () => {
    if (insertUpdateState.superiorObj?.length > 0) {
      return insertUpdateState.superiorObj[0].key;
    }

    return null;
  };

  const GetSuperiorRecord = async (superiorIdGuid: string | null) => {
    let superior: Array<TPKeyValue> = [];

    if (superiorIdGuid === null) return superior;

    let serviceClient = new UserService();
    let expectedCodes: Array<number> = [200];

    try {
      let responseRequest = await serviceClient.getUserById(
        superiorIdGuid,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: UserViewModel;
      recordInfo = { ...responseRequest };

      let keyvalue: TPKeyValue = {
        key: recordInfo.userGuid,
        value: recordInfo.name,
      };
      superior.push(keyvalue);

      return superior;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} GetSuperiorRecord ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} GetSuperiorRecord ex`);
      return [];
    }
  };

  const getAuthenticationMethods = async () => {
    const branchClient = new BranchService();
    const expectedCodes: Array<number> = [200, 404];
    //TODO: Guardar esta constante en un archivo "TEXTTREE"
    const authenticationMethods = await branchClient.getFirstLevelBranches(
      "TEXTTREEE",
      TPActiveOptions.ALL,
      1,
      false,
      true,
      expectedCodes,
    );
    const authenticationMethodsToSelectConverted: Array<TPKeyValue> =
      authenticationMethods.map((k) => {
        return {
          key: k.id,
          value: k.description,
        };
      });
    authenticationMethodsToSelectConverted.unshift({ key: "", value: "--" });
    setAuthenticationMethodsToSelect(authenticationMethodsToSelectConverted);
  };

  const loadResourcesAndLoadUserInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(await TPI18N.GetText(ResourceSet, "Title" + mode + "Label"));
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    setIsActiveLabel(await TPI18N.GetText(ResourceSet, "IsActiveLabel"));
    setUsersPersonalInfoSection(
      await TPI18N.GetText(ResourceSet, "UsersPersonalInfoSection"),
    );
    setLoginName(await TPI18N.GetText(ResourceSet, "LoginName"));
    setFirstName(await TPI18N.GetText(ResourceSet, "FirstName"));
    setLastName(await TPI18N.GetText(ResourceSet, "LastName"));
    setInitials(await TPI18N.GetText(ResourceSet, "Initials"));
    setProfile(await TPI18N.GetText(ResourceSet, "Profile"));
    setSuperior(await TPI18N.GetText(ResourceSet, "Superior"));
    setEmail(await TPI18N.GetText(ResourceSet, "Email"));
    setTeams(await TPI18N.GetText(ResourceSet, "Teams"));
    setFunctions(await TPI18N.GetText(ResourceSet, "Functions"));
    setAreas(await TPI18N.GetText(ResourceSet, "Areas"));
    setPhone(await TPI18N.GetText(ResourceSet, "Phone"));
    setMobile(await TPI18N.GetText(ResourceSet, "Mobile"));
    setPhoneNumberShouldContainOnlyNumbersLabel(
      await TPI18N.GetText(
        ResourceSet,
        "PhoneNumberShouldContainOnlyNumbersLabel",
      ),
    );
    setMobileNumberShouldContainOnlyNumbersLabel(
      await TPI18N.GetText(
        ResourceSet,
        "MobileNumberShouldContainOnlyNumbersLabel",
      ),
    );
    setCalendar(await TPI18N.GetText(ResourceSet, "Calendar"));
    setTimezone(await TPI18N.GetText(ResourceSet, "Timezone"));
    setSchedule(await TPI18N.GetText(ResourceSet, "Schedule"));
    setAuthenticationMethod(
      await TPI18N.GetText(ResourceSet, "AuthenticationMethod"),
    );
    setMicrosoftIdentity(
      await TPI18N.GetText(ResourceSet, "MicrosoftIdentity"),
    );
    setDefaultLanguage(await TPI18N.GetText(ResourceSet, "DefaultLanguage"));
    setMaximumNumberSimultaneous(
      await TPI18N.GetText(ResourceSet, "MaximumNumberSimultaneous"),
    );

    setModalAcceptLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalAcceptLabel"),
    );
    setmodalTitleLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectBranchTitleLabel",
      ),
    );
    setModalCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalCancelLabel"),
    );
    setModalSelectedBranchLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectedBranchLabel",
      ),
    );
    setEmptyLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel"),
    );
    getSchedules();
    getTimezones();
    getFunctions();
    getSuperiors();
    getAuthenticationMethods();
    getProfiles();
    getCalendars();
    getLanguages();

    if (mode == "Update") {
      await getUserById(recordId);
    }
    if (mode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };

  // const handleInputTextChange = (propertyName: string, newValue: any) => {
  //   let newInsertUpdateState = { ...insertUpdateState };
  //   newInsertUpdateState[propertyName] = newValue;
  //   newInsertUpdateState[propertyName + "ErrorMessage"] = "";
  //   setInsertUpdateState(newInsertUpdateState);
  // };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    // Define validation rules based on propertyName
    switch (propertyName) {
      case "phone":
        if (/^[0-9]*$/.test(newValue)) {
          setInsertUpdateState({
            ...insertUpdateState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]: "",
          });
        } else {
          setInsertUpdateState({
            ...insertUpdateState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]:
              phoneNumberShouldContainOnlyNumbersLabel,
          });
        }
        break;
      case "mobile":
        // Example: Validate mobile number with a different rule (if needed)
        if (/^[0-9]*$/.test(newValue)) {
          setInsertUpdateState({
            ...insertUpdateState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]: "",
          });
        } else {
          setInsertUpdateState({
            ...insertUpdateState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]:
              mobileNumberShouldContainOnlyNumbersLabel,
          });
        }
        break;
      // Add cases for other specific fields as needed
      default:
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState[propertyName] = newValue;
        newInsertUpdateState[propertyName + "ErrorMessage"] = "";
        setInsertUpdateState(newInsertUpdateState);
        break;
    }
  };

  const handleInputCheckBoxChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async () => {
    let recordInputDTO: UserInputDTO = {
      userGuid: "",
      isActive: insertUpdateState.isActive ?? false,
      name: insertUpdateState.firstName ?? "",
      areaDescription: "",
      areaId: selectedAreaBranchListValue.branchId ?? "",
      authenticationMethodId: "MSAD",
      calendarDescription: "",
      calendarId: insertUpdateState.calendarId ?? "",
      defaultLanguage: insertUpdateState.defaultLanguage ?? "",
      email: insertUpdateState.email ?? "",
      firstName: insertUpdateState.firstName ?? "",
      functionDescription: "",
      functionId: insertUpdateState.functionId ?? "",
      initials: insertUpdateState.initials ?? "",
      isSystemRecord: false,
      lastName: insertUpdateState.lastName ?? "",
      login: insertUpdateState.login ?? "",
      maxSimultaneousChat: insertUpdateState.maxSimultaneousChat ?? 0,
      microsoftIdentity: "",
      mobile: insertUpdateState.mobile ?? "",
      phone: insertUpdateState.phone ?? "",
      profileDescription: insertUpdateState.profileId ?? "",
      profileId: insertUpdateState.profileId ?? "",
      scheduleDescription: "",
      scheduleId: insertUpdateState.scheduleId,
      superiorIdGuid: GetSuperiorIdGuidSelected(),
      teamDescription: "",
      teamId: selectedTeamBranchListValue.branchId ?? "",
      timeZoneId: insertUpdateState.timeZoneId ?? "",
      typistIdGuid: TPGlobal.currentUserGuid,
    };
    let inputDTOValidator = new UserInputDTOValidator(
      ValidationUserGroupModeEnum.user,
    );
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      for (let key in resultValidator) {
        let toUpdate = "";
        if (resultValidator.hasOwnProperty(key)) {
          const name: string = resultValidator[key] as string;
          toUpdate = await TPI18N.GetResource(name);
        }
        newInsertUpdateState[key + "ErrorMessage"] = toUpdate;
      }
      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    if (mode == "Insert") {
      await insertFunction(recordInputDTO);
    } else {
      await updateFunction(recordInputDTO);
    }
  };

  const insertFunction = async (inputDTO: UserInputDTO) => {
    let serviceClient = new UserService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.insertUser(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateFunction = async (inputDTO: UserInputDTO) => {
    let serviceClient = new UserService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateUser(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const getUserById = async (pRecordId: string) => {
    let serviceClient = new UserService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getUserById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );
      let recordInfo: UserViewModel;
      recordInfo = { ...responseRequest };
      let newInsertUpdateState = { ...insertUpdateState };

      newInsertUpdateState.authenticationMethodId =
        recordInfo.authenticationMethodId ?? "MSAD";
      newInsertUpdateState.calendarId = recordInfo.calendarId ?? "";
      newInsertUpdateState.defaultLanguage = recordInfo.defaultLanguage ?? "";
      newInsertUpdateState.email = recordInfo.email ?? "";
      newInsertUpdateState.firstName = recordInfo.firstName ?? "";
      newInsertUpdateState.functionId = recordInfo.functionId ?? "";
      newInsertUpdateState.initials = recordInfo.initials ?? "";
      newInsertUpdateState.isActive = recordInfo.isActive ?? false;
      newInsertUpdateState.lastName = recordInfo.lastName ?? "";
      newInsertUpdateState.login = recordInfo.login ?? "";
      newInsertUpdateState.maxSimultaneousChat =
        recordInfo.maxSimultaneousChat ?? 0;
      newInsertUpdateState.microsoftIdentity =
        recordInfo.microsoftIdentity ?? "";
      newInsertUpdateState.mobile = recordInfo.mobile ?? "";
      newInsertUpdateState.phone = recordInfo.phone ?? "";
      newInsertUpdateState.profileId = recordInfo.profileId ?? "";
      newInsertUpdateState.scheduleId = recordInfo.scheduleId ?? "";
      newInsertUpdateState.superiorObj = await GetSuperiorRecord(
        recordInfo.superiorIdGuid,
      );

      newInsertUpdateState.timeZoneId = recordInfo.timeZoneId ?? "";
      newInsertUpdateState.typistIdGuid =
        recordInfo.typistGuid ?? TPGlobal.currentUserGuid;
      newInsertUpdateState.isSystem = recordInfo.isSystem;

      setInsertUpdateState(newInsertUpdateState);
      await getTeamBranchInfo(recordInfo.teamId);
      await getAreaBranchInfo(recordInfo.areaId);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getUserById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getUserById ex`);
      setIsLoadingScreen(false);
    }
  };
  const getBranchInfoService = async (branchId: string) => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200];
    let branchData: BranchViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getBranchById(
        branchId,
        false,
        false,
        expectedCodes,
      );
      branchData = responseRequest;
      return branchData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBranchInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getBranchInfo ex`);
      return null;
    }
  };

  const getTeamBranchInfo = async (parentId: string) => {
    let branchData: BranchViewModel | null = null;
    branchData = await getBranchInfoService(parentId);

    if (branchData == null) {
      //todo logs
      return false;
    }

    let newSelectedTeamBranchListValue: TPBranchSelectionValue = {
      ...selectedTeamBranchListValue,
    };
    newSelectedTeamBranchListValue.branchId = parentId;
    newSelectedTeamBranchListValue.branchDescription = branchData.description;
    newSelectedTeamBranchListValue.branchHierarchyDescription =
      branchData.hierarchyDescription;
    setSelectedTeamBranchListValue(newSelectedTeamBranchListValue);
  };
  const getAreaBranchInfo = async (parentId: string) => {
    let branchData: BranchViewModel | null = null;
    branchData = await getBranchInfoService(parentId);

    if (branchData == null) {
      //todo logs
      return false;
    }

    let newSelectedAreaBranchListValue: TPBranchSelectionValue = {
      ...selectedAreaBranchListValue,
    };
    newSelectedAreaBranchListValue.branchId = parentId;
    newSelectedAreaBranchListValue.branchDescription = branchData.description;
    newSelectedAreaBranchListValue.branchHierarchyDescription =
      branchData.hierarchyDescription;
    setSelectedAreaBranchListValue(newSelectedAreaBranchListValue);
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const handleOnTeamBranchChange = (
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.teamIdErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);

    let newSelectedTeamBranchListValue: TPBranchSelectionValue = {
      ...selectedTeamBranchListValue,
    };
    newSelectedTeamBranchListValue.branchId = newBranchId;
    newSelectedTeamBranchListValue.branchDescription = newBranchDescription;
    newSelectedTeamBranchListValue.branchHierarchyDescription =
      newBranchHierarchyDescription;
    setSelectedTeamBranchListValue(newSelectedTeamBranchListValue);
  };

  const handleOnAreaBranchChange = (
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.areaIdErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);

    let newSelectedAreaBranchListValue: TPBranchSelectionValue = {
      ...selectedAreaBranchListValue,
    };
    newSelectedAreaBranchListValue.branchId = newBranchId;
    newSelectedAreaBranchListValue.branchDescription = newBranchDescription;
    newSelectedAreaBranchListValue.branchHierarchyDescription =
      newBranchHierarchyDescription;
    setSelectedAreaBranchListValue(newSelectedAreaBranchListValue);
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadUserInfo();
  }, []);

  return (
    //#region  Render
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-6">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{usersPersonalInfoSection}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-11">
            <TPPageSection>
              <div className="row">
                <div className="form-group col-sm-12">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={loginName}
                    isMandatory={true}
                    value={insertUpdateState.login}
                    onChange={
                      mode == "Insert"
                        ? (e: any) =>
                            handleInputTextChange("login", e.target.value)
                        : () => {
                            TPGlobal.foo();
                          }
                    }
                    maxLength={100}
                    errorMessage={insertUpdateState.loginErrorMessage}
                    disabled={mode == "Insert" ? false : true}
                  ></TPTextBox>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={firstName}
                    isMandatory={true}
                    value={insertUpdateState.firstName}
                    onChange={(e: any) => {
                      handleInputTextChange("firstName", e.target.value);
                    }}
                    maxLength={50}
                    errorMessage={insertUpdateState.firstNameErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  ></TPTextBox>
                </div>
                <div className="form-group col-sm-6">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={lastName}
                    isMandatory={false}
                    value={insertUpdateState.lastName}
                    onChange={(e: any) => {
                      handleInputTextChange("lastName", e.target.value);
                    }}
                    maxLength={50}
                    errorMessage={insertUpdateState.lastNameErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  ></TPTextBox>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-2">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={initials}
                    isMandatory={true}
                    value={insertUpdateState.initials}
                    onChange={(e: any) => {
                      handleInputTextChange("initials", e.target.value);
                    }}
                    maxLength={10}
                    errorMessage={insertUpdateState.initialsErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  ></TPTextBox>
                </div>
                <div className="form-group col-sm-5">
                  <TPSelect
                    id="IdSelect"
                    isMandatory={true}
                    onChange={(e: any) => {
                      handleInputTextChange("profileId", e.target.value);
                    }}
                    dataSource={profilesToSelect}
                    value={insertUpdateState.profileId}
                    labelText={profile}
                    errorMessage={insertUpdateState.profileIdErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  />
                </div>
                <div className="form-group col-sm-5">
                  <TPAutoComplete
                    isMandatory={false}
                    labelText={superior}
                    onValueChange={handleSuperiorChange}
                    onSearch={(query: string) => {
                      handleSuperiorOnAutocompleteQuery(query);
                    }}
                    isLoading={false}
                    options={autocompleteSuperiorOptions}
                    withIcon={true}
                    emptyLabel={emptyLabel}
                    onKeyDown={handleSuperiorOnAutocompleteKeyDown}
                    selected={insertUpdateState.superiorObj}
                    errorMessage={insertUpdateState.superiorObjErrorMessage}
                    downArrowClick={handleAutoCompleteTopNSuperiorClick}
                    disabled={insertUpdateState.isSystem}
                  ></TPAutoComplete>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-11">
            <TPPageSection>
              <div className="row">
                <div className="form-group col-sm-6">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={email}
                    isMandatory={false}
                    value={insertUpdateState.email}
                    onChange={(e: any) => {
                      handleInputTextChange("email", e.target.value);
                    }}
                    errorMessage={insertUpdateState.emailErrorMessage}
                    maxLength={450}
                    disabled={insertUpdateState.isSystem}
                  ></TPTextBox>
                </div>

                <div className="form-group col-sm-6">
                  <TPBranchSelection2
                    labelText={teams}
                    disabledTextBox={insertUpdateState.isSystem}
                    treeId={teamTreeJson.TreeCode}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={true}
                    value={selectedTeamBranchListValue}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string,
                    ) => {
                      handleOnTeamBranchChange(
                        branchId,
                        branchDescription,
                        branchHierachyDescription,
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={insertUpdateState.teamIdErrorMessage}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    loadChildsFromThisParent={
                      teamTreeJson.ParentCode.startsWith("[Root:")
                        ? ""
                        : teamTreeJson.ParentCode
                    }
                  ></TPBranchSelection2>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-6">
                  <TPSelect
                    id="IdSelect"
                    onChange={(e: any) => {
                      handleInputTextChange("functionId", e.target.value);
                    }}
                    dataSource={functionsToSelect}
                    value={insertUpdateState.functionId}
                    labelText={functions}
                    errorMessage={insertUpdateState.functionIdErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  />
                </div>

                <div className="form-group col-sm-6">
                  <TPBranchSelection2
                    labelText={areas}
                    disabledTextBox={insertUpdateState.isSystem}
                    treeId={areaTreeJson.TreeCode}
                    modalAcceptLabel={modalAcceptLabel}
                    modalCancelLabel={modalCancelLabel}
                    modalSelectedBranchLabel={modalSelectedBranchLabel}
                    modalTitle={modalTitleLabel}
                    mustSelectLastLevelBranch={true}
                    value={selectedAreaBranchListValue}
                    onChange={(
                      branchId: string,
                      branchDescription: string,
                      branchHierachyDescription: string,
                    ) => {
                      handleOnAreaBranchChange(
                        branchId,
                        branchDescription,
                        branchHierachyDescription,
                      );
                    }}
                    emptyLabel={emptyLabel}
                    errorMessage={insertUpdateState.teamIdErrorMessage}
                    onIsLoadingProgress={(value: boolean) => {
                      setIsLoadingScreen(value);
                    }}
                    loadChildsFromThisParent={
                      areaTreeJson.ParentCode.startsWith("[Root:")
                        ? ""
                        : areaTreeJson.ParentCode
                    }
                  ></TPBranchSelection2>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-6">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={phone}
                    isMandatory={false}
                    value={insertUpdateState.phone}
                    onChange={(e: any) => {
                      handleInputTextChange("phone", e.target.value);
                    }}
                    maxLength={50}
                    errorMessage={insertUpdateState.phoneErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  ></TPTextBox>
                </div>

                <div className="form-group col-sm-6">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={mobile}
                    isMandatory={false}
                    value={insertUpdateState.mobile}
                    onChange={(e: any) => {
                      handleInputTextChange("mobile", e.target.value);
                    }}
                    maxLength={50}
                    errorMessage={insertUpdateState.mobileErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  ></TPTextBox>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-6">
                  <TPSelect
                    id="IdSelect"
                    onChange={(e: any) => {
                      handleInputTextChange("calendarId", e.target.value);
                    }}
                    dataSource={calendarsToSelect}
                    value={insertUpdateState.calendarId}
                    labelText={calendar}
                    isMandatory={true}
                    errorMessage={insertUpdateState.calendarIdErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  />
                </div>

                <div className="form-group col-sm-6">
                  <TPSelect
                    id="IdSelect"
                    onChange={(e: any) => {
                      handleInputTextChange("timeZoneId", e.target.value);
                    }}
                    dataSource={timezonesToSelect}
                    value={insertUpdateState.timeZoneId}
                    labelText={timezone}
                    isMandatory={true}
                    errorMessage={insertUpdateState.timeZoneIdErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-6">
                  <TPSelect
                    id="IdSelect"
                    onChange={(e: any) => {
                      handleInputTextChange("scheduleId", e.target.value);
                    }}
                    dataSource={schedulesToSelect}
                    value={insertUpdateState.scheduleId}
                    labelText={schedule}
                    isMandatory={true}
                    errorMessage={insertUpdateState.scheduleIdErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  />
                </div>
                <div className="form-group col-sm-6">
                  <TPSelect
                    id="IdSelect"
                    onChange={(e: any) => {
                      handleInputTextChange("defaultLanguage", e.target.value);
                    }}
                    isMandatory={true}
                    dataSource={languagesToSelect}
                    value={insertUpdateState.defaultLanguage}
                    labelText={defaultLanguage}
                    errorMessage={insertUpdateState.defaultLanguageErrorMessage}
                    disabled={insertUpdateState.isSystem}
                  />
                </div>
                {/* <div className="form-group col-sm-6">
                  <TPSelect
                    onChange={(e: any) => {
                      handleInputTextChange(
                        "authenticationMethodId",
                        e.target.value
                      );
                    }}
                    dataSource={[
                      {
                        key: "MSAD",
                        value: "Microsoft Azure AD authenticationxx",
                      },
                    ]}
                    value={insertUpdateState.authenticationMethodId}
                    labelText={authenticationMethod}
                    errorMessage={
                      insertUpdateState.authenticationMethodIdErrorMessage
                    }
                  />
                </div> */}
              </div>

              <div className="row">
                {/* <div className="form-group col-sm-6">
                  <TPTextBox
                    labelText={microsoftIdentity}
                    isMandatory={false}
                    value={insertUpdateState.microsoftIdentity}
                    onChange={(e: any) => {
                      handleInputTextChange(
                        "microsoftIdentity",
                        e.target.value
                      );
                    }}
                    errorMessage={
                      insertUpdateState.microsoftIdentityErrorMessage
                    }
                  ></TPTextBox>
                </div> */}
              </div>
              <p>{usersPersonalInfoSection}</p>
              <div className="row">
                {/* <div className="form-group col-sm-6">
                  <TPNumeric
                    labelText={maximumNumberSimultaneous}
                    onChange={(e: number) => {
                      handleInputTextChange("maxSimultaneousChat", e);
                    }}
                    value={insertUpdateState.maxSimultaneousChat}
                    errorMessage={
                      insertUpdateState.maxSimultaneousChatErrorMessage
                    }
                  ></TPNumeric>
                </div> */}
                <div className="form-group col-sm-6 mt-4">
                  <TPCheckBox
                    id="IdCheckBox"
                    labelText={isActiveLabel}
                    checked={insertUpdateState.isActive}
                    onChange={handleInputCheckBoxChange}
                    disabled={insertUpdateState.isSystem}
                  ></TPCheckBox>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row">
          <div className="col-11">
            <TPPageAcceptCancelButtonsContainer>
              {!insertUpdateState.isSystem && (
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={handleOkButtonClick}
                >
                  {saveButtonLabel}
                </TPButton>
              )}

              <TPButton
                id="IdButton"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
    //#endregion
  );
};

export default UserInsertUpdate;
