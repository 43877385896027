import {
  TabsCaseViewerSlice,
  TabsCaseViewerStateModel,
} from "@/layouts/VerticalTabs/_redux/TabsCaseViewerSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import { StoreModel } from "../../redux/store";

const MINUTE = 60;
const HOUR = MINUTE * 60;

interface VerticalTabCaseTimeListenerProps {
  caseNumber: string;
}

export default function VerticalTabCaseTimeListener({
  caseNumber,
}: VerticalTabCaseTimeListenerProps) {
  const { seconds } = useSelector(
    (s: StoreModel) => s[TabsCaseViewerSlice.name]
  ) as TabsCaseViewerStateModel;

  return (
    <span style={{ fontSize: "14px", color: "red" }}>
      {moment(moment().format("YYYY-MM-DD"))
        .add(seconds[caseNumber] || 0, "s")
        .format(seconds[caseNumber] >= HOUR ? "HH:mm:ss" : "mm:ss")}
    </span>
  );
}
