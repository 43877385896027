import TPGeneralContext from "@/contexts/TPGeneralContext";
import {
  collapsedLeftPosition,
  collapsedMenuWidth,
  expandedLeftPosition,
  expandedMenuWidth,
  horizontalTabHeight,
  menuFooterHeight,
  verticalSpace,
} from "@/helpers/generalStyles";
import "animate.css";
import React from "react";
import styled, { keyframes } from "styled-components";

export const TPMainHeaderContainerStyle = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
  .logos-container{
    display: flex;
    justify-content: ${contextData.isLeftMenuCollapsed ? "center" : "space-between"};
    flex-direction: ${contextData.isLeftMenuCollapsed ? "column" : "row"};
    gap: 8px;
    padding-top: 8px;
    align-items: center;
    padding-left: ${contextData.isLeftMenuCollapsed ? "0" : "16px"};
    padding-right: ${contextData.isLeftMenuCollapsed ? "0" : "16px"};

    .img-logo {
      height: 16px;
      margin:  0 ${contextData.isLeftMenuCollapsed ? "18px" : "none"};
      &.collapsed{
        display: ${contextData.isLeftMenuCollapsed ? "block" : "none"}
      }
      &.expanded{
        display: ${!contextData.isLeftMenuCollapsed ? "block" : "none"}
      }
    }
  }
`;
});

export const MainMenuContainerStyled = styled.div``;

export const LeftMenuHeaderStyled = styled.div(({ theme }) => {
  return ``;
});

export const TPMainMenuContainerStyle = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
    position: ${contextData.isLeftMenuCollapsed ? "initial" : "fixed"};
    top: 0;
    bottom: 0 ;
    left: 0;
    background-color: ${theme.colors.leftMenu.backgroundColor};
    width: ${contextData.isLeftMenuCollapsed ? collapsedMenuWidth : expandedMenuWidth};
    bottom: ${contextData.isLeftMenuCollapsed ? "0" : menuFooterHeight};
    overflow: ${contextData.isLeftMenuCollapsed ? "visible" : "auto"};
    min-height: ${contextData.isLeftMenuCollapsed ? "100vh" : "initial"}; ;

`;
});

export const TPSearchMenuContainer = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
    display:${contextData.isLeftMenuCollapsed ? "none" : "block"};
    padding: 20px;

    input {
      width: 100%;
      ::placeholder{
        font-size: 12px;
      }
    }
    `;
});

export const LeftMenuFooterStyled = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
    position: fixed;
    width: ${contextData.isLeftMenuCollapsed ? collapsedMenuWidth : expandedMenuWidth};
    left: 0;
    bottom: 0;
    height: ${menuFooterHeight};
    background-color: ${theme.colors.leftMenu.footer.backgroundColor};

    .logo-name-station{
      color: ${theme.colors.leftMenu.logoAndStation.fontColor};
      display: flex;
      justify-content: space-around;

      .logo-container{
        width: 50px;
        height: 50px;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-circlex{
          width: 80%;
          height: 80%;
          border-radius: 25px;
          border: 1px solid ${theme.colors.leftMenu.logoAndStation.logoCircle.borderColor};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .name-station{
        color:${theme.colors.leftMenu.logoAndStation.station.name.fontColor};
        display:${contextData.isLeftMenuCollapsed ? "none" : "flex"};
        flex-direction: column;
        align-items: end;
        line-height: 20px;
        justify-content: center;
        font-size: 12px;

        .station{
          text-decoration: underline;
          cursor: pointer;

          .label{
            font-weight: 500;
            color: ${theme.colors.leftMenu.logoAndStation.station.label.fontColor};
          }

          .number{
            color: ${theme.colors.leftMenu.logoAndStation.station.number.fontColor};
            font-weight: 700;
          }
        }
      }
    }

    .copyright-text{
      display:${contextData.isLeftMenuCollapsed ? "none" : "block"};
      display: none;
      font-size: 9px;
      text-align: center;

      a{
        color: ${theme.colors.primary};
      }
    }
  `;
});

export const TPFooterCopyrightTextStyled = styled.span`
  font-size: 9px;
`;

export const TPFooterTeleperformanceTextStyled = styled.span(
  ({ theme }) => `
  text-decoration: underline;
  color: ${theme.colors.primary}
`,
);

const OpenNotificationCenterAnimation = keyframes`
 0% { width: 0 }
 100% { width: 250px }
`;

const CloseNotificationCenterAnimation = keyframes`
 0% { width: 250px }
 100% { width: 0 }
`;

interface NotificationCenterContainerStyledProps {
  theme: any;
  isOpened: boolean;
}

export const NotificationCenterContainerStyled =
  styled.div<NotificationCenterContainerStyledProps>(({ isOpened, theme }) => {
    return `
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    padding: 10px;
    height: 100vh;
    background: ${theme.colors.notificationCenter.backgroundColor};
    width: 250px;
    overflow: auto;
    color: ${theme.colors.notificationCenter.fontColor};
    z-index: 1;

    ul{
      list-style: none;
      padding: 10px;
      font-size: 12px;

      li{
        padding: 10px 0;

        .notification-item-container {
          display: flex;
          flex-direction: column;
          position: relative;
          background-color:${theme.colors.notificationCenter.item.backgroundColor};
          padding:5px;

          &.notification-success{
            border-left:3px solid ${theme.colors.notificationCenter.item.success.borderColor};
          }

          &.notification-warning{
            border-left:3px solid ${theme.colors.notificationCenter.item.warning.borderColor};
          }

          &.notification-error{
            border-left:3px solid ${theme.colors.notificationCenter.item.error.borderColor};
          }

          &.notification-info{
            border-left:3px solid ${theme.colors.notificationCenter.item.info.borderColor};
          }
        }

        .notification-title{
          font-weight: 600;
          margin-right:20px;
        }

        .notification-text{
        }

        .notification-date{
          color: ${theme.colors.notificationCenter.date.fontColor};
        }

        .notification-remove{
          position: absolute;
          right: 0;
          top: 0;

          &.btn.tp-btn-icon i{
            font-size: 12px;
          }

        }

        &:hover{
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
`;
  });

export const TPHorizontalTabsContainerStyle = styled.div``;

export const TPHorizontalTabsNavTabsContainerStyle = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
  position: fixed;
  left: ${contextData.isLeftMenuCollapsed ? collapsedLeftPosition : expandedLeftPosition};
  height: ${horizontalTabHeight};
  top: 9px;
  right: 0;
  display: flex;

  .nav{
    font-size: 14px;

    &.nav-tabs {
      overflow-x: hidden;
      overflow-y: hidden;
      flex-wrap: nowrap;
      width: calc(100% - 370px);
      border-bottom: 1px solid gray;
      scrollbar-width: thin;
      scrollbar-color: ${theme.colors.scrollbar.color} transparent;
      height: calc(100% + 1px);

      &:hover{
        overflow-x: overlay;
      }

      &::-webkit-scrollbar {
        height: 10px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.scrollbar.color};
        border-radius: 10px;
        border: 0;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      .nav-item{
        .nav-link {
          position: relative;
          white-space: nowrap;
          color: black;
          width: 150px;
          height: ${horizontalTabHeight};
          text-align: left;
          padding: 4px 8px;
          display: flex;
          flex-direction: column;
          border: 1px solid ${theme.colors.scrollbar.color};
          border-bottom: 0;
          opacity: .7;

          .tp-title-horizontal-tab{
            word-break: break-word;
            white-space: break-spaces;
            margin-right: 5px;
            font-weight: 900;
            overflow: hidden;
            margin: 0;
            cursor: pointer;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-width: 8em;
            margin-top: 6px;
          }

          .tp-subtitle-1-horizontal-tab{
            font-size: 10px;
          }

          .tp-subtitle-2-horizontal-tab{
            font-size: 10px;
          }

          .tp-close-horizontal-tab {
                position: absolute;
                right: 6px;
                top: 12px;
                height: 15px;
                width: 15px;
                font-size: 11px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                background: #dddddd;
                border-radius: 50%;
                color: white;
                cursor: pointer;
                
                &:hover {
                  background: #afafaf;
                }
          }

          &.active {
            position: relative;
            border-color: transparent;
            border-top: 7px solid ${theme.colors.primary};
            font-weight: 600;
            background-color: ${theme.colors.leftMenu.backgroundColor};
            opacity:1;
            height: 69px;
            z-index:1;

            .tp-title-horizontal-tab {
              margin-top: 0;
            }

            .tp-close-horizontal-tab {
              top: 7px;
            }
          }
        }
      }
    }
  }
`;
});

export const HorizontalTabsFavoritesIconsContainerStyled = styled.div`
  display: flex;
  width: 350px;
  margin-right: 20px;
  position: absolute;
  right: 0;
  top: 5px;
`;

export const HorizontalTabsFavoritesIconsStyled = styled.ul`
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-around;
  width: 100%;
  gap: 10px;
`;

export const HorizontalTabsFavoritesIconContainerStyled = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;

  span {
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
  }

  div {
    border: 1px solid #5d5d5d;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  label {
    color: #5d5d5d;
    font-weight: 500;
    font-size: 10px;
    cursor: pointer;
    margin-top: 2px;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const TPHorizontalTabsNavTabsContentStyle = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
      position: fixed;
      overflow-y: hidden;
      width: calc(100% - ${
        contextData.isLeftMenuCollapsed
          ? Number(collapsedMenuWidth.replace("px", "")) + 22 + "px"
          : expandedMenuWidth + " - 22px"
      } ); /*22px is because padding 10 both sides + line width 1px both sides*/

      left: ${contextData.isLeftMenuCollapsed ? collapsedLeftPosition : expandedLeftPosition};
      top: calc(${horizontalTabHeight} + ${verticalSpace});
      height: calc(100% - 88px);
      background-color: ${theme.colors.leftMenu.backgroundColor};
      padding: 10px;

      .tp-tab-content{
        background-color: white;
        width:100%;
        height:100%;
        padding-bottom: 5px;
        border: 1px solid #BFBFBF;
        border-radius: 4px;
      }
  `;
});

const commonLi = styled.li``;

export const SectionContainerStyled = styled.ul(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
    padding: 3px 10px;
`;
});

export const SectionItemContainerStyled = styled.li(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
    list-style: none;

    ${MenuTitleItem}{
      display: ${contextData.isLeftMenuCollapsed ? "none" : ""};
    }

  `;
});

export const MenuContainerStyled = styled.ul(
  ({ theme }) => `
  padding: 0;
  color: #2E2E2E;
  font-size: 12px;
  font-weight: 600;
`,
);

export const MenuItemContainerStyled = styled.li(
  ({ theme }) => `
  list-style: none;
  position:relative;
  margin: 15px 0;
`,
);

export const MenuItemTitleContainerStyled = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
    padding: 10px 0;
    cursor: pointer;

    svg {
      font-size: 16px;
    }

    &:hover {
      color: ${theme.colors.primary};
      span{
          font-weight: 700;
      }
    }

    ${
      contextData.isLeftMenuCollapsed == true &&
      `
      justify-content:  center;
    `
    }
`;
});

export const ExpanderContainerStyled = styled.div(() => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
    position: ${!contextData.isLeftMenuCollapsed ? "absolute" : ""};
    right: 0;
`;
});

interface SubmenuContainerStyledType {
  theme: any;
  isLeftMenuCollapsed: boolean;
}

export const SubmenuContainerStyled = styled.ul<SubmenuContainerStyledType>(
  ({ theme, isLeftMenuCollapsed }: any) => `
  padding-left: 15px;
  font-weight: normal;

  ${
    isLeftMenuCollapsed &&
    `
      background-color: white;
      position:absolute;
      top: 0;
      left: calc(${collapsedMenuWidth} - 50px);
      min-height: 40px;
      z-index: 1;
      padding: 10px;
      border-radius: 5px;
      min-width: 200px;
      -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.43);
      box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.43);
  `
  };

`,
);

export const SubmenuItemContainerStyled = styled.li(
  ({ theme }) => `
  list-style: none;
  padding: 5px;
  cursor: pointer;
  font-size: 12.8px;
  padding-left: 15px;

  &:hover{
    background-color: ${theme.colors.brandHighlight};
    border-radius: 8px;
    padding-left: 15px;
  }
`,
);

export const SectionTitle = styled.span(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
          display: ${contextData.isLeftMenuCollapsed ? "none" : "block"};
          font-size: 10px;
          letter-spacing: 0.1em;
          color: ${theme.colors.scrollbar.color};
          text-transform: uppercase;
          font-weight: 600;
          word-break: break-all;
        `;
});

export const MenuTitleItem = styled.span`
  font-weight: 500;
  font-size: 12px;
`;

export const SubMenuTitle = styled.span`
  font-weight: 400;
`;

export const RightVerticalMenuContainer = styled.div`
  z-index: 1;
  min-height: 800px;
`;

export const RightVerticalMenuWrapper = styled.div`
  border: 1px solid rgb(165, 165, 165, 0.25);
  padding: 15px;
  border-radius: 5px;
`;

export const RightVerticalMenuUl = styled.ul``;

export const RightVerticalMenuLi = styled.li(
  ({ theme }) => `
  cursor: pointer;
  color: #2e2e2e;
  padding-bottom: 10px !important;

  a {
    text-decoration: none;
    color: inherit;
  }

  &.active {
    color: ${theme.colors.primary};
    font-weight: bold;
  }
`,
);
