import { TPCodes } from "./TPCodes";

export class StandardCodeMessage {
  public code: number = 0;
  public message: string = "";
}

export class StandardResponse {
  public responseCode: TPCodes = TPCodes.UNKNOWN;
  public traceId: string = "";
  public responseMessage: StandardCodeMessage = new StandardCodeMessage();
}

export class HTTPResponse {
  public responseResult: boolean = false;
  public responseData: any = null;
}
