import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { Clone } from "@/models/Workflow/WorkflowTypeClone";
import { WorkflowTypeInputDTO } from "@/models/Workflow/WorkflowTypeInputDTO";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";

export class WorkflowTypeService {
  serviceFileName: string = "WorkflowTypeService.ts";

  public async getByFilterIsActive(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<WorkflowTypeViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByFilterIsActive ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getByFilterIsActive`);
      throw new Error(`Error ${this.serviceFileName} getByFilterIsActive`);
    }
  }

  public async getById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<WorkflowTypeViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype/";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getById`);
      throw new Error(`Error ${this.serviceFileName} getById`);
    }
  }

  public async insert(
    inputDTO: WorkflowTypeInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insert ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insert`);
      throw new Error(`Error ${this.serviceFileName} insert`);
    }
  }

  public async update(
    inputDTO: WorkflowTypeInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} update ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} update`);
      throw new Error(`Error ${this.serviceFileName} update`);
    }
  }

  public async clone(
    inputDTO: Clone,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype/clone";
    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} clone ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} update`);
      throw new Error(`Error ${this.serviceFileName} update`);
    }
  }

  public async delete(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype/" + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} delete ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} delete`);
      throw new Error(`Error ${this.serviceFileName} delete`);
    }
  }

  public async getByBaseLevelAndClassifiers(
    baseLevelId: string,
    branch1: string,
    branch2: string,
    branch3: string,
    branch4: string,
    branch5: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<WorkflowTypeViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype/get-workflow?BaseLevelId=";
    url = url + baseLevelId;
    url = url + "&Branch1=" + branch1;
    url = url + "&Branch2=" + branch2;
    url = url + "&Branch3=" + branch3;
    url = url + "&Branch4=" + branch4;
    url = url + "&Branch5=" + branch5;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getByBaseLevelAndClassifiers ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getByBaseLevelAndClassifiers`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getByBaseLevelAndClassifiers`,
      );
    }
  }

  public async getDiagramByBaseLevelAndClassifiers(
    workflowTypeId: string,
    baseLevelId: string,
    branch1: string,
    branch2: string,
    branch3: string,
    branch4: string,
    branch5: string,
    showResponsible: boolean,
    orientationDiagram: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype/diagram?";
    url = url + "&workflowTypeId=" + workflowTypeId;
    url = url + "&baseLevelId=" + baseLevelId;
    url =
      url +
      "&classify1BranchId=" +
      (!branch1 ? TPGlobal.Branch.NA_BranchCode : branch1);
    url =
      url +
      "&classify2BranchId=" +
      (!branch2 ? TPGlobal.Branch.NA_BranchCode : branch2);
    url =
      url +
      "&classify3BranchId=" +
      (!branch3 ? TPGlobal.Branch.NA_BranchCode : branch3);
    url =
      url +
      "&classify4BranchId=" +
      (!branch4 ? TPGlobal.Branch.NA_BranchCode : branch4);
    url =
      url +
      "&classify5BranchId=" +
      (!branch5 ? TPGlobal.Branch.NA_BranchCode : branch5);
    url = url + "&showResponsible=" + showResponsible;
    url = url + "&orientationDiagram=" + orientationDiagram;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.keyList[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getDiagramByBaseLevelAndClassifiers ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getDiagramByBaseLevelAndClassifiers`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getDiagramByBaseLevelAndClassifiers`,
      );
    }
  }

  public async getDiagramByWorkflowType(
    workflowTypeId: string,
    orientationDiagram: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/workflowtype/diagram/by-workflow-id";
    url = url + "?workflowtypeid=" + workflowTypeId;
    url = url + "&orientationDiagram=" + orientationDiagram;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.keyList[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getDiagramByWorkflowType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getDiagramByWorkflowType`);
      throw new Error(`Error ${this.serviceFileName} getDiagramByWorkflowType`);
    }
  }
}
