import styled from "styled-components";

interface TPSelectStyledProps {
  minWidth: number;
}

export const TPSelectStyled = styled.select<TPSelectStyledProps>(
  ({ theme, minWidth }) => `
  min-width: ${minWidth}px;
  padding: 0 0.6em;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 4px;
  height: 35px;

  option{
    &:hover{
      background-color: ${theme.colors.select.option.hover.backgroundColor};
      color: ${theme.colors.select.option.hover.fontColor};
    }
  }

  &.form-select {
    font-size: 14px;

    &:focus, &.focus {
      box-shadow: none;
      border-color: ${theme.colors.primary} !important;
      outline: 1px solid ${theme.colors.primary} !important; 

      &:invalid,
      &.is-invalid {
        border-color: ${theme.colors.errorColor} !important;
        outline: 1px solid ${theme.colors.errorColor} !important;
      }
    }
  }
`,
);

export default TPSelectStyled;
