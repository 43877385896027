import { put, takeEvery, all } from "redux-saga/effects";
import { GroupCloneFormSlice } from "./GroupCloneFormSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { TPI18N } from "@/services/I18nService";
import GroupCloneFormModel from "../models/GroupCloneFormModel";
import e from "../models/GroupCloneFormEnum";
import { GroupsService } from "@/services/GroupsService";
import { SequenceService } from "@/services/SequenceService";
import { SequeceGeneratorSequencesNameEnum } from "@/models/Global/TPGlobalEnums";

const { clone } = new GroupsService();
const { generalAutomaticId } = new SequenceService();

function* message({ payload }: PayloadAction<Array<string | Array<string>>>) {
  try {
    for (const attribute of payload[1]) {
      // @ts-ignore
      const message: string = yield TPI18N.GetText(payload[0]!, attribute);
      yield put(
        GroupCloneFormSlice.actions.messageSuccess({
          attribute,
          message,
        }),
      );
    }
  } catch (error) {
    yield put(
      GroupCloneFormSlice.actions.saveError({
        error,
        message: e.CloneGetMessageErrorMessage,
      }),
    );
  }
}

function* getId() {
  try {
    // @ts-ignore
    const response = yield generalAutomaticId(
      false,
      true,
      [200],
      SequeceGeneratorSequencesNameEnum.SQGROU,
    );
    yield put(
      GroupCloneFormSlice.actions.getIdSuccess(
        response.responseData.data[0].sequenceCode,
      ),
    );
  } catch (error) {
    const message: string = yield TPI18N.GetText(
      e.GroupCloneFormComponent,
      e.CloneSaveErrorMessage,
    );
    yield put(GroupCloneFormSlice.actions.getIdError({ error, message }));
  }
}

function* save({ payload }: PayloadAction<GroupCloneFormModel>) {
  try {
    // @ts-ignore
    const response: any = yield clone(payload);
    if (response.responseData.responseCode > 299) {
      yield put(
        GroupCloneFormSlice.actions.saveError({
          error: response.responseData.responseMessage,
          message: response.responseData.responseMessage.message,
        }),
      );
    } else {
      const message: string = yield TPI18N.GetText(
        e.GroupCloneFormComponent,
        e.CloneSaveSuccessMessage,
      );
      yield put(
        GroupCloneFormSlice.actions.saveSuccess({
          element: {
            ...payload,
            groupId: response.responseData.keyList[0].value,
          },
          message,
        }),
      );
    }
  } catch (error) {
    const message: string = yield TPI18N.GetText(
      e.GroupCloneFormComponent,
      e.CloneSaveErrorMessage,
    );
    yield put(GroupCloneFormSlice.actions.saveError({ error, message }));
  }
}

export function* GroupCloneFormSaga() {
  yield all([
    takeEvery(GroupCloneFormSlice.actions.message, message),
    takeEvery(GroupCloneFormSlice.actions.getId, getId),
    takeEvery(GroupCloneFormSlice.actions.save, save),
  ]);
}
