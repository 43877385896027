import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { UserGroupInputDTO } from "@/models/UserGroups/UserGroupInputDTO";
import { UserGroupViewModel } from "@/models/UserGroups/UserGroupModels";

export class UserGroupService {
  serviceFileName: string = "UserGroupService.ts";

  public async getAllNoGroupByGroupId(
    groupId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<UserGroupViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/user-group/no-group/${groupId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAllNoGroupByGroupId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getAllNoGroupByGroupId`);
      throw new Error(`Error ${this.serviceFileName} getAllNoGroupByGroupId`);
    }
  }

  public async getAllGroupByGroupId(
    groupId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<UserGroupViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/user-group/group/${groupId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAllGroupByGroupId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getAllGroupByGroupId`);
      throw new Error(`Error ${this.serviceFileName} getAllGroupByGroupId`);
    }
  }

  public async insert(
    inputDTO: UserGroupInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user-group";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insert ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insert`);
      throw new Error(`Error ${this.serviceFileName} insert`);
    }
  }

  public async delete(
    groupId: string,
    guidUser: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/user-group/group/${groupId}/user/${guidUser}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} delete ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} delete`);
      throw new Error(`Error ${this.serviceFileName} delete`);
    }
  }

  public async reassign(
      reassign: any,
      showPositiveMessage: boolean = true,
      showNegativeMessage: boolean = true,
      expectedCodes: Array<number> = [200, 404]
  ): Promise<any> {
    const {putData} = new TPHTTPService();
    try {
      await putData(
          `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/user-group/reassign-users-group`,
          reassign,
          expectedCodes,
          showPositiveMessage,
          showNegativeMessage,
          [...TPGlobal.standardHeaders]
      );
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} reassign ex`, TPLogType.ERROR, error);
      console.error(`Error ${this.serviceFileName} reassign`);
      throw new Error(`Error ${this.serviceFileName} reassign`);
    }
  }
}
