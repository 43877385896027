import styled from "styled-components";

export const MainContainerStyled = styled.div(
  ({ theme }) => `
  .modal-title{
    text-transform: uppercase;
    color: ${theme.colors.primary};
    font-size: 24px;
    font-weight: 900;
  }

  mark,
  .mark{
    padding: 0 !important;
    background-color: yellow !important;
    display: inline !important;
  }

  .tab-pane.fade.show.active > div {
    z-index: 1;
    border: none !important;
    overflow-x: hidden;
    height: calc(100% - 110px);
  }

  .tp-primary-color {
    color: ${theme.colors.primary};
  }

  .tpblock {
    display: block !important;
  }

  .tpdiagram {
    min-width: 900px;
  }

  .tpnone {
    display: none !important;
  }

  .tphover:hover {
    background-color: ${theme.colors.cherryColor};
  }

  .tpred {
    color: ${theme.colors.redColor};;
  }
  .tpgreen {
    color: ${theme.colors.greenColor};;
  }

  .tpbold {
    font-weight: 600;
  }

  .tpfont10 {
    font-size: 10px;
  }
  .tpfont11 {
    font-size: 11px;
  }
  .tpfont12 {
    font-size: 12px;
  }
  .tpfont13 {
    font-size: 13px;
  }
  .tpfont14 {
    font-size: 14px;
  }
  .tpfont15 {
    font-size: 15px;
  }
  .tpfont16 {
    font-size: 16px;
  }

  .tpw100per {
    width: 100%;
  }

  .tph100per {
    height: 100%;
  }

  .tpcenter {
    display: flex;
    justify-content: center;
  }
  .tpright {
    display: flex;
    justify-content: right;
  }
  .tpleft {
    display: flex;
    justify-content: left;
  }

  .tpaligncenter {
    display: flex;
    align-items: center;
  }

  .tpalignbaseline {
    display: flex;
    align-items: baseline;
  }

  .tp-badge {
    border-radius: 10px;
    width: 6ch;
  }

  .tp-bg-primary {
    background-color: ${theme.colors.primary};
  }

  .tp-bg-secondary {
    background-color: ${theme.colors.graySlightColor};
    color: var(--bs-body-color);
  }

  .tpmargin-bottom5 {
    margin-bottom: 5px !important;
  }
  .tpmargin-bottom15 {
    margin-bottom: 15px !important;
  }
  .tpmax-width-170 {
    max-width: 170px;
  }
  .tpmin-width-100 {
    min-width: 100px;
  }
  .tpheight-250 {
    height: 250px;
  }
  .tp-padding-5 {
    padding: 5px !important;
  }
  .tp-padding-10 {
    padding: 10px !important;
  }
  .tp-padding-right-0 {
    padding-right: 0 !important;
  }
  .tp-padding-left-0 {
    padding-left: 0 !important;
  }
  .tp-padding-8 {
    padding: 8px !important;
  }

  .tprotate-0-and-scale2 {
    transform: rotate(0deg) scale(1.5);
    margin: 5px;
  }
  .tprotate-90-and-scale2 {
    transform: rotate(90deg) scale(1.5);
    margin: 5px;
  }
  .tprotate-180-and-scale2 {
    transform: rotate(180deg) scale(1.5);
    margin: 5px;
  }
  .tprotate-270-and-scale2 {
    transform: rotate(270deg) scale(1.5);
    margin: 5px;
  }

  .tptreeviewbranchli {
    margin-bottom: 11px !important;
    margin-top: 11px !important;
    white-space: nowrap;
  }

  .tptreeviewbranchli::before {
    content: "" !important;
    display: inline-block !important;
    // border-top: 1px solid #dcdcdc !important;
    width: 20px !important;
    height: 0px !important;
    margin-left: -33px !important;
    position: relative !important;
    top: -5px !important;
    padding-right: 2px !important;
    white-space: nowrap;
    border: none;
  }

  .tptreeviewbranchliadmin {
    margin-bottom: 11px !important;
    margin-top: 11px !important;
    white-space: nowrap;
  }

  .tptreeviewbranchliadmin::before {
    content: "" !important;
    display: inline-block !important;
    //border-top: 1px solid #dcdcdc !important;
    width: 20px !important;
    height: 0px !important;
    margin-left: 0 !important;
    position: relative !important;
    top: -5px !important;
    padding-right: 2px !important;
    white-space: nowrap;
  }

  .tpdisabled {
    pointer-events: none;
    opacity: 0.2;
  }

  fieldset.section-border {
    border: 1px groove #dee2e6 !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
    margin-top: 30px !important;
  }

  fieldset.section-noborder {
    padding: 0 1.4em 1.4em 1.4em !important;
  }

  legend.section-border {
    //font-size: 1.2em !important;
    //font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    //border-bottom: none !important;
    margin-top: -15px;
    background-color: ${theme.colors.legend.sectionBorder.backgroundColor};
    color: ${theme.colors.legend.sectionBorder.color};
  }
  .tptable-border-gray {
    border: 1px solid ${theme.colors.borderGrayColor};
  }
  .tpoverflow-y-auto {
    overflow-y: auto;
  }

  .tpcircle-text {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: #fff;
    border: 2px solid #5d5d5d;
    color: #000;
    text-align: center;
    font: 10px Arial, sans-serif;
  }

  .tp-bread-crumb {
    padding: 0;
    margin: 0;
    min-width: 0;
  }

  .rbt-menu {
    width: 400px !important;
  }

  .accordion-item{
    border: none;
  }

  .accordion-button {
    padding: 9px;
  }

  .accordion-button {
    color: ${theme.colors.blackColor};
    background-color: ${theme.colors.whiteColor};
    box-shadow: none;
    font-size: 17px;
    font-weight: 700;
    border-bottom: 1px solid ${theme.colors.blackColor};
  }
  .accordion-button:focus {
    border-color: none;
    box-shadow: none;
  }

  .tpFixedHeadTable {
    overflow-y: auto;
    color: ${theme.colors.fixedHeadTable.fontColor};
    font-size: 13px;
    border: 1px solid ${theme.colors.fixedHeadTable.borderColor};
  }
  .tpFixedHeadTable thead th {
    position: sticky;
    top: -1px;
    background-color: ${theme.colors.fixedHeadTable.thead.th.backgroundColor};
  }
  .tpFixedHeadTable.tpWithoutSticky thead th {
    position: unset;
    top: 0;
  }

  .tpFixedHeadTable table {
    border-collapse: collapse;
    width: 100%;
  }
  .tpFixedHeadTable.noWidth100per table {
    border-collapse: collapse;
    width: unset;
  }
  .tpFixedHeadTable th,
  td {
    padding: 8px 15px;
    border-top: 1px solid ${theme.colors.borderGrayColor};
    border-bottom: 1px solid ${theme.colors.borderGrayColor};
  }
  .tpFixedHeadTable th {
    background-color: ${theme.colors.fixedHeadTable.thead.th.backgroundColor};
  }
  .tpFixedHeadTable table.noTransparent {
    background-color: ${theme.colors.fixedHeadTable.noTransparent.backgroundColor};
  }

  .tpOrderHover:after {
    content: attr(data-hover);
    visibility: hidden;
    position: relative;
    z-index: 1;
    left: 0;
    font-size: 16px;
  }
  .tpOrderHover:hover:after {
    visibility: visible;
  }

  .tpFixedHeadTable.highlightOnHover > table tbody tr:hover {
    background-color: ${theme.colors.fixedHeadTable.highlightOnHover.tbody.tr.hover.backgroundColor};
  }
  .tpFixedHeadTable.nohighlightOnHover > table tbody tr:hover {
    background-color: ${theme.colors.fixedHeadTable.noHighlightOnHover.tbody.tr.hover.backgroundColor};
  }

  .tpFixedHeadTableshowTotalRows {
    color: ${theme.colors.fixedHeadTable.showTotalRows.fontColor};
    font-size: 15px;
  }

  .tpfilterOpen {
    color: ${theme.colors.primary};
    background-color: ${theme.colors.whiteSmoke};
    padding: 3px;
    font-size: 24px;
    border: 1px solid ${theme.colors.darkGray};
    border-radius: 3px;
  }

  .tpfilterApplied {
    color: ${theme.colors.primary};
    background-color: ${theme.colors.whiteSmoke};
    padding: 3px;
    font-size: 24px;
    border: 1px solid ${theme.colors.darkGray};
    border-radius: 3px;
  }

  .accordion-button.tpRemoveAccordionButton:after {
    visibility: hidden !important;
  }

  .secondary-style-tp-tabs.nav-tabs {
    border-bottom-color: #bfbfbf;
  }

  .secondary-style-tp-tabs.nav-tabs .nav-link.active,
  .secondary-style-tp-tabs.nav-tabs .nav-item.show .nav-link {
    color: ${theme.colors.primary};
    background-color: ${theme.colors.whiteColor};
    border-bottom: 2px solid;
    border-color: ${theme.colors.whiteColor} ${theme.colors.whiteColor} ${theme.colors.primary};
    font-weight: 600;
  }

  .secondary-style-tp-tabs.nav-tabs .nav-link:hover,
  .secondary-style-tp-tabs.nav-tabs .nav-link:focus {
    border: none;
    border-bottom: 2px solid ${theme.colors.primary};
    isolation: isolate;
  }

  .secondary-style-tp-tabs.nav-tabs .nav-link {
    border: none;
    border-bottom: 1px solid;
    color: ${theme.colors.blackColor};
  }

  .secondary-style-tp-tabs .nav-link:hover,
  .nav-link:focus {
    color: ${theme.colors.blackColor};
  }

  .tp-fixed-table {
    thead {
      th {
        font-size: 12px;
        padding: 4px;
      }
    }
    tbody {
      tr:nth-child(even) {
        background-color: #f7f7f7;
      }
      td {
        font-size: 12px;
        padding: 4px;
      }
    }
  }

  // jid
  .tab-pane.fade.show.active > .new-case__additional_data_container {
    margin: 0px;
    border: 1px solid rgb(222, 226, 230) !important;
  }

  //review yeison
  .new-case__main_container {
    position: relative;
    min-height: calc(100vh - 125px);

    .new-case__additional_data_container {
      max-height: 155px;
      overflow: auto;
    }

    .new_case__attachments-container {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        border-bottom: 1px solid ${theme.colors.graySlightColor};
        &:nth-child(odd) {
          background-color: ${theme.colors.whiteColor};
        }
        &:nth-child(even) {
          background-color: #f7f7f7;
        }
      }
    }

    .new_case__attachments-btn-delete {
      background-color: #eb0027;
      color: white;
      cursor: pointer;

      svg {
        &:hover {
          background-color: #eb0027;
          color: white;
        }
      }
    }

    .new-case__footer_buttons {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 60px;

      .new-case__footer_buttons-actions {
      }
      .new-case__footer_buttons-buttons {
      }
    }
  }

  .tpFollowUpFolders h1 {
    text-align: center;
  }
  .tpFollowUpFolders li {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    align-items: center;
    width: 90%;
  }
  .tpFollowUpFolders li.active {
    color: ${theme.colors.primary};
    font-weight: 600;
  }

  .tptasksfoldertitle {
    margin-left: 5px;
    max-width: 70px;
    white-space: nowrap;
  }

  .tpColumnsTaskContainer {
    border: 1px solid rgb(222, 226, 230);
    border-radius: 5px;
    padding: 10px;
    margin-right: 10px;
    width: 100%;
    height: 100%;
  }

  .tp-root-left {
    width: auto;
    cursor: pointer;
  }

  .tp-config-values-menu {
  }
  .tp-config-values-menu li label.tp-menu-item:hover {
    color: ${theme.colors.primary};
  }

  .tp-config-values-content {
  }
  .tp-highlight-searcheable {
    background-color: ${theme.colors.yellowColor};
  }

  .tptable-key-values tr > td {
    border: none;
  }

  .tpAction-panel-sticky {
    margin-top: 14px;
    & li {
      padding: 10px;
      margin-bottom: 12px;

      &:hover {
        // border-left: 5px ${theme.colors.primary} solid;
        cursor: pointer;
        // font-weight: bold;
        // color: ${theme.colors.primary};
        background-color: #FFB8DC;
      }
    }
  }

  .tptimeline {
    border: 2px solid ${theme.colors.darkGray};
    border-radius: 5px;
    margin-top: 8px;
    padding: 3px;
    cursor: pointer;
    padding: 5px;
    width: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    &:hover {
      // box-shadow: 0 4px 4px #4e4c4c;
      border: 2px solid ${theme.colors.primary};
    }
  }
  .workflow-task-show-more-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tptimeline-workflow-task {
    font-size: 8px;
    opacity: 0.7;
  }

  .show-more {
    font-size: 12px;
    font-weight: 600;
  }

  .tptimeline-subcontent-title {
    font-size: 13px;
    fontW-weight: 500;
    margin-bottom: -2px;
    margin-top: 5px !important;
  }
  .tptimeline-task {
    border-left: 1px solid ${theme.colors.darkGray};
    margin-left: 10px;
    width: calc(100% - 50px);
  }

  .timeline-current-task {
    border: 2px solid ${theme.colors.primary};
  }

  .tptimeline-circle-right {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(100% + 21px);
    width: 10px;
    height: 10px;
    border: 1px solid ${theme.colors.primary};
    border-radius: 50%;
    background-color: ${theme.colors.primary};
  }
  .tptimeline-vertical-line {
    position: absolute;
    top: 0;
    left: calc(100% + 20px + 4px);
    width: 1px;
    height: calc(100% + 15px);
    border: 1px solid ${theme.colors.darkGray};
  }
  .tptimeline-horizontal-line {
    position: absolute;
    top: calc(50% - 1px);
    left: calc(100% + 1px);
    width: 20px;
    height: 1px;
    border: 1px solid ${theme.colors.darkGray};
  }

  .tptimeline-task-container {
    width: calc(100% - 250px);
    border: 1px solid red;
  }

  .tptable-report {
    font-size: 13px;
    & thead {
      & tr {
        & th {
          padding: 3px 10px;
          & span {
            white-space: nowrap;
          }
        }
      }
    }
  }
  .tp-rotate180 {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: rotate(180deg);
    }
  }

  .tp-onhovershow {
    i {
      display: none !important;
    }
    &:hover {
      i {
        display: inline !important;
      }
    }
  }
`,
);
