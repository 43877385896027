import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TreeCloneBranchStyles } from "@/components/bootstrap/content/trees/TreeCloneBranchStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPTreeView, {
  TPTreeViewDataModel,
} from "@/components/TPTreeView/TPTreeView";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { BranchService } from "@/services/BranchService";
import { TPI18N } from "@/services/I18nService";
import { TreeService } from "@/services/TreeService";
import { FC, ReactElement, useEffect, useState } from "react";
import TPModalQuestion, {
  TPModalQuestionState,
} from "../ModalQuestion/TPModalQuestion";

export interface TPModalCloneBranchState {
  isShown: boolean;
  branchId: string;
  branchName: string;
}

export interface CloneBranchTree {
  id: string;
  name: string;
  data: TPTreeViewDataModel[];
}

type TPModalCloneBranchProps = {
  acceptLabel: string;
  cancelLabel: string;
  treeSubTitle: string;
  isShown: boolean;
  branchId: string;
  branchName: string;
  treeId: string;
  treeData: TPTreeViewDataModel[];
  callBackAnswer: Function;
  guidTree: string;
};

type BranchClonedObject = {
  branchName: string;
  newBranchName: string;
  treeIdSelected: string;
  cloneTo: string;
  cloneToName: string;
};

type ModalMessage = {
  root: string;
  clone: string;
  close: string;
};

type BranchAction = "root" | "clone" | "close";

export type CloneBranchContract = {
  treeIdSource: string;
  branchIdSource: string;
  treeIdDestination: string;
  branchIdDestination: string;
  branchDescription: string;
  guidUser: string;
};

const TPModalCloneBranch: FC<TPModalCloneBranchProps> = ({
  acceptLabel,
  cancelLabel,
  treeSubTitle,
  isShown,
  callBackAnswer,
  branchId,
  branchName,
  treeId,
  treeData,
  guidTree,
}): ReactElement => {
  // useState variables

  // i18n text resources
  const [cloneBranchTitle, setCloneBranchTitle] = useState("");
  const [confirmBaseMessage, setConfirmBaseMessage] = useState("");
  const [rootConfirmMessage, setRootConfirmMessage] = useState("");
  const [cloneConfirmMessage, setCloneConfirmMessage] = useState("");
  const [rootButtonLabel, setRootButtonLabel] = useState("");
  const [cloneButtonLabel, setCloneButtonLabel] = useState("");
  const [currentBranchLabel, setCurrentBranchLabel] = useState("");
  const [treeSelectionLabel, setTreeSelectionLabel] = useState("");
  const [cloneToLabel, setCloneToLabel] = useState("");
  const [newDescriptionLabel, setNewDescriptionLabel] = useState("");

  // flow clone modal variables
  const [treeList, setTreeList] = useState<TPKeyValue[]>([]);

  const [branchClonedData, setBranchClonedData] = useState(
    {} as BranchClonedObject,
  );

  const [isLoadingScreen, setIsLoadingScreen] = useState(false);

  const [treeCloneData, setTreeCloneData] = useState(
    [] as TPTreeViewDataModel[],
  );
  const [branchNode, setBranchNode] = useState("");
  const [branchLabel, setBranchLabel] = useState("");

  // confirm modal messages
  const baseMessage: string = `${confirmBaseMessage.replace("{0}", branchClonedData.branchName)}`;

  const modalMessages: ModalMessage = {
    root: `${baseMessage} ${rootConfirmMessage.replace("{0}", branchClonedData.treeIdSelected)}`,
    clone: `${baseMessage} ${cloneConfirmMessage.replace("{0}", branchClonedData.cloneToName)}`,
    close: "",
  };

  const [branchActionMessage, setBranchActionMessage] = useState("");

  let confirmModalStatus: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
  };

  const [confirmModal, setConfirmModal] = useState(confirmModalStatus);

  // custom component styles
  const inputStyles = {
    width: "35em",
  };

  const labelStyles = {
    textAlign: "end",
    width: "10em",
    paddingRight: "10px",
  };

  // form components configuration
  const componentsData = [
    {
      type: "TPTextBox",
      id: "current-branch-input",
      value: branchClonedData.branchName,
      onChange: () => {},
      disabled: true,
      isHorizontal: true,
      labelText: currentBranchLabel,
      textStyle: inputStyles,
      labelStyle: labelStyles,
    },
    {
      type: "TPSelect",
      id: "tree-selection",
      value: branchClonedData.treeIdSelected,
      onChange: (e: any) => handleChangeTree(e.target.value),
      labelText: treeSelectionLabel,
      dataSource: treeList,
      isMandatory: false,
      isHorizontal: true,
      selectStyle: inputStyles,
      labelStyle: labelStyles,
    },
    {
      type: "TPTextBox",
      id: "clone-to-input",
      value: branchClonedData.cloneToName,
      onChange: () => {},
      disabled: true,
      isHorizontal: true,
      labelText: cloneToLabel,
      textStyle: inputStyles,
      labelStyle: labelStyles,
    },
    {
      type: "TPTextBox",
      id: "new-description-area",
      value: branchClonedData.newBranchName,
      onChange: (e: any) =>
        updateClonedBranchBy("newBranchName", e.target.value),
      disabled: false,
      maxLength: 500,
      isHorizontal: true,
      labelText: newDescriptionLabel,
      textStyle: inputStyles,
      labelStyle: labelStyles,
    },
  ];

  // buttons component configuration
  const cloneButtonsOpts: any[] = [
    {
      label: cloneButtonLabel,
      type: TPButtonTypes.primary,
      action: "clone",
      isDisabled:
        branchClonedData.cloneTo === "" ||
        branchClonedData.newBranchName === "",
    },
    {
      label: rootButtonLabel,
      type: TPButtonTypes.primary,
      action: "root",
      isDisabled: branchClonedData.newBranchName === "",
    },
    {
      label: cancelLabel,
      type: TPButtonTypes.link,
      action: "close",
      isDisabled: false,
    },
  ];

  // manage modal status by styles
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  if (isShown && confirmModal.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    styleModal["zIndex"] = 1;
    backdropClass = "modal-backdrop show";
  } else if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  // component name variable
  const componentName: string = "TPModalCloneBranch.tsx";

  // get clone modal resources text
  const getResources = async () => {
    setCloneBranchTitle(
      await TPI18N.GetText(getComponentName(), "CloneBranchTitle"),
    );
    setConfirmBaseMessage(
      await TPI18N.GetText(getComponentName(), "ConfirmBaseMessage"),
    );
    setRootConfirmMessage(
      await TPI18N.GetText(getComponentName(), "RootConfirmMessage"),
    );
    setCloneConfirmMessage(
      await TPI18N.GetText(getComponentName(), "CloneConfirmMessage"),
    );
    setRootButtonLabel(
      await TPI18N.GetText(getComponentName(), "RootButtonLabel"),
    );
    setCloneButtonLabel(
      await TPI18N.GetText(getComponentName(), "CloneButtonLabel"),
    );
    setCurrentBranchLabel(
      await TPI18N.GetText(getComponentName(), "CurrentBranchLabel"),
    );
    setTreeSelectionLabel(
      await TPI18N.GetText(getComponentName(), "TreeSelectionLabel"),
    );
    setCloneToLabel(await TPI18N.GetText(getComponentName(), "CloneToLabel"));
    setNewDescriptionLabel(
      await TPI18N.GetText(getComponentName(), "NewDescriptionLabel"),
    );
  };

  // get component name to i18n service
  const getComponentName = () => {
    return componentName.replace(".tsx", "");
  };

  // get list of trees
  const getTreesList = async () => {
    const treeService = new TreeService();
    let expectedCodes: number[] = [200, 404];
    try {
      const responseRequest = await treeService.getTreesByFilter(
        "2",
        false,
        true,
        expectedCodes,
      );
      return [...responseRequest];
    } catch (error) {
      let errorMsg: string = `Error ${componentName} - getTreesList except`;
      TPLog.Log(errorMsg, TPLogType.ERROR, error);
      console.error(errorMsg);
      return [];
    }
  };

  // clone branch function
  const cloneBranch = async (type: BranchAction) => {
    const branchService = new BranchService();
    const { newBranchName, cloneTo, treeIdSelected } = branchClonedData;

    let contract: CloneBranchContract = {
      treeIdSource: treeId,
      branchIdSource: branchId,
      treeIdDestination: treeIdSelected,
      branchIdDestination: type === "root" ? "" : cloneTo,
      branchDescription: newBranchName,
      guidUser: TPGlobal.currentUserGuid,
    };

    closeConfirmModal();
    setIsLoadingScreen(true);

    return await branchService.cloneBranch(contract);
  };

  // define branch function by action
  const callBranchActionBy = (type: BranchAction) => {
    if (type === "close") {
      callBackAnswer(false);
      return;
    }

    handleOpenConfirmModalBy(type);
  };

  // open confirm modal by {type: root | clone}
  const handleOpenConfirmModalBy = (type: BranchAction) => {
    let cloneModal: TPModalQuestionState;
    cloneModal = { ...confirmModal };
    cloneModal.isShown = true;
    cloneModal.callBackData = { type };
    setConfirmModal(cloneModal);
    setBranchActionMessage(modalMessages[type]);
  };

  // after close the confirm modal actions
  const handleCloseConfirmModal = (response: boolean, data: any) => {
    let type: BranchAction = data.type;

    if (!response) {
      setConfirmModal({ ...confirmModal, isShown: false });
      return;
    }

    cloneBranch(type).then((data) => {
      setIsLoadingScreen(false);
      callBackAnswer(false, data.keyList[0], branchClonedData.treeIdSelected);
    });
  };

  // close confirm modal and clean clone data
  const closeConfirmModal = () => {
    setBranchLabel("");
    setBranchNode("");
    setTreeCloneData([]);
    setConfirmModal({ ...confirmModal, isShown: false });
  };

  // update cloned branch data by {key, value}
  const updateClonedBranchBy = (key: string, value: string) => {
    setBranchClonedData({
      ...branchClonedData,
      [key]: value,
    });
  };

  // update destination branch to clone
  const handleChangeBranch = (branch: string, label: string) => {
    setBranchClonedData({
      ...branchClonedData,
      cloneTo: branch,
      cloneToName: label,
    });
  };

  // change tree event by selection
  const handleChangeTree = (treeId: string) => {
    setBranchClonedData({
      ...branchClonedData,
      treeIdSelected: treeId,
      cloneToName: "",
      cloneTo: "",
    });
    setNewTreeBy(treeId);
  };

  // set configuration to the new tree
  const setNewTreeBy = async (treeId: string) => {
    setIsLoadingScreen(true);
    getTreeBy(treeId).then((response) => {
      const treeData: TPTreeViewDataModel[] = response.map(
        (item: BranchViewModel) => {
          return {
            label: item.description,
            id: item.id,
            parentId: item.parentId ?? "",
            hasChild: item.hasChild,
            isExpanded: false,
            isActive: item.isActive,
            isVisible: item.isVisible,
            hierarchyIds: item.hierarchyIds,
            hierarchyDescription: item.hierarchyDescription,
          };
        },
      );
      setBranchLabel("");
      setBranchNode("");
      setTreeCloneData(treeData);
      setIsLoadingScreen(false);
    });
  };

  // get tree by {treeId}
  const getTreeBy = async (treeId: string) => {
    let serviceClient = new BranchService();
    const filter = 2;
    return await serviceClient.getFirstLevelBranches(
      treeId,
      filter,
      filter,
      false,
      true,
      [200, 404],
    );
  };

  // after modal open get all trees with currently tree selected
  useEffect(() => {
    if (isShown) {
      setIsLoadingScreen(true);
      getTreesList().then((response) => {
        let treeListConverted: TPKeyValue[] = response.map(
          (item: TreeViewModel) => {
            return {
              key: item.id,
              value: item.name,
            };
          },
        );

        setTreeList(treeListConverted);

        setBranchClonedData({
          branchName: branchName,
          newBranchName: `${branchName}`,
          treeIdSelected: treeId,
          cloneTo: "",
          cloneToName: "",
        });

        setIsLoadingScreen(false);
      });
    }
  }, [branchName, isShown, treeId]);

  // after modal open get current tree data
  useEffect(() => {
    if (treeData) {
      setBranchLabel("");
      setBranchNode("");
      setTreeCloneData(treeData);
    }
  }, [treeData, isShown]);

  // once modal is rendered get necessary resources
  useEffect(() => {
    getResources();
  });

  return (
    <>
      <TPModalQuestion
        id="confirm-clone-branch-md"
        title={cloneBranchTitle}
        yesLabel={acceptLabel}
        noLabel={cancelLabel}
        question={branchActionMessage}
        callBackData={confirmModal.callBackData}
        isShown={confirmModal.isShown}
        callBackAnswer={(response: boolean, data: any) =>
          handleCloseConfirmModal(response, data)
        }
      ></TPModalQuestion>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <header className="modal-header justify-content-between">
              <h5 className="modal-title">{cloneBranchTitle}</h5>
              <button
                id="close-clone-branch-modal-btn"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => callBackAnswer(false)}
              ></button>
            </header>
            <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
              <section className="modal-body d-flex gap-4 w-100">
                <TreeCloneBranchStyles>
                  <div className="tree-clone">
                    <p>
                      {treeSubTitle} {branchClonedData.treeIdSelected}
                    </p>
                    <TPTreeView
                      setIsLoading={setIsLoadingScreen}
                      treeId={branchClonedData.treeIdSelected}
                      dataSource={treeCloneData}
                      setDataSource={setTreeCloneData}
                      selectedNode={branchNode}
                      setSelectedNode={setBranchNode}
                      selectedLabel={branchLabel}
                      setSelectedLabel={setBranchLabel}
                      selectedHierarchyLabel={""}
                      setSelectedHierarchyLabel={() => {}}
                      renderParent={""}
                      handleChangeNodeCallBack={(
                        branch: string,
                        label: string,
                      ) => {
                        handleChangeBranch(branch, label);
                      }}
                      filterIsActive={2}
                      filterIsVisible={2}
                      guidTree={guidTree}
                      isAdminMode={true}
                      mustSelectLastLevelBranch={false}
                    />
                  </div>
                </TreeCloneBranchStyles>
                <div className="d-flex flex-column align-items-center gap-3">
                  {componentsData.map((component, index) => {
                    if (component.type === "TPTextBox") {
                      return (
                        <TPTextBox
                          key={index}
                          id={component.id}
                          isMandatory={index === 3}
                          value={component.value ?? ""}
                          onChange={component.onChange}
                          disabled={component.disabled}
                          isHorizontal={component.isHorizontal}
                          labelText={component.labelText}
                          textStyle={component.textStyle}
                          labelStyle={component.labelStyle}
                          maxLength={component.maxLength}
                        />
                      );
                    } else if (component.type === "TPSelect") {
                      return (
                        <TPSelect
                          key={index}
                          id={component.id}
                          value={component.value}
                          onChange={component.onChange}
                          labelText={component.labelText}
                          dataSource={component.dataSource ?? []}
                          isMandatory={component.isMandatory}
                          isHorizontal={component.isHorizontal}
                          selectStyle={component.selectStyle}
                          labelStyle={component.labelStyle}
                        />
                      );
                    }
                    return null;
                  })}
                  <div className="d-flex" style={{ gap: "1em" }}>
                    {cloneButtonsOpts.map((item, index) => (
                      <TPButton
                        key={index}
                        id={`branch-${item.action}-md`}
                        type={item.type}
                        onClick={() => callBranchActionBy(item.action)}
                        disabled={item.isDisabled}
                      >
                        {item.label}
                      </TPButton>
                    ))}
                  </div>
                </div>
              </section>
            </TPLoadingOverlay>
          </div>
        </div>
      </div>
      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalCloneBranch;
