import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FC } from "react";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import "./Style.css";

interface Properties {
  icon?: TPIconTypes;
  label?: string | number;
  backgroundColor?: string;
  accentColor?: string;
  iconColor?: string;
}

export const TPChip: FC<Properties> = function ({
  icon,
  label,
  backgroundColor,
  iconColor,
  accentColor,
}) {
  return (
    <div
      className="tp-chip"
      style={{
        backgroundColor: backgroundColor || "#f0f0f0",
        color: accentColor,
      }}
    >
      {icon && (
        <TPIcon iconType={icon} style={{ color: iconColor || "#a0a0a0" }} />
      )}
      {label}
    </div>
  );
};
