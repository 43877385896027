import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import {
  ExpandableColumn,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  ConnectionProtocol,
  IMAPFolder,
  MailboxType,
  SecurityType,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { InboundMailAzureApplicationViewModel } from "@/models/InboundMailAzureApplication/InboundMailAzureApplicationViewModel";
import { InboundMailboxesViewModel } from "@/models/InboundMailboxes/InboundMailboxesViewModel";
import { TPI18N } from "@/services/I18nService";
import { InboundMailAzureApplicationService } from "@/services/InboundMailAzureApplicationService";
import { InboundMailboxesRulesService } from "@/services/InboundMailboxesRulesService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import * as XLSX from "xlsx";
import RuleApplied, { RuleToString } from "../InboundMailboxes/RuleApplied";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<InboundMailboxesViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface InboundMailboxesRulesAdminInterface {
  callBackCommands: Function;
}

interface Row {
  id: string;
  description: string;
  webServiceCLassifierId: string;
  actionType: string;
  isActive: boolean;
  isSystemRecord: boolean;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const InboundMailboxesRulesAdmin = React.forwardRef(
  ({ callBackCommands }: InboundMailboxesRulesAdminInterface, ref) => {
    //Groups called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand(true);
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    // //Screen resources
    const componentFileName: string = "InboundMailboxesRulesAdmin.tsx";
    const resourceSet: string = "InboundMailboxesRulesAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [nameColumnLabel, setNameColumnLabel] = useState("");
    const [actionTypeColumnLabel, setActionTypeColumnLabel] = useState("");
    const [webServiceCLassifierId, setWebServiceCLassifierId] = useState("");
    const [ruleDefinitionColumnLabel, setRuleDefinitionColumnLabel] =
      useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemRecordColumnLabel] =
      useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //Value resources
    const [otherMailboxTypeValue, setOtherMailboxTypeValue] = useState("");
    const [basicMailboxTypeValue, setBasicMailboxTypeValue] = useState("");
    const [exchangeOnlineMailboxTypeValue, setExchangeOnlineMailboxTypeValue] =
      useState("");
    const [pop3ConnectionProtocolValue, setPop3ConnectionProtocolValue] =
      useState("");
    const [imapConnectionProtocolValue, setImapConnectionProtocolValue] =
      useState("");
    const [noneSecurityTypeValue, setNoneSecurityTypeValue] = useState("");
    const [autoSecurityTypeValue, setAutoSecurityTypeValue] = useState("");
    const [sslOnConnectSecurityTypeValue, setSslOnConnectSecurityTypeValue] =
      useState("");
    const [startTlsSecurityTypeValue, setStartTlsSecurityTypeValue] =
      useState("");
    const [
      startTlsWhenAvailableSecurityTypeValue,
      setStartTlsWhenAvailableSecurityTypeValue,
    ] = useState("");
    const [flaggedIMAPFolderValue, setFlaggedIMAPFolderValue] = useState("");
    const [draftsIMAPFolderValue, setDraftsIMAPFolderValue] = useState("");
    const [trashIMAPFolderValue, setTrashIMAPFolderValue] = useState("");
    const [inboxIMAPFolderValue, setInboxIMAPFolderValue] = useState("");
    const [junkIMAPFolderValue, setJunkIMAPFolderValue] = useState("");
    const [sentIMAPFolderValue, setSentIMAPFolderValue] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );

      //Value resources
      setOtherMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "OtherMailboxTypeValue"),
      );
      setBasicMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "BasicMailboxTypeValue"),
      );
      setExchangeOnlineMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "ExchangeOnlineMailboxTypeValue"),
      );
      setPop3ConnectionProtocolValue(
        await TPI18N.GetText(resourceSet, "Pop3ConnectionProtocolValue"),
      );
      setImapConnectionProtocolValue(
        await TPI18N.GetText(resourceSet, "ImapConnectionProtocolValue"),
      );
      setNoneSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "NoneSecurityTypeValue"),
      );
      setAutoSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "AutoSecurityTypeValue"),
      );
      setSslOnConnectSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "SslOnConnectSecurityTypeValue"),
      );
      setStartTlsSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "StartTlsSecurityTypeValue"),
      );
      setStartTlsWhenAvailableSecurityTypeValue(
        await TPI18N.GetText(
          resourceSet,
          "StartTlsWhenAvailableSecurityTypeValue",
        ),
      );
      setFlaggedIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "FlaggedIMAPFolderValue"),
      );
      setDraftsIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "DraftsIMAPFolderValue"),
      );
      setTrashIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "TrashIMAPFolderValue"),
      );
      setInboxIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "InboxIMAPFolderValue"),
      );
      setJunkIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "JunkIMAPFolderValue"),
      );
      setSentIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "SentIMAPFolderValue"),
      );

      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "TableId"));
      setNameColumnLabel(await TPI18N.GetText(resourceSet, "TableDescLabel"));
      setActionTypeColumnLabel(
        await TPI18N.GetText(resourceSet, "TableActionLabel"),
      );
      setWebServiceCLassifierId(
        await TPI18N.GetText(resourceSet, "TableClassifierLabel"),
      );
      setRuleDefinitionColumnLabel(
        await TPI18N.GetText(resourceSet, "TableRuleDefinitionLabel"),
      );
      setIsActiveColumnLabel(
        await TPI18N.GetText(resourceSet, "TableActiveLabel"),
      );
      setIsSystemRecordColumnLabel(
        await TPI18N.GetText(resourceSet, "TableIsSystemRecord"),
      );

      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const getConnectionTypeValue = (value: any) => {
      switch (value) {
        case ConnectionProtocol.POP3:
          return pop3ConnectionProtocolValue;

        case ConnectionProtocol.IMAP:
          return imapConnectionProtocolValue;

        default:
          return "";
      }
    };

    const getSecurityTypeValue = (value: any) => {
      switch (value.toString()) {
        case SecurityType.None.toString():
          return noneSecurityTypeValue;

        case SecurityType.Auto.toString():
          return autoSecurityTypeValue;

        case SecurityType.SslOnConnect.toString():
          return sslOnConnectSecurityTypeValue;

        case SecurityType.StartTls.toString():
          return startTlsSecurityTypeValue;

        case SecurityType.StartTlsWhenAvailable.toString():
          return startTlsWhenAvailableSecurityTypeValue;

        default:
          return "";
      }
    };

    const getMailboxTypeValue = (value: any) => {
      switch (value) {
        case MailboxType.BASIC:
          return basicMailboxTypeValue;

        // case MailboxType.OTHER:
        //   return otherMailboxTypeValue;

        case MailboxType.EXCHANGEONLINE:
          return exchangeOnlineMailboxTypeValue;

        default:
          return "";
      }
    };

    const getIMAPFolderValue = (value: any) => {
      switch (value) {
        case IMAPFolder.Flagged:
          return flaggedIMAPFolderValue;

        case IMAPFolder.Drafts:
          return draftsIMAPFolderValue;

        case IMAPFolder.Trash:
          return trashIMAPFolderValue;

        case IMAPFolder.Inbox:
          return inboxIMAPFolderValue;

        case IMAPFolder.Junk:
          return junkIMAPFolderValue;

        case IMAPFolder.Sent:
          return sentIMAPFolderValue;

        default:
          return "";
      }
    };

    const getInboundMailAzureApplicationById = async (
      idInboundMailAzureApp: string,
    ) => {
      let serviceClient = new InboundMailAzureApplicationService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest =
          await serviceClient.getInboundMailAzureApplicationById(
            idInboundMailAzureApp,
            false,
            true,
            expectedCodes,
          );
        let recordInfo: InboundMailAzureApplicationViewModel;
        recordInfo = { ...responseRequest };

        return recordInfo;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailAzureApplicationById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getInboundMailAzureApplicationById ex`,
        );
        return null;
      }
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        //delete
        newColumns.push({
          name: "",
          width: "50px",
          style: { padding: 0 },
          center: true,
          cell: (row: { [x: string]: any }) => (
            <div className="dropdown">
              <TPButton
                dataBsToggle={true}
                type={TPButtonTypes.empty}
                onClick={() => {
                  TPGlobal.foo();
                }}
                className={"menu-button"}
              >
                <TPIcon iconType={TPIconTypes.moreVert} />
              </TPButton>
              <ul className="dropdown-menu">
                <li>
                  <a
                    onClick={(id: any) =>
                      handleDeleteClick(row["id"].toString())
                    }
                    className="dropdown-item"
                    href="#"
                  >
                    {deleteLabel}
                  </a>
                </li>
                {!row["isSystemRecord"] && (
                  <>
                    <li>
                      <a
                        onClick={(id: any) =>
                          handleUpdateClick(row["id"].toString())
                        }
                        className="dropdown-item"
                        href="#"
                      >
                        {updateLabel}
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          ),
        });
        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) =>
            !row["isSystemRecord"] && (
              <TPButton
                type={TPButtonTypes.primary}
                onClick={(id: string) =>
                  handleUpdateClick(row["id"].toString())
                }
                className="update-button"
              >
                <TPIcon iconType={TPIconTypes.chevronRight} />
              </TPButton>
            ),
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });

        //id
        newColumns.push({
          name: idColumnLabel,
          cell: (row: { [x: string]: any }) => row["id"],
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });

        //description
        newColumns.push({
          width: "150px",
          name: nameColumnLabel,
          cell: (row: { [x: string]: any }) => row["description"],
          selector: (row: { [x: string]: any }) => row["description"],
          sortable: true,
        });

        //actionType
        newColumns.push({
          name: actionTypeColumnLabel,
          cell: (row: { [x: string]: any }) => (
            <TPLanguage
              resourceSet={TPGlobal.globalResourceSet}
              resourceId={`ACTION${row["actionType"]}`}
            />
          ),
          selector: (row: { [x: string]: any }) => row["actionType"],
          sortable: true,
        });

        //webClassifierLocalizedDescription
        newColumns.push({
          width: "150px",
          name: webServiceCLassifierId,
          cell: (row: { [x: string]: any }) =>
            row["webClassifierLocalizedDescription"],
          selector: (row: { [x: string]: any }) =>
            row["webClassifierLocalizedDescription"],
          sortable: true,
        });

        //jsonRule
        newColumns.push({
          width: "500px",
          name: ruleDefinitionColumnLabel,
          cell: (row: { [x: string]: any }) => (
            <>
              {/* <p className="me-2">{[...JSON.parse(row["jsonRule"])].length})</p> */}
              <RuleApplied inboundMailRuleJson={JSON.parse(row["jsonRule"])} />
            </>
          ),
          selector: (row: { [x: string]: any }) => row["jsonRule"],
          sortable: true,
        });

        //isActive
        newColumns.push({
          name: isActiveColumnLabel,
          selector: (row: { [x: string]: any }) => row["isActive"],
          width: "100px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isActive"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        //IsSystemRecord
        newColumns.push({
          name: isSystemRecordColumnLabel,
          selector: (row: { [x: string]: any }) => row["isSystemRecord"],
          width: "100px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isSystemRecord"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    const handleLoginClick = async (
      idInboundMailbox: string,
      authenticationAzureAppId: string,
    ) => {
      let inboundMailAzureApp: InboundMailAzureApplicationViewModel | null;

      inboundMailAzureApp = await getInboundMailAzureApplicationById(
        authenticationAzureAppId,
      );

      window.open(
        `${inboundMailAzureApp?.authenticationURL}tenantIdHex=${TPGlobal.tenantHex}&inboundMailboxId=${idInboundMailbox}`,
        "loginAuthentication",
        "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=300,left=100,top=100",
      );
    };

    //Get groups by Filter
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new InboundMailboxesRulesService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getInboundMailboxesByFilter(
          selectedFilter,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);

        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = (refresh: boolean = false) => {
      if (refresh) {
        let command1: commandType = {
          type: commandsEnum.reload_grid,
          payload: [],
        };
        dispatchCommand(command1);
      }
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Group
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand(true);
    };

    //Update
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Modal Question to delete
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete Group after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new InboundMailboxesRulesService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteInboundMailboxById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = ["id", "description"];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search === "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemValue: any;
          let itemany: any;
          itemany = item;
          if (searcheableColumns[i].split(".").length === 2) {
            let itemSub: any = searcheableColumns[i].split(".");
            itemValue = itemany[itemSub[0]][itemSub[1]];
          } else {
            itemValue = itemany[searcheableColumns[i]];
          }

          if (
            itemValue &&
            itemValue.toString().toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = async (
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const apiData = await dataDownloadFormat();
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    const dataDownloadFormat = async () => {
      const data = filteredData();
      let newData: any = [];
      await Promise.all(
        data.map(async (item: any) => {
          let rule = await RuleToString(JSON.parse(item.jsonRule));
          newData.push({
            [idColumnLabel]: item.id,
            [nameColumnLabel]: item.localizedDescription.replaceAll(",", "-"),
            [actionTypeColumnLabel]: item.actionType,
            [webServiceCLassifierId]: item.webClassifierLocalizedDescription,
            [ruleDefinitionColumnLabel]: rule,
          });
        }),
      );
      return newData;
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
          break;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
          break;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
          break;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
          break;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: [],
          };
          dispatchCommand(command1);
          setIsLoadingScreen(false);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} loadResourcesAndFilter ex`);
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    // data provides access to your row data
    const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({
      data,
    }) => {
      return (
        <ExpandableColumn>
          <span>{data.id}</span>
          <span>{data.description}</span>
          <span>{data.webServiceCLassifierId}</span>
          <span>{data.actionType}</span>
          <span>{data.isActive.toString()}</span>
          <span>{data.isSystemRecord.toString()}</span>
          <span>{data.localizedDescription}</span>
        </ExpandableColumn>
      );
    };

    return (
      <>
        <TPModalQuestion
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <TPPageFirstRow>
                <TPPageActions>
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewClick()}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    // onClick={async () => { await dataDownloadCSV() }}
                    onClick={() => exportToCSV("inbound-mail-rules-admin-data")}
                    text={exportLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                </TPPageActions>
                <TPFilterAndSearch>
                  <TPPageFilterContainer>
                    <TPSelect
                      id="IdSelect"
                      onChange={handleFilterChange}
                      dataSource={filterKeyValue}
                      value={adminState.selectedFilter}
                      labelText={filterIsActiveLabel}
                      isHorizontal={true}
                    ></TPSelect>
                  </TPPageFilterContainer>
                  <TPPageSearchContainer>
                    <TPTextBox
                      id="IdTextBox"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);

export default InboundMailboxesRulesAdmin;
