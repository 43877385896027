export const copyToClipboard = function (text: string): void {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied!");
    })
    .catch((error) => {
      console.log(
        "Unexpected error while trying to copy to the clipboard: ",
        error,
      );
    });
};
