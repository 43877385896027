import { TPI18N } from "@/services/I18nService";
import { ReactElement, useEffect, useState } from "react";
import ClassicPager from "../../design-system/pagers/ClassicPager";
import DefaultPager from "../../design-system/pagers/DefaultPager";
import { StyledPager } from "./dynamic-table-styles";

type TablePagerProps = {
	id?: string;
	page: number;
	totalPages: number;
	resultsPerPage: number;
	allResults: number;
	selectedResults: number;
	rowsPerPage: number;
	ofLabel: string;
	pageLabel: string;
	allLabel: string;
	nextPageLabel: string;
	pagerStyle?: 'classic' | 'default';
	onRowsPerPageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	onPagerChange: (value: number) => void;
	customRowsPerPageOptions?: Array<number>;
};

function TablePager({
	id,
	page,
	totalPages,
	resultsPerPage,
	allResults,
	selectedResults,
	rowsPerPage,
	ofLabel,
	pageLabel,
	nextPageLabel,
	allLabel,
	pagerStyle = 'default',
	onRowsPerPageChange,
	onPagerChange,
	customRowsPerPageOptions
}: TablePagerProps): ReactElement {
	const rowsPerPageOptions = customRowsPerPageOptions ?? [10, 20, 30, 50, -1];

  const [selectedRowsLabel, setSelectedRowsLabel] = useState<string>("");
  const [reviewingLabel, setReviewingLabel] = useState<string>("");
  const [itemPerPageLabel, setItemPerPageLabel] = useState<string>("");
  const [previousLabel, setPreviousLabel] = useState<string>("");
  const [nextLabel, setNextLabel] = useState<string>("");

  const reviewingLabelParts = reviewingLabel.split("#");

  const getSelectId = () => {
    return `${id && `${id}-`}pager-selection`;
  };

  const getOptionId = (option: number) => {
    return `${option === -1 ? "all-" : `${option}-`}pager-option`;
  };

  const loadUtilsResources = async () => {
    setSelectedRowsLabel(
      await TPI18N.GetText("CompletePendingTasksComponent", "SelectedRowsLabel")
    );
    setReviewingLabel(
      await TPI18N.GetText("TPPaginator", "YouAreViewingLabel")
    );
    setItemPerPageLabel(
      await TPI18N.GetText("TPPaginator", "ItemsPerPageLabel")
    );
    setPreviousLabel(
      await TPI18N.GetText("TPModalPDFViewerComponent", "PreviousLabel")
    );
    setNextLabel(
      await TPI18N.GetText("TPModalPDFViewerComponent", "NextLabel")
    );
  };

  useEffect(() => {
    loadUtilsResources().then();
  }, []);

  return (
    <StyledPager selection={selectedResults}>
      <p className="selected-rows">
        <strong>{selectedResults}</strong> {selectedRowsLabel.toLowerCase()}
      </p>

      <section className="pager-content">
        <p className="rows-selected">
          {reviewingLabelParts[0]} <strong>{resultsPerPage}</strong>{" "}
          {reviewingLabelParts[1]} <strong>{allResults}</strong>
        </p>

        <div className="select-page">
          <p>{itemPerPageLabel}:</p>
          <select
            id={getSelectId()}
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
          >
            {rowsPerPageOptions.map((option) => (
              <option id={getOptionId(option)} key={option} value={option}>
                {option === -1 ? allLabel : option}
              </option>
            ))}
          </select>
        </div>
        {pagerStyle === "classic" ? (
          <ClassicPager
            currentPage={page}
            totalPages={totalPages}
            nextPageLabel={nextLabel}
            previousLabel={previousLabel}
            pageLabel={pageLabel}
            ofLabel={ofLabel}
            onPageChange={onPagerChange}
          />
        ) : (
          <DefaultPager
            currentPage={page}
            totalPages={totalPages}
            nextPageLabel={nextPageLabel}
            previousLabel={previousLabel}
            onPageChange={onPagerChange}
          />
        )}
      </section>
    </StyledPager>
  );
}

export default TablePager;
