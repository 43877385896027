import TPGlobal from "@/helpers/TPGlobal";
import { notificationCenterItem } from "@/models/NotificationCenter/NotificationCenterModel";
import React, { ReactElement } from "react";
import { ToastContainer, toast } from "react-toastify";

export enum TPToastTypes {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
}

export const showToast = (message: string, toastType: TPToastTypes) => {
  toast(message, { type: toastType });
};

const TPToast = (): ReactElement => {
  return <ToastContainer />;
};

export default TPToast;
