import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { AttachmentHistoryViewModel } from "@/models/Attachments/AttachmentHistoryViewModel";
import {
  ClientAdminViewModel,
  ClientePreferredAgentsModel,
} from "@/models/Client/ClientAdminViewModel";
import { ClientBasicViewModel } from "@/models/Client/ClientBasicViewModel";
import { ClientNewCustomerInputDTO } from "@/models/Client/ClientNewCustomerInputDTO";

export class ClientService {
  serviceFileName: string = "ClientService.ts";

  public async getClientByEmailBasic(
    email: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<ClientBasicViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/client/emailbasic?";
    url = url + "email=" + email;
    //todo validar
    url = url + "&restrictProcessingFilter=2"; //

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getClientByEmailBasic ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getClientByEmailBasic`);
      throw new Error(`Error ${this.serviceFileName} getClientByEmailBasic`);
    }
  }
  public async getClientByDocumentBasic(
    document: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<ClientBasicViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/client/documentbasic?";
    url = url + "document=" + document;
    //todo validar
    url = url + "&restrictProcessingFilter=2"; //

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getClientByDocumentBasic ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getClientByDocumentBasic`);
      throw new Error(`Error ${this.serviceFileName} getClientByDocumentBasic`);
    }
  }
  public async getClientByPhoneBasic(
    phone: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<ClientBasicViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/client/phonebasic?";
    url = url + "phone=" + phone;
    //todo validar
    url = url + "&restrictProcessingFilter=2"; //

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getClientByPhoneBasic ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getClientByPhoneBasic`);
      throw new Error(`Error ${this.serviceFileName} getClientByPhoneBasic`);
    }
  }
  public async getClientByNamesBasic(
    name: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<ClientBasicViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/client/namebasic?";
    url = url + "name=" + name;
    //todo validar
    url = url + "&restrictProcessingFilter=2"; //

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getClientByNamesBasic ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getClientByNamesBasic`);
      throw new Error(`Error ${this.serviceFileName} getClientByNamesBasic`);
    }
  }
  public async getClientByInternalCodeBasic(
    internalCode: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<ClientBasicViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/client/internalcodebasic?";
    url = url + "internalCode=" + internalCode;
    //todo validar
    url = url + "&restrictProcessingFilter=2"; //

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getClientByInternalCodeBasic ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getClientByInternalCodeBasic`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getClientByInternalCodeBasic`,
      );
    }
  }
  public async getClientBySubsidiaryOrganizationId(
    idSubOrg: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<ClientAdminViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/client/subsidiary-organization/" + idSubOrg.toString();

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getClientBySubsidiaryOrganizationId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${this.serviceFileName} getClientBySubsidiaryOrganizationId`,
      );
      throw new Error(
        `Error ${this.serviceFileName} getClientBySubsidiaryOrganizationId`,
      );
    }
  }
  public async getAttachmentsClient(
    clientId: number,
    userGuid: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<AttachmentHistoryViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/attachment/history/client";
    url = url + "?clientId=" + clientId.toString();
    url = url + "&userGuid=" + userGuid;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getAttachmentsClient ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getAttachmentsClient`);
      throw new Error(`Error ${this.serviceFileName} getAttachmentsClient`);
    }
  }
  public async insertNewClient(
    inputDTO: ClientNewCustomerInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/client/new-customer";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertNewClient ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertNewClient`);
      throw new Error(`Error ${this.serviceFileName} insertNewClient`);
    }
  }
  public async updateClient(
    inputDTO: ClientNewCustomerInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/client/update-customer";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateClient ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateClient`);
      throw new Error(`Error ${this.serviceFileName} updateClient`);
    }
  }
  public static async getPreferredAgents(
    subsidiaryId: number,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<ClientePreferredAgentsModel>> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/customerpreferredagent/subsidiary/" +
      String(subsidiaryId);
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to get TPGenAI response: ${error}`,
      );
    }
  }
}
