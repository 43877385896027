import allThemes from "@/assets/styles/theme";
import "@/components/TPGenAIMailKeyPoints/Styles.css";
import { copyToClipboard } from "@/helpers/ClipboardManager";
import TPGlobal from "@/helpers/TPGlobal";
import {
  TPGenAIKeyPointsRequest,
  TPGenAIKeyPointsResponse,
  TPGenAIKeyPointsUpdateModel,
} from "@/models/TPGenAI/TPGenAIModel";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { TPGenAIService } from "@/services/TPGenAIService";
import { Icon } from "@iconify/react";
import { FC, useEffect, useState } from "react";
import TPCheckBox from "../bootstrap/forms/checkbox/TPCheckBox";

const resourceSet: string = "CaseViewerComponent";
const loadingLabel: string = "...";
const insertionType: string = "NEWCASE";

enum TrackingValues {
  mail2keypoints = "mail2keypoints",
  mail2summary = "mail2summary",
}

interface Properties {
  caseId: number;
  type: string;
  dataCallback?: any;
}

const TPMailKeypoints: FC<Properties> = function ({
  caseId,
  type = "inbound",
  dataCallback,
}) {
  const [currentCaseId] = useState<number>(caseId || 0);
  const [inboundMail, setInboundMail] = useState<any | null>(null);
  const [originalEmail, setOriginalEmail] = useState<string>("");
  const [AIEnabled, setAIEnabled] = useState(false);
  const [keyPoints, setKeyPoints] = useState<string[]>([]);
  const [canProcess, setCanProcess] = useState<boolean>(false);
  const [customKeypointsInput, setCustomKeyPointInput] = useState<string[]>([]);
  const [overwriteOnSubmit, setOverwriteOnSubmit] = useState<boolean>(false);
  const [languageDriver, setLanguageDriver] = useState<{
    languageName: string;
    languageCode: string;
  } | null>(TPGlobal.TPGenAIAvailableLanguages[0]);
  const [overwriteKeyPointsLabel, setOverwriteKeyPointsLabel] =
    useState<string>(loadingLabel);
  const [languageDriverLabel, setLanguageDriverLabel] =
    useState<string>(loadingLabel);
  const [processButtonLabel, setProcessButtonLabel] =
    useState<string>(loadingLabel);

  const loadLabels = async function () {
    setOverwriteKeyPointsLabel(
      await TPI18N.GetText(resourceSet, "OverwriteKeyPointsLabel"),
    );
    setLanguageDriverLabel(
      await TPI18N.GetText(resourceSet, "LanguageDriverLabel"),
    );
    setProcessButtonLabel(
      await TPI18N.GetText(resourceSet, "ProcessButtonLabel"),
    );
  };

  const handleCustomKeyPointInput = function (text: string) {
    const processedKeyPoints = text
      .split("\n")
      .map((keyPoint) => (keyPoint.at(0) != "-" ? "- " + keyPoint : keyPoint));
    setCustomKeyPointInput(processedKeyPoints);
  };

  const processNewKeyPoints = function (
    language?: string,
    overwrite?: boolean,
  ): void {
    if (AIEnabled && inboundMail && type == "inbound") {
      if (inboundMail.tpGenAIKeyPoints && !overwrite) {
        setKeyPoints(String(inboundMail.tpGenAIKeyPoints).split("\n"));
        return;
      }
      setCanProcess(false);
      const tpGenAiServiceInstance = new TPGenAIService();
      const processRequest: TPGenAIKeyPointsRequest = {
        Keypoints: "-",
        Language: language || TPGlobal.language,
        Email: originalEmail,
      };
      tpGenAiServiceInstance
        .processMailToKeyPoints(processRequest, [200], true, true)
        .then((response: TPGenAIKeyPointsResponse) => {
          setKeyPoints(response.items || []);
          setCanProcess(true);
          tpGenAiServiceInstance.insertTracking(
            {
              CaseId: caseId,
              Guid_USER: TPGlobal.currentUserGuid,
              InboundMailBoxReadId: inboundMail.id,
              JsonResult: JSON.stringify(response),
              LanguageTo: language || TPGlobal.language,
              ResultType: TrackingValues.mail2keypoints,
              TaskId: inboundMail.taskId,
            },
            [200],
            false,
            false,
          );
          if (!inboundMail.tpGenAIKeyPoints || overwriteOnSubmit)
            updateMailboxKeyPoints(language, response.items.join("\n"));
        })
        .catch((error) => {
          console.error(error);
          setCanProcess(true);
        });
    }
  };

  const processEmailSummary = async function (language?: string) {
    if (AIEnabled && inboundMail && dataCallback && type == "outbound") {
      setCanProcess(false);
      const tpGenAiServiceInstance = new TPGenAIService();
      tpGenAiServiceInstance
        .keyPointsToMail(
          {
            keypoints: customKeypointsInput.join("\n"),
            email: inboundMail.plainTextBody,
            language: language || TPGlobal.language,
          },
          [200],
          true,
          true,
        )
        .then((response) => {
          if (response) {
            dataCallback(response.output);
            setCanProcess(true);
            tpGenAiServiceInstance.insertTracking(
              {
                CaseId: caseId,
                Guid_USER: TPGlobal.currentUserGuid,
                InboundMailBoxReadId: inboundMail.id,
                JsonResult: JSON.stringify(response),
                LanguageTo: language || TPGlobal.language,
                ResultType: TrackingValues.mail2summary,
                TaskId: inboundMail.taskId,
              },
              [200],
              false,
              false,
            );

            if (overwriteOnSubmit)
              updateMailboxKeyPoints(language, customKeypointsInput.join("\n"));
          }
        })
        .catch((e) => {
          console.error(e);
          setCanProcess(true);
        });
    }
  };

  const updateMailboxKeyPoints = function (
    language?: string,
    stagedKeyPoints?: string,
  ): void {
    if (AIEnabled && inboundMail) {
      const tpGenAiServiceInstance = new TPGenAIService();
      const updateKeyPointsBody: TPGenAIKeyPointsUpdateModel = {
        Id: Number(inboundMail.id),
        LanguageTo: language || TPGlobal.language,
        Value: stagedKeyPoints || inboundMail.tpGenAIKeyPoints,
      };
      tpGenAiServiceInstance.updateMailKeypoints(
        updateKeyPointsBody,
        [200],
        false,
        false,
      );
    }
  };

  useEffect(() => {
    if (currentCaseId) {
      const taskServiceInstance = new TaskService();
      taskServiceInstance
        .getInboundMailboxReadByCaseId(currentCaseId, false, false, [200])
        .then((response) => {
          const mailboxRead = response.find(
            (item: { insertionType: string; taskId: string }) =>
              item.insertionType === insertionType,
          );
          if (mailboxRead) {
            setInboundMail(mailboxRead);
            setOriginalEmail(mailboxRead.plainTextBody);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      loadLabels();
    }
  }, [currentCaseId]);

  useEffect(() => {
    if (originalEmail) {
      setAIEnabled(true);
      setCanProcess(true);
    }
  }, [originalEmail]);

  useEffect(() => {
    if (type == "inbound") processNewKeyPoints(TPGlobal.language, false);
  }, [AIEnabled, inboundMail]);

  return (
    <>
      <div className="main-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            alignItems: "flex-start",
          }}
        >
          <div
            className="key-points-container"
            style={allThemes.base.highlight}
          >
            {type == "inbound" && (
              <ul>
                {keyPoints.length > 0 &&
                  keyPoints.map((keyPoint) => <li>{keyPoint}</li>)}
              </ul>
            )}
            {type == "outbound" && (
              <textarea
                value={customKeypointsInput.join("\n")}
                className="key-points-input"
                onChange={(event) =>
                  handleCustomKeyPointInput(event.target.value)
                }
              />
            )}
          </div>
          <button
            type="button"
            className="copy-button"
            onClick={() => {
              type == "outbound"
                ? copyToClipboard(customKeypointsInput.join("\n"))
                : copyToClipboard(keyPoints.join("\n"));
            }}
          >
            <Icon icon="iconamoon:copy-bold" />
          </button>
        </div>
        <div className="overwrite-options">
          <TPCheckBox
            checked={overwriteOnSubmit}
            onChange={() => setOverwriteOnSubmit(!overwriteOnSubmit)}
          />
          <div>{overwriteKeyPointsLabel}</div>
        </div>
        <div className="language-driver-options">
          <label>
            {languageDriverLabel} <label style={{ color: "red" }}>*</label>
          </label>
          <div className="language-driver-select-container">
            <select
              className="language-driver-select"
              value={languageDriver?.languageCode || "N/A"}
              onChange={(event) => {
                setLanguageDriver(
                  TPGlobal.TPGenAIAvailableLanguages.find(
                    (lang) => lang.languageCode === event.target.value,
                  ) || null,
                );
              }}
            >
              {TPGlobal.TPGenAIAvailableLanguages.map((language) => (
                <option
                  key={language.languageCode}
                  value={language.languageCode}
                >
                  {language.languageName}
                </option>
              ))}
            </select>
            <button
              disabled={!canProcess}
              type="submit"
              className="process-button"
              style={{
                backgroundColor: canProcess
                  ? allThemes.base.button.primary.backgroundColor
                  : allThemes.base.button.disabled.backgroundColor,
              }}
              onClick={() => {
                type == "outbound"
                  ? processEmailSummary(languageDriver?.languageCode)
                  : processNewKeyPoints(languageDriver?.languageCode, true);
              }}
            >
              {processButtonLabel}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TPMailKeypoints;
