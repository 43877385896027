import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CustomToolbar } from "./CustomToolbar";

export const TPEditor = ({
  id,
  value,
  onChange,
  popUpOpen,
  referece,
  initialValue,
  isVisible,
  placeholder,
}: any) => {
  const random = Math.floor(Math.random() * 999);
  const toolbarId = "toolbar" + random;
  const editorId = `${id && `${id}-`}text-editor`;

  const [toolbarNode, setToolbarNode] = useState<HTMLDivElement | null>(null);

  const modules = React.useMemo(() => {
    const config = {
      toolbar: {
        container: toolbarNode,
      },
      clipboard: {
        matchVisual: false,
      },
    };
    return config;
  }, [toolbarNode]);

  let formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "align",
  ];

  return (
    <>
      <CustomToolbar
        ref={setToolbarNode}
        id={toolbarId}
        isPopUp={popUpOpen}
        isVisible={isVisible}
      />
      {!!toolbarNode && (
        <ReactQuill
          id={editorId}
          defaultValue={initialValue}
          ref={referece}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          modules={modules}
          formats={formats}
          theme={"snow"}
          style={{ height: "140px" }}
        />
      )}
    </>
  );
};
