import * as yup from "yup";
import {Sa} from "@/models/Global/TPGlobalModels";

/**
 * project list
 */
export enum ProjectsAdminEnum {
    /**
     * key word
     */
    ProjectsAdmin = 'ProjectsAdmin',

    /**
     * file name component
     */
    ProjectsAdminFilename = 'ProjectsAdmin.tsx',

    /**
     * component name
     */
    ProjectsAdminComponent = 'ProjectsAdminComponent',

    /**
     * COMPONENT LABELS
     */

    /**
     * title label
     */
    TitleLabel = 'TitleLabel',

    /**
     * new project button label
     */
    NewProjectButtonLabel = 'NewProjectButtonLabel',

    /**
     * search input label
     */
    SearchInputLabel = 'Search',

    /**
     * export button label
     */
    ExportButtonLabel = 'Export',

    /**
     * refresh button label
     */
    RefreshButtonLabel = 'Refresh',

    /**
     * preferences input label
     */
    PreferencesInputLabel = 'PreferencesInputLabel',

    /**
     * actions table column label
     */
    ActionsTableColumnLabel = 'ActionsTableColumnLabel',

    /**
     * delete action label
     */
    DeleteActionLabel = 'DeleteLabel',

    /**
     * update action label
     */
    UpdateActionLabel = 'UpdateLabel',

    /**
     * clone action label
     */
    CloneActionLabel = 'CloneLabel',

    /**
     * id table column label
     */
    IdTableColumnLabel = 'IdTableColumnLabel',

    /**
     * name table column label
     */
    NameTableColumnLabel = 'NameTableColumnLabel',

    /**
     * structure type table column label
     */
    StructureTypeTableColumnLabel = 'StructureTypeTableColumnLabel',

    /**
     * queues table column label
     */
    QueuesTableColumnLabel = 'QueuesTableColumnLabel',

    /**
     * active table column label
     */
    ActiveTableColumnLabel = 'ActiveTableColumnLabel',

    /**
     * active column yes label
     */
    ActiveColumnYesLabel = 'ActiveColumnYesLabel',

    /**
     * active column no label
     */
    ActiveColumnNoLabel = 'ActiveColumnNoLabel',

    /**
     * MODAL COMPONENT LABELS
     */

    /**
     * title modal label
     */
    titleModalLabel = 'ConfirmTitle',

    /**
     * yes modal button label
     */
    YesModalButtonLabel = 'OkButton',

    /**
     * no modal button label
     */
    NoModalButtonLabel = 'CancelButton',

    /**
     * question modal label
     */
    QuestionModalLabel = 'QuestionModalLabel',
}

/**
 * project list state event
 */
export enum ProjectListStateEvent {
    getMessage = "ProjectList/getMessage/loading",
    getMessageSuccess = "ProjectList/getMessage/success",
    getMessageError = "ProjectList/getMessage/error",
    find = "ProjectList/find/loading",
    findSuccess = "ProjectList/find/success",
    findError = "ProjectList/find/error",
}

/**
 * project type enum
 */
export enum FieldType {
    /**
     * date
     */
    DATE = 'DATE',

    /**
     * phone
     */
    PHONE = 'PHONE',

    /**
     * number
     */
    NUMERIC = 'NUMERIC',

    /**
     * email
     */
    EMAIL = 'EMAIL',

    /**
     * other
     */
    OTHER = 'OTHER',
}

/**
 * project type enum
 */
export enum ProjectType {
    /**
     * front office
     */
    FRONTOFFICE = 'FRONTOFFICE',

    /**
     * back office
     */
    BACKOFFICE = 'BACKOFFICE',
}

/**
 * set condition enum
 */
export enum SetConditionEnum {
    /**
     * and condition
     */
    AND = 'AND',

    /**
     * or condition
     */
    OR = 'OR',
}

/**
 * set condition model
 */
export interface SetConditionModel {
    eventLoadStructureDetailId: number | string;
    field: FieldType | string | null;
    operation: string | null;
    value: string | null;
    conditionType?: SetConditionEnum | string | null;
}

/**
 * set model
 */
export interface SetModel {
    id?: string | null;
    eventProjectId?: string | null;
    name: string | null;
    groupId: number | null;
    priority?: number | null;
    conditions?: Array<SetConditionModel> | null;
}

/**
 * language model
 */
export interface LanguageModel {
    languageId: string;
    localizedValue: string;
    order: number;
}

/**
 * project model
 */
export interface ProjectModel {
	id: string;
	name: string;
	nameLanguages: Array<LanguageModel>;
	recordType: ProjectType | null;
	structureTypeId: string | null;
	defaultQueueId: string | null;
	supervisorId: string | null;
	descriptionStructure: string | null;
	conditionsOrQueues: Array<SetModel>;
	isActive: boolean;

	projectId: string;
	names?: Array<LanguageModel>;
	structureId?: string;
	defaultQueueName: string | null;
	supervisorName?: string;
}

export interface TableProjectModel extends Omit<ProjectModel, ""> {
    queues: string;
}

export type ProjectListState = {
    messages: { [attribute: string]: Sa<string> };
    datasource: Sa<Array<TableProjectModel>>;
};

export const projectListInitialState: ProjectListState = {
    messages: {},
    datasource: new Sa<Array<TableProjectModel>>({value: []}),
}

/**
 * project new
 */

/**
 * project new enum
 */
export enum ProjectNewEnum {
    /**
     * key word
     */
    ProjectNew = 'ProjectNew',

    /**
     * file name component
     */
    ProjectNewFilename = 'ProjectNew.tsx',

    /**
     * component name
     */
    ProjectNewComponent = 'ProjectNewComponent',

    /**
     * COMPONENT LABELS
     */

    /**
     * title label
     */
    TitleLabel = 'TitleLabel',

    /**
     * update title label
     */
    UpdateTitleLabel = 'UpdateTitleLabel',

    /**
     * step 1 title label
     */
    Step1TitleLabel = 'Step1TitleLabel',

    /**
     * step 1 description label
     */
    Step1DescriptionLabel = 'Step1DescriptionLabel',

    /**
     * step 2 front title label
     */
    Step2FrontTitleLabel = 'Step2FrontTitleLabel',

    /**
     * step 2 front subtitle label
     */
    Step2FrontSubtitleLabel = 'Step2FrontSubtitleLabel',

    /**
     * step 2 front description label
     */
    Step2FrontDescriptionLabel = 'Step2FrontDescriptionLabel',

    /**
     * step 2 back title label
     */
    Step2BackTitleLabel = 'Step2BackTitleLabel',

    /**
     * step 2 input queue label
     */
    Step2InputQueueLabel = 'Step2InputQueueLabel',

    /**
     * step 2 alert label
     */
    Step2AlertLabel = 'Step2AlertLabel',

    /**
     * step 2 description label
     */
    Step2DescriptionLabel = 'Step2DescriptionLabel',

    /**
     * step 2 set label
     */
    Step2SetLabel = 'Step2SetLabel',

    /**
     * step 2 set clone action label
     */
    Step2SetCloneActionLabel = 'CloneLabel',

    /**
     * step 2 set delete action label
     */
    Step2SetDeleteActionLabel = 'DeleteLabel',

    /**
     * step 2 set delete title modal label
     */
    Step2SetDeleteTitleModalLabel = 'ConfirmTitle',
    /**
     * step 2 set delete yes modal button label
     */
    Step2SetDeleteYesModalButtonLabel = 'OkButton',
    /**
     * step 2 set delete no modal button label
     */
    Step2SetDeleteNoModalButtonLabel = 'CancelButton',
    /**
     * step 2 set delete question modal label
     */
    Step2SetDeleteQuestionModalLabel = 'Step2SetDeleteQuestionModalLabel',

    /**
     * step 2 set configuration label
     */
    Step2SetConfigurationLabel = 'Step2SetConfigurationLabel',

    /**
     * step 2 set input assign label
     */
    Step2SetInputDefaultAssignLabel = 'Step2SetInputDefaultAssignLabel',

    /**
     * step 2 set input assign label
     */
    Step2SetInputAssignLabel = 'Step2SetInputAssignLabel',

    /**
     * step 2 set input priority label
     */
    Step2SetInputPriorityLabel = 'Step2SetInputPriorityLabel',

    /**
     * step 2 set condition label
     */
    Step2SetConditionLabel = 'Step2SetConditionLabel',

    /**
     * step 2 set input base field label
     */
    Step2SetInputBaseFieldLabel = 'Step2SetInputBaseFieldLabel',

    /**
     * step 2 set input base field label
     */
    Step2SetInputComparisonOperationLabel = 'Step2SetInputComparisonOperationLabel',

    /**
     * step 2 set input base field label
     */
    Step2SetInputReferenceValueLabel = 'Step2SetInputReferenceValueLabel',

    /**
     * step 2 set input and option label
     */
    Step2SetInputAndOptionLabel = 'AndOperatorText',

    /**
     * step 2 set input or option label
     */
    Step2SetInputOrOptionLabel = 'OrOperatorText',

    /**
     * step 2 set add new button label
     */
    Step2SetAddNewButtonLabel = 'Step2SetAddNewButtonLabel',

    /**
     * form
     */

    /**
     * form input id label
     */
    FormInputIdLabel = "FormInputIdLabel",

    /**
     * form input name label
     */
    FormInputNameLabel = "FormInputNameLabel",

    /**
     * form input structure type label
     */
    FormInputStructureTypeLabel = "FormInputStructureTypeLabel",

    /**
     * form input supervisor label
     */
    FormInputSupervisorLabel = "FormInputSupervisorLabel",

    /**
     * form input type label
     */
    FormInputTypeLabel = "FormInputTypeLabel",

    /**
     * form input type front office option label
     */
    FormInputTypeFrontOfficeOptionLabel = "FormInputTypeFrontOfficeOptionLabel",

    /**
     * form input type back office option label
     */
    FormInputTypeBackOfficeOptionLabel = "FormInputTypeBackOfficeOptionLabel",

    /**
     * form input active label
     */
    FormInputActiveLabel = "FormInputActiveLabel",

    /**
     * required field label
     */
    RequiredFieldLabel = "RequiredFieldLabel",

    /**
     * maximum 20 characters allowed label
     */
    Maximum20CharactersAllowedLabel = "Maximum20CharactersAllowedLabel",

    /**
     * form actions label
     */

    /**
     * form action cancel label
     */
    FormActionCancelLabel = "CancelButton",

    /**
     * form action cancel label
     */
    FormActionContinueLabel = "FormActionContinueLabel",

    /**
     * form action accept label
     */
    FormActionNewProjectLabel = "FormActionNewProjectLabel",

    /**
     * form action update accept label
     */
    FormActionEditProjectLabel = "FormActionEditProjectLabel",

    /**
     * MODAL COMPONENT LABELS
     */

    /**
     * language IconButton tooltip
     */
    LanguageButtonTooltipLabel = "LanguageButtonTooltipLabel",

    /**
     * modal title label
     */
    LanguageModalTitleLabel = "LanguageModalTitleLabel",

    /**
     * save label when clicking the save button
     */
    LanguageModalOkButtonLabel = "OkButton",

    /**
     * cancel label when clicking the cancel button
     */
    LanguageModalCancelButtonLabel = "CancelButton",
}

/**
 * project new model
 */
export interface ProjectNewModel {
    projectId: string | null;
    name: string | null;
    nameLanguages: Array<LanguageModel>;
    recordType: ProjectType;
    structureTypeId: string | null;
    defaultQueueId?: string | null;
    supervisorId: string | null;
    conditionsOrQueues?: Array<SetModel>;
    isActive: boolean;
}

/**
 * set condition control
 */
export const SetConditionControl = {
    initialValues: {
        eventLoadStructureDetailId: "",
        field: "",
        operation: "",
        value: "",
        conditionType: null,
    } as SetConditionModel,
    validationSchema: yup.object({
        field: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel),
        operation: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel),
        value: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel),
    }),
}

/**
 * set control
 */
export const SetControl = {
    initialValues: {
        groupId: null,
        name: "",
        priority: 1,
        conditions: [{...SetConditionControl.initialValues}],
    } as SetModel,
    validationSchema: yup.object({
        groupId: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel),
        priority: yup
            .number()
            .required(ProjectNewEnum.RequiredFieldLabel),
        conditions: yup
            .array()
            .required(ProjectNewEnum.RequiredFieldLabel),
    }),
}

/**
 * project new control
 */
export const ProjectNewControl = {
    initialValues: {
        projectId: null,
        name: null,
        nameLanguages: [] as Array<LanguageModel>,
        recordType: ProjectType.FRONTOFFICE,
        structureTypeId: null,
        defaultQueueId: null,
        supervisorId: null,
        conditionsOrQueues: [] as Array<SetModel>,
        isActive: true,
    } as ProjectNewModel,
    validationSchema: yup.object({
        projectId: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel),
        name: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel)
            .max(20, ProjectNewEnum.Maximum20CharactersAllowedLabel),
        recordType: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel),
    }),
}

/**
 * project clone enum
 */
export enum ProjectCloneEnum {
    /**
     * key word
     */
    ProjectClone = 'ProjectClone',

    /**
     * file name component
     */
    ProjectCloneFilename = 'ProjectClone.tsx',

    /**
     * component name
     */
    ProjectCloneComponent = 'ProjectCloneComponent',

    /**
     * COMPONENT LABELS
     */

    /**
     * title label
     */
    TitleLabel = 'TitleLabel',

    /**
     * description label
     */
    DescriptionLabel = 'DescriptionLabel',

    /**
     * form
     */

    /**
     * form input source id label
     */
    FormInputSourceIdLabel = "FormInputSourceIdLabel",

    /**
     * form input id label
     */
    FormInputIdLabel = "FormInputIdLabel",

    /**
     * form input name label
     */
    FormInputNameLabel = "FormInputNameLabel",

    /**
     * form input active label
     */
    FormInputActiveLabel = "FormInputActiveLabel",

    /**
     * required field label
     */
    RequiredFieldLabel = "RequiredFieldLabel",

    /**
     * maximum 20 characters allowed label
     */
    Maximum20CharactersAllowedLabel = "Maximum20CharactersAllowedLabel",

    /**
     * form actions label
     */

    /**
     * form action cancel label
     */
    FormActionCancelLabel = "CancelButton",

    /**
     * form action accept label
     */
    FormActionNewProjectLabel = "FormActionNewProjectLabel",

    /**
     * MODAL COMPONENT LABELS
     */

    /**
     * language IconButton tooltip
     */
    LanguageButtonTooltipLabel = "LanguageButtonTooltipLabel",

    /**
     * modal title label
     */
    LanguageModalTitleLabel = "LanguageModalTitleLabel",

    /**
     * save label when clicking the save button
     */
    LanguageModalOkButtonLabel = "OkButton",

    /**
     * cancel label when clicking the cancel button
     */
    LanguageModalCancelButtonLabel = "CancelButton",
}

/**
 * project clone model
 */
export interface ProjectCloneModel {
    sourceProjectId: string;
    projectId: string;
    name: string | null;
    nameLanguages: Array<LanguageModel>;
    isActive: boolean;
}

/**
 * project clone control
 */
export const ProjectCloneControl = {
    initialValues: {
        sourceProjectId: "",
        projectId: "",
        name: null,
        nameLanguages: [] as Array<LanguageModel>,
        isActive: true,
    } as ProjectCloneModel,
    validationSchema: yup.object({
        sourceProjectId: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel),
        projectId: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel),
        name: yup
            .string()
            .required(ProjectNewEnum.RequiredFieldLabel)
            .max(20, ProjectNewEnum.Maximum20CharactersAllowedLabel),
    }),
}

/**
 * project detail enum
 */
export enum ProjectDetailEnum {
    /**
     * key word
     */
    ProjectDetail = 'ProjectDetail',

    /**
     * file name component
     */
    ProjectDetailFilename = 'ProjectDetail.tsx',

    /**
     * component name
     */
    ProjectDetailComponent = 'ProjectDetailComponent',

    /**
     * COMPONENT LABELS
     */

    /**
     * title label
     */
    TitleLabel = 'TitleLabel',

    /**
     * action section
     */

    /**
     * action tooltip mode table label
     */
    ActionTooltipModeTableLabel = 'ActionTooltipModeTableLabel',

    /**
     * action tooltip mode cards label
     */
    ActionTooltipModeCardsLabel = 'ActionTooltipModeCardsLabel',

    /**
     * action input search label
     */
    ActionInputSearchLabel = 'Search',

    /**
     * action tooltip download label
     */
    ActionTooltipDownloadLabel = 'Export',

    /**
     * action tooltip refresh label
     */
    ActionTooltipRefreshLabel = 'Refresh',

    /**
     * action input sort label
     */
    ActionInputSortLabel = 'Sort',

    /**
     * action button load new file label
     */
    ActionButtonLoadNewFileLabel = 'ActionButtonLoadNewFileLabel',

    /**
     * global report label
     */
    GlobalReportLabel = 'GlobalReportLabel',

    /**
     * global report records label
     */
    GlobalReportEventsLabel = 'GlobalReportEventsLabel',

    /**
     * global report worked label
     */
    GlobalReportWorkedLabel = 'GlobalReportWorkedLabel',

    /**
     * global report pending label
     */
    GlobalReportPendingLabel = 'GlobalReportPendingLabel',

    /**
     * global report total queues label
     */
    GlobalReportTotalQueuesLabel = 'GlobalReportTotalQueuesLabel',

    /**
     * global report customer experts available label
     */
    GlobalReportCustomerExpertsAvailableLabel = 'GlobalReportCustomerExpertsAvailableLabel',

    /**
     * customer experts label
     */
    CustomerExpertsLabel = 'CustomerExpertsLabel',

    /**
     * tickets label
     */
    TicketsLabel = 'TicketsLabel',

    /**
     * table column queue name label
     */
    TableColumnQueueNameLabel = 'TableColumnQueueNameLabel',

    /**
     * table column total ce label
     */
    TableColumnTotalCELabel = 'TableColumnTotalCELabel',

    /**
     * table column ce ready label
     */
    TableColumnCEReadyLabel = 'TableColumnCEReadyLabel',

    /**
     * table column online label
     */
    TableColumnOnlineLabel = 'TableColumnOnlineLabel',

    /**
     * table column working label
     */
    TableColumnWorkingLabel = 'TableColumnWorkingLabel',

    /**
     * table column offline label
     */
    TableColumnOfflineLabel = 'TableColumnOfflineLabel',

    /**
     * table column events label
     */
    TableColumnEventsLabel = 'TableColumnEventsLabel',

    /**
     * table column worked label
     */
    TableColumnWorkedLabel = 'TableColumnEventsLabel',

    /**
     * table column pending label
     */
    TableColumnPendingLabel = 'TableColumnPendingLabel',

    /**
     * table column type label
     */
    TableColumnTypeLabel = 'TableColumnTypeLabel',

    /**
     * table column action reassign ce label
     */
    TableColumnActionReassignCELabel = 'TableColumnActionReassignCELabel',

    /**
     * modal
     */

    /**
     * modal title label
     */
    ModalTitleLabel = 'ModalTitleLabel',

    /**
     * modal current queue label
     */
    ModalCurrentQueueLabel = 'ModalCurrentQueueLabel',

    /**
     * modal option 1 label
     */
    ModalOption1Label = 'ModalOption1Label',

    /**
     * modal option 2 label
     */
    ModalOption2Label = 'ModalOption2Label',

    /**
     * modal action reassign label
     */
    ModalActionSelectAllLabel = 'ModalActionSelectAllLabel',

    /**
     * modal action reassign label
     */
    ModalActionNextPageLabel = 'ModalActionNextPageLabel',

    /**
     * modal action cancel label
     */
    ModalActionCancelLabel = 'ModalActionCancelLabel',

    /**
     * modal action reassign label
     */
    ModalActionReassignLabel = 'ModalActionReassignLabel',

}

/**
 * project detail state event
 */
export enum ProjectDetailStateEvent {
    setMessage = "ProjectDetail/setMessage/loading",
    find = "ProjectDetail/find/loading",
    findSuccess = "ProjectDetail/find/success",
    findError = "ProjectDetail/find/error",
    findGlobalReports = "ProjectDetail/findGlobalReports/loading",
}

export type ProjectDetailState = {
    messages: { [attribute: string]: string };
    datasource: Sa<Array<TableProjectModel>>;
    globalReport?: ProjectDetailModel;
};

export const projectDetailInitialState: ProjectDetailState = {
    messages: {},
    datasource: new Sa<Array<TableProjectModel>>({value: []}),
}

export interface ProjectDetailModel {
    queueId: string;
    queueName: string;
    totalCe: string;
    ceReady: string;
    online: string;
    working: string;
    offline: string;
    events: string;
    worked: string;
    pending: string;
    type: ProjectType;
}

export interface TableProjectDetailModel {
    queueId?: string | null;
    queueName: string;
    totalCe: string;
    ceReady: string;
    online: string;
    working: string;
    offline: string;
    events: string;
    worked: string;
    pending: string;
    type: ProjectType;
    reassign: string;
}
