import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion from "@/layouts/ModalQuestion/TPModalQuestion";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  commandType,
  commandsEnum as event,
} from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import {
  SequeceGeneratorSequencesNameEnum,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { StoreModel } from "@/redux/store";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import { SequenceService } from "@/services/SequenceService";
import { useFormik } from "formik";
import { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailTemplateCloneFormSlice,
  EmailTemplateCloneFormStateModel,
} from "./_redux/EmailTemplateCloneFormSlice";
import EmailTemplateCloneFormLanguageModal from "./EmailTemplateCloneFormLanguageModal";
import e from "./models/EmailTemplateCloneEnum";
import { EmailTemplateCloneFormControl } from "./models/EmailTemplateCloneFormControl";

interface EmailTemplateCloneFormProps {
  tabId: string;
  element: any;
  globalDispatch: (action: commandType) => void;
}

export default function EmailTemplateCloneForm({
  tabId,
  element,
  globalDispatch,
}: EmailTemplateCloneFormProps) {
  const dispatch = useDispatch();

  const {
    message: m,
    mailAccounts,
    result,
  } = useSelector(
    (s: StoreModel) => s[EmailTemplateCloneFormSlice.name]
  ) as EmailTemplateCloneFormStateModel;

  const componentFileName = "EmailTemplateCloneForm";
  const [languageLabel, setLanguageLabel] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [languages, setLanguages] = useState<any>({});

  const formik = useFormik({
    initialValues: {
      ...EmailTemplateCloneFormControl.initialValues,
      sourceId: element.id as string,
    },
    validationSchema: EmailTemplateCloneFormControl.validationSchema,
    validateOnChange: false,
    onSubmit: async () => {
      setIsModelOpen(true);
    },
  });

  useEffect(() => {
    dispatch(
      EmailTemplateCloneFormSlice.actions.message([
        e.TitleLabel,
        e.ElementToCloneLabel,
        e.NewTemplateIdLabel,
        e.DescriptionLabel,
        e.EmailAccountLabel,
        e.ParametersLabel,
        e.IsActiveLabel,
        e.CanBeUsedLabel,
        e.RequiredFieldLabel,
        e.Maximum20CharactersAllowedLabel,
        e.Maximum100CharactersAllowedLabel,
        e.OkButtonLabel,
        e.CancelButtonLabel,
        e.LanguageButtonTooltipLabel,
        e.LanguageModalTitleLabel,
        e.ConfirmModalTitleLabel,
        e.ConfirmModalQuestionLabel,
        e.ConfirmModalOkButtonLabel,
      ])
    );
    dispatch(EmailTemplateCloneFormSlice.actions.getMailAccounts());
    setLanguageLabel(
      TPGlobal.TPClientAvailableLanguages.find(
        ({ id }) => id === TPGlobal.language
      )?.name
    );
    setLoading(false);
  }, []);

  useEffect(
    () => () => {
      dispatch(EmailTemplateCloneFormSlice.actions.clean());
    },
    []
  );

  useEffect(() => {
    generalAutomaticId();
  }, []);

  useEffect(() => {
    if (result?.message)
      console[result.error ? "error" : "info"](result.message);
  }, [result]);

  useEffect(() => {
    const l = Object.entries(languages).map(
      ([languageId, localizedValue], order) => ({
        languageId,
        localizedValue,
        order,
      })
    );
    formik.setFieldValue("descriptionLocalizedValues", l);
  }, [languages]);

  async function responseModelHandler(response: boolean) {
    setIsModelOpen(false);
    if (response) {
      const request: any = {
        ...formik.values,
        descriptionLocalizedValues: [
          {
            languageId: TPGlobal.language,
            localizedValue: formik.values.description,
            order: 0,
          },
          ...(formik.values?.descriptionLocalizedValues as Array<any>).map(
            (l, index) => ({ ...l, order: index + 1 })
          ),
        ],
      };

      const { cloneEmailTemplate } = new EmailTemplateService();
      cloneEmailTemplate(request, true, true, [200])
        .then(() => {
          globalDispatch({ type: event.vertical_tab_close, payload: tabId });
          globalDispatch({ type: event.reloadGrid });
          globalDispatch({
            type: event.update_vertical_tab,
            payload: {
              recordId: `${request.destinationId}`,
              languageId: TPGlobal.language,
              recordDescription: `${request.destinationId}`,
            },
          });
        })
        .catch((error) => console.error(error));
    }
  }

  function onChangeHandler(event: any, attribute: string) {
    const value = event.target.value;
    formik.setFieldValue(attribute, value);
  }

  function onClickCancelButtonHandler() {
    dispatch(EmailTemplateCloneFormSlice.actions.close(element.id));
  }

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequeceGeneratorSequencesNameEnum.SQEMTE
      );

      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        formik.setFieldValue("destinationId", result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setLoading(false);
    }
  };

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={loading}>
        <div className="row">
          <div className="col-5">
            <TPPageTitle>{m?.[e.TitleLabel]}</TPPageTitle>
            <hr />

            <form onSubmit={formik.handleSubmit}>
              <TPTextBox
                id="sourceId"
                disabled={true}
                labelText={m?.[e.ElementToCloneLabel]}
                isMandatory={true}
                value={`${element.description ?? ""}${element.description ? ` (${formik.values.sourceId})` : formik.values.sourceId}`}
                onChange={(e: any) => onChangeHandler(e, "sourceId")}
                maxLength={20}
                errorMessage={
                  formik.errors.sourceId && m?.[formik.errors.sourceId]
                }
              />

              <TPTextBox
                id="destinationId"
                disabled={true}
                labelText={m?.[e.NewTemplateIdLabel]}
                isMandatory={true}
                value={formik.values.destinationId}
                onChange={(e: any) => onChangeHandler(e, "destinationId")}
                maxLength={20}
                errorMessage={
                  formik.errors.destinationId &&
                  m?.[formik.errors.destinationId]
                }
              />

              <div className="row col-12">
                <div className="col-11">
                  <TPTextBox
                    id="description"
                    isMandatory={true}
                    labelText={`${m?.[e.DescriptionLabel]}${languageLabel ? ` (${languageLabel})` : ""}`}
                    value={formik.values.description}
                    onChange={(e: any) => onChangeHandler(e, "description")}
                    maxLength={100}
                    errorMessage={
                      formik.errors.description &&
                      m?.[formik.errors.description]
                    }
                  />
                </div>

                <div className="col-1" style={styles.iconButton}>
                  <EmailTemplateCloneFormLanguageModal
                    m={m}
                    languages={languages}
                    setLanguages={setLanguages}
                  />
                </div>
              </div>

              <TPSelect
                id="idAccount"
                isMandatory={false}
                labelText={m?.[e.EmailAccountLabel]}
                onChange={(e: any) => onChangeHandler(e, "idAccount")}
                dataSource={mailAccounts}
                value={formik.values.idAccount}
                errorMessage={formik.errors.idAccount}
              />

              <TPPageSectionTitle>{m?.[e.ParametersLabel]}</TPPageSectionTitle>

              <div className="row">
                <TPCheckBox
                  id="isActive"
                  className="col-6"
                  labelText={m?.[e.IsActiveLabel]}
                  checked={formik.values.isActive}
                  onChange={(e: any) =>
                    onChangeHandler(
                      { target: { value: !formik.values.isActive } },
                      "isActive"
                    )
                  }
                />

                <TPCheckBox
                  id="isAddable"
                  className="col-6"
                  labelText={m?.[e.CanBeUsedLabel]}
                  checked={formik.values.isAddable}
                  onChange={(e: any) =>
                    onChangeHandler(
                      { target: { value: !formik.values.isAddable } },
                      "isAddable"
                    )
                  }
                />
              </div>

              <TPPageAcceptCancelButtonsContainer>
                <TPButton
                  type={TPButtonTypes.primary}
                  onClick={() => formik.handleSubmit()}
                >
                  {m?.[e.OkButtonLabel]}
                </TPButton>

                <TPButton
                  type={TPButtonTypes.link}
                  onClick={onClickCancelButtonHandler}
                  className={"ms-2"}
                >
                  {m?.[e.CancelButtonLabel]}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>

              {isModelOpen && (
                <TPModalQuestion
                  title={m?.[e.ConfirmModalTitleLabel]}
                  yesLabel={m?.[e.ConfirmModalOkButtonLabel]}
                  noLabel={m?.[e.CancelButtonLabel]}
                  question={m?.[e.ConfirmModalQuestionLabel]}
                  callBackData={{}}
                  isShown={isModelOpen}
                  callBackAnswer={responseModelHandler}
                />
              )}
            </form>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );
}

const styles = {
  iconButton: {
    marginTop: "auto",
  } as CSSProperties,
};
