import TPToast, {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGeneralContext from "@/contexts/TPGeneralContext";
import TPNotificationContext from "@/contexts/TPNotificationContext";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  BrowserClientInputDTO,
  BrowserClientUpdateInputDTO,
} from "@/models/BrowserClient/BrowserClientInputDTO";
import { SignalRSubtopicEnum } from "@/models/Global/TPGlobalEnums";
import { GroupTakeNextResponseModel } from "@/models/GroupTakeNext/GroupTakeNextModel";
import { notificationCenterItem } from "@/models/NotificationCenter/NotificationCenterModel";
import {
  TPRealTimeMessages,
  TPRealTimeMessageType,
} from "@/RealTime/TPRealTimeMessages";
import { BrowserKeepAliveService } from "@/services/BrowserKeepAliveService";
import { TPI18N } from "@/services/I18nService";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import moment from "moment";
import React, {
  ReactElement,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import { HorizontalTabsSlice } from "./_redux/HorizonatalTabsSlice";
import MainHorizontalTabs from "./MainHorizontalTabs";
import MainMenu from "./MainMenu";
import MainItemTabType from "./MainMenuItemTabType";
import { MainContainerStyled } from "./mainStyles";

type CounterOptionPair = {
  counter: number;
  option: string;
  localizedDescription: string;
};

type AdminStateType = {
  notificationList: Array<notificationCenterItem>;
};

enum commandsEnum {
  "new_notification" = 0,
  "set_alreadySeen" = 1,
  "remove_notification" = 2,
  "set_is_deleting" = 3,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

type CaseViewerVerticalItem = {
  caseNumber: number;
  currentCronTime: number;
};

type CaseViewerAdminState = {
  currentCase: number;
  casesList: Array<CaseViewerVerticalItem>;
  updateTimeLine: number;
  messageType: string;
  resolveCallback: Function;
  currentTakeNext: number;
};
enum commandsCaseViewerEnum {
  "new_case" = 0,
  "remove_case" = 1,
  "change_current_case" = 2,
  "update_cronTime" = 3,
  "remove_all_cases" = 4,
  "update_timeline_current_case" = 5,
  "my_tasks" = 6,
  "group_take_next" = 7,
}

type commandTypeCaseViewer = {
  type: commandsCaseViewerEnum;
  payload: any;
};

const Main = (): ReactElement => {
  const dispatch = useDispatch();

  const contextData: any = React.useContext(TPGeneralContext);

  const ChildRef = useRef();

  const componentFileName = "Main.tsx";
  //Screen resources
  const resourceSet: string = "MainComponent";
  const [caseViewerLabel, setCaseViewerLabel] = useState("");

  //#region Setup
  const theScopes: Array<string> = ["openid", "profile", TPGlobal.scopeAPI];
  const authRequest = {
    scopes: theScopes,
  };
  const { instance, accounts, inProgress } = useMsal();
  const [alreadyHaveToken, setAlreadyHaveToken] = useState(false);

  const [horizontalTabsArray, setHorizontalTabArray] = useState<
    Array<MainItemTabType>
  >([]);
  const [counterOptionPair, setCounterOption] = useState<CounterOptionPair>({
    counter: 500,
    option: "",
    localizedDescription: "",
  });

  useEffect(() => {
    dispatch(
      HorizontalTabsSlice.actions.update(
        structuredClone(horizontalTabsArray) as any
      )
    );
  }, [horizontalTabsArray]);

  const loadResources = async () => {
    setCaseViewerLabel(
      await TPI18N.GetText(resourceSet, "CaseViewerTitleLabel")
    );
  };

  const handleMenuItemClick = (
    option: string,
    localizedDescription: string
  ): void => {
    let newCounterOptionPair: CounterOptionPair = {} as CounterOptionPair;
    newCounterOptionPair.counter = counterOptionPair.counter + 1;
    newCounterOptionPair.option = option;
    newCounterOptionPair.localizedDescription = localizedDescription;
    setCounterOption(newCounterOptionPair);
  };

  const handleTabClick = (tabId: number): void => {
    let newTabsArrayHorizontal: Array<MainItemTabType>;
    newTabsArrayHorizontal = horizontalTabsArray.map(
      (item: MainItemTabType, index: number) => {
        item.tabIsActive = item.tabId == tabId;
        return item;
      }
    );
    setHorizontalTabArray(newTabsArrayHorizontal);
  };

  const handleMouseMoveContentArea = () => {
    let theRef: any;
    theRef = ChildRef.current;
    theRef.mouseMoveFromParent();
  };

  const handleCloseClick = (tabId: number): void => {
    let newTabsArrayHorizontal: Array<MainItemTabType> = [];
    let i: number;
    let tmpIsActive: number = 0;

    //add all tabs except the one your are going to remove
    //get current active tab
    for (i = 0; i <= horizontalTabsArray.length - 1; i++) {
      if (horizontalTabsArray[i].tabId != tabId) {
        newTabsArrayHorizontal.push(horizontalTabsArray[i]);
      } else {
        if (
          horizontalTabsArray[i].tabType ===
          "TPClientCloudMain.Client.Components.Cases.CaseViewer"
        ) {
          //remove
          let command1: commandTypeCaseViewer = {
            type: commandsCaseViewerEnum.remove_all_cases,
            payload: null,
          };
          dispatchCommandCaseViewer(command1);
        }
      }
      if (horizontalTabsArray[i].tabIsActive) {
        tmpIsActive = horizontalTabsArray[i].tabId;
      }
    }
    //check if we need to relocate ActiveTab due to
    //remove one is the current one
    //just find the previous one of the current one
    if (tmpIsActive == tabId && newTabsArrayHorizontal.length > 0) {
      if (tmpIsActive == horizontalTabsArray[0].tabId) {
        tmpIsActive = newTabsArrayHorizontal[0].tabId;
      } else {
        for (i = 0; i <= horizontalTabsArray.length - 2; i++) {
          if (horizontalTabsArray[i + 1].tabId == tmpIsActive) {
            tmpIsActive = horizontalTabsArray[i].tabId;
            break;
          }
        }
      }

      for (i = 0; i <= newTabsArrayHorizontal.length - 1; i++) {
        newTabsArrayHorizontal[i].tabIsActive = false;
        if (newTabsArrayHorizontal[i].tabId == tmpIsActive) {
          newTabsArrayHorizontal[i].tabIsActive = true;
        }
      }
    }

    setHorizontalTabArray(newTabsArrayHorizontal);
  };

  //#region casesViewer
  const handleAddNewCaseViewerCallBack = async (
    newCase: number,
    resolveCallback?: Function,
    fromTakeNext?: boolean
  ) => {
    await loadResources();
    let command1: commandTypeCaseViewer = {
      type: commandsCaseViewerEnum.new_case,
      payload: {
        newCase: String(newCase),
        resolveCallback: resolveCallback,
        isTakeNext: fromTakeNext,
      },
    };
    dispatchCommandCaseViewer(command1);
  };

  const handleRemoveNewCaseViewerCallBack = (newCase: number) => {
    let command1: commandTypeCaseViewer = {
      type: commandsCaseViewerEnum.remove_case,
      payload: { newCase: newCase },
    };
    dispatchCommandCaseViewer(command1);
  };
  const handleUpdateCronTimeNewCaseViewerCallBack = () => {
    let command1: commandTypeCaseViewer = {
      type: commandsCaseViewerEnum.update_cronTime,
      payload: null,
    };
    dispatchCommandCaseViewer(command1);
  };
  const handleChangeCurrentCaseViewerCallBack = (newCurrentCase: number) => {
    let command1: commandTypeCaseViewer = {
      type: commandsCaseViewerEnum.change_current_case,
      payload: { newCurrentCase: newCurrentCase },
    };
    dispatchCommandCaseViewer(command1);
  };

  const handleRefresTimelineCaseViewerCallBack = (messageType: string) => {
    let command1: commandTypeCaseViewer = {
      type: commandsCaseViewerEnum.update_timeline_current_case,
      payload: { messageType: messageType },
    };
    dispatchCommandCaseViewer(command1);
  };

  const showMyTasks = () => {
    let command1: commandTypeCaseViewer = {
      type: commandsCaseViewerEnum.my_tasks,
      payload: null,
    };
    dispatchCommandCaseViewer(command1);
  };

  const takeNext = () => {
    let command1: commandTypeCaseViewer = {
      type: commandsCaseViewerEnum.group_take_next,
      payload: null,
    };
    dispatchCommandCaseViewer(command1);
  };

  const showTakeNextCase = (
    takeNextResponse: GroupTakeNextResponseModel,
    resolveCallback: Function
  ) => {
    handleAddNewCaseViewerCallBack(
      Number(takeNextResponse.caseId),
      resolveCallback,
      true
    );
  };

  //#endregion casesViewer

  //realtime handlers

  const handlerRealTimeOnDisconnect = () => {
    //todo
    setTimeout(function () {
      //try again to connect
      startMessaging(
        TPGlobal.sessionId,
        TPGlobal.currentUserGuid,
        TPGlobal.tenantHex,
        TPGlobal.language,
        TPGlobal.realTimeURL,
        TPGlobal.encryptedAESKey
      );
    }, 5000);
  };

  const handlerRealTimeIncomingMessages = (payload: TPRealTimeMessageType) => {
    console.log("payload", payload);
    switch (payload.subtopic) {
      case SignalRSubtopicEnum.INITIAL_TASKS:
        handleAddNewCaseViewerCallBack(payload.data);
        break;
      case SignalRSubtopicEnum.NEXT_TASKS:
        handleRefresTimelineCaseViewerCallBack(payload.subtopic);
        break;
      case SignalRSubtopicEnum.ADD_TASK:
        handleRefresTimelineCaseViewerCallBack(payload.subtopic);
        break;
      case SignalRSubtopicEnum.REJECT_TASK:
        handleRefresTimelineCaseViewerCallBack(payload.subtopic);
        break;
      case SignalRSubtopicEnum.RECLASSIFY_CASE:
        handleRefresTimelineCaseViewerCallBack(payload.subtopic);
        break;
      case SignalRSubtopicEnum.RECLASSIFY_CASE:
        handleRefresTimelineCaseViewerCallBack(payload.subtopic);
        break;
      case SignalRSubtopicEnum.TOASTMESSAGE:
        // alert(payload.data);
        showToast(payload.data, TPToastTypes.success);
        break;
      default:
        let command1: commandType = {
          type: commandsEnum.new_notification,
          payload: payload,
        };
        dispatchCommand(command1);
        break;
    }
  };

  const startMessaging = async (
    sessionId: string,
    userId: string,
    tenantid: string,
    languageid: string,
    url: string,
    encryptedAESKey: string
  ) => {
    try {
      await TPRealTimeMessages.start(
        sessionId,
        userId,
        tenantid,
        languageid,
        url,
        handlerRealTimeOnDisconnect,
        handlerRealTimeIncomingMessages,
        encryptedAESKey
      );
    } catch (error) {
      //todo logs
      setTimeout(function () {
        //try again to connect
        startMessaging(
          TPGlobal.sessionId,
          TPGlobal.currentUserGuid,
          TPGlobal.tenantHex,
          TPGlobal.language,
          TPGlobal.realTimeURL,
          TPGlobal.encryptedAESKey
        );
      }, 5000);
    }
  };

  const setAlreadySeenNotificationsCallback = () => {
    let command1: commandType = {
      type: commandsEnum.set_alreadySeen,
      payload: null,
    };
    dispatchCommand(command1);
  };

  const removeNotificationsCallback = (id: any) => {
    let command1: commandType = {
      type: commandsEnum.remove_notification,
      payload: id,
    };
    dispatchCommand(command1);
  };

  const addHorizontalTab = (
    tabType: string,
    tabTitle: string,
    tabTitleTooltip: string,
    tabId: number
  ) => {
    let existingTab;
    let existingException;
    let newTabsArrayHorizontal: Array<MainItemTabType>;

    if (tabId == 500) {
      return;
    }

    //check if option exist on horizontalTabsArray
    //if not exists create it
    //if exists detect if option is in ExceptionList to create a new  tab
    //otherwise switch to existing one

    existingTab = horizontalTabsArray.find((x) => x.tabType == tabType);
    if (existingTab) {
      //check for exceptions
      existingException = TPGlobal.newTabException.find((x) => x == tabType);
      if (!existingException) {
        //switch
        newTabsArrayHorizontal = horizontalTabsArray.map(
          (item: MainItemTabType, index: number) => {
            item.tabIsActive = item.tabType == tabType;
            return item;
          }
        );
        setHorizontalTabArray(newTabsArrayHorizontal);
        return;
      }
    }

    //add new tab
    let horizontalTab: MainItemTabType = {} as MainItemTabType;
    horizontalTab.tabType = tabType;
    horizontalTab.tabTitle = tabTitle;

    horizontalTab.tabTitleTooltip = tabTitleTooltip;
    horizontalTab.tabId = tabId;
    horizontalTab.tabIsActive = true;

    newTabsArrayHorizontal = horizontalTabsArray.map(
      (item: MainItemTabType, index: number) => {
        item.tabIsActive = false;
        return item;
      }
    );

    newTabsArrayHorizontal.push(horizontalTab);
    setHorizontalTabArray(newTabsArrayHorizontal);
  };

  const reportKeepAliveBeat = async () => {
    //check if exists
    //if exist update keepalive and stationId
    //if not exist create it

    let serviceClient = new BrowserKeepAliveService();
    let expectedCodes: Array<number> = [200, 404];
    let bolR: boolean = false;
    try {
      let responseRequest = await serviceClient.getBrowserClientBySesionId(
        TPGlobal.sessionId,
        false,
        false,
        expectedCodes
      );
      if (responseRequest && responseRequest.length === 1) {
        bolR = await updateKeepAliveBeat();
        if (!bolR) {
          //todo logs
        }
      } else {
        bolR = await insertKeepAliveBeat();
        if (!bolR) {
          //todo logs
        }
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reportKeepAliveBeat ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} reportKeepAliveBeat ex`);
      return null;
    }
  };

  const insertKeepAliveBeat = async () => {
    let serviceClient = new BrowserKeepAliveService();
    let expectedCodes: Array<number> = [200, 429];
    let inputDTO: BrowserClientInputDTO = {
      connectionId: TPGlobal.sessionId,
      iPAddress: "todo",
      symmetricKey: "todo",
      loginUser: TPGlobal.currentUserGuid,
      stationId: TPGlobal.stationId ? TPGlobal.stationId : "",
      uRLExternalAPP: "",
    };
    try {
      let responseRequest = await serviceClient.insertBrowserClient(
        inputDTO,
        false,
        false,
        expectedCodes
      );
      return true;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertKeepAliveBeat ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} insertKeepAliveBeat ex`);
      return false;
    }
  };

  const updateKeepAliveBeat = async () => {
    let serviceClient = new BrowserKeepAliveService();
    let expectedCodes: Array<number> = [200];
    let inputDTO: BrowserClientUpdateInputDTO = {
      connectionId: TPGlobal.sessionId,
      stationId: TPGlobal.stationId ? TPGlobal.stationId : "",
    };
    try {
      let responseRequest = await serviceClient.updateBrowserClient(
        inputDTO,
        false,
        false,
        expectedCodes
      );
      return true;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateKeepAliveBeat ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updateKeepAliveBeat ex`);
      return false;
    }
  };

  //sample code
  // {
  //   id: 1,
  //   title: "Notification 1 bien largo para ver si se baja a la segunda linea",
  //   text: "This is the description",
  //   date: "25-02-2022 12:30",
  //   alreadyView: false,
  //   color: TPToastTypes.success,
  // },
  const initialNotifications: Array<notificationCenterItem> = [];

  const initialStateBLL: AdminStateType = {
    notificationList: [...initialNotifications],
  };

  //reducer definition
  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
  function doCommand(prevState: AdminStateType, command: commandType) {
    let newAdminState: AdminStateType;
    let i: number;
    switch (command.type) {
      case commandsEnum.new_notification:
        newAdminState = { ...prevState };
        let newNotificationList: Array<notificationCenterItem> = [
          ...newAdminState.notificationList,
        ];
        let maxId: number = 0;
        if (newNotificationList.length >= 1) {
          maxId = Math.max(
            ...newNotificationList.map((x: notificationCenterItem) => x.id)
          );
        }
        const format1 = "YYYY-MM-DD HH:mm:ss";
        var date1 = new Date();

        let newItem: notificationCenterItem = {
          id: maxId + 1,
          title: "title notificationxx",
          text: command.payload.encryptedPayload,
          alreadyView: false,
          date: moment(date1).format(format1),
          color: TPToastTypes.success,
        };
        newNotificationList.push(newItem);
        newAdminState.notificationList = [...newNotificationList];
        return newAdminState;
      case commandsEnum.set_alreadySeen:
        newAdminState = { ...prevState };
        for (i = 0; i <= newAdminState.notificationList.length - 1; i++) {
          newAdminState.notificationList[i].alreadyView = true;
        }
        return newAdminState;
      case commandsEnum.remove_notification:
        newAdminState = { ...prevState };
        let newList: Array<notificationCenterItem> = [];
        for (i = 0; i <= newAdminState.notificationList.length - 1; i++) {
          if (newAdminState.notificationList[i].id !== command.payload) {
            newList.push(newAdminState.notificationList[i]);
          }
        }
        newAdminState.notificationList = [...newList];
        return newAdminState;
      case commandsEnum.set_is_deleting:
        newAdminState = { ...prevState };

        let newList1: Array<notificationCenterItem> = [];

        const item = newAdminState.notificationList.filter(
          (k) => k.id == command.payload.id
        )[0];
        item.isRemoving = true;

        newAdminState.notificationList = [...newAdminState.notificationList];
        return newAdminState;
      default:
        return prevState;
    }
  }

  //case viewer reducer
  const initialStateCaseViewerBLL: CaseViewerAdminState = {
    currentCase: 0,
    casesList: [],
    updateTimeLine: 0,
    messageType: "",
    resolveCallback: () => {},
    currentTakeNext: -1,
  };
  const [adminStateCaseViewer, dispatchCommandCaseViewer] = useReducer(
    doCommandCaseViewer,
    initialStateCaseViewerBLL
  );
  function doCommandCaseViewer(
    prevState: CaseViewerAdminState,
    command: commandTypeCaseViewer
  ) {
    let newCaseViewerState: CaseViewerAdminState;
    switch (command.type) {
      case commandsCaseViewerEnum.change_current_case:
        newCaseViewerState = { ...prevState };
        newCaseViewerState.currentCase = command.payload.newCurrentCase;
        return newCaseViewerState;

      case commandsCaseViewerEnum.update_timeline_current_case:
        newCaseViewerState = { ...prevState };
        newCaseViewerState.updateTimeLine =
          newCaseViewerState.updateTimeLine + 1;
        newCaseViewerState.messageType = command.payload.messageType;
        return newCaseViewerState;

      case commandsCaseViewerEnum.new_case:
        newCaseViewerState = { ...prevState };
        const requestedCaseFound = newCaseViewerState.casesList.find(
          (c) => String(c.caseNumber) == String(command.payload.newCase) // Compare as strings
        );
        if (!requestedCaseFound) {
          newCaseViewerState.casesList.push({
            caseNumber: command.payload.newCase,
            currentCronTime: 0,
          });
        }

        newCaseViewerState.currentCase = command.payload.newCase;
        newCaseViewerState.resolveCallback = command.payload.resolveCallback;
        newCaseViewerState.currentTakeNext = command.payload.isTakeNext
          ? command.payload.newCase
          : -1;

        let newCounterOptionPair: CounterOptionPair = {} as CounterOptionPair;
        newCounterOptionPair.counter = counterOptionPair.counter + 1;
        newCounterOptionPair.option =
          "TPClientCloudMain.Client.Components.Cases.CaseViewer";
        newCounterOptionPair.localizedDescription = caseViewerLabel;
        setCounterOption(newCounterOptionPair);

        return newCaseViewerState;

      case commandsCaseViewerEnum.remove_case:
        newCaseViewerState = { ...prevState };
        newCaseViewerState.casesList = newCaseViewerState.casesList.filter(
          (x) => x.caseNumber !== command.payload.newCase
        );
        if (newCaseViewerState.casesList.length > 0) {
          newCaseViewerState.currentCase =
            newCaseViewerState.casesList[0].caseNumber;
        }

        return newCaseViewerState;

      case commandsCaseViewerEnum.update_cronTime:
        newCaseViewerState = { ...prevState };
        for (
          let index = 0;
          index < newCaseViewerState.casesList.length;
          index++
        ) {
          const element = newCaseViewerState.casesList[index];
          if (element.caseNumber === newCaseViewerState.currentCase) {
            newCaseViewerState.casesList[index].currentCronTime =
              newCaseViewerState.casesList[index].currentCronTime + 1;
            break;
          }
        }
        //TODO Update database
        return newCaseViewerState;
      case commandsCaseViewerEnum.remove_all_cases:
        newCaseViewerState = { ...prevState };
        newCaseViewerState.casesList = [];
        return newCaseViewerState;
      case commandsCaseViewerEnum.my_tasks:
        newCaseViewerState = { ...prevState };
        let newTaskCounterOptionPair: CounterOptionPair =
          {} as CounterOptionPair;
        newTaskCounterOptionPair.counter = counterOptionPair.counter + 1;
        newTaskCounterOptionPair.option =
          "TPClientCloudMain.Client.Components.FollowUp.MyTasks";
        newTaskCounterOptionPair.localizedDescription = caseViewerLabel;
        setCounterOption(newTaskCounterOptionPair);
        return newCaseViewerState;
      case commandsCaseViewerEnum.group_take_next:
        newCaseViewerState = { ...prevState };
        let newGTNCounterOptionPair: CounterOptionPair =
          {} as CounterOptionPair;
        newGTNCounterOptionPair.counter = counterOptionPair.counter + 1;
        newGTNCounterOptionPair.option =
          "TPClientCloudMain.Client.Components.FollowUp.GroupTakeNext";
        newGTNCounterOptionPair.localizedDescription = caseViewerLabel;
        setCounterOption(newGTNCounterOptionPair);
        return newCaseViewerState;
    }
  }

  useEffect(() => {
    addHorizontalTab(
      counterOptionPair.option,
      counterOptionPair && counterOptionPair.localizedDescription.length > 34
        ? counterOptionPair.localizedDescription.slice(0, 34) + "..."
        : counterOptionPair.localizedDescription,
      counterOptionPair.localizedDescription,
      counterOptionPair.counter
    );
  }, [counterOptionPair]);

  //Acquire an access token
  //Get logged user information
  //Intitialize RealTime
  useEffect(() => {
    const startup = async () => {
      await TPGlobal.startUp(contextData);
      await TPGlobal.initRealTime();
      startMessaging(
        TPGlobal.sessionId,
        TPGlobal.currentUserGuid,
        TPGlobal.tenantHex,
        TPGlobal.language,
        TPGlobal.realTimeURL,
        TPGlobal.encryptedAESKey
      );
      await reportKeepAliveBeat();
    };

    if (
      !alreadyHaveToken &&
      inProgress === InteractionStatus.None &&
      accounts.length > 0
    ) {
      const tokenRequest = {
        account: accounts[0],
        scopes: theScopes,
      };

      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          //setup global variables
          TPGlobal.tenantHex = window.sessionStorage.getItem(
            "tenantidhex"
          ) as string;
          TPGlobal.language = window.sessionStorage.getItem(
            "languageid"
          ) as string;
          TPGlobal.tenantSelectorURL = window.sessionStorage.getItem(
            "tenantSelectorURL"
          ) as string;
          TPGlobal.jwt = response.accessToken;
          TPGlobal.sessionId = uuidv4() as string;
          let newStandardHeaders: Array<TPKeyValue> = [];
          newStandardHeaders.push({
            key: "tpc-session",
            value: TPGlobal.sessionId,
          });
          newStandardHeaders.push({
            key: "tpc-language",
            value: TPGlobal.language,
          });
          newStandardHeaders.push({
            key: "Authorization",
            value: "Bearer " + TPGlobal.jwt,
          });
          TPGlobal.standardHeaders = [...newStandardHeaders];
          //startup
          startup();
          setAlreadyHaveToken(true);
        })
        .catch(async (e) => {
          // Catch interaction_required errors and call interactive method to resolve
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(tokenRequest);
          }
          //todo log error
          throw e;
        });
    }
  }, [inProgress, accounts, instance, alreadyHaveToken]);

  //refresh Access Token every 1 minute
  useEffect(() => {
    const interval = setInterval(() => {
      if (accounts.length > 0) {
        const tokenRequest = {
          account: accounts[0],
          scopes: theScopes,
        };
        instance
          .acquireTokenSilent(tokenRequest)
          .then((response) => {
            TPGlobal.jwt = response.accessToken;
            for (
              let i: number = 0;
              i <= TPGlobal.standardHeaders.length - 1;
              i++
            ) {
              if (TPGlobal.standardHeaders[i].key === "Authorization") {
                TPGlobal.standardHeaders[i].value = "Bearer " + TPGlobal.jwt;
              }
            }
          })
          .catch(async (e) => {
            // Catch interaction_required errors and call interactive method to resolve
            if (e instanceof InteractionRequiredAuthError) {
              await instance.acquireTokenRedirect(tokenRequest);
            }
            //todo log error
            throw e;
          });
      }
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  //keepalive every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      reportKeepAliveBeat();
    }, 1000 * 30);
    return () => clearInterval(interval);
  }, []);

  //#endregion

  useEffect(() => {
    if (adminStateCaseViewer.casesList.length <= 0) {
      let tabId: number;
      let oneItem: Array<MainItemTabType> = horizontalTabsArray.filter(
        (x: MainItemTabType) =>
          x.tabType === "TPClientCloudMain.Client.Components.Cases.CaseViewer"
      );
      if (oneItem.length > 0) {
        tabId = oneItem[0].tabId;
        handleCloseClick(tabId);
      }
    }
  }, [adminStateCaseViewer.casesList]);

  return (
    <TPNotificationContext.Provider
      value={{
        notificationList: adminState.notificationList,
        setAlreadySeenNotificationsCallback,
        removeNotificationsCallback,
      }}
    >
      <TPCaseViewerContext.Provider
        value={{
          handleAddNewCaseViewerCallBack: handleAddNewCaseViewerCallBack,
          handleRemoveNewCaseViewerCallBack: handleRemoveNewCaseViewerCallBack,
          handleUpdateCronTimeNewCaseViewerCallBack:
            handleUpdateCronTimeNewCaseViewerCallBack,
          handleChangeCurrentCaseViewerCallBack:
            handleChangeCurrentCaseViewerCallBack,
          casesCollection: adminStateCaseViewer.casesList,
          currentCase: adminStateCaseViewer.currentCase,
          currentCaseResolveCallback: adminStateCaseViewer.resolveCallback,
          currentTakeNext: adminStateCaseViewer.currentTakeNext,
          updateTimeLine: adminStateCaseViewer.updateTimeLine,
          messageType: adminStateCaseViewer.messageType,
          showMyTasks: showMyTasks,
          takeNext: takeNext,
          showTakeNextCase: showTakeNextCase,
        }}
      >
        <MainContainerStyled className="main-container-styled">
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
          >
            {alreadyHaveToken && (
              <>
                <TPToast></TPToast>
                <MainMenu
                  menuItemClickCallback={handleMenuItemClick}
                  ref={ChildRef}
                />
                <MainHorizontalTabs
                  horizontalTabsArray={horizontalTabsArray}
                  handleTabClickCallBack={handleTabClick}
                  handleCloseClickCallBack={handleCloseClick}
                  handleMouseMoveContentArea={handleMouseMoveContentArea}
                  menuItemClickCallback={handleMenuItemClick}
                />
              </>
            )}
            <div id="modal"></div>
          </MsalAuthenticationTemplate>
        </MainContainerStyled>
      </TPCaseViewerContext.Provider>
    </TPNotificationContext.Provider>
  );
};

export default Main;
