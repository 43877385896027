import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { camelCaseToId } from "@/modules/core/utils/text-regex";
import TPLabel from "../TPLabel/TPLabel";

interface TPRadioGroupInterface {
  onChange?: Function;
  className?: string;
  value: string;
  labelText?: string;
  disabled?: boolean;
  source: Array<TPKeyValue>;
  isHorizontal?: boolean;
  withIcon?: boolean;
  isMandatory?: boolean;
  errorMessage?: string;
  labelStyle?: any;
  radioStyle?: any;
  id?: string;
}
const TPRadioGroup = ({
  onChange,
  className = "",
  value,
  labelText,
  disabled,
  source,
  isHorizontal = false,
  withIcon = false,
  isMandatory = false,
  errorMessage = "",
  labelStyle,
  radioStyle,
  id,
}: TPRadioGroupInterface) => {
  const handleOnValueChange = (event: any) => {
    onChange && onChange(event);
  };

  const getRadioIdBy = (type: string): string => {
    let baseId: string = `${camelCaseToId(type)}-radio-btn`;

    return id ? `${id}-${baseId}` : baseId;
  };

  return (
    <>
      <TPFormControlContainerStyled
        isHorizontal={isHorizontal}
        className={`${withIcon ? "input-group" : ""}`}
        style={radioStyle}
      >
        <TPLabel
          isMandatory={isMandatory}
          labelText={labelText}
          style={labelStyle}
        />
        <div className={`${errorMessage != "" ? " is-invalid" : ""}`}>
          {source &&
            source.map((item) => {
              return (
                <div key={item.key} className="form-check form-check-inline">
                  <input
                    id={getRadioIdBy(item.key)}
                    name={getRadioIdBy(item.key)}
                    className="form-check-input"
                    type="radio"
                    value={item.key}
                    checked={value === item.key}
                    onChange={handleOnValueChange}
                    disabled={disabled}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={getRadioIdBy(item.key)}
                  >
                    {item.value}
                  </label>
                </div>
              );
            })}
        </div>
        {errorMessage != "" && (
          <div className="invalid-feedback">{errorMessage}</div>
        )}
      </TPFormControlContainerStyled>
    </>
  );
};

export default TPRadioGroup;
