import TPGeneralContext from "@/contexts/TPGeneralContext";
import React from "react";
import styled from "styled-components";

import {
  collapsedLeftPosition,
  expandedLeftPosition,
  horizontalTabHeight,
  verticalSpace,
  verticalTabWidth,
} from "@/helpers/generalStyles";

export const MenuVerticalTabStyled = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);

  return `
  	    width: ${verticalTabWidth};
  	    position: fixed;
  	    left:  ${contextData.isLeftMenuCollapsed ? collapsedLeftPosition : expandedLeftPosition};
  	    top: calc(${horizontalTabHeight} - 1px + ${verticalSpace});
  	    overflow-y: auto;
  	    margin-top: 12px;
  	    margin-left: 10px;
  	    bottom: 0;
  	    background-color: #F5F5F5;

  	    ul {
  	        margin: 0;
  	        padding: 0;
  	        border-right: 1px solid #BFBFBF;
            height: 100%;

  	        li {
  	            position: relative;
  	            list-style: none;
  	            margin: 0;
  	            padding: 4px;
  	            min-height: 40px;
  	            background-color: #f7f7f7;
  	            font-size: 11px;
  	            font-weight: 500;
  	            display: flex;
  	            align-items: center;
  	            flex-wrap: wrap;
  	            border-top-left-radius: 5px;
  	            border-bottom-left-radius: 5px;
  	            border: 1px solid #989898;
  	            border-right: none;

  	            min-height: 58px;

  	            margin-bottom: 5px;


                &:first-child{
                  border-top: none;
                }

  	            &.home{
  	              .btn{
  	                width: 100%;
  	                &.tp-btn-icon{
  	                  justify-content: center;
  	                  i{
  	                    font-size: 25px;
  	                    position: initial;
  	                  }
  	                }
  	              }
                    display:flex;
                    flex-direction:column;
                    align-items:stretch;
                    justify-content:center;
  	            }

  	            .btn:not(.home){
  	              i{
  	                position: absolute;
  	                left: 2px;
  	                top: 2px;
  	              }
  	            }

  	            .btn{
  	                &.tp-btn-icon{
  	                    padding: 0;

  	                    span{
  	                        text-decoration: none;
  	                        font-size: 11px;
  	                        font-weight: 500;
  	                    }
  	                }
  	            }

  	            .tp-vertical-tab-close-button{
  	                position: absolute;
  	                top: 0;
  	                right: 2px;
  	                cursor: pointer;
  	                position: absolute;
  	                top: 4px;
  	                right: 4px;
  	                background-color: #DDD;
  	                cursor: pointer;
  	                color: white;
  	                border-radius: 7px;
  	                width: 14px;
  	                height: 14px;
  	                display: flex;
  	                justify-content: center;
  	                font-size: 8px;
  	                align-items: center;

  	                &:hover{
  	                }
  	            }

  	            &.active {
  	                border-left: 8px solid ${theme.colors.primary};
  	                background-color: #fff;
  	                border-right: none !important;
  	                width: 100px;
  	                border-right: 1px solid #fff;
  	                z-index: 99999;
  	                i{
  	                  color: ${theme.colors.primary}
  	                }
  	            }
  	        }
  	    }
  	    `;
});

//We have a copy of this called ContentVerticalNoTabsStyled in this file also
//if you change this check if you also need to change  ContentVerticalNoTabsStyled
export const ContentVerticalTabStyled = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
  position: fixed;
  left: calc(${verticalTabWidth} + ${
    contextData.isLeftMenuCollapsed
      ? collapsedLeftPosition
      : expandedLeftPosition
  } + 10px);
  top:   calc(${horizontalTabHeight} - 1px + ${verticalSpace} + 12px);
  bottom: 0;
  overflow-y: auto;
  right: 0;
  padding: 10px;
  padding-left: 0;
  border: 1px solid #989898;
  border-top: none;

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      padding: 0;

      > div {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
  `;
});

export const ContentVerticalTabInsertUpdateStyled = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
  position: fixed;
  left: calc(${verticalTabWidth} + 20px + ${
    contextData.isLeftMenuCollapsed
      ? collapsedLeftPosition
      : expandedLeftPosition
  });
  top: calc(${horizontalTabHeight} - 1px + ${verticalSpace} + 12px );
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      padding: 0;

      > div {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
  `;
});

//this is a copy of ContentVerticalTabStyled
//if you change this check if you also need to change  ContentVerticalTabStyled
export const ContentVerticalNoTabsStyled = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `
  position: fixed;
  left: calc(${contextData.isLeftMenuCollapsed ? collapsedLeftPosition : expandedLeftPosition} + 10px);
  top:   calc(${horizontalTabHeight} - 1px + ${verticalSpace} + 12px);
  bottom: 0;
  overflow-y: auto;
  right: 0;
  padding: 10px;
  padding-left: 0;


  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      padding: 0;

      > div {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
  `;
});
