import styled from "styled-components";

const primaryColor = "#780096";

const uiIcon = (size: string = "24px") => `
	font-size: ${size};
	width: ${size};
	height: ${size};
`;

export const StyledSearchInput = styled.div<{ width: string; focus: boolean }>`
  width: ${(p) => p.width};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border: 1px solid ${(p) => (!p.focus ? "#bfbfbf" : primaryColor)};
  padding: 0 16px;
  border-radius: 4px;
  height: 32px;

  input {
    border: none;
    outline: none;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
  }

  .ui-icon {
    color: #656565;
    align-items: center;
    ${uiIcon("22px")}
  }

  .clean-input {
    cursor: pointer;
    border-radius: 3px;
    color: #656565;
    ${uiIcon("20px")}

    &:hover {
      background-color: #dcdfe4;
    }
  }
`;

const StyledPagerBase = `
	display: flex;
	align-items: center;
	gap: 0.5em;
	flex-wrap: wrap;

	button {
		margin: 0;
		font-size: 13px;
		font-weight: 500;
		border: 1px solid #dddddd;
		background-color: white;
		border-radius: 4px;
		padding: 4px 9px;

		&:hover {
			background-color: #f3f3f3;
		}

		&:disabled {
			background-color: inherit;
		}
	}
`;

export const StyledClassicPager = styled.div`
  ${StyledPagerBase}

  .ui-icon {
    font-size: 24px;
    width: 24px;
    height: 29px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    align-items: center;

    &:hover {
      background-color: #f3f3f3;
    }
  }
`;

export const StyledDefaultPager = styled.div(
  ({ theme }) => `
	${StyledPagerBase}

	button.contained {
		background-color: ${theme.colors.cim.button.primary.background};
		color: ${theme.colors.cim.button.primary.color};
		border-color: ${theme.colors.cim.button.primary.border};

		&:hover {
			background-color: ${theme.colors.cim.button.primary.hover.background};
			color: ${theme.colors.cim.button.primary.hover.color};
			border-color: ${theme.colors.cim.button.primary.hover.border};
		}
	}
`
);
