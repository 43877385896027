import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPaginator } from "@/components/TPPaginator/TPPaginator";
import { exportToCSV } from "@/helpers/ExportToCSV";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import { ClientBasicViewModel } from "@/models/Client/ClientBasicViewModel";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ClientService } from "@/services/ClientService";
import { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  setCanMerge,
  setMainCustomerData,
  setMergeCustomerData,
} from "../redux/MergeCustomersSlice";
import { MergeCustomersState } from "../redux/MergeCustomersStore";
import useLabels from "../styles/labels";
import "../styles/Styles.css";
import CustomerInfoTabs from "./CustomerInfoTabs";

interface CustomerSearchAccordionProperties {
  title: string;
  id: string;
  type: "main" | "merge";
  dataCallback?: () => ClientAdminViewModel;
}

enum FilterOptions {
  Document = "document",
  Name = "name",
  Phone = "phone",
  Email = "email",
  InternalCode = "internal_code",
  AdditionalData = "additional_data",
}

const CustomerSearchAccordion: FC<CustomerSearchAccordionProperties> =
  function ({ type, dataCallback, id, title }) {
    const dispatch = useDispatch();
    const { labels, labelsLoaded } = useLabels();
    const { mainCustomerData, mergeCustomerData } = useSelector(
      (state: MergeCustomersState) => state.mainData,
    );
    const [loadingContent, setLoadingContent] = useState<boolean>(false);
    const [tabsVisible, setTabsVisible] = useState<boolean>(false);

    const [currentQuery, setCurrentQuery] = useState<string>("");
    const [currentFilter, setCurrentFilter] = useState<string>(
      FilterOptions.Document,
    );
    const [querySubmitted, setQuerySubmitted] = useState<boolean>(false);
    const [customersData, setCustomersData] = useState<ClientBasicViewModel[]>(
      [],
    );
    const [resultQuery, setResultQuery] = useState<string>("");
    const [filteredCustomerData, setFilteredCustomerData] = useState<
      ClientBasicViewModel[]
    >([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [filterSelectOptions, setFilterSelectOption] = useState<TPKeyValue[]>(
      [],
    );

    useEffect(() => {
      if (labelsLoaded)
        setFilterSelectOption([
          { key: FilterOptions.Document, value: labels["document"] },
          { key: FilterOptions.Name, value: labels["name"] },
          { key: FilterOptions.Phone, value: labels["preferredPhone"] },
          { key: FilterOptions.Email, value: labels["preferredEmail"] },
          { key: FilterOptions.InternalCode, value: labels["internalCode"] },
        ]);
    }, [labels]);

    useEffect(() => {
      if (currentPage > filteredCustomerData.length / rowsPerPage)
        setCurrentPage(1);
    }, [rowsPerPage]);

    const searchCustomers = async function (): Promise<void> {
      setLoadingContent(true);
      setTabsVisible(false);
      setQuerySubmitted(true);
      dispatch(setCanMerge(false));
      if (currentQuery.trim().length > 0) {
        const clientServiceInstance = new ClientService();
        switch (currentFilter) {
          case FilterOptions.Document:
            clientServiceInstance
              .getClientByDocumentBasic(currentQuery.trim(), false, false, [
                200,
              ])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
          case FilterOptions.Name:
            clientServiceInstance
              .getClientByNamesBasic(currentQuery.trim(), false, false, [200])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
          case FilterOptions.Email:
            clientServiceInstance
              .getClientByEmailBasic(currentQuery.trim(), false, false, [200])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
          case FilterOptions.Phone:
            clientServiceInstance
              .getClientByPhoneBasic(currentQuery.trim(), false, false, [200])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
          case FilterOptions.InternalCode:
            clientServiceInstance
              .getClientByInternalCodeBasic(currentQuery.trim(), false, false, [
                200,
              ])
              .then((response) => {
                setCustomersData(response);
                setLoadingContent(false);
              })
              .catch((error) => console.warn(error))
              .finally(() => setLoadingContent(false));
            break;
        }
      }
    };

    useEffect(() => {
      setFilteredCustomerData(customersData);
    }, [customersData]);

    useEffect(() => {
      if (resultQuery.trim().length > 0) {
        const lowercaseQuery = resultQuery.toLowerCase();
        setFilteredCustomerData([
          ...customersData.filter(
            (data) =>
              data.document.toLowerCase().includes(lowercaseQuery) ||
              data.name.toLowerCase().includes(lowercaseQuery) ||
              data.clientEmail.toLowerCase().includes(lowercaseQuery) ||
              data.clientPhone.toLowerCase().includes(lowercaseQuery) ||
              data.internalCode.toLowerCase().includes(lowercaseQuery) ||
              data.documentType.toLowerCase().includes(lowercaseQuery),
          ),
        ]);
      } else setFilteredCustomerData([...customersData]);
    }, [resultQuery]);

    return (
      <TPLoadingOverlay active={loadingContent}>
        <div id={`${type}-search-accordion`} className="accordion">
          <div className="accordion-item">
            <div className="accordion-header">
              <button
                className="accordion-button"
                style={{ border: "none", backgroundColor: "#F4F4F4" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${id}-customer-search`}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <TPIcon
                    iconType={TPIconTypes.person}
                    style={{ color: allThemes.base.primary }}
                  />
                  {title}
                </div>
              </button>
            </div>
          </div>
          <div
            id={`${id}-customer-search`}
            className="accordion-collapse collapse show"
          >
            <div className="accordion-body">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <div className="merge-customers-search-container">
                  <div>
                    <TPSelect
                      onChange={(event: any) =>
                        setCurrentFilter(event.target.value)
                      }
                      dataSource={filterSelectOptions}
                      value={currentFilter}
                      labelText={labels.SearchBy}
                      minWidth={256}
                    />
                  </div>
                  <div>
                    <TPTextBox
                      onChange={(event: any) =>
                        setCurrentQuery(event.target.value)
                      }
                      value={currentQuery}
                      withIcon
                      icon={TPIconTypes.search}
                      placeholder={labels.Search}
                      containerStyle={{ width: "256px" }}
                    />
                  </div>
                  <TPButton
                    onClick={() => searchCustomers()}
                    type={TPButtonTypes.primary}
                  >
                    {labels.Search}
                  </TPButton>
                </div>
                {querySubmitted && !tabsVisible && (
                  <>
                    <div className="customer-search-controls">
                      <div>
                        <TPTextBox
                          onChange={(event: any) =>
                            setResultQuery(event.target.value)
                          }
                          value={resultQuery}
                          withIcon
                          icon={TPIconTypes.search}
                          placeholder={labels.Search}
                          containerStyle={{ width: "256px" }}
                        />
                      </div>
                      <div style={{ display: "flex", gap: "16px" }}>
                        <button
                          id="merge-customers-refresh-button"
                          type="button"
                          style={{ border: "none", backgroundColor: "white" }}
                          onClick={() => searchCustomers()}
                        >
                          <TPIcon iconType={TPIconTypes.refresh} />
                        </button>
                        <button
                          id="merge-customers-export-csv"
                          type="button"
                          style={{ border: "none", backgroundColor: "white" }}
                          onClick={() =>
                            exportToCSV(customersData, "CustomersData.csv")
                          }
                          disabled={customersData.length == 0}
                        >
                          <TPIcon iconType={TPIconTypes.excel} />
                        </button>
                      </div>
                    </div>
                    <DataTable
                      fixedHeader
                      fixedHeaderScrollHeight="256px"
                      responsive
                      striped
                      columns={[
                        {
                          name: <b>{labels["id"]}</b>,
                          selector: (row: ClientBasicViewModel) => row.id,
                          cell: (row: ClientBasicViewModel) => (
                            <button
                              style={{
                                border: "none",
                                background: "none",
                                color: allThemes.base.primary,
                              }}
                              onClick={() => {
                                setLoadingContent(true);
                                const clientServiceInstance =
                                  new ClientService();
                                clientServiceInstance
                                  .getClientBySubsidiaryOrganizationId(
                                    row.subsidiaryOrganizationId,
                                    false,
                                    false,
                                    [200],
                                  )
                                  .then((response) => {
                                    type == "main"
                                      ? dispatch(
                                          setMainCustomerData(response[0]),
                                        )
                                      : dispatch(
                                          setMergeCustomerData(response[0]),
                                        );
                                    setLoadingContent(false);
                                    setTabsVisible(true);
                                  })
                                  .catch((error) => console.error(error));
                              }}
                            >
                              <u>{row.id}</u>
                            </button>
                          ),
                          sortable: true,
                        },
                        {
                          name: <b>{labels["internalCode"]}</b>,
                          selector: (row: ClientBasicViewModel) =>
                            row.internalCode,
                          sortable: true,
                        },
                        {
                          name: <b>{labels["documentType"]}</b>,
                          selector: (row: ClientBasicViewModel) =>
                            row.documentType,
                          sortable: true,
                        },
                        {
                          name: <b>{labels["document"]}</b>,
                          selector: (row: ClientBasicViewModel) => row.document,
                          sortable: true,
                        },
                        {
                          name: <b>{labels["name"]}</b>,
                          selector: (row: ClientBasicViewModel) => row.name,
                          sortable: true,
                        },
                        {
                          name: <b>{labels["lastName"]}</b>,
                          selector: (row: ClientBasicViewModel) => row.lastName,
                          sortable: true,
                        },
                        {
                          name: <b>{labels["customerTypeDescription"]}</b>,
                          selector: (row: ClientBasicViewModel) =>
                            row.customerTypeDescription,
                          sortable: true,
                        },
                        {
                          name: <b>{labels["preferredPhone"]}</b>,
                          selector: (row: ClientBasicViewModel) =>
                            row.clientPhone,
                          sortable: true,
                        },
                        {
                          name: <b>{labels["preferredEmail"]}</b>,
                          selector: (row: ClientBasicViewModel) =>
                            row.clientEmail,
                          sortable: true,
                        },
                      ]}
                      data={filteredCustomerData.slice(
                        (currentPage - 1) * rowsPerPage,
                        (currentPage - 1) * rowsPerPage + rowsPerPage,
                      )}
                      noDataComponent={<label>{labels.NoResults}</label>}
                    />
                    <TPPaginator
                      currentPage={currentPage}
                      onChangePage={(page) => setCurrentPage(page)}
                      onChangeRowsPerPage={(rowsPerPage) =>
                        setRowsPerPage(rowsPerPage)
                      }
                      rowCount={filteredCustomerData.length}
                      rowsPerPage={rowsPerPage}
                    />
                  </>
                )}
                {querySubmitted && tabsVisible && (
                  <CustomerInfoTabs
                    customer={
                      type == "main" ? mainCustomerData : mergeCustomerData
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </TPLoadingOverlay>
    );
  };

export default CustomerSearchAccordion;
