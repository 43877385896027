import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import React, { ReactElement, FC } from "react";
import errorImg from "@/assets/images/red-alert-icon.png";

export type TPModalErrorStateType = {
  id?: string;
  isShown: boolean;
  title: string;
  errorMessage: string;
  yesLabel: string;
  callBackFunction: Function;
};

type TPModalErrorProps = {
  modalState: TPModalErrorStateType;
};

const TPModalError: FC<TPModalErrorProps> = ({ modalState }): ReactElement => {
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  if (modalState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }
  return (
    <>
      <div
        id={modalState.id}
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{modalState.title}</h5>
              <TPButton
                type={TPButtonTypes.empty}
                onClick={() => modalState.callBackFunction()}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-3">
                  <img src={errorImg} width={80}></img>
                </div>
                <div className="col">
                  <p className="tpbold">{modalState.errorMessage}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <TPButton
                type={TPButtonTypes.primary}
                onClick={() => modalState.callBackFunction()}
              >
                {modalState.yesLabel}
              </TPButton>
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalError;
