import { Children, useEffect, useReducer, useState } from "react";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
  RecursiveRoutes,
  Data,
  LocalizedValues,
  InserFolder,
  InserFolderAndFile,
} from "@/models/ImagesAdmin/ImagesAdminModel";
import { ImageService } from "@/services/ImageService";
import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";

export class DataType {
  static file: string = "Image";
  static folder: string = "Folder";
}

export class OperationMode {
  static SelectFolder: string = "SelectFolder";
  static ActiveFolder: string = "ActiveFolder";
  static EditFolder: string = "EditFolder";
  static SelectFile: string = "SelectFile";
  static EditFile: string = "EditFile";
  static ActiveMenu: string = "ActiveMenu";
  static ActiveMenuFile: string = "ActiveMenuFile";
  static Defaul: string = "";
}

enum commandsEnum {
  selected_item,
  selected_all,
  initialize_folders,
  initialize_files,
  add_folder,
  add_file,
  disable_folder,
  disable_file,
  update_item,
  filter_data,
}

class modelTPKeyValue {
  key: string = "";
  value: any = "";
  value2?: any = null;
  value3?: any = null;
  value4?: any = null;
  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}

export const useStatusImagesAdmin = () => {
  const resourceSet: string = "ImagesAdminComponent";
  const [listFolderModel, setListFolder] = useState<Array<RecursiveRoutes>>(
    Array<RecursiveRoutes>,
  );
  const [listFilesModel, setListFiles] = useState<Array<RecursiveRoutes>>(
    Array<RecursiveRoutes>,
  );
  const [listFilesModelTemp, setListFilesTemp] = useState<
    Array<RecursiveRoutes>
  >(Array<RecursiveRoutes>);
  const [listMultiFileSelected, setlistMultiFileSelected] = useState<
    Array<RecursiveRoutes>
  >(Array<RecursiveRoutes>);
  const [selectedItem, setSelectedItem] = useState<RecursiveRoutes>(
    new RecursiveRoutes(),
  );
  const [selectedFolderParent, setSelectedFolderParent] =
    useState<RecursiveRoutes>(new RecursiveRoutes());
  const [operationMode, setOperationMode] = useState<string>("");

  //option
  const [optionList, setOptionList] = useState<Array<TPKeyValue>>([]);
  const [optionStatusList, setOptionStatusList] = useState<Array<TPKeyValue>>(
    [],
  );
  const [sortAscendingOrDescending, setSortAscendingOrDescending] =
    useState<boolean>(false);
  const [filterStatus, setFlterStatus] = useState<string>("All");
  const [orderBy, setOrderBy] = useState<string>("Name");
  const [filter, setFiltery] = useState<string>("");
  const [viewListAndGrid, setViewList] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Label
  const [nameItem, setNameItem] = useState("");
  const [sizeItem, setSizeItem] = useState("");
  const [dateItem, setDateItem] = useState("");

  const [allItem, setAllItem] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const [inactiveItem, setInactiveItem] = useState("");

  useEffect(() => {
    var data = [...optionList];
    if (nameItem != "" && !data.find((s) => s.value == nameItem))
      data.push(new modelTPKeyValue("Name", nameItem));
    if (sizeItem != "" && !data.find((s) => s.value == sizeItem))
      data.push(new modelTPKeyValue("Size", sizeItem));
    if (dateItem != "" && !data.find((s) => s.value == dateItem))
      data.push(new modelTPKeyValue("Date", dateItem));

    setOptionList(data);
  }, [nameItem, sizeItem, dateItem]);

  useEffect(() => {
    var data = [...optionList];
    if (allItem != "" && !data.find((s) => s.value == allItem))
      data.push(new modelTPKeyValue("All", allItem));
    if (sizeItem != "" && !data.find((s) => s.value == activeItem))
      data.push(new modelTPKeyValue("Active", activeItem));
    if (dateItem != "" && !data.find((s) => s.value == inactiveItem))
      data.push(new modelTPKeyValue("Inactive", inactiveItem));

    setOptionStatusList(data);
  }, [allItem, activeItem, inactiveItem]);

  const loadResourcesAndOrganizations = async () => {
    setNameItem(await TPI18N.GetText(resourceSet, "NameItem"));
    setSizeItem(await TPI18N.GetText(resourceSet, "SizeItem"));
    setDateItem(await TPI18N.GetText(resourceSet, "DateItem"));

    setAllItem(await TPI18N.GetText(resourceSet, "AllItem"));
    setActiveItem(await TPI18N.GetText(resourceSet, "ActiveItem"));
    setInactiveItem(await TPI18N.GetText(resourceSet, "InactiveItem"));
  };

  const reducerAction = (
    status = Array<RecursiveRoutes>(),
    action: { type: commandsEnum; payload: RecursiveRoutes },
  ) => {
    switch (action.type) {
      case commandsEnum.initialize_folders:
        if (status == undefined || status == null)
          status = Array<RecursiveRoutes>();
        var temp = [...status];
        temp.push(action.payload);
        getSortTPKeyValue();
        iterateData(temp, action.payload, commandsEnum.initialize_folders);
        return temp;
        break;
        return status;
      case commandsEnum.initialize_files:
        var temp = [...status];
        iterateData(temp, selectedItem, commandsEnum.initialize_files);
        return temp;
        break;
        return temp;
      case commandsEnum.selected_item:
        var temp = [...status];
        setSelectedItem(action.payload);
        if (action.payload.type == DataType.folder) {
          setSelectedFolderParent(action.payload);
        }
        return status;
        break;
      case commandsEnum.add_folder:
        if (status == undefined || status == null)
          status = Array<RecursiveRoutes>();
        var temp = [...status];
        selectedItem.childrens.push(action.payload);
        return temp;
        break;
      case commandsEnum.update_item:
        if (status == undefined || status == null)
          status = Array<RecursiveRoutes>();
        var temp = [...status];

        if (action.payload.type == DataType.folder) {
          var tempFolder = selectedItem.childrens.find(
            (s) => s.id != action.payload.id,
          );
          tempFolder = action.payload;
        }

        if (action.payload.type == DataType.file) {
          var tempFilter = selectedItem.childrens.find(
            (s) => s.id == action.payload.id,
          );
          tempFilter = action.payload;
        }
        return temp;
        break;
      case commandsEnum.disable_folder:
        if (status == undefined || status == null)
          status = Array<RecursiveRoutes>();
        var temp = [...status];
        var temp1 = selectedItem.childrens.find(
          (s) => s.id != action.payload.id,
        );
        temp1 = action.payload;
        return temp;
        break;
      case commandsEnum.filter_data:
        var temp = [...status];

        setListFiles(listFilesModelTemp);
        var data = [...listFilesModelTemp];

        if (filter.trim() != "") {
          data = [...listFilesModelTemp].filter((s) =>
            s.fileName.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
          );
          setListFiles(data);
        }

        if (filterStatus == "Active") {
          data = [...data].filter((s) => s.isActive == true);
          setListFiles(data);
        } else if (filterStatus == "Inactive") {
          data = [...data].filter((s) => s.isActive == false);
          setListFiles(data);
        }

        if (orderBy == "Name") {
          data.sort((a, b) => {
            return !sortAscendingOrDescending
              ? a.fileName.localeCompare(b.fileName)
              : b.fileName.localeCompare(a.fileName);
          });
        }

        if (orderBy == "Size") {
          data.sort((a, b) => {
            if (!sortAscendingOrDescending) {
              return a.size - b.size;
            } else {
              return b.size - a.size;
            }
          });
        }

        if (orderBy == "Date") {
          data.sort((a, b) => {
            const dateA = new Date(a.dateUpload);
            const dateB = new Date(b.dateUpload);
            if (sortAscendingOrDescending) {
              return dateA.getTime() - dateB.getTime();
            } else {
              return dateB.getTime() - dateA.getTime();
            }
          });
        }

        setListFiles(data);

        return temp;
        break;
      default:
        return status;
    }
  };

  //Itera las rutas como recursivas y busca por id de padre
  const iterateFolder = (
    list: Array<RecursiveRoutes>,
    newFolder: RecursiveRoutes,
    action: commandsEnum,
    level: number = 0,
  ) => {
    var mode = false;
    list.forEach((element) => {
      const node = element;
      if (element.id == newFolder.parent) {
        mode = true;
        if (action == commandsEnum.disable_folder) {
          element.isActive = !element.isActive;
          LoadData(list, newFolder);
        }
      }
      if (node.childrens.length > 0 && !mode) {
        iterateFolder(node.childrens, newFolder, level + 1);
      }
    });
  };

  //Itera las rutas como recursivas y busca por id de ruta
  const iterateData = (
    list: Array<RecursiveRoutes>,
    newFolder: RecursiveRoutes,
    action: commandsEnum,
    level: number = 0,
  ) => {
    var mode = false;

    list.forEach((element) => {
      const node = element;

      if (element.id == newFolder.id) {
        mode = true;

        if (
          action == commandsEnum.initialize_folders &&
          element.type == DataType.folder
        ) {
          var data = [...element.childrens].filter(
            (s) => s.type == DataType.folder,
          );
          var temp = { ...element };
          temp.childrens = data;

          var folder;
          if (listFolderModel)
            folder = listFolderModel.find((s) => s.id == element.id);

          if (folder) {
            if (folder.childrens == undefined)
              folder.childrens = Array<RecursiveRoutes>();
            folder.childrens = data;
          } else {
            listFolderModel.push(temp);
          }

          setListFolder(listFolderModel);
        }
      }
      if (node.childrens.length > 0 && !mode) {
        iterateFolder(node.childrens, newFolder, level + 1);
      }
    });
  };

  //Comun para reiniciar carpetas y archivos
  const LoadData = (
    list: Array<RecursiveRoutes>,
    newFolder: RecursiveRoutes,
  ) => {
    setListFolder(new Array<RecursiveRoutes>());
    iterateData(list, newFolder, commandsEnum.initialize_folders);
    iterateData(list, selectedFolderParent, commandsEnum.initialize_files);
  };

  //Inicializa  valores
  const getSortTPKeyValue = () => {
    loadResourcesAndOrganizations();
  };

  const [status, dispatch] = useReducer(
    reducerAction,
    new Array<RecursiveRoutes>(),
  );

  //Llamado al inicializar el componente para mostrar las carpetas y archivos
  const handleInitializeFolders = () => {
    setIsLoading(true);
    let _expectedCodes: number[] = [200];
    let request = new ImageService(_expectedCodes);
    var responseData = request.getFilterAllFolderAndFile(0);

    if (responseData) {
      responseData.then((e: Array<Data>) => {
        console.log("Data images", e);
        var temp = Array<RecursiveRoutes>();
        if (e) {
          temp = convertToRecursiveRoutes(e);
          temp.forEach((f) => {
            dispatch({ type: commandsEnum.initialize_folders, payload: f });
          });
          handleLoading(false);
        } else {
          handleLoading(false);
        }
      });
    }
  };

  const handleLoading = (mode: boolean) => {
    setIsLoading(mode);
  };

  //Llamado al inicializar el componente crear la lista e archivos
  const handleInitializeFiles = (
    data: RecursiveRoutes = new RecursiveRoutes(),
  ) => {
    handleLoading(true);
    let _expectedCodes: number[] = [200];
    let request = new ImageService(_expectedCodes);
    var responseData;

    if (data.type == DataType.folder) {
      responseData = request.getFilterAllFolderAndFile(
        data.id,
        DataType.file,
        2,
      );

      if (responseData) {
        responseData.then((e: Array<Data>) => {
          var temp = Array<RecursiveRoutes>();
          temp = convertToRecursiveRoutesFiles(e);
          setListFiles(temp);
          setListFilesTemp(temp);
          handleLoading(false);
        });
      }
    }
  };

  //Acción para seleccionar carpetas o archivos y el tipo de selección, folder,file, fileEdit, etc..
  const handleSelected = (data: RecursiveRoutes, _operationMode: string) => {
    setSelectedItem(data);
    setOperationMode(_operationMode);
    dispatch({ type: commandsEnum.selected_item, payload: data });

    if (
      data.type == DataType.folder &&
      _operationMode == OperationMode.SelectFolder
    ) {
      handleInitializeFiles(data);
      handleLoading(true);
    }
  };

  //Agregar o elimina varios elementos a l lista multiple como seleccionado selección
  const handleMultiSelectedFile = (item: RecursiveRoutes) => {
    var list = [...listMultiFileSelected];
    setlistMultiFileSelected(new Array<RecursiveRoutes>());
    if (list.find((s) => s.id == item.id)) {
      list = list.filter((s) => s.id != item.id);
      setlistMultiFileSelected(list);
    } else {
      list.push(item);
      setlistMultiFileSelected(list);
    }
  };

  //Permite seleccionar todos los archivos de la carpeta seleccionada
  const handleAllSelectedFile = () => {
    setlistMultiFileSelected(listFilesModel);
  };

  //Elimina la multi selección de archivos
  const handleMultiSelectedFileClear = () => {
    setlistMultiFileSelected(new Array<RecursiveRoutes>());
  };

  //Crea de manera temporal un nuevo folder
  const handleNewFolder = (folder: RecursiveRoutes) => {
    var newFolder = new RecursiveRoutes();
    newFolder.folderName = "New Folder";
    newFolder.type = DataType.folder;
    newFolder.parent = folder.id;
    newFolder.isActive = true;
    newFolder.id = 0; // Eliminar
    newFolder.userUpload = TPGlobal.currentUserGuid;
    newFolder.folderNameLocalizedValues = [
      { languageId: "es", localizedValue: "System" },
    ];

    let temp = handleConvertRecursiveRouterToInserFolder(newFolder);

    let _expectedCodes: number[] = [200];
    let request = new ImageService(_expectedCodes);
    request.insertFolder(temp).then((e) => {
      dispatch({ type: commandsEnum.add_folder, payload: newFolder });
      handleInitializeFolders();
    });
  };

  //Actualiza carpetas
  const handleUpdateFolder = (folder: RecursiveRoutes) => {
    let _expectedCodes: number[] = [200];
    let request = new ImageService(_expectedCodes);
    let temp = handleConvertRecursiveRouterToUpdate(folder);
    request.updateFolder(temp).then((e) => {
      handleLoading(false);
    });
  };

  //Actualiza  archivos
  const handleUpdateFile = (file: RecursiveRoutes) => {
    let _expectedCodes: number[] = [200];
    let request = new ImageService(_expectedCodes);
    let temp = handleConvertRecursiveRouterToUpdate(file);
    request.updateFolder(temp).then((e) => {
      handleLoading(false);
    });
  };

  //Desactivar una carpeta
  const handleDisableFolder = (folder: RecursiveRoutes) => {
    let _expectedCodes: number[] = [200];
    let request = new ImageService(_expectedCodes);
    let temp = handleConvertRecursiveRouterToUpdate(folder);
    request.updateFolder(temp).then((e) => {
      if (e)
        if (
          e.responseData.responseMessage.code &&
          e.responseData.responseMessage.code == 200
        ) {
          dispatch({ type: commandsEnum.update_item, payload: folder });
          handleInitializeFiles(selectedFolderParent);
        }
    });
  };

  //Desactivar una archivo
  const handleDisableFile = (folder: RecursiveRoutes) => {
    let _expectedCodes: number[] = [200];
    let request = new ImageService(_expectedCodes);
    let temp = handleConvertRecursiveRouterToUpdate(folder);
    request.updateFolder(temp).then((e) => {
      if (e)
        if (
          e.responseData.responseMessage.code &&
          e.responseData.responseMessage.code == 200
        ) {
          dispatch({ type: commandsEnum.update_item, payload: folder });
          handleInitializeFiles(selectedFolderParent);
        }
    });
  };

  //Ordena
  const handleOrderData = (mode: string) => {
    dispatch({
      type: commandsEnum.filter_data,
      payload: new RecursiveRoutes(),
    });
  };

  //Ordenar por cualquier descendente y ascendente
  const handleSortAscendingOrDescending = (mode: boolean) => {
    setSortAscendingOrDescending(mode);
    dispatch({
      type: commandsEnum.filter_data,
      payload: new RecursiveRoutes(),
    });
  };

  const handleSortFolderActive = (mode: string) => {
    setFlterStatus(mode);
    dispatch({
      type: commandsEnum.filter_data,
      payload: new RecursiveRoutes(),
    });
  };

  //Cambia entre vistas lista o grid
  const handleSortView = (mode: boolean) => {
    setViewList(mode);
  };

  //Ordenar por nombre  , peso etc
  const handleOrderBy = (mode: string) => {
    setOrderBy(mode);
    dispatch({
      type: commandsEnum.filter_data,
      payload: new RecursiveRoutes(),
    });
  };

  //Filtra archivos
  const handleFilter = (mode: string) => {
    setFiltery(mode);
    dispatch({
      type: commandsEnum.filter_data,
      payload: new RecursiveRoutes(),
    });
  };

  //Subir archivos
  const handleUploadImage = (file: any) => {
    handleLoading(true);
    let _expectedCodes: number[] = [200];
    let request = new ImageService(_expectedCodes, true);
    if (selectedFolderParent) {
      request.UploadFile(file, selectedFolderParent.id).then((e) => {
        handleInitializeFiles(selectedFolderParent);
        handleLoading(false);
      });
    }
  };

  const handleConvertRecursiveRouterToInserFolder = (
    data: RecursiveRoutes,
  ): InserFolder => {
    let temp = new InserFolder();
    temp.folderName = data.folderName;
    temp.keywords = data.keywords;
    temp.isActive = data.isActive;
    temp.parent = data.parent;
    temp.guidUserUpload = data.userUpload;
    temp.folderNameLocalizedValues = data.folderNameLocalizedValues;

    return temp;
  };

  const handleConvertRecursiveRouterToUpdate = (
    data: RecursiveRoutes,
  ): InserFolderAndFile => {
    let temp = new InserFolderAndFile();
    temp.folderName = data.folderName;
    temp.id = data.id;
    temp.fileName = data.fileName;
    temp.keywords = data.keywords;
    temp.isActive = data.isActive;
    temp.parent = data.parent;
    temp.guidUserUpload = TPGlobal.currentUserGuid;
    temp.folderNameLocalizedValues = [
      { languageId: "es", localizedValue: "System", order: 0 },
    ];

    return temp;
  };

  function convertToRecursiveRoutes(dataList: Data[]): RecursiveRoutes[] {
    const map = new Map<number, RecursiveRoutes>();

    dataList.forEach((data) => {
      map.set(data.id, {
        id: data.id,
        folderName: data.folderName,
        type: data.type,
        parent: data.parent,
        fileName: data.fileName,
        extension: data.extension,
        dateUpload: data.dateUpload,
        userUpload: data.guidUserUpload,
        keywords: data.keywords,
        size: data.size,
        dimensions: data.dimensions,
        blobId: data.blobId,
        isActive: data.isActive,
        imageUrl: data.imageUrl?.trim() || "",
        thumbnailUrl: data.thumbnailUrl?.trim() || "",
        folderNameLocalizedValues: data.folderNameLocalizedValues,
        childrens: [],
      });
    });

    const roots: RecursiveRoutes[] = [];

    map.forEach((route) => {
      if (route.parent !== undefined && map.has(route.parent)) {
        map.get(route.parent)!.childrens.push(route);
      } else {
        roots.push(route);
      }
    });

    return roots;
  }

  function convertToRecursiveRoutesFiles(dataList: Data[]): RecursiveRoutes[] {
    let list = new Array<RecursiveRoutes>();

    let data = dataList.map((data) => {
      list.push({
        id: data.id,
        folderName: data.folderName,
        type: data.type,
        parent: data.parent,
        fileName: data.fileName,
        extension: data.extension,
        dateUpload: data.dateUpload,
        userUpload: data.guidUserUpload,
        keywords: data.keywords,
        size: data.size,
        dimensions: data.dimensions,
        blobId: data.blobId,
        isActive: data.isActive,
        imageUrl: data.imageUrl?.trim() || "",
        thumbnailUrl: data.thumbnailUrl?.trim() || "",
        folderNameLocalizedValues: data.folderNameLocalizedValues,
        childrens: [],
      });
    });

    return list;
  }

  const formatDate = (dateTemp: string) => {
    const date = new Date(dateTemp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();

    return `${year}/${month}/${day}`;
  };

  return {
    status,
    listFilesModel,
    listFolderModel,
    selectedItem,
    selectedFolderParent,
    optionList,
    optionStatusList,
    sortAscendingOrDescending,
    viewListAndGrid,
    orderBy,
    filterStatus,
    listMultiFileSelected,
    operationMode,
    isLoading,
    handleMultiSelectedFile,
    handleMultiSelectedFileClear,
    handleAllSelectedFile,
    handleSelected,
    handleNewFolder,
    handleInitializeFolders,
    handleInitializeFiles,
    handleUpdateFolder,
    handleUpdateFile,
    handleDisableFolder,
    handleDisableFile,
    handleOrderData,
    handleSortAscendingOrDescending,
    handleSortFolderActive,
    handleSortView,
    handleOrderBy,
    handleFilter,
    handleUploadImage,
    handleLoading,
  };
};
