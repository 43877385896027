import { ReactElement } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import TPLoadingSpinner from "./TPLoadingSpinner";

import styled from "styled-components";

const LoadingOverlayContainer = styled(LoadingOverlay)(
  ({ theme }) => `
  .tp-loading-overlay-loader-overlay {
    background-color: ${theme.colors.loading.loadingOverlay.backgroundColor};
  }
`
);

const TPLoadingOverlay = ({
  children,
  active,
  top,
  styles,
}: any): ReactElement => {
  return (
    <LoadingOverlayContainer
      classNamePrefix="tp-loading-overlay-loader-"
      className="container-fluid"
      styles={{
        overlay: {
          position: "absolute",
          height: "100%",
          width: "100%",
          top: "0px",
          left: "0px",
          display: "flex",
          textAlign: "center",
          fontSize: "1.2em",
          color: "#FFF",
          zIndex: "800",
          transition: "opacity 500ms ease-in",
          opacity: "1",
          img: {
            /*maxWidth: "10%",*/
            height: "50px",
          },
        },
        wrapper: {
          overflow: "auto",
          height: "calc(100% - 23px)",
          paddingBottom: "1em",
        },
      }}
      active={active}
      spinner={<TPLoadingSpinner top={top} />}
    >
      {children}
    </LoadingOverlayContainer>
  );
};

export default TPLoadingOverlay;
