import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageSectionTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import { FunctionAssignmentsViewModel } from "@/models/Functions/FunctionAssignmentsModels";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { UserViewModel } from "@/models/Users/UserModels";
import { FunctionAssignmentsService } from "@/services/FunctionAssignmentsService";
import { TPI18N } from "@/services/I18nService";
import FileSaver from "file-saver";
import { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { tableStyles } from "../FormDesigner/StyeFromDesigner";

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<UserViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

type commandType = {
  type: commandsEnum;
  payload: any;
};

interface properties {
  currentUser: any;
  newUser: any;
  callBack: Function;
}

interface ISelectedRows {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: FunctionAssignmentsViewModel[] | never[];
}

const ReassignUsersFunctionsComponent: React.FC<properties> = ({
  currentUser,
  newUser,
  callBack,
}) => {
  const componentFileName: string = "ReassignUsersFunctionsComponent.tsx";
  const resourceFunctionsTable = "ReassignUsersFunctionsTableComponent";
  const resourceFunctionsModal = "ReassignUsersFunctionsModalComponent";

  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
  const [data, setData] = useState<UserViewModel[]>([]);
  const [filteredData, setFilteredData] = useState<UserViewModel[]>([]);

  const [searchLabel, setSearchLabel] = useState("");
  const [exportLabel, setExportLabel] = useState("");
  const [refreshLabel, setRefreshLabel] = useState("");

  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalSuccessTitle, setModalSuccessTitle] = useState("");
  const [modalActions, setModalActions] = useState(false);

  const [selectedRecordsLabel, setSelectedRecordsLabel] = useState("");
  const [recordsProcessedLabel, setRecordsProcessedLabel] = useState("");
  const [recordsFailedLabel, setRecordsFailedLabel] = useState("");

  const [reassignUserButtonDisabled, setReassignUserButtonDisabled] =
    useState(true);

  const [reassignButtonLabel, setReassignButtonLabel] = useState("");

  const [functionColumnLabel, setFunctionColumnLabel] = useState("");
  const [clientServiceColumnLabel, setClientServiceColumnLabel] = useState("");
  const [classificator1ColumnLabel, setClassificator1ColumnLabel] =
    useState("");
  const [classificator2ColumnLabel, setClassificator2ColumnLabel] =
    useState("");
  const [classificator3ColumnLabel, setClassificator3ColumnLabel] =
    useState("");
  const [classificator4ColumnLabel, setClassificator4ColumnLabel] =
    useState("");
  const [classificator5ColumnLabel, setClassificator5ColumnLabel] =
    useState("");

  const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");

  const loadResourcesAndUsersFilter = async () => {
    setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
    setExportLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
    );
    setRefreshLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
    );
    setSelectedRecordsLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectedRecordsLabel")
    );
    setRecordsProcessedLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "RecordsProcessedLabel")
    );
    setRecordsFailedLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "RecordsFailedLabel")
    );
    setModalSuccessTitle(
      await TPI18N.GetText(resourceFunctionsModal, "SuccessTitleLabel")
    );
    setReassignButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReassignButtonLabel")
    );
    setFunctionColumnLabel(
      await TPI18N.GetText(resourceFunctionsTable, "FunctionColumnLabel")
    );
    setClientServiceColumnLabel(
      await TPI18N.GetText(resourceFunctionsTable, "ClientServiceColumnLabel")
    );
    setClassificator1ColumnLabel(
      await TPI18N.GetText(resourceFunctionsTable, "Classificator1ColumnLabel")
    );
    setClassificator2ColumnLabel(
      await TPI18N.GetText(resourceFunctionsTable, "Classificator2ColumnLabel")
    );
    setClassificator3ColumnLabel(
      await TPI18N.GetText(resourceFunctionsTable, "Classificator3ColumnLabel")
    );
    setClassificator4ColumnLabel(
      await TPI18N.GetText(resourceFunctionsTable, "Classificator4ColumnLabel")
    );
    setClassificator5ColumnLabel(
      await TPI18N.GetText(resourceFunctionsTable, "Classificator5ColumnLabel")
    );
    setThereAreNoRecordsToShow(
      await TPI18N.GetText(resourceFunctionsTable, "ThereAreNoRecordsToShow")
    );
  };

  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  const [functionsSelectedRows, setFunctionsSelectedRows] =
    useState<ISelectedRows>({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });

  useEffect(() => {
    setReassignUserButtonDisabled(
      !newUser || functionsSelectedRows.selectedCount == 0
    );
  }, [newUser, functionsSelectedRows]);

  const setupGridColumns = (prevState: AdminStateType) => {
    try {
      let newState: AdminStateType;
      newState = { ...prevState };
      let newColumns: Array<any> = [];

      //function
      newColumns.push({
        name: functionColumnLabel,
        cell: (row: { [x: string]: any }) => row["functionDescription"],
        selector: (row: { [x: string]: any }) => row["function"],
        sortable: true,
      });

      //organization/relation
      newColumns.push({
        name: clientServiceColumnLabel,
        cell: (row: { [x: string]: any }) => row["baseLevelSourceDescription"],
        selector: (row: { [x: string]: any }) => row["clientService"],
        sortable: true,
      });

      //contact method/source
      newColumns.push({
        name: classificator1ColumnLabel,
        selector: (row: { [x: string]: any }) => row["classificator1"],
        cell: (row: { [x: string]: any }) => row["branch1Description"],
        sortable: true,
      });

      //type
      newColumns.push({
        name: classificator2ColumnLabel,
        selector: (row: { [x: string]: any }) => row["classificator2"],
        cell: (row: { [x: string]: any }) => row["branch2Description"],
        sortable: true,
      });

      //process
      newColumns.push({
        name: classificator3ColumnLabel,
        selector: (row: { [x: string]: any }) => row["classificator3"],
        cell: (row: { [x: string]: any }) => row["branch3Description"],
        sortable: true,
      });

      //location
      newColumns.push({
        name: classificator4ColumnLabel,
        selector: (row: { [x: string]: any }) => row["classificator4"],
        cell: (row: { [x: string]: any }) => row["branch4Description"],
        sortable: true,
      });

      //priority
      newColumns.push({
        name: classificator5ColumnLabel,
        selector: (row: { [x: string]: any }) => row["classificator5"],
        cell: (row: { [x: string]: any }) => row["branch5Description"],
        sortable: true,
      });

      newState.gridColumns = [...newColumns];
      return newState;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} setupGridColumns ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} setupGridColumns ex`);
      return prevState;
    }
  };

  const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
  };

  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);

  function doCommand(prevState: AdminStateType, command: commandType) {
    switch (command.type) {
      case commandsEnum.set_filterIsLoaded:
        let newStateFilter: AdminStateType;
        newStateFilter = { ...prevState };
        newStateFilter.filterIsLoaded = true;
        return newStateFilter;
      case commandsEnum.setup_grid_columns:
        let newStateColumns: AdminStateType = setupGridColumns(prevState);
        newStateColumns.columnsAreLoaded = true;
        return newStateColumns;
      case commandsEnum.change_selectedFilter:
        let newStateChangeFilter: AdminStateType;
        newStateChangeFilter = { ...prevState };
        newStateChangeFilter.selectedFilter = command.payload;
        return newStateChangeFilter;
      case commandsEnum.change_search_pattern:
        let newStatePattern: AdminStateType;
        newStatePattern = { ...prevState };
        newStatePattern.searchPattern = command.payload;
        return newStatePattern;
      default:
        return prevState;
    }
  }

  const handleReassignUser = async () => {
    let serviceClient = new FunctionAssignmentsService();
    let expectedCodes: Array<number> = [200, 404];

    let guids = [];

    for (let i = 0; i <= functionsSelectedRows.selectedRows.length - 1; i++) {
      guids.push(functionsSelectedRows.selectedRows[i].guidId);
    }

    let DTO = {
      GuidIds: guids,
      GuidUserNew: newUser.key,
      GuidUserCurrent: currentUser.key,
      GuidTypistId: TPGlobal.currentUserGuid,
    };

    try {
      let responseRequest = await serviceClient.reassignFunctionAssignment(
        DTO,
        false,
        true,
        expectedCodes
      );

      setModalSuccess(true);

      console.log(responseRequest);

      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reloadDataGrid ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} reloadDataGrid ex`);
      return [];
    }
  };

  const reloadDataGrid = async (selectedFilter: string) => {
    let serviceClient = new FunctionAssignmentsService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getFunctionAssignmentsByUserId(
        currentUser.key,
        false,
        true,
        expectedCodes
      );

      setIsLoadingScreen(false);

      callBack(responseRequest.length);

      return [...responseRequest];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reloadDataGrid ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} reloadDataGrid ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const reloadGridCommand = () => {
    reloadDataGrid(adminState.selectedFilter)
      .then(function (result) {
        let command1: commandType = {
          type: commandsEnum.reload_grid,
          payload: result,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadGridCommand ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadGridCommand ex`);
      });
  };

  useEffect(() => {
    if (adminState.columnsAreLoaded) {
      reloadGridCommand();
    }
  }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

  const handleSearchPatternChange = (newValue: string) => {
    let command1: commandType = {
      type: commandsEnum.change_search_pattern,
      payload: newValue,
    };
    dispatchCommand(command1);
  };

  const fetchData = async function () {
    setIsLoadingScreen(true);
    const serviceClientInstance = new FunctionAssignmentsService();
    const response = await serviceClientInstance.getFunctionAssignmentsByUserId(
      currentUser.key,
      false,
      false,
      [200]
    );

    setData(response);
    setFilteredData(response);
    callBack(response.length);
    setIsLoadingScreen(false);
  };

  useEffect(() => {
    let searcheableColumns: Array<string> = [
      "functionDescription",
      "baseLevelSourceDescription",
      "branch1Description",
      "branch2Description",
      "branch3Description",
      "branch4Description",
      "branch5Description",
    ];
    let i: number;
    let search: string;
    search = adminState.searchPattern.trim();
    setFilteredData(
      data.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]]
              ? itemany[searcheableColumns[i]]
                  .toLowerCase()
                  .includes(search.toLowerCase())
              : false
          ) {
            return item;
          }
        }
      })
    );
  }, [adminState.searchPattern]);

  useEffect(() => {
    fetchData();
    loadResourcesAndUsersFilter()
      .then(function () {
        //set filter is loaded
        let command1: commandType = {
          type: commandsEnum.set_filterIsLoaded,
          payload: null,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResourcesAndUsersFilter ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} loadResourcesAndUsersFilter ex`
        );
      });
  }, []);

  //Run when filter is loaded to get columns
  useEffect(() => {
    if (adminState.filterIsLoaded) {
      let command1: commandType = {
        type: commandsEnum.setup_grid_columns,
        payload: null,
      };
      dispatchCommand(command1);
    }
  }, [adminState.filterIsLoaded]);

  const handleSelectedRowsChange = (data: ISelectedRows) => {
    setFunctionsSelectedRows(data);
  };

  const exportToCSV = (
    apiData = filteredData,
    fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  ) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    /* custom headers */
    XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  useEffect(() => {
    if (currentUser) fetchData();
  }, [currentUser]);

  const handleClick = () => {
    const element = document.getElementsByName("select-all-rows");
    if (!functionsSelectedRows.allSelected) element[0]?.click();
    setTimeout(() => element[0]?.click(), 200);
  };

  const handleFetchData = () => {
    fetchData();
    handleClick();
  };

  return (
    <>
      <TPPageFirstRow>
        <TPPageSearchContainer>
          <TPTextBox
            id="IdTextBox"
            icon={TPIconTypes.search}
            withIcon={true}
            value={adminState.searchPattern}
            placeholder={searchLabel}
            onChange={(e: any) => handleSearchPatternChange(e.target.value)}
            isHorizontal={true}
          />
        </TPPageSearchContainer>

        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TPButton
            id="IdButton"
            type={TPButtonTypes.icon}
            onClick={() =>
              exportToCSV(filteredData, "ReassignUsers-functions-data")
            }
            icon={TPIconTypes.excel}
            text={exportLabel}
          />
          <TPButton
            id="IdButton"
            type={TPButtonTypes.icon}
            onClick={() => handleFetchData()}
            text={refreshLabel}
            icon={TPIconTypes.refresh}
          />
          <TPPageAcceptCancelButtonsContainer>
            <TPButton
              id="IdButton"
              type={TPButtonTypes.primary}
              onClick={handleReassignUser}
              disabled={reassignUserButtonDisabled}
            >
              {reassignButtonLabel}
            </TPButton>
          </TPPageAcceptCancelButtonsContainer>
        </div>
      </TPPageFirstRow>
      <div style={{ width: "100%" }}>
        <DataTable
          persistTableHead={true}
          fixedHeader={true}
          fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
          responsive={true}
          striped={true}
          highlightOnHover={true}
          pagination
          paginationPerPage={10}
          paginationComponentOptions={TPGlobal.paginationComponentOptions}
          columns={adminState.gridColumns}
          data={filteredData as any}
          noDataComponent={thereAreNoRecordsToShow}
          sortFunction={TPGlobal.datatableCustomSort}
          customStyles={tableStyles}
          onSelectedRowsChange={handleSelectedRowsChange}
          selectableRows={true}
          selectableRowsHighlight
        />
      </div>
      <TPModalSuccess
        modalState={{
          title: "success",
          yesLabel: "Ok",
          isShown: modalSuccess,
          callBackFunction: () => {
            setModalSuccess(false);
            setModalActions(false);
            fetchData();
            handleClick();
          },
        }}
      >
        <TPPageSectionTitle>{modalSuccessTitle}</TPPageSectionTitle>
        <br />
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{selectedRecordsLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
            }
          >
            {functionsSelectedRows.selectedCount}
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{recordsProcessedLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge"
            }
            style={{ backgroundColor: "green" }}
          >
            {functionsSelectedRows.selectedCount}
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{recordsFailedLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge"
            }
            style={{ backgroundColor: "red" }}
          >
            0
          </div>
        </div>
      </TPModalSuccess>
    </>
  );
};

export default ReassignUsersFunctionsComponent;
