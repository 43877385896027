import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import {
  FishCaseModel,
  GroupTakeNextGroupModel,
  GroupTakeNextInsertStatisticsModel,
  GroupTakeNextMomentInsertModel,
  GroupTakeNextResponseModel,
} from "@/models/GroupTakeNext/GroupTakeNextModel";

export class GroupTakeNextService {
  serviceFileName: string = "GroupTakeNextService.ts";

  public async getTakeNext(
    userID: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<GroupTakeNextGroupModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups/take-next?userId=" + TPGlobal.currentUserGuid;
    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTakeNext ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getTakeNext`);
      throw new Error(`Error ${this.serviceFileName} getTakeNext`);
    }
  }

  public static async fishCase(
    requestBody: FishCaseModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<GroupTakeNextResponseModel> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/groups/fish-case";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      console.error(error);
      throw new Error(`Unexpected error when trying to fish case: ${error}`);
    }
  }

  public static async insertMoment(
    requestBody: GroupTakeNextMomentInsertModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/takenext";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData;
    } catch (error) {
      console.error(error);
      throw new Error(`Unexpected error when trying to fish case: ${error}`);
    }
  }

  public static async keepAlive(
    momentId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/takenext/" +
      `${momentId}/keep-alive`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData;
    } catch (error) {
      console.error(error);
      throw new Error(`Unexpected error when trying to keep alive: ${error}`);
    }
  }

  public static async insertStatistics(
    takeNextId: string,
    requestBody: GroupTakeNextInsertStatisticsModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/takenext/" +
      `${takeNextId}/statistics`;
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];
    try {
      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData;
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to insert statistics: ${error}`,
      );
    }
  }
}
