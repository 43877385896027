import NoDataImg from "@/assets/images/no-data.png";
import NoFilteredDataImg from "@/assets/images/no-filtered-data.svg";
import { ReactElement } from "react";
import { StyledNoDataImage } from "./dynamic-table-styles";

type TableNotFoundProps = {
  text?: string;
  isSearchData?: boolean;
  noResultsLabel: string;
  newOneLabel: string;
};

function TableNotFound({
  text,
  isSearchData = false,
  noResultsLabel,
  newOneLabel,
}: TableNotFoundProps): ReactElement {
  const image = isSearchData ? NoFilteredDataImg : NoDataImg;
  return (
    <StyledNoDataImage>
      <img src={image} alt="no-data" />
      <p>{text ? `${text} ${newOneLabel}` : noResultsLabel}</p>
    </StyledNoDataImage>
  );
}

export default TableNotFound;
