import styled from "styled-components";

export const TPColorPickerContainer = styled.div`
  position: relative;
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 5;
`;

export const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
