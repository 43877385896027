import { TPPaginator } from "@/components/TPPaginator/TPPaginator";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { exportToCSV } from "@/helpers/ExportToCSV";
import { ClientePreferredAgentsModel } from "@/models/Client/ClientAdminViewModel";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ClientService } from "@/services/ClientService";
import { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../../styles/Styles.css";
import useLabels from "../../styles/labels";

interface CustomerPreferredAgentsTabProperties {
  subsidiaryId: number;
}

const CustomerPreferredAgentsTab: FC<CustomerPreferredAgentsTabProperties> =
  function ({ subsidiaryId }) {
    const { labels, labelsLoaded } = useLabels();
    const [loading, setLoading] = useState<boolean>(false);
    const [customerPreferredAgents, setCustomerPreferredAgents] = useState<
      ClientePreferredAgentsModel[]
    >([]);
    const [query, setQuery] = useState<string>("");
    const [filteredAgents, setFilteredAgents] = useState<
      ClientePreferredAgentsModel[]
    >([]);
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const loadPreferredAgents = function () {
      setLoading(true);
      ClientService.getPreferredAgents(subsidiaryId, false, false, [200])
        .then((response) => {
          if (response) {
            setCustomerPreferredAgents(response);
            setFilteredAgents(response);
          }
          setLoading(false);
        })
        .catch((error) => console.error(error));
    };

    useEffect(() => {
      if (subsidiaryId) loadPreferredAgents();
    }, []);

    useEffect(() => {
      if (query.trim().length > 0) {
        const lowercaseQuery = query.toLowerCase();
        setFilteredAgents([
          ...customerPreferredAgents.filter((agent) => {
            return (
              String(agent.id).toLowerCase().includes(lowercaseQuery) ||
              String(agent.idSubsidiary)
                .toLowerCase()
                .includes(lowercaseQuery) ||
              String(agent.agentName).toLowerCase().includes(lowercaseQuery) ||
              String(agent.agentLogin).toLowerCase().includes(lowercaseQuery) ||
              String(agent.idUser).toLowerCase().includes(lowercaseQuery) ||
              String(agent.insertDate).toLowerCase().includes(lowercaseQuery) ||
              String(agent.priority).toLowerCase().includes(lowercaseQuery)
            );
          }),
        ]);
      } else {
        setFilteredAgents(customerPreferredAgents);
      }
    }, [query]);

    return (
      <>
        <TPLoadingOverlay active={loading}>
          <div id="merge-customers-contacts-data-table">
            <div className="customer-search-controls">
              <div>
                <TPTextBox
                  onChange={(event: any) => setQuery(event.target.value)}
                  value={query}
                  withIcon
                  icon={TPIconTypes.search}
                  placeholder={labels.Search}
                  containerStyle={{ width: "256px" }}
                />
              </div>
              <div style={{ display: "flex", gap: "16px" }}>
                <button
                  id="merge-customers-refresh-button"
                  type="button"
                  style={{ border: "none", backgroundColor: "white" }}
                  onClick={() => loadPreferredAgents()}
                >
                  <TPIcon iconType={TPIconTypes.refresh} />
                </button>
                <button
                  id="merge-customers-export-csv"
                  type="button"
                  style={{ border: "none", backgroundColor: "white" }}
                  onClick={() => {
                    if (customerPreferredAgents.length > 0)
                      exportToCSV(
                        customerPreferredAgents,
                        "CustomerPreferredAgents.csv",
                      );
                  }}
                  disabled={customerPreferredAgents.length == 0}
                >
                  <TPIcon iconType={TPIconTypes.excel} />
                </button>
              </div>
            </div>
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="400px"
              columns={[
                {
                  name: <b>{labels["id"]}</b>,
                  selector: (row: ClientePreferredAgentsModel) => row.id,
                  sortable: true,
                },
                {
                  name: <b>{labels.UserId}</b>,
                  selector: (row: ClientePreferredAgentsModel) => row.idUser,
                  sortable: true,
                },
                {
                  name: <b>{labels["subsidiaryId"]}</b>,
                  selector: (row: ClientePreferredAgentsModel) =>
                    row.idSubsidiary,
                  sortable: true,
                },
                {
                  name: <b>{labels.AgentName}</b>,
                  selector: (row: ClientePreferredAgentsModel) => row.agentName,
                  sortable: true,
                },
                {
                  name: <b>{labels.AgentLogin}</b>,
                  selector: (row: ClientePreferredAgentsModel) =>
                    row.agentLogin,
                  sortable: true,
                },
                {
                  name: <b>{labels.InsertDate}</b>,
                  selector: (row: ClientePreferredAgentsModel) =>
                    row.insertDate,
                  sortable: true,
                },
                {
                  name: <b>{labels.Priority}</b>,
                  selector: (row: ClientePreferredAgentsModel) => row.priority,
                  sortable: true,
                },
              ]}
              data={filteredAgents}
              dense
              noDataComponent={<label>{labels.NoResults}</label>}
            />
            <TPPaginator
              currentPage={page}
              onChangePage={(newPage) => setPage(newPage)}
              onChangeRowsPerPage={(newRowsPerPage) =>
                setRowsPerPage(newRowsPerPage)
              }
              rowCount={filteredAgents.length}
              rowsPerPage={rowsPerPage}
            />
          </div>
        </TPLoadingOverlay>
      </>
    );
  };

export default CustomerPreferredAgentsTab;
