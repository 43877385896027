/**
 * form designer export and import form enum
 */
enum FormDesignerExportImportFormEnum {
  /**
   * store attribute name and component name
   */
  FormDesignerExportImportForm = "FormDesignerExportImportForm",

  /**
   * keyword resource set local
   */
  FormDesignerExportImportFormComponent = "FormDesignerExportImportFormComponent",

  /**
   * labels
   */

  /**
   * Title label
   */
  TitleLabel = "TitleLabel",

  /**
   * warning title label
   */
  WarningTitleLabel = "WarningTitleLabel",

  /**
   * warning description label
   */
  WarningDescriptionLabel = "WarningDescriptionLabel",

  /**
   * table column id label
   */
  TableColumnIdLabel = "TableColumnIdLabel",

  /**
   * table column id label
   */
  TableColumnDescriptionsLabel = "TableColumnDescriptionsLabel",

  /**
   * table column id label
   */
  TableColumnFormTypeIdLabel = "TableColumnFormTypeIdLabel",

  /**
   * table column id label
   */
  TableColumnLanguageIdsLabel = "TableColumnLanguageIdsLabel",

  /**
   * table column id label
   */
  TableColumnFormTypeDescriptionsLabel = "TableColumnFormTypeDescriptionsLabel",

  /**
   * table column id label
   */
  TableColumnJsonDesignerUT8Label = "TableColumnJsonDesignerUT8Label",

  /**
   * table column id label
   */
  TableColumnLogoUT8Label = "TableColumnLogoUT8Label",

  /**
   * table column id label
   */
  TableColumnApplyPersonalDataLabel = "TableColumnApplyPersonalDataLabel",

  /**
   * table column id label
   */
  TableColumnIsActiveLabel = "TableColumnIsActiveLabel",

  /**
   * save label when clicking the save button
   */
  OkButtonLabel = "OkButtonLabel",

  /**
   * Notification messages
   */

  /**
   * Notification message when a form designer is successfully export or import
   */
  NotificationDescriptionMessage = "NotificationDescriptionMessage",

  /**
   * Notification message when a form designer is successfully export or import
   */
  NotificationTypeMessage = "NotificationTypeMessage",

  /**
   * Notification message when a form designer is successfully export or import
   */
  ImportSuccessMessage = "ImportSuccessMessage",

  /**
   * Notification message when you export or import a form designer and get an error
   */
  ImportErrorMessage = "ImportErrorMessage",

  /**
   * Notification message when you export or import a form designer and get an error
   */
  ExportErrorMessage = "ExportErrorMessage",

  /**
   * Notification message when you message a form designer and get an error
   */
  GetMessageErrorMessage = "Error when checking messages, please consult the system administrator",
}

export default FormDesignerExportImportFormEnum;
