import React, { CSSProperties, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { StoreModel, TypeOf } from "@/redux/store";
import {
  GroupCloneFormSlice,
  GroupCloneFormStateModel,
} from "./_redux/GroupCloneFormSlice";
import { useFormik } from "formik";
import { GroupCloneFormControl } from "./models/GroupCloneFormControl";
import e from "./models/GroupCloneFormEnum";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import {
  VerticalTabsAdminContainerSlice,
  VerticalTabsAdminContainerStateModel,
} from "@/layouts/VerticalTabs/VerticalTabsAdminContainer/_redux/VerticalTabsAdminContainerSlice";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import GroupCloneFormModel from "./models/GroupCloneFormModel";

interface GroupCloneFormProps {
  id: string;
}

export default function GroupCloneForm({ id }: GroupCloneFormProps) {
  const dispatch = useDispatch();

  const {
    message: m,
    form,
    result,
  } = useSelector(
    (s: StoreModel) => s[GroupCloneFormSlice.name],
  ) as GroupCloneFormStateModel;

  const { tabs, focusIndex } = useSelector(
    (s: StoreModel) => s[VerticalTabsAdminContainerSlice.name],
  ) as VerticalTabsAdminContainerStateModel;

  const formik = useFormik({
    initialValues: {
      ...GroupCloneFormControl.initialValues,
      sourceGroupId: id,
    } as GroupCloneFormModel,
    validationSchema: GroupCloneFormControl.validationSchema,
    validateOnChange: false,
    onSubmit: (form) => {
      dispatch(GroupCloneFormSlice.actions.save({ ...form }));
    },
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(GroupCloneFormSlice.actions.clean());
    Object.entries(formik.values).forEach(([attribute, value]) => {
      formik.setFieldValue(attribute, value);
      dispatch(GroupCloneFormSlice.actions.setAttribute({ attribute, value }));
    });
    loadingHandler();
  }, []);

  useEffect(
    () => () => {
      dispatch(GroupCloneFormSlice.actions.clean());
    },
    [],
  );

  useEffect(() => {
    if (result?.message) {
      showToast(
        result.message,
        result?.error ? TPToastTypes.error : TPToastTypes.success,
      );
    }
  }, [result]);

  useEffect(() => {
    if (id === tabs[focusIndex]?.recordData?.recordId) {
      loadingHandler();
    }
  }, [focusIndex]);

  useEffect(() => {
    if (!formik.values.groupId) {
      formik.setFieldValue("groupId", form.groupId);
    }
  }, [form.groupId]);

  function loadingHandler() {
    dispatch(
      GroupCloneFormSlice.actions.message([
        e.GroupCloneFormComponent,
        [
          e.TitleLabel,
          e.ElementToCloneLabel,
          e.NewElementIdLabel,
          e.DescriptionLabel,
          e.IsActiveLabel,
          e.RequiredFieldLabel,
          e.Maximum20CharactersAllowedLabel,
          e.OkButtonLabel,
        ],
      ]),
    );
    dispatch(GroupCloneFormSlice.actions.getId());
    setLoading(false);
  }

  function onChangeHandler(event: any, attribute: string) {
    const value = event.target.value;
    formik.setFieldValue(attribute, value);
    dispatch(GroupCloneFormSlice.actions.setAttribute({ attribute, value }));
  }

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay
        active={loading || TypeOf(result, GroupCloneFormSlice.actions.save)}
      >
        <div className="row">
          <div className="col-5">
            <TPPageTitle id="GroupCloneFormTitle">
              {m?.[e.TitleLabel]}
            </TPPageTitle>
            <hr />

            <form id="GroupCloneFormSectionForm" style={styles.form}>
              <TPTextBox
                id="GroupCloneFormInputSourceGroupId"
                labelText={m?.[e.ElementToCloneLabel]}
                disabled={true}
                isMandatory={true}
                value={`${tabs[focusIndex]?.recordData?.form?.user?.name} (${tabs[focusIndex]?.recordData?.form?.user?.login})`}
                onChange={() => {}}
                maxLength={20}
                errorMessage={
                  formik.errors.sourceGroupId &&
                  m?.[formik.errors.sourceGroupId]
                }
              />

              <TPTextBox
                id="GroupCloneFormInputGroupId"
                labelText={m?.[e.NewElementIdLabel]}
                disabled={true}
                isMandatory={true}
                value={formik.values.groupId}
                onChange={(e: HTMLInputElement) =>
                  onChangeHandler(e, "groupId")
                }
                maxLength={20}
                errorMessage={
                  formik.errors.groupId && m?.[formik.errors.groupId]
                }
              />

              <TPTextBox
                id="GroupCloneFormInputDescription"
                isMandatory={true}
                labelText={m?.[e.DescriptionLabel]}
                value={formik.values.description}
                onChange={(e: HTMLInputElement) =>
                  onChangeHandler(e, "description")
                }
                maxLength={20}
                errorMessage={
                  formik.errors.description && m?.[formik.errors.description]
                }
              />

              <div style={styles.checkbox}>
                <TPCheckBox
                  id="GroupCloneFormInputIsActive"
                  labelText={m?.[e.IsActiveLabel]}
                  checked={formik.values.isActive}
                  onChange={() =>
                    onChangeHandler(
                      { target: { value: !formik.values.isActive } },
                      "isActive",
                    )
                  }
                />
              </div>
            </form>

            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="GroupCloneFormButtonSave"
                type={TPButtonTypes.primary}
                onClick={() => formik.handleSubmit()}
              >
                {m?.[e.OkButtonLabel]}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );
}

const styles = {
  form: {
    marginBottom: "20px",
  } as CSSProperties,
  checkbox: {
    margin: "20px 0",
  } as CSSProperties,
};
