import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import React, { Children, FC, useState } from "react";
import ReactDOM from "react-dom";

export type TPModalProps = {
  titleModal: string | JSX.Element;
  acceptLabel: string | JSX.Element;
  cancelLabel: string | JSX.Element;
  callBackAnswer: Function;
  callBackData?: any;
  isShown: boolean;
  modalWidth?: string;
  hideFooterButtons?: boolean;
  enableAcceptButton?: boolean;
  id?: string;
};

export type TPModalStateType = {
  modalState: TPModalProps;
  children: React.ReactNode;
};

const TPModal: FC<TPModalStateType> = ({ modalState, children }) => {
  const modalRoot = document.getElementById("modal") as HTMLElement;
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  if (modalState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }
  return ReactDOM.createPortal(
    <>
      <div
        id={modalState.id}
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div
          className={`modal-dialog ${modalState.modalWidth ? modalState.modalWidth : "modal-lg"} modal-dialog-centered modal-dialog-scrollable`}
        >
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{modalState.titleModal}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) =>
                  modalState.callBackAnswer(false, modalState.callBackData)
                }
              ></button>
            </div>
            <div className="modal-body" style={{ height: "auto" }}>
              {children}
            </div>
            {!modalState.hideFooterButtons && (
              <div className="modal-footer">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <TPButton
                      id="IdButton"
                      type={TPButtonTypes.primary}
                      onClick={() =>
                        modalState.callBackAnswer(true, modalState.callBackData)
                      }
                      disabled={modalState.enableAcceptButton === false}
                    >
                      {modalState.acceptLabel}
                    </TPButton>

                    <TPButton
                      id="IdButton"
                      type={TPButtonTypes.link}
                      onClick={() =>
                        modalState.callBackAnswer(
                          false,
                          modalState.callBackData,
                        )
                      }
                    >
                      {modalState.cancelLabel}
                    </TPButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={backdropClass}></div>
    </>,
    modalRoot,
  );
};

export default TPModal;
