import { Validator } from "fluentvalidation-ts";
import { UserGroupTypesEnum } from "../Global/TPGlobalEnums";
import { UserInputDTO } from "../Users/UserInputDTO";

export interface GroupsInputDTO {
  groupId: number;
  isUpdatable: boolean;
  isRotating: boolean;
  isPicking: boolean;
  finishPendingCaseBeforeRecivingNewOne: boolean;
  preferredAgent: boolean;
  recentAgent: boolean;
  prioritizeRelatedCases: boolean;
  topSearchCoefficient: number | null;
  assignCasesToUsersLogged: boolean | null;
  minutesLogoutReassign: number | null;
  maximumCasesPerUser: number | null;
  taskResponsibleForSocialMediaAnswer: number;
  priorityWeight: number;
  user: UserInputDTO;
}

export class GroupsInputDTOValidator extends Validator<GroupsInputDTO> {
  constructor(groupType: UserGroupTypesEnum) {
    super();
    const resourceSet = "GroupsInsertUpdateComponent";
    try {
      switch (groupType) {
        case UserGroupTypesEnum.rotating:
          this.ruleFor("minutesLogoutReassign")
            .must((minutes) => {
              return minutes ? minutes > 0 : false;
            })
            .withMessage(resourceSet + "|InputDTOMinutesLogoutEmpty")
            .when((formModel) => formModel.assignCasesToUsersLogged === true);
          this.ruleFor("maximumCasesPerUser")
            .must((cases) => {
              return cases ? cases > 0 : false;
            })
            .withMessage(resourceSet + "|InputDTOMaximunCasesPerUserEmpty");
          break;
        case UserGroupTypesEnum.takeNext:
          this.ruleFor("priorityWeight")
            .must((priorityWeight) => {
              return priorityWeight > 0;
            })
            .withMessage(resourceSet + "|InputDTOPriorityWeightInvalid");
          this.ruleFor("topSearchCoefficient")
            .must((top) => {
              return top ? top > 0 : false;
            })
            .withMessage(resourceSet + "|InputDTOTopInvalid");
          break;
        default:
          break;
      }
    } catch (error) {}
  }
}
