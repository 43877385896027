import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  SequeceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useEffect, useState } from "react";

import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { FormCloneInputDTO, FormCloneInputDTOValidator } from "@/models/FormDesigner/FormDesigner";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { SequenceService } from "@/services/SequenceService";

type InsertCloneProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  baseId: string;
  newId: string;
  description: string;
  isActive: boolean;
  // languageId: string;
  formTypeId: string;
  applyPersonalData: boolean
  recordLanguageList: Array<TPKeyValue>;

  //validator
  baseIdErrorMessage: string;
  languageIdErrorMessage: string;
  descriptionErrorMessages: Array<string>;
};

enum commandsEnum {
  "create_home" = 0,
  "new_vertical_tab" = 1,
  "update_vertical_tab" = 2,
  "vertical_tab_click" = 3,
  "vertical_tab_close" = 4,
  "insertupdate_cancel_or_ok" = 5,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const FormDesignerClone: FC<InsertCloneProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "FormDesignerClone.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  const [tempIdValue, setTempIdValue] = useState("");
  const [tempDescription, setTempDescription] = useState("");

  //Screen resources
  const resourceSet: string = "FormDesignerCloneComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [newIdLabel, setnewIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [primaryLanguageLabel, setPrimaryLanguageLabel] = useState("");
  const [languageList, setLanguageList] = useState<Array<TPKeyValue>>([]);

  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);

  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    baseId: recordId,
    newId: "",
    description: "",
    isActive: true,
    // languageId: "",
    formTypeId: "",
    applyPersonalData: false,
    recordLanguageList: [],

    baseIdErrorMessage: "",
    languageIdErrorMessage: "",
    descriptionErrorMessages: [],
  };

  const [cloneState, setCloneState] = useState(insertUpdateInitialState);

  //#endregion
  const loadResourcesAndLoadInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleCloneLabel"));

    setParametersSectionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ParametersSectionLabel")
    );

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList")
    );
    setIdLabel(await TPI18N.GetText(resourceSet, "IdCloneLabel"));
    setnewIdLabel(await TPI18N.GetText(resourceSet, "NewIdCloneLabel"));
    setDescriptionLabel(
      await TPI18N.GetText(resourceSet, "DescriptionCloneLabel")
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveCloneLabel"));
    setPrimaryLanguageLabel(await TPI18N.GetText(resourceSet, "PrimaryLanguageLabel"));

    //load Languages
    let newlanguageList = TPGlobal.TPClientAvailableLanguages.map((item) => {
      return { key: item.id, value: item.name };
    }).filter((x) => x.key !== TPGlobal.TPClientDefaultLanguage);
    newlanguageList.unshift({ key: "", value: "--" });

    setLanguageList(newlanguageList);

    //screen state
    if (realMode == "Clone") {
      await getById(realRecordId);
    }
  };
  console.log("realMode", realMode)
  const getById = async (pRecordId: string) => {
    let serviceClient = new AdditionalDataFormService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getFormDesingnerById(
        pRecordId,
        false,
        true,
        expectedCodes
      );

      let recordInfo;
      recordInfo = { ...responseRequest };

      setTempIdValue(pRecordId);
      setTempDescription(recordInfo.description);

      let newInsertUpdateState = { ...cloneState };

      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }

      newInsertUpdateState.baseId = recordInfo.id;
      newInsertUpdateState.description = recordInfo.description;
      newInsertUpdateState.formTypeId = recordInfo.formTypeId;
      newInsertUpdateState.applyPersonalData = recordInfo.applyPersonalData
      // newInsertUpdateState.newId = `${recordInfo.id}_Cloned`

      setCloneState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getFunctionById ex`);
      setIsLoadingScreen(false);
    }
  };
  console.log("cloneState", cloneState)

  const handleOnIdChange = (newId: string) => {
    let newInsertUpdateState = { ...cloneState };
    newInsertUpdateState.baseId = newId;
    newInsertUpdateState.baseIdErrorMessage = "";
    setCloneState(newInsertUpdateState);
  };

  // const handleChangeLanguage = (languageOption: string) => {
  //   let newInsertUpdateState = { ...cloneState };
  //   newInsertUpdateState.languageId = languageOption;
  //   newInsertUpdateState.languageIdErrorMessage = "";
  //   setCloneState(newInsertUpdateState);
  // }


  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...cloneState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setCloneState(newInsertUpdateState);
  };

  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...cloneState };
    newInsertUpdateState.isActive = !cloneState.isActive;
    setCloneState(newInsertUpdateState);
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const handleOkButtonClick = async () => {
    let isInValid: boolean = false;
    let i: number;
    let n: number;

    let recordInputDTO: FormCloneInputDTO = {
      baseId: cloneState.baseId,
      newId: referenceId,
      description: cloneState.recordLanguageList[0].value,
      // languageId: cloneState.languageId,
      isActive: cloneState.isActive,
      formTypeId: cloneState.formTypeId,
      applyPersonalData: cloneState.applyPersonalData,
      otherLocalizedValues: [],
    };

    n = cloneState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = cloneState.recordLanguageList[i];
      recordInputDTO.otherLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }

    let inputDTOValidator = new FormCloneInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...cloneState };

      if (resultValidator.baseId) {
        newInsertUpdateState.baseIdErrorMessage = await TPI18N.GetResource(
          resultValidator.baseId
        );
      } else {
        newInsertUpdateState.baseIdErrorMessage = "";
      }

      // if (resultValidator.languageId) {
      //   newInsertUpdateState.languageIdErrorMessage = await TPI18N.GetResource(
      //     resultValidator.languageId
      //   );
      // } else {
      //   newInsertUpdateState.languageIdErrorMessage = "";
      // }

      if (resultValidator.description) {
        newInsertUpdateState.descriptionErrorMessages[0] =
          await TPI18N.GetResource(resultValidator.description);
      } else {
        newInsertUpdateState.descriptionErrorMessages[0] = "";
      }

      if (resultValidator.otherLocalizedValues) {
        n = cloneState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.otherLocalizedValues[i] != null) {
            newInsertUpdateState.descriptionErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.otherLocalizedValues[i])
              );
          }
        }
      } else {
        n = cloneState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.descriptionErrorMessages[i] = "";
        }
      }
      setCloneState(newInsertUpdateState);
      isInValid = true;
    }

    if (!isInValid) {
      if (realMode == "Clone") {
        await clone(recordInputDTO);
      }
    }
  };

  const clone = async (inputDTO: FormCloneInputDTO) => {
    let serviceClient = new AdditionalDataFormService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.clone(
        inputDTO,
        true,
        true,
        expectedCodes
      );

      if (responseRequest.responseResult) {
        callBackResult({
          result: "clone_general_insert_and_change_mode",
          payload: inputDTO.baseId,
        });

        //change mode
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleCloneLabel"));
        setRealRecordId(inputDTO.baseId);
        setRealMode("Clone");
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} insert ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} insert ex`);
    }
    setIsLoadingScreen(false);
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequeceGeneratorSequencesNameEnum.SQADFO
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        console.log("responseRequest", responseRequest)
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources
  useEffect(() => {
    loadResourcesAndLoadInfo();
    generalAutomaticId();
  }, []);


  console.log("line 393>>>", mode, recordId);

  return (
    //#region  Render
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-10">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row mb-2">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdOldTextBox"
                      labelText={idLabel}
                      isMandatory={false}
                      value={tempDescription + " (" + tempIdValue + ")"}
                      onChange={() => { }}
                      maxLength={20}
                      disabled={true}
                      errorMessage={""}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={newIdLabel}
                      isMandatory={true}
                      // value={cloneState.id}
                      value={referenceId}
                      onChange={(e: any) => handleOnIdChange(e.target.value)}
                      maxLength={20}
                      disabled={true}
                      errorMessage={cloneState.baseIdErrorMessage}
                    />
                  </div>
                  {/* <TPSelect
                      id="IdSelect"
                      isMandatory={true}
                      labelText={primaryLanguageLabel}
                      onChange={(e: any) => handleChangeLanguage(e.target.value)}
                      dataSource={languageList}
                      value={cloneState.languageId}
                      errorMessage={cloneState.languageIdErrorMessage}
                    ></TPSelect> */}
                </div>
              </div>
              <div className="row mb-2">
                {cloneState.recordLanguageList.length > 0 &&
                  TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                      index === 0 && (
                        <div className="col-6" key={`languageItem-${item.id}`}>
                          <div className="form-group">
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={index === 0}
                              labelText={`${descriptionLabel} (${item.name})`}
                              value={cloneState.recordLanguageList[index].value}
                              onChange={(e: any) =>
                                handleLanguageChange(index, e.target.value)
                              }
                              maxLength={100}
                              errorMessage={
                                cloneState.descriptionErrorMessages[index]
                              }
                            />
                          </div>
                        </div>
                      )
                  )}
                {cloneState.recordLanguageList.length > 1 && (
                  <>
                    <div className="col-4">
                      <div className="pt-4">
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          icon={TPIconTypes.language}
                          text={`+${cloneState.recordLanguageList.length - 1}`}
                          tooltip={languageListLabel}
                          className={"pt-3"}
                          onClick={handleOpenModalLanguageList}
                        />
                      </div>
                    </div>
                    <TPModalLanguageList
                      id="IdModalLanguageList"
                      isOpen={isOpenModalLanguageList}
                      title={languageListLabel}
                      acceptLabel={saveButtonLabel}
                      cancelLabel={cancelButtonLabel}
                      saveChanges={handleSaveChangesModalLanguageList}
                      closeModal={handleCloseModalLanguageList}
                    >
                      <div
                        className="row overflow-auto"
                        style={{ height: "200px" }}
                      >
                        {TPGlobal.TPClientAvailableLanguages.map(
                          (item, index) =>
                            index > 0 && (
                              <div
                                className="col-12"
                                key={`languageItem-${item.id}`}
                              >
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={index === 0}
                                    labelText={`${descriptionLabel} (${item.name})`}
                                    value={
                                      cloneState.recordLanguageList[index].value
                                    }
                                    onChange={(e: any) =>
                                      handleLanguageChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    maxLength={100}
                                    errorMessage={
                                      cloneState.descriptionErrorMessages[index]
                                    }
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </TPModalLanguageList>
                  </>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      labelText={isActiveLabel}
                      checked={cloneState.isActive}
                      onChange={(e: any) => handleIsActiveChange()}
                    ></TPCheckBox>
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
    //#endregion
  );
};

export default FormDesignerClone;
