import React, { useEffect, useState } from "react";

import ParentFolder from "./ImagesAdminParentFolder";
import {
  ImagesAdminContainerFolderDivStyle,
  IcoFolderIStyle,
  MenuItemDivStyle,
  NivelDivStyle,
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { DataType } from "./useStatusImagesAdmin";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";

type ContainerFolderProps = {
  folders: Array<RecursiveRoutes>;
};

const ContainerFolder = () => {
  const componentFileName: string = "ContainerFolder.tsx";

  const [listFolderTemp, selectedFolderTemp] = useState<Array<RecursiveRoutes>>(
    Array<RecursiveRoutes>,
  );

  const value = useAppConctextImageAdmin();
  const {
    status,
    selectedItem,
    listFolderModel,
    handleSelected,
    handleInitializeFolders,
  } = value;

  useEffect(() => {
    selectedFolderTemp(listFolderModel);
    console.log();
  }, [listFolderModel]);

  const handleActivateMenu = (event: any) => {
    event.preventDefault();
  };

  return (
    <ImagesAdminContainerFolderDivStyle onContextMenu={handleActivateMenu}>
      {listFolderTemp.map((folder) => (
        <ParentFolder folders={folder} key={folder.id}></ParentFolder>
      ))}
    </ImagesAdminContainerFolderDivStyle>
  );
};

export default ContainerFolder;
