import React, {forwardRef, useImperativeHandle, useState} from "react";
import {TPIconTypes} from "@/models/Global/TPGlobalEnums";
import {ProjectListState, ProjectModel, ProjectsAdminEnum as e, TableProjectModel} from "@/models/Project/Projects";
import {commandsEnum as event, commandType} from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import DynamicTable, {ColumnStyles, CustomColumnNames} from "@/modules/core/components/dynamic-table/DynamicTable";
import {MinorOption} from "@/modules/core/components/dynamic-table/TableActionItem";
import TPModalQuestion, {TPModalQuestionState} from "@/layouts/ModalQuestion/TPModalQuestion";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import {EventProjectService} from "@/services/EventProjectService";

export interface ProjectsAdminTableEvents {
    load: () => void;
}

interface ProjectsAdminTableProps {
    state: ProjectListState;
    verticalTabDispatch: (action: commandType) => void;
    loadData: () => void;
}

const ProjectsAdminTable = forwardRef(({state, verticalTabDispatch, loadData}: ProjectsAdminTableProps, ref) => {
    const [modalQuestionState, setModalQuestionState] = useState<TPModalQuestionState>({
        isShown: false,
        callBackData: {},
    });

    useImperativeHandle(ref, () => ({
        load() {
        },
    } as ProjectsAdminTableEvents));

    const columns: CustomColumnNames<TableProjectModel> = {
        id: `${state.messages?.[e.IdTableColumnLabel]?.value ?? ""}`,
        name: `${state.messages?.[e.NameTableColumnLabel]?.value ?? ""}`,
        descriptionStructure: `${state.messages?.[e.StructureTypeTableColumnLabel]?.value ?? ""}`,
        queues: `${state.messages?.[e.QueuesTableColumnLabel]?.value ?? ""}`,
        isActive: `${state.messages?.[e.ActiveTableColumnLabel]?.value ?? ""}`,
    };

    const columnStyles: ColumnStyles<TableProjectModel> = {
        id: ({value, item}) => (
            <TablePrimaryItem
                value={value}
                onClick={() => onClickDetailHandler(item)}
            />
        ),
        descriptionStructure: ({value, item}) => (
            <>{item.descriptionStructure ? item.descriptionStructure : ""}</>
        ),
        isActive: ({value}) => (
            <TableChip
                value={value}
                onLabel={`${state.messages?.[e.ActiveColumnYesLabel]?.value ?? ""}`}
                offLabel={`${state.messages?.[e.ActiveColumnNoLabel]?.value ?? ""}`}
            />
        ),
    };

    const options: MinorOption<TableProjectModel>[] = [
        {
            key: `${state.messages?.[e.UpdateActionLabel]?.value ?? ""}`,
            type: 'edit',
            icon: TPIconTypes.edit,
            onOptionChange: ({id}) => onClickUpdateHandler(id)
        },
        {
            key: `${state.messages?.[e.CloneActionLabel]?.value ?? ""}`,
            type: 'clone',
            icon: TPIconTypes.clone,
            onOptionChange: (project) => onClickCloneHandler(project)
        },
        {
            key: `${state.messages?.[e.DeleteActionLabel]?.value ?? ""}`,
            type: 'delete',
            icon: TPIconTypes.delete,
            onOptionChange: ({id}) => onClickDeleteHandler(id)
        },
    ];

    function onClickUpdateHandler(id: string) {
        verticalTabDispatch({type: event.update_vertical_tab, payload: {recordId: id,}});
    }

    function onClickCloneHandler(element: ProjectModel) {
        verticalTabDispatch({
            type: event.clone_vertical_tab,
            payload: {
                recordId: element.id,
                form: element,
            }
        });
    }

    function onClickDeleteHandler(id: string) {
        setModalQuestionState({
            ...modalQuestionState,
            isShown: true,
            callBackData: {recordId: id},
        } as TPModalQuestionState);
    }

    function onClickDetailHandler(element: ProjectModel) {
        verticalTabDispatch({type: event.detail, payload: element});
    }

    const onClickIconHandler = (event: TPIconTypes) => {
        if (event === TPIconTypes.loop) loadData();
    };

    function callBackModalHandler(
        confirmDelete: boolean,
        callBackData: any
    ) {
        setModalQuestionState({
            ...modalQuestionState,
            isShown: false,
            callBackData: {},
        });
        if (confirmDelete) {
            const {deleteOne} = new EventProjectService();
            deleteOne(callBackData.recordId)
                .then(() => loadData())
                .catch(error => console.log(error));
        }
    }

    return (
        <>
            <DynamicTable
                id="projectAdminTable"
                data={state.datasource.value ?? []}
                columnNames={columns}
                columnStyles={columnStyles}
                minorOptions={options}
                onIconClicked={event => onClickIconHandler(event)}
                withPreferences={true}
            />

            {modalQuestionState?.isShown && modalQuestionState.callBackData.recordId && (
                <TPModalQuestion
                    id="IdModalQuestion"
                    title={`${state.messages?.[e.titleModalLabel]?.value ?? ""}`}
                    yesLabel={`${state.messages?.[e.YesModalButtonLabel]?.value ?? ""}`}
                    noLabel={`${state.messages?.[e.NoModalButtonLabel]?.value ?? ""}`}
                    question={`${state.messages?.[e.QuestionModalLabel]?.value ?? ""}`?.replace("{recordId}", modalQuestionState.callBackData.recordId)}
                    callBackData={modalQuestionState.callBackData}
                    isShown={modalQuestionState.isShown}
                    callBackAnswer={callBackModalHandler}
                />
            )}
        </>
    );
})

export default ProjectsAdminTable
