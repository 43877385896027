import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { AdditionalFilter } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable, {
  ColumnStyles,
  CustomActionProps,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { ReactElement } from "react";

type UploadProgressTableProps = {
  additionalFilters?: AdditionalFilter[];
  callBackCommands: Function;
};

interface UploadTableData {
  project: string;
  structure: string;
  uploadedBy: string;
  uploadData: string;
  status: boolean;
  errors: number;
}

function UploadProgressTable({
  additionalFilters,
  callBackCommands,
}: UploadProgressTableProps): ReactElement {
  const data: UploadTableData[] = [
    {
      project: "CIM",
      structure: "FRAMEWORKS-01",
      uploadedBy: "John Doe",
      uploadData: "09/09/2024 5:48",
      status: false,
      errors: 5,
    },
    {
      project: "TPClient",
      structure: "structure-0121",
      uploadedBy: "John Doe",
      uploadData: "30/08/2024 1:25",
      status: true,
      errors: 0,
    },
  ];

  const customColumns: ColumnStyles<UploadTableData> = {
    project: ({ value, item }) => (
      <TablePrimaryItem value={value} onClick={() => console.log(item)} />
    ),
    status: ({ value }) => (
      <TableChip
        value={value}
        onLabel={"In progress"}
        offLabel={"Failed"}
        justify="flex-start"
      />
    ),
    errors: ({ value }) => (
      <div style={{ display: "flex", gap: "0.5em" }}>
        {value === 0 ? (
          " "
        ) : (
          <>
            {value}
            <TPIcon iconType={TPIconTypes.eventManager} />
          </>
        )}
      </div>
    ),
  };

  const additionalAction: React.FC<CustomActionProps<UploadTableData>> = ({
    item,
  }) => {
    return (
      <TPButton
        id="close-solve-records"
        isDesignSystem
        customType={ButtonCustomType.tertiary}
        onClick={() => console.log(item)}
        style={{ padding: "1px 18px" }}
        disabled={item.status}
      >
        Upload File
      </TPButton>
    );
  };

  return (
    <DynamicTable
      id="load-history"
      data={data}
      searchPosition="right"
      additionalFilters={additionalFilters}
      columnStyles={customColumns}
      CustomAction={additionalAction}
    />
  );
}

export default UploadProgressTable;
