import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { QuickClassifierViewModel } from "@/models/QuickClassifier/QuickClassifierViewModel";
import {
  QuickClassifierShortcutsInputDTO,
  QuickClassifierShortcutsInputDTOValidator,
} from "@/models/QuickClassifierShortcuts/QuickClassifierShortcutsInputDTO";
import { TPI18N } from "@/services/I18nService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";

interface IQCShortcutProps {
  currentBaseLevel: string;
  organizationsRelationsKeyValue: Array<TPKeyValue>;
  currentMood: string;
  quickClassifierId?: string;
  hotkey?: number;
}

type AdminStateType = {
  quickClassifierId: string;
  hotkey: number;
  hotkeyErrorMessage: string;
  guidUserErrorMessage: string;
  webserviceClassifierUserIdErrorMessage: string;
};

enum commandsEnum {
  "change_quick_classifier" = 0,
  "hotkey_onchange" = 1,
  "set_errors" = 2,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const QuickClassifierShortcutInsertUpdate = React.forwardRef(
  (
    {
      currentBaseLevel,
      organizationsRelationsKeyValue,
      currentMood,
      quickClassifierId = "",
      hotkey = 0,
    }: IQCShortcutProps,
    ref,
  ) => {
    const componentFileName: string = "QuickClassifierShortcutInsertUpdate.tsx";
    const resourceSet: string = "QuickClassifierShortcutInsertUpdateComponent";
    const resourceSetAdmin: string = "QuickClassifierShortcutsAdminComponent";

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);

    const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
      useState("");

    const [quickClassifiersListKeyValue, setQuickClassifiersListKeyValue] =
      useState<Array<TPKeyValue>>([]);

    const [hotkeyLabel, setHotkeyLabel] = useState("");
    const [quickClassifierLabel, setQuickClassifierLabel] = useState("");

    //Load Resources
    const loadResourcesAndQuickClassifier = async () => {
      setOrganizationsRelationsLabel(
        await TPI18N.GetText(resourceSetAdmin, "OrganizationsRelationsLabel"),
      );
      setHotkeyLabel(
        await TPI18N.GetText(resourceSetAdmin, "HotkeyColumnLabel"),
      );
      setQuickClassifierLabel(
        await TPI18N.GetText(resourceSetAdmin, "QuickClassifierLabel"),
      );

      await getQuickClassifiersForOrganizationRelation(currentBaseLevel);

      setIsLoadingScreen(false);
    };

    useImperativeHandle(ref, () => ({
      async validateQCShortcut() {
        let bolR: boolean = await internalValidation();
        return bolR;
      },
      getAdminState() {
        return adminState;
      },
    }));

    //get quick classificators for a baselevel
    const getQuickClassifiersForOrganizationRelation = async (
      newBaseLevel: string,
    ): Promise<Array<TPKeyValue>> => {
      let newQuickClassifierList: Array<TPKeyValue> = [];
      let serviceClient = new QuickClassifierService();
      let expectedCodes: Array<number> = [200];
      let quickData: Array<QuickClassifierViewModel> = [];
      try {
        let responseRequest =
          await serviceClient.getQuickClassifiersByBaseLevel(
            newBaseLevel,
            false,
            false,
            expectedCodes,
          );
        quickData = responseRequest;
        if (quickData) {
          for (let i: number = 0; i <= quickData.length - 1; i++) {
            if (quickData[i].isActive) {
              newQuickClassifierList.push({
                key: quickData[i].id,
                value: quickData[i].localizedDescription
                  ? quickData[i].localizedDescription
                  : quickData[i].description,
                value2: {
                  classifier1Id: quickData[i].classifier1Id,
                  classifier2Id: quickData[i].classifier2Id,
                  classifier3Id: quickData[i].classifier3Id,
                  classifier4Id: quickData[i].classifier4Id,
                  classifier5Id: quickData[i].classifier5Id,
                },
                value3: {
                  localizedClassifier1: quickData[i].localizedClassifier1,
                  localizedClassifier2: quickData[i].localizedClassifier2,
                  localizedClassifier3: quickData[i].localizedClassifier3,
                  localizedClassifier4: quickData[i].localizedClassifier4,
                  localizedClassifier5: quickData[i].localizedClassifier5,
                },
                value4: {
                  localizedHierarchyClassifier1:
                    quickData[i].localizedHierarchyClassifier1,
                  localizedHierarchyClassifier2:
                    quickData[i].localizedHierarchyClassifier2,
                  localizedHierarchyClassifier3:
                    quickData[i].localizedHierarchyClassifier3,
                  localizedHierarchyClassifier4:
                    quickData[i].localizedHierarchyClassifier4,
                  localizedHierarchyClassifier5:
                    quickData[i].localizedHierarchyClassifier5,
                },
              });
            }
          }
          if (newQuickClassifierList.length >= 1) {
            let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
            newQuickClassifierList.unshift(intitialKeyValue);
          }
        }
        setQuickClassifiersListKeyValue(newQuickClassifierList);
        return newQuickClassifierList;
      } catch (error) {
        newQuickClassifierList = [];
        setQuickClassifiersListKeyValue(newQuickClassifierList);
        TPLog.Log(
          `Error ${componentFileName} getQuickClassifiersForOrganizationRelation ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getQuickClassifiersForOrganizationRelation ex`,
        );
        newQuickClassifierList = [];
        return newQuickClassifierList;
      }
    };

    const internalValidation = async () => {
      let recordInputDTO: QuickClassifierShortcutsInputDTO = {
        webserviceClassifierUserId: adminState.quickClassifierId,
        guidUser: TPGlobal.currentUserGuid,
        hotkey: +adminState.hotkey,
      };

      let inputDTOValidator = new QuickClassifierShortcutsInputDTOValidator();
      let resultValidator = inputDTOValidator.validate(recordInputDTO);

      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        let validationsErrores: any = new Object();
        if (resultValidator.hotkey) {
          validationsErrores.hotkeyErrorMessage = await TPI18N.GetResource(
            resultValidator.hotkey,
          );
        } else {
          validationsErrores.hotkeyErrorMessage = "";
        }

        if (resultValidator.guidUser) {
          validationsErrores.guidUserErrorMessage = await TPI18N.GetResource(
            resultValidator.guidUser,
          );
        } else {
          validationsErrores.guidUserErrorMessage = "";
        }

        if (resultValidator.webserviceClassifierUserId) {
          validationsErrores.webserviceClassifierUserIdErrorMessage =
            await TPI18N.GetResource(
              resultValidator.webserviceClassifierUserId,
            );
        } else {
          validationsErrores.webserviceClassifierUserIdErrorMessage = "";
        }

        let command: commandType = {
          type: commandsEnum.set_errors,
          payload: {
            errors: validationsErrores,
          },
        };
        dispatchCommand(command);
        return false;
      }

      return true;
    };

    const handleQuickClassifierOnChange = async (e: any) => {
      const newValue: string = e.target.value;
      let command1: commandType = {
        type: commandsEnum.change_quick_classifier,
        payload: {
          newQuickClassifierId: newValue,
          webserviceClassifierUserIdErrorMessage: "",
        },
      };
      dispatchCommand(command1);
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      quickClassifierId: quickClassifierId,
      webserviceClassifierUserIdErrorMessage: "",
      hotkey: 1,
      hotkeyErrorMessage: "",
      guidUserErrorMessage: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      let newScreenState: AdminStateType;
      newScreenState = { ...prevState };
      switch (command.type) {
        case commandsEnum.change_quick_classifier:
          newScreenState.quickClassifierId =
            command.payload.newQuickClassifierId;

          newScreenState.webserviceClassifierUserIdErrorMessage =
            command.payload.webserviceClassifierUserIdErrorMessage;
          return newScreenState;

        case commandsEnum.hotkey_onchange:
          newScreenState.hotkey = command.payload.hotkey;
          newScreenState.hotkeyErrorMessage = "";
          return newScreenState;

        case commandsEnum.set_errors:
          newScreenState = { ...prevState };
          newScreenState.hotkeyErrorMessage =
            command.payload.errors.hotkeyErrorMessage;

          newScreenState.webserviceClassifierUserIdErrorMessage =
            command.payload.errors.webserviceClassifierUserIdErrorMessage;

          newScreenState.guidUserErrorMessage =
            command.payload.errors.guidUserErrorMessage;
          return newScreenState;

        default:
          return prevState;
      }
    }

    const handlerHotkeyChange = (newHotkey: number) => {
      dispatchCommand({
        type: commandsEnum.hotkey_onchange,
        payload: {
          hotkey: newHotkey,
          hotkeyErrorMessage: "",
        },
      });
    };

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndQuickClassifier();
    }, [currentBaseLevel]);

    useEffect(() => {
      if (!!quickClassifierId) {
        dispatchCommand({
          type: commandsEnum.change_quick_classifier,
          payload: {
            newQuickClassifierId: quickClassifierId,
            webserviceClassifierUserIdErrorMessage: "",
          },
        });
      }
      if (hotkey > 0) {
        dispatchCommand({
          type: commandsEnum.hotkey_onchange,
          payload: {
            hotkey: hotkey,
            hotkeyErrorMessage: "",
          },
        });
      }
    }, [quickClassifierId]);

    return (
      <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <TPSelect
                id="IdSelect"
                dataSource={organizationsRelationsKeyValue}
                value={currentBaseLevel}
                labelText={organizationsRelationsLabel}
                isMandatory={true}
                disabled={true}
                onChange={() => {}}
              ></TPSelect>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <TPSelect
                id="IdSelect"
                onChange={handleQuickClassifierOnChange}
                dataSource={quickClassifiersListKeyValue}
                value={adminState.quickClassifierId}
                isMandatory={true}
                labelText={quickClassifierLabel}
                disabled={currentMood !== "insert"}
                errorMessage={adminState.webserviceClassifierUserIdErrorMessage}
              ></TPSelect>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <TPNumeric
                id="IdTPNumeric"
                labelText={hotkeyLabel}
                onChange={handlerHotkeyChange}
                value={adminState.hotkey}
                isMandatory={true}
                errorMessage={adminState.hotkeyErrorMessage}
              ></TPNumeric>
            </div>
          </div>
        </div>
      </TPLoadingOverlay>
    );
  },
);

export default QuickClassifierShortcutInsertUpdate;
