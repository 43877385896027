import { SxProps } from "@mui/material";
import styled from "styled-components";

export const StyledDialogContent = styled.div`
  padding: 1em;

  .dialog-actions {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledTab: SxProps = {
  minWidth: "236px",
  alignItems: "flex-start",
  textTransform: "none",
  color: "#2E2E2E",
  fontSize: "14px",
  transition: "none",
  paddingLeft: 0,
  "&.Mui-selected": {
    color: "#780096",
    fontWeight: "700",
    borderColor: "#780096",
  },
  "&:focus": { outline: "none" },
  "&:active": { backgroundColor: "transparent" },
};

export const StyledTabs: SxProps = {
  "& .MuiTabs-indicator": {
    backgroundColor: "#780096",
  },
};
