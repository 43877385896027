import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { TPI18N } from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageTitle,
} from "../TPPage/tpPageStyles";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPTextArea from "../bootstrap/forms/textArea/TPTextArea";
import TPRenderKeyValues from "../TPRenderKeyValues/TPRenderKeyValues";
import {
  ActionTypeEnum,
  ModalSizeEnum,
  SystemParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import TPRadioGroup from "../bootstrap/forms/radio/TPRadioGroup";
import { TaskViewModel } from "@/models/Task/TaskModels";
import {
  TaskReportInputDTO,
  TEmpAdditionalDataTaskViewModel,
} from "@/models/Task/TaskReportInputDTO";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TaskService } from "@/services/TaskService";
import { TaskTypeService } from "@/services/TaskTypeService";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
  AdditionalDataService,
  AdditionalDataTaskTypeService,
} from "@/services/AdditionalDataService";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import TPRenderAdditionalDataSwitch from "../TPAdditionalData/TPRenderAdditionalDataSwitch";
import { TPAddtionalDataUIModes } from "../TPAdditionalData/TPAdditionalDataUImodes";
import { v4 as uuidv4 } from "uuid";
import { TEmpAdditionalDataCaseViewModel } from "@/models/Cases/CasesInputDTO";
import TPModal from "@/layouts/TPModal/TPModal";
import TPAutoComplete from "../bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { UserService } from "@/services/UserService";
import {
  ReassignTaskInputDTO,
  ReassignTaskInputDTOValidator,
} from "@/models/Task/ReassignTaskInputDTO";
import { ParametersService } from "@/services/ParametersService";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import {
  TaskRejectInputDTO,
  TaskRejectInputDTOValidator,
} from "@/models/Task/TaskRejectInputDTO";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import { CalculateTaskResponsibleInputDTO } from "@/models/Task/CalculateTaskResponsibleInputDTO";
import { TPEditor } from "../TPEditor/TPEditor";

interface TPYesNoTaskInterface {
  caseNumber: number;
  taskIdToView: number;
  mode: string;
  isWorkflowTask: boolean;
  closeTaskCallBack: Function;
  saveTaskCallBack: Function;
  actionsTaskCallBack?: Function;
}

type modalReassignScreenType = {
  newResponsibleGuidUser: Array<TPKeyValue>;
  report: string;
  newResponsibleGuidUserErrorMessage: string;
  reportErrorMessage: string;
  [x: string]: any;
};

type modalRejectScreenType = {
  reasonId: string;
  comments: string;
  reasonIdErrorMessage: string;
  commentsErrorMessage: string;
  [x: string]: any;
};

export const TPYesNoTask = React.forwardRef(
  (
    {
      caseNumber,
      taskIdToView,
      mode,
      isWorkflowTask,
      closeTaskCallBack,
      saveTaskCallBack,
      actionsTaskCallBack,
    }: TPYesNoTaskInterface,
    ref,
  ) => {
    //function called from parent
    useImperativeHandle(ref, () => ({
      refreshTaskFromParent() {
        loadTaskInfo();
      },
      canClose() {
        return !hasBeenModified;
      },
      clearHasBeenModified() {
        setHasBeenModified(false);
      },
      refreshAdditionalData: (
        newAdditionaData: Array<TEmpAdditionalDataTaskViewModel>,
      ) => {
        realRefreshAdditionalData(newAdditionaData);
      },
    }));

    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [isLoadingModalScreen, setIsLoadingModalScreen] = useState(true);
    const componentFileName: string = "TPYesNoTask.tsx";
    const resourceSet = "YesNoTaskComponent";
    const resourceSetReassignModal: string = "ReassignTaskModal";
    const resourceSetRejectModal: string = "RejectTaskModal";
    const resourceSetTaskViewer = "TaskViewerComponent";

    const editorRef = useRef<any>(null);

    const [responsibleLabel, setResponsibleLabel] = useState("");
    const [creationDateLabel, setCreationDateLabel] = useState("");
    const [startDateLabel, setStartDateLabel] = useState("");
    const [dueToDateLabel, setDueToDateLabel] = useState("");
    const [readerLabel, setReaderLabel] = useState("");
    const [reportSummaryLabel, setReportSummaryLabel] = useState("");

    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");

    const [editorErrorMessage, setEditorErrorMessage] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [saveButtonIsActive, setSaveButtonIsActive] = useState(false);
    const [
      calculateTaskResponsibleButtonLabel,
      setCalculateTaskResponsibleButtonLabel,
    ] = useState("");
    const [currentTask, setCurrentTask] = useState<TaskViewModel | null>(null);
    const [currentTaskType, setCurrentTaskType] =
      useState<TaskTypeViewModel | null>(null);
    const [hasBeenModified, setHasBeenModified] = useState(false);
    const [currentActionType, setCurrentActionType] = useState<ActionTypeEnum>(
      ActionTypeEnum.Reassign,
    );

    const [autocompleteToOptions, setAutocompleteToOptions] = useState<
      Array<TPKeyValue>
    >([]);

    //top n options for arrow icon
    const [autocompleteToTopNOptions, setAutocompleteToTopNOptions] = useState<
      Array<TPKeyValue>
    >([]);

    const [emptyLabel, setEmptyLabel] = useState("");
    const [isShownActionModal, setIsShownActionModal] = useState(false);
    const [toLabel, setToLabel] = useState("");
    const [taskTitleModal, setTaskTitleModal] = useState("");
    const [modalAcceptLabel, setModalAcceptLabel] = useState("");
    const [modalCancelLabel, setModalCancelLabel] = useState("");
    const [actionsLabel, setActionsLabel] = useState("");
    const [reassignMenuItemLabel, setReassignMenuItemLabel] = useState("");
    const [rejectMenuItemLabel, setRejectMenuItemLabel] = useState("");
    const [commentsLabel, setCommentsLabel] = useState("");
    const [reasonsLabel, setReasonsLabel] = useState("");

    //reassign
    const initialModalReassignScreenState: modalReassignScreenType = {
      newResponsibleGuidUser: [],
      report: "",
      newResponsibleGuidUserErrorMessage: "",
      reportErrorMessage: "",
    };
    const [modalReassignScreenState, setModalReassignScreenState] =
      useState<modalReassignScreenType>(initialModalReassignScreenState);

    //reject
    const initialModalRejectScreenState: modalRejectScreenType = {
      reasonId: "",
      comments: "",
      reasonIdErrorMessage: "",
      commentsErrorMessage: "",
    };
    const [modalRejectScreenState, setModalRejectScreenState] =
      useState<modalRejectScreenType>(initialModalRejectScreenState);

    const [reasonList, setReasonList] = useState<Array<TPKeyValue>>([]);

    //additionalData
    //key: additional data id
    //value: addtional data value
    //value2: other properties
    //value3: should show
    const [additionalDataValues, setAdditionalDataValues] = useState<
      Array<TPKeyValue>
    >([]);
    const childRefArray: any = useRef([]);
    const taskguid: string = uuidv4();

    const [currentPath, setCurrentPath] = useState("");
    const [currentPathErrorMessage, setCurrentPathErrorMessage] = useState("");

    const [editorValue, setEditorValue] = useState("");

    const loadResourcesAndTaskInfo = async () => {
      setResponsibleLabel(
        await TPI18N.GetText(resourceSetTaskViewer, "ResponsibleLabel"),
      );
      setCreationDateLabel(
        await TPI18N.GetText(resourceSetTaskViewer, "CreationDateLabel"),
      );
      setStartDateLabel(
        await TPI18N.GetText(resourceSetTaskViewer, "StartDateLabel"),
      );
      setDueToDateLabel(
        await TPI18N.GetText(resourceSetTaskViewer, "DueToDateLabel"),
      );
      setReaderLabel(
        await TPI18N.GetText(resourceSetTaskViewer, "ReaderLabel"),
      );
      setReportSummaryLabel(
        await TPI18N.GetText(resourceSetTaskViewer, "ReportSummaryLabel"),
      );

      setSaveButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButtonLabel"),
      );
      setCalculateTaskResponsibleButtonLabel(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "CalculateTaskResponsibleButtonLabel",
        ),
      );

      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"),
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));

      setToLabel(await TPI18N.GetText(resourceSetReassignModal, "ToLabel"));
      setModalAcceptLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setModalCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      setActionsLabel(
        await TPI18N.GetText(resourceSetReassignModal, "ActionsLabel"),
      );
      setReassignMenuItemLabel(
        await TPI18N.GetText(resourceSetReassignModal, "ReassignMenuItemLabel"),
      );
      setRejectMenuItemLabel(
        await TPI18N.GetText(resourceSetReassignModal, "RejectMenuItemLabel"),
      );
      setCommentsLabel(
        await TPI18N.GetText(resourceSetReassignModal, "CommentsLabel"),
      );
      setReasonsLabel(
        await TPI18N.GetText(resourceSetRejectModal, "ReasonsLabel"),
      );
      //get task info by id
      setIsLoadingScreen(true);
      await loadTaskInfo();

      //get additional data
      await getAdditionalDataForTaskId();
      setIsLoadingScreen(false);
    };

    const loadTaskInfo = async () => {
      let currentTaskElement: TaskViewModel | null;
      let currentTaskTypeElement: TaskTypeViewModel | null;
      try {
        currentTaskElement = await getTaskInforById(taskIdToView);
        if (currentTaskElement) {
          currentTaskElement.report = TPGlobal.TPReplaceEnter(
            currentTaskElement.report,
          );
          setCurrentTask(currentTaskElement);

          // Loading task type information
          currentTaskTypeElement = await getTaskTypeInformationById(
            currentTaskElement.taskTypeId,
          );
          if (currentTaskTypeElement) {
            setCurrentTaskType(currentTaskTypeElement);
          } else {
            //todo logs
          }
        } else {
          //todo logs
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} loadTaskInfo ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} loadTaskInfo ex`);
        setIsLoadingScreen(false);
      }
    };

    const getTaskTypeInformationById = async (idTaskType: string) => {
      let taskTypeService = new TaskTypeService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest = await taskTypeService.getTaskTypeById(
          idTaskType,
          false,
          true,
          expectedCodes,
        );
        if (responseRequest) {
          return responseRequest;
        } else {
          //todo logs
          return null;
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getTaskTypeInforById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getTaskTypeInforById ex`);
        return null;
      }
    };

    const getTaskInforById = async (idTask: number) => {
      let taskService = new TaskService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest = await taskService.getTaskById(
          idTask,
          false,
          true,
          expectedCodes,
        );
        if (responseRequest && responseRequest.length >= 1) {
          return responseRequest[0];
        } else {
          //todo logs
          return null;
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getTaskInforById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getTaskInforById ex`);
        return null;
      }
    };

    const getAdditionalDataForTaskId = async () => {
      let taskService = new AdditionalDataTaskTypeService();
      let additionalDataService = new AdditionalDataService();
      let expectedCodes: Array<number> = [200, 404];
      let expectedCodes2: Array<number> = [200];
      let newAddtionalDataValues: Array<TPKeyValue> = [];
      let additionalDataInfo: AdditionalDataViewModel;

      try {
        let responseRequest = await taskService.getByCaseTaskId(
          taskIdToView,
          false,
          true,
          expectedCodes,
        );
        if (responseRequest && responseRequest.length >= 1) {
          //for each additional data get other properties and
          //push elements on newAddtionalDataValues and mutate state
          for (let i = 0; i <= responseRequest.length - 1; i++) {
            additionalDataInfo =
              await additionalDataService.getAdditionalDataById(
                responseRequest[i].additionalDataId,
                false,
                true,
                expectedCodes2,
              );
            additionalDataInfo.order = responseRequest[i].order;
            additionalDataInfo.isMandatory = responseRequest[i].isMandatory;
            let oneKeyValue: TPKeyValue = {
              key: responseRequest[i].additionalDataId,
              value: responseRequest[i].value,
              value2: additionalDataInfo,
            };
            newAddtionalDataValues.push(oneKeyValue);
          }
          //mutate additional data state
          setAdditionalDataValues(newAddtionalDataValues);
        } else {
          //todo logs
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getAdditionalDataForTaskId ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getAdditionalDataForTaskId ex`,
        );
      }
    };

    // const handleChangeEditor = () => {
    //   setEditorErrorMessage("");
    //   if (editorRef.current) {
    //     if (editorRef.current.getContent()) {
    //       setHasBeenModified(true);
    //     } else {
    //       setHasBeenModified(false);
    //     }
    //   }
    // };

    const handleChangeEditor = (value: any) => {
      const filterData = value.replace(/(<([^>]+)>)/gi, "");
      setEditorErrorMessage("");
      setEditorValue(value);
      setHasBeenModified(filterData.length > 0 ? false : true);
    };

    const handleChangeYesNo = (e: any) => {
      let newValue = e.target.value;
      setCurrentPathErrorMessage("");
      setHasBeenModified(true);
      setCurrentPath(newValue);
    };

    const handleCloseTask = () => {
      if (mode === "EDIT") {
        if (hasBeenModified) {
          closeTaskCallBack(taskIdToView, true);
          return;
        }
      }
      closeTaskCallBack(taskIdToView, false);
    };

    const handleSaveTask = async () => {
      let inputDTO: TaskReportInputDTO;
      let atLeastOneError: boolean = false;
      let newTempAdditionalDataValues: Array<TEmpAdditionalDataCaseViewModel> =
        [];
      let comments: string;

      if (editorValue.trim() == "") {
        setHasBeenModified(true);
      }

      if (editorRef.current.props.value) {
        comments = editorRef.current.props.value;
        comments = comments === null ? "" : comments.trim();
        if (currentTaskType?.isCommentRequired && comments === "") {
          setEditorErrorMessage(
            await TPI18N.GetText(resourceSetTaskViewer, "ErrorNoComments"),
          );
          atLeastOneError = true;
        } else if (
          currentTaskType?.isCommentRequired &&
          TPGlobal.TPSanitizeWithoutLinks(comments).trim() === ""
        ) {
          comments = TPGlobal.TPSanitizeWithoutLinks(comments).trim();
          setEditorErrorMessage(
            await TPI18N.GetText(resourceSetTaskViewer, "ErrorNoComments"),
          );
          atLeastOneError = true;
        } else if (
          currentTaskType?.isCommentRequired &&
          TPGlobal.RemoveHtmlTinyWhiteSpaces(comments) === ""
        ) {
          setEditorErrorMessage(
            await TPI18N.GetText(resourceSetTaskViewer, "ErrorNoComments"),
          );
          atLeastOneError = true;
        }

        if (!currentPath) {
          setCurrentPathErrorMessage(
            await TPI18N.GetText(resourceSet, "ErrorNoPath"),
          );
          atLeastOneError = true;
        }
        //validate additional data
        if (!validateAdditonalData()) {
          atLeastOneError = true;
        }

        if (atLeastOneError) {
          return;
        }
        newTempAdditionalDataValues = getFinalAdditonalDataValues();
        setSaveButtonIsActive(true);

        inputDTO = {
          id: taskIdToView,
          report: TPGlobal.stringToUTF8(comments).toString(),
          decisionPath: currentPath,
          escalatedTaskReport: "",
          typistGuidUser: TPGlobal.currentUserGuid,
          tEmpAdditionalDataTask: newTempAdditionalDataValues,
          useRPCMethod: true,
        };
        saveTaskCallBack(inputDTO);
      }
    };

    const handleCalculateTaskResponsible = async () => {
      let inputDTO: CalculateTaskResponsibleInputDTO = {
        taskId: taskIdToView,
        typistGuidUser: TPGlobal.currentUserGuid,
      };

      let serviceClient = new TaskService();
      let expectedCodes: Array<number> = [200];
      try {
        let responseRequest = await serviceClient.CalculateTaskResponsible(
          inputDTO,
          false,
          true,
          expectedCodes,
        );

        if (responseRequest && responseRequest.length >= 1) {
          let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
            function (item) {
              return {
                key: item.userGuid,
                value: `${item.firstName} ${item.lastName}`,
              };
            },
          );

          let newmodalReassignScreenState = { ...modalReassignScreenState };
          newmodalReassignScreenState.newResponsibleGuidUser =
            newToKeyValueList;
          newmodalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
          setModalReassignScreenState(newmodalReassignScreenState);
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleCalculateTaskResponsible ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleCalculateTaskResponsible ex`,
        );
      }
    };

    const renderCollectMode = () => {
      let jsxElement: any;
      if (!currentTask) {
        return null;
      }
      jsxElement = (
        <div className="container-fluid">
          <div className="row">
            <div
              className="col d-flex justify-content-end"
              style={{ margin: "10px 0px" }}
            >
              <button
                className="btn-close"
                aria-label="Close"
                style={{ fontSize: "12px" }}
                onClick={() => handleCloseTask()}
              ></button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <TPPageTitle style={{ margin: "10px 10px 10px 0px" }}>
                    {currentTask.taskTypeLocalizedDescription}
                  </TPPageTitle>
                </div>
                <div>
                  <div className="dropdown">
                    <TPButton
                      iconAtRight={true}
                      dataBsToggle={true}
                      type={TPButtonTypes.icon}
                      onClick={() => {
                        TPGlobal.foo();
                      }}
                      icon={TPIconTypes.moreVert}
                      text={actionsLabel}
                      className={"menu-button"}
                    ></TPButton>

                    <ul className="dropdown-menu">
                      {TPGlobal.globalPermissions.canReassignTask && (
                        <li>
                          <a
                            onClick={() =>
                              handleOnActionClick(ActionTypeEnum.Reassign)
                            }
                            className="dropdown-item"
                            href="#"
                          >
                            {reassignMenuItemLabel}
                          </a>
                        </li>
                      )}
                      {mode.toUpperCase() !== "EDIT" &&
                        TPGlobal.globalPermissions.canRejectTask && (
                          <li>
                            <a
                              onClick={() =>
                                handleOnActionClick(ActionTypeEnum.Reject)
                              }
                              className="dropdown-item"
                              href="#"
                            >
                              {rejectMenuItemLabel}
                            </a>
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12">{renderHeader()}</div>
          </div> */}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <TPRadioGroup
                  id="IdRadioGroup"
                  value={currentPath}
                  source={[
                    { key: "Y", value: yesLabel },
                    { key: "N", value: noLabel },
                  ]}
                  errorMessage={currentPathErrorMessage}
                  onChange={handleChangeYesNo}
                ></TPRadioGroup>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <TPEditor
                referece={(editor: any) => (editorRef.current = editor)}
                value={editorValue}
                onChange={handleChangeEditor}
                initialValue=""
                isVisible={false}
                placeholder=""
              />
              <span style={{ color: "#dc3545", fontSize: "14px" }}>
                {editorErrorMessage}
              </span>
            </div>
          </div>

          <div
            className="row"
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            {renderAdditionalDataList(TPAddtionalDataUIModes.Collect)}
          </div>
          <div className="row">
            <div className="col">
              <TPPageAcceptCancelButtonsContainer
                style={{ marginBottom: "5px" }}
              >
                <TPButton
                  type={TPButtonTypes.primary}
                  onClick={handleSaveTask}
                  disabled={saveButtonIsActive}
                >
                  {saveButtonLabel}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>
            </div>
          </div>
          <div
            className="row"
            style={{
              border: "1px solid #dee2e6",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          ></div>
        </div>
      );
      return jsxElement;
    };

    const renderViewMode = () => {
      let jsxElement: any;
      if (!currentTask) {
        return null;
      }
      jsxElement = (
        <div className="container-fluid">
          <div className="row">
            <div
              className="col d-flex justify-content-end"
              style={{ margin: "10px 0px" }}
            >
              <button
                className="btn-close"
                aria-label="Close"
                style={{ fontSize: "12px" }}
                onClick={() => handleCloseTask()}
              ></button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <TPPageTitle style={{ margin: "10px 10px 10px 0px" }}>
                    {currentTask.taskTypeLocalizedDescription}
                  </TPPageTitle>
                </div>
                {isWorkflowTask && (
                  <div>
                    <div className="dropdown">
                      <TPButton
                        iconAtRight={true}
                        dataBsToggle={true}
                        type={TPButtonTypes.icon}
                        onClick={() => {
                          TPGlobal.foo();
                        }}
                        icon={TPIconTypes.moreVert}
                        text={actionsLabel}
                        className={"menu-button"}
                      ></TPButton>

                      <ul className="dropdown-menu">
                        {TPGlobal.globalPermissions.canRejectTask && (
                          <li>
                            <a
                              onClick={() =>
                                handleOnActionClick(ActionTypeEnum.Reject)
                              }
                              className="dropdown-item"
                              href="#"
                            >
                              {rejectMenuItemLabel}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12">{renderHeader()}</div>
          </div> */}
          <div className="row">
            <div className="col-12">
              <TPRadioGroup
                id="IdRadioGroup"
                value={currentTask.decisionPath}
                source={[
                  { key: "Y", value: yesLabel },
                  { key: "N", value: noLabel },
                ]}
              ></TPRadioGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div>{reportSummaryLabel}</div>
              <div
                className="form-control"
                style={{
                  minHeight: "100px",
                  maxHeight: "200px",
                  backgroundColor: "#e9ecef",
                  overflowY: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: currentTask.report }}
              ></div>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            {renderAdditionalDataList(TPAddtionalDataUIModes.View1)}
          </div>
          <div
            className="row"
            style={{
              border: "1px solid #dee2e6",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          ></div>
        </div>
      );
      return jsxElement;
    };

    const renderAdditionalDataList = (mode: TPAddtionalDataUIModes) => {
      return additionalDataValues.map(function (item) {
        let elementJsx;
        let index: number;
        let value: any;

        index = additionalDataValues.findIndex(
          (x: TPKeyValue) => x.key == item.key,
        );
        value = additionalDataValues[index].value;
        if (mode == TPAddtionalDataUIModes.Collect) {
          elementJsx = (
            <div className="col-6 mb-2" key={"adda" + item.key}>
              <TPRenderAdditionalDataSwitch
                guidControl={taskguid}
                modeUI={mode}
                defaultValue={value}
                itemToRender={item}
                ref={(element: any) => {
                  childRefArray.current[item.key] = element;
                }}
                onChange={(
                  idControl: any,
                  newValue: string,
                  addtionalDataId: string,
                ) =>
                  handleChangeAditionalDataValue(idControl, newValue, item.key)
                }
              />
            </div>
          );
        } else {
          elementJsx = (
            <div className="col-6 mb-2" key={"adda" + item.key}>
              <TPRenderAdditionalDataSwitch
                guidControl={taskguid}
                modeUI={mode}
                defaultValue={value}
                itemToRender={item}
                ref={(element: any) => {
                  childRefArray.current[item.key] = element;
                }}
              />
            </div>
          );
        }

        return elementJsx;
      });
    };

    //called before save case to validate additional data
    const validateAdditonalData = (): boolean => {
      let result: boolean = true;
      for (let i: number = 0; i <= additionalDataValues.length - 1; i++) {
        let element: TPKeyValue;
        element = additionalDataValues[i];
        //key: additional data id
        //value: addtional data value
        //value2: other properties
        //value3: should show

        if (childRefArray.current) {
          const keys = Object.keys(childRefArray.current);
          if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
            let controlref: any = childRefArray.current[element.key];
            if (!controlref.validateFromParent()) {
              result = false;
              break;
            }
          }
        }
      }
      return result;
    };

    //called before save case to get additional data values
    const getFinalAdditonalDataValues =
      (): Array<TEmpAdditionalDataCaseViewModel> => {
        let result: Array<TEmpAdditionalDataCaseViewModel> = [];
        for (let i: number = 0; i <= additionalDataValues.length - 1; i++) {
          let element: TPKeyValue;
          element = additionalDataValues[i];
          //key: additional data id
          //value: addtional data value
          //value2: other properties
          //value3: should show

          if (childRefArray.current) {
            const keys = Object.keys(childRefArray.current);
            if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
              let controlref: any = childRefArray.current[element.key];

              let stringValue: string;
              stringValue = controlref.getValueFromParent();
              if (stringValue) {
                let oneAddData: TEmpAdditionalDataCaseViewModel;
                oneAddData = {
                  additionalDataId: element.key,
                  value: stringValue,
                  isMandatory: element.value2.isMandatory,
                };
                result.push(oneAddData);
              }
            }
          }
        }
        return result;
      };

    const getHeaderValues = () => {
      let headerValues: Array<TPKeyValue> = [];
      if (currentTask) {
        headerValues.push({
          key: responsibleLabel,
          value: currentTask?.writerUserName,
        });
        headerValues.push({
          key: creationDateLabel,
          value: currentTask?.creationDateFormatted,
        });
        headerValues.push({
          key: startDateLabel,
          value: currentTask?.startDateFormatted,
        });
        headerValues.push({
          key: dueToDateLabel,
          value: currentTask?.limitDateFormatted,
          value2: true,
        });
        headerValues.push({
          key: readerLabel,
          value: currentTask?.readerUserName,
        });
      }

      return headerValues;
    };

    const renderHeader = () => {
      if (!currentTask) {
        return null;
      }
      return (
        <TPRenderKeyValues
          items={getHeaderValues()}
          size={200}
        ></TPRenderKeyValues>
      );
    };

    //handle additional data change
    const handleChangeAditionalDataValue = (
      idControl: string,
      newValue: any,
      additionalDataId: string,
    ) => {
      let newAddtionalDataValues: Array<TPKeyValue> = [...additionalDataValues];
      //mutate state
      for (let i = 0; i <= newAddtionalDataValues.length - 1; i++) {
        if (newAddtionalDataValues[i].key === additionalDataId) {
          newAddtionalDataValues[i].value = newValue;
          setHasBeenModified(true);
          break;
        }
      }
      setAdditionalDataValues(newAddtionalDataValues);
    };

    const realRefreshAdditionalData = (
      newAdditionaDataToCheck: Array<TEmpAdditionalDataTaskViewModel>,
    ) => {
      let newAdditionalDataValues: Array<TPKeyValue> = [
        ...additionalDataValues,
      ];
      let found: boolean = false;
      for (let i = 0; i <= newAdditionaDataToCheck.length - 1; i++) {
        for (let j = 0; j <= newAdditionalDataValues.length - 1; j++) {
          if (
            newAdditionaDataToCheck[i].additionalDataId.toUpperCase() ===
            newAdditionalDataValues[j].key.toUpperCase()
          ) {
            newAdditionalDataValues[j].value = newAdditionaDataToCheck[i].value;
            found = true;
          }
        }
      }
      if (found) {
        setAdditionalDataValues(newAdditionalDataValues);
      }
    };
    let alreadyLoadReasons: boolean = false;
    const handleOnActionClick = async (actionName: ActionTypeEnum) => {
      setIsShownActionModal(true);
      switch (actionName) {
        case ActionTypeEnum.Reassign:
          setTaskTitleModal(
            await TPI18N.GetText(
              resourceSetReassignModal,
              "ReassignTaskTitleModal",
            ),
          );
          setIsLoadingModalScreen(false);
          break;
        case ActionTypeEnum.Reject:
          setTaskTitleModal(
            await TPI18N.GetText(
              resourceSetRejectModal,
              "RejectTaskTitleModal",
            ),
          );
          if (!alreadyLoadReasons) {
            await getReasonsDatalist();
            alreadyLoadReasons = true;
          }
          setIsLoadingModalScreen(false);
          break;

        default:
          break;
      }
      setCurrentActionType(actionName);
    };

    const getReasonsDatalist = async () => {
      let parametersService = new ParametersService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest =
          await parametersService.getByParentIdAndFilterIsActive(
            SystemParametersEnum.REFORETA,
            TPActiveOptions.ACTIVE.toString(),
            false,
            true,
            expectedCodes,
          );
        if (responseRequest) {
          let newReasonList: Array<TPKeyValue> = responseRequest.map(
            function (item) {
              return { key: item.id, value: item.localizedDescription };
            },
          );
          newReasonList.unshift({ key: "", value: "--" });
          setReasonList(newReasonList);
        } else {
          //todo logs
          return null;
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getReasonsDatalist ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getReasonsDatalist ex`);
        return null;
      }
    };

    const handleCallbackAnserModal = async (confirm: boolean, data: any) => {
      if (confirm) {
        switch (currentActionType) {
          case ActionTypeEnum.Reassign:
            let recordInputDTO: ReassignTaskInputDTO = {
              taskId: taskIdToView,
              newResponsibleGuidUser:
                modalReassignScreenState.newResponsibleGuidUser.length > 0
                  ? modalReassignScreenState.newResponsibleGuidUser[0].key
                  : "",
              report: modalReassignScreenState.report,
            };
            let inputDTOValidator = new ReassignTaskInputDTOValidator();
            let resultValidator = inputDTOValidator.validate(recordInputDTO);
            if (!TPGlobal.TPIsEmpty(resultValidator)) {
              let newModalReassignScreenState = { ...modalReassignScreenState };
              var listPropertyNames = Object.keys(resultValidator);
              if (listPropertyNames) {
                for (let index = 0; index < listPropertyNames.length; index++) {
                  const element = listPropertyNames[index];
                  if (resultValidator[element]) {
                    newModalReassignScreenState[element + "ErrorMessage"] =
                      await TPI18N.GetResource(
                        resultValidator[element] as string,
                      );
                  } else {
                    newModalReassignScreenState[element + "ErrorMessage"] = "";
                  }
                }
              }
              setModalReassignScreenState(newModalReassignScreenState);
              return;
            }

            if (await reassignTask(recordInputDTO)) {
              if (actionsTaskCallBack) {
                actionsTaskCallBack(currentActionType);
              }
            }
            break;
          case ActionTypeEnum.Reject:
            let recordInputRejectTaskDTO: TaskRejectInputDTO = {
              id: taskIdToView,
              reasonId: modalRejectScreenState.reasonId,
              comments: modalRejectScreenState.comments,
              typistGuidUser: TPGlobal.currentUserGuid,
            };
            let inputDTORejectTaskValidator = new TaskRejectInputDTOValidator();
            let resultValidatorRejectTask =
              inputDTORejectTaskValidator.validate(recordInputRejectTaskDTO);
            if (!TPGlobal.TPIsEmpty(resultValidatorRejectTask)) {
              let newModalRejectScreenState = { ...modalRejectScreenState };
              var listPropertyNamesRejectTask = Object.keys(
                resultValidatorRejectTask,
              );
              if (listPropertyNamesRejectTask) {
                for (
                  let index = 0;
                  index < listPropertyNamesRejectTask.length;
                  index++
                ) {
                  const element = listPropertyNamesRejectTask[index];
                  if (resultValidatorRejectTask[element]) {
                    newModalRejectScreenState[element + "ErrorMessage"] =
                      await TPI18N.GetResource(
                        resultValidatorRejectTask[element] as string,
                      );
                  } else {
                    newModalRejectScreenState[element + "ErrorMessage"] = "";
                  }
                }
              }
              setModalRejectScreenState(newModalRejectScreenState);
              return;
            }

            if (await rejectTask(recordInputRejectTaskDTO)) {
              if (actionsTaskCallBack) {
                actionsTaskCallBack(currentActionType);
              }
            }
            break;
        }
      }
      setIsShownActionModal(false);
      setModalReassignScreenState(initialModalReassignScreenState);
      setModalRejectScreenState(initialModalRejectScreenState);
    };

    const reassignTask = async (
      inputDTO: ReassignTaskInputDTO,
    ): Promise<boolean> => {
      let serviceClient = new TaskService();
      let expectedCodes: Array<number> = [200];

      try {
        setIsLoadingModalScreen(true);

        let responseRequest = await serviceClient.reassignTaskResponsible(
          inputDTO,
          true,
          true,
          expectedCodes,
        );
        setIsLoadingModalScreen(false);
        if (responseRequest.responseResult) {
          return true;
        }
        return true;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reassignTask ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reassignTask ex`);
        setIsLoadingModalScreen(false);
        return false;
      }
    };

    const rejectTask = async (
      inputDTO: TaskRejectInputDTO,
    ): Promise<boolean> => {
      let serviceClient = new TaskService();
      let expectedCodes: Array<number> = [200];

      try {
        setIsLoadingModalScreen(true);

        let responseRequest = await serviceClient.rejectTask(
          inputDTO,
          true,
          true,
          expectedCodes,
        );
        setIsLoadingModalScreen(false);
        if (responseRequest.responseResult) {
          return true;
        }
        return false;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} rejectTask ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} rejectTask ex`);
        setIsLoadingModalScreen(false);
        return false;
      }
    };

    const handleToChange = (newSelectedValue: Array<TPKeyValue>) => {
      let newmodalReassignScreenState = { ...modalReassignScreenState };
      newmodalReassignScreenState.newResponsibleGuidUser = newSelectedValue;
      newmodalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
      setModalReassignScreenState(newmodalReassignScreenState);
    };

    const handleToOnAutocompleteQuery = async (query: string) => {
      let tasktypeService = new UserService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        //Load users by search
        let responseRequest =
          await tasktypeService.getActiveUsersBySearchParameter(
            query,
            false,
            true,
            expectedCodes,
          );
        let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return {
              key: item.userGuid,
              value: `${item.name}`,
            };
          },
        );
        setAutocompleteToOptions(newToKeyValueList);
        return newToKeyValueList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
        );
        return [];
      }
    };

    const handleToOnAutocompleteKeyDown = (event: any) => {
      //left arrow 37
      //right arror 39
      //enter 13
      //home 36
      //end  35
      // if (
      //   event.keyCode != 37 &&
      //   event.keyCode != 39 &&
      //   event.keyCode != 13 &&
      //   event.keyCode != 35 &&
      //   event.keyCode != 36
      // ) {
      //   setAutocompleteToOptions([]);
      //   let newModalReassignScreenState = { ...modalReassignScreenState };
      //   newModalReassignScreenState.newResponsibleGuidUser = [];
      //   newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
      //   setModalReassignScreenState(newModalReassignScreenState);
      // }
      const inputValue = event.target.value;
      if (inputValue.length === 1) {
        handleToOnAutocompleteQuery("");
      }
    };

    const handleAutoCompleteTopNClick = async () => {
      let newTopNOptions: Array<TPKeyValue> = [];
      if (autocompleteToTopNOptions.length === 0) {
        newTopNOptions = await handleToOnAutocompleteQuery("");

        if (newTopNOptions.length >= 1) {
          //save on cache
          setAutocompleteToTopNOptions([...newTopNOptions]);
          setAutocompleteToOptions([...newTopNOptions]);
          let newModalReassignScreenState = { ...modalReassignScreenState };
          newModalReassignScreenState.newResponsibleGuidUser = [];
          newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
          setModalReassignScreenState(newModalReassignScreenState);
        }
      } else {
        //use cached values;
        setAutocompleteToOptions([...autocompleteToTopNOptions]);
        let newModalReassignScreenState = { ...modalReassignScreenState };
        newModalReassignScreenState.newResponsibleGuidUser = [];
        newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
        setModalReassignScreenState(newModalReassignScreenState);
      }
    };

    const handleReassignsCommentOnChange = (newValue: string) => {
      let newModalReassignScreenState = { ...modalReassignScreenState };
      newModalReassignScreenState.report = newValue;
      newModalReassignScreenState.reportErrorMessage = "";
      setModalReassignScreenState(newModalReassignScreenState);
    };

    const handleRejectCommentOnChange = (newValue: string) => {
      let newModalRejectScreenState = { ...modalRejectScreenState };
      newModalRejectScreenState.comments = newValue;
      newModalRejectScreenState.commentsErrorMessage = "";
      setModalRejectScreenState(newModalRejectScreenState);
    };

    const handleOnReasonIdChange = (e: any) => {
      let newModalRejectScreenState = { ...modalRejectScreenState };
      newModalRejectScreenState.reasonId = e.target.value;
      newModalRejectScreenState.reasonIdErrorMessage = "";
      setModalRejectScreenState(newModalRejectScreenState);
    };

    useEffect(() => {
      childRefArray.current = [];
      loadResourcesAndTaskInfo();
    }, []);

    return (
      <>
        <TPModal
          modalState={{
            titleModal: `${taskTitleModal} - ${currentTask?.taskTypeLocalizedDescription}`,
            acceptLabel: modalAcceptLabel,
            cancelLabel: modalCancelLabel,
            callBackAnswer: handleCallbackAnserModal,
            callBackData: null,
            isShown: isShownActionModal,
            modalWidth: ModalSizeEnum.MODALLG,
          }}
        >
          <TPLoadingOverlay active={isLoadingModalScreen}>
            {currentActionType === ActionTypeEnum.Reassign && (
              <>
                <div className="row">
                  <div className="col-8">
                    <TPAutoComplete
                      isMandatory={true}
                      labelText={toLabel}
                      onValueChange={handleToChange}
                      onSearch={(query: string) => {
                        handleToOnAutocompleteQuery(query);
                      }}
                      isLoading={false}
                      options={autocompleteToOptions}
                      withIcon={true}
                      emptyLabel={emptyLabel}
                      onKeyDown={handleToOnAutocompleteKeyDown}
                      selected={modalReassignScreenState.newResponsibleGuidUser}
                      errorMessage={
                        modalReassignScreenState.newResponsibleGuidUserErrorMessage
                      }
                      downArrowClick={handleAutoCompleteTopNClick}
                    ></TPAutoComplete>
                  </div>
                  <div className="col-4 mt-4">
                    <TPButton
                      type={TPButtonTypes.primary}
                      onClick={handleCalculateTaskResponsible}
                    >
                      {calculateTaskResponsibleButtonLabel}
                    </TPButton>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TPTextArea
                      id="IdTextArea"
                      labelText={commentsLabel}
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleReassignsCommentOnChange(e.target.value)
                      }
                      value={modalReassignScreenState.report}
                      rows={7}
                      errorMessage={modalReassignScreenState.reportErrorMessage}
                    />
                  </div>
                </div>
              </>
            )}
            {currentActionType === ActionTypeEnum.Reject && (
              <>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        isMandatory={true}
                        labelText={reasonsLabel}
                        onChange={handleOnReasonIdChange}
                        dataSource={reasonList}
                        value={modalRejectScreenState.reasonId}
                        errorMessage={
                          modalRejectScreenState.reasonIdErrorMessage
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TPTextArea
                      id="IdTextArea"
                      labelText={commentsLabel}
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleRejectCommentOnChange(e.target.value)
                      }
                      value={modalRejectScreenState.comments}
                      rows={7}
                      errorMessage={modalRejectScreenState.commentsErrorMessage}
                    />
                  </div>
                </div>
              </>
            )}
          </TPLoadingOverlay>
        </TPModal>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div>
            {mode.toUpperCase() === "EDIT"
              ? renderCollectMode()
              : renderViewMode()}
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);
