import React, { useEffect, useState } from "react";

import ParentFolder from "./ImagesAdminParentFolder";
import {
  ImagesAdminContainerFolderDivStyle,
  IcoFolderIStyle,
  MenuItemDivStyle,
  NivelDivStyle,
  FileDivStyle,
  ContainerImageStyle,
  ImageStyle,
  PopupDetailDivStyle,
  NameFolderPStyle,
  NameFolderInputStyle,
  IcoIStyle,
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { OperationMode } from "./useStatusImagesAdmin";
import { click } from "@testing-library/user-event/dist/click";
import { ImagesAdminPopupMenu } from "./ImagesAdminPopupMenu";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import { TPI18N } from "@/services/I18nService";

type ContainerFolderProps = {
  file: RecursiveRoutes;
};

const ImagesAdminFile: React.FC<ContainerFolderProps> = ({ file }) => {
  const componentFileName: string = "ImagesAdminFile.tsx";
  const resourceSet: string = "ImagesAdminComponent";

  const [editName, setEditName] = React.useState<string>("");

  //Label
  const [namePopuLabel, setNamePopuLabel] = useState("");
  const [sizePopuLabel, setSizePopuLabelLabel] = useState("");
  const [dimensionPopuLabel, setDimensionPopuLabel] = useState("");
  const [keyWordsPopuLabel, setKeyWordsLabel] = useState("");
  const [creationDatePopuLabel, setCreationDatePopuLabel] = useState("");

  useEffect(() => {
    loadResourcesAndOrganizations();
  }, []);

  const loadResourcesAndOrganizations = async () => {
    setNamePopuLabel(await TPI18N.GetText(resourceSet, "NamePopuLabel"));
    setSizePopuLabelLabel(await TPI18N.GetText(resourceSet, "SizePopuLabel"));
    setDimensionPopuLabel(
      await TPI18N.GetText(resourceSet, "DimensionPopuLabel"),
    );
    setKeyWordsLabel(await TPI18N.GetText(resourceSet, "KeyWordsPopuLabel"));
    setCreationDatePopuLabel(
      await TPI18N.GetText(resourceSet, "CreationDatePopuLabel"),
    );
  };

  const value = useAppConctextImageAdmin();
  const {
    handleSelected,
    handleUpdateFolder,
    handleMultiSelectedFile,
    handleMultiSelectedFileClear,
    handleAllSelectedFile,
    listFilesModel,
    selectedFolderParent,
    selectedItem,
    operationMode,
    listMultiFileSelected,
  } = value;

  const handleActiveEditName = () => {
    if (operationMode == OperationMode.ActiveMenuFile) {
      handleSelected(file, OperationMode.EditFile);
    } else {
      file.fileName = editName;
      handleUpdateFolder(file);
      handleSelected(selectedFolderParent, OperationMode.ActiveFolder);
    }
  };

  const Cancel = () => {
    if (listMultiFileSelected.length <= 0) {
      handleSelected(selectedFolderParent, OperationMode.ActiveFolder);
    } else {
      handleMultiSelectedFileClear();
    }
  };

  const handleActivateMenu = (event: any) => {
    handleSelected(file, OperationMode.ActiveMenuFile);
    event.preventDefault();
  };

  const handleSeletedFileAction = (fileAction: RecursiveRoutes) => {
    if (listMultiFileSelected.length <= 0) {
      Cancel();
      handleSelected(fileAction, OperationMode.SelectFile);
    }
  };

  const handleDisableFolderActive = () => {
    Cancel();
    if (listMultiFileSelected.length <= 0) {
      file.isActive = !file.isActive;
      handleUpdateFolder(file);
    } else {
      listMultiFileSelected.forEach((e) => {
        e.isActive = !e.isActive;
        handleUpdateFolder(e);
      });
    }
  };

  const handleMultiSelectedFileAction = (e: any) => {
    e.preventDefault();
    handleMultiSelectedFile(file);
  };

  const handleSelectedAllFileAction = () => {
    handleAllSelectedFile();
  };

  return (
    <>
      <FileDivStyle
        onContextMenu={handleActivateMenu}
        grayscale={file.isActive ? 0 : 1}
      >
        <ContainerImageStyle>
          <ImageStyle
            onClick={() => {
              handleSeletedFileAction(file);
            }}
            src={file.thumbnailUrl}
            alt={file.fileName}
          ></ImageStyle>
          {!file.isActive ? (
            <IcoIStyle
              color="red"
              bottom={"3px"}
              right={"5px"}
              background_color="none"
              className={"bi bi-lock-fill"}
            ></IcoIStyle>
          ) : null}
          <IcoIStyle
            onClick={handleMultiSelectedFileAction}
            color={"black"}
            bottom={"70px"}
            right={"5px"}
            background_color="white"
            className={
              listMultiFileSelected.find((s) => s.id == file.id)
                ? "bi-check-square-fill"
                : "bi-dash-square"
            }
          ></IcoIStyle>
        </ContainerImageStyle>
        {selectedItem.id != null &&
        selectedItem.id === file.id &&
        (operationMode == OperationMode.ActiveMenuFile ||
          operationMode == OperationMode.EditFile) ? (
          <ImagesAdminPopupMenu
            right={"-35px"}
            top="0px"
            Cancel={Cancel}
            handleAdd={() => {}}
            handleMultiSelect={handleSelectedAllFileAction}
            handleActiveEditName={handleActiveEditName}
            handleRemove={handleDisableFolderActive}
            select={selectedItem}
            operationMode={operationMode}
            context={file}
          ></ImagesAdminPopupMenu>
        ) : (
          ""
        )}
        <PopupDetailDivStyle>
          <p>
            {namePopuLabel} : {file.fileName}
            <br />
            {sizePopuLabel}: {file.size}
            <br />
            {dimensionPopuLabel}: {file.dimensions}
            <br />
            {keyWordsPopuLabel}: {file.keywords}
            <br />
            {creationDatePopuLabel}: {file.dateUpload}{" "}
          </p>
        </PopupDetailDivStyle>
        {selectedItem.id != null &&
        selectedItem.id === file.id &&
        operationMode == OperationMode.EditFile &&
        listMultiFileSelected.length <= 0 ? (
          <NameFolderInputStyle
            type="text"
            placeholder={file.fileName.trim()}
            onChange={(e) => {
              setEditName(e.target.value);
            }}
            width={"100px"}
          />
        ) : (
          <NameFolderPStyle
            onClick={() => {
              handleSeletedFileAction(file);
            }}
          >
            {" "}
            {file.fileName}
          </NameFolderPStyle>
        )}
      </FileDivStyle>
    </>
  );
};

export default ImagesAdminFile;
