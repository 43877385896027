//https://northflank.com/blog/adding-themes-to-a-react-app-using-styled-components

import { Base } from "./themeInterfaces";

const brandHighlight = "#ffb8dc";
const brandHighlightError = "#ed9393";
const secondaryBrandHighlight = "#e4ccea";
const highlightForeground = "#2e002b";
const errorDark = "#8c0900";

const allThemes: { [id: string]: Base } = {
  base: {
    brandHighlight: brandHighlight,
    primary: "#a00095",
    secondary: "#6c757d",
    mandatory: "red",
    errorColor: "red",
    errorDark: errorDark,
    errorBackground: "red",
    defaultFontColor: "#2E2E2E",
    whiteColor: "white",
    redColor: "red",
    greenColor: "green",
    cherryColor: "#e9e9e9",
    graySlightColor: "#e6e6e6",
    borderGrayColor: "#dee2e6",
    whiteSmoke: "#f5f5f5",
    darkGray: "#989898",
    redGray: "#dbd5d5",
    blackColor: "#2e2e2e",
    yellowColor: "yellow",
    darkColor: "#a5a5a5",
    link: {
      fontColor: "#a00095",
    },
    legend: {
      sectionBorder: {
        backgroundColor: "white",
        color: "black",
      },
    },
    button: {
      primary: {
        backgroundColor: "#a00095",
        borderColor: "#a00095",
        fontColor: "white",
      },
      secondary: {
        backgroundColor: "#6c757d",
        borderColor: "#6c757d",
      },
      outlinePrimary: {
        fontColor: "#a00095",
        borderColor: "#a00095",
        hover: {
          fontColor: "white",
          backgroundColor: "#a00095",
        },
      },
      link: {
        fontColor: "#a00095",
      },
      empty: {
        backgroundColor: "transparent",
      },
      disabled: {
        backgroundColor: "#736472",
        borderColor: "#a00095",
        fontColor: "white",
      },
    },
    cim: {
      button: {
        primary: {
          color: "#ffff",
          background: "#780096",
          border: "#780096",
          hover: {
            color: "#ffff",
            background: "#9002B1",
            border: "#9002B1",
          },
          disabled: {
            color: "#989898",
            background: "#E6E6E6",
            border: "#E6E6E6",
          },
        },
        secondary: {
          color: "#780096",
          background: "#ffff",
          border: "#780096",
          hover: {
            color: "#9002B1",
            background: "#E6E6E6",
            border: "#9002B1",
          },
          disabled: {
            color: "#989898",
            background: "#ffff",
            border: "#989898",
          },
        },
        tertiary: {
          color: "#780096",
          background: "transparent",
          border: "transparent",
          hover: {
            color: "#9002B1",
            background: "transparent",
            border: "transparent",
          },
          disabled: {
            color: "#989898",
            background: "transparent",
            border: "transparent",
          },
        },
      },
    },
    fixedHeadTable: {
      fontColor: "#1f1f1f",
      borderColor: "#dee2e6",
      noTransparent: {
        backgroundColor: "white",
      },
      thead: {
        th: {
          backgroundColor: "white",
        },
      },
      showTotalRows: {
        fontColor: "#1f1f1f",
      },
      highlightOnHover: {
        tbody: {
          tr: {
            hover: {
              backgroundColor: "#eeeeee",
            },
          },
        },
      },
      noHighlightOnHover: {
        tbody: {
          tr: {
            hover: {
              backgroundColor: "white",
            },
          },
        },
      },
    },
    table: {
      tableContainer: {
        backgroundColor: "#f7f7f7",
        borderColor: "#efeeee",
      },
      dataTableContainer: {
        borderColor: "#dee2e6",
        dropdown: {
          menu: {
            item: {
              fontcolor: "#212529",
              hover: {
                backgroundColor: "white",
              },
            },
          },
        },
      },
      icon: {
        isActive: {
          fontColor: "#989898",
        },
      },
      condensed: {
        tbody: {
          tr: {
            selected: {
              backgroundColor: "#cbcbcb",
            },
          },
        },
        td: {
          borderColor: "#ced4da",
        },
      },
    },
    tree: {
      branch: {
        conventions: {
          active: {
            visible: {
              fontColor: "black",
            },
            invisible: {
              fontColor: "black",
              backgroundColor: "yellow",
            },
          },
          inactive: {
            visible: {
              fontColor: "red",
            },
            invisible: {
              fontColor: "red",
              backgroundColor: "yellow",
            },
          },
          selected: {
            active: {
              visible: {},
              invisible: {
                borderColor: "#a00095",
                fontColor: "black",
              },
            },
            inactive: {
              visible: {
                borderColor: "#a00095",
                fontColor: "red",
                backgroundColor: "white",
              },
              invisible: { borderColor: "#a00095", fontColor: "red" },
            },
          },
        },
      },
    },
    loading: {
      loadingOverlay: {
        backgroundColor: "rgba(237, 237, 237, 0.7)",
      },
    },
    checkbox: {
      border: "#7B7B7B",
      checked: {
        backgroundColor: "#009A58",
        borderColor: "#009A58",
      },
      focus: {
        borderColor: "#009A58",
      },
    },
    select: {
      option: {
        hover: {
          backgroundColor: "#a00095",
          fontColor: "white",
        },
      },
    },
    conditionsTable: {
      container: {
        borderColor: "#dfdfdf",
      },
      totalConditions: {
        backgroundColor: "#a00095",
      },
    },
    configValues: {
      menu: {
        backgroundColor: "#f5f5f5",
        item: {
          backgroundColor: "gray",
        },
      },
      category: {
        itemContainer: {
          hover: {
            fontColor: "#a00095",
          },
        },
        content: {
          title: {
            backgroundColor: "#f5f5f5",
          },
        },
      },
      submenu: {
        a: {
          fontColor: "#2e2e2e",
        },
        hover: {
          backgroundColor: "#a00095",
          a: {
            fontColor: "white",
          },
        },
      },
      content: {
        borderColor: "gainsboro",
      },
      footer: {
        borderColor: "gainsboro",
      },
    },
    wizard: {
      step: {
        backgroundColor: "#e6e6e6",
        fontColor: "black",
        number: {
          backgroundColor: "whitesmoke",
          fontColor: "black",
          borderColor: "#d9d9d9",
        },
        title: {
          fontColor: "#000",
        },
        active: {
          backgroundColor: "transparent",
          fontColor: "black",
          number: {
            backgroundColor: "#a00095",
            fontColor: "white",
            borderColor: "#a00095",
          },
          title: {
            fontColor: "#a00095",
          },
        },
      },
    },
    leftMenu: {
      backgroundColor: "#F5F5F5",
      footer: {
        backgroundColor: "#F0F0F0",
      },
      logoAndStation: {
        fontColor: "#989898",
        logoCircle: {
          borderColor: "#989898",
        },
        station: {
          label: {
            fontColor: "#7B7B7B",
          },
          number: {
            fontColor: "#04B469",
          },
          name: {
            fontColor: "#2e2e2e",
          },
        },
      },
    },
    notificationCenter: {
      backgroundColor: "#989898",
      fontColor: "#2e2e2e",
      item: {
        backgroundColor: "white",
        success: {
          borderColor: "green",
        },
        warning: {
          borderColor: "orange",
        },
        error: {
          borderColor: "red",
        },
        info: {
          borderColor: "blue",
        },
      },
      date: {
        fontColor: "gray",
      },
    },
    scrollbar: {
      color: "#989898",
    },
    accordion: {
      collapseButton: {
        backgroundColor: secondaryBrandHighlight,
        color: highlightForeground,
        border: "none",
        textDecoration: "none",
      },
    },
    highlight: {
      backgroundColor: secondaryBrandHighlight,
      color: highlightForeground,
      errorBackgroundColor: brandHighlightError,
    },
  },
};

export default allThemes;
