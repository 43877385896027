import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPFilterSelector from "@/components/TPFilterSelector/TPFilterSelector";
import TPModal from "@/layouts/TPModal/TPModal";

import {
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { CasesFollowUpFilterDistinctViewModel } from "@/models/FollowUp/CasesFollowUpFilterDistinctViewModel";
import { CasesFollowUpFiltersModel } from "@/models/FollowUp/CasesFollowUpFiltersModel";
import { CasesFollowUpInputDTO } from "@/models/FollowUp/CasesFollowUpInputDTO";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { FollowUpService } from "@/services/FollowUpService";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useReducer, useState } from "react";

import StatusAlertIMG from "@/assets/images/casestatus/StatusAlert.gif";
import StatusExpiredIMG from "@/assets/images/casestatus/StatusExpired.gif";
import StatusOnHoldIMG from "@/assets/images/casestatus/StatusOnHold.gif";
import StatusPendingIMG from "@/assets/images/casestatus/StatusPending.gif";
import StatusRecivedIMG from "@/assets/images/casestatus/StatusRecived.gif";
import StatusSolvedIMG from "@/assets/images/casestatus/StatusSolved.gif";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { FavoritesInputDTO } from "@/models/Favorites/FavoritesInputDTO";
import { CasesFollowUpViewModel } from "@/models/FollowUp/CasesFollowUpViewModel";
import { FavoritesService } from "@/services/FavoritesService";

enum commandsEnum {
  "change_folder" = 0,
  "change_activefilter" = 1,
  "close_currentFilter" = 2,
  "apply_currentFilter_part1" = 3,
  "apply_currentFilter_part2" = 4,
  "change_currentPage" = 5,
  "change_currentOrder" = 6,
  "remove_filters_part1" = 7,
  "refresh" = 8,
  "change_activeColumns" = 9,
  "change_highlight_pattern" = 10,
  "change_show_highlight_rows" = 11,
  "clear_highlight_options" = 12,
  "convert_destroy_favorite" = 13,
}

enum filterColumnsEnum {
  "NONE" = "NONE",
  "FAVORITE" = "FAVORITE",
  "CASENUMBER" = "S_CASEIDFILTERCOL",
  "CASESTATUS" = "S_CSSTIDFILTERCOL",
  "RESPONSIBLE" = "S_CASERESPOFILTERCOL",
  "CUSTOMERNAME" = "S_CUSNAFILTERCOL",
  "CUSTOMERTYPE" = "S_CUTYIDFILTERCOL",
  "CASECOMMENTS" = "XX1",
  "BASELEVEL" = "S_BALEIDFILTERCOL",
  "CLASSIFY1" = "S_CLASSID1FILTERCOL",
  "CLASSIFY2" = "S_CLASSID2FILTERCOL",
  "CLASSIFY3" = "S_CLASSID3FILTERCOL",
  "CLASSIFY4" = "S_CLASSID4FILTERCOL",
  "CLASSIFY5" = "S_CLASSID5FILTERCOL",
  "INSERTDATE" = "S_INSDATEFILTERCOL",
  "CLOSEDATE" = "S_CDATEFILTERCOL",
  "PROMISEDATE" = "S_PRODATEFILTERCOL",
  "TASKS" = "XX2",
}

export enum orderColumnsEnum {
  "BASELEVEL" = "S_BALEIDORDEREDCOL",
  "CASENUMBER" = "S_CASEIDORDEREDCOL",
  "CLOSEDATE" = "S_CDATEORDEREDCOL",
  "CLASSIFY1" = "S_CLASSID1ORDEREDCOL",
  "CLASSIFY2" = "S_CLASSID2ORDEREDCOL",
  "CLASSIFY3" = "S_CLASSID3ORDEREDCOL",
  "CLASSIFY4" = "S_CLASSID4ORDEREDCOL",
  "CLASSIFY5" = "S_CLASSID5ORDEREDCOL",
  "COMMENTS" = "S_COMMENTSORDEREDCOL",
  "CASESTATUS" = "S_CSSTIDORDEREDCOL",
  "CUSTOMERTYPE" = "S_CUTYIDORDEREDCOL",
  "INSERTDATE" = "S_INSDATEORDEREDCOL",
  "LASTMODIFYDATE" = "S_LMDATEORDEREDCOL",
  "PROMISEDATE" = "S_CPROMIORDEREDCOL",
  "CUSTOMERNAME" = "S_CLIORDEREDCOL",
  "RESPONSIBLE" = "S_CASERESPORDEREDCOL",
}

export enum filterColumnsCodesEnum {
  "BASELEVEL" = "S_BALEIDDISTCOL",
  "CASENUMBER" = "S_CASEIDDISTCOL",
  "CLOSEDATE" = "S_CDATEDISTCOL",
  "CLASSIFY1" = "S_CLASSID1DISTCOL",
  "CLASSIFY2" = "S_CLASSID2DISTCOL",
  "CLASSIFY3" = "S_CLASSID3DISTCOL",
  "CLASSIFY4" = "S_CLASSID4DISTCOL",
  "CLASSIFY5" = "S_CLASSID5DISTCOL",
  "CUSTOMERNAME" = "S_CLIDISTCOL",
  "CASESTATUS" = "S_CSSTIDDISTCOL",
  "CUSTOMERTYPE" = "S_CUTYIDDISTCOL",
  "RESPONSIBLE" = "S_CASERESPODISTCOL",
  "INSERTDATE" = "S_CASEINSDISTCOL",
  "PROMISEDATE" = "S_CASEPRODISTCOL",
}

type AdminStateType = {
  currentFolder: folderTypesEnum;
  gridData: Array<any>;
  rowsPerPage: number;
  totalRows: number;
  currentPage: number;

  //order
  currentOrderType: orderColumnsEnum;
  currentOrderDirection: string;

  //filters
  applyFilters: boolean;
  currentFilter: filterColumnsEnum;

  caseNumberFilter: Array<TPKeyValue>;
  caseStatusFilter: Array<TPKeyValue>;
  responsibleFilter: Array<TPKeyValue>;
  customerNameFilter: Array<TPKeyValue>;
  customerTypeFilter: Array<TPKeyValue>;
  baseLevelFilter: Array<TPKeyValue>;
  classificator1Filter: Array<TPKeyValue>;
  classificator2Filter: Array<TPKeyValue>;
  classificator3Filter: Array<TPKeyValue>;
  classificator4Filter: Array<TPKeyValue>;
  classificator5Filter: Array<TPKeyValue>;

  insertDateFilter: Array<TPKeyValue>;
  closeDateFilter: Array<TPKeyValue>;
  promiseDateFilter: Array<TPKeyValue>;

  alreadyClickCaseNumberFilter: boolean;
  alreadyClickCaseStatusFilter: boolean;
  alreadyClickResponsibleFilter: boolean;
  alreadyClickCustomerNameFilter: boolean;
  alreadyClickCustomerTypeFilter: boolean;
  alreadyClickBaseLevelFilter: boolean;
  alreadyClickClassificator1Filter: boolean;
  alreadyClickClassificator2Filter: boolean;
  alreadyClickClassificator3Filter: boolean;
  alreadyClickClassificator4Filter: boolean;
  alreadyClickClassificator5Filter: boolean;

  alreadyClickInsertDateFilter: boolean;
  alreadyClickCloseDateFilter: boolean;
  alreadyClickPromiseDateFilter: boolean;

  showFavoriteColumn: boolean;
  showCasesColumn: boolean;
  showStatusColumn: boolean;
  showResponsibleColumn: boolean;
  showCustomerNameColumn: boolean;
  showCustomerTypeColumn: boolean;
  showCommentsColumn: boolean;
  showTasksColumn: boolean;
  showBaseLevelColumn: boolean;
  showClassificator1Column: boolean;
  showClassificator2Column: boolean;
  showClassificator3Column: boolean;
  showClassificator4Column: boolean;
  showClassificator5Column: boolean;
  showInsertDateColumn: boolean;
  showCloseDateColumn: boolean;
  showPromiseDateColumn: boolean;

  //counters
  allCounter: number;
  myTaskCounter: number;
  myTeamsCounter: number;
  myFavoritesCounter: number;
  myTypedCasesCounter: number;
  myRespobilitityCounter: number;

  //highlight
  currentHighlightPattern: string;
  highlightCells: Array<TPKeyValue>;
  filterHighlightRows: boolean;
};

type commandType = {
  type: commandsEnum;
  payload: any;
};

enum folderTypesEnum {
  "ALL" = "ALL",
  "MYTASKS" = "MYTASKS",
  "MYTEAM" = "MYTEAM",
  "FAVORITES" = "FAVORITES",
  "TYPED" = "TYPED",
  "RESPONSIBLE" = "RESPONSIBLE",
}

export enum realFoldersTypeEnum {
  "PendingCases" = 0,
  "PendingRead" = 1,
  "InterestCases" = 2,
  "TypedCases" = 3,
  "ResponsibilitiesCases" = 4,
  "MyTeamCases" = 5,
  "MyTeamOnlyUserCases" = 6,
  "Reminders" = 7,
  "TeamReminders" = 8,
  "ALL" = 9,
}

type modalSelectColumnsType = {
  isShown: boolean;
  classModal: string;
  styleModal: any;
  backdropClass: string;

  localshowCasesColumn: boolean;
  localshowStatusColumn: boolean;
  localshowResponsibleColumn: boolean;
  localshowCustomerNameColumn: boolean;
  localshowCustomerTypeColumn: boolean;
  localshowCommentsColumn: boolean;
  localshowTasksColumn: boolean;
  localshowBaseLevelColumn: boolean;
  localshowClassificator1Column: boolean;
  localshowClassificator2Column: boolean;
  localshowClassificator3Column: boolean;
  localshowClassificator4Column: boolean;
  localshowClassificator5Column: boolean;

  localshowInsertDateColumn: boolean;
  localshowCloseDateColumn: boolean;
  localshowPromiseDateColumn: boolean;

  localRowsPerPage: number;
};

type modalHighLightStateType = {
  isShown: boolean;
  classModal: string;
  styleModal: any;
  backdropClass: string;
};

enum FavoriteOptionsEnum {
  "Convert_ToFavorite" = 0,
  "Destroy_ToFavorite" = 1,
}

const MyTasks = () => {
  const casecontext: any = React.useContext(TPCaseViewerContext);
  //#region Init

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const componentFileName: string = "MyTasks.tsx";

  //Screen resources
  const resourceSet: string = "MyTasksComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [allFolder, setAllFolder] = useState("");
  const [myTaskFolder, setMyTaskFolder] = useState("");
  const [myTeamsFolder, setMyTeamsFolder] = useState("");
  const [favoritesFolder, setFavoritesFolder] = useState("");
  const [myTypedCasesFolder, setMyTypedCasesFolder] = useState("");
  const [responsibleFolder, setResponsibleFolder] = useState("");
  const [textToHighlight, setTextToHighlight] = useState("");
  const [taskidColumnLabel, setTaskidColumnLabel] = useState("");
  const [taskStatusColumnLabel, setTaskStatusColumnLabel] = useState("");
  const [taskResponsibleColumnLabel, setTaskResponsibleColumnLabel] =
    useState("");
  const [taskCreationDateColumnLabel, setTaskCreationDateColumnLabel] =
    useState("");
  const [taskLimitDateColumnLabel, setTaskLimitDateColumnLabel] = useState("");
  const [
    taskTypeLocalizedDescriptionColumnLabel,
    setTaskTypeLocalizedDescriptionColumnLabel,
  ] = useState("");
  const [thereAreHiddenRowsLabel, setThereAreHiddenRowsLabel] = useState("");

  const [refreshLabel, setRefreshLabel] = useState("");
  const [removeFilterLabel, setRemoveFilterLabel] = useState("");
  const [highLightResultsLabel, setHighLightResultsLabel] = useState("");

  const [totalRowsLabel, setTotalRowsLabel] = useState("");
  const [allFilterLabel, setAllFilterLabel] = useState("");
  const [selectFiltersTitle, setSelectFiltersTitle] = useState("");
  const [otherSettingsLabel, setOtherSettingsLabel] = useState("");
  const [selectColumnsToShowTitle, setSelectColumnsToShowTitle] = useState("");
  const [okButtonLabel, setOkButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [clearFilterButtonLabel, setClearFilterButtonLabel] = useState("");
  const [rowsPerPageLabel, setRowsPerPageLabel] = useState("");
  const [otherParametersLabel, setOtherParametersLabel] = useState("");
  const [showOnlyRows, setShowOnlyRows] = useState("");

  const [favoriteColumn, setFavoriteColumn] = useState("");
  const [caseNumberColumn, setCaseNumberColumn] = useState("");
  const [caseStatusColumn, setCaseStatusColumn] = useState("");
  const [caseResponsibleColumn, setCaseResponsibleColumn] = useState("");
  const [customerNameColumn, setCustomerNameColumn] = useState("");
  const [customerTypeColumn, setCustomerTypeColumn] = useState("");
  const [caseCommentsColumn, setCaseCommentsColumn] = useState("");
  const [tasksColumn, setTasksColumn] = useState("");
  const [baseLevelColumn, setBaseLevelColumn] = useState("");
  const [classify1Column, setClassify1Column] = useState("");
  const [classify2Column, setClassify2Column] = useState("");
  const [classify3Column, setClassify3Column] = useState("");
  const [classify4Column, setClassify4Column] = useState("");
  const [classify5Column, setClassify5Column] = useState("");
  const [insertDateColumn, setInsertDateColumn] = useState("");
  const [closeDateColumn, setCloseDateColumn] = useState("");
  const [promiseDateColumn, setPromiseDateColumn] = useState("");

  const [isShownModalBodySelect, setIsShownModalBodySelect] = useState(false);
  const [testInerHtmlComponent, setTestInerHtmlComponent] = useState("");
  const [commentModalLabel, setCommentModalLabel] = useState("");
  const [caseNumberModalLabel, setCaseNumberModalLabel] = useState("");

  const defaultRowsPerPage: number = 100;
  const favoriteColumnWidth: string = "100px";
  const caseNumberColumnWidth: string = "100px";
  const caseStatusColumnWidth: string = "100px";
  const responsibleColumnWidth: string = "200px";
  const customerNameColumnWidth: string = "200px";
  const customerTypeColumnWidth: string = "100px";
  const commentsColumnWidth: string = "200px";
  const tasksColumnWidth: string = "200px";
  const baseLevelColumnWidth: string = "200px";
  const classify1ColumnWidth: string = "200px";
  const classify2ColumnWidth: string = "200px";
  const classify3ColumnWidth: string = "200px";
  const classify4ColumnWidth: string = "200px";
  const classify5ColumnWidth: string = "200px";
  const insertDateColumnWidth: string = "100px";
  const closeDateColumnWidth: string = "100px";
  const promiseDateColumnWidth: string = "100px";

  const divGridMaxHeight: string = "600px";

  let ascTriangle: any = <>&#9650;</>;
  let descTriangle: any = <>&#9660;</>;

  const intialModalSelectColumnsState: modalSelectColumnsType = {
    isShown: false,
    classModal: "modal",
    styleModal: { display: "none" },
    backdropClass: "",

    localshowCasesColumn: false,
    localshowStatusColumn: false,
    localshowResponsibleColumn: false,
    localshowCustomerNameColumn: false,
    localshowCustomerTypeColumn: false,
    localshowCommentsColumn: false,
    localshowTasksColumn: false,
    localshowBaseLevelColumn: false,
    localshowClassificator1Column: false,
    localshowClassificator2Column: false,
    localshowClassificator3Column: false,
    localshowClassificator4Column: false,
    localshowClassificator5Column: false,

    localshowInsertDateColumn: false,
    localshowCloseDateColumn: false,
    localshowPromiseDateColumn: false,

    localRowsPerPage: defaultRowsPerPage,
  };
  const [modalSelectColumnsState, setModalSelectColumnsState] =
    useState<modalSelectColumnsType>(intialModalSelectColumnsState);

  const initialModalHighLightState: modalHighLightStateType = {
    isShown: false,
    classModal: "modal",
    styleModal: { display: "none" },
    backdropClass: "",
  };
  const [modalHighLightState, setModalHighLightState] =
    useState<modalHighLightStateType>(initialModalHighLightState);

  const initialRowsPerPageList: Array<TPKeyValue> = [
    { key: "100", value: "100" },
    { key: "200", value: "200" },
    { key: "500", value: "500" },
    { key: "1000", value: "1000" },
    { key: "5000", value: "5000" },
  ];

  const [rowsPerPageList, setRowsPerPageList] = useState(
    initialRowsPerPageList,
  );

  //#endregion

  const [showTasksTable, setShowTasksTable] = useState<Array<number>>([]);

  const loadResourcesAndInitialData = async () => {
    let newGridData: Array<any> | null = [];
    let counters: any;
    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
    setAllFolder(await TPI18N.GetText(resourceSet, "AllFolder"));
    setMyTaskFolder(await TPI18N.GetText(resourceSet, "MyTaskFolder"));
    setMyTeamsFolder(await TPI18N.GetText(resourceSet, "MyTeamsFolder"));
    setFavoritesFolder(await TPI18N.GetText(resourceSet, "FavoritesFolder"));
    setMyTypedCasesFolder(
      await TPI18N.GetText(resourceSet, "MyTypedCasesFolder"),
    );
    setResponsibleFolder(
      await TPI18N.GetText(resourceSet, "ResponsibleFolder"),
    );

    setRefreshLabel(await TPI18N.GetText(resourceSet, "ReloadButton"));
    setHighLightResultsLabel(
      await TPI18N.GetText(resourceSet, "HighLightResultsLabel"),
    );

    setRemoveFilterLabel(
      await TPI18N.GetText(resourceSet, "RemoveFilterLabel"),
    );

    setTextToHighlight(await TPI18N.GetText(resourceSet, "TextToHighlight"));
    setTaskidColumnLabel(
      await TPI18N.GetText(resourceSet, "TaskidColumnLabel"),
    );
    setTaskStatusColumnLabel(
      await TPI18N.GetText(resourceSet, "TaskStatusColumnLabel"),
    );
    setTaskResponsibleColumnLabel(
      await TPI18N.GetText(resourceSet, "TaskResponsibleColumnLabel"),
    );
    setTaskTypeLocalizedDescriptionColumnLabel(
      await TPI18N.GetText(
        resourceSet,
        "TaskTypeLocalizedDescriptionColumnLabel",
      ),
    );

    setTaskCreationDateColumnLabel(
      await TPI18N.GetText(resourceSet, "TaskCreationDateColumnLabel"),
    );

    setTaskLimitDateColumnLabel(
      await TPI18N.GetText(resourceSet, "TaskLimitDateColumnLabel"),
    );

    setThereAreHiddenRowsLabel(
      await TPI18N.GetText(resourceSet, "ThereAreHiddenRowsLabel"),
    );

    setTotalRowsLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "TotalRowsLabel"),
    );
    setAllFilterLabel(await TPI18N.GetText(resourceSet, "AllFilterLabel"));
    setSelectFiltersTitle(
      await TPI18N.GetText(resourceSet, "SelectFiltersTitle"),
    );
    setOtherSettingsLabel(
      await TPI18N.GetText(resourceSet, "OtherSettingsLabel"),
    );
    setSelectColumnsToShowTitle(
      await TPI18N.GetText(resourceSet, "SelectColumnsToShowTitle"),
    );

    setOkButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setClearFilterButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ClearFilter"),
    );
    setRowsPerPageLabel(await TPI18N.GetText(resourceSet, "RowsPerPageLabel"));
    setOtherParametersLabel(
      await TPI18N.GetText(resourceSet, "OtherParametersLabel"),
    );

    //grid columns
    setFavoriteColumn(await TPI18N.GetText(resourceSet, "FavoriteColumn"));
    setCaseNumberColumn(await TPI18N.GetText(resourceSet, "CaseNumberColumn"));
    setCaseStatusColumn(await TPI18N.GetText(resourceSet, "CaseStatusColumn"));
    setCaseResponsibleColumn(
      await TPI18N.GetText(resourceSet, "CaseResponsibleColumn"),
    );
    setCustomerNameColumn(
      await TPI18N.GetText(resourceSet, "CustomerNameColumn"),
    );
    setCustomerTypeColumn(
      await TPI18N.GetText(resourceSet, "CustomerTypeColumn"),
    );
    setCaseCommentsColumn(
      await TPI18N.GetText(resourceSet, "CaseCommentsColumn"),
    );
    setTasksColumn(await TPI18N.GetText(resourceSet, "TasksColumn"));
    setBaseLevelColumn(await TPI18N.GetText(resourceSet, "BaseLevelColumn"));
    setClassify1Column(await TPI18N.GetText(resourceSet, "Classify1Column"));
    setClassify2Column(await TPI18N.GetText(resourceSet, "Classify2Column"));
    setClassify3Column(await TPI18N.GetText(resourceSet, "Classify3Column"));
    setClassify4Column(await TPI18N.GetText(resourceSet, "Classify4Column"));
    setClassify5Column(await TPI18N.GetText(resourceSet, "Classify5Column"));

    setInsertDateColumn(await TPI18N.GetText(resourceSet, "InsertDateColumn"));
    setCloseDateColumn(await TPI18N.GetText(resourceSet, "CloseDateColumn"));
    setPromiseDateColumn(
      await TPI18N.GetText(resourceSet, "PromiseDateColumn"),
    );
    setShowOnlyRows(await TPI18N.GetText(resourceSet, "ShowOnlyRows"));

    setCommentModalLabel(
      await TPI18N.GetText(resourceSet, "commentModalLabel"),
    );
    setCaseNumberModalLabel(
      await TPI18N.GetText(resourceSet, "caseNumberModalLabel"),
    );

    //counters
    counters = await calcFoldersCounter();

    //get initial data for my task folder
    newGridData = await reloadGridData(
      folderTypesEnum.MYTASKS,
      1,
      defaultRowsPerPage,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      let command1: commandType = {
        type: commandsEnum.change_folder,
        payload: {
          newCurrentFolder: folderTypesEnum.MYTASKS,
          newGridData: newGridData,
          newTotalRows:
            newGridData && newGridData.length >= 1
              ? newGridData[0].totalRecords
              : 0,
          newCounters: counters,
          newHighLightedCells: [],
        },
      };
      dispatchCommand(command1);
      await calcFoldersCounter();
    } else {
      //todo logs
    }
  };

  const buildFilterJson = () => {
    let newFilter: Array<CasesFollowUpFiltersModel> = [];
    let i: number;
    let newFilterValue: string = "";

    //case number
    if (adminState.caseNumberFilter.length >= 1) {
      if (adminState.caseNumberFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.caseNumberFilter.length - 1; i++) {
          if (adminState.caseNumberFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue + adminState.caseNumberFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CASENUMBER,
            filterValue: newFilterValue,
            filterType: "int",
          });
        }
      }
    }

    //case status
    if (adminState.caseStatusFilter.length >= 1) {
      if (adminState.caseStatusFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.caseStatusFilter.length - 1; i++) {
          if (adminState.caseStatusFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue + adminState.caseStatusFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CASESTATUS,
            filterValue: newFilterValue,
            filterType: "string",
          });
        }
      }
    }

    //responsible
    if (adminState.responsibleFilter.length >= 1) {
      if (adminState.responsibleFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.responsibleFilter.length - 1; i++) {
          if (adminState.responsibleFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue + adminState.responsibleFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.RESPONSIBLE,
            filterValue: newFilterValue,
            filterType: "int",
          });
        }
      }
    }

    //insertDate
    if (adminState.insertDateFilter.length >= 1) {
      if (adminState.insertDateFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.insertDateFilter.length - 1; i++) {
          if (adminState.insertDateFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue + adminState.insertDateFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.INSERTDATE,
            filterValue: newFilterValue,
            filterType: "datetime",
          });
        }
      }
    }
    //closedate
    if (adminState.closeDateFilter.length >= 1) {
      if (adminState.closeDateFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.closeDateFilter.length - 1; i++) {
          if (adminState.closeDateFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue + adminState.closeDateFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CLOSEDATE,
            filterValue: newFilterValue,
            filterType: "datetime",
          });
        }
      }
    }
    //promisedate
    if (adminState.promiseDateFilter.length >= 1) {
      if (adminState.promiseDateFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.promiseDateFilter.length - 1; i++) {
          if (adminState.promiseDateFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue + adminState.promiseDateFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.PROMISEDATE,
            filterValue: newFilterValue,
            filterType: "datetime",
          });
        }
      }
    }
    //customer name
    if (adminState.customerNameFilter.length >= 1) {
      if (adminState.customerNameFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.customerNameFilter.length - 1; i++) {
          if (adminState.customerNameFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue +
              adminState.customerNameFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CUSTOMERNAME,
            filterValue: newFilterValue,
            filterType: "int",
          });
        }
      }
    }
    //customer type
    if (adminState.customerTypeFilter.length >= 1) {
      if (adminState.customerTypeFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.customerTypeFilter.length - 1; i++) {
          if (adminState.customerTypeFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue +
              adminState.customerTypeFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CUSTOMERTYPE,
            filterValue: newFilterValue,
            filterType: "string",
          });
        }
      }
    }

    //base level
    if (adminState.baseLevelFilter.length >= 1) {
      if (adminState.baseLevelFilter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.baseLevelFilter.length - 1; i++) {
          if (adminState.baseLevelFilter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue + adminState.baseLevelFilter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.BASELEVEL,
            filterValue: newFilterValue,
            filterType: "string",
          });
        }
      }
    }

    //CLASSIFY 1
    if (adminState.classificator1Filter.length >= 1) {
      if (adminState.classificator1Filter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.classificator1Filter.length - 1; i++) {
          if (adminState.classificator1Filter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue +
              adminState.classificator1Filter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CLASSIFY1,
            filterValue: newFilterValue,
            filterType: "string",
          });
        }
      }
    }

    //CLASSIFY 2
    if (adminState.classificator2Filter.length >= 1) {
      if (adminState.classificator2Filter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.classificator2Filter.length - 1; i++) {
          if (adminState.classificator2Filter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue +
              adminState.classificator2Filter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CLASSIFY2,
            filterValue: newFilterValue,
            filterType: "string",
          });
        }
      }
    }

    //CLASSIFY 3
    if (adminState.classificator3Filter.length >= 1) {
      if (adminState.classificator3Filter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.classificator3Filter.length - 1; i++) {
          if (adminState.classificator3Filter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue +
              adminState.classificator3Filter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CLASSIFY3,
            filterValue: newFilterValue,
            filterType: "string",
          });
        }
      }
    }

    //CLASSIFY 4
    if (adminState.classificator4Filter.length >= 1) {
      if (adminState.classificator4Filter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.classificator4Filter.length - 1; i++) {
          if (adminState.classificator4Filter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue +
              adminState.classificator4Filter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CLASSIFY4,
            filterValue: newFilterValue,
            filterType: "string",
          });
        }
      }
    }

    //CLASSIFY 5
    if (adminState.classificator5Filter.length >= 1) {
      if (adminState.classificator5Filter[0].value2 == false) {
        newFilterValue = "";
        for (i = 1; i <= adminState.classificator5Filter.length - 1; i++) {
          if (adminState.classificator5Filter[i].value2) {
            if (newFilterValue !== "") {
              newFilterValue = newFilterValue + ",";
            }
            newFilterValue =
              newFilterValue +
              adminState.classificator5Filter[i].key.substring(3);
          }
        }
        if (newFilterValue !== "") {
          newFilter.push({
            filterName: filterColumnsEnum.CLASSIFY5,
            filterValue: newFilterValue,
            filterType: "string",
          });
        }
      }
    }

    return newFilter;
  };

  const reloadGridData = async (
    folder: folderTypesEnum,
    startRecord: number,
    endRecord: number,
    orderedColumn: orderColumnsEnum,
    orderDirection: string,
  ) => {
    let serviceClient = new FollowUpService();
    let expectedCodes: Array<number> = [200, 404];
    let realFolder: realFoldersTypeEnum;
    let inputDTO: CasesFollowUpInputDTO;

    try {
      setIsLoadingScreen(true);
      switch (folder) {
        case folderTypesEnum.FAVORITES:
          realFolder = realFoldersTypeEnum.InterestCases;
          break;
        case folderTypesEnum.MYTASKS:
          realFolder = realFoldersTypeEnum.PendingCases;
          break;
        case folderTypesEnum.MYTEAM:
          realFolder = realFoldersTypeEnum.MyTeamCases;
          break;
        case folderTypesEnum.TYPED:
          realFolder = realFoldersTypeEnum.TypedCases;
          break;
        case folderTypesEnum.RESPONSIBLE:
          realFolder = realFoldersTypeEnum.ResponsibilitiesCases;
          break;
        case folderTypesEnum.ALL:
          realFolder = realFoldersTypeEnum.ALL;
          break;
      }
      inputDTO = {
        followUpType: realFolder,
        userGuid: TPGlobal.currentUserGuid,
        startRecord: startRecord,
        endRecord: endRecord,
        orderedColumn: orderedColumn,
        orderDirection: orderDirection,
        tempTaskSearchFilter: buildFilterJson(),
      };
      //todo

      let responseRequest =
        await serviceClient.getFollowUpCasesByFolderAndFilter(
          inputDTO,
          false,
          false,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`,
      );
      setIsLoadingScreen(false);
      return null;
    }
  };

  const calcFoldersCounter = async () => {
    let newGridData: Array<any> | null = [];
    let newAllCounter: number;
    let newMyTaskCounter: number;
    let newMyTeamsCounter: number;
    let newMyFavoritesCounter: number;
    let newMyTypedCasesCounter: number;
    let newMyRespobilitityCounter: number;

    newAllCounter = 0;
    newMyTaskCounter = 0;
    newMyTeamsCounter = 0;
    newMyFavoritesCounter = 0;
    newMyTypedCasesCounter = 0;
    newMyRespobilitityCounter = 0;

    //all
    newGridData = await reloadGridData(
      folderTypesEnum.ALL,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newAllCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //my task
    newGridData = await reloadGridData(
      folderTypesEnum.MYTASKS,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyTaskCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //my team
    newGridData = await reloadGridData(
      folderTypesEnum.MYTEAM,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyTeamsCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //favorites
    newGridData = await reloadGridData(
      folderTypesEnum.FAVORITES,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyFavoritesCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //typed cases
    newGridData = await reloadGridData(
      folderTypesEnum.TYPED,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyTypedCasesCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    //responsabilities
    newGridData = await reloadGridData(
      folderTypesEnum.RESPONSIBLE,
      1,
      1,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      newMyRespobilitityCounter =
        newGridData && newGridData.length >= 1
          ? newGridData[0].totalRecords
          : 0;
    } else {
      //todo logs
    }

    return {
      newAllCounter: newAllCounter,
      newMyTaskCounter: newMyTaskCounter,
      newMyTeamsCounter: newMyTeamsCounter,
      newMyFavoritesCounter: newMyFavoritesCounter,
      newMyTypedCasesCounter: newMyTypedCasesCounter,
      newMyRespobilitityCounter: newMyRespobilitityCounter,
    };
  };

  const handleFolderOnClick = async (newFolder: folderTypesEnum) => {
    let newGridData: Array<any> | null = [];
    let counters: any;
    let newHighLightedCells: Array<TPKeyValue> = [];
    counters = await calcFoldersCounter();

    newGridData = await reloadGridData(
      newFolder,
      1,
      adminState.rowsPerPage,
      orderColumnsEnum.CASENUMBER,
      "asc",
    );
    if (newGridData != null) {
      if (adminState.currentHighlightPattern.trim() !== "") {
        newHighLightedCells = findHighlightedCells(
          newGridData,
          adminState.currentHighlightPattern,
        );
      }

      let command1: commandType = {
        type: commandsEnum.change_folder,
        payload: {
          newCurrentFolder: newFolder,
          newGridData: newGridData,
          newTotalRows:
            newGridData && newGridData.length >= 1
              ? newGridData[0].totalRecords
              : 0,
          newCounters: counters,
          newHighLightedCells: newHighLightedCells,
        },
      };
      dispatchCommand(command1);
    } else {
      //todo logs
    }
  };

  const handleRefreshClick = async () => {
    let newGridData: Array<any> | null = [];
    let counters: any;
    let newHighLightedCells: Array<TPKeyValue> = [];
    counters = await calcFoldersCounter();

    newGridData = await reloadGridData(
      adminState.currentFolder,
      1,
      adminState.rowsPerPage,
      adminState.currentOrderType,
      adminState.currentOrderDirection,
    );
    if (newGridData != null) {
      if (adminState.currentHighlightPattern.trim() !== "") {
        newHighLightedCells = findHighlightedCells(
          newGridData,
          adminState.currentHighlightPattern,
        );
      }

      let command1: commandType = {
        type: commandsEnum.refresh,
        payload: {
          newGridData: newGridData,
          newTotalRows:
            newGridData && newGridData.length >= 1
              ? newGridData[0].totalRecords
              : 0,
          newCounters: counters,
          newHighLightedCells: newHighLightedCells,
        },
      };
      dispatchCommand(command1);
    } else {
      //todo logs
    }
  };

  const handleRemoveFilterClick = async () => {
    let command1: commandType = {
      type: commandsEnum.remove_filters_part1,
      payload: null,
    };
    dispatchCommand(command1);
  };

  const handleChangePage = async (direction: string) => {
    let newPageNumber: number;
    let newGridData: Array<any> | null = [];
    let newHighLightedCells: Array<TPKeyValue> = [];

    if (direction === "FIRST" && adminState.currentPage === 1) {
      return;
    }
    if (direction === "PREVIOUS" && adminState.currentPage === 1) {
      return;
    }
    if (direction === "NEXT" && adminState.currentPage === calcMaxPages()) {
      return;
    }
    if (direction === "LAST" && adminState.currentPage === calcMaxPages()) {
      return;
    }
    newPageNumber = adminState.currentPage;
    if (direction === "FIRST") {
      newPageNumber = 1;
    }
    if (direction === "PREVIOUS") {
      newPageNumber = newPageNumber - 1;
    }
    if (direction === "NEXT") {
      newPageNumber = newPageNumber + 1;
    }
    if (direction === "LAST") {
      newPageNumber = calcMaxPages();
    }

    newGridData = await reloadGridData(
      adminState.currentFolder,
      (newPageNumber - 1) * adminState.rowsPerPage + 1,
      newPageNumber * adminState.rowsPerPage,
      adminState.currentOrderType,
      adminState.currentOrderDirection,
    );
    if (newGridData != null) {
      if (adminState.currentHighlightPattern.trim() !== "") {
        newHighLightedCells = findHighlightedCells(
          newGridData,
          adminState.currentHighlightPattern,
        );
      }

      let command1: commandType = {
        type: commandsEnum.change_currentPage,
        payload: {
          newCurrentPage: newPageNumber,
          newGridData: newGridData,
          newTotalRows:
            newGridData && newGridData.length >= 1
              ? newGridData[0].totalRecords
              : 0,
          newHighLightedCells: newHighLightedCells,
        },
      };
      dispatchCommand(command1);
    } else {
      //todo logs
    }
  };

  const getFilterByFolderUserAndColumn = async (
    folder: folderTypesEnum,
    column: filterColumnsCodesEnum,
  ) => {
    let serviceClient = new FollowUpService();
    let expectedCodes: Array<number> = [200, 404];
    let i: number;
    let realFolder: realFoldersTypeEnum;
    try {
      setIsLoadingScreen(true);
      switch (folder) {
        case folderTypesEnum.FAVORITES:
          realFolder = realFoldersTypeEnum.InterestCases;
          break;
        case folderTypesEnum.MYTASKS:
          realFolder = realFoldersTypeEnum.PendingCases;
          break;
        case folderTypesEnum.MYTEAM:
          realFolder = realFoldersTypeEnum.MyTeamCases;
          break;
        case folderTypesEnum.TYPED:
          realFolder = realFoldersTypeEnum.TypedCases;
          break;
        case folderTypesEnum.RESPONSIBLE:
          realFolder = realFoldersTypeEnum.ResponsibilitiesCases;
          break;
        case folderTypesEnum.ALL:
          realFolder = realFoldersTypeEnum.ALL;
          break;
      }
      let responseRequest = await serviceClient.getFilterByFolderUserAndColumn(
        realFolder,
        TPGlobal.currentUserGuid,
        column,
        false,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFilterByFolderUserAndColumn ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getFilterByFolderUserAndColumn ex`,
      );
      setIsLoadingScreen(false);
      return null;
    }
  };

  const handleFilterOnClick = async (newFilter: filterColumnsEnum) => {
    let newFilterValues: Array<TPKeyValue> = [];
    let filterData: Array<CasesFollowUpFilterDistinctViewModel> | null = [];
    let i: number;
    let allSelected: boolean = true;

    if (newFilter == adminState.currentFilter) {
      //close filter if already open
      let command1: commandType = {
        type: commandsEnum.close_currentFilter,
        payload: null,
      };
      dispatchCommand(command1);
      return;
    }

    switch (newFilter) {
      case filterColumnsEnum.CASENUMBER:
        if (!adminState.alreadyClickCaseNumberFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CASENUMBER,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.caseNumberFilter.length - 1;
            i++
          ) {
            if (adminState.caseNumberFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.caseNumberFilter[i].key.substring(3),
              columnDescription: adminState.caseNumberFilter[i].value,
              columnValue: adminState.caseNumberFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CASESTATUS:
        if (!adminState.alreadyClickCaseStatusFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CASESTATUS,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.caseStatusFilter.length - 1;
            i++
          ) {
            if (adminState.caseStatusFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.caseStatusFilter[i].key.substring(3),
              columnDescription: adminState.caseStatusFilter[i].value,
              columnValue: adminState.caseStatusFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.RESPONSIBLE:
        if (!adminState.alreadyClickResponsibleFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.RESPONSIBLE,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.responsibleFilter.length - 1;
            i++
          ) {
            if (adminState.responsibleFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.responsibleFilter[i].key.substring(3),
              columnDescription: adminState.responsibleFilter[i].value,
              columnValue: adminState.responsibleFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CUSTOMERNAME:
        if (!adminState.alreadyClickCustomerNameFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CUSTOMERNAME,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.customerNameFilter.length - 1;
            i++
          ) {
            if (adminState.customerNameFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.customerNameFilter[i].key.substring(3),
              columnDescription: adminState.customerNameFilter[i].value,
              columnValue: adminState.customerNameFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.INSERTDATE:
        if (!adminState.alreadyClickInsertDateFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.INSERTDATE,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.insertDateFilter.length - 1;
            i++
          ) {
            if (adminState.insertDateFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.insertDateFilter[i].key.substring(3),
              columnDescription: adminState.insertDateFilter[i].value,
              columnValue: adminState.insertDateFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CLOSEDATE:
        if (!adminState.alreadyClickCloseDateFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CLOSEDATE,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.closeDateFilter.length - 1;
            i++
          ) {
            if (adminState.closeDateFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.closeDateFilter[i].key.substring(3),
              columnDescription: adminState.closeDateFilter[i].value,
              columnValue: adminState.closeDateFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.PROMISEDATE:
        if (!adminState.alreadyClickPromiseDateFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.PROMISEDATE,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.promiseDateFilter.length - 1;
            i++
          ) {
            if (adminState.promiseDateFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.promiseDateFilter[i].key.substring(3),
              columnDescription: adminState.promiseDateFilter[i].value,
              columnValue: adminState.promiseDateFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CUSTOMERTYPE:
        if (!adminState.alreadyClickCustomerTypeFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CUSTOMERTYPE,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.customerTypeFilter.length - 1;
            i++
          ) {
            if (adminState.customerTypeFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.customerTypeFilter[i].key.substring(3),
              columnDescription: adminState.customerTypeFilter[i].value,
              columnValue: adminState.customerTypeFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.BASELEVEL:
        if (!adminState.alreadyClickBaseLevelFilter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.BASELEVEL,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.baseLevelFilter.length - 1;
            i++
          ) {
            if (adminState.baseLevelFilter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.baseLevelFilter[i].key.substring(3),
              columnDescription: adminState.baseLevelFilter[i].value,
              columnValue: adminState.baseLevelFilter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CLASSIFY1:
        if (!adminState.alreadyClickClassificator1Filter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CLASSIFY1,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.classificator1Filter.length - 1;
            i++
          ) {
            if (adminState.classificator1Filter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.classificator1Filter[i].key.substring(3),
              columnDescription: adminState.classificator1Filter[i].value,
              columnValue: adminState.classificator1Filter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CLASSIFY2:
        if (!adminState.alreadyClickClassificator2Filter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CLASSIFY2,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.classificator2Filter.length - 1;
            i++
          ) {
            if (adminState.classificator2Filter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.classificator2Filter[i].key.substring(3),
              columnDescription: adminState.classificator2Filter[i].value,
              columnValue: adminState.classificator2Filter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CLASSIFY3:
        if (!adminState.alreadyClickClassificator3Filter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CLASSIFY3,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.classificator3Filter.length - 1;
            i++
          ) {
            if (adminState.classificator3Filter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.classificator3Filter[i].key.substring(3),
              columnDescription: adminState.classificator3Filter[i].value,
              columnValue: adminState.classificator3Filter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CLASSIFY4:
        if (!adminState.alreadyClickClassificator4Filter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CLASSIFY4,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.classificator4Filter.length - 1;
            i++
          ) {
            if (adminState.classificator4Filter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.classificator4Filter[i].key.substring(3),
              columnDescription: adminState.classificator4Filter[i].value,
              columnValue: adminState.classificator4Filter[i].value2,
            });
          }
        }
        break;
      case filterColumnsEnum.CLASSIFY5:
        if (!adminState.alreadyClickClassificator5Filter) {
          filterData = await getFilterByFolderUserAndColumn(
            adminState.currentFolder,
            filterColumnsCodesEnum.CLASSIFY5,
          );
        } else {
          for (
            let i: number = 1;
            i <= adminState.classificator5Filter.length - 1;
            i++
          ) {
            if (adminState.classificator5Filter[i].value2 == false) {
              allSelected = false;
            }

            filterData.push({
              columnID: adminState.classificator5Filter[i].key.substring(3),
              columnDescription: adminState.classificator5Filter[i].value,
              columnValue: adminState.classificator5Filter[i].value2,
            });
          }
        }
        break;
    }

    if (filterData !== null) {
      newFilterValues.push({
        key: "ALL",
        value: allFilterLabel,
        value2: allSelected,
      });
      for (i = 0; i <= filterData.length - 1; i++) {
        newFilterValues.push({
          key: "key" + filterData[i].columnID,
          value: filterData[i].columnDescription,
          value2:
            filterData[i].columnValue === undefined
              ? true
              : filterData[i].columnValue,
        });
      }
    } else {
      //todo logs
      return;
    }

    let command1: commandType = {
      type: commandsEnum.change_activefilter,
      payload: {
        newActiveFilter: newFilter,
        newFilterValues: newFilterValues,
      },
    };
    dispatchCommand(command1);
  };

  const closeModalSelectColumns = () => {
    let newModalSelectColumnsState: modalSelectColumnsType = {
      ...modalSelectColumnsState,
    };
    newModalSelectColumnsState.isShown = false;
    setModalSelectColumnsState(newModalSelectColumnsState);
  };

  const handleShowSelectColumns = () => {
    let newModalSelectColumnsState: modalSelectColumnsType = {
      ...modalSelectColumnsState,
    };
    newModalSelectColumnsState.localshowCasesColumn =
      adminState.showCasesColumn;
    newModalSelectColumnsState.localshowStatusColumn =
      adminState.showStatusColumn;
    newModalSelectColumnsState.localshowResponsibleColumn =
      adminState.showResponsibleColumn;
    newModalSelectColumnsState.localshowCustomerNameColumn =
      adminState.showCustomerNameColumn;
    newModalSelectColumnsState.localshowCustomerTypeColumn =
      adminState.showCustomerTypeColumn;
    newModalSelectColumnsState.localshowCommentsColumn =
      adminState.showCommentsColumn;
    newModalSelectColumnsState.localshowTasksColumn =
      adminState.showTasksColumn;

    newModalSelectColumnsState.localshowBaseLevelColumn =
      adminState.showBaseLevelColumn;
    newModalSelectColumnsState.localshowClassificator1Column =
      adminState.showClassificator1Column;
    newModalSelectColumnsState.localshowClassificator2Column =
      adminState.showClassificator2Column;
    newModalSelectColumnsState.localshowClassificator3Column =
      adminState.showClassificator3Column;
    newModalSelectColumnsState.localshowClassificator4Column =
      adminState.showClassificator4Column;
    newModalSelectColumnsState.localshowClassificator5Column =
      adminState.showClassificator5Column;

    newModalSelectColumnsState.localshowInsertDateColumn =
      adminState.showInsertDateColumn;
    newModalSelectColumnsState.localshowCloseDateColumn =
      adminState.showCloseDateColumn;
    newModalSelectColumnsState.localshowPromiseDateColumn =
      adminState.showPromiseDateColumn;

    newModalSelectColumnsState.isShown = true;
    newModalSelectColumnsState.localRowsPerPage = adminState.rowsPerPage;
    setModalSelectColumnsState(newModalSelectColumnsState);
  };

  const closeModalHighLight = () => {
    let newModalHighLightState: modalHighLightStateType = {
      ...modalHighLightState,
    };
    newModalHighLightState.isShown = false;
    setModalHighLightState(newModalHighLightState);
  };

  const handleHighLightResultsClick = () => {
    let newModalHighLightState: modalHighLightStateType = {
      ...modalHighLightState,
    };
    newModalHighLightState.isShown = true;
    setModalHighLightState(newModalHighLightState);
  };

  const handleApplySelectColumns = () => {
    let command1: commandType = {
      type: commandsEnum.change_activeColumns,
      payload: null,
    };
    dispatchCommand(command1);
  };

  //called when filter component click apply or close buttons
  const handleOnFilteSelectorChange = (
    filter: filterColumnsEnum,
    success: boolean,
    selectedItems: Array<TPKeyValue>,
  ) => {
    if (!success) {
      let command1: commandType = {
        type: commandsEnum.close_currentFilter,
        payload: null,
      };
      dispatchCommand(command1);
      return;
    } else {
      let command1: commandType = {
        type: commandsEnum.apply_currentFilter_part1,
        payload: {
          changedFilter: filter,
          newFilterValues: selectedItems,
        },
      };
      dispatchCommand(command1);
    }
  };

  const calcMaxPages = () => {
    var maxPages: number = 0;
    maxPages = Math.trunc(adminState.totalRows / adminState.rowsPerPage);
    if (maxPages * adminState.rowsPerPage < adminState.totalRows) {
      maxPages = maxPages + 1;
    }
    return maxPages;
  };

  const handleColumnOrderOnClick = async (
    clickedColumnToOrder: orderColumnsEnum,
  ) => {
    let newCurrentOrderType: orderColumnsEnum = orderColumnsEnum.CASENUMBER;
    let newCurrentOrderDirection: string = "asc";
    let newGridData: Array<any> | null = [];
    let newHighLightedCells: Array<TPKeyValue> = [];

    if (adminState.currentOrderType === clickedColumnToOrder) {
      //toggle direction
      newCurrentOrderType = clickedColumnToOrder;
      if (adminState.currentOrderDirection === "asc") {
        newCurrentOrderDirection = "desc";
      } else {
        newCurrentOrderDirection = "asc";
      }
    } else {
      newCurrentOrderType = clickedColumnToOrder;
      newCurrentOrderDirection = "asc";
    }
    //new search
    newGridData = await reloadGridData(
      adminState.currentFolder,
      1,
      adminState.rowsPerPage,
      newCurrentOrderType,
      newCurrentOrderDirection,
    );
    if (newGridData != null) {
      if (adminState.currentHighlightPattern.trim() !== "") {
        newHighLightedCells = findHighlightedCells(
          newGridData,
          adminState.currentHighlightPattern,
        );
      }

      let command1: commandType = {
        type: commandsEnum.change_currentOrder,
        payload: {
          newCurrentOrderType: newCurrentOrderType,
          newCurrentOrderDirection: newCurrentOrderDirection,
          newGridData: newGridData,
          newTotalRows:
            newGridData && newGridData.length >= 1
              ? newGridData[0].totalRecords
              : 0,
          newHighLightedCells: newHighLightedCells,
        },
      };
      dispatchCommand(command1);
    } else {
      //todo logs
    }
  };

  const renderGridColumnHeader = (columnToRender: filterColumnsEnum) => {
    let jsxElement: any;
    let columnWidth: string = "";
    let equivalentOrderColumn: orderColumnsEnum = orderColumnsEnum.CASENUMBER;
    let columnLabel: string = "";
    let filterList: Array<TPKeyValue> = [];
    let filterIsApplied: boolean = false;
    let cellStyle: any = {};
    switch (columnToRender) {
      case filterColumnsEnum.FAVORITE:
        columnWidth = favoriteColumnWidth;
        // equivalentOrderColumn = orderColumnsEnum.CASENUMBER;
        columnLabel = favoriteColumn;
        // filterList = adminState.caseNumberFilter;
        break;
      case filterColumnsEnum.CASENUMBER:
        columnWidth = caseNumberColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CASENUMBER;
        columnLabel = caseNumberColumn;
        filterList = adminState.caseNumberFilter;
        break;
      case filterColumnsEnum.CASESTATUS:
        columnWidth = caseStatusColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CASESTATUS;
        columnLabel = caseStatusColumn;
        filterList = adminState.caseStatusFilter;
        break;

      case filterColumnsEnum.RESPONSIBLE:
        columnWidth = responsibleColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.RESPONSIBLE;
        columnLabel = caseResponsibleColumn;
        filterList = adminState.responsibleFilter;
        break;

      case filterColumnsEnum.CUSTOMERNAME:
        columnWidth = customerNameColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CUSTOMERNAME;
        columnLabel = customerNameColumn;
        filterList = adminState.customerNameFilter;
        break;

      case filterColumnsEnum.CUSTOMERTYPE:
        columnWidth = customerTypeColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CUSTOMERTYPE;
        columnLabel = customerTypeColumn;
        filterList = adminState.customerTypeFilter;
        break;
      case filterColumnsEnum.CASECOMMENTS:
        columnWidth = commentsColumnWidth;
        columnLabel = caseCommentsColumn;
        break;
      case filterColumnsEnum.TASKS:
        columnWidth = tasksColumnWidth;
        columnLabel = tasksColumn;
        break;
      case filterColumnsEnum.INSERTDATE:
        columnWidth = insertDateColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.INSERTDATE;
        columnLabel = insertDateColumn;
        filterList = adminState.insertDateFilter;
        break;
      case filterColumnsEnum.CLOSEDATE:
        columnWidth = closeDateColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CLOSEDATE;
        columnLabel = closeDateColumn;
        filterList = adminState.closeDateFilter;
        break;
      case filterColumnsEnum.PROMISEDATE:
        columnWidth = promiseDateColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.PROMISEDATE;
        columnLabel = promiseDateColumn;
        filterList = adminState.promiseDateFilter;
        break;
      case filterColumnsEnum.BASELEVEL:
        columnWidth = baseLevelColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.BASELEVEL;
        columnLabel = baseLevelColumn;
        filterList = adminState.baseLevelFilter;
        break;
      case filterColumnsEnum.CLASSIFY1:
        columnWidth = classify1ColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CLASSIFY1;
        columnLabel = classify1Column;
        filterList = adminState.classificator1Filter;
        cellStyle["whiteSpace"] = "nowrap";
        break;
      case filterColumnsEnum.CLASSIFY2:
        columnWidth = classify2ColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CLASSIFY2;
        columnLabel = classify2Column;
        filterList = adminState.classificator2Filter;
        cellStyle["whiteSpace"] = "nowrap";
        break;
      case filterColumnsEnum.CLASSIFY3:
        columnWidth = classify3ColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CLASSIFY3;
        columnLabel = classify3Column;
        filterList = adminState.classificator3Filter;
        cellStyle["whiteSpace"] = "nowrap";
        break;
      case filterColumnsEnum.CLASSIFY4:
        columnWidth = classify4ColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CLASSIFY4;
        columnLabel = classify4Column;
        filterList = adminState.classificator4Filter;
        cellStyle["whiteSpace"] = "nowrap";
        break;
      case filterColumnsEnum.CLASSIFY5:
        columnWidth = classify5ColumnWidth;
        equivalentOrderColumn = orderColumnsEnum.CLASSIFY5;
        columnLabel = classify5Column;
        filterList = adminState.classificator5Filter;
        cellStyle["whiteSpace"] = "nowrap";
        break;
    }
    if (filterList && filterList.length >= 1 && !filterList[0].value2) {
      filterIsApplied = true;
    }

    if (
      columnToRender === filterColumnsEnum.CASECOMMENTS ||
      columnToRender === filterColumnsEnum.TASKS ||
      columnToRender === filterColumnsEnum.FAVORITE
    ) {
      jsxElement = (
        <th
          style={{
            ...{
              padding: "10px",
              width: columnWidth,
              zIndex: "100",
              top: "-1px",
            },
            ...cellStyle,
          }}
        >
          <div className="d-flex gap-1 align-items-center">
            <span className="tpfont12">{columnLabel}</span>
          </div>
        </th>
      );
    } else {
      jsxElement = (
        <th
          style={{
            ...{
              padding: "10px",
              width: columnWidth,
              zIndex: "100",
              top: "-1px",
            },
            ...cellStyle,
          }}
        >
          <div className="d-flex gap-1 align-items-center">
            {adminState.currentOrderType === equivalentOrderColumn ? (
              adminState.currentOrderDirection === "asc" ? (
                <>
                  <span
                    className="tpfont12"
                    onClick={() =>
                      handleColumnOrderOnClick(equivalentOrderColumn)
                    }
                  >
                    {columnLabel}
                  </span>
                  <span className="tpfont12">{ascTriangle}</span>
                  <TPIcon
                    iconType={TPIconTypes.filter}
                    onClick={() => handleFilterOnClick(columnToRender)}
                    className={
                      filterIsApplied
                        ? "tpfilterApplied tpfont12"
                        : adminState.currentFilter === columnToRender
                          ? "tpfilterOpen tpfont12"
                          : "tpfont12"
                    }
                  />
                </>
              ) : (
                <>
                  <span
                    className="tpfont12"
                    onClick={() =>
                      handleColumnOrderOnClick(equivalentOrderColumn)
                    }
                  >
                    {columnLabel}
                  </span>
                  <span className="tpfont12">{descTriangle}</span>
                  <TPIcon
                    iconType={TPIconTypes.filter}
                    onClick={() => handleFilterOnClick(columnToRender)}
                    className={
                      filterIsApplied
                        ? "tpfilterApplied tpfont12"
                        : adminState.currentFilter === columnToRender
                          ? "tpfilterOpen tpfont12"
                          : "tpfont12"
                    }
                  />
                </>
              )
            ) : (
              <>
                <span
                  onClick={() =>
                    handleColumnOrderOnClick(equivalentOrderColumn)
                  }
                  className="tpOrderHover tpfont12"
                  data-hover="&nbsp;&#9650;"
                >
                  {columnLabel}
                </span>
                <TPIcon
                  iconType={TPIconTypes.filter}
                  onClick={() => handleFilterOnClick(columnToRender)}
                  className={
                    filterIsApplied
                      ? "tpfilterApplied tpfont12"
                      : adminState.currentFilter === columnToRender
                        ? "tpfilterOpen tpfont12"
                        : "tpfont12"
                  }
                />
              </>
            )}

            {adminState.currentFilter === columnToRender && (
              <TPFilterSelector
                id="IdFilterSelector"
                isShown={true}
                title={selectFiltersTitle}
                filterList={filterList}
                onFilterChange={(
                  success: boolean,
                  selectedItems: Array<TPKeyValue>,
                ) =>
                  handleOnFilteSelectorChange(
                    adminState.currentFilter,
                    success,
                    selectedItems,
                  )
                }
              />
            )}
          </div>
        </th>
      );
    }
    return jsxElement;
  };

  const renderStatusImage = (caseStatusId: string) => {
    switch (caseStatusId) {
      case "AL":
        return StatusAlertIMG;
      case "EX":
        return StatusExpiredIMG;
      case "OH":
        return StatusOnHoldIMG;
      case "PE":
        return StatusPendingIMG;
      case "RE":
        return StatusRecivedIMG;
      case "SO":
        return StatusSolvedIMG;
      default:
        //todo LOGS
        return "";
    }
  };

  const renderDateAsTwoRows = (
    dateToRender: string,
    id: string,
    caseId: string,
  ) => {
    let jsxElement: any;
    let dateParts: Array<string>;
    if (!dateToRender) {
      return "";
    }
    dateParts = dateToRender.split(" ");
    jsxElement = (
      <>
        <div className="row">
          <div className="col">
            <span
              className={`tpsearcheableitem ${
                calcHighLight(
                  "case_" + caseId.toString() + "_col_" + id + "_p1",
                )
                  ? "tp-highlight-searcheable"
                  : ""
              } `}
            >
              {dateParts[0]}
            </span>
          </div>
        </div>
        {dateParts.length >= 2 && (
          <div className="row">
            <div className="col">
              <span
                className={`tpsearcheableitem ${
                  calcHighLight(
                    "case_" + caseId.toString() + "_col_" + id + "_p2",
                  )
                    ? "tp-highlight-searcheable"
                    : ""
                } `}
              >
                {dateParts[1]}
              </span>
            </div>
          </div>
        )}
      </>
    );
    return jsxElement;
  };

  const handleShowTasksTable = (itemFollowUp: CasesFollowUpViewModel): void => {
    if (!showTasksTable.includes(itemFollowUp.caseId)) {
      let newShowTasksTableArray = [...showTasksTable];

      newShowTasksTableArray.push(itemFollowUp.caseId);

      setShowTasksTable(newShowTasksTableArray);
    } else {
      let newShowTasksTableArray = showTasksTable.filter((caseId) => {
        return caseId !== itemFollowUp.caseId;
      });

      setShowTasksTable(newShowTasksTableArray);
    }
  };

  const renderTasks = (itemFollowup: CasesFollowUpViewModel) => {
    let jsxElement: any;
    let tableHeight: number;
    if (!itemFollowup.tasks || itemFollowup.tasks.length === 0) {
      return null;
    }
    tableHeight = 45 + itemFollowup.tasks.length * 35;
    if (tableHeight > 200) {
      tableHeight = 200;
    }

    jsxElement = (
      <>
        <div
          className="tpFixedHeadTable nohighlightOnHover"
          style={{ height: tableHeight.toString() + "px", marginLeft: "100px" }}
        >
          <table className="noTransparent">
            <thead>
              <tr>
                {/* <th>{taskidColumnLabel}</th> */}
                <th>{taskStatusColumnLabel}</th>
                <th>{taskResponsibleColumnLabel}</th>
                <th>{taskTypeLocalizedDescriptionColumnLabel}</th>
                <th>{taskCreationDateColumnLabel}</th>
                <th>{taskLimitDateColumnLabel}</th>
              </tr>
            </thead>
            <tbody>
              {itemFollowup.tasks.map(function (item, index) {
                let cellStyle: any = {};
                cellStyle["verticalAlign"] = "top";
                if (index === itemFollowup.tasks.length - 1) {
                  cellStyle["borderBottom"] = "unset";
                }

                return (
                  <tr>
                    {/* <td
                      key={"task1_" + item.taskId + "_" + index.toString()}
                      style={cellStyle}
                    >
                      <span
                        className={`tpsearcheableitem ${
                          calcHighLight(
                            "case_" +
                              itemFollowup.caseId.toString() +
                              "_coltask1_" +
                              item.taskId.toString()
                          )
                            ? "tp-highlight-searcheable"
                            : ""
                        } `}
                      >
                        {item.taskId}
                      </span>
                    </td> */}
                    <td
                      key={"task2_" + item.taskId + "_" + index.toString()}
                      style={cellStyle}
                    >
                      <span
                        className={`tpsearcheableitem ${
                          calcHighLight(
                            "case_" +
                              itemFollowup.caseId.toString() +
                              "_coltask2_" +
                              item.taskId.toString(),
                          )
                            ? "tp-highlight-searcheable"
                            : ""
                        } `}
                      >
                        {item.taskStatusLocalizedDescription}
                      </span>
                    </td>
                    <td
                      key={"task3_" + item.taskId + "_" + index.toString()}
                      style={cellStyle}
                    >
                      <span
                        className={`tpsearcheableitem ${
                          calcHighLight(
                            "case_" +
                              itemFollowup.caseId.toString() +
                              "_coltask3_" +
                              item.taskId.toString(),
                          )
                            ? "tp-highlight-searcheable"
                            : ""
                        } `}
                      >
                        {item.taskResponsible}
                      </span>
                    </td>
                    <td
                      key={"task4_" + item.taskId + "_" + index.toString()}
                      style={cellStyle}
                    >
                      <span
                        className={`tpsearcheableitem ${
                          calcHighLight(
                            "case_" +
                              itemFollowup.caseId.toString() +
                              "_coltask4_" +
                              item.taskId.toString(),
                          )
                            ? "tp-highlight-searcheable"
                            : ""
                        } `}
                      >
                        {item.taskTypeLocalizedDescription}
                      </span>
                    </td>
                    <td
                      key={"task5_" + item.taskId + "_" + index.toString()}
                      style={cellStyle}
                    >
                      <span
                        className={`tpsearcheableitem ${
                          calcHighLight(
                            "case_" +
                              itemFollowup.caseId.toString() +
                              "_coltask5_" +
                              item.taskId.toString(),
                          )
                            ? "tp-highlight-searcheable"
                            : ""
                        } `}
                      >
                        {item.creationDateFormatted}
                      </span>
                    </td>
                    <td
                      key={"task6_" + item.taskId + "_" + index.toString()}
                      style={cellStyle}
                    >
                      <span
                        className={`tpsearcheableitem ${
                          calcHighLight(
                            "case_" +
                              itemFollowup.caseId.toString() +
                              "_coltask6_" +
                              item.taskId.toString(),
                          )
                            ? "tp-highlight-searcheable"
                            : ""
                        } `}
                      >
                        {item.limitDateFormatted}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
    return jsxElement;
  };

  const handleSearchOnChange = (newSearchPattern: string) => {
    let newHighLightedCells: Array<TPKeyValue> = [];
    if (newSearchPattern.trim() === "") {
      let command1: commandType = {
        type: commandsEnum.change_highlight_pattern,
        payload: {
          newCurrentHighlightPattern: "",
          newHighlightCells: [],
        },
      };
      dispatchCommand(command1);
      return;
    }
    newHighLightedCells = findHighlightedCells(
      adminState.gridData,
      newSearchPattern,
    );

    let command1: commandType = {
      type: commandsEnum.change_highlight_pattern,
      payload: {
        newCurrentHighlightPattern: newSearchPattern,
        newHighlightCells: [...newHighLightedCells],
      },
    };
    dispatchCommand(command1);
    return;
  };

  const findHighlightedCells = (
    arrayToSearch: Array<any>,
    newSearchPattern: string,
  ) => {
    let newHighLightedCells: Array<TPKeyValue> = [];
    let i: number;
    let j: number;
    let dateParts: Array<string>;
    for (i = 0; i <= arrayToSearch.length - 1; i++) {
      let row = arrayToSearch[i];
      if (
        row.caseId &&
        row.caseId
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colcase",
          value: row.caseId.toString(),
        });
      }
      if (
        row.caseStatusLocalizedDescription &&
        row.caseStatusLocalizedDescription
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colcasestatus",
          value: row.caseId.toString(),
        });
      }
      if (
        row.caseResponsible &&
        row.caseResponsible
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colcaseresp",
          value: row.caseId.toString(),
        });
      }
      if (
        row.clientName &&
        row.clientName
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colcustomer",
          value: row.caseId.toString(),
        });
      }
      if (
        row.customerTypeLocalizedDescription &&
        row.customerTypeLocalizedDescription
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colcustomertype",
          value: row.caseId.toString(),
        });
      }
      if (row.insertDateFormatted) {
        //insertdate
        dateParts = row.insertDateFormatted.split(" ");
        if (
          dateParts[0].toLowerCase().indexOf(newSearchPattern.toLowerCase()) !==
          -1
        ) {
          newHighLightedCells.push({
            key: "case_" + row.caseId.toString() + "_col_insertdate_p1",
            value: row.caseId.toString(),
          });
        }
        if (dateParts.length >= 2) {
          if (
            dateParts[1]
              .toLowerCase()
              .indexOf(newSearchPattern.toLowerCase()) !== -1
          ) {
            newHighLightedCells.push({
              key: "case_" + row.caseId.toString() + "_col_insertdate_p2",
              value: row.caseId.toString(),
            });
          }
        }
      }
      if (row.closeDateFormatted) {
        //closedate
        dateParts = row.closeDateFormatted.split(" ");
        if (
          dateParts[0].toLowerCase().indexOf(newSearchPattern.toLowerCase()) !==
          -1
        ) {
          newHighLightedCells.push({
            key: "case_" + row.caseId.toString() + "_col_closedate_p1",
            value: row.caseId.toString(),
          });
        }
        if (dateParts.length >= 2) {
          if (
            dateParts[1]
              .toLowerCase()
              .indexOf(newSearchPattern.toLowerCase()) !== -1
          ) {
            newHighLightedCells.push({
              key: "case_" + row.caseId.toString() + "_col_closedate_p2",
              value: row.caseId.toString(),
            });
          }
        }
      }
      if (row.promiseDateFormatted) {
        //promisedate
        dateParts = row.promiseDateFormatted.split(" ");
        if (
          dateParts[0].toLowerCase().indexOf(newSearchPattern.toLowerCase()) !==
          -1
        ) {
          newHighLightedCells.push({
            key: "case_" + row.caseId.toString() + "_col_promisedate_p1",
            value: row.caseId.toString(),
          });
        }
        if (dateParts.length >= 2) {
          if (
            dateParts[1]
              .toLowerCase()
              .indexOf(newSearchPattern.toLowerCase()) !== -1
          ) {
            newHighLightedCells.push({
              key: "case_" + row.caseId.toString() + "_col_promisedate_p2",
              value: row.caseId.toString(),
            });
          }
        }
      }
      if (
        row.comments &&
        row.comments
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colcomments",
          value: row.caseId.toString(),
        });
      }
      if (row.tasks && row.tasks.length >= 1) {
        for (j = 0; j <= row.tasks.length - 1; j++) {
          let task = row.tasks[j];
          if (
            task.taskId &&
            task.taskId
              .toString()
              .toLowerCase()
              .indexOf(newSearchPattern.toLowerCase()) !== -1
          ) {
            newHighLightedCells.push({
              key: "case_" + row.caseId.toString() + "_coltask1_" + task.taskId,
              value: row.caseId.toString(),
            });
          }
          if (
            task.taskStatusLocalizedDescription &&
            task.taskStatusLocalizedDescription
              .toString()
              .toLowerCase()
              .indexOf(newSearchPattern.toLowerCase()) !== -1
          ) {
            newHighLightedCells.push({
              key: "case_" + row.caseId.toString() + "_coltask2_" + task.taskId,
              value: row.caseId.toString(),
            });
          }
          if (
            task.taskResponsible &&
            task.taskResponsible
              .toString()
              .toLowerCase()
              .indexOf(newSearchPattern.toLowerCase()) !== -1
          ) {
            newHighLightedCells.push({
              key: "case_" + row.caseId.toString() + "_coltask3_" + task.taskId,
              value: row.caseId.toString(),
            });
          }
          if (
            task.taskTypeLocalizedDescription &&
            task.taskTypeLocalizedDescription
              .toString()
              .toLowerCase()
              .indexOf(newSearchPattern.toLowerCase()) !== -1
          ) {
            newHighLightedCells.push({
              key: "case_" + row.caseId.toString() + "_coltask4_" + task.taskId,
              value: row.caseId.toString(),
            });
          }
        }
      }

      if (
        row.baseLevelLocalizedDescription &&
        row.baseLevelLocalizedDescription
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colbaselevel",
          value: row.caseId.toString(),
        });
      }
      if (
        row.classifier1LocalizedDescription &&
        row.classifier1LocalizedDescription
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass11",
          value: row.caseId.toString(),
        });
      }
      if (
        row.hierarchyDescriptionClassify1 &&
        row.hierarchyDescriptionClassify1
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass12",
          value: row.caseId.toString(),
        });
      }

      if (
        row.classifier2LocalizedDescription &&
        row.classifier2LocalizedDescription
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass21",
          value: row.caseId.toString(),
        });
      }
      if (
        row.hierarchyDescriptionClassify2 &&
        row.hierarchyDescriptionClassify2
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass22",
          value: row.caseId.toString(),
        });
      }

      if (
        row.classifier3LocalizedDescription &&
        row.classifier3LocalizedDescription
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass31",
          value: row.caseId.toString(),
        });
      }
      if (
        row.hierarchyDescriptionClassify3 &&
        row.hierarchyDescriptionClassify3
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass32",
          value: row.caseId.toString(),
        });
      }

      if (
        row.classifier4LocalizedDescription &&
        row.classifier4LocalizedDescription
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass41",
          value: row.caseId.toString(),
        });
      }
      if (
        row.hierarchyDescriptionClassify4 &&
        row.hierarchyDescriptionClassify4
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass42",
          value: row.caseId.toString(),
        });
      }

      if (
        row.classifier5LocalizedDescription &&
        row.classifier5LocalizedDescription
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass51",
          value: row.caseId.toString(),
        });
      }
      if (
        row.hierarchyDescriptionClassify5 &&
        row.hierarchyDescriptionClassify5
          .toString()
          .toLowerCase()
          .indexOf(newSearchPattern.toLowerCase()) !== -1
      ) {
        newHighLightedCells.push({
          key: "case_" + row.caseId.toString() + "_colclass52",
          value: row.caseId.toString(),
        });
      }
    }
    return newHighLightedCells;
  };

  const handleFilterHighlightRows = () => {
    let command1: commandType = {
      type: commandsEnum.change_show_highlight_rows,
      payload: null,
    };
    dispatchCommand(command1);
  };

  const calcHighLight = (cellId: string) => {
    let found: boolean;
    let index: number;
    found = false;
    index = adminState.highlightCells.findIndex(
      (x: TPKeyValue) => x.key === cellId,
    );
    if (index !== -1) {
      found = true;
    }
    return found;
  };

  const calcShowRow = (caseId: string) => {
    let found: boolean;
    let index: number;
    found = false;
    index = adminState.highlightCells.findIndex(
      (x: TPKeyValue) => x["value"] === caseId.toString(),
    );
    if (index !== -1) {
      found = true;
    }
    return found;
  };

  const calcNoRowsWarningMessage = () => {
    let jsxElement: any;
    if (!adminState.filterHighlightRows || adminState.gridData.length === 0) {
      return null;
    }

    jsxElement = (
      <div className="row">
        <div className="col">
          <span className="alert alert-warning d-flex">
            You have selected to highlight results and show only the rows that
            meet the highlight patternxx. Be careful with the possible results
            that are hidden when using this optionxx
          </span>
        </div>
      </div>
    );

    return jsxElement;
  };

  const calcNoRowsWarningMessage2 = () => {
    let jsxElement: any;
    let found: boolean;
    let i: number;
    found = false;
    if (!adminState.filterHighlightRows || adminState.gridData.length === 0) {
      return null;
    }

    for (i = 0; i <= adminState.gridData.length - 1; i++) {
      if (
        adminState.highlightCells.findIndex(
          (x: TPKeyValue) =>
            x["value"].toString() === adminState.gridData[i].caseId.toString(),
        ) === -1
      ) {
        found = true;
        break;
      }
    }
    if (found) {
      jsxElement = (
        <div className="row">
          <div className="col">
            <span className="alert alert-warning d-flex">
              {thereAreHiddenRowsLabel}
            </span>
          </div>
        </div>
      );
      return jsxElement;
    } else {
      return null;
    }
  };

  const convertToFavorite_DestroyFavorite = async (
    caseNumber: number,
    favoriteOption: FavoriteOptionsEnum,
  ) => {
    let serviceClient = new FavoritesService();
    let expectedCodes: Array<number> = [200];
    let inputDTO: FavoritesInputDTO;
    let responseRequest: any;
    try {
      setIsLoadingScreen(true);
      inputDTO = { caseId: caseNumber, userGuid: TPGlobal.currentUserGuid };
      if (favoriteOption === FavoriteOptionsEnum.Convert_ToFavorite) {
        responseRequest = await serviceClient.convertToFavorite(
          inputDTO,
          false,
          false,
          expectedCodes,
        );
      } else {
        responseRequest = await serviceClient.destroyFavorite(
          inputDTO.caseId,
          inputDTO.userGuid,
          false,
          false,
          expectedCodes,
        );
      }
      setIsLoadingScreen(false);
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} convertToFavorite_DestroyFavorite ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} convertToFavorite_DestroyFavorite ex`,
      );
      setIsLoadingScreen(false);
      return null;
    }
  };

  const handleFavoriteCaseClick = async (
    caseNumber: number,
    favoriteOption: FavoriteOptionsEnum,
  ) => {
    let i: number;
    let response: any = await convertToFavorite_DestroyFavorite(
      caseNumber,
      favoriteOption,
    );
    if (response) {
      //change grid data
      //change left menu counters
      for (i = 0; i <= adminState.gridData.length - 1; i++) {
        if (adminState.gridData[i].caseId === caseNumber) {
          {
            let command1: commandType = {
              type: commandsEnum.convert_destroy_favorite,
              payload: {
                newCaseId: caseNumber,
                newFavoritesCounter:
                  favoriteOption === FavoriteOptionsEnum.Convert_ToFavorite
                    ? adminState.myFavoritesCounter + 1
                    : adminState.myFavoritesCounter - 1,
              },
            };
            dispatchCommand(command1);
            break;
          }
        }
      }
    }
  };

  const handleCallbackAnser = (status: boolean, callbackData: any) => {
    setIsShownModalBodySelect(false);
  };

  const inerHtmlComponent = (inerHtml: string) => {
    setTestInerHtmlComponent(inerHtml);
  };

  // comments modal pop up
  const handleCallCommentsModal = async (caseId: Number, comments: string) => {
    try {
      setIsLoadingScreen(true);
      inerHtmlComponent(
        `<div><h5>${caseNumberModalLabel}: ${caseId}</h5>${comments}</div>`,
      );
      setIsLoadingScreen(false);
      setIsShownModalBodySelect(true);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleCallBackModal ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} handleCallBackModal ex`);
      setIsLoadingScreen(false);
    }
  };

  //reducer
  const initialStateBLL: AdminStateType = {
    currentFolder: folderTypesEnum.MYTASKS,
    gridData: [],
    rowsPerPage: defaultRowsPerPage,
    totalRows: 0,
    currentPage: 1,

    currentOrderType: orderColumnsEnum.CASENUMBER,
    currentOrderDirection: "asc",

    applyFilters: false,
    currentFilter: filterColumnsEnum.NONE,

    caseNumberFilter: [],
    caseStatusFilter: [],
    responsibleFilter: [],
    customerNameFilter: [],
    customerTypeFilter: [],
    baseLevelFilter: [],
    classificator1Filter: [],
    classificator2Filter: [],
    classificator3Filter: [],
    classificator4Filter: [],
    classificator5Filter: [],

    insertDateFilter: [],
    closeDateFilter: [],
    promiseDateFilter: [],

    alreadyClickCaseNumberFilter: false,
    alreadyClickCaseStatusFilter: false,
    alreadyClickResponsibleFilter: false,
    alreadyClickCustomerNameFilter: false,
    alreadyClickCustomerTypeFilter: false,
    alreadyClickBaseLevelFilter: false,
    alreadyClickClassificator1Filter: false,
    alreadyClickClassificator2Filter: false,
    alreadyClickClassificator3Filter: false,
    alreadyClickClassificator4Filter: false,
    alreadyClickClassificator5Filter: false,

    alreadyClickInsertDateFilter: false,
    alreadyClickCloseDateFilter: false,
    alreadyClickPromiseDateFilter: false,

    showFavoriteColumn: true,
    showCasesColumn: true,
    showStatusColumn: true,
    showResponsibleColumn: true,
    showCustomerNameColumn: true,
    showCustomerTypeColumn: true,
    showCommentsColumn: true,
    showTasksColumn: true,
    showBaseLevelColumn: false,
    showClassificator1Column: false,
    showClassificator2Column: false,
    showClassificator3Column: false,
    showClassificator4Column: false,
    showClassificator5Column: false,
    showInsertDateColumn: false,
    showCloseDateColumn: false,
    showPromiseDateColumn: false,

    //counters
    allCounter: 0,
    myTaskCounter: 0,
    myTeamsCounter: 0,
    myFavoritesCounter: 0,
    myTypedCasesCounter: 0,
    myRespobilitityCounter: 0,

    //highlight
    currentHighlightPattern: "",
    highlightCells: [],
    filterHighlightRows: false,
  };
  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
  function doCommand(prevState: AdminStateType, command: commandType) {
    let newAdminState: AdminStateType;
    switch (command.type) {
      case commandsEnum.change_folder:
        newAdminState = { ...prevState };
        newAdminState.currentFolder = command.payload.newCurrentFolder;
        newAdminState.gridData = command.payload.newGridData;
        newAdminState.totalRows = command.payload.newTotalRows;
        newAdminState.currentPage = 1;
        newAdminState.currentOrderType = orderColumnsEnum.CASENUMBER;
        newAdminState.currentOrderDirection = "asc";

        //filters
        newAdminState.applyFilters = false;
        newAdminState.currentFilter = filterColumnsEnum.NONE;
        newAdminState.caseNumberFilter = [];
        newAdminState.caseStatusFilter = [];
        newAdminState.responsibleFilter = [];
        newAdminState.customerNameFilter = [];
        newAdminState.customerTypeFilter = [];
        newAdminState.baseLevelFilter = [];
        newAdminState.classificator1Filter = [];
        newAdminState.classificator2Filter = [];
        newAdminState.classificator3Filter = [];
        newAdminState.classificator4Filter = [];
        newAdminState.classificator5Filter = [];

        newAdminState.insertDateFilter = [];
        newAdminState.closeDateFilter = [];
        newAdminState.promiseDateFilter = [];

        newAdminState.alreadyClickCaseNumberFilter = false;
        newAdminState.alreadyClickCaseStatusFilter = false;
        newAdminState.alreadyClickResponsibleFilter = false;
        newAdminState.alreadyClickCustomerNameFilter = false;
        newAdminState.alreadyClickCustomerTypeFilter = false;
        newAdminState.alreadyClickBaseLevelFilter = false;
        newAdminState.alreadyClickClassificator1Filter = false;
        newAdminState.alreadyClickClassificator2Filter = false;
        newAdminState.alreadyClickClassificator3Filter = false;
        newAdminState.alreadyClickClassificator4Filter = false;
        newAdminState.alreadyClickClassificator5Filter = false;

        newAdminState.alreadyClickInsertDateFilter = false;
        newAdminState.alreadyClickCloseDateFilter = false;
        newAdminState.alreadyClickPromiseDateFilter = false;

        //counters
        newAdminState.allCounter = command.payload.newCounters.newAllCounter;
        newAdminState.myTaskCounter =
          command.payload.newCounters.newMyTaskCounter;
        newAdminState.myTeamsCounter =
          command.payload.newCounters.newMyTeamsCounter;
        newAdminState.myFavoritesCounter =
          command.payload.newCounters.newMyFavoritesCounter;
        newAdminState.myTypedCasesCounter =
          command.payload.newCounters.newMyTypedCasesCounter;
        newAdminState.myRespobilitityCounter =
          command.payload.newCounters.newMyRespobilitityCounter;

        //highlight
        newAdminState.highlightCells = command.payload.newHighLightedCells;

        return newAdminState;
      case commandsEnum.change_activefilter:
        newAdminState = { ...prevState };
        switch (command.payload.newActiveFilter as filterColumnsEnum) {
          case filterColumnsEnum.CASENUMBER:
            newAdminState.alreadyClickCaseNumberFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.caseNumberFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.CASESTATUS:
            newAdminState.alreadyClickCaseStatusFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.caseStatusFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.RESPONSIBLE:
            newAdminState.alreadyClickResponsibleFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.responsibleFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.CUSTOMERNAME:
            newAdminState.alreadyClickCustomerNameFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.customerNameFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.INSERTDATE:
            newAdminState.alreadyClickInsertDateFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.insertDateFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.CLOSEDATE:
            newAdminState.alreadyClickCloseDateFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.closeDateFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.PROMISEDATE:
            newAdminState.alreadyClickPromiseDateFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.promiseDateFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.CUSTOMERTYPE:
            newAdminState.alreadyClickCustomerTypeFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.customerTypeFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.BASELEVEL:
            newAdminState.alreadyClickBaseLevelFilter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.baseLevelFilter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.CLASSIFY1:
            newAdminState.alreadyClickClassificator1Filter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.classificator1Filter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;

          case filterColumnsEnum.CLASSIFY2:
            newAdminState.alreadyClickClassificator2Filter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.classificator2Filter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.CLASSIFY3:
            newAdminState.alreadyClickClassificator3Filter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.classificator3Filter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.CLASSIFY4:
            newAdminState.alreadyClickClassificator4Filter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.classificator4Filter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
          case filterColumnsEnum.CLASSIFY5:
            newAdminState.alreadyClickClassificator5Filter = true;
            if (command.payload.newFilterValues.length > 0) {
              newAdminState.classificator5Filter = [
                ...command.payload.newFilterValues,
              ];
            }
            break;
        }
        newAdminState.currentFilter = command.payload
          .newActiveFilter as filterColumnsEnum;
        newAdminState.applyFilters = false;
        return newAdminState;
      case commandsEnum.close_currentFilter:
        newAdminState = { ...prevState };
        newAdminState.currentFilter = filterColumnsEnum.NONE;
        newAdminState.applyFilters = false;
        return newAdminState;
      case commandsEnum.apply_currentFilter_part1:
        newAdminState = { ...prevState };
        switch (command.payload.changedFilter as filterColumnsEnum) {
          case filterColumnsEnum.CASENUMBER:
            newAdminState.caseNumberFilter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CASESTATUS:
            newAdminState.caseStatusFilter = [
              ...command.payload.newFilterValues,
            ];

            break;
          case filterColumnsEnum.RESPONSIBLE:
            newAdminState.responsibleFilter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CUSTOMERNAME:
            newAdminState.customerNameFilter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.INSERTDATE:
            newAdminState.insertDateFilter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CLOSEDATE:
            newAdminState.closeDateFilter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.PROMISEDATE:
            newAdminState.promiseDateFilter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CUSTOMERTYPE:
            newAdminState.customerTypeFilter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.BASELEVEL:
            newAdminState.baseLevelFilter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CLASSIFY1:
            newAdminState.classificator1Filter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CLASSIFY2:
            newAdminState.classificator2Filter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CLASSIFY3:
            newAdminState.classificator3Filter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CLASSIFY4:
            newAdminState.classificator4Filter = [
              ...command.payload.newFilterValues,
            ];
            break;
          case filterColumnsEnum.CLASSIFY5:
            newAdminState.classificator5Filter = [
              ...command.payload.newFilterValues,
            ];
            break;
        }
        newAdminState.currentFilter = filterColumnsEnum.NONE;
        newAdminState.applyFilters = true;
        return newAdminState;
      case commandsEnum.apply_currentFilter_part2:
        newAdminState = { ...prevState };
        newAdminState.gridData = command.payload.newGridData;
        newAdminState.totalRows = command.payload.newTotalRows;
        newAdminState.currentPage = 1;
        newAdminState.applyFilters = false;

        //highlight
        newAdminState.highlightCells = command.payload.newHighLightedCells;

        return newAdminState;
      case commandsEnum.change_currentPage:
        newAdminState = { ...prevState };
        newAdminState.currentPage = command.payload.newCurrentPage;
        newAdminState.gridData = command.payload.newGridData;
        newAdminState.totalRows = command.payload.newTotalRows;

        //highlight
        newAdminState.highlightCells = command.payload.newHighLightedCells;

        return newAdminState;
      case commandsEnum.change_currentOrder:
        newAdminState = { ...prevState };
        newAdminState.currentOrderType = command.payload.newCurrentOrderType;
        newAdminState.currentOrderDirection =
          command.payload.newCurrentOrderDirection;
        newAdminState.gridData = command.payload.newGridData;
        newAdminState.totalRows = command.payload.newTotalRows;
        newAdminState.currentPage = 1;

        //highlight
        newAdminState.highlightCells = command.payload.newHighLightedCells;

        return newAdminState;
      case commandsEnum.remove_filters_part1:
        newAdminState = { ...prevState };
        newAdminState.alreadyClickCaseNumberFilter = false;
        newAdminState.alreadyClickCaseStatusFilter = false;
        newAdminState.alreadyClickResponsibleFilter = false;
        newAdminState.alreadyClickCustomerNameFilter = false;
        newAdminState.alreadyClickCustomerTypeFilter = false;
        newAdminState.alreadyClickBaseLevelFilter = false;
        newAdminState.alreadyClickClassificator1Filter = false;
        newAdminState.alreadyClickClassificator2Filter = false;
        newAdminState.alreadyClickClassificator3Filter = false;
        newAdminState.alreadyClickClassificator4Filter = false;
        newAdminState.alreadyClickClassificator5Filter = false;

        newAdminState.caseNumberFilter = [];
        newAdminState.caseStatusFilter = [];
        newAdminState.responsibleFilter = [];
        newAdminState.customerNameFilter = [];
        newAdminState.customerTypeFilter = [];
        newAdminState.baseLevelFilter = [];
        newAdminState.classificator1Filter = [];
        newAdminState.classificator2Filter = [];
        newAdminState.classificator3Filter = [];
        newAdminState.classificator4Filter = [];
        newAdminState.classificator5Filter = [];

        newAdminState.currentFilter = filterColumnsEnum.NONE;
        newAdminState.applyFilters = true;
        return newAdminState;
      case commandsEnum.refresh:
        newAdminState = { ...prevState };
        newAdminState.gridData = command.payload.newGridData;
        newAdminState.totalRows = command.payload.newTotalRows;
        newAdminState.currentPage = 1;
        newAdminState.applyFilters = false;

        //counters
        newAdminState.allCounter = command.payload.newCounters.newAllCounter;
        newAdminState.myTaskCounter =
          command.payload.newCounters.newMyTaskCounter;
        newAdminState.myTeamsCounter =
          command.payload.newCounters.newMyTeamsCounter;
        newAdminState.myFavoritesCounter =
          command.payload.newCounters.newMyFavoritesCounter;
        newAdminState.myTypedCasesCounter =
          command.payload.newCounters.newMyTypedCasesCounter;
        newAdminState.myRespobilitityCounter =
          command.payload.newCounters.newMyRespobilitityCounter;

        //highlight
        newAdminState.highlightCells = command.payload.newHighLightedCells;

        return newAdminState;
      case commandsEnum.change_activeColumns:
        newAdminState = { ...prevState };
        newAdminState.showCasesColumn =
          modalSelectColumnsState.localshowCasesColumn;
        newAdminState.showStatusColumn =
          modalSelectColumnsState.localshowStatusColumn;
        newAdminState.showResponsibleColumn =
          modalSelectColumnsState.localshowResponsibleColumn;
        newAdminState.showCustomerNameColumn =
          modalSelectColumnsState.localshowCustomerNameColumn;
        newAdminState.showCustomerTypeColumn =
          modalSelectColumnsState.localshowCustomerTypeColumn;
        newAdminState.showCommentsColumn =
          modalSelectColumnsState.localshowCommentsColumn;
        newAdminState.showTasksColumn =
          modalSelectColumnsState.localshowTasksColumn;
        newAdminState.showBaseLevelColumn =
          modalSelectColumnsState.localshowBaseLevelColumn;
        newAdminState.showClassificator1Column =
          modalSelectColumnsState.localshowClassificator1Column;
        newAdminState.showClassificator2Column =
          modalSelectColumnsState.localshowClassificator2Column;
        newAdminState.showClassificator3Column =
          modalSelectColumnsState.localshowClassificator3Column;
        newAdminState.showClassificator4Column =
          modalSelectColumnsState.localshowClassificator4Column;
        newAdminState.showClassificator5Column =
          modalSelectColumnsState.localshowClassificator5Column;

        newAdminState.showInsertDateColumn =
          modalSelectColumnsState.localshowInsertDateColumn;
        newAdminState.showCloseDateColumn =
          modalSelectColumnsState.localshowCloseDateColumn;
        newAdminState.showPromiseDateColumn =
          modalSelectColumnsState.localshowPromiseDateColumn;

        if (
          newAdminState.rowsPerPage !== modalSelectColumnsState.localRowsPerPage
        ) {
          newAdminState.rowsPerPage = modalSelectColumnsState.localRowsPerPage;
          newAdminState.applyFilters = true;
        }
        return newAdminState;
      case commandsEnum.change_highlight_pattern:
        newAdminState = { ...prevState };
        newAdminState.currentHighlightPattern =
          command.payload.newCurrentHighlightPattern;
        newAdminState.highlightCells = command.payload.newHighlightCells;

        return newAdminState;
      case commandsEnum.change_show_highlight_rows:
        newAdminState = { ...prevState };
        newAdminState.filterHighlightRows = !newAdminState.filterHighlightRows;
        return newAdminState;
      case commandsEnum.clear_highlight_options:
        newAdminState = { ...prevState };
        newAdminState.currentHighlightPattern = "";
        newAdminState.highlightCells = [];
        newAdminState.filterHighlightRows = false;
        return newAdminState;
      case commandsEnum.convert_destroy_favorite:
        let i: number;
        newAdminState = { ...prevState };
        newAdminState.myFavoritesCounter = command.payload.newFavoritesCounter;
        for (i = 0; i <= newAdminState.gridData.length - 1; i++) {
          if (newAdminState.gridData[i].caseId === command.payload.newCaseId) {
            newAdminState.gridData[i].interestCase =
              !newAdminState.gridData[i].interestCase;
            break;
          }
        }
        return newAdminState;
      default:
        return prevState;
        break;
    }
  }

  //run only once
  useEffect(() => {
    loadResourcesAndInitialData();
  }, []);

  //run when apply filters is set to true
  useEffect(() => {
    if (adminState.applyFilters) {
      let newHighLightedCells: Array<TPKeyValue> = [];
      reloadGridData(
        adminState.currentFolder,
        1,
        adminState.rowsPerPage,
        adminState.currentOrderType,
        adminState.currentOrderDirection,
      )
        .then((response: any) => {
          if (response !== null) {
            if (adminState.currentHighlightPattern.trim() !== "") {
              newHighLightedCells = findHighlightedCells(
                response,
                adminState.currentHighlightPattern,
              );
            }
            let command1: commandType = {
              type: commandsEnum.apply_currentFilter_part2,
              payload: {
                newGridData: response,
                newTotalRows:
                  response && response.length >= 1
                    ? response[0].totalRecords
                    : 0,
                newHighLightedCells: newHighLightedCells,
              },
            };
            dispatchCommand(command1);
          } else {
            //todo logs
          }
        })
        .catch((error) => {
          //todo logs
        });
    }
  }, [adminState.applyFilters]);

  //run when want to show/hide modal
  useEffect(() => {
    let newModalState: modalSelectColumnsType = { ...modalSelectColumnsState };
    if (modalSelectColumnsState.isShown) {
      newModalState.classModal = "modal show";
      newModalState.styleModal = { display: "block" };
      newModalState.backdropClass = "modal-backdrop show";
    } else {
      newModalState.classModal = "modal";
      newModalState.styleModal = { display: "none" };
      newModalState.backdropClass = "";
    }
    setModalSelectColumnsState(newModalState);
  }, [modalSelectColumnsState.isShown]);

  //run when want to show/hide modal
  useEffect(() => {
    let newModalState: modalHighLightStateType = { ...modalHighLightState };
    if (modalHighLightState.isShown) {
      newModalState.classModal = "modal show";
      newModalState.styleModal = { display: "block" };
      newModalState.backdropClass = "modal-backdrop show";
    } else {
      newModalState.classModal = "modal";
      newModalState.styleModal = { display: "none" };
      newModalState.backdropClass = "";
    }
    setModalHighLightState(newModalState);
  }, [modalHighLightState.isShown]);

  return (
    <ContentVerticalNoTabsStyled>
      <div className="container-fluid">
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col-2">
              <div className="tpColumnsTaskContainer">
                <div className="tpFollowUpFolders">
                  <TPPageTitle>{titleLabel}</TPPageTitle>
                  {/* folders zone */}
                  <ul>
                    <li
                      className={
                        adminState.currentFolder === folderTypesEnum.MYTASKS
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handleFolderOnClick(folderTypesEnum.MYTASKS)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <span className={"tpaligncenter"}>
                        <TPIcon iconType={TPIconTypes.task} />
                        <span className={"tptasksfoldertitle"}>
                          {myTaskFolder}
                        </span>
                      </span>
                      <span
                        className={
                          "d-flex align-items-center justify-content-center badge tp-badge" +
                          (adminState.currentFolder === folderTypesEnum.MYTASKS
                            ? " tp-bg-primary"
                            : " tp-bg-secondary")
                        }
                      >
                        {adminState.myTaskCounter}
                      </span>
                    </li>
                    <li
                      className={
                        adminState.currentFolder === folderTypesEnum.MYTEAM
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handleFolderOnClick(folderTypesEnum.MYTEAM)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <span className={"tpaligncenter"}>
                        <TPIcon iconType={TPIconTypes.teams} />
                        <span className={"tptasksfoldertitle"}>
                          {myTeamsFolder}
                        </span>
                      </span>
                      <span
                        className={
                          "d-flex align-items-center justify-content-center badge tp-badge" +
                          (adminState.currentFolder === folderTypesEnum.MYTEAM
                            ? " tp-bg-primary"
                            : " tp-bg-secondary")
                        }
                      >
                        {adminState.myTeamsCounter}
                      </span>
                    </li>
                    <li
                      className={
                        adminState.currentFolder === folderTypesEnum.FAVORITES
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handleFolderOnClick(folderTypesEnum.FAVORITES)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <span className={"tpaligncenter"}>
                        <TPIcon iconType={TPIconTypes.favorite} />
                        <span className={"tptasksfoldertitle"}>
                          {favoritesFolder}
                        </span>
                      </span>
                      <span
                        className={
                          "d-flex align-items-center justify-content-center badge tp-badge" +
                          (adminState.currentFolder ===
                          folderTypesEnum.FAVORITES
                            ? " tp-bg-primary"
                            : " tp-bg-secondary")
                        }
                      >
                        {adminState.myFavoritesCounter}
                      </span>
                    </li>
                    <li
                      className={
                        adminState.currentFolder === folderTypesEnum.TYPED
                          ? "active"
                          : ""
                      }
                      onClick={() => handleFolderOnClick(folderTypesEnum.TYPED)}
                      style={{ cursor: "pointer" }}
                    >
                      <span className={"tpaligncenter"}>
                        <TPIcon iconType={TPIconTypes.keyboard} />
                        <span className={"tptasksfoldertitle"}>
                          {myTypedCasesFolder}
                        </span>
                      </span>
                      <span
                        className={
                          "d-flex align-items-center justify-content-center badge tp-badge" +
                          (adminState.currentFolder === folderTypesEnum.TYPED
                            ? " tp-bg-primary"
                            : " tp-bg-secondary")
                        }
                      >
                        {adminState.myTypedCasesCounter}
                      </span>
                    </li>
                    <li
                      className={
                        adminState.currentFolder === folderTypesEnum.RESPONSIBLE
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handleFolderOnClick(folderTypesEnum.RESPONSIBLE)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <span className={"tpaligncenter"}>
                        <TPIcon iconType={TPIconTypes.personSearch} />
                        <span className={"tptasksfoldertitle"}>
                          {responsibleFolder}
                        </span>
                      </span>
                      <span
                        className={
                          "d-flex align-items-center justify-content-center badge tp-badge" +
                          (adminState.currentFolder ===
                          folderTypesEnum.RESPONSIBLE
                            ? " tp-bg-primary"
                            : " tp-bg-secondary")
                        }
                      >
                        {adminState.myRespobilitityCounter}
                      </span>
                    </li>
                    <li
                      className={
                        adminState.currentFolder === folderTypesEnum.ALL
                          ? "active"
                          : ""
                      }
                      onClick={() => handleFolderOnClick(folderTypesEnum.ALL)}
                      style={{ cursor: "pointer" }}
                    >
                      <span className={"tpaligncenter"}>
                        <TPIcon iconType={TPIconTypes.all} />
                        <span className={"tptasksfoldertitle"}>
                          {allFolder}
                        </span>
                      </span>
                      <span
                        className={
                          "d-flex align-items-center justify-content-center badge tp-badge" +
                          (adminState.currentFolder === folderTypesEnum.ALL
                            ? " tp-bg-primary"
                            : " tp-bg-secondary")
                        }
                      >
                        {adminState.allCounter}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-10">
              <div className="tpColumnsTaskContainer">
                {/* my task grid zone */}
                {adminState.gridData && (
                  <div>
                    {/* highligth warning zone */}

                    {adminState.filterHighlightRows &&
                      calcNoRowsWarningMessage()}

                    <div className="row">
                      <div className="col-6 d-flex gap-3">
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          onClick={() => handleRefreshClick()}
                          text={refreshLabel}
                          icon={TPIconTypes.refresh}
                        />
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          onClick={() => handleRemoveFilterClick()}
                          text={removeFilterLabel}
                          icon={TPIconTypes.filter}
                        />
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          onClick={() => handleHighLightResultsClick()}
                          text={highLightResultsLabel}
                          icon={TPIconTypes.filter}
                        />
                      </div>
                      <div className="col-6 d-flex gap-3 justify-content-end">
                        <div>
                          <TPButton
                            id="IdButton"
                            type={TPButtonTypes.icon}
                            onClick={() => handleShowSelectColumns()}
                            text={otherSettingsLabel}
                            icon={TPIconTypes.settings}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div
                          className={`tpFixedHeadTable  highlightOnHover  mt-2 ${
                            adminState.currentFilter !==
                              filterColumnsEnum.NONE ||
                            modalSelectColumnsState.isShown
                              ? "tpWithoutSticky"
                              : ""
                          }  `}
                          style={{ height: divGridMaxHeight }}
                        >
                          <table id="searcheableContentMyTasks">
                            <thead>
                              <tr>
                                {adminState.showFavoriteColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.FAVORITE,
                                  )}
                                {adminState.showCasesColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CASENUMBER,
                                  )}
                                {adminState.showStatusColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CASESTATUS,
                                  )}
                                {adminState.showResponsibleColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.RESPONSIBLE,
                                  )}
                                {adminState.showCustomerNameColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CUSTOMERNAME,
                                  )}
                                {adminState.showCustomerTypeColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CUSTOMERTYPE,
                                  )}
                                {adminState.showInsertDateColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.INSERTDATE,
                                  )}
                                {adminState.showCloseDateColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CLOSEDATE,
                                  )}
                                {adminState.showPromiseDateColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.PROMISEDATE,
                                  )}
                                {adminState.showCommentsColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CASECOMMENTS,
                                  )}
                                {adminState.showTasksColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.TASKS,
                                  )}
                                {adminState.showBaseLevelColumn &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.BASELEVEL,
                                  )}
                                {adminState.showClassificator1Column &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CLASSIFY1,
                                  )}
                                {adminState.showClassificator2Column &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CLASSIFY2,
                                  )}
                                {adminState.showClassificator3Column &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CLASSIFY3,
                                  )}
                                {adminState.showClassificator4Column &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CLASSIFY4,
                                  )}
                                {adminState.showClassificator5Column &&
                                  renderGridColumnHeader(
                                    filterColumnsEnum.CLASSIFY5,
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {adminState.gridData.map(
                                function (item, index, array) {
                                  if (
                                    adminState.filterHighlightRows &&
                                    !calcShowRow(item.caseId)
                                  ) {
                                    return null;
                                  }

                                  let cellStyle: any = {};
                                  cellStyle["verticalAlign"] = "top";
                                  if (index === array.length - 1) {
                                    cellStyle["borderBottom"] = "unset";
                                  }
                                  return (
                                    <>
                                      <tr key={"tasktr" + index.toString()}>
                                        {adminState.showFavoriteColumn && (
                                          <td>
                                            {item.interestCase ? (
                                              <>
                                                <TPIcon
                                                  iconType={
                                                    TPIconTypes.favorite
                                                  }
                                                  onClick={() =>
                                                    handleFavoriteCaseClick(
                                                      item.caseId,
                                                      FavoriteOptionsEnum.Destroy_ToFavorite,
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    fontSize: "20px",
                                                    paddingLeft: "10px",
                                                    color: "Gold",
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <TPIcon
                                                  iconType={
                                                    TPIconTypes.noFavorite
                                                  }
                                                  onClick={() =>
                                                    handleFavoriteCaseClick(
                                                      item.caseId,
                                                      FavoriteOptionsEnum.Convert_ToFavorite,
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    fontSize: "20px",
                                                    paddingLeft: "10px",
                                                  }}
                                                />
                                              </>
                                            )}
                                          </td>
                                        )}
                                        {adminState.showCasesColumn && (
                                          <td
                                            key={
                                              "casenumber" + index.toString()
                                            }
                                            style={{
                                              ...{
                                                width: caseNumberColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <span
                                              className={`tpsearcheableitem ${
                                                calcHighLight(
                                                  "case_" +
                                                    item.caseId.toString() +
                                                    "_colcase",
                                                )
                                                  ? "tp-highlight-searcheable"
                                                  : ""
                                              } `}
                                              onClick={() =>
                                                casecontext.handleAddNewCaseViewerCallBack(
                                                  item.caseId,
                                                )
                                              }
                                              style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {item.caseId}
                                            </span>
                                          </td>
                                        )}
                                        {adminState.showStatusColumn && (
                                          <td
                                            key={
                                              "casestatus" + index.toString()
                                            }
                                            style={{
                                              ...{
                                                width: caseStatusColumnWidth,
                                                whiteSpace: "nowrap",
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <img
                                              src={renderStatusImage(
                                                item.caseStatusId,
                                              )}
                                              width="16px"
                                            />
                                            &nbsp;
                                            <span
                                              className={`tpsearcheableitem ${
                                                calcHighLight(
                                                  "case_" +
                                                    item.caseId.toString() +
                                                    "_colcasestatus",
                                                )
                                                  ? "tp-highlight-searcheable"
                                                  : ""
                                              } `}
                                            >
                                              {
                                                item.caseStatusLocalizedDescription
                                              }
                                            </span>
                                          </td>
                                        )}
                                        {adminState.showResponsibleColumn && (
                                          <td
                                            key={"caseresp" + index.toString()}
                                            style={{
                                              ...{
                                                width: responsibleColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <span
                                              className={`tpsearcheableitem ${
                                                calcHighLight(
                                                  "case_" +
                                                    item.caseId.toString() +
                                                    "_colcaseresp",
                                                )
                                                  ? "tp-highlight-searcheable"
                                                  : ""
                                              } `}
                                            >
                                              {item.caseResponsible}
                                            </span>
                                          </td>
                                        )}
                                        {adminState.showCustomerNameColumn && (
                                          <td
                                            key={
                                              "customername" + index.toString()
                                            }
                                            style={{
                                              ...{
                                                width: customerNameColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <span
                                              className={`tpsearcheableitem ${
                                                calcHighLight(
                                                  "case_" +
                                                    item.caseId.toString() +
                                                    "_colcustomer",
                                                )
                                                  ? "tp-highlight-searcheable"
                                                  : ""
                                              } `}
                                            >
                                              {item.clientName}
                                            </span>
                                          </td>
                                        )}
                                        {adminState.showCustomerTypeColumn && (
                                          <td
                                            key={
                                              "customertype" + index.toString()
                                            }
                                            style={{
                                              ...{
                                                width: customerTypeColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <span
                                              className={`tpsearcheableitem ${
                                                calcHighLight(
                                                  "case_" +
                                                    item.caseId.toString() +
                                                    "_colcustomertype",
                                                )
                                                  ? "tp-highlight-searcheable"
                                                  : ""
                                              } `}
                                            >
                                              {
                                                item.customerTypeLocalizedDescription
                                              }
                                            </span>
                                          </td>
                                        )}
                                        {adminState.showInsertDateColumn && (
                                          <td
                                            key={
                                              "insertdate" + index.toString()
                                            }
                                            style={{
                                              ...{
                                                width: insertDateColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            {renderDateAsTwoRows(
                                              item.insertDateFormatted,
                                              "insertdate",
                                              item.caseId,
                                            )}
                                          </td>
                                        )}
                                        {adminState.showCloseDateColumn && (
                                          <td
                                            key={"closedate" + index.toString()}
                                            style={{
                                              ...{
                                                width: closeDateColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            {renderDateAsTwoRows(
                                              item.closeDateFormatted,
                                              "closedate",
                                              item.caseId,
                                            )}
                                          </td>
                                        )}
                                        {adminState.showPromiseDateColumn && (
                                          <td
                                            key={
                                              "promisedate" + index.toString()
                                            }
                                            style={{
                                              ...{
                                                width: promiseDateColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            {renderDateAsTwoRows(
                                              item.promiseDateFormatted,
                                              "promisedate",
                                              item.caseId,
                                            )}
                                          </td>
                                        )}
                                        {adminState.showCommentsColumn && (
                                          <td
                                            key={"comments" + index.toString()}
                                            style={{
                                              ...{
                                                width: commentsColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <span
                                              className={`tpsearcheableitem ${
                                                calcHighLight(
                                                  "case_" +
                                                    item.caseId.toString() +
                                                    "_colcomments",
                                                )
                                                  ? "tp-highlight-searcheable"
                                                  : ""
                                              } `}
                                              style={{ display: "ruby" }}
                                            >
                                              {TPGlobal.RemoveHtmlOnlyContent(
                                                item.comments,
                                              ).length <
                                              TPGlobal.characterNumberCutStringComments ? (
                                                TPGlobal.RemoveHtmlOnlyContent(
                                                  item.comments,
                                                )
                                              ) : (
                                                <React.Fragment>
                                                  {TPGlobal.RemoveHtmlOnlyContent(
                                                    item.comments,
                                                  ).substring(
                                                    0,
                                                    TPGlobal.characterNumberCutStringComments,
                                                  )}
                                                  ...
                                                  <TPButton
                                                    type={TPButtonTypes.icon}
                                                    onClick={() => {
                                                      handleCallCommentsModal(
                                                        item.caseId,
                                                        item.comments,
                                                      );
                                                    }}
                                                    className="update-button"
                                                    icon={TPIconTypes.addCircle}
                                                  ></TPButton>
                                                </React.Fragment>
                                              )}
                                            </span>
                                          </td>
                                        )}
                                        {adminState.showTasksColumn && (
                                          <td
                                            key={"tasks" + index.toString()}
                                            style={{
                                              ...{
                                                width: tasksColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <button
                                              className="accordion-button"
                                              type="button"
                                              onClick={() =>
                                                handleShowTasksTable(item)
                                              }
                                              style={{
                                                padding: "7px",
                                              }}
                                            >
                                              <span className="d-flex align-items-center justify-content-center badge tp-bg-primary">
                                                {item.tasks.length}
                                              </span>
                                            </button>
                                          </td>
                                        )}
                                        {adminState.showBaseLevelColumn && (
                                          <td
                                            key={"baselevel" + index.toString()}
                                            style={{
                                              ...{
                                                width: baseLevelColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <span
                                              className={`tpsearcheableitem ${
                                                calcHighLight(
                                                  "case_" +
                                                    item.caseId.toString() +
                                                    "_colbaselevel",
                                                )
                                                  ? "tp-highlight-searcheable"
                                                  : ""
                                              } `}
                                            >
                                              {
                                                item.baseLevelLocalizedDescription
                                              }
                                            </span>
                                          </td>
                                        )}
                                        {adminState.showClassificator1Column && (
                                          <td
                                            key={"class1" + index.toString()}
                                            style={{
                                              ...{
                                                width: classify1ColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <div className="tpbold">
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass11",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.classifier1LocalizedDescription
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass12",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.hierarchyDescriptionClassify1
                                                }
                                              </span>
                                            </div>
                                          </td>
                                        )}
                                        {adminState.showClassificator2Column && (
                                          <td
                                            key={"class2" + index.toString()}
                                            style={{
                                              ...{
                                                width: classify2ColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <div className="tpbold">
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass21",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.classifier2LocalizedDescription
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass22",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.hierarchyDescriptionClassify2
                                                }
                                              </span>
                                            </div>
                                          </td>
                                        )}
                                        {adminState.showClassificator3Column && (
                                          <td
                                            key={"class3" + index.toString()}
                                            style={{
                                              ...{
                                                width: classify3ColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <div className="tpbold">
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass31",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.classifier3LocalizedDescription
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass32",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.hierarchyDescriptionClassify3
                                                }
                                              </span>
                                            </div>
                                          </td>
                                        )}
                                        {adminState.showClassificator4Column && (
                                          <td
                                            key={"class4" + index.toString()}
                                            style={{
                                              ...{
                                                width: classify4ColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <div className="tpbold">
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass41",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.classifier4LocalizedDescription
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass42",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.hierarchyDescriptionClassify4
                                                }
                                              </span>
                                            </div>
                                          </td>
                                        )}
                                        {adminState.showClassificator5Column && (
                                          <td
                                            key={"class5" + index.toString()}
                                            style={{
                                              ...{
                                                width: classify5ColumnWidth,
                                              },
                                              ...cellStyle,
                                            }}
                                          >
                                            <div className="tpbold">
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass51",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.classifier5LocalizedDescription
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span
                                                className={`tpsearcheableitem ${
                                                  calcHighLight(
                                                    "case_" +
                                                      item.caseId.toString() +
                                                      "_colclass52",
                                                  )
                                                    ? "tp-highlight-searcheable"
                                                    : ""
                                                } `}
                                              >
                                                {
                                                  item.hierarchyDescriptionClassify5
                                                }
                                              </span>
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                      {adminState.showTasksColumn &&
                                        showTasksTable.includes(
                                          item.caseId,
                                        ) && (
                                          <tr>
                                            <td
                                              colSpan={8}
                                              key={"tasks" + index.toString()}
                                              style={{
                                                ...{
                                                  width: tasksColumnWidth,
                                                },
                                                ...cellStyle,
                                              }}
                                            >
                                              {renderTasks(item)}
                                            </td>
                                          </tr>
                                        )}
                                    </>
                                  );
                                },
                              )}
                            </tbody>
                          </table>
                          {calcNoRowsWarningMessage2()}
                        </div>
                      </div>
                    </div>

                    {/* modal pop-up code */}
                    <TPModal
                      modalState={{
                        titleModal: commentModalLabel,
                        acceptLabel: okButtonLabel,
                        cancelLabel: cancelButtonLabel,
                        callBackAnswer: handleCallbackAnser,
                        callBackData: TPGlobal.foo,
                        isShown: isShownModalBodySelect,
                        modalWidth: undefined,
                        hideFooterButtons: true,
                      }}
                    >
                      <div
                        style={{ height: "400px", overflow: "auto" }}
                        dangerouslySetInnerHTML={{
                          __html: `${testInerHtmlComponent}`,
                        }}
                      />
                    </TPModal>

                    <div
                      style={{ fontSize: "13px" }}
                      className="col d-flex justify-content-end align-items-center pt-2"
                    >
                      <div>
                        <span style={{ color: "gray" }}>
                          {(adminState.currentPage - 1) *
                            adminState.rowsPerPage +
                            1}
                          &nbsp;-&nbsp;
                          {(adminState.currentPage - 1) *
                            adminState.rowsPerPage +
                            adminState.rowsPerPage <
                          adminState.totalRows
                            ? (adminState.currentPage - 1) *
                                adminState.rowsPerPage +
                              adminState.rowsPerPage
                            : adminState.totalRows}
                          &nbsp;&nbsp;{totalRowsLabel}&nbsp;
                          {adminState.totalRows}
                        </span>
                      </div>
                      <div>
                        <span
                          className="page-button"
                          style={
                            adminState.currentPage === 1
                              ? { color: "gray" }
                              : { color: "#a00095" }
                          }
                        >
                          <TPIcon
                            iconType={TPIconTypes.firstPage}
                            style={{ fontSize: "25px" }}
                          ></TPIcon>
                        </span>
                      </div>
                      <div>
                        <span
                          className="page-button"
                          style={
                            adminState.currentPage === 1
                              ? { color: "gray" }
                              : { color: "#a00095" }
                          }
                        >
                          <TPIcon
                            onClick={() => {
                              if (adminState.currentPage === 1) {
                                return;
                              }
                              handleChangePage("PREVIOUS");
                            }}
                            iconType={TPIconTypes.chevronLeft}
                            style={{ fontSize: "25px" }}
                          />
                        </span>
                      </div>
                      <div>
                        <span
                          className="page-button"
                          style={
                            adminState.currentPage < calcMaxPages()
                              ? { color: "#a00095" }
                              : { color: "gray" }
                          }
                        >
                          <TPIcon
                            onClick={() => {
                              if (adminState.currentPage === calcMaxPages()) {
                                return;
                              }
                              handleChangePage("NEXT");
                            }}
                            iconType={TPIconTypes.chevronRight}
                            style={{ fontSize: "25px" }}
                          />
                        </span>
                      </div>
                      <div>
                        <span
                          className="page-button"
                          style={
                            adminState.currentPage < calcMaxPages()
                              ? { color: "#a00095" }
                              : { color: "gray" }
                          }
                        >
                          <TPIcon
                            iconType={TPIconTypes.lastPage}
                            style={{ fontSize: "25px" }}
                          ></TPIcon>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </TPLoadingOverlay>
      </div>

      {/* Modal select columns */}
      <>
        <div
          className={modalSelectColumnsState.classModal}
          tabIndex={-1}
          data-bs-backdrop="static"
          style={modalSelectColumnsState.styleModal}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header justify-content-between">
                <h5 className="modal-title">{otherSettingsLabel}</h5>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.empty}
                  onClick={() => closeModalSelectColumns()}
                  className={"text-end"}
                >
                  X
                </TPButton>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {selectColumnsToShowTitle}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={caseNumberColumn}
                          checked={modalSelectColumnsState.localshowCasesColumn}
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowCasesColumn =
                              !newModalSelectColumnsState.localshowCasesColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={caseStatusColumn}
                          checked={
                            modalSelectColumnsState.localshowStatusColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowStatusColumn =
                              !newModalSelectColumnsState.localshowStatusColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={caseResponsibleColumn}
                          checked={
                            modalSelectColumnsState.localshowResponsibleColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowResponsibleColumn =
                              !newModalSelectColumnsState.localshowResponsibleColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={customerNameColumn}
                          checked={
                            modalSelectColumnsState.localshowCustomerNameColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowCustomerNameColumn =
                              !newModalSelectColumnsState.localshowCustomerNameColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={customerTypeColumn}
                          checked={
                            modalSelectColumnsState.localshowCustomerTypeColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowCustomerTypeColumn =
                              !newModalSelectColumnsState.localshowCustomerTypeColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={insertDateColumn}
                          checked={
                            modalSelectColumnsState.localshowInsertDateColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowInsertDateColumn =
                              !newModalSelectColumnsState.localshowInsertDateColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={closeDateColumn}
                          checked={
                            modalSelectColumnsState.localshowCloseDateColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowCloseDateColumn =
                              !newModalSelectColumnsState.localshowCloseDateColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={promiseDateColumn}
                          checked={
                            modalSelectColumnsState.localshowPromiseDateColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowPromiseDateColumn =
                              !newModalSelectColumnsState.localshowPromiseDateColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={caseCommentsColumn}
                          checked={
                            modalSelectColumnsState.localshowCommentsColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowCommentsColumn =
                              !newModalSelectColumnsState.localshowCommentsColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={tasksColumn}
                          checked={modalSelectColumnsState.localshowTasksColumn}
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowTasksColumn =
                              !newModalSelectColumnsState.localshowTasksColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={baseLevelColumn}
                          checked={
                            modalSelectColumnsState.localshowBaseLevelColumn
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowBaseLevelColumn =
                              !newModalSelectColumnsState.localshowBaseLevelColumn;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={classify1Column}
                          checked={
                            modalSelectColumnsState.localshowClassificator1Column
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowClassificator1Column =
                              !newModalSelectColumnsState.localshowClassificator1Column;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={classify2Column}
                          checked={
                            modalSelectColumnsState.localshowClassificator2Column
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowClassificator2Column =
                              !newModalSelectColumnsState.localshowClassificator2Column;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={classify3Column}
                          checked={
                            modalSelectColumnsState.localshowClassificator3Column
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowClassificator3Column =
                              !newModalSelectColumnsState.localshowClassificator3Column;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={classify4Column}
                          checked={
                            modalSelectColumnsState.localshowClassificator4Column
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowClassificator4Column =
                              !newModalSelectColumnsState.localshowClassificator4Column;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          labelText={classify5Column}
                          checked={
                            modalSelectColumnsState.localshowClassificator5Column
                          }
                          onChange={() => {
                            let newModalSelectColumnsState: modalSelectColumnsType =
                              { ...modalSelectColumnsState };
                            newModalSelectColumnsState.localshowClassificator5Column =
                              !newModalSelectColumnsState.localshowClassificator5Column;
                            setModalSelectColumnsState(
                              newModalSelectColumnsState,
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6"></div>
                  </div>
                </TPPageSection>

                <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {otherParametersLabel}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <TPPageSection>
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        let newvalue: string;
                        newvalue = e.target.value;
                        let newModalSelectColumnsState: modalSelectColumnsType =
                          { ...modalSelectColumnsState };
                        newModalSelectColumnsState.localRowsPerPage = parseInt(
                          newvalue,
                          10,
                        );
                        setModalSelectColumnsState(newModalSelectColumnsState);
                      }}
                      dataSource={rowsPerPageList}
                      value={modalSelectColumnsState.localRowsPerPage}
                      labelText={rowsPerPageLabel}
                    />
                  </div>
                </TPPageSection>
              </div>
              <div className="modal-footer">
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={() => {
                    closeModalSelectColumns();
                    handleApplySelectColumns();
                  }}
                >
                  {okButtonLabel}
                </TPButton>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.link}
                  onClick={() => closeModalSelectColumns()}
                  className={"ms-2"}
                >
                  {cancelButtonLabel}
                </TPButton>
              </div>
            </div>
          </div>
        </div>

        <div className={modalSelectColumnsState.backdropClass}></div>
      </>

      {/* modal highlight */}
      <>
        <div
          className={modalHighLightState.classModal}
          tabIndex={-1}
          data-bs-backdrop="static"
          style={modalHighLightState.styleModal}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header justify-content-between">
                <h5 className="modal-title">{highLightResultsLabel}</h5>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.empty}
                  onClick={() => {
                    closeModalHighLight();
                  }}
                  className={"text-end"}
                >
                  X
                </TPButton>
              </div>
              <div className="modal-body">
                <section>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          onChange={(e: any) => {
                            let newSearch: string = e.target.value;
                            handleSearchOnChange(newSearch);
                          }}
                          value={adminState.currentHighlightPattern}
                          labelText={textToHighlight}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      display: "none",
                    }}
                  >
                    <div className="col">
                      <div className="form-group">
                        <TPCheckBox
                          id="IdCheckBox"
                          checked={adminState.filterHighlightRows}
                          labelText={showOnlyRows}
                          onChange={() => {
                            handleFilterHighlightRows();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {calcNoRowsWarningMessage()}
                </section>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col">
                    <TPButton
                      id="IdButton"
                      type={TPButtonTypes.primary}
                      onClick={() => {
                        closeModalHighLight();
                      }}
                    >
                      {okButtonLabel}
                    </TPButton>
                    <TPButton
                      id="IdButton"
                      type={TPButtonTypes.link}
                      onClick={() => {
                        closeModalHighLight();
                        let command1: commandType = {
                          type: commandsEnum.clear_highlight_options,
                          payload: null,
                        };
                        dispatchCommand(command1);
                      }}
                      className={"ms-2"}
                    >
                      {clearFilterButtonLabel}
                    </TPButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={modalHighLightState.backdropClass}></div>
      </>
    </ContentVerticalNoTabsStyled>
  );
};
export default MyTasks;
