import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPGlobal from "@/helpers/TPGlobal";
import {
  ContentVerticalTabStyled,
  MenuVerticalTabStyled,
} from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { VerticalTabItemModel } from "@/models/VerticalTabs/VerticalTabsModel";
import { TPI18N } from "@/services/I18nService";
import { ReactElement, useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

//components
import CustomerTypeAdmin from "@/pages/CustomerType/CustomerTypeAdmin";
import CustomerTypeInsertUpdate from "@/pages/CustomerType/CustomerTypeInsertUpdate";
import FunctionAssignmentsAdmin from "@/pages/Functions/FunctionAssignmentsAdmin";
import FunctionsAdmin from "@/pages/Functions/FunctionsAdmin";
import FunctionsInsertUpdate from "@/pages/Functions/FunctionsInsertUpdate";
import OrganizationsAdmin from "@/pages/Organizations/OrganizationsAdmin";
import OrganizationsInsertUpdate from "@/pages/Organizations/OrganizationsInsertUpdate";
import OrganizationsRelationsAdmin from "@/pages/OrganizationsRelations/OrganizationsRelationsAdmin";
import OrganizationsRelationsInsertUpdate from "@/pages/OrganizationsRelations/OrganizationsRelationsInsertUpdate";
import RelationsAdmin from "@/pages/Relations/RelationsAdmin";
import RelationsInsertUpdate from "@/pages/Relations/RelationsInsertUpdate";

import SchedulesAdmin from "@/pages/Schedules/SchedulesAdmin";
import SchedulesInsertUpdate from "@/pages/Schedules/SchedulesInsertUpdate";

import { TPLog, TPLogType } from "@/helpers/TPLog";
import AdditionalDataAdmin from "@/pages/AdditionalData/AdditionalDataAdmin";
import AdditionalDataInsertUpdate from "@/pages/AdditionalData/AdditionalDataInsertUpdate";
import CalendarAdmin from "@/pages/Calendar/CalendarAdmin";
import CalendarContainerInsertUpdate from "@/pages/Calendar/CalendarContainerInsertUpdate";
import CaseCustomStatesAdmin from "@/pages/CaseCustomStates/CaseCustomStatesAdmin";
import CaseCustomStatesInsertUpdate from "@/pages/CaseCustomStates/CaseCustomStatesInsertUpdate";
import FunctionsAssignmentsInsertUpdate from "@/pages/Functions/FunctionAssignmentInsertUpdate";
import GroupsAdmin from "@/pages/Groups/GroupsAdmin";
import GroupsInsertUpdateContainer from "@/pages/Groups/GroupsInsertUpdateContainer";
import OutboundMailAccountAdmin from "@/pages/OutboundMailAccount/OutboundMailAccountAdmin";
import OutboundMailAccountContainerInsertUpdate from "@/pages/OutboundMailAccount/OutboundMailAccountContainerInsertUpdate";
import QuickClassifiersAdmin from "@/pages/QuickClassifiers/QuickClassifiersAdmin";
import QuickClassifiersInsertUpdate from "@/pages/QuickClassifiers/QuickClassifiersInsertUpdate";
import RolesAdmin from "@/pages/Roles/RoleAdmin";
import RolesContainerInsertUpdate from "@/pages/Roles/RolesContainerInsertUpdate";
import TaskTypeAdmin from "@/pages/TaskType/TaskTypeAdmin";
import TaskTypeContainerInsertUpdate from "@/pages/TaskType/TaskTypeContainerInsertUpdate";
import TreeContainerInsertUpdate from "@/pages/Trees/TreeContainerInsertUpdate";
import TreesAdmin from "@/pages/Trees/TreesAdmin";
import UserAdmin from "@/pages/Users/UserAdmin";
import UserInsertUpdate from "@/pages/Users/UserInsertUpdate";
import WorkflowAssignmentAdmin from "@/pages/Workflow/WorkflowAssignmentAdmin";
import WorkflowAssignmentInsertUpdate from "@/pages/Workflow/WorkflowAssignmentInsertUpdate";
import WorkflowTypeAdmin from "@/pages/Workflow/WorkflowTypeAdmin";
import WorkflowTypeContainerInsertUpdate from "@/pages/Workflow/WorkflowTypeContainerInsertUpdate";

import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import AdditionalBulkLoad from "@/pages/AdditionalData/AdditionalBulkLoad";
import EmailTemplateContainerInsertUpdate from "@/pages/EmailTemplates/EmailTemplateContainerInsertUpdate";
import EmailTemplatesAdmin from "@/pages/EmailTemplates/EmailTemplatesAdmin";
import { AppProviderFormDesigner } from "@/pages/FormDesigner/ContextFormDesignerAdmin";
import FormDesigner from "@/pages/FormDesigner/FormDesignerAdmin";
import FormDesignerAndBuilder from "@/pages/FormDesigner/FormDesignerAndBuilder";
import FormDesignerContainerClone from "@/pages/FormDesigner/FormDesignerContainerClone";
import FormDesignerExportImportForm from "@/pages/FormDesigner/FormDesignerExportImportForm/FormDesignerExportImportForm";
import InboundMailboxesRulesAdmin from "@/pages/InboundMailboxRules/InboundMailboxesRulesAdmin";
import InboundMailboxesRulesContainerInsertUpdate from "@/pages/InboundMailboxRules/InboundMailboxesRulesContainerInsertUpdate";
import InboundMailboxesRulesInsertUpdate from "@/pages/InboundMailboxRules/InboundMailboxesRulesInsertUpdate";
import InboundMailAzureApplicationAdmin from "@/pages/InboundMailboxes/InboundMailAzureApplicationAdmin";
import InboundMailAzureApplicationAdminInsertUpdate from "@/pages/InboundMailboxes/InboundMailAzureApplicationAdminInsertUpdate";
import InboundMailboxInProcessAdmin from "@/pages/InboundMailboxes/InboundMailboxInProcessAdmin";
import InboundMailboxInProcessUpdateContainer from "@/pages/InboundMailboxes/InboundMailboxInProcessUpdateContainer";
import InboundMailboxesAdmin from "@/pages/InboundMailboxes/InboundMailboxesAdmin";
import InboundMailboxesInsertUpdateContainer from "@/pages/InboundMailboxes/InboundMailboxesInsertUpdateContainer";
import LoadHistoryAdmin from "@/pages/LoadHistory/LoadHistoryAdmin";
import MenuDefinition from "@/pages/MenuDefinition/MenuDefinitionAdmin";
import MenuDefinitionContainerInsertUpdate from "@/pages/MenuDefinition/MenuDefinitionContainerInsertUpdate";
import ProfilesAdmin from "@/pages/Profiles/ProfilesAdmin";
import ProfilesClone from "@/pages/Profiles/ProfilesClone";
import ProfilesInsertUpdate from "@/pages/Profiles/ProfilesInsertUpdate";
import QuickClassifierShortcutsAdmin from "@/pages/QuickClassifierShortcuts/QuickClassifierShortcutsAdmin";
import UserBulkLoad from "@/pages/Users/UserBulkLoad";
import WorkflowTypeContainerClone from "@/pages/Workflow/WorkflowTypeContainerClone";

import EmailTemplateCloneForm from "@/pages/EmailTemplates/EmailTemplateCloneForm/EmailTemplateCloneForm";
import SolvedRecords from "@/pages/LoadHistory/SolvedRecords";
import { StoreModel, TypeOf } from "@/redux/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  VerticalTabsAdminContainerSlice,
  VerticalTabsAdminContainerStateModel,
} from "./VerticalTabsAdminContainer/_redux/VerticalTabsAdminContainerSlice";
import ProjectDetail from "@/pages/Projects/ProjectDetail/ProjectDetail";
import ProjectClone from "@/pages/Projects/ProjectClone/ProjectClone";
import ProjectNew from "@/pages/Projects/ProjectNew/ProjectNew";
import ProjectsAdmin from "@/pages/Projects/ProjectsAdmin/ProjectsAdmin";

export enum commandsEnum {
  "create_home" = 0,
  "new_vertical_tab" = 1,
  "update_vertical_tab" = 2,
  "vertical_tab_click" = 3,
  "vertical_tab_close" = 4,
  "insertupdate_cancel_or_ok" = 5,
  "vertical_tab_close_by_recordid" = 6,
  "change_to_update_mode" = 7,
  "reloadGrid" = 8,
  "new_bulk_load_vertical_tab" = 9,
  "new_additional_bulk_load_vertical_tab" = 10,
  "clone_vertical_tab" = 11,
  "clone_end_vertical_tab" = 12,
  "clone_email_vertical_tab" = 13,
  "change_to_update_reload_data" = 14,
  "export" = 15,
  "import" = 16,
  'detail' = 17,
}
export type commandType = {
  type: commandsEnum;
  payload?: any;
};

interface VerticalTabsAdminContainerInterface {
  componentType: string;
}

const VerticalTabsAdminContainer = ({
  componentType,
}: VerticalTabsAdminContainerInterface): ReactElement => {
  const dispatch = useDispatch();
  //#region Init
  let componentFileName = "[" + componentType + "].tsx";
  const casecontext: any = React.useContext(TPCaseViewerContext);

  const ChildRef = useRef();

  const { tabs, result } = useSelector(
    (s: StoreModel) => s[VerticalTabsAdminContainerSlice.name]
  ) as VerticalTabsAdminContainerStateModel;

  //control state
  const [isReady, setIsReady] = useState(false);
  const [isBranchCloned, setIsBranchCloned] = useState(false);
  const [branchIdSelected, setBranchIdSelected] = useState("");
  //resources
  const [homeLabel, setHomeLabel] = useState("");
  const [newLabel, setNewLabel] = useState("");
  const [updateLabel, setUpdateLabel] = useState("");
  const [detailLabel, setDetailLabel] = useState('');
  const [cloneEmailLabel, setCloneEmailLabel] = useState("");
  const [cloneLabel, setCloneLabel] = useState("");
  const [exportLabel, setExportLabel] = useState("");
  const [importLabel, setImportLabel] = useState("");
  const [exportAndImportLabel, setExportAndImportLabel] = useState("");
  //#endregion

	const loadResources = async () => {
		setHomeLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'HomeLabel'));
		setNewLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'NewLabel'));
		setUpdateLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'UpdateLabel'));
		setDetailLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'DetailLabel'));
		setCloneEmailLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'CloneEmailLabel'));
		setCloneLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'CloneLabel'));
		setExportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ExportLabel'));
		setImportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ImportLabel'));
		setExportAndImportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ExportAndImport'));

    //flag isReady
    setIsReady(true);
  };

  //handler to receive commands from child tabs
  const handleCallBackCommands = (theCommand: any) => {
    //new
    if (theCommand.command === "new") {
      let command1: commandType = {
        type: commandsEnum.new_vertical_tab,
        payload: null,
      };
      dispatchCommand(command1);
      return;
    }

    //new bulk load vertical tab
    if (theCommand.command === "new_bulk_load") {
      let command1_1: commandType = {
        type: commandsEnum.new_bulk_load_vertical_tab,
        payload: null,
      };
      dispatchCommand(command1_1);
      return;
    }

    //new additional bulk load vertical tab
    if (theCommand.command == "new_additional_bulk_load") {
      let command1_2: commandType = {
        type: commandsEnum.new_additional_bulk_load_vertical_tab,
        payload: theCommand.payload,
      };
      dispatchCommand(command1_2);
      return;
    }

    //update
    if (theCommand.command === "update") {
      let realPayload: any;
      setIsBranchCloned(theCommand.type && theCommand.type === "cloned");
      setBranchIdSelected(theCommand.branch);
      if (
        theCommand.recordDescription === "" ||
        theCommand.recordDescription !== null
      ) {
        realPayload = {
          recordId: theCommand.recordId,
          languageId: theCommand.languageId,
          recordDescription: theCommand.recordDescription,
        };
      } else {
        realPayload = {
          recordId: theCommand.recordId,
          languageId: theCommand.recordId,
        };
      }
      let command2: commandType = {
        type: commandsEnum.update_vertical_tab,
        payload: realPayload,
      };
      dispatchCommand(command2);
      return;
    }

    //update
    if (theCommand.command === "cloneEmail") {
      dispatchCommand({
        type: commandsEnum.clone_email_vertical_tab,
        payload: {
          recordId: theCommand.recordId,
          languageId: theCommand.languageId,
          recordDescription: theCommand?.recordDescription,
          description: theCommand?.description,
        },
      });
      return;
    }

    //clone
    if (theCommand.command === "clone") {
      dispatchCommand({
        type: commandsEnum.clone_vertical_tab,
        payload: {
          recordId: theCommand.recordId,
          languageId: theCommand?.languageId,
          recordDescription: theCommand?.recordDescription,
          form: theCommand?.form,
        },
      } as commandType);
      return;
    }

    // import
    if (theCommand.command === "import") {
      dispatchCommand({
        type: commandsEnum.import,
        payload: null,
      } as commandType);
      return;
    }

    if (theCommand.command === "delete") {
      let command: commandType = {
        type: commandsEnum.vertical_tab_close_by_recordid,
        payload: theCommand.recordId,
      };
      dispatchCommand(command);
    }
  };

  //handle to receive commands form insert update component
  const handleCallBackInsertUpdate = (theResult: any) => {
    switch (theResult.result) {
      case "ChangeToUpdateMode":
        let command1: commandType = {
          type: commandsEnum.change_to_update_mode,
          payload: theResult,
        };
        dispatchCommand(command1);
        break;
      case "ReloadGrid":
        let command3: commandType = {
          type: commandsEnum.reloadGrid,
          payload: theResult,
        };
        dispatchCommand(command3);
        break;
      case "ChangeCloneToUpdateMode":
        let command: commandType = {
          type: commandsEnum.vertical_tab_close,
          payload: theResult.tabId,
        };
        dispatchCommand(command);

        let command0: commandType = {
          type: commandsEnum.reloadGrid,
          payload: theResult,
        };
        dispatchCommand(command0);

        handleCallBackCommands({
          command: "update",
          recordId: theResult.recordId,
          languageId: theResult.language,
          recordDescription: "",
        });
        break;
      case "clone_email_success":
        console.log(
          "🚀 ~ handleCallBackInsertUpdate ~ command4: commandType.theResult:",
          theResult
        );
        let command4: commandType = {
          type: commandsEnum.update_vertical_tab,
          payload: theResult,
        };
        dispatchCommand(command4);
        break;
      default:
        let command2: commandType = {
          type: commandsEnum.insertupdate_cancel_or_ok,
          payload: {
            recordId: theResult.recordId,
            languageId: theResult.languageId,
            recordDescription: undefined,
          },
        };
        dispatchCommand(command2);
        break;
    }
  };

  //Vertical tab Click
  const handleVerticalTabClick = (tabId: string) => {
    let command: commandType = {
      type: commandsEnum.vertical_tab_click,
      payload: tabId,
    };
    dispatchCommand(command);
  };

  //vertical tab close
  const handleVerticaltabClose = (tabId: string, e?: any) => {
    e?.stopPropagation();
    let command: commandType = {
      type: commandsEnum.vertical_tab_close,
      payload: tabId,
    };
    if (
      casecontext.currentCaseResolveCallback &&
      typeof casecontext.currentCaseResolveCallback == "function"
    ) {
      casecontext.currentCaseResolveCallback();
    }
    dispatchCommand(command);
  };

  //create home tab
  const realHomeCommand = (prevState: Array<VerticalTabItemModel>) => {
    let newStateHome: Array<VerticalTabItemModel> = [...prevState];
    let myuuid = uuidv4();

    let tabContent: any = null;
    switch (componentType) {
      case "TPClientCloudMain.Client.Components.Functions.FunctionsAdminComponent":
        tabContent = (
          <FunctionsAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Group.GroupContainerComponent":
        tabContent = (
          <GroupsAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.OrganizationAdminComponent":
        tabContent = (
          <OrganizationsAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Relation.RelationContainerComponent":
        tabContent = (
          <RelationsAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.BaseLevel.BaseLevelContainerComponent":
        tabContent = (
          <OrganizationsRelationsAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.TaskType.TaskTypeContainerComponent":
        tabContent = (
          <TaskTypeAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Schedule.ScheduleContainerComponent":
        tabContent = (
          <SchedulesAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowTypeContainerComponent":
        tabContent = (
          <WorkflowTypeAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Tree.TreeContainerComponent":
        tabContent = (
          <TreesAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Calendar.CalendarContainerComponent":
        tabContent = (
          <CalendarAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.User.UserContainerComponent":
        tabContent = (
          <UserAdmin callBackCommands={handleCallBackCommands} ref={ChildRef} />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.CustomerType.CustomerTypeContainerComponent":
        tabContent = (
          <CustomerTypeAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Functions.FunctionAssignmentsAdminComponent":
        tabContent = (
          <FunctionAssignmentsAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.QuickClassifiers.QuickClassifiersContainerComponent":
        tabContent = (
          <QuickClassifiersAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowAssignmentContainerComponent":
        tabContent = (
          <WorkflowAssignmentAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.CaseCustomState":
        tabContent = (
          <CaseCustomStatesAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Roles.RolesContainerComponent":
        tabContent = (
          <RolesAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.AdditionalDataContainerComponent":
        tabContent = (
          <AdditionalDataAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.OutboundMailAccount.OutboundMailAccountAdminComponent":
        tabContent = (
          <OutboundMailAccountAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.FormDesigner.FormDesignerContainerComponent":
        tabContent = (
          <FormDesigner
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Profiles.ProfilesContainerComponent":
        tabContent = (
          <ProfilesAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.EmailTemplatesAdminComponent":
        tabContent = (
          <EmailTemplatesAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Menu.MenuItemAdminComponent":
        tabContent = (
          <MenuDefinition
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.QuickClassifierShortcutsAdminComponent":
        tabContent = (
          <QuickClassifierShortcutsAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAdminComponent":
        tabContent = (
          <InboundMailboxesAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesRulesAdminComponent":
        tabContent = (
          <InboundMailboxesRulesAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesInProcessAdminComponent":
        tabContent = (
          <InboundMailboxInProcessAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAzureApplicationAdminComponent":
        tabContent = (
          <InboundMailAzureApplicationAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.EventsManager.LoadHistory":
        tabContent = (
          <LoadHistoryAdmin
            callBackCommands={handleCallBackCommands}
            ref={ChildRef}
          />
        );
        break;
        case 'TPClientCloudMain.Client.Components.EventsManager.Projects':
            tabContent = <ProjectsAdmin verticalTabDispatch={dispatchAction} ref={ChildRef} />;
            break;
      default:
        //todo logs
        break;
    }

    let verticalElement: VerticalTabItemModel = {
      isActive: true,
      title: homeLabel,
      subTitle: "",
      allowClose: false,
      icon: TPIconTypes.home,
      id: myuuid,
      jsxFragment: tabContent,
      recordData: { isHome: true },
    };
    newStateHome.push(verticalElement);
    return newStateHome;
  };

  //create new bulk load tab
  const realNewBulkLoadCommand = (prevState: Array<VerticalTabItemModel>) => {
    let i: number;
    let alreadyExists: boolean;
    let myuuid = uuidv4();
    let tabContent: any = null;
    // const recordId: string = "newBulLoad";
    const recordId: string = "";
    //check if and new tab is already present
    //if not exist create it
    //othewise switch to existing one
    alreadyExists = false;
    for (i = 0; i <= prevState.length - 1 && !alreadyExists; i++) {
      alreadyExists = prevState[i].recordData.recordId === recordId;
    }
    let newverticalTabsState = [...prevState];
    if (alreadyExists) {
      for (i = 0; i <= newverticalTabsState.length - 1; i++) {
        newverticalTabsState[i].isActive =
          newverticalTabsState[i].recordData.recordId == recordId;
      }
      return newverticalTabsState;
    }
    //not exists
    //set all active =false
    for (i = 0; i <= newverticalTabsState.length - 1; i++) {
      newverticalTabsState[i].isActive = false;
    }
    switch (componentType) {
      case "TPClientCloudMain.Client.Components.User.UserContainerComponent":
        tabContent = (
          <UserBulkLoad
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      default:
        //todo logs
        break;
    }
    //TODO: change icon and other properties
    let verticalElement: VerticalTabItemModel = {
      isActive: true,
      title: newLabel,
      subTitle: "",
      allowClose: true,
      icon: TPIconTypes.public,
      id: myuuid,
      jsxFragment: tabContent,
      recordData: {
        recordId: recordId,
        operation: "Insert",
        isHome: false,
      },
    };
    newverticalTabsState.push(verticalElement);
    return newverticalTabsState;
  };

  //create new bulk load tab
  const realNewAdditionalBulkLoadCommand = (
    prevState: Array<VerticalTabItemModel>,
    data: any[]
  ) => {
    let i: number;
    let alreadyExists: boolean;
    let myuuid = uuidv4();
    let tabContent: any = null;
    const recordId: string = "newAdditionalBulLoad";

    alreadyExists = false;
    for (i = 0; i <= prevState.length - 1 && !alreadyExists; i++) {
      if (prevState[i].recordData.recordId === recordId) {
        alreadyExists = true;
      }
    }
    let newverticalTabsState = [...prevState];
    if (alreadyExists) {
      for (i = 0; i <= newverticalTabsState.length - 1; i++) {
        newverticalTabsState[i].isActive =
          newverticalTabsState[i].recordData.recordId == recordId;
      }
      return newverticalTabsState;
    }
    for (i = 0; i <= newverticalTabsState.length - 1; i++) {
      newverticalTabsState[i].isActive = false;
    }
    switch (componentType) {
      case "TPClientCloudMain.Client.Components.Organization.AdditionalDataContainerComponent":
        tabContent = <AdditionalBulkLoad ids={data} />;
        break;
      default:
        break;
    }
    //TODO: change icon and other properties
    let verticalElement: VerticalTabItemModel = {
      isActive: true,
      title: exportAndImportLabel,
      subTitle: "",
      allowClose: true,
      icon: TPIconTypes.public,
      id: myuuid,
      jsxFragment: tabContent,
      recordData: {
        recordId: recordId,
        operation: "Insert",
        isHome: false,
      },
    };
    newverticalTabsState.push(verticalElement);
    return newverticalTabsState;
  };

  //create new tab
  const realNewCommand = (prevState: Array<VerticalTabItemModel>) => {
    let i: number;
    let alreadyExists: boolean;
    let myuuid = uuidv4();
    let tabContent: any = null;
    //check if and new tab is already present
    //if not exist create it
    //othewise switch to existing one
    alreadyExists = false;
    for (i = 0; i <= prevState.length - 1; i++) {
      if (prevState[i].recordData.recordId === "--") {
        alreadyExists = true;
        break;
      }
    }
    let newverticalTabsState = [...prevState];
    if (alreadyExists) {
      for (i = 0; i <= newverticalTabsState.length - 1; i++) {
        if (newverticalTabsState[i].recordData.recordId === "--") {
          newverticalTabsState[i].isActive = true;
        } else {
          newverticalTabsState[i].isActive = false;
        }
      }
      return newverticalTabsState;
    }
    //not exists
    //set all active =false
    for (i = 0; i <= newverticalTabsState.length - 1; i++) {
      newverticalTabsState[i].isActive = false;
    }
    switch (componentType) {
      case "TPClientCloudMain.Client.Components.Functions.FunctionsAdminComponent":
        tabContent = (
          <FunctionsInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.OrganizationAdminComponent":
        tabContent = (
          <OrganizationsInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Relation.RelationContainerComponent":
        tabContent = (
          <RelationsInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.BaseLevel.BaseLevelContainerComponent":
        tabContent = (
          <OrganizationsRelationsInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.TaskType.TaskTypeContainerComponent":
        tabContent = (
          <TaskTypeContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Schedule.ScheduleContainerComponent":
        tabContent = (
          <SchedulesInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowTypeContainerComponent":
        tabContent = (
          <WorkflowTypeContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Tree.TreeContainerComponent":
        tabContent = (
          <TreeContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            callBackUpdate={handleCallBackCommands}
            cloned={isBranchCloned}
            branchId={branchIdSelected}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.User.UserContainerComponent":
        tabContent = (
          <UserInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.User.UserContainerComponent":
        tabContent = (
          <UserBulkLoad
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Calendar.CalendarContainerComponent":
        tabContent = (
          <CalendarContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.CustomerType.CustomerTypeContainerComponent":
        tabContent = (
          <CustomerTypeInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Functions.FunctionAssignmentsAdminComponent":
        tabContent = (
          <FunctionsAssignmentsInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.QuickClassifiers.QuickClassifiersContainerComponent":
        tabContent = (
          <QuickClassifiersInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowAssignmentContainerComponent":
        tabContent = (
          <WorkflowAssignmentInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.CaseCustomState":
        tabContent = (
          <CaseCustomStatesInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Roles.RolesContainerComponent":
        tabContent = (
          <RolesContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.AdditionalDataContainerComponent":
        tabContent = (
          <AdditionalDataInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.OutboundMailAccount.OutboundMailAccountAdminComponent":
        tabContent = (
          <OutboundMailAccountContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Group.GroupContainerComponent":
        tabContent = (
          <GroupsInsertUpdateContainer
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Profiles.ProfilesContainerComponent":
        tabContent = (
          <ProfilesInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.EmailTemplatesAdminComponent":
        tabContent = (
          <EmailTemplateContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            languageId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Menu.MenuItemAdminComponent":
        tabContent = (
          <MenuDefinitionContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAdminComponent":
        tabContent = (
          <InboundMailboxesInsertUpdateContainer
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesInProcessAdminComponent":
        tabContent = (
          <InboundMailboxInProcessUpdateContainer
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesRulesAdminComponent":
        tabContent = (
          <InboundMailboxesRulesContainerInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAzureApplicationAdminComponent":
        tabContent = (
          <InboundMailAzureApplicationAdminInsertUpdate
            mode={"Insert"}
            recordId={""}
            callBackResult={handleCallBackInsertUpdate}
            // tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.FormDesigner.FormDesignerContainerComponent":
        tabContent = (
          <AppProviderFormDesigner>
            <FormDesignerAndBuilder
              mode={"Insert"}
              callBackCommands={handleCallBackCommands}
              ref={ChildRef}
            />
          </AppProviderFormDesigner>
        );
        break;
      case 'TPClientCloudMain.Client.Components.EventsManager.Projects':
          tabContent = <ProjectNew tabId={myuuid} dispatch={dispatchAction} />;
          break;
      default:
        //todo logs
        break;
    }
    let verticalElement: VerticalTabItemModel = {
      isActive: true,
      title: newLabel,
      subTitle: "",
      allowClose: true,
      icon: TPIconTypes.newEntity,
      id: myuuid,
      jsxFragment: tabContent,
      recordData: {
        recordId: "--",
        operation: "Insert",
        isHome: false,
      },
    };

    newverticalTabsState.push(verticalElement);
    return newverticalTabsState;
  };

  //create update tab
  const realUpdateCommand = (
    prevState: Array<VerticalTabItemModel>,
    id: string,
    languageId: string,
    recordDescription?: string
  ) => {
    let i: number;
    let alreadyExists: boolean;
    let myuuid = uuidv4();
    let tabContent: any = null;
    //check if and new tab is already present
    //if not exist create it
    //othewise switch to existing one
    alreadyExists = false;
    for (i = 0; i <= prevState.length - 1; i++) {
      if (
        prevState[i].recordData.recordId === id &&
        prevState[i].recordData.operation === "Update"
      ) {
        alreadyExists = true;
        break;
      }
    }
    let newverticalTabsState = [...prevState];
    if (alreadyExists) {
      for (i = 0; i <= newverticalTabsState.length - 1; i++) {
        if (
          newverticalTabsState[i].recordData.recordId === id &&
          prevState[i].recordData.operation === "Update"
        ) {
          newverticalTabsState[i].isActive = true;
        } else {
          newverticalTabsState[i].isActive = false;
        }
      }
      return newverticalTabsState;
    }
    //not exists
    //set all active =false
    for (i = 0; i <= newverticalTabsState.length - 1; i++) {
      newverticalTabsState[i].isActive = false;
    }

    switch (componentType) {
      case "TPClientCloudMain.Client.Components.Functions.FunctionsAdminComponent":
        tabContent = (
          <FunctionsInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.User.UserContainerComponent":
        tabContent = (
          <UserInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.OrganizationAdminComponent":
        tabContent = (
          <OrganizationsInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Relation.RelationContainerComponent":
        tabContent = (
          <RelationsInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.BaseLevel.BaseLevelContainerComponent":
        tabContent = (
          <OrganizationsRelationsInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.TaskType.TaskTypeContainerComponent":
        tabContent = (
          <TaskTypeContainerInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Schedule.ScheduleContainerComponent":
        tabContent = (
          <SchedulesInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowTypeContainerComponent":
        tabContent = (
          <WorkflowTypeContainerInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Tree.TreeContainerComponent":
        tabContent = (
          <TreeContainerInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            callBackUpdate={handleCallBackCommands}
            cloned={isBranchCloned}
            branchId={branchIdSelected}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Calendar.CalendarContainerComponent":
        tabContent = (
          <CalendarContainerInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.CustomerType.CustomerTypeContainerComponent":
        tabContent = (
          <CustomerTypeInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Functions.FunctionAssignmentsAdminComponent":
        tabContent = (
          <FunctionsAssignmentsInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.QuickClassifiers.QuickClassifiersContainerComponent":
        tabContent = (
          <QuickClassifiersInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowAssignmentContainerComponent":
        tabContent = (
          <WorkflowAssignmentInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.CaseCustomState":
        tabContent = (
          <CaseCustomStatesInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Roles.RolesContainerComponent":
        tabContent = (
          <RolesContainerInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.AdditionalDataContainerComponent":
        tabContent = (
          <AdditionalDataInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.OutboundMailAccount.OutboundMailAccountAdminComponent":
        tabContent = (
          <OutboundMailAccountContainerInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Group.GroupContainerComponent":
        tabContent = (
          <GroupsInsertUpdateContainer
            mode={"Update"}
            recordId={id}
            recordDescription={recordDescription}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Profiles.ProfilesContainerComponent":
        tabContent = (
          <ProfilesInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.EmailTemplatesAdminComponent":
        tabContent = (
          <EmailTemplateContainerInsertUpdate
            mode={"Update"}
            languageId={languageId}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Menu.MenuItemAdminComponent":
        tabContent = (
          <MenuDefinitionContainerInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAdminComponent":
        tabContent = (
          <InboundMailboxesInsertUpdateContainer
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesInProcessAdminComponent":
        tabContent = (
          <InboundMailboxInProcessUpdateContainer
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesRulesAdminComponent":
        tabContent = (
          <InboundMailboxesRulesInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      // case "TPClientCloudMain.Client.Components.Configuration.InboundMailAzureApplicationAdminComponent":
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAzureApplicationAdminComponent":
        tabContent = (
          <InboundMailAzureApplicationAdminInsertUpdate
            mode={"Update"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.FormDesigner.FormDesignerContainerComponent":
        tabContent = (
          <AppProviderFormDesigner>
            <FormDesignerAndBuilder
              mode={"Update"}
              callBackCommands={handleCallBackCommands}
              recordId={id}
              ref={ChildRef}
            />
          </AppProviderFormDesigner>
        );
        break;
      case "TPClientCloudMain.Client.Components.EventsManager.LoadHistory":
        tabContent = (
          <SolvedRecords
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
        case 'TPClientCloudMain.Client.Components.EventsManager.Projects':
            tabContent = <ProjectNew tabId={myuuid} id={id} dispatch={dispatchAction} />;
            break;

      default:
        //todo logs
        break;
    }

    let realSubtitle: string;
    if (recordDescription !== null && recordDescription !== undefined) {
      realSubtitle = recordDescription
        ? recordDescription.length < 10
          ? recordDescription
          : recordDescription.substring(0, 10) + "..."
        : "";
    } else {
      realSubtitle = id.length < 10 ? id : id.substring(0, 10) + "...";
    }

    let verticalElement: VerticalTabItemModel = {
      isActive: true,
      title: updateLabel,
      subTitle: realSubtitle,
      allowClose: true,
      icon: TPIconTypes.edit,
      id: myuuid,
      jsxFragment: tabContent,
      recordData: { recordId: id, operation: "Update", isHome: false },
    };

		newverticalTabsState.push(verticalElement);

		// Create a map to keep track of the latest items by recordId
		const latestItemsMap = new Map();

		// Populate the map with the latest items
		for (const item of newverticalTabsState) {
			const recordId = item?.recordData?.recordId;
			if (recordId) {
				// Update the map with the latest item for each recordId
				latestItemsMap.set(recordId, item);
			}
		}
		// Create the final result array based on the latest items map
		const uniqueData = [];
		for (const item of newverticalTabsState) {
			const recordId = item?.recordData?.recordId;
			if (recordId) {
				// Only add the item if it's the latest occurrence
				if (latestItemsMap.get(recordId) === item) {
					uniqueData.push(item);
				}
			} else {
				// Add non-duplicated items directly
				uniqueData.push(item);
			}
		}

		newverticalTabsState = uniqueData;
		return newverticalTabsState;
	};

  //Clone Email tab
  const realCloneEmailCommand = (
    prevState: Array<VerticalTabItemModel>,
    id: string,
    languageId: string,
    recordDescription?: string,
    description?: string
  ) => {
    let i: number;
    let alreadyExists: boolean;
    let myuuid = uuidv4();
    let tabContent: any = null;
    //check if and new tab is already present
    //if not exist create it
    //othewise switch to existing one
    alreadyExists = false;
    for (i = 0; i <= prevState.length - 1; i++) {
      if (
        prevState[i].recordData.recordId === id &&
        prevState[i].recordData.operation === "CloneEmail"
      ) {
        alreadyExists = true;
        break;
      }
    }
    let newverticalTabsState = [...prevState];
    if (alreadyExists) {
      for (i = 0; i <= newverticalTabsState.length - 1; i++) {
        if (
          newverticalTabsState[i].recordData.recordId === id &&
          prevState[i].recordData.operation === "CloneEmail"
        ) {
          newverticalTabsState[i].isActive = true;
        } else {
          newverticalTabsState[i].isActive = false;
        }
      }
      return newverticalTabsState;
    }
    //not exists
    //set all active =false
    for (i = 0; i <= newverticalTabsState.length - 1; i++) {
      newverticalTabsState[i].isActive = false;
    }

    switch (componentType) {
      case "TPClientCloudMain.Client.Components.Functions.FunctionsAdminComponent":
        tabContent = (
          <FunctionsInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.User.UserContainerComponent":
        tabContent = (
          <UserInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.OrganizationAdminComponent":
        tabContent = (
          <OrganizationsInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Relation.RelationContainerComponent":
        tabContent = (
          <RelationsInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.BaseLevel.BaseLevelContainerComponent":
        tabContent = (
          <OrganizationsRelationsInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.TaskType.TaskTypeContainerComponent":
        tabContent = (
          <TaskTypeContainerInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Schedule.ScheduleContainerComponent":
        tabContent = (
          <SchedulesInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowTypeContainerComponent":
        tabContent = (
          <WorkflowTypeContainerInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Tree.TreeContainerComponent":
        tabContent = (
          <TreeContainerInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            callBackUpdate={handleCallBackCommands}
            cloned={isBranchCloned}
            branchId={branchIdSelected}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Calendar.CalendarContainerComponent":
        tabContent = (
          <CalendarContainerInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.CustomerType.CustomerTypeContainerComponent":
        tabContent = (
          <CustomerTypeInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Functions.FunctionAssignmentsAdminComponent":
        tabContent = (
          <FunctionsAssignmentsInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.QuickClassifiers.QuickClassifiersContainerComponent":
        tabContent = (
          <QuickClassifiersInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowAssignmentContainerComponent":
        tabContent = (
          <WorkflowAssignmentInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.CaseCustomState":
        tabContent = (
          <CaseCustomStatesInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Roles.RolesContainerComponent":
        tabContent = (
          <RolesContainerInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.AdditionalDataContainerComponent":
        tabContent = (
          <AdditionalDataInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.OutboundMailAccount.OutboundMailAccountAdminComponent":
        tabContent = (
          <OutboundMailAccountContainerInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Group.GroupContainerComponent":
        tabContent = (
          <GroupsInsertUpdateContainer
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Profiles.ProfilesContainerComponent":
        tabContent = (
          <ProfilesInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.EmailTemplatesAdminComponent":
        tabContent = (
          <EmailTemplateContainerInsertUpdate
            mode={"CloneEmail"}
            languageId={languageId}
            recordId={id}
            description={description}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Menu.MenuItemAdminComponent":
        tabContent = (
          <MenuDefinitionContainerInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAdminComponent":
        tabContent = (
          <InboundMailboxesInsertUpdateContainer
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesInProcessAdminComponent":
        tabContent = (
          <InboundMailboxInProcessUpdateContainer
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesRulesAdminComponent":
        tabContent = (
          <InboundMailboxesRulesInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      // case "TPClientCloudMain.Client.Components.Configuration.InboundMailAzureApplicationAdminComponent":
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAzureApplicationAdminComponent":
        tabContent = (
          <InboundMailAzureApplicationAdminInsertUpdate
            mode={"CloneEmail"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;

      default:
        //todo logs
        break;
    }

    let realSubtitle: string;
    if (recordDescription !== null && recordDescription !== undefined) {
      realSubtitle = recordDescription
        ? recordDescription.length < 10
          ? recordDescription
          : recordDescription.substring(0, 10) + "..."
        : "";
    } else {
      realSubtitle = id.length < 10 ? id : id.substring(0, 10) + "...";
    }

    let verticalElement: VerticalTabItemModel = {
      isActive: true,
      title: cloneEmailLabel,
      subTitle: realSubtitle,
      allowClose: true,
      icon: TPIconTypes.clone,
      id: myuuid,
      jsxFragment: tabContent,
      recordData: { recordId: id, operation: "CloneEmail", isHome: false },
    };

    newverticalTabsState.push(verticalElement);
    return newverticalTabsState;
  };

  const realCloneCommand = (
    prevState: Array<VerticalTabItemModel>,
    id: string,
    languageId: string,
    recordDescription?: string,
    form?: any
  ) => {
    let i: number;
    let alreadyExists: boolean;
    let myuuid = uuidv4();
    let tabContent: any = null;
    //check if and new tab is already present
    //if not exist create it
    //othewise switch to existing one
    alreadyExists = false;
    for (i = 0; i <= prevState.length - 1; i++) {
      if (
        prevState[i].recordData.recordId === id &&
        prevState[i].recordData.operation === "Clone"
      ) {
        alreadyExists = true;
        break;
      }
    }

    let newverticalTabsState = [...prevState];
    if (alreadyExists) {
      for (i = 0; i <= newverticalTabsState.length - 1; i++) {
        if (
          newverticalTabsState[i].recordData.recordId === id &&
          prevState[i].recordData.operation === "Clone"
        ) {
          newverticalTabsState[i].isActive = true;
        } else {
          newverticalTabsState[i].isActive = false;
        }
      }
      return newverticalTabsState;
    }
    //not exists
    //set all active =false
    for (i = 0; i <= newverticalTabsState.length - 1; i++) {
      newverticalTabsState[i].isActive = false;
    }

    switch (componentType) {
      case "TPClientCloudMain.Client.Components.Functions.FunctionsAdminComponent":
        tabContent = (
          <FunctionsInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.User.UserContainerComponent":
        tabContent = (
          <UserInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.OrganizationAdminComponent":
        tabContent = (
          <OrganizationsInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Relation.RelationContainerComponent":
        tabContent = (
          <RelationsInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.BaseLevel.BaseLevelContainerComponent":
        tabContent = (
          <OrganizationsRelationsInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.TaskType.TaskTypeContainerComponent":
        tabContent = (
          <TaskTypeContainerInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Schedule.ScheduleContainerComponent":
        tabContent = (
          <SchedulesInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowTypeContainerComponent":
        tabContent = (
          <WorkflowTypeContainerClone
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;

      case "TPClientCloudMain.Client.Components.Organization.FormDesigner.FormDesignerContainerComponent":
        tabContent = (
          <FormDesignerContainerClone
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Tree.TreeContainerComponent":
        tabContent = (
          <TreeContainerInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            callBackUpdate={handleCallBackCommands}
            cloned={isBranchCloned}
            branchId={branchIdSelected}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Calendar.CalendarContainerComponent":
        tabContent = (
          <CalendarContainerInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.CustomerType.CustomerTypeContainerComponent":
        tabContent = (
          <CustomerTypeInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Functions.FunctionAssignmentsAdminComponent":
        tabContent = (
          <FunctionsAssignmentsInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.QuickClassifiers.QuickClassifiersContainerComponent":
        tabContent = (
          <QuickClassifiersInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.WorkflowAssignmentContainerComponent":
        tabContent = (
          <WorkflowAssignmentInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.WorkFlow.CaseCustomState":
        tabContent = (
          <CaseCustomStatesInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Roles.RolesContainerComponent":
        tabContent = (
          <RolesContainerInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Organization.AdditionalDataContainerComponent":
        tabContent = (
          <AdditionalDataInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.OutboundMailAccount.OutboundMailAccountAdminComponent":
        tabContent = (
          <OutboundMailAccountContainerInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Group.GroupContainerComponent":
        tabContent = (
          <GroupsInsertUpdateContainer
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Profiles.ProfilesContainerComponent":
        tabContent = (
          <ProfilesClone
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.EmailTemplatesAdminComponent":
        tabContent = (
          <EmailTemplateCloneForm
            tabId={myuuid}
            element={form}
            globalDispatch={dispatchAction}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Menu.MenuItemAdminComponent":
        tabContent = (
          <MenuDefinitionContainerInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAdminComponent":
        tabContent = (
          <InboundMailboxesInsertUpdateContainer
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesInProcessAdminComponent":
        tabContent = (
          <InboundMailboxInProcessUpdateContainer
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
            tabId={myuuid}
          />
        );
        break;
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesRulesAdminComponent":
        tabContent = (
          <InboundMailboxesRulesInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
      // case "TPClientCloudMain.Client.Components.Configuration.InboundMailAzureApplicationAdminComponent":
      case "TPClientCloudMain.Client.Components.Configuration.InboundMailboxesAzureApplicationAdminComponent":
        tabContent = (
          <InboundMailAzureApplicationAdminInsertUpdate
            mode={"Clone"}
            recordId={id}
            callBackResult={handleCallBackInsertUpdate}
          />
        );
        break;
        case 'TPClientCloudMain.Client.Components.EventsManager.Projects':
            tabContent = <ProjectClone tabId={myuuid} element={form} dispatch={dispatchAction} />;
            break;

      default:
        //todo logs
        break;
    }

    let realSubtitle: string;
    if (recordDescription !== null && recordDescription !== undefined) {
      realSubtitle = recordDescription
        ? recordDescription.length < 10
          ? recordDescription
          : recordDescription.substring(0, 10) + "..."
        : "";
    } else {
      realSubtitle =
        `${id}`.length < 10 ? `${id}` : `${id}`.substring(0, 10) + "...";
    }

    let verticalElement: VerticalTabItemModel = {
      isActive: true,
      title: cloneLabel,
      subTitle: realSubtitle,
      allowClose: true,
      icon: TPIconTypes.copy,
      id: myuuid,
      jsxFragment: tabContent,
      recordData: { recordId: id, operation: "Clone", isHome: false, form },
    };

    newverticalTabsState.push(verticalElement);
    return newverticalTabsState;
  };

  //click on vertical tab
  const realVerticalTabClickCommand = (
    prevState: Array<VerticalTabItemModel>,
    tabId: string
  ) => {
    return [...prevState].map((s) => ({
      ...s,
      isActive: s.id === tabId,
    }));
  };

  //close vertical tab
  const realVerticalTabCloseCommand = (
    prevState: Array<VerticalTabItemModel>,
    tabId: string
  ) => {
    let newverticalTabsState = prevState.filter((x) => x.id !== tabId);
    newverticalTabsState = newverticalTabsState.map(function (item, index) {
      if (index === 0) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    return newverticalTabsState;
  };
  //close vertical tab by recordId
  const realVerticalTabCloseByRecorIdCommand = (
    prevState: Array<VerticalTabItemModel>,
    recordId: string
  ) => {
    let newverticalTabsState = prevState.filter(
      (x) => x.recordData.recordId !== recordId
    );
    newverticalTabsState = newverticalTabsState.map(function (item, index) {
      if (index === 0) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    return newverticalTabsState;
  };

  const realInsertUpdateCancelOrOk = (
    prevState: Array<VerticalTabItemModel>,
    theResult: any
  ) => {
    let i: number;
    let theRef: any;
    theRef = ChildRef.current;
    let newverticalTabsState = [...prevState];
    let indexToRemove: number = -1;

    //remove the tab corresponding to theResult.recordId
    for (i = 0; i <= newverticalTabsState.length - 1; i++) {
      if (
        newverticalTabsState[i].recordData.recordId === theResult.recordId ||
        (newverticalTabsState[i].recordData.recordId === "--" &&
          theResult.recordId === "")
      ) {
        indexToRemove = i;
        break;
      }
    }
    if (indexToRemove === -1) {
      TPLog.Log(
        `Error ${componentFileName} realInsertUpdateCancelOrOk`,
        TPLogType.ERROR,
        "indexToRemove=-1"
      );
      console.error(
        `Error ${componentFileName} realInsertUpdateCancelOrOk: indexToRemove=-1`
      );
      return prevState;
    }
    newverticalTabsState.splice(indexToRemove, 1);
    newverticalTabsState = newverticalTabsState.map(function (item, index) {
      if (index === 0) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    if (theResult.result === "CANCEL") {
      return newverticalTabsState;
    } else {
      theRef.refreshGridFromParent();
      return newverticalTabsState;
    }
  };

  const realReloadGrid = (
    prevState: Array<VerticalTabItemModel>,
    theResult: any
  ) => {
    let newverticalTabsState = [...prevState];
    let theRef: any;
    theRef = ChildRef.current;
    theRef?.refreshGridFromParent();
    return newverticalTabsState;
  };

  const realChangeToUpdateMode = (
    prevState: Array<VerticalTabItemModel>,
    theResult: any
  ) => {
    let theRef: any;
    theRef = ChildRef.current;
    let newverticalTabsState = [...prevState];
    for (let index = 0; index < newverticalTabsState.length; index++) {
      const element = newverticalTabsState[index];
      if (element.id === theResult.tabId) {
        element.icon = TPIconTypes.edit;
        element.subTitle =
          theResult.recordId.length < 10
            ? theResult.recordId
            : theResult.recordId.substring(0, 10) + "...";
        element.title = updateLabel;
        element.recordData.recordId = theResult.recordId;
        break;
      }
    }
    theRef.refreshGridFromParent();
    return newverticalTabsState;
  };

  const realChangeToUpdateRecordData = (
    prevState: Array<VerticalTabItemModel>,
    recordData: any
  ) => {
    return [...prevState].map((v) => {
      if (!v.isActive) return v;

      return {
        ...v,
        recordData: {
          ...v.recordData,
          ...recordData,
        },
      };
    });
  };

    function realDetail(state: Array<VerticalTabItemModel>, element: { [attribute: string]: any }) {
        let exist = false;
        const newState = [...state].map(s => {
            if (s.recordData.recordId === element.id) {
                exist = true;
            }

            return {
                ...s,
                isActive: s.recordData.recordId === element.id
            };
        });

        if (!exist) {
            const id = uuidv4()
            let jsxFragment;

            switch (componentType) {
                case 'TPClientCloudMain.Client.Components.EventsManager.Projects':
                    jsxFragment = (<ProjectDetail tabId={id}
                                                  element={element}
                                                  dispatch={dispatchAction}/>)
                    break;
                default:
                    jsxFragment = <span>Undefined content</span>
                    break;
            }

            newState.push({
                id,
                title: detailLabel,
                subTitle: element.id?.length < 10 ? element.id : `${element.id.substring(0, 10)}...`,
                icon: TPIconTypes.newEntity,
                recordData: {
                    isHome: false,
                    operation: detailLabel,
                    recordId: element.id,
                    element: structuredClone(element)
                },
                jsxFragment,
                isActive: true,
                allowClose: true
            } as VerticalTabItemModel);
        }

        return newState;
    }

  function realExport(state: Array<VerticalTabItemModel>, element: any) {
    let exist = false;
    const newState = [...state].map((s) => {
      if (s.recordData.operation === "Export" && s.recordData.recordId === element.id) {
        exist = true;
      }

      return {
        ...s,
        isActive: s.recordData.operation === "Export" && s.recordData.recordId === element.id,
      };
    });

    if (!exist) {
      const id = uuidv4();
      const jsxFragment = (
        <FormDesignerExportImportForm
          tabId={id}
          element={element}
          dispatchAction={dispatchAction}
        />
      );

      newState.push({
        id,
        title: exportLabel,
        subTitle:
          element.id?.length < 10
            ? element.id
            : `${element.id.substring(0, 10)}...`,
        icon: TPIconTypes.copy,
        recordData: {
          isHome: false,
          operation: "Export",
          recordId: element.id,
          element: structuredClone(element),
        },
        jsxFragment,
        isActive: true,
        allowClose: true,
      } as VerticalTabItemModel);
    }

    return newState;
  }

  function realImport(state: Array<VerticalTabItemModel>, element?: any) {
    let exist = false;
    const newState = [...state].map((s) => {
      if (!element && s.recordData.operation === "Import") {
        exist = true;
      }

      return {
        ...s,
        isActive: s.recordData.operation === "Import",
      };
    });

    if (!exist) {
      const id = uuidv4();
      const jsxFragment = (
        <FormDesignerExportImportForm
          tabId={id}
          dispatchAction={dispatchAction}
        />
      );

      newState.push({
        id,
        title: importLabel,
        subTitle: "",
        icon: TPIconTypes.copy,
        recordData: {
          isHome: false,
          operation: "Import",
          recordId: null,
          element: null,
        },
        jsxFragment,
        isActive: true,
        allowClose: true,
      } as VerticalTabItemModel);
    }

    return newState;
  }

  //vertical tab state with reducer
  let initialState: Array<VerticalTabItemModel> = [];
  const [verticalTabsState, dispatchCommand] = useReducer(
    doCommand,
    initialState
  );

  function doCommand(
    prevState: Array<VerticalTabItemModel>,
    command: commandType
  ) {
    switch (command.type) {
      case commandsEnum.create_home:
        return realHomeCommand(prevState);
      case commandsEnum.new_vertical_tab:
        return realNewCommand(prevState);
      case commandsEnum.new_bulk_load_vertical_tab:
        return realNewBulkLoadCommand(prevState);
      case commandsEnum.new_additional_bulk_load_vertical_tab:
        return realNewAdditionalBulkLoadCommand(prevState, command.payload);
      case commandsEnum.update_vertical_tab:
        if (command.payload.recordDescription !== null) {
          return realUpdateCommand(
            prevState,
            command.payload.recordId,
            command.payload.languageId,
            command.payload.recordDescription
          );
        } else {
          return realUpdateCommand(
            prevState,
            command.payload.recordId,
            command.payload.languageId
          );
        }
      case commandsEnum.clone_email_vertical_tab:
        return realCloneEmailCommand(
          prevState,
          command.payload.recordId,
          command.payload.languageId,
          command?.payload?.recordDescription,
          command?.payload?.description
        );

      case commandsEnum.clone_vertical_tab:
        return realCloneCommand(
          prevState,
          command.payload.recordId,
          command.payload?.languageId,
          command.payload?.recordDescription,
          command.payload?.form
        );
      case commandsEnum.vertical_tab_click:
        return realVerticalTabClickCommand(prevState, command.payload);
      case commandsEnum.vertical_tab_close:
        return realVerticalTabCloseCommand(prevState, command.payload);
      case commandsEnum.insertupdate_cancel_or_ok:
        return realInsertUpdateCancelOrOk(prevState, command.payload);
      case commandsEnum.vertical_tab_close_by_recordid:
        return realVerticalTabCloseByRecorIdCommand(prevState, command.payload);
      case commandsEnum.change_to_update_mode:
        return realChangeToUpdateMode(prevState, command.payload);
      case commandsEnum.change_to_update_reload_data:
        return realChangeToUpdateRecordData(prevState, command.payload);
      case commandsEnum.reloadGrid:
        return realReloadGrid(prevState, command.payload);
      case commandsEnum.detail:
          return realDetail(prevState, command.payload);
      case commandsEnum.export:
        return realExport(prevState, command.payload);
      case commandsEnum.import:
        return realImport(prevState, command.payload);
      default:
        TPLog.Log(
          `Error ${componentFileName} doCommand`,
          TPLogType.ERROR,
          "no command case defined for supplied command"
        );
        console.error(
          `Error ${componentFileName} doCommand: no command case defined for supplied command`
        );
        return prevState;
    }
  }

  function dispatchAction(action: commandType) {
    dispatchCommand(action);
  }

  //run once to include functionsAdmin in first tab
  useEffect(() => {
    //load resources
    if (!isReady) {
      loadResources();
    } else {
      let command: commandType = {
        type: commandsEnum.create_home,
        payload: null,
      };
      dispatchCommand(command);
    }
  }, [isReady]);

  useEffect(() => {
    dispatch(
      VerticalTabsAdminContainerSlice.actions.update(
        structuredClone(
          [...verticalTabsState].map((v) => ({ ...v, jsxFragment: null }))
        )
      )
    );
  }, [verticalTabsState]);

  useEffect(() => {
    if (TypeOf(result, VerticalTabsAdminContainerSlice.actions.export)) {
      dispatchCommand({
        type: commandsEnum.export,
        payload: { ...result.action.payload },
      });
    }
    if (TypeOf(result, VerticalTabsAdminContainerSlice.actions.clone)) {
      dispatchCommand({
        type: commandsEnum.clone_vertical_tab,
        payload: {
          recordId: result.action.payload.groupId,
          recordDescription: result.action.payload.user.login,
          form: result.action.payload,
        },
      });
    }
  }, [result]);

  return (
    <>
      <MenuVerticalTabStyled>
        <ul>
          {verticalTabsState.length > 0 &&
            verticalTabsState.map(function (item, index) {
              return (
                <li
                  key={"verticalTablink" + item.id}
                  className={`${item.isActive ? " active" : ""} home`}
                  onClick={() => {
                    if (!item.isActive) {
                      handleVerticalTabClick(item.id);
                    }
                  }}
                >
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => {}}
                    icon={item.icon}
                  />
                  {item.allowClose && (
                    <label
                      className="tp-vertical-tab-close-button"
                      onClick={(e: any) => handleVerticaltabClose(item.id, e)}
                    >
                      X
                    </label>
                  )}
                  {!item.recordData.isHome && (
                    <label>{item.title + " / " + item.subTitle}</label>
                  )}
                </li>
              );
            })}
        </ul>
      </MenuVerticalTabStyled>
      <ContentVerticalTabStyled>
        <ul>
          {verticalTabsState.length > 0 &&
            verticalTabsState.map(function (item, index) {
              return (
                <li key={"verticalTabContent" + item.id}>
                  <div
                    className={`container-fluid  ${item.isActive ? "  active" : ""}`}
                  >
                    {!["CloneEmail"].includes(item.recordData.operation) ? (
                      item.jsxFragment
                    ) : item.isActive ? (
                      item.jsxFragment
                    ) : (
                      <></>
                    )}
                  </div>
                </li>
              );
            })}
        </ul>
      </ContentVerticalTabStyled>
    </>
  );
};

export default VerticalTabsAdminContainer;
