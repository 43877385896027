import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import DynamicTable, {
  ColumnStyles,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { Dialog } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { StyledDialogContent } from "./load-history-styles";

type SolvedRecordsProps = {
  recordId: string;
  callBackResult: Function;
};

interface UserRecord {
  recordId: string;
  type: string;
  workedBy: string;
  time: string;
  outcome: string;
  workedDate: string;
}

const SolvedRecords: FC<SolvedRecordsProps> = ({
  recordId,
  callBackResult,
}): ReactElement => {
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [recordSelected, setRecordSelected] = useState<UserRecord>();
  const [titleLabel, setTitleLabel] = useState<string>("User Records");
  const [closeLabel, setCloseLabel] = useState<string>("Close");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const records: UserRecord[] = [
    {
      recordId: "214110",
      type: "Event",
      workedBy: "John Doe",
      time: "60:00:00",
      workedDate: "09/09/2024 5:11",
      outcome: "Escalated",
    },
    {
      recordId: "212117",
      type: "Event",
      workedBy: "John Doe",
      time: "00:30:00",
      workedDate: "09/09/2024 4:00",
      outcome: "Success",
    },
  ];

  const customColumns: ColumnStyles<UserRecord> = {
    recordId: ({ value, item }) => (
      <TablePrimaryItem value={value} onClick={() => handleShowRecord(item)} />
    ),
  };

  const handleCloseTab = () => {
    callBackResult({ result: "CANCEL", recordId });
  };

  const handleShowRecord = (record: UserRecord) => {
    setRecordSelected(record);
    handleClickOpen();
  };

  return (
    <TPLoadingOverlay active={isLoadingScreen}>
      <div className="row">
        <div className="col">
          <CIMTitleSection>
            <TPPageTitle style={{ margin: 0 }}>{titleLabel}</TPPageTitle>
            <TPButton
              id="close-solve-records"
              isDesignSystem
              customType={ButtonCustomType.tertiary}
              onClick={handleCloseTab}
              style={{ padding: "1px 18px" }}
            >
              {closeLabel}
            </TPButton>
          </CIMTitleSection>
          <DynamicTable
            id="records"
            data={records}
            columnStyles={customColumns}
            searchPosition="right"
          />
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ fontFamily: "Noto Sans" }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogContent>
          {recordSelected && (
            <>
              <h1>Record: {recordSelected.recordId}</h1>
              <div>
                <section>
                  <p>Information fields</p>
                  <p>Outcome: {recordSelected.outcome}</p>
                  <p>Time: {recordSelected.time}</p>
                  <p>Worked By: {recordSelected.workedBy}</p>
                </section>
              </div>
            </>
          )}
          <div className="dialog-actions">
            <TPButton
              id="close-record"
              isDesignSystem
              onClick={handleClose}
              style={{ padding: "1px 18px" }}
            >
              {closeLabel}
            </TPButton>
          </div>
        </StyledDialogContent>
      </Dialog>
    </TPLoadingOverlay>
  );
};

export default SolvedRecords;
