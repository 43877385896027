import styled from "styled-components";

export const tableStyles: any = {
  rows: {
    style: {
      color: "#2E2E2E",
      backgroundColor: "#FFF",
      minHeight: "46px", // override the row height
      padding: "5px",
    },
    stripedStyle: {
      color: "#2E2E2E",
      backgroundColor: "#F7F7F7",

      "&:hover": {
        backgroundColor: "#c !important",
      },
    },
  },
  headCells: {
    style: {
      backgroundColor: "#FFF",
      fontSize: "14px",
      fontWeight: "600",
      "&:hover": {
        color: "black",
      },
      ">div>div": {
        whiteSpace: "normal",
        textOverflow: "initial",
        wordBreak: "break-word",
        textAlign: "center",
      },
    },
  },

  cells: {
    style: {
      fontSize: "13px",
      // borderBottom: "1px solid black",

      "&[data-column-id='1']": {
        /*backgroundColor: "#edecec",
                /*borderBottom: "1px solid black",*/
      },
    },
  },
  checkbox: {
    style: {
      "&:hover": {
        backgroundColor: "transparent", // override the background color on hover
      },
      borderColor: "1px solid red",
      backgroundColor: "red", // override the background color
    },
  },
};

export const RowConfigStyle = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
`;

export const RightRowContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
`;

export const ContainerInputSelectStyle = styled.div<TabStyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  width: ${(props) => (props.width != undefined ? props.width : "200px")};
  border-radius: 4px;
  position: relative;
`;

interface TabStyleProps {
  width?: string;
}

export const SelectStyle = styled.div<TabStyleProps>`
  width: ${(props) => (props.width != undefined ? props.width : "100%")};
  display: flex;
  flex-direction: row;
  border: 1px solid #ced4da;
  border-radius: 5px;
  justify-content: space-between;
  padding: 1px;
  align-items: center;
  padding-left: 5px;
  color: #8d8d8d;
  background-color: white;
`;

export const ChildContainerStyle = styled.div<TabStyleProps>`
  width: ${(props) => (props.width != undefined ? props.width : "200px")};
  display: flex;
  -webkit-box-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  height: 370px;
  min-width: ${(props) => (props.width != undefined ? props.width : "200px")};
  border-radius: 2px;
  border: 1px solid #80808042;
  background-color: white;
  position: absolute;
  bottom: -372px;
  z-index: 100;
  left: 0px;
  padding-top: 10px;
  // box-shadow: black 0px 0px 2px;
  overflow: auto;
`;

export const ContainerCheck = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
`;

export const ContainerTabStyle = styled.div`
  width: 98%;
  height: 64px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: end;
  justify-content: start;
  background-color: transparent;
  /* border-bottom: 1px solid #989898; */
  align-items: flex-end;
  gap: 5px;
`;

interface TabActiveStyleProps {
  activeBorder: boolean;
}

export const TabActiveStyle = styled.div<TabActiveStyleProps>`
  display: flex;
  justify-content: center;
  width: 100px;
  border: none;
  border-bottom: ${(props) =>
    props.activeBorder ? "2px solid #780096" : "2px solid #ffffff"};
`;

export const NewCustomerFormDesign = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: start;
  width: 100%;
  overflow: 800px;
`;

export const ContainerAditionalData = styled.div`
    display: flex;
    -webkit-box-pack: center;
    justify-content: start;
    align-items: center;
    flex-direction: column;   
   width: 300px0;
    padding-top:10px ;
    border-top:1px solid #989898;
    border-left:1px solid #989898;
    border-bottom:1px solid #989898;
    
`;

export const ContainerRowDesign = styled.div`
  display: flex;
  -webkit-box-pack: start;
  justify-content: start;
  min-height: 600px;
  min-width: 76%;
  padding-top: 8px;
  padding-left: 15px;
  border: 1px solid rgb(152, 152, 152);
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  border-right: 1px solid white;
`;

export const ContainerTextBox = styled.div`
  width: 90%;
  height: 39px;
`;

export const ItemSelect = styled.div`
  width: 150px;
`;

export const ChildStyle = styled.div<ColorStyleProperty>`
  width: 100%;
  min-height: 20px;
  border: 1px solid #989898;
  border-radius: 5px;

  background-color: ${(props) =>
    props.color != "" && props.color != undefined ? props.color : ""};
`;

export const ContainerColorBackGroundStyle = styled.div`
  height: auto;
  display: flex;
  width: 50px;
  background-color: blue;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 2px;
  gap: 5%;
  width: 100%;
`;

interface ColorStyleProperty {
  color?: string;
}

export const ColorStyle = styled.div<ColorStyleProperty>`
  height: 20px;
  width: 20px;
  border-radius: 100%;
  box-shadow: 0px 0px 2px;
  background-color: ${(props) =>
    props.color != "" && props.color != undefined ? props.color : "white"};
`;

export const ContainerCheckStyle = styled.div`
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 18px;
`;

export const ContainerFormStyle = styled.div`
  height: auto;
  width: 98%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  /* justify-content: flex-start; */
  /* -webkit-box-align: stretch; */
  align-items: start;
  overflow: hidden;
  /* padding: 5px; */
  gap: 5px;
  position: relative;
`;

interface ActiveStyleProperty {
  active: boolean;
  color?: string;
}

export const ContainerRowItemStyle = styled.div<ActiveStyleProperty>`
  position: relative;
  min-width: 100%;
  height: auto;
  min-height: 50px;
  border-radius: 1px;
  background-color: #f5f5f5;
  padding-right: 14px;
  padding-left: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.color != "" && props.color != undefined ? props.color : ""};
  box-shadow: ${(props) =>
    props.active && props.active != undefined ? "0px 0px 2px " : ""};
`;

interface ActiveStyleProperty {
  active: boolean;
  color?: string;
}
export const BorderActiveStyle = styled.div<ActiveStyleProperty>`
  width: 100%;
  min-height: 95px;
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: start;
  background-color: ${(props) => (props.color ? props.color : "transparent")};
`;

export const MenuRow = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  border-radius: 4px;
  box-shadow: black 0px 0px 2px;
  right: 10px;
  top: auto;
  background-color: rgb(255, 255, 255);
  bottom: -100px;
  flex-direction: column;
  justify-content: space-evenly;
  display: flex;
  place-items: center;
  -webkit-box-align: center;
  overflow: hidden;
  min-width: 150px;
  z-index: 100;
`;

export const BtnMenuIco = styled.div`
  position: absolute;
  top: 0px;
  right: 2px;
  height: 40px;
  width: 20px;
  -webkit-box-align: start;
  align-items: start;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 5px;
  z-index: 11;
  display: flex;
  justify-content: right;
  /* margin-left: 150px; */
`;

export const ContainerBtnEditStyle = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: end;
  -webkit-box-align: end;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-right: 15px;
`;

export const ContainerSectionStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-direction: column;
`;

export const AddSectionStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #f4f4f4;
  padding: 5px;
  border-radius: 2;
  //cursor: pointer;
`;

export const BtnMoveSectionStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 30px;
`;

export const RowVerticalStyle = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #f5f5f5;
  gap: 2px;
  padding: 5px;
  // min-height: 90px;
  border-radius: 5px;
  position: relative;
`;

interface MenuItemDivStyleProps {
  right?: string;
  top?: string;
  bottom?: string;
  background_color?: string;
}
export const DraggableStyle = styled.div<MenuItemDivStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //background-color: #dbdbdf;
  width: 100%;
  align-items: center;
  /*   right: ${({ right }) => right || "10%"}; 
    top: ${({ top }) => top || "26px"};    
    z-index: 10000;
    cursor: grabbing; */
`;

export const IcoAddStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const AbsoluteStyle = styled.div`
  position: absolute;
  top: -23px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
  gap: 5px;
`;

export const AbsoluteBtnSaveStyle = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  top: -75px;
  flex-direction: initial;
  align-items: flex-end;
  padding-right: 10px;
  gap: 5px;
`;

export const ContainerPreferences = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  width: 554px;
  flex-direction: column;
  gap: 5px;
`;

export const ContainerNotification = styled.div<ColorStyleProperty>`
  display: flex;
  align-items: start;
  justify-content: start;
  padding: 8px;
  width: 550px;
  border-radius: 3px;
  margin-top: 10px;
  column-gap: 10px;
  background-color: ${(props) =>
    props.color != "" && props.color != undefined
      ? props.color
      : "rgb(211, 225, 252)"};
`;

export const ContainerPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const ContainerHeaderStyle = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  padding: 2px;
`;

export const ContainerPanelStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

interface WhidthModel {
  width?: string;
  isVetical?: boolean;
}

export const ItemHeaderStyle = styled.div<WhidthModel>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.width != undefined ? props.width : "auto")};
  flex-direction: ${(props) =>
    props.isVetical != undefined && props.isVetical ? "column" : "row"};
`;

export const ContainerPreviewFormStyle = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const FormPreviewStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 70%;
  height: 100%;
`;

export const RowPreviewStyle = styled.div<ColorStyleProperty>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: ${(props) =>
    props.color != "" && props.color != undefined ? props.color : ""};
  width: 100%;
  height: 100%;
  gap: 5px;
`;
