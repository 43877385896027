import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  ActionTypeEnum,
  ModalSizeEnum,
  SystemParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TaskViewModel } from "@/models/Task/TaskModels";
import {
  TaskSendMailReportInputDTO,
  TEmpAdditionalDataTaskViewModel,
} from "@/models/Task/TaskReportInputDTO";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextArea from "../bootstrap/forms/textArea/TPTextArea";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageTitle,
} from "../TPPage/tpPageStyles";
import TPRenderKeyValues from "../TPRenderKeyValues/TPRenderKeyValues";
// import React from "react";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPModal from "@/layouts/TPModal/TPModal";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { TEmpAdditionalDataCaseViewModel } from "@/models/Cases/CasesInputDTO";
import { CasesViewModel } from "@/models/Cases/CasesViewModels";
import {
  ReassignTaskInputDTO,
  ReassignTaskInputDTOValidator,
} from "@/models/Task/ReassignTaskInputDTO";
import {
  TaskRejectInputDTO,
  TaskRejectInputDTOValidator,
} from "@/models/Task/TaskRejectInputDTO";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import {
  AdditionalDataService,
  AdditionalDataTaskTypeService,
} from "@/services/AdditionalDataService";
import { CaseService } from "@/services/CaseService";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import { ParametersService } from "@/services/ParametersService";
import { TemporaryAttachmentService } from "@/services/TemporaryAttachments";
import { UserService } from "@/services/UserService";
import { Icon } from "@iconify/react";
import React from "react";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "../bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "../bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { TPAddtionalDataUIModes } from "../TPAdditionalData/TPAdditionalDataUImodes";
import TPRenderAdditionalDataSwitch from "../TPAdditionalData/TPRenderAdditionalDataSwitch";

import allThemes from "@/assets/styles/theme";
import ComposeWithAIAlert from "../TPGenAIMailKeyPoints/ComposeWithAI";
import { TPGenAIHeader } from "../TPGenAIMailKeyPoints/TPGenAIHeader";

enum commandsCaseAndCustomerInfoEnum {
  "load_case_info" = 0,
  "load_customer_info" = 1,
  "change_currentTab" = 2,
  "change_case_accordion_info" = 3,
  "change_customer_accordion_info" = 4,
  "change_case_status" = 5,
}

type commandCaseAndCustomerInfoType = {
  type: commandsCaseAndCustomerInfoEnum;
  payload: any;
};

enum taskType {
  receivedMail = "S_RECEMA",
}

enum currentAccordionsCaseEnum {
  "NONE" = 0,
  "CLASSIFICATION" = 1,
  "COMMENTS" = 2,
  "ATTACHMENTS" = 3,
  "ADDITIONALDATA" = 4,
}
interface TPEmailTaskInterface {
  caseNumber: number;
  taskIdToView: number;
  mode: string;
  isWorkflowTask: boolean;
  closeTaskCallBack: Function;
  saveTaskCallBack: Function;
  actionsTaskCallBack?: Function;
}

enum currentAccordionsEmailMenu {
  "ORIGIN" = 0,
  "TRANSLATION" = 1,
}

type modalReassignScreenType = {
  newResponsibleGuidUser: Array<TPKeyValue>;
  report: string;
  newResponsibleGuidUserErrorMessage: string;
  reportErrorMessage: string;
  [x: string]: any;
};

type modalTemplateScreenType = {
  newTemplate: Array<TPKeyValue>;
  reportTemplate: string;
  newTemplateErrorMessage: string;
  reportTemplateErrorMessage: string;
  [x: string]: any;
};

type modalRejectScreenType = {
  reasonId: string;
  comments: string;
  reasonIdErrorMessage: string;
  commentsErrorMessage: string;
  [x: string]: any;
};

type CaseAttachmentType = {
  sender: string;
  description: string;
  filesDraggedList: Array<any>;

  //validations
  senderErrorMessage: string;
  descriptionErrorMessage: string;
  filesDraggedListErrorMessage: Array<string>;
  notFilesErrorMessage: string;
  [key: string]: any;
};

export const TPEmailTask = React.forwardRef(
	(
		{
			caseNumber,
			taskIdToView,
			mode,
			isWorkflowTask,
			closeTaskCallBack,
			saveTaskCallBack,
			actionsTaskCallBack
		}: TPEmailTaskInterface,
		ref
	) => {
		//function called from parent
		useImperativeHandle(ref, () => ({
			refreshTaskFromParent() {
				loadTaskInfo();
			},
			canClose() {
				return !hasBeenModified;
			},
			clearHasBeenModified() {
				setHasBeenModified(false);
			},
			refreshAdditionalData: (newAdditionaData: Array<TEmpAdditionalDataTaskViewModel>) => {
				realRefreshAdditionalData(newAdditionaData);
			},
			activeSaveButton() {
				setSaveButtonIsActive(false);
			}
		}));

		//screen loading
		const [isLoadingScreen, setIsLoadingScreen] = useState(true);
		const [isLoadingModalScreen, setIsLoadingModalScreen] = useState(true);
		const componentFileName: string = 'TPEmailTask.tsx';
		const resourceSet = 'EmailTaskComponent';
		const resourceSetReassignModal: string = 'ReassignTaskModal';
		const resourceSetRejectModal: string = 'RejectTaskModal';
		const resourceSetTaskViewer = 'TaskViewerComponent';
		const resourceTranslationPreviewModal = 'TranslationPreviewModal';

		const editorRef = useRef<any>(null);

		const [responsibleLabel, setResponsibleLabel] = useState('');
		const [creationDateLabel, setCreationDateLabel] = useState('');
		const [startDateLabel, setStartDateLabel] = useState('');
		const [dueToDateLabel, setDueToDateLabel] = useState('');
		const [readerLabel, setReaderLabel] = useState('');
		const [reportSummaryLabel, setReportSummaryLabel] = useState('');

		const [translationFeatureLabel, setTranslationFeatureLabel] = useState('');
		const [previewOptionLabel, setPreviewOptionLabel] = useState('');
		const [useTranslationFeatureLabel, setUseTranslationFeatureLabel] = useState('');
		const [translationPreviewTitleModal, setTranslationPreviewTitleModal] = useState('');
		const [languageforTranslationLabel, setLanguageforTranslationLabel] = useState('');

		const [editorErrorMessage, setEditorErrorMessage] = useState('');
		const [saveButtonLabel, setSaveButtonLabel] = useState('');
		const [saveButtonIsActive, setSaveButtonIsActive] = useState(false);
		const [currentTask, setCurrentTask] = useState<TaskViewModel | null>(null);
		const [currentTaskType, setCurrentTaskType] = useState<TaskTypeViewModel | null>(null);
		const [hasBeenModified, setHasBeenModified] = useState(false);
		const [currentActionType, setCurrentActionType] = useState<ActionTypeEnum>(ActionTypeEnum.Reassign);
		const [autocompleteToOptions, setAutocompleteToOptions] = useState<Array<TPKeyValue>>([]);
		const [autocompleteTemplateOptions, setAutocompleteTemplateOptions] = useState<Array<TPKeyValue>>([]);

		//top n options for arrow icon
		const [autocompleteToTopNOptions, setAutocompleteToTopNOptions] = useState<Array<TPKeyValue>>([]);
		const [autocompleteTemplateTopNOptions, setAutocompleteTemplateTopNOptions] = useState<Array<TPKeyValue>>([]);

		const [emptyLabel, setEmptyLabel] = useState('');
		const [isShownActionModal, setIsShownActionModal] = useState(false);
		const [fromLabel, setFromLabel] = useState('');
		const [toLabel, setToLabel] = useState('');
		const [ccLabel, setCCLabel] = useState('');
		const [bccLabel, setBccLabel] = useState('');
		const [subjectLabel, setSubjectLabel] = useState('');
		const [messageLabel, setMessageLabel] = useState('');
		const [taskTitleModal, setTaskTitleModal] = useState('');
		const [modalAcceptLabel, setModalAcceptLabel] = useState('');
		const [modalCancelLabel, setModalCancelLabel] = useState('');
		const [actionsLabel, setActionsLabel] = useState('');
		const [reassignMenuItemLabel, setReassignMenuItemLabel] = useState('');
		const [rejectMenuItemLabel, setRejectMenuItemLabel] = useState('');
		const [internalReportNotForSendingLabel, setInternalReportNotForSendingLabel] = useState('');
		const [replyToAllLabel, setReplyToAllLabel] = useState('');
		const [useTemplateLabel, setUseTemplateLabel] = useState('');
		const [replyToLabel, setReplyToLabel] = useState('');
		const [templateLanguageLabel, setTemplateLanguageLabel] = useState('');
		const [templateLabel, setTemplateLabel] = useState('');
		const [ccButtonLabel, setCcButtonLabel] = useState('');
		const [bccButtonLabel, setBccButtonLabel] = useState('');
		const [writeSomethingHereLabel, setWriteSomethingHereLabel] = useState('');
		const [titleLabel, setTitleLabel] = useState('');
		const [okLabel, setOkLabel] = useState('');
		const [cancelLabel, setCancelLabel] = useState('');
		const [attachmentLabel, setAttachmentLabel] = useState('');
		const [newAttachmentLabel, setNewAttachmentLabel] = useState('');

		const [senderLabel, setSenderLabel] = useState('');
		const [descriptionLabel, setDescriptionLabel] = useState('');
		const [dropFilesHereLabel, setDropFilesHereLabel] = useState('');
		const [uploadFileButtonLabel, setUploadFileButtonLabel] = useState('');
		const [sendCaseLabel, setSendCaseLabel] = useState('');
		const [sendTasksLabel, setSendTasksLabel] = useState('');
		const [attachNewFileLabel, setAttachNewFileLabel] = useState('');
		const [deleteLabel, setDeleteLabel] = useState('');
		const [fileLabel, setFileLabel] = useState('');
		const [saveInCaseLabel, setSaveInCaseLabel] = useState('');
		const [addCaseAttachmentLabel, setAddCaseAttachmentLabel] = useState('');
		const [allLabel, setAllLabel] = useState('');
		const [pleaseEnterAMessageLabel, setPleaseEnterAMessageLabel] = useState('');
		const [attachmentsLable, setAttachmentsLable] = useState('');

		const [subjectTranslation, setSubjectTranslation] = useState('');

		const [commentsLabel, setCommentsLabel] = useState('');
		const [reasonsLabel, setReasonsLabel] = useState('');

		let initialStateFromList: Array<TPKeyValue> = [];
		const [fromKeyValue, setFromKeyValue] = useState(initialStateFromList);
		const [fromValue, setFromValue] = useState('--');
		const [fromValueErrorMessage, setFromValueErrorMessage] = useState('');
		const [isToValue, setIsToValue] = useState(false);
		const [toValue, setToValue] = useState('');
		const [toValueErrorMessage, setToValueErrorMessage] = useState('');
		const [ccValue, setCCValue] = useState('');
		const [isCcValue, setIsCCValue] = useState(false);
		const [ccValueErrorMessage, setCCValueErrorMessage] = useState('');
		const [bccValue, setBccValue] = useState('');
		const [isBccValue, setIsBccValue] = useState(false);
		const [bccValueErrorMessage, setBccValueErrorMessage] = useState('');
		const [subjectValue, setSubjectValue] = useState('');
		const [subjectValueErrorMessage, setSubjectValueErrorMessage] = useState('');

		const [isShowCc, setIsShowCc] = useState(false);
		const [isShowBcc, setIsShowBcc] = useState(false);
		const [templateLanguage, setTemplateLanguage] = useState<Array<TPKeyValue>>([]);
		const [replyTo, setReplyTo] = useState<Array<TPKeyValue>>([]);
		const [isReplyToDataExist, setIsReplyToDataExist] = useState(false);
		const [templateLanguageValue, setTemplateLanguageValue] = useState('--');
		const [templateLanguageValueErrorMessage, setTemplateLanguagevalueErrorMessage] = useState('');
		const [replyToValue, setReplyToValue] = useState('--');
		const [showReplyAll, setShowReplyAll] = useState(false);
		const [replyToValueErrorMessage, setReplyToValueErrorMessage] = useState('');
		const [isInternalReportNotForSending, setIsInternalReportNotForSending] = useState(false);
		const [isReplyToAll, setIsReplyToAll] = useState(false);
		const [isUseTemplate, setIsUseTemplate] = useState(false);
		const [isSendCase, setIsSendCase] = useState(false);
		const [isSendTasks, setIsSendTasks] = useState(false);
		const [isLoading, setIsLoading] = useState(false); // state to track loading status

		const [operationGuid, setOperationGuid] = useState('');

		const [editorValue, setEditorValue] = useState('');
		const [translatedValue, setTranslatedValue] = useState('');

		const [emailStatus, setEmailStatus] = useState<currentAccordionsEmailMenu>(currentAccordionsEmailMenu.TRANSLATION);
		const [originalEmail, setOriginalEmail] = useState<string>('');

		const [isSownModalAttachment, setIsSownModalAttachment] = useState(false);
		const [isLoadingScreenModalAttachment, setIsLoadingScreenModalAttachment] = useState(false);
		const [isMessageError, setIsMessageError] = useState<boolean>(false);

		const [StoryFaiAvailableLanguages, setStoryFaiAvailableLanguages] = useState<Array<any>>([]);

		const initialCaseAttachmentType: CaseAttachmentType = {
			sender: TPGlobal.currentUserName,
			description: '',
			senderErrorMessage: '',
			descriptionErrorMessage: '',
			filesDraggedList: [],
			existFilesDraggedList: [],
			filesDraggedListErrorMessage: [],
			existFilesDraggedListErrorMessage: [],
			notFilesErrorMessage: ''
		};

		const [caseAttachmentState, setCaseAttachmentState] = useState(initialCaseAttachmentType);
		const [attachment, setAttachment] = useState<any[]>([]);
		const [isSaveInCase, setIsSaveInCase] = useState(false);
		// State to keep track of selected checkboxes
		const [selectedCheckboxes, setSelectedCheckboxes] = useState<any>({});
		const [addCaseExistAttachment, setAddCaseExistAttachment] = useState<any[]>([]);
		const [caseInformation, setCaseInformation] = useState<CasesViewModel | null>(null);

		const [mailboxID, setMailboxID] = useState<any>(0);

		const handleChange = (value: any) => {
			const filterData = value.replace(/(<([^>]+)>)/gi, '');

			setEditorValue(value);
			if (filterData.length > 0) {
				setIsMessageError(false);
			}
		};

		const handleTranslateChange = (value: any) => {
			setTranslatedValue(value);
		};

		const handleModalPopup = () => {
			setIsSownModalAttachment(true);
		};

		const handleCallBackNewAttachmentModal = async (confirmAdd: boolean, callBackData: any) => {
			if (confirmAdd) {
				let serviceClient = new TemporaryAttachmentService();
				let expectedCodes: Array<number> = [200];

				let filename: string;
				let extension: string;
				let parts: Array<string> = [];
				let blobId: string;

				let newCaseAttachmentState = { ...caseAttachmentState };
				if (attachment && attachment.length > 0) {
					setIsLoadingScreenModalAttachment(true);
					for (let i = 0; i < attachment.length; i++) {
						const element = attachment[i];
						filename = element.name;
						parts = filename.split('.');
						if (parts.length === 1) {
							extension = '';
						} else {
							extension = parts[parts.length - 1];
						}
						//todo send sender, guid user, etc
						blobId = 'caseblob' + uuidv4().replaceAll('-', '');
						const fileData = new FormData();
						fileData.append('fileData', element);
						fileData.append('operationGuid', operationGuid);
						fileData.append('blobId', blobId);
						fileData.append('filename', filename);
						fileData.append('extensionName', extension);
						fileData.append('sender', caseAttachmentState.sender);
						fileData.append('description', caseAttachmentState.description);
						fileData.append('guid_USER', TPGlobal.currentUserGuid);

						// const taskAttacment = {
						//   "BlobId": blobId,
						//   "Filename": filename,
						//   "ExtensionName": extension,
						//   "Sender": caseAttachmentState.sender,
						//   "Description": caseAttachmentState.description,
						//   "Guid_USER": TPGlobal.currentUserGuid,
						//   "SaveInCase": true
						// }

						// newCaseAttachmentState.filesDraggedList.push(taskAttacment);
						// newCaseAttachmentState.notFilesErrorMessage = "";
						// setCaseAttachmentState(newCaseAttachmentState);

						let responseRequest = await serviceClient.insertTemporaryAttachment(fileData, false, true, expectedCodes);
						if (responseRequest.responseResult) {
							showToast(responseRequest.responseData.responseMessage.message, TPToastTypes.success);

							const taskAttacment = {
								BlobId: blobId,
								Filename: filename,
								ExtensionName: extension,
								Sender: caseAttachmentState.sender,
								Description: caseAttachmentState.description,
								Guid_USER: TPGlobal.currentUserGuid,
								SaveInCase: true
							};

							newCaseAttachmentState.filesDraggedList.push(taskAttacment);
							newCaseAttachmentState.notFilesErrorMessage = '';
							setCaseAttachmentState(newCaseAttachmentState);
						}
					}
					setIsLoadingScreenModalAttachment(false);
				} else {
					newCaseAttachmentState.notFilesErrorMessage = await TPI18N.GetText(resourceSet, 'NotFilesErrorMessageLabel');
					setCaseAttachmentState(newCaseAttachmentState);
					return;
				}
			}
			setIsSownModalAttachment(false);
		};

		useEffect(() => {
			// Operation guid configuration
			setOperationGuid(uuidv4().replaceAll('-', ''));
			// get all attachments by case
			getCaseAttachments(caseNumber);
		}, []);

		// useEffect(()=>{

		// },[replyToValue])

		const getCaseAttachments = async (caseId: number) => {
			let serviceClient = new CaseService();
			let expectedCodes: Array<number> = [200, 404];

			try {
				setIsLoadingScreen(true);
				let responseRequest = null as any;
				// responseRequest = await serviceClient.getAttachmentsByCaseId(
				//   caseId,
				//   false,
				//   true,
				//   expectedCodes
				// );
				if (responseRequest && responseRequest.length > 0) {
					// for (let i = 0; i <= responseRequest.length - 1; i++) {
					//   responseRequest[i].tpguidrow = "tpCaseAttachmentsrow" + uuidv4().replaceAll("-", "");
					//   responseRequest[i].addCase = true; // Initialize addCase property

					// }

					const formattedResponse = responseRequest.map((item: any) => ({
						BlobId: item.blobId,
						Filename: item.filename,
						ExtensionName: item.extensionName,
						Sender: item.sender,
						Description: item.description,
						Guid_USER: item.userGuid,
						SaveInCase: true
					}));

					setAddCaseExistAttachment(formattedResponse);

					let newCaseAttachmentState = { ...caseAttachmentState };

					setCaseAttachmentState({
						...newCaseAttachmentState,
						existFilesDraggedList: formattedResponse
					});
				}
				setIsLoadingScreen(false);
				return responseRequest;
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} getCaseAttachments ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} getCaseAttachments ex`);
				setIsLoadingScreen(false);
				return null;
			}
		};

		let modules = {
			toolbar: {
				container: '#toolbar'
			},
			clipboard: {
				matchVisual: false
			}
		};

		let formats = [
			'header',
			'font',
			'size',
			'bold',
			'italic',
			'underline',
			'strike',
			'blockquote',
			'list',
			'bullet',
			'indent',
			'link',
			'image',
			'color'
		];

		//reassign
		const initialModalReassignScreenState: modalReassignScreenType = {
			newResponsibleGuidUser: [],
			report: '',
			newResponsibleGuidUserErrorMessage: '',
			reportErrorMessage: ''
		};
		const [modalReassignScreenState, setModalReassignScreenState] = useState<modalReassignScreenType>(
			initialModalReassignScreenState
		);

		// Template
		const initialModalTemplateScreenState: modalTemplateScreenType = {
			newTemplate: [],
			reportTemplate: '',
			newTemplateErrorMessage: '',
			reportTemplateErrorMessage: ''
		};
		const [modalTemplateScreenState, setModalTemplateScreenState] = useState<modalTemplateScreenType>(
			initialModalTemplateScreenState
		);

		//reject
		const initialModalRejectScreenState: modalRejectScreenType = {
			reasonId: '',
			comments: '',
			reasonIdErrorMessage: '',
			commentsErrorMessage: ''
		};
		const [modalRejectScreenState, setModalRejectScreenState] =
			useState<modalRejectScreenType>(initialModalRejectScreenState);

		const [reasonList, setReasonList] = useState<Array<TPKeyValue>>([]);

		useEffect(() => {
			if (templateLanguageValue && modalTemplateScreenState.newTemplate.length > 0) {
				const fetchData = async () => {
					let taskService = new TaskService();
					let expectedCodes: Array<number> = [200];
					const data = {
						EmailTemplateId: modalTemplateScreenState.newTemplate[0]?.key,
						LanguageId: templateLanguageValue,
						CaseId: caseInformation?.caseId,
						TaskId: taskIdToView,
						SubsidiaryOrganizationId: caseInformation?.subsidiaryOrganizationId,
						UserSessionGuid: TPGlobal.currentUserGuid,
						RemoveDecisionTaskArea: false
					};
					try {
						setIsLoading(true); // set loading to true before making the request
						let responseRequest = await taskService.renderEmailTemplate(data, false, true, expectedCodes);
						// Handle successful response

						setSubjectValue(responseRequest?.responseData?.renderedSubject);
						setToValue(prevToValue =>
							prevToValue
								? prevToValue + '; ' + responseRequest?.responseData?.renderedTo
								: responseRequest?.responseData?.renderedTo
						);
						setCCValue(prevCCValue =>
							prevCCValue
								? prevCCValue + '; ' + responseRequest?.responseData?.renderedCc
								: responseRequest?.responseData?.renderedCc
						);
						setBccValue(prevBccValue =>
							prevBccValue
								? prevBccValue + '; ' + responseRequest?.responseData?.renderedBcc
								: responseRequest?.responseData?.renderedBcc
						);

						setIsCCValue(responseRequest?.responseData?.renderedCc?.length > 0 ? true : false);
						setIsBccValue(responseRequest?.responseData?.renderedBcc?.length > 0 ? true : false);

						// If replyToValue is '--', replace the content before the separator with the new content
						const separatorIndex = editorValue.indexOf('<p><br></p><p>_____________________________</p>');

						if (separatorIndex !== -1) {
							// Extract the content after the separator
							const contentAfterSeparator = editorValue.substring(separatorIndex);

							// Replace the content before the separator with the new content
							const newEditorValue = responseRequest?.responseData?.renderedBody + contentAfterSeparator;

							setEditorValue(newEditorValue);
						} else {
							setEditorValue(responseRequest?.responseData?.renderedBody);
						}

						setIsLoading(false); // set loading to false after request completes
					} catch (error) {
						setIsLoading(false); // set loading to false in case of error
						// Handle error
						console.error('Error:', error);
					}
				};
				fetchData();
			}
		}, [templateLanguageValue, modalTemplateScreenState.newTemplate.length > 0]);

		//additionalData
		//key: additional data id
		//value: addtional data value
		//value2: other properties
		//value3: should show
		const [additionalDataValues, setAdditionalDataValues] = useState<Array<TPKeyValue>>([]);
		const childRefArray: any = useRef([]);
		const taskguid: string = uuidv4();

		const loadResourcesAndTaskInfo = async () => {
			setResponsibleLabel(await TPI18N.GetText(resourceSetTaskViewer, 'ResponsibleLabel'));
			setCreationDateLabel(await TPI18N.GetText(resourceSetTaskViewer, 'CreationDateLabel'));
			setStartDateLabel(await TPI18N.GetText(resourceSetTaskViewer, 'StartDateLabel'));
			setDueToDateLabel(await TPI18N.GetText(resourceSetTaskViewer, 'DueToDateLabel'));
			setReaderLabel(await TPI18N.GetText(resourceSetTaskViewer, 'ReaderLabel'));
			setReportSummaryLabel(await TPI18N.GetText(resourceSetTaskViewer, 'ReportSummaryLabel'));
			setFromLabel(await TPI18N.GetText(resourceSet, 'FromLabel'));
			setCCLabel(await TPI18N.GetText(resourceSet, 'CCLabel'));
			setBccLabel(await TPI18N.GetText(resourceSet, 'BCCLabel'));
			setSubjectLabel(await TPI18N.GetText(resourceSet, 'SubjectLabel'));
			setMessageLabel(await TPI18N.GetText(resourceSet, 'MessageLabel'));
			setInternalReportNotForSendingLabel(await TPI18N.GetText(resourceSet, 'InternalReportNotForSendingLabel'));
			setReplyToAllLabel(await TPI18N.GetText(resourceSet, 'ReplyToAllLabel'));
			setUseTemplateLabel(await TPI18N.GetText(resourceSet, 'UseTemplateLabel'));
			setReplyToLabel(await TPI18N.GetText(resourceSet, 'ReplyToLabel'));
			setTemplateLanguageLabel(await TPI18N.GetText(resourceSet, 'TemplateLanguageLabel'));
			setTemplateLabel(await TPI18N.GetText(resourceSet, 'TemplateLabel'));
			setCcButtonLabel(await TPI18N.GetText(resourceSet, 'CcButtonLabel'));
			setBccButtonLabel(await TPI18N.GetText(resourceSet, 'BccButtonLabel'));
			setWriteSomethingHereLabel(await TPI18N.GetText(resourceSet, 'WriteSomethingHereLabel'));
			setTitleLabel(await TPI18N.GetText(resourceSet, 'TitleLabel'));
			setOkLabel(await TPI18N.GetText(resourceSet, 'OkLabel'));
			setCancelLabel(await TPI18N.GetText(resourceSet, 'CancelLabel'));
			setAttachmentLabel(await TPI18N.GetText(resourceSet, 'AttachmentLabel'));
			setNewAttachmentLabel(await TPI18N.GetText(resourceSet, 'NewAttachmentLabel'));
			setSenderLabel(await TPI18N.GetText(resourceSet, 'SenderLabel'));
			setDescriptionLabel(await TPI18N.GetText(resourceSet, 'DescriptionLabel'));
			setDropFilesHereLabel(await TPI18N.GetText(resourceSet, 'DropFilesHereLabel'));
			setUploadFileButtonLabel(await TPI18N.GetText(resourceSet, 'UploadFileButtonLabel'));
			setSendCaseLabel(await TPI18N.GetText(resourceSet, 'SendCaseLabel'));
			setSendTasksLabel(await TPI18N.GetText(resourceSet, 'SendTasksLabel'));
			setAttachNewFileLabel(await TPI18N.GetText(resourceSet, 'AttachNewFileLabel'));
			setDeleteLabel(await TPI18N.GetText(resourceSet, 'DeleteLabel'));
			setFileLabel(await TPI18N.GetText(resourceSet, 'FileLabel'));
			setSaveInCaseLabel(await TPI18N.GetText(resourceSet, 'SaveInCaseLabel'));
			setAddCaseAttachmentLabel(await TPI18N.GetText(resourceSet, 'AddCaseAttachmentLabel'));
			setAllLabel(await TPI18N.GetText(resourceSet, 'AllLabel'));
			setPleaseEnterAMessageLabel(await TPI18N.GetText(resourceSet, 'PleaseEnterAMessageLabel'));

			setSaveButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'SaveButtonLabel'));

			setEmptyLabel(await TPI18N.GetText(resourceSetTaskViewer, 'EmptyLabel'));
			setAttachmentsLable(await TPI18N.GetText(resourceSetTaskViewer, 'AttachmentsLable'));

			setTranslationFeatureLabel(await TPI18N.GetText(resourceSet, 'TranslationFeatureLabel'));
			setPreviewOptionLabel(await TPI18N.GetText(resourceSet, 'PreviewOptionLabel'));
			setUseTranslationFeatureLabel(await TPI18N.GetText(resourceSet, 'UseTranslationFeatureLabel'));
			setLanguageforTranslationLabel(await TPI18N.GetText(resourceSet, 'languageforTranslationLabel'));

			setTranslationPreviewTitleModal(
				await TPI18N.GetText(resourceTranslationPreviewModal, 'TranslationPreviewTitleModal')
			);

			//modal resources

			setToLabel(await TPI18N.GetText(resourceSetReassignModal, 'ToLabel'));
			setModalAcceptLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'OkButton'));
			setModalCancelLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'CancelButton'));
			setActionsLabel(await TPI18N.GetText(resourceSetReassignModal, 'ActionsLabel'));
			setReassignMenuItemLabel(await TPI18N.GetText(resourceSetReassignModal, 'ReassignMenuItemLabel'));
			setRejectMenuItemLabel(await TPI18N.GetText(resourceSetReassignModal, 'RejectMenuItemLabel'));
			setCommentsLabel(await TPI18N.GetText(resourceSetReassignModal, 'CommentsLabel'));
			setReasonsLabel(await TPI18N.GetText(resourceSetRejectModal, 'ReasonsLabel'));

			setIsLoadingScreen(true);

			// Loading mail account information
			await loadFromInfo();

			//get task info by id
			await loadTaskInfo();

			//get additional data
			await getAdditionalDataForTaskId();

			setIsLoadingScreen(false);
		};

		const loadFromInfo = async () => {
			let serviceClient = new OutboundMailAccountService();
			let expectedCodes: Array<number> = [200, 404];
			try {
				setIsLoadingScreen(true);
				let responseRequest = await serviceClient.getAllMailAccounts(false, true, expectedCodes);
				let newEmailAccountListState: Array<TPKeyValue> = [];
				responseRequest.forEach((item: any) => {
					newEmailAccountListState.push({
						key: item.idAccount,
						value: item.description,
						...item
					});
				});

				newEmailAccountListState.unshift({ key: '--', value: '--' });
				setFromKeyValue(newEmailAccountListState);
				setIsLoadingScreen(false);
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} getAllMailAccounts ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} getAllMailAccounts ex`);
				setIsLoadingScreen(false);
			}

			let taskService = new TaskService();
			let _expectedCodes: Array<number> = [200];
			try {
				let responseRequest = await taskService.getTemplateLanguages(false, true, _expectedCodes);

				// Language dropdown
				let languageOptions: Array<TPKeyValue> = [];
				responseRequest.forEach((item: any) => {
					languageOptions.push({
						key: item.id,
						value: item.name,
						...item
					});
				});

				languageOptions.unshift({
					key: '--',
					value: '--'
				});

				setTemplateLanguage(languageOptions);
			} catch (error) {
				// ... error handling ...
				console.error('error', error);
			}

			let __expectedCodes: Array<number> = [200, 404];
			try {
				let responseRequest = await taskService.getInboundMailboxReadByCaseId(caseNumber, false, true, __expectedCodes);

				// Language dropdown
				let replyToOptions: Array<TPKeyValue> = [];
				responseRequest.forEach((item: any) => {
					replyToOptions.push({
						key: item.id,
						value: item.replyToLocalized,
						...item
					});
				});

				if (responseRequest.length > 0) {
					setIsReplyToDataExist(true)
				} else {
					setIsReplyToDataExist(false)
				}

				replyToOptions.unshift({
					key: '--',
					value: '--'
				});

				setReplyTo(replyToOptions);
			} catch (error) {
				// ... error handling ...
				console.error('error', error);
			}
		};

		const getCaseInfoByNumber = async (caseNumber: number): Promise<CasesViewModel | null> => {
			let serviceClient = new CaseService();
			let expectedCodes: Array<number> = [200];
			let responseRequest: CasesViewModel;
			try {
				setIsLoadingScreen(true);
				responseRequest = await serviceClient.getCaseInfoByCaseNumber(caseNumber, false, true, expectedCodes);
				setIsLoadingScreen(false);
				return responseRequest;
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} getCustomerByClientIdSubsidiaryIdAndSubOrg ex`);
				setIsLoadingScreen(false);
				return null;
			}
		};

		const loadTaskInfo = async () => {
			let currentTaskElement: TaskViewModel | null;
			let currentTaskTypeElement: TaskTypeViewModel | null;
			let caseInfo: CasesViewModel | null;

			try {
				currentTaskElement = await getTaskInforById(taskIdToView);
				if (currentTaskElement) {
					currentTaskElement.report = TPGlobal.TPReplaceEnter(currentTaskElement.report);

					setCurrentTask(currentTaskElement);

					// Loading task type information
					currentTaskTypeElement = await getTaskTypeInformationById(currentTaskElement.taskTypeId);
					if (currentTaskTypeElement) {
						setCurrentTaskType(currentTaskTypeElement);

						// Loading case information
						caseInfo = await getCaseInfoByNumber(currentTaskElement.caseId);
						if (caseInfo) {
							setCaseInformation(caseInfo);
						}
					} else {
						//todo logs
					}
				} else {
					//todo logs
				}
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} loadTaskInfo ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} loadTaskInfo ex`);
				setIsLoadingScreen(false);
			}
		};

		const getTaskTypeInformationById = async (idTaskType: string) => {
			let taskTypeService = new TaskTypeService();
			let expectedCodes: Array<number> = [200];

			try {
				let responseRequest = await taskTypeService.getTaskTypeById(idTaskType, false, true, expectedCodes);
				if (responseRequest) {
					return responseRequest;
				} else {
					//todo logs
					return null;
				}
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} getTaskTypeInforById ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} getTaskTypeInforById ex`);
				return null;
			}
		};

		const [languageValue, setLanguageValue] = useState('--');
		const [fromLanguage, setFromLanguage] = useState('--');

		const getTranslation = async () => {
			let taskService = new TaskService();

			let content = editorValue?.replace(/<p>/g, '').replace(/<\/p>/g, '');

			try {
				let responseRequest = await taskService.translateOutbound('', languageValue, content);

				return responseRequest;
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} getTranslation ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} getTranslation ex`);
				setIsModalTranslateLoading(false);
				setFromLanguage('--');
				setTranslatedValue(await TPI18N.GetText(resourceTranslationPreviewModal, 'translateError'));
				return null;
			}
		};

		// useEffect(() => {
		// 	if (mode.toUpperCase() === 'EDIT') {
		// 		if (editorValue !== "") {
		// 			let response = getTranslation();
		// 			response.then(result => {
		// 				if (result) {
		// 					setFromLanguage(result[0]);
		// 					setTranslatedValue(result[2]);
		// 				}
		// 			});
		// 		}
		// 	}
		// }, [editorValue]);

		const getTaskInforById = async (idTask: number) => {
			let taskService = new TaskService();
			let expectedCodes: Array<number> = [200];

			try {
				let responseRequest = await taskService.getTaskById(idTask, false, true, expectedCodes);
				if (responseRequest && responseRequest.length >= 1) {
					return responseRequest[0];
				} else {
					//todo logs
					return null;
				}
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} getTaskInforById ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} getTaskInforById ex`);
				return null;
			}
		};

		const getAdditionalDataForTaskId = async () => {
			let taskService = new AdditionalDataTaskTypeService();
			let additionalDataService = new AdditionalDataService();
			let expectedCodes: Array<number> = [200, 404];
			let expectedCodes2: Array<number> = [200];
			let newAddtionalDataValues: Array<TPKeyValue> = [];
			let additionalDataInfo: AdditionalDataViewModel;

			try {
				let responseRequest = await taskService.getByCaseTaskId(taskIdToView, false, true, expectedCodes);

				if (responseRequest && responseRequest.length >= 1) {
					//for each additional data get other properties and
					//push elements on newAddtionalDataValues and mutate state
					for (let i = 0; i <= responseRequest.length - 1; i++) {
						additionalDataInfo = await additionalDataService.getAdditionalDataById(
							responseRequest[i].additionalDataId,
							false,
							true,
							expectedCodes2
						);
						additionalDataInfo.order = responseRequest[i].order;
						additionalDataInfo.isMandatory = responseRequest[i].isMandatory;
						let oneKeyValue: TPKeyValue = {
							key: responseRequest[i].additionalDataId,
							value: responseRequest[i].value,
							value2: additionalDataInfo
						};
						newAddtionalDataValues.push(oneKeyValue);
					}
					//mutate additional data state
					setAdditionalDataValues(newAddtionalDataValues);
				} else {
					//todo logs
				}
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} getAdditionalDataForTaskId ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} getAdditionalDataForTaskId ex`);
			}
		};

		const handleFromValueChange = (e: any) => {
			let fromValue: string = e.target.value;
			setFromValue(fromValue);
			setFromValueErrorMessage('');
		};
		const handleTemplateLanguageValueChange = (e: any) => {
			let fromValue: string = e.target.value;
			setTemplateLanguageValue(fromValue);
			setTemplateLanguagevalueErrorMessage('');
		};
		const handleReplyToValueChange = async (e: any) => {
			let fromValue: string = e.target.value;
			setReplyToValue(fromValue);
			setShowReplyAll(fromValue !== '--');
			setReplyToValueErrorMessage('');

			let taskService = new TaskService();
			let expectedCodes: Array<number> = [200];

			try {
				let responseRequest = await taskService.getInboundMailboxReadById(e.target.value, false, true, expectedCodes);

				if (responseRequest.length > 0) {
					setToValue(prevToValue =>
						prevToValue ? prevToValue + '; ' + responseRequest?.[0]?.addressTO : responseRequest?.[0]?.addressTO
					);
					setCCValue(prevCCValue =>
						prevCCValue ? prevCCValue + '; ' + responseRequest?.[0]?.addressCC : responseRequest?.[0]?.addressCC
					);
					setBccValue(prevBccValue =>
						prevBccValue ? prevBccValue + '; ' + responseRequest?.[0]?.addressBCC : responseRequest?.[0]?.addressBCC
					);

					// setEditorValue(prevEditorValue => prevEditorValue + responseRequest?.responseData?.renderedBody)

					// setIsToValue(responseRequest?.responseRequest?.[0]?.addressTO?.length > 0 ? true : false)
					setIsCCValue(responseRequest?.[0]?.addressCC?.length > 0 ? true : false);
					setIsBccValue(responseRequest?.[0]?.addressBCC?.length > 0 ? true : false);

					if (fromValue !== '--') {
						// setEditorValue(prevEditorValue => prevEditorValue ? prevEditorValue + "<p><br></p><p>_____________________________</p>" + responseRequest?.[0]?.htmlBody : + "<p><br></p><p>_____________________________</p>" + responseRequest?.[0]?.htmlBody);
						setEditorValue(prevEditorValue =>
							prevEditorValue
								? `${prevEditorValue}<p><br></p><p>_____________________________</p>${responseRequest?.[0]?.htmlBody}`
								: `<p><br></p><p>_____________________________</p>${responseRequest?.[0]?.htmlBody}`
						);
					}
				}
			} catch (error) {
				// ... error handling ...
				if (fromValue === '--') {
					let editorValueData = editorValue;
					const searchTerm = '<p><br></p><p>_____________________________</p>';
					const startIndex = editorValueData.indexOf(searchTerm);
					if (startIndex !== -1) {
						// editorValueData = editorValueData.substring(startIndex + searchTerm.length);
						const newEditorValue = editorValue.substring(0, startIndex);
						setEditorValue(newEditorValue);
					}
				}

				console.error('error', error);
			}
		};

		const getSubjectTranslation = async (content: string) => {
			let taskService = new TaskService();

			try {
				let responseRequest = await taskService.translateOutbound('', languageValue, content);

				return {
					responseResult: true,
					responseData: responseRequest
				};
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} getTranslation ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} getTranslation ex`);
				return null;
			}
		};

		const handleToValueChange = (e: any) => {
			let toValue: string = e.target.value;
			setToValue(toValue);
			setToValueErrorMessage('');
		};
		const handleCCValueChange = (e: any) => {
			let ccValue: string = e.target.value;
			setCCValue(ccValue);
			setCCValueErrorMessage('');
		};
		const handleBccValueChange = (e: any) => {
			let bccValue: string = e.target.value;
			setBccValue(bccValue);
			setBccValueErrorMessage('');
		};
		const handleSubjectValueChange = (e: any) => {
			let subjectValue: string = e.target.value;
			setSubjectValue(subjectValue);
			setSubjectValueErrorMessage('');

			if (useTranslation) {
				let response = getSubjectTranslation(subjectValue);

				response.then(data => {
					if (data) {
						console.log('response data', data.responseData);

						setSubjectTranslation(data.responseData);
					}
				});
			}
		};

		const handleShowCc = () => {
			setIsShowCc(!isShowCc);
		};
		const handleShowBcc = () => {
			setIsShowBcc(!isShowBcc);
		};
		// const handleChangeEditor = () => {
		//   setEditorErrorMessage("");
		//   if (editorRef.current) {
		//     if (editorRef.current.getContent()) {
		//       setHasBeenModified(true);
		//     }
		//     else {
		//       setHasBeenModified(false);
		//     }
		//   }
		// };

		const handleCloseTask = () => {
			if (mode === 'EDIT') {
				if (hasBeenModified) {
					closeTaskCallBack(taskIdToView, true);
					return;
				}
			}
			closeTaskCallBack(taskIdToView, false);
		};
		const handleOnchangeBoolValue = () => {
			// let newValue: boolean = !additionalDataBoolValue;
			// setAdditionalDataBoolValue(newValue);
			// if (onValueChange) {
			//   onValueChange(newValue ? "1" : "0", idControl);
			// }
		};

		const handleOnAttachmentValueChange = (newValue: string, propertyName: string) => {
			let newCaseAttachmentState = { ...caseAttachmentState };
			newCaseAttachmentState[propertyName] = newValue;
			newCaseAttachmentState[`${propertyName}ErrorMessage`] = '';
			setCaseAttachmentState(newCaseAttachmentState);
		};
		const handleUploadFilesDraggedChange = (filesList: any) => {
			setAttachment(filesList);
		};

		const handleOnDeleteAttachment = (itemName: string) => {
			let newCaseAttachmentState = { ...caseAttachmentState };
			let newFilesDraggedList = [...newCaseAttachmentState.filesDraggedList];
			newFilesDraggedList = newFilesDraggedList.filter((x: any) => x.Filename !== itemName);
			newCaseAttachmentState.filesDraggedList = newFilesDraggedList;
			setCaseAttachmentState(newCaseAttachmentState);
		};
		const handleInternalReportNotForSending = () => {
			setIsInternalReportNotForSending(!isInternalReportNotForSending);
			setIsReplyToAll(false);
			setIsUseTemplate(false);
			setIsShowCc(false);
			setIsShowBcc(false);
		};
		const handleReplyToAll = () => {
			setIsReplyToAll(!isReplyToAll);
		};

		const handleSendCase = () => {
			setIsSendCase(!isSendCase);

			if (isSendCase === true && isSendTasks === true) {
				setIsSendTasks(false);
			}
		};
		const handleSaveInCase = (blobId: string) => {
			let newCaseAttachmentState = { ...caseAttachmentState };
			let newFilesDraggedList = [...newCaseAttachmentState.filesDraggedList];

			// Use map to update the SaveInCase property of the item
			newFilesDraggedList = newFilesDraggedList.map(item => {
				if (item.BlobId === blobId) {
					return {
						...item,
						SaveInCase: !item.SaveInCase
					};
				}
				return item;
			});
			// Update the state with the modified filesDraggedList
			newCaseAttachmentState.filesDraggedList = newFilesDraggedList;
			setCaseAttachmentState(newCaseAttachmentState);
		};

		// const handleAddCaseExistAttacment = (item: any) => {
		//   setAddCaseExistAttachment(prevState => {
		//     return prevState.map(i => {
		//       if (i.BlobId === item.BlobId) {
		//         return {
		//           ...i,
		//           SaveInCase: !i.SaveInCase
		//         };
		//       }
		//       return i;
		//     });
		//   });
		// };

		// make an use state that is an empty array

		const handleAddCaseExistAttacment = (item: any) => {
			setAddCaseExistAttachment(prevState => {
				let newCaseAttachmentState = { ...caseAttachmentState };
				let newExistFilesDraggedList = [...newCaseAttachmentState.existFilesDraggedList];

				const updatedPrevState = prevState.map(i => {
					if (i.BlobId === item.BlobId) {
						// Toggle the SaveInCase value
						const updatedItem = {
							...i,
							SaveInCase: !i.SaveInCase
						};
						// If SaveInCase is true, add the item to newExistFilesDraggedList
						if (updatedItem.SaveInCase) {
							newExistFilesDraggedList.push(updatedItem);
						} else {
							// If SaveInCase is false, remove the item from newExistFilesDraggedList
							newExistFilesDraggedList = newExistFilesDraggedList.filter(file => file.BlobId !== updatedItem.BlobId);
						}
						return updatedItem;
					}
					return i;
				});

				// Update state after all modifications
				setCaseAttachmentState({
					...newCaseAttachmentState,
					existFilesDraggedList: newExistFilesDraggedList
				});

				return updatedPrevState;
			});
		};

		// const handleAddCaseExistAttacment = (blobId:any) => {
		//   setAddCaseExistAttachment(prevData => prevData.map(item => {
		//     if (item.BlobId === blobId) {
		//       // Toggle the SaveInCase property
		//       const updatedItem = { ...item, SaveInCase: !item.SaveInCase };
		//       // If SaveInCase is true, add the item to the array
		//       if (updatedItem.SaveInCase) {
		//         return updatedItem;
		//       } else {
		//         // If SaveInCase is false, filter out the item from the array
		//         return null;
		//       }
		//     }
		//     return item;
		//   }).filter(Boolean)); // Remove null items from the array
		// };

		const [isSelectAll, setIsSelectAll] = useState(false);

		// Function to handle select all checkbox change
		// const handleSelectAllChange = () => {

		//   const newSelectAllValue = !isSelectAll;
		//   setIsSelectAll(newSelectAllValue);

		//   if (newSelectAllValue) {
		//     const formattedResponse = addCaseExistAttachment.map(item => ({
		//       BlobId: item.BlobId,
		//       Filename: item.Filename,
		//       ExtensionName: item.ExtensionName,
		//       Sender: item.Sender,
		//       Description: item.Description,
		//       Guid_USER: item.Guid_USER,
		//       SaveInCase: true
		//     }));
		//     setAddCaseExistAttachment(formattedResponse);
		//   } else if (!newSelectAllValue) {
		//     const formattedResponse = addCaseExistAttachment.map(item => ({
		//       BlobId: item.BlobId,
		//       Filename: item.Filename,
		//       ExtensionName: item.ExtensionName,
		//       Sender: item.Sender,
		//       Description: item.Description,
		//       Guid_USER: item.Guid_USER,
		//       SaveInCase: false
		//     }));
		//     setAddCaseExistAttachment(formattedResponse);
		//   }
		// };

		const handleSelectAllChange = () => {
			const newSelectAllValue = !isSelectAll;
			setIsSelectAll(newSelectAllValue);

			const formattedResponse = addCaseExistAttachment.map(item => ({
				...item,
				SaveInCase: newSelectAllValue
			}));

			setAddCaseExistAttachment(formattedResponse);

			// Update state for existFilesDraggedList
			const newExistFilesDraggedList = newSelectAllValue ? formattedResponse : [];
			setCaseAttachmentState(prevState => ({
				...prevState,
				existFilesDraggedList: newExistFilesDraggedList
			}));
		};
		const handleSendTasks = () => {
			setIsSendTasks(!isSendTasks);
		};

		const handleCurrentTaskReport = (): string => {
			let data = '';
			// console.log(emailStatus);

			if (currentAccordionsEmailMenu.TRANSLATION == emailStatus) {
				if (currentTask?.report) data = currentTask?.report;
			} else {
				data = originalEmail;
			}
			return data;
		};

		const handleUseTemplate = () => {
			setIsUseTemplate(!isUseTemplate);
		};

		const [isShownModalTranslate, setIsShownModalTranslate] = useState(false);
		const [isModalTranslateLoading, setIsModalTranslateLoading] = useState(false);

		const handleSaveTask = async () => {
			let inputDTO: TaskSendMailReportInputDTO;
			let atLeastOneError: boolean = false;
			let newTempAdditionalDataValues: Array<TEmpAdditionalDataCaseViewModel> = [];
			let comments: string = '';
			let tEmpAttachmentsTaskArray = [
				...caseAttachmentState.filesDraggedList,
				...caseAttachmentState.existFilesDraggedList
			];
			var invalidEmailErrorMessage: string = await TPI18N.GetText(
				TPGlobal.globalResourceSet,
				'invalidEmailErrorMessage'
			);

			sendTracking();

			if (!isInternalReportNotForSending) {
				if (fromValue.trim() == '--') {
					setFromValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorFromMessage'));
					atLeastOneError = true;
				}

				// if (replyToValue.trim() == "--") {
				//   setReplyToValueErrorMessage(await TPI18N.GetText(resourceSet, "ErrorReplyToMessage"));
				//   atLeastOneError = true;
				// }

				if (isUseTemplate) {
					if (templateLanguageValue.trim() == '--') {
						setTemplateLanguagevalueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorTemplateLanguageMessage'));
						atLeastOneError = true;
					}

					if (modalTemplateScreenState?.newTemplate.length === 0) {
						let newmodalTemplateScreenState = { ...modalTemplateScreenState };
						newmodalTemplateScreenState.newTemplateErrorMessage = await TPI18N.GetText(
							resourceSet,
							'ErrorTemplateErrorMessage'
						);
						setModalTemplateScreenState(newmodalTemplateScreenState);
						atLeastOneError = true;
					}
				}

				if (toValue.trim() == '') {
					setToValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorToMessage'));
					atLeastOneError = true;
				} else if (toValue && toValue.trim() !== '') {
					if (toValue.includes(';')) {
						toValue.split(';').map(item => {
							if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
								setToValueErrorMessage(invalidEmailErrorMessage);
								return false;
							}
						});
					} else {
						if (!TPGlobal.regularExpressionCheckEmail.test(toValue)) {
							setToValueErrorMessage(invalidEmailErrorMessage);
							return false;
						}
					}
				}

				if (ccValue && ccValue.trim() !== '') {
					if (ccValue.includes(';')) {
						ccValue.split(';').map(item => {
							if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
								setCCValueErrorMessage(invalidEmailErrorMessage);
								return false;
							}
						});
					} else {
						if (!TPGlobal.regularExpressionCheckEmail.test(ccValue)) {
							setCCValueErrorMessage(invalidEmailErrorMessage);
							return false;
						}
					}
				}

				if (bccValue && bccValue.trim() !== '') {
					if (bccValue.includes(';')) {
						bccValue.split(';').map(item => {
							if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
								setBccValueErrorMessage(invalidEmailErrorMessage);
								return false;
							}
						});
					} else {
						if (!TPGlobal.regularExpressionCheckEmail.test(bccValue)) {
							setBccValueErrorMessage(invalidEmailErrorMessage);
							return false;
						}
					}
				}

				if (subjectValue.trim() == '') {
					setSubjectValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorSubjectMessage'));
					atLeastOneError = true;
				}

				const filterData = editorValue.replace(/(<([^>]+)>)/gi, '');

				if (filterData.length > 0) {
					setIsMessageError(false);
				} else {
					setIsMessageError(true);
				}

				// if (editorRef.current.props.value) {
				//   comments = editorRef.current.props.value;
				//   comments = (comments === null ? "" : comments.trim());

				//   if (currentTaskType?.isCommentRequired && comments === "") {
				//     setEditorErrorMessage(await TPI18N.GetText(resourceSet, "ErrorNoComments"));
				//     atLeastOneError = true;
				//   }
				//   else if (currentTaskType?.isCommentRequired && TPGlobal.TPSanitizeWithoutLinks(comments).trim() === "") {
				//     comments = TPGlobal.TPSanitizeWithoutLinks(comments).trim();
				//     setEditorErrorMessage(await TPI18N.GetText(resourceSetTaskViewer, "ErrorNoComments"));
				//     atLeastOneError = true;
				//   }
				//   else if (currentTaskType?.isCommentRequired && TPGlobal.RemoveHtmlTinyWhiteSpaces(comments) === "") {
				//     setEditorErrorMessage(await TPI18N.GetText(resourceSetTaskViewer, "ErrorNoComments"));
				//     atLeastOneError = true;
				//   }

				//validate additional data

				if (!validateAdditonalData()) {
					atLeastOneError = true;
				}

				if (atLeastOneError) {
					return;
				}
				newTempAdditionalDataValues = getFinalAdditonalDataValues();
				setSaveButtonIsActive(true);
				// }
			}

			console.log('comments', comments);
			inputDTO = {
				useRPCMethod: true,
				id: taskIdToView,
				report: TPGlobal.stringToUTF8(editorValue).toString(),
				decisionPath: '',
				escalatedTaskReport: '',
				typistGuidUser: TPGlobal.currentUserGuid,
				// UseRPCMethod: true,
				tEmpAdditionalDataTask: isInternalReportNotForSending === true ? [] : newTempAdditionalDataValues,
				fromEmailAccountId: isInternalReportNotForSending === true ? '' : fromValue,
				to: isInternalReportNotForSending === true ? '' : toValue,
				cc: isInternalReportNotForSending === true ? '' : ccValue,
				bcc: isInternalReportNotForSending === true ? '' : bccValue,
				subject: TPGlobal.stringToUTF8(subjectValue).toString(),
				internalReportNotForSending: isInternalReportNotForSending,
				replyTo: isInternalReportNotForSending === true ? '' : replyToValue,
				replyToAll: isReplyToAll,
				useTemplate: isUseTemplate,
				templateLanguageId:
					isInternalReportNotForSending === true && isUseTemplate === true ? templateLanguageValue : '',
				templateId:
					isInternalReportNotForSending === true && isUseTemplate === true
						? modalTemplateScreenState?.newTemplate[0]?.key
						: '',
				sendCase: isSendCase,
				sendTasks: isSendTasks,
				tEmpAttachmentsTask: isInternalReportNotForSending === true ? [] : tEmpAttachmentsTaskArray
			};
			saveTaskCallBack(inputDTO, true);
		};

		const [useTranslation, setUseTranslation] = useState(false);

		const handleLanguageChange = (event: any) => {
			let translateValue: string = event.target.value;
			setLanguageValue(translateValue);
		};

		const handleUseTranslation = () => {
			setUseTranslation(!useTranslation);
		};

		const [acceptLabel, setAcceptLabel] = useState('Ok');

		const loadResourcesAndIconList = async () => {
			setAcceptLabel(await TPI18N.GetText(resourceSet, 'AcceptLabel'));
		};

		const sendTracking = async () => {
			let taskService = new TaskService();

			let DTO = {
				CaseId: currentTask?.caseId,
				TaskId: currentTask?.id,
				InboundMailboxReadID: mailboxID,
				ResultType: 'Success',
				LanguageFrom: fromLanguage,
				LanguageTo: languageValue,
				JsonResult: translatedValue,
				Guid_USER: TPGlobal.currentUserGuid
			};

			console.log("DTO", DTO);
			

			try {
				let responseRequest = await taskService.trackOutbound(DTO);
				return true;
			} catch (error) {
				TPLog.Log(`Error ${componentFileName} sendTracking ex`, TPLogType.ERROR, error);
				console.error(`Error ${componentFileName} sendTracking ex`);
				return false;
			}
		};

		const handleCallbackAnser = (status: boolean, callbackData: any) => {
			setIsShownModalTranslate(false);

			if (status) {
				handleSaveTask();
			}
		};

		useEffect(() => {
			let firstSplit: Array<any> = [];
			let languageList: Array<any> = [
				{
					languageCode: '',
					languageName: '--'
				}
			];

			firstSplit = TPGlobal.StoryFaiAvailableLanguages.split('|');

			for (let i = 0; i < firstSplit.length; i++) {
				let secondSplit: any[] = [];
				secondSplit = firstSplit[i].split('$');
				languageList.push({
					languageCode: secondSplit[0],
					languageName: secondSplit[1]
				});
			}

			setStoryFaiAvailableLanguages(languageList);
		}, []);

		const handleTracking = async () => {
			let atLeastOneError: boolean = false;
			var invalidEmailErrorMessage: string = await TPI18N.GetText(TPGlobal.globalResourceSet, "invalidEmailErrorMessage");

			if (useTranslation) {
				if (fromValue.trim() == '--') {
					setFromValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorFromMessage'));
					atLeastOneError = true;
				}

				if (isUseTemplate) {
					if (templateLanguageValue.trim() == '--') {
						setTemplateLanguagevalueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorTemplateLanguageMessage'));
						atLeastOneError = true;
					}

					if (modalTemplateScreenState?.newTemplate.length === 0) {
						let newmodalTemplateScreenState = { ...modalTemplateScreenState };
						newmodalTemplateScreenState.newTemplateErrorMessage = await TPI18N.GetText(
							resourceSet,
							'ErrorTemplateErrorMessage'
						);
						setModalTemplateScreenState(newmodalTemplateScreenState);
						atLeastOneError = true;
					}
				}

				if (toValue.trim() == '') {
					setToValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorToMessage'));
					atLeastOneError = true;
				} else if (toValue && toValue.trim() !== '') {
					if (toValue.includes(';')) {
						toValue.split(';').map(item => {
							if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
								setToValueErrorMessage(invalidEmailErrorMessage);
								return false;
							}
						});
					} else {
						if (!TPGlobal.regularExpressionCheckEmail.test(toValue)) {
							setToValueErrorMessage(invalidEmailErrorMessage);
							return false;
						}
					}
				}

				if (ccValue && ccValue.trim() !== '') {
					if (ccValue.includes(';')) {
						ccValue.split(';').map(item => {
							if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
								setCCValueErrorMessage(invalidEmailErrorMessage);
								return false;
							}
						});
					} else {
						if (!TPGlobal.regularExpressionCheckEmail.test(ccValue)) {
							setCCValueErrorMessage(invalidEmailErrorMessage);
							return false;
						}
					}
				}

				if (bccValue && bccValue.trim() !== '') {
					if (bccValue.includes(';')) {
						bccValue.split(';').map(item => {
							if (!TPGlobal.regularExpressionCheckEmail.test(item)) {
								setBccValueErrorMessage(invalidEmailErrorMessage);
								return false;
							}
						});
					} else {
						if (!TPGlobal.regularExpressionCheckEmail.test(bccValue)) {
							setBccValueErrorMessage(invalidEmailErrorMessage);
							return false;
						}
					}
				}

				if (subjectValue.trim() == '') {
					setSubjectValueErrorMessage(await TPI18N.GetText(resourceSet, 'ErrorSubjectMessage'));
					atLeastOneError = true;
				}

				const filterData = editorValue.replace(/(<([^>]+)>)/gi, '');

				if (filterData.length > 0) {
					setIsMessageError(false);
				} else {
					setIsMessageError(true);
				}

				if (!validateAdditonalData()) {
					atLeastOneError = true;
				}

				if (atLeastOneError) {
					return;
				}

				setIsModalTranslateLoading(true);

				if (mode.toUpperCase() === 'EDIT') {
					if (editorValue !== "") {
						let response = getTranslation();
						response.then(result => {
							if (result) {
								setFromLanguage(result[0]);
								setTranslatedValue(result[2]);
								
								setIsModalTranslateLoading(false);
							}
						});
					}
				}
				setIsShownModalTranslate(true);
			} else {
				handleSaveTask();
			}
		};

		const renderCollectMode = () => {
			let jsxElement: any;
			if (!currentTask) {
				return null;
			}

			jsxElement = (
				<div className="container-fluid">
					<div className="row">
						<div className="col d-flex justify-content-end" style={{ margin: '10px 0px' }}>
							<button
								className="btn-close"
								aria-label="Close"
								style={{ fontSize: '12px' }}
								onClick={() => handleCloseTask()}
							></button>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div>
									<TPPageTitle style={{ marginTop: '10px', marginBottom: '10px' }}>
										{currentTask.taskTypeLocalizedDescription}
									</TPPageTitle>
								</div>
								{isWorkflowTask && (
									<div>
										<div className="dropdown">
											<TPButton
												iconAtRight={true}
												dataBsToggle={true}
												type={TPButtonTypes.icon}
												onClick={() => {
													TPGlobal.foo();
												}}
												icon={TPIconTypes.moreVert}
												text={actionsLabel}
												className={'menu-button'}
											></TPButton>
											<ul className="dropdown-menu">
												{TPGlobal.globalPermissions.canReassignTask && (
													<li>
														<a
															onClick={() => handleOnActionClick(ActionTypeEnum.Reassign)}
															className="dropdown-item"
															href="#"
														>
															{reassignMenuItemLabel}
														</a>
													</li>
												)}

												{mode.toUpperCase() !== 'EDIT' && TPGlobal.globalPermissions.canRejectTask && (
													<li>
														<a
															onClick={() => handleOnActionClick(ActionTypeEnum.Reject)}
															className="dropdown-item"
															href="#"
														>
															{rejectMenuItemLabel}
														</a>
													</li>
												)}
											</ul>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<ComposeWithAIAlert caseId={caseNumber} />
					<div className="row">
						<div className="col-md-8">
							<TPCheckBox
								labelText={internalReportNotForSendingLabel}
								checked={isInternalReportNotForSending}
								onChange={(e: any) => handleInternalReportNotForSending()}
							></TPCheckBox>
						</div>

						{!isInternalReportNotForSending && (
							<div className="col-md-4">
								<TPCheckBox
									labelText={useTemplateLabel}
									checked={isUseTemplate}
									onChange={(e: any) => handleUseTemplate()}
								></TPCheckBox>
							</div>
						)}
					</div>
					{TPGlobal.EnableStoryFai === '1' && (
						<div style={{ display: 'inline-block' }}>
							<div className="box" style={{ backgroundColor: '#e4ccea', display: 'flex', flexDirection: 'column' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: '8px',
										alignContent: 'baseline',
										marginTop: '15px',
										marginLeft: '15px'
									}}
								>
									<Icon icon="ep:warn-triangle-filled" width="24" height="24" style={{ color: '#3e004e' }} />
									<p style={{ color: '#3e004e' }}>{translationFeatureLabel}</p>
									{TPGlobal.StoryFaiPreview === '1' && (
										<strong style={{ color: '#3e004e', marginRight: '15px' }}>{previewOptionLabel}</strong>
									)}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: '8px',
										alignContent: 'baseline',
										marginLeft: '15px'
									}}
								>
									<TPCheckBox
										labelText={<p style={{ color: '#3e004e' }}>{useTranslationFeatureLabel}</p>}
										checked={useTranslation}
										onChange={(e: any) => handleUseTranslation()}
									></TPCheckBox>
								</div>
								{useTranslation && (
									<div style={{ marginLeft: '15px', marginBottom: '15px', marginRight: '15px' }}>
										<TPSelect
											isMandatory={useTranslation}
											dataSource={StoryFaiAvailableLanguages.map(
												item => ({ key: item.languageCode, value: item.languageName }) as TPKeyValue
											)}
											value={languageValue}
											labelText={<strong style={{ color: '#3e004e' }}>{languageforTranslationLabel}</strong>}
											onChange={handleLanguageChange}
										></TPSelect>
									</div>
								)}
							</div>
						</div>
					)}
					{!isInternalReportNotForSending && (
						<div className="row">
							{isReplyToDataExist &&
								<div className="col-md-9">
									<TPSelect
										isMandatory={false}
										onChange={handleReplyToValueChange}
										dataSource={replyTo}
										value={replyToValue}
										labelText={replyToLabel}
										errorMessage={replyToValueErrorMessage}
									></TPSelect>
								</div>
							}
							{showReplyAll && (
								<>
									{!isInternalReportNotForSending && (
										<div className="col-md-3" style={{ marginTop: '33px' }}>
											<TPCheckBox
												// disabled={true}
												labelText={replyToAllLabel}
												checked={isReplyToAll}
												onChange={(e: any) => handleReplyToAll()}
											></TPCheckBox>
										</div>
									)}
								</>
							)}
						</div>
					)}

					{isUseTemplate && (
						<div className="row">
							<div className="col-md-6">
								<TPSelect
									isMandatory={isUseTemplate}
									onChange={handleTemplateLanguageValueChange}
									dataSource={templateLanguage}
									value={templateLanguageValue}
									labelText={templateLanguageLabel}
									errorMessage={templateLanguageValueErrorMessage}
								></TPSelect>
							</div>
							<div className="col-md-6">
								<TPAutoComplete
									isMandatory={isUseTemplate}
									labelText={templateLabel}
									onValueChange={handleTemplateChange}
									onSearch={(query: string) => {
										handleTemplateOnAutocompleteQuery(query);
									}}
									isLoading={isLoading}
									options={autocompleteTemplateOptions}
									withIcon={true}
									emptyLabel={emptyLabel}
									onKeyDown={handleTemplateOnAutocompleteKeyDown}
									selected={modalTemplateScreenState.newTemplate}
									errorMessage={modalTemplateScreenState.newTemplateErrorMessage}
									downArrowClick={handleAutoCompleteTemplatepNClick}
								></TPAutoComplete>
							</div>
						</div>
					)}
					{!isInternalReportNotForSending && (
						<div className="row">
							<div className="col-md-12">
								<TPSelect
									isMandatory={!isInternalReportNotForSending}
									id="IdSelect"
									onChange={handleFromValueChange}
									dataSource={fromKeyValue}
									value={fromValue}
									labelText={fromLabel}
									errorMessage={fromValueErrorMessage}
								></TPSelect>
							</div>
						</div>
					)}
					{!isInternalReportNotForSending && (
						<div className="row position-relative">
							<div className="col-md-9">
								<TPTextBox
									id="IdTextBox"
									labelText={toLabel}
									isMandatory={!isInternalReportNotForSending}
									value={toValue}
									onChange={handleToValueChange}
									maxLength={200}
									errorMessage={toValueErrorMessage}
								/>
							</div>
							<div className="col-md-3 d-flex" style={{ marginTop: '30px', height: '30px' }}>
								<div className="d-flex">
									<div>{ccButtonLabel}</div>
									<div className="mt-2 mx-2" style={{ color: isReplyToAll ? '#000' : '#A00095' }}>
										<TPIcon iconType={TPIconTypes.email} onClick={handleShowCc} />
									</div>
								</div>
								<div className="d-flex">
									<div>{bccButtonLabel}</div>
									<div className="mt-2 mx-2" style={{ color: isReplyToAll ? '#000' : '#A00095' }}>
										<TPIcon iconType={TPIconTypes.email} onClick={handleShowBcc} />
									</div>
								</div>
							</div>
						</div>
					)}

					{!isInternalReportNotForSending && (
						<div className="row">
							{/* {isReplyToAll === true ?
              <div className="col-md-6">
                <TPTextBox
                  id="IdTextBox"
                  labelText={ccLabel}
                  isMandatory={isReplyToAll}
                  value={ccValue}
                  onChange={handleCCValueChange}
                  maxLength={200}
                  errorMessage={ccValueErrorMessage}
                />
              </div> : isShowCc === true ? <div className="col-md-6">
                <TPTextBox
                  id="IdTextBox"
                  labelText={ccLabel}
                  isMandatory={isReplyToAll}
                  value={ccValue}
                  onChange={handleCCValueChange}
                  maxLength={200}
                  errorMessage={ccValueErrorMessage}
                />
              </div> : isCcValue === true ? <div className="col-md-6">
                <TPTextBox
                  id="IdTextBox"
                  labelText={ccLabel}
                  isMandatory={isReplyToAll}
                  value={ccValue}
                  onChange={handleCCValueChange}
                  maxLength={200}
                  errorMessage={ccValueErrorMessage}
                />
              </div> : ""
            } */}

              {isReplyToAll || isShowCc || isCcValue ? (
                <div className="col-md-6">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={ccLabel}
                    isMandatory={isReplyToAll}
                    value={ccValue}
                    onChange={handleCCValueChange}
                    maxLength={200}
                    errorMessage={ccValueErrorMessage}
                  />
                </div>
              ) : null}

              {isReplyToAll || isShowBcc || isBccValue ? (
                <div className="col-md-6">
                  <TPTextBox
                    id="IdTextBox"
                    labelText={bccLabel}
                    isMandatory={isReplyToAll}
                    value={bccValue}
                    onChange={handleBccValueChange}
                    maxLength={200}
                    errorMessage={bccValueErrorMessage}
                  />
                </div>
              ) : null}
            </div>
          )}

          {!isInternalReportNotForSending && (
            <div className="row mb-2">
              <div className="col-md-12">
                <TPTextBox
                  id="IdTextBox"
                  labelText={subjectLabel}
                  isMandatory={!isInternalReportNotForSending}
                  value={subjectValue}
                  onChange={handleSubjectValueChange}
                  maxLength={200}
                  errorMessage={subjectValueErrorMessage}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              {/* Quill Editor */}
              <div style={{ marginTop: "12px", marginBottom: "6px" }}>
                {messageLabel}
                {!isInternalReportNotForSending && (
                  <span style={{ color: "#FF0000" }}>*</span>
                )}
              </div>
              <div className="text-editor">
                <div id="toolbar">
                  <select className="ql-header ql-picker" />
                  <button className="ql-bold" />
                  <button className="ql-italic" />
                  <button className="ql-underline" />
                  <select className="ql-align" />
                  <button className="ql-list" value="ordered" />
                  <button className="ql-list" value="bullet" />
                  <button className="ql-indent" value="-1" />
                  <button className="ql-indent" value="+1" />
                  <select className="ql-color" />
                  <select className="ql-background" />
                  <button className="ql-clean" />
                  <button className="ql-help" />
                </div>
                <ReactQuill
                  ref={(el) => (editorRef.current = el)}
                  value={editorValue}
                  onChange={handleChange}
                  placeholder={writeSomethingHereLabel}
                  modules={modules}
                  formats={formats}
                  theme={"snow"}
                  style={{ height: "140px" }}
                />
                {isMessageError && (
                  <div
                    style={{
                      color: "#DC3545",
                      fontSize: "14px",
                      margin: "4px 0px 0px 0px",
                    }}
                  >
                    {pleaseEnterAMessageLabel}
                  </div>
                )}
              </div>
              <span style={{ color: "#dc3545", fontSize: "14px" }}>
                {editorErrorMessage}
              </span>
              {isSownModalAttachment && (
                <TPModal
                  modalState={{
                    titleModal: newAttachmentLabel,
                    acceptLabel: okLabel,
                    cancelLabel: cancelLabel,
                    callBackAnswer: handleCallBackNewAttachmentModal,
                    callBackData: null,
                    isShown: isSownModalAttachment,
                    modalWidth: ModalSizeEnum.MODALMD,
                  }}
                >
                  {/* Attechment */}
                  {/* <div>{attachmentLabel}</div> */}
                  <TPLoadingOverlay active={isLoadingScreenModalAttachment}>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <TPTextBox
                            id="IdTextBox"
                            onChange={(e: any) =>
                              handleOnAttachmentValueChange(
                                e.target.value,
                                "sender"
                              )
                            }
                            value={caseAttachmentState.sender}
                            isMandatory={true}
                            labelText={senderLabel}
                            errorMessage={
                              caseAttachmentState.senderErrorMessage
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <TPTextArea
                            id="IdTextArea"
                            onChange={(e: any) =>
                              handleOnAttachmentValueChange(
                                e.target.value,
                                "description"
                              )
                            }
                            value={caseAttachmentState.description}
                            isMandatory={false}
                            labelText={descriptionLabel}
                            errorMessage={
                              caseAttachmentState.descriptionErrorMessage
                            }
                            rows={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col p-0">
                        <FileUploader
                          label={
                            attachment[0]?.name
                              ? attachment[0]?.name
                              : dropFilesHereLabel
                          }
                          multiple={true}
                          handleChange={handleUploadFilesDraggedChange}
                          UploadButtonText={uploadFileButtonLabel}
                        />

                        {/* {caseAttachmentState.notFilesErrorMessage && (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "0.875em",
                                color: "#DC3545",
                              }}
                            >
                              {caseAttachmentState.notFilesErrorMessage}
                            </div>
                          </div>
                        )}

                        {caseAttachmentState.filesDraggedList &&
                          caseAttachmentState.filesDraggedList.length >= 1 && (
                            <div className="mt-2">
                              <h5 className="tpbold tp-primary-color">
                                {"tabAttachmentsLabel"}
                              </h5>
                              <hr />
                              <ul className="new_case__attachments-container">
                                {caseAttachmentState.filesDraggedList.length >= 1 &&
                                  caseAttachmentState.filesDraggedList.map(function (
                                    item: any,
                                    index
                                  ) {
                                    return (
                                      <li
                                        className="d-flex mt-1"
                                        key={"lifile" + index.toString()}
                                      >

                                        <span>{item.Filename}</span>
                                        <span className="new_case__attachments-btn-delete">
                                          <TPIcon
                                            className="tphover"
                                            key={"deletefile" + index.toString()}
                                            style={{
                                              fontSize: "25px",
                                              cursor: "pointer",
                                            }}
                                            iconType={TPIconTypes.delete}
                                            onClick={() =>
                                              handleOnDeleteAttachment(item.Filename)
                                            }
                                          />
                                        </span>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          )} */}
                      </div>
                    </div>
                  </TPLoadingOverlay>
                </TPModal>
              )}

              {/* <div style={{ marginTop: "15px", marginBottom: "4px" }}>Attach new File</div> */}

              {/* <div className="mt-3 mb-3">
                <TPButton type={TPButtonTypes.primary} onClick={() => { }}> Attach new File</TPButton>
              </div> */}
              {!isInternalReportNotForSending && (
                <>
                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "5px",
                      fontSize: "22px",
                      textTransform: "uppercase",
                    }}
                  >
                    {attachmentsLable}
                  </div>
                  <div
                    style={{ border: "1px solid gainsboro", padding: "10px" }}
                  >
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-md-3">
                        <TPCheckBox
                          // disabled={true}
                          labelText={sendCaseLabel}
                          checked={isSendCase}
                          onChange={(e: any) => handleSendCase()}
                        ></TPCheckBox>
                      </div>

                      <div className="col-md-3">
                        <TPCheckBox
                          disabled={!isSendCase}
                          labelText={sendTasksLabel}
                          checked={isSendTasks}
                          onChange={(e: any) => handleSendTasks()}
                        ></TPCheckBox>
                      </div>
                    </div>
                    <div className="fw-bold mt-3 mb-3">
                      {attachNewFileLabel}{" "}
                      <button
                        className="ql-handlePopupClick p-0"
                        style={{ border: "none", background: "transparent" }}
                        onClick={handleModalPopup}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-paperclip"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                          </svg>
                        </span>
                      </button>
                    </div>

                    <table className="w-100">
                      <tr
                        style={{
                          border: "1px solid #dddddd",
                          padding: "8px 12px",
                          fontSize: "14px",
                        }}
                      >
                        <th
                          className="col-2 ps-3 "
                          style={{ padding: "12px 0px" }}
                        >
                          {deleteLabel}
                        </th>
                        <th
                          className="col-8 ps-3  border-start"
                          style={{ padding: "12px 0px" }}
                        >
                          {fileLabel}
                        </th>
                        <th
                          className="col-2 ps-1  border-start"
                          style={{ padding: "12px 0px" }}
                        >
                          {saveInCaseLabel}
                        </th>
                      </tr>
                      {caseAttachmentState.filesDraggedList.length >= 1 &&
                        caseAttachmentState.filesDraggedList.map(function (
                          item: any,
                          index
                        ) {
                          return (
                            <tr
                              style={{ border: "1px solid #dddddd" }}
                              key={"lifile" + index.toString()}
                            >
                              <td style={{ border: "1px solid #dddddd" }}>
                                <TPIcon
                                  className="tphover"
                                  key={"deletefile" + index.toString()}
                                  style={{
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                  iconType={TPIconTypes.delete}
                                  onClick={() =>
                                    handleOnDeleteAttachment(item.Filename)
                                  }
                                />
                              </td>
                              <td>{item.Filename}</td>
                              <td style={{ border: "1px solid #dddddd" }}>
                                <TPCheckBox
                                  labelText={""}
                                  id={item.BlobId}
                                  checked={item.SaveInCase}
                                  onChange={(e: any) =>
                                    handleSaveInCase(item.BlobId)
                                  }
                                ></TPCheckBox>
                              </td>
                            </tr>
                          );
                        })}
                    </table>

                    <div className="fw-bold mt-3 mb-3">
                      {addCaseAttachmentLabel}
                    </div>
                    <table className="w-100">
                      <tr style={{ border: "1px solid #dddddd" }}>
                        <td style={{ border: "1px solid #dddddd" }}>
                          <TPCheckBox
                            // disabled={true}
                            labelText={""}
                            checked={isSelectAll}
                            onChange={(e: any) => handleSelectAllChange()}
                          ></TPCheckBox>
                        </td>
                        <td
                          style={{ border: "1px solid #dddddd", width: "100%" }}
                        >
                          {allLabel}
                        </td>
                      </tr>
                      {addCaseExistAttachment.length >= 1 &&
                        addCaseExistAttachment.map(function (item: any, index) {
                          return (
                            <tr
                              style={{ border: "1px solid #dddddd" }}
                              key={"lifile" + index.toString()}
                            >
                              <td style={{ border: "1px solid #dddddd" }}>
                                <TPCheckBox
                                  labelText={""}
                                  checked={item.SaveInCase}
                                  onChange={(e: any) =>
                                    handleAddCaseExistAttacment(item)
                                  }
                                ></TPCheckBox>
                              </td>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  width: "100%",
                                }}
                              >
                                {item.Filename}
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className="row"
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              marginTop: "5px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            {renderAdditionalDataList(TPAddtionalDataUIModes.Collect)}
          </div>
          <div className="row">
            <div className="col">
              <TPPageAcceptCancelButtonsContainer
                style={{ marginBottom: "5px" }}
              >
                <TPButton
                  type={TPButtonTypes.primary}
                  onClick={handleTracking}
                  // disabled={saveButtonIsActive}
                >
                  {saveButtonLabel}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>
            </div>
          </div>
          {isShownModalTranslate && (
            <TPModal
              modalState={{
                titleModal: translationPreviewTitleModal,
                acceptLabel: acceptLabel,
                cancelLabel: cancelLabel,
                callBackAnswer: handleCallbackAnser,
                isShown: true,
                modalWidth: undefined,
              }}
            >
              <TPLoadingOverlay active={isModalTranslateLoading}>
                <div className="text-editor">
                  <div id="toolbar">
                    <select className="ql-header ql-picker" />
                    <button className="ql-bold" />
                    <button className="ql-italic" />
                    <button className="ql-underline" />
                    <select className="ql-align" />
                    <button className="ql-list" value="ordered" />
                    <button className="ql-list" value="bullet" />
                    <button className="ql-indent" value="-1" />
                    <button className="ql-indent" value="+1" />
                    <select className="ql-color" />
                    <select className="ql-background" />
                    <button className="ql-clean" />
                    <button className="ql-help" />
                  </div>
                  <ReactQuill
                    ref={(el) => (editorRef.current = el)}
                    value={translatedValue}
                    onChange={handleTranslateChange}
                    placeholder={writeSomethingHereLabel}
                    modules={modules}
                    formats={formats}
                    theme={"snow"}
                    style={{ height: "140px" }}
                  />
                  {isMessageError && (
                    <div
                      style={{
                        color: "#DC3545",
                        fontSize: "14px",
                        margin: "4px 0px 0px 0px",
                      }}
                    >
                      {pleaseEnterAMessageLabel}
                    </div>
                  )}
                </div>
              </TPLoadingOverlay>
            </TPModal>
          )}
          <div
            className="row"
            style={{
              border: "1px solid #dee2e6",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          ></div>
        </div>
      );
      return jsxElement;
    };

    const renderViewMode = () => {
      let jsxElement: any;
      if (!currentTask) return null;
      jsxElement = (
        <div className="container-fluid">
          <div className="row">
            <div
              className="col d-flex justify-content-end"
              style={{ margin: "10px 0px" }}
            >
              <button
                className="btn-close"
                aria-label="Close"
                style={{ fontSize: "12px" }}
                onClick={() => handleCloseTask()}
              ></button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <TPPageTitle
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    {currentTask.taskTypeLocalizedDescription}
                  </TPPageTitle>
                </div>
                {isWorkflowTask && (
                  <div>
                    <div className="dropdown">
                      <TPButton
                        dataBsToggle={true}
                        type={TPButtonTypes.icon}
                        onClick={() => {
                          TPGlobal.foo();
                        }}
                        icon={TPIconTypes.moreVert}
                        text={actionsLabel}
                        className={"menu-button"}
                      ></TPButton>
                      <ul className="dropdown-menu">
                        {TPGlobal.globalPermissions.canRejectTask && (
                          <li>
                            <a
                              onClick={() =>
                                handleOnActionClick(ActionTypeEnum.Reject)
                              }
                              className="dropdown-item"
                              href="#"
                            >
                              {rejectMenuItemLabel}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {currentTask.taskTypeId == taskType.receivedMail && (
            <TPGenAIHeader
              id={currentTask.taskTypeId}
              originalEmail={originalEmail}
              caseNumber={caseNumber}
            />
          )}
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {handleIsActiveBtnOriginalEmail() ? (
              // <TPButton
              // 	style={{
              // 		backgroundColor: 'lightgray',
              // 		width: '25px' /*  */,
              // 		textAlign: 'center',
              // 		justifyContent: 'center',
              // 		maxHeight: '20px'
              // 	}}
              // 	type={TPButtonTypes.icon}
              // 	onClick={() => {
              // 		handleActionBtnEmail(true);
              // 	}}
              // 	text=""
              // 	icon={handleActionBtnEmail(false) ? TPIconTypes.arrowDropDown : TPIconTypes.arrowDropUp}
              // >
              // 	{/*  <span className="material-symbols-outlined"></span> */}
              // 	{/*  <i className="bi bi-1-square-fill"></i> */}
              // </TPButton>
              <button
                type="button"
                style={{
                  ...allThemes.base.accordion.collapseButton,
                  borderRadius: "4px",
                  width: "100%",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
                onClick={() =>
                  setEmailStatus(
                    emailStatus == currentAccordionsEmailMenu.TRANSLATION
                      ? currentAccordionsEmailMenu.ORIGIN
                      : currentAccordionsEmailMenu.TRANSLATION
                  )
                }
              >
                {emailStatus == currentAccordionsEmailMenu.TRANSLATION ? (
                  <Icon icon="ph:caret-up-bold" />
                ) : (
                  <Icon icon="ph:caret-down-bold" />
                )}
              </button>
            ) : null}
          </div>
          <div className="row">
            <div className="col-12">
              <div>{reportSummaryLabel}</div>
              <div
                className="form-control"
                style={{
                  minHeight: "100px",
                  /*maxHeight: "200px",*/
                  /*backgroundColor: "#e9ecef",*/
                  overflowY: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: handleCurrentTaskReport() }}
              ></div>
            </div>
          </div>
          <div
            className="row"
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              marginTop: "5px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            {renderAdditionalDataList(TPAddtionalDataUIModes.View1)}
          </div>
          <div
            className="row"
            style={{
              border: "1px solid #dee2e6",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          ></div>
        </div>
      );
      return jsxElement;
    };

    const renderAdditionalDataList = (mode: TPAddtionalDataUIModes) => {
      return additionalDataValues.map(function (item) {
        let elementJsx;
        let index: number;
        let value: any;

        index = additionalDataValues.findIndex(
          (x: TPKeyValue) => x.key == item.key
        );
        value = additionalDataValues[index].value;
        if (mode == TPAddtionalDataUIModes.Collect) {
          elementJsx = (
            <div className="col-6 mb-2" key={"adda" + item.key}>
              <TPRenderAdditionalDataSwitch
                guidControl={taskguid}
                modeUI={mode}
                defaultValue={value}
                itemToRender={item}
                ref={(element: any) => {
                  childRefArray.current[item.key] = element;
                }}
                onChange={(
                  idControl: any,
                  newValue: string,
                  addtionalDataId: string
                ) =>
                  handleChangeAditionalDataValue(idControl, newValue, item.key)
                }
              />
            </div>
          );
        } else {
          elementJsx = (
            <div className="col-6 mb-2" key={"adda" + item.key}>
              <TPRenderAdditionalDataSwitch
                guidControl={taskguid}
                modeUI={mode}
                defaultValue={value}
                itemToRender={item}
                ref={(element: any) => {
                  childRefArray.current[item.key] = element;
                }}
              />
            </div>
          );
        }

        return elementJsx;
      });
    };

    //called before save case to validate additional data
    const validateAdditonalData = (): boolean => {
      let result: boolean = true;
      for (let i = 0; i <= additionalDataValues.length - 1; i++) {
        let element: TPKeyValue;
        element = additionalDataValues[i];
        //key: additional data id
        //value: addtional data value
        //value2: other properties
        //value3: should show

        if (childRefArray.current) {
          const keys = Object.keys(childRefArray.current);
          if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
            let controlref: any = childRefArray.current[element.key];
            if (!controlref.validateFromParent()) {
              result = false;
              break;
            }
          }
        }
      }
      return result;
    };

    //called before save case to get additional data values
    const getFinalAdditonalDataValues =
      (): Array<TEmpAdditionalDataCaseViewModel> => {
        let result: Array<TEmpAdditionalDataCaseViewModel> = [];
        for (let i = 0; i <= additionalDataValues.length - 1; i++) {
          let element: TPKeyValue;
          element = additionalDataValues[i];
          //key: additional data id
          //value: addtional data value
          //value2: other properties
          //value3: should show

          if (childRefArray.current) {
            const keys = Object.keys(childRefArray.current);
            if (keys && keys.findIndex((x: any) => x === element.key) !== -1) {
              let controlref: any = childRefArray.current[element.key];

              let stringValue: string;
              stringValue = controlref.getValueFromParent();
              if (stringValue) {
                let oneAddData: TEmpAdditionalDataCaseViewModel;
                oneAddData = {
                  additionalDataId: element.key,
                  value: stringValue,
                  isMandatory: element.value2.isMandatory,
                };
                result.push(oneAddData);
              }
            }
          }
        }
        return result;
      };

    const getHeaderValues = () => {
      let headerValues: Array<TPKeyValue> = [];
      if (currentTask) {
        headerValues.push({
          key: responsibleLabel,
          value: currentTask?.writerUserName,
        });
        headerValues.push({
          key: creationDateLabel,
          value: currentTask?.creationDateFormatted,
        });
        headerValues.push({
          key: startDateLabel,
          value: currentTask?.startDateFormatted,
        });
        headerValues.push({
          key: dueToDateLabel,
          value: currentTask?.limitDateFormatted,
          value2: true,
        });
        headerValues.push({
          key: readerLabel,
          value: currentTask?.readerUserName,
        });
      }
      return headerValues;
    };

    const renderHeader = () => {
      if (!currentTask) {
        return null;
      }

      return (
        <TPRenderKeyValues
          items={getHeaderValues()}
          size={200}
        ></TPRenderKeyValues>
      );
    };

    //handle additional data change
    const handleChangeAditionalDataValue = (
      idControl: string,
      newValue: any,
      additionalDataId: string
    ) => {
      let newAddtionalDataValues: Array<TPKeyValue> = [...additionalDataValues];
      //mutate state
      for (let i = 0; i <= newAddtionalDataValues.length - 1; i++) {
        if (newAddtionalDataValues[i].key === additionalDataId) {
          newAddtionalDataValues[i].value = newValue;
          setHasBeenModified(true);
          break;
        }
      }
      setAdditionalDataValues(newAddtionalDataValues);
    };

    const realRefreshAdditionalData = (
      newAdditionaDataToCheck: Array<TEmpAdditionalDataTaskViewModel>
    ) => {
      let newAdditionalDataValues: Array<TPKeyValue> = [
        ...additionalDataValues,
      ];
      let found: boolean = false;
      for (let i = 0; i <= newAdditionaDataToCheck.length - 1; i++) {
        for (let j = 0; j <= newAdditionalDataValues.length - 1; j++) {
          if (
            newAdditionaDataToCheck[i].additionalDataId.toUpperCase() ===
            newAdditionalDataValues[j].key.toUpperCase()
          ) {
            newAdditionalDataValues[j].value = newAdditionaDataToCheck[i].value;
            found = true;
          }
        }
      }
      if (found) {
        setAdditionalDataValues(newAdditionalDataValues);
      }
    };

    let alreadyLoadReasons: boolean = false;
    const handleOnActionClick = async (actionName: ActionTypeEnum) => {
      setIsShownActionModal(true);
      switch (actionName) {
        case ActionTypeEnum.Reassign:
          setTaskTitleModal(
            await TPI18N.GetText(
              resourceSetReassignModal,
              "ReassignTaskTitleModal"
            )
          );
          setIsLoadingModalScreen(false);
          break;
        case ActionTypeEnum.Reject:
          setTaskTitleModal(
            await TPI18N.GetText(resourceSetRejectModal, "RejectTaskTitleModal")
          );
          if (!alreadyLoadReasons) {
            await getReasonsDatalist();
            alreadyLoadReasons = true;
          }
          setIsLoadingModalScreen(false);
          break;

        default:
          break;
      }
      setCurrentActionType(actionName);
    };

    const getReasonsDatalist = async () => {
      let parametersService = new ParametersService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest =
          await parametersService.getByParentIdAndFilterIsActive(
            SystemParametersEnum.REFORETA,
            TPActiveOptions.ACTIVE.toString(),
            false,
            true,
            expectedCodes
          );
        if (responseRequest) {
          let newReasonList: Array<TPKeyValue> = responseRequest.map(
            function (item) {
              return { key: item.id, value: item.localizedDescription };
            }
          );
          newReasonList.unshift({ key: "", value: "--" });
          setReasonList(newReasonList);
        } else {
          //todo logs
          return null;
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getReasonsDatalist ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getReasonsDatalist ex`);
        return null;
      }
    };

    const handleToChange = (newSelectedValue: Array<TPKeyValue>) => {
      let newmodalReassignScreenState = { ...modalReassignScreenState };
      newmodalReassignScreenState.newResponsibleGuidUser = newSelectedValue;
      newmodalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
      setModalReassignScreenState(newmodalReassignScreenState);
    };

    const handleTemplateChange = (newSelectedValue: Array<TPKeyValue>) => {
      let newmodalTemplateScreenState = { ...modalTemplateScreenState };
      newmodalTemplateScreenState.newTemplate = newSelectedValue;
      newmodalTemplateScreenState.newTemplateErrorMessage = "";
      setModalTemplateScreenState(newmodalTemplateScreenState);
    };

    const handleToOnAutocompleteQuery = async (query: string) => {
      let tasktypeService = new UserService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        //Load users by search
        let responseRequest =
          await tasktypeService.getActiveUsersBySearchParameter(
            query,
            false,
            true,
            expectedCodes
          );
        let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return {
              key: item.userGuid,
              value: `${item.name}`,
            };
          }
        );
        setAutocompleteToOptions(newToKeyValueList);
        return newToKeyValueList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} handleToOnAutocompleteQuery ex`
        );
        return [];
      }
    };

    const handleTemplateOnAutocompleteQuery = async (query: string) => {
      let tasktypeService = new UserService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        //Load users by search
        let responseRequest = await tasktypeService.getEmailTemplatesBySearch(
          query,
          "1",
          templateLanguageValue,
          false,
          true,
          expectedCodes
        );
        let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
          function (item) {
            return {
              key: item.id,
              value: `${item.localizedDescription}`,
            };
          }
        );
        setAutocompleteTemplateOptions(newToKeyValueList);
        return newToKeyValueList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleTemplatOnAutocompleteQuery ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} handleTemplateOnAutocompleteQuery ex`
        );
        return [];
      }
    };

    const handleToOnAutocompleteKeyDown = (event: any) => {
      //left arrow 37
      //right arror 39
      //enter 13
      //home 36
      //end  35
      // if (
      //   event.keyCode != 37 &&
      //   event.keyCode != 39 &&
      //   event.keyCode != 13 &&
      //   event.keyCode != 35 &&
      //   event.keyCode != 36
      // ) {
      //   setAutocompleteToOptions([]);
      //   let newModalReassignScreenState = { ...modalReassignScreenState };
      //   newModalReassignScreenState.newResponsibleGuidUser = [];
      //   newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
      //   setModalReassignScreenState(newModalReassignScreenState);
      // }
      const inputValue = event.target.value;
      if (inputValue.length === 1) {
        handleToOnAutocompleteQuery("");
      }
    };

    const handleTemplateOnAutocompleteKeyDown = (event: any) => {
      //left arrow 37
      //right arror 39
      //enter 13
      //home 36
      //end  35
      if (
        event.keyCode != 37 &&
        event.keyCode != 39 &&
        event.keyCode != 13 &&
        event.keyCode != 35 &&
        event.keyCode != 36
      ) {
        setAutocompleteTemplateOptions([]);
        let newModalTemplateScreenState = { ...modalTemplateScreenState };
        newModalTemplateScreenState.newTemplate = [];
        newModalTemplateScreenState.newTemplateErrorMessage = "";
        setModalTemplateScreenState(newModalTemplateScreenState);
      }
    };

    const handleAutoCompleteTopNClick = async () => {
      let newTopNOptions: Array<TPKeyValue> = [];
      if (autocompleteToTopNOptions.length === 0) {
        newTopNOptions = await handleToOnAutocompleteQuery("");

        if (newTopNOptions.length >= 1) {
          //save on cache
          setAutocompleteToTopNOptions([...newTopNOptions]);
          setAutocompleteToOptions([...newTopNOptions]);
          let newModalReassignScreenState = { ...modalReassignScreenState };
          newModalReassignScreenState.newResponsibleGuidUser = [];
          newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
          setModalReassignScreenState(newModalReassignScreenState);
        }
      } else {
        //use cached values;
        setAutocompleteToOptions([...autocompleteToTopNOptions]);
        let newModalReassignScreenState = { ...modalReassignScreenState };
        newModalReassignScreenState.newResponsibleGuidUser = [];
        newModalReassignScreenState.newResponsibleGuidUserErrorMessage = "";
        setModalReassignScreenState(newModalReassignScreenState);
      }
    };

    const handleAutoCompleteTemplatepNClick = async () => {
      let newTemplatepTopNOptions: Array<TPKeyValue> = [];
      if (autocompleteTemplateTopNOptions.length === 0) {
        newTemplatepTopNOptions = await handleTemplateOnAutocompleteQuery("");

        if (newTemplatepTopNOptions.length >= 1) {
          //save on cache
          setAutocompleteTemplateTopNOptions([...newTemplatepTopNOptions]);
          setAutocompleteTemplateOptions([...newTemplatepTopNOptions]);
          let newModalTemplateScreenState = { ...modalTemplateScreenState };
          newModalTemplateScreenState.newTemplate = [];
          newModalTemplateScreenState.newTemplateErrorMessage = "";
          setModalTemplateScreenState(newModalTemplateScreenState);
        }
      } else {
        //use cached values;
        setAutocompleteTemplateOptions([...autocompleteTemplateTopNOptions]);
        let newModalTemplateScreenState = { ...modalTemplateScreenState };
        newModalTemplateScreenState.newTemplate = [];
        newModalTemplateScreenState.newTemplateErrorMessage = "";
        setModalTemplateScreenState(newModalTemplateScreenState);
      }
    };

    const handleReassignsCommentOnChange = (newValue: string) => {
      let newModalReassignScreenState = { ...modalReassignScreenState };
      newModalReassignScreenState.report = newValue;
      newModalReassignScreenState.reportErrorMessage = "";
      setModalReassignScreenState(newModalReassignScreenState);
    };

    const handleRejectCommentOnChange = (newValue: string) => {
      let newModalRejectScreenState = { ...modalRejectScreenState };
      newModalRejectScreenState.comments = newValue;
      newModalRejectScreenState.commentsErrorMessage = "";
      setModalRejectScreenState(newModalRejectScreenState);
    };

    const handleCallbackAnserModal = async (confirm: boolean, data: any) => {
      if (confirm) {
        switch (currentActionType) {
          case ActionTypeEnum.Reassign:
            let recordInputDTO: ReassignTaskInputDTO = {
              taskId: taskIdToView,
              newResponsibleGuidUser:
                modalReassignScreenState.newResponsibleGuidUser.length > 0
                  ? modalReassignScreenState.newResponsibleGuidUser[0].key
                  : "",
              report: modalReassignScreenState.report,
            };
            let inputDTOValidator = new ReassignTaskInputDTOValidator();
            let resultValidator = inputDTOValidator.validate(recordInputDTO);
            if (!TPGlobal.TPIsEmpty(resultValidator)) {
              let newModalReassignScreenState = { ...modalReassignScreenState };
              var listPropertyNames = Object.keys(resultValidator);
              if (listPropertyNames) {
                for (let index = 0; index < listPropertyNames.length; index++) {
                  const element = listPropertyNames[index];
                  if (resultValidator[element]) {
                    newModalReassignScreenState[element + "ErrorMessage"] =
                      await TPI18N.GetResource(
                        resultValidator[element] as string
                      );
                  } else {
                    newModalReassignScreenState[element + "ErrorMessage"] = "";
                  }
                }
              }
              setModalReassignScreenState(newModalReassignScreenState);
              return;
            }

            if (await reassignTask(recordInputDTO)) {
              if (actionsTaskCallBack) {
                actionsTaskCallBack(currentActionType);
              }
            }
            break;

          case ActionTypeEnum.Reject:
            let recordInputRejectTaskDTO: TaskRejectInputDTO = {
              id: taskIdToView,
              reasonId: modalRejectScreenState.reasonId,
              comments: modalRejectScreenState.comments,
              typistGuidUser: TPGlobal.currentUserGuid,
            };
            let inputDTORejectTaskValidator = new TaskRejectInputDTOValidator();
            let resultValidatorRejectTask =
              inputDTORejectTaskValidator.validate(recordInputRejectTaskDTO);
            if (!TPGlobal.TPIsEmpty(resultValidatorRejectTask)) {
              let newModalRejectScreenState = { ...modalRejectScreenState };
              var listPropertyNamesRejectTask = Object.keys(
                resultValidatorRejectTask
              );
              if (listPropertyNamesRejectTask) {
                for (
                  let index = 0;
                  index < listPropertyNamesRejectTask.length;
                  index++
                ) {
                  const element = listPropertyNamesRejectTask[index];
                  if (resultValidatorRejectTask[element]) {
                    newModalRejectScreenState[element + "ErrorMessage"] =
                      await TPI18N.GetResource(
                        resultValidatorRejectTask[element] as string
                      );
                  } else {
                    newModalRejectScreenState[element + "ErrorMessage"] = "";
                  }
                }
              }
              setModalRejectScreenState(newModalRejectScreenState);
              return;
            }

            if (await rejectTask(recordInputRejectTaskDTO)) {
              if (actionsTaskCallBack) {
                actionsTaskCallBack(currentActionType);
              }
            }
            break;
        }
      }
      setIsShownActionModal(false);
      setModalReassignScreenState(initialModalReassignScreenState);
      setModalRejectScreenState(initialModalRejectScreenState);
    };

    const reassignTask = async (
      inputDTO: ReassignTaskInputDTO
    ): Promise<boolean> => {
      let serviceClient = new TaskService();
      let expectedCodes: Array<number> = [200];

      try {
        setIsLoadingModalScreen(true);

        let responseRequest = await serviceClient.reassignTaskResponsible(
          inputDTO,
          true,
          true,
          expectedCodes
        );
        setIsLoadingModalScreen(false);
        if (responseRequest.responseResult) {
          return true;
        }
        return false;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reassignTask ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reassignTask ex`);
        setIsLoadingModalScreen(false);
        return false;
      }
    };

    const rejectTask = async (
      inputDTO: TaskRejectInputDTO
    ): Promise<boolean> => {
      let serviceClient = new TaskService();
      let expectedCodes: Array<number> = [200];

      try {
        setIsLoadingModalScreen(true);

        let responseRequest = await serviceClient.rejectTask(
          inputDTO,
          true,
          true,
          expectedCodes
        );
        setIsLoadingModalScreen(false);
        if (responseRequest.responseResult) {
          return true;
        }
        return false;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} rejectTask ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} rejectTask ex`);
        setIsLoadingModalScreen(false);
        return false;
      }
    };

    const handleOnReasonIdChange = (e: any) => {
      let newModalRejectScreenState = { ...modalRejectScreenState };
      newModalRejectScreenState.reasonId = e.target.value;
      newModalRejectScreenState.reasonIdErrorMessage = "";
      setModalRejectScreenState(newModalRejectScreenState);
    };

    //Email
    const handleGetOriginalEmail = async () => {
      let taskService = new TaskService();
      let expectedCodes: Array<number> = [200, 404];
      var responseRequest = new Array();

      try {
        responseRequest = await taskService.getInboundMailboxReadByCaseId(
          caseNumber,
          false,
          true,
          expectedCodes
        );

        let insertionType = responseRequest.find(
          (s) => s.taskId == taskIdToView
        );
        setMailboxID(insertionType.id);

        if (insertionType) {
          setOriginalEmail(insertionType.plainTextBody);
        }
      } catch (error) {
        setOriginalEmail("...");
      }
    };

    const handleActionBtnEmail = (isRead: boolean) => {
      if (emailStatus == currentAccordionsEmailMenu.ORIGIN) {
        if (isRead) setEmailStatus(currentAccordionsEmailMenu.TRANSLATION);
        return true;
      } else {
        if (isRead) setEmailStatus(currentAccordionsEmailMenu.ORIGIN);
        return false;
      }
    };

    const handleIsActiveBtnOriginalEmail = (): boolean => {
      let isActive = false;

      if (
        TPGlobal.EnableStoryFai === "1" &&
        caseInformation?.caseType === TPGlobal.CaseType.MAIL_INBOUND &&
        currentTask?.taskTypeId === "S_RECEMA"
      ) {
        isActive = true;
      }

      return isActive;
    };

    // console.log("Case type", caseInformation?.caseType);

    useEffect(() => {
      childRefArray.current = [];
      loadResourcesAndTaskInfo();
      handleGetOriginalEmail();
    }, []);

    return (
      <>
        <TPModal
          modalState={{
            titleModal: `${taskTitleModal} - ${currentTask?.taskTypeLocalizedDescription}`,
            acceptLabel: modalAcceptLabel,
            cancelLabel: modalCancelLabel,
            callBackAnswer: handleCallbackAnserModal,
            callBackData: null,
            isShown: isShownActionModal,
            modalWidth:
              currentActionType === ActionTypeEnum.Reassign
                ? ModalSizeEnum.MODALMD
                : ModalSizeEnum.MODALXL,
          }}
        >
          <TPLoadingOverlay active={isLoadingModalScreen}>
            {currentActionType === ActionTypeEnum.Reassign && (
              <>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPAutoComplete
                        isMandatory={true}
                        labelText={toLabel}
                        onValueChange={handleToChange}
                        onSearch={(query: string) => {
                          handleToOnAutocompleteQuery(query);
                        }}
                        isLoading={false}
                        options={autocompleteToOptions}
                        withIcon={true}
                        emptyLabel={emptyLabel}
                        onKeyDown={handleToOnAutocompleteKeyDown}
                        selected={
                          modalReassignScreenState.newResponsibleGuidUser
                        }
                        errorMessage={
                          modalReassignScreenState.newResponsibleGuidUserErrorMessage
                        }
                        downArrowClick={handleAutoCompleteTopNClick}
                      ></TPAutoComplete>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TPTextArea
                      id="IdTextArea"
                      labelText={commentsLabel}
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleReassignsCommentOnChange(e.target.value)
                      }
                      value={modalReassignScreenState.report}
                      rows={7}
                      errorMessage={modalReassignScreenState.reportErrorMessage}
                    />
                  </div>
                </div>
              </>
            )}
            {currentActionType === ActionTypeEnum.Reject && (
              <>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        isMandatory={true}
                        labelText={reasonsLabel}
                        onChange={handleOnReasonIdChange}
                        dataSource={reasonList}
                        value={modalRejectScreenState.reasonId}
                        errorMessage={
                          modalRejectScreenState.reasonIdErrorMessage
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TPTextArea
                      id="IdTextArea"
                      labelText={commentsLabel}
                      isMandatory={true}
                      onChange={(e: any) =>
                        handleRejectCommentOnChange(e.target.value)
                      }
                      value={modalRejectScreenState.comments}
                      rows={7}
                      errorMessage={modalRejectScreenState.commentsErrorMessage}
                    />
                  </div>
                </div>
              </>
            )}
          </TPLoadingOverlay>
        </TPModal>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div>
            {mode.toUpperCase() === "EDIT"
              ? renderCollectMode()
              : renderViewMode()}
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
);
