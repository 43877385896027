export interface ITPWizardStep {
  children?: any;
  isActive: boolean;
  id: string;
  labelText: string;
  numberText: string;
  withIcon?: boolean;
  icon?: any;
  name: string;
}
const TPWizardStep = ({
  isActive,
  id,
  labelText,
  numberText,
  withIcon,
  icon,
  name,
}: ITPWizardStep) => {
  return (
    <li className="nav-item" role="presentation">
      <a
        id={`id${id}`}
        className={`nav-link ${isActive ? "active" : ""}`}
        data-bs-toggle="tab"
        data-bs-target={"#" + id}
        type="button"
        role="tab"
        aria-controls={id}
        aria-selected={isActive}
      >
        <div className="wizard-number">{numberText}</div>
        <div className="wizard-title">{labelText}</div>
      </a>
    </li>
  );
};

export default TPWizardStep;
