import { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageTitle,
} from "../TPPage/tpPageStyles";
import TPButton from "../bootstrap/components/buttons/TPButton";
import {
  ActionTypeEnum,
  ModalSizeEnum,
  SystemParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import TPTextArea from "../bootstrap/forms/textArea/TPTextArea";
import TPRenderKeyValues from "../TPRenderKeyValues/TPRenderKeyValues";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TaskService } from "@/services/TaskService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TaskViewModel } from "@/models/Task/TaskModels";
import {
  TaskAutomaticInputDTO,
  TaskReportInputDTO,
  TEmpAdditionalDataTaskViewModel,
} from "@/models/Task/TaskReportInputDTO";
import React from "react";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import {
  AdditionalDataService,
  AdditionalDataTaskTypeService,
} from "@/services/AdditionalDataService";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import TPRenderAdditionalDataSwitch from "../TPAdditionalData/TPRenderAdditionalDataSwitch";
import { TPAddtionalDataUIModes } from "../TPAdditionalData/TPAdditionalDataUImodes";
import { v4 as uuidv4 } from "uuid";
import { TEmpAdditionalDataCaseViewModel } from "@/models/Cases/CasesInputDTO";
import TPModal from "@/layouts/TPModal/TPModal";
import TPAutoComplete from "../bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { UserService } from "@/services/UserService";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import { ParametersService } from "@/services/ParametersService";
import {
  ReassignTaskInputDTO,
  ReassignTaskInputDTOValidator,
} from "@/models/Task/ReassignTaskInputDTO";
import {
  TaskRejectInputDTO,
  TaskRejectInputDTOValidator,
} from "@/models/Task/TaskRejectInputDTO";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import { CalculateTaskResponsibleInputDTO } from "@/models/Task/CalculateTaskResponsibleInputDTO";

interface TPStandardTaskAutomaticInterface {
  caseNumber: number;
  taskIdToView: number;
  mode: string;
  isWorkflowTask: boolean;
  closeTaskCallBack: Function;
  saveTaskCallBack: Function;
  actionsTaskCallBack?: Function;
}

type modalReassignScreenType = {
  newResponsibleGuidUser: Array<TPKeyValue>;
  report: string;
  newResponsibleGuidUserErrorMessage: string;
  reportErrorMessage: string;
  [x: string]: any;
};

type modalRejectScreenType = {
  reasonId: string;
  comments: string;
  reasonIdErrorMessage: string;
  commentsErrorMessage: string;
  [x: string]: any;
};

export const TPStandardTaskAutomatic = React.forwardRef(
  (
    {
      caseNumber,
      taskIdToView,
      mode,
      isWorkflowTask,
      closeTaskCallBack,
      saveTaskCallBack,
      actionsTaskCallBack,
    }: TPStandardTaskAutomaticInterface,
    ref,
  ) => {
    //function called from parent
    useImperativeHandle(ref, () => ({
      refreshTaskFromParent() {
        loadTaskInfo();
      },
      canClose() {
        return !hasBeenModified;
      },
      clearHasBeenModified() {
        setHasBeenModified(false);
      },
    }));

    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const componentFileName: string = "TPStandardTaskAutomatic.tsx";
    const resourceSet = "StandardTaskComponent";
    const resourceSetReassignModal: string = "ReassignTaskModal";
    const resourceSetTaskViewer = "TaskViewerComponent";

    const [reportSummaryLabel, setReportSummaryLabel] = useState("");

    const [saveButtonIsActive, setSaveButtonIsActive] = useState(false);
    const [currentTask, setCurrentTask] = useState<TaskViewModel | null>(null);
    const [hasBeenModified, setHasBeenModified] = useState(false);

    //reassign
    const initialModalReassignScreenState: modalReassignScreenType = {
      newResponsibleGuidUser: [],
      report: "",
      newResponsibleGuidUserErrorMessage: "",
      reportErrorMessage: "",
    };
    const [modalReassignScreenState, setModalReassignScreenState] =
      useState<modalReassignScreenType>(initialModalReassignScreenState);

    //reject
    const initialModalRejectScreenState: modalRejectScreenType = {
      reasonId: "",
      comments: "",
      reasonIdErrorMessage: "",
      commentsErrorMessage: "",
    };
    const [modalRejectScreenState, setModalRejectScreenState] =
      useState<modalRejectScreenType>(initialModalRejectScreenState);

    const [reasonList, setReasonList] = useState<Array<TPKeyValue>>([]);

    //additionalData
    //key: additional data id
    //value: addtional data value
    //value2: other properties
    //value3: should show
    const [additionalDataValues, setAdditionalDataValues] = useState<
      Array<TPKeyValue>
    >([]);
    const childRefArray: any = useRef([]);
    const taskguid: string = uuidv4();

    const loadResourcesAndTaskInfo = async () => {
      setReportSummaryLabel(
        await TPI18N.GetText(resourceSetTaskViewer, "ReportSummaryLabel"),
      );
      setIsLoadingScreen(true);

      //get task info by id
      await loadTaskInfo();

      //solve automatic task
      await solveAutomaticTask();

      setIsLoadingScreen(false);
    };

    const loadTaskInfo = async () => {
      let currentTaskElement: TaskViewModel | null;
      let taskService = new TaskService();
      let expectedCodes: Array<number> = [200];

      try {
        currentTaskElement = await getTaskInforById(taskIdToView);
        if (currentTaskElement) {
          currentTaskElement.report = TPGlobal.TPReplaceEnter(
            currentTaskElement.report,
          );
          setCurrentTask(currentTaskElement);
        } else {
          //todo logs
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} loadTaskInfo ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} loadTaskInfo ex`);
        setIsLoadingScreen(false);
      }
    };

    const solveAutomaticTask = async () => {
      let taskService = new TaskService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest = await taskService.AutomaticTask(
          { taskid: taskIdToView, typistGuidUser: TPGlobal.currentUserGuid },
          false,
          true,
          expectedCodes,
        );
        if (responseRequest) {
          return responseRequest;
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} solveAutomaticTask ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} solveAutomaticTask ex`);
        setIsLoadingScreen(false);
      }
    };

    const getTaskInforById = async (idTask: number) => {
      let taskService = new TaskService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest = await taskService.getTaskById(
          idTask,
          false,
          true,
          expectedCodes,
        );
        if (responseRequest && responseRequest.length >= 1) {
          return responseRequest[0];
        } else {
          //todo logs
          return null;
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getTaskInforById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getTaskInforById ex`);
        return null;
      }
    };
    const handleCloseTask = () => {
      if (mode === "EDIT") {
        if (hasBeenModified) {
          closeTaskCallBack(taskIdToView, true);
          return;
        }
      }
      closeTaskCallBack(taskIdToView, false);
    };

    const renderCollectMode = () => {
      let jsxElement: any;
      if (!currentTask) {
        return null;
      }

      jsxElement = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex justify-content-end ">
              <span
                style={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() => handleCloseTask()}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <TPPageTitle style={{ margin: "10px 10px 10px 0px" }}>
                    {currentTask.taskTypeLocalizedDescription}
                  </TPPageTitle>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              border: "1px solid #dee2e6",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          ></div>
        </div>
      );
      return jsxElement;
    };

    const renderViewMode = () => {
      let jsxElement: any;
      if (!currentTask) {
        return null;
      }
      jsxElement = (
        <div className="container-fluid">
          <div className="row">
            <div
              className="col d-flex justify-content-end"
              style={{ margin: "10px 0px" }}
            >
              <button
                //  type="button"
                className="btn-close"
                //  data-bs-dismiss="modal"
                //  aria-label="Close"
                style={{ fontSize: "12px" }}
                onClick={() => handleCloseTask()}
              >
                {/* &times; */}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <TPPageTitle style={{ margin: "10px 10px 10px 0px" }}>
                    {currentTask.taskTypeLocalizedDescription}
                  </TPPageTitle>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div>{reportSummaryLabel}</div>
              <div
                className="form-control"
                style={{
                  minHeight: "100px",
                  maxHeight: "200px",
                  backgroundColor: "#e9ecef",
                  overflowY: "auto",
                  marginTop: "5px",
                }}
                dangerouslySetInnerHTML={{ __html: currentTask.report }}
              ></div>
            </div>
          </div>
          <div
            className="row"
            style={{
              border: "1px solid #dee2e6",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          ></div>
        </div>
      );
      return jsxElement;
    };

    useEffect(() => {
      childRefArray.current = [];
      loadResourcesAndTaskInfo();
    }, []);

    return (
      <>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div>
            {mode.toUpperCase() === "EDIT"
              ? renderCollectMode()
              : renderViewMode()}
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);
