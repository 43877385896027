import React, { forwardRef } from "react";
import { DndContext, useDraggable } from "@dnd-kit/core";
import styled from "styled-components";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DraggableStyle } from "./StyeFromDesigner";

type DraggableViewProps = {
  id: string;
  children: React.ReactNode;
};

const DraggableView = forwardRef<HTMLDivElement, DraggableViewProps>(
  ({ id, children }, ref) => {
    const {
      setNodeRef,
      transform,
      attributes,
      listeners,
      transition,
      isDragging,
    } = useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      boxShadow: isDragging ? "0px 0px 5px" : "",
      borderRadius: isDragging ? "2px" : "",
    };

    return (
      <>
        <DraggableStyle
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          style={style}
        >
          {children}
        </DraggableStyle>
      </>
    );
  },
);

export default DraggableView;
