import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  EmailTemplateTable,
  EmailTemplateViewModel,
} from "@/models/EmailTemplates/EmailTemplateModels";
import { TPActiveOptions, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable, {
  ColumnStyles,
  CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import { MinorOption } from "@/modules/core/components/dynamic-table/TableActionItem";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { StoreModel } from "@/redux/store";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import { TPI18N } from "@/services/I18nService";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  EmailTemplateCloneFormSlice,
  EmailTemplateCloneFormStateModel,
} from "./EmailTemplateCloneForm/_redux/EmailTemplateCloneFormSlice";

type AdminStateType = {
  selectedFilter: string;
  filterByLanguage: string;
  gridColumns: Array<any>;
  gridData: Array<EmailTemplateViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface EmailTemplateAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "change_filterByLanguage" = 2,
  "reload_grid" = 3,
  "change_search_pattern" = 4,
  "change_selectedFilter" = 5,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const EmailTemplatesAdmin = React.forwardRef(
  ({ callBackCommands }: EmailTemplateAdminInterface, ref) => {
    const componentFileName: string = "EmailTemplatesAdmin.tsx";

    const { result } = useSelector(
      (s: StoreModel) => s[EmailTemplateCloneFormSlice.name]
    ) as EmailTemplateCloneFormStateModel;

    //Email template called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [emailTemplateData, setEmailTemplateData] = useState<
      EmailTemplateViewModel[]
    >([]);
    //Screen resources
    const resourceSet: string = "EmailTemplateAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [filterByLanguageLabel, setFilterByLanguageLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [cloneLabel, setCloneLabel] = useState("");
    const [yesLabel, setYesLabel] = useState("");
    const [noLabel, setNoLabel] = useState("");
    // grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [idAccountColumnLabel, setidAccountColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [languageColumnLabel, setLanguageColumnLabel] = useState("");
    const [subjectColumnLabel, setSubjectColumnLabel] = useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCancelLabel, setDeleteCancelLabel] = useState("");
    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);
    const [filterByLanguage, setFilterByLanguage] =
      useState(initialStateFilter);
    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );
    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndEmailTemplateFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm")
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );
      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setCloneLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CloneLabel")
      );
      setYesLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes")
      );
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));
      setFilterByLanguageLabel(
        await TPI18N.GetText(resourceSet, "FilterByLanguageLabel")
      );
      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setidAccountColumnLabel(await TPI18N.GetText(resourceSet, "Account"));
      setDescriptionColumnLabel(
        await TPI18N.GetText(resourceSet, "Description")
      );
      setLanguageColumnLabel(await TPI18N.GetText(resourceSet, "Language"));
      setSubjectColumnLabel(await TPI18N.GetText(resourceSet, "Subject"));
      setIsActiveColumnLabel(await TPI18N.GetText(resourceSet, "Active"));
      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);

      let serviceClient = new EmailTemplateService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        let responseRequest = await serviceClient.getLanguagesOption(
          "C",
          false,
          true,
          expectedCodes
        );

        // Language dropdown
        let languageOptions = responseRequest.map(
          (template: EmailTemplateViewModel) => ({
            key: template.id,
            value: template.name,
          })
        );

        languageOptions.unshift({
          key: "ALL",
          value: await TPI18N.GetText(resourceSet, "All"),
        });

        setFilterByLanguage(languageOptions);
      } catch (error) {
        // ... error handling ...
        console.error("error", error);
      }
    };

    //Run to populate grid columns when columns are loaded or
    const setupGridColumns = (prevState: AdminStateType) => {
      let newState: AdminStateType = { ...prevState };
      newState.gridColumns = [prevState.gridData];
      return newState;
    };

    //Get email template by Filter
    const reloadDataGrid = async (
      selectedFilter: string,
      filterByLanguage: string
    ) => {
      let serviceClient = new EmailTemplateService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.getEmailTemplateFilterByLanguage(
            filterByLanguage,
            selectedFilter,
            false,
            true,
            expectedCodes
          );
        setIsLoadingScreen(false);

        setEmailTemplateData(responseRequest);

        return [...getEmailTemplateData(responseRequest)];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter, adminState.filterByLanguage)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const getEmailTemplateData = (
      response: EmailTemplateViewModel[]
    ): EmailTemplateTable[] => {
      return response.map(
        ({
          id,
          languageId,
          idAccount,
          description,
          localizedLanguage,
          subject,
          isActive,
          isSystemRecord,
        }) => {
          return {
            id,
            languageId,
            idAccount,
            description,
            localizedLanguage,
            subject,
            isActive,
            isSystemRecord,
          };
        }
      );
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New email template
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update profile
    const handleUpdateClick = (id: string, languageId: string) => {
      let command: any = {
        command: "update",
        recordId: id,
        languageId: languageId,
      };
      callBackCommands(command);
    };

    //Clone Email
    const handleCloneClick = (id: string) => {
      let element = emailTemplateData.find((item) => item.id === id);

      callBackCommands({
        command: "clone",
        recordId: id,
        form: element,
      });
    };

    //Modal Question to delete profile
    const handleDeleteClick = (id: string, languageId: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = {
        recordId: id,
        languageId: languageId,
      };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete email template after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new EmailTemplateService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteEmailTemplateById(
            callBackData.recordId,
            callBackData.languageId,
            true,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
              // languageId: callBackData.languageId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    const handleFilterByLanguageChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_filterByLanguage,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      filterByLanguage: "ALL",
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    const emailTemplateColumns: CustomColumnNames<EmailTemplateTable> = {
      id: idColumnLabel,
      idAccount: idAccountColumnLabel,
      description: descriptionColumnLabel,
      localizedLanguage: languageColumnLabel,
      subject: subjectColumnLabel,
      isActive: isActiveColumnLabel,
    };

    const customColumns: ColumnStyles<EmailTemplateTable> = {
      id: ({ value, item }) => (
        <TablePrimaryItem
          value={value}
          isDisabled={item.isSystemRecord}
          onClick={() =>
            !item.isSystemRecord && handleUpdateClick(item.id, item.languageId)
          }
        />
      ),
      isActive: ({ value }) => (
        <TableChip
          value={value}
          onLabel={yesLabel}
          offLabel={noLabel}
          justify="flex-start"
        />
      ),
    };

    const minorOptions: MinorOption<EmailTemplateTable>[] = [
      {
        key: updateLabel,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (e) => handleUpdateClick(e.id, e.languageId),
      },
      {
        key: deleteLabel,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: (e) => handleDeleteClick(e.id, e.languageId),
      },
      {
        key: cloneLabel,
        type: "clone",
        icon: TPIconTypes.clone,
        onOptionChange: (e) => handleCloneClick(e.id),
      },
    ];

    const handleIconClick = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) handleRefreshClick();
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;

        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;

        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload.sort((a: any, b: any) =>
            a.languageId.localeCompare(b.languageId)
          );
          return newStateGrid;

        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;

        case commandsEnum.change_filterByLanguage:
          let newStateChangeFilterByLanguage: AdminStateType;
          newStateChangeFilterByLanguage = { ...prevState };
          newStateChangeFilterByLanguage.filterByLanguage = command.payload;
          return newStateChangeFilterByLanguage;

        default:
          return prevState;
      }
    }

    const additionalFilters: AdditionalFilter[] = [
      {
        key: "status",
        data: filterKeyValue,
        label: filterIsActiveLabel,
        selectedValue: adminState.selectedFilter,
        onChange: handleFilterChange,
      },
      {
        key: "language",
        data: filterByLanguage,
        label: filterByLanguageLabel,
        selectedValue: adminState.filterByLanguage,
        minWidth: 160,
        onChange: handleFilterByLanguageChange,
      },
    ];

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndEmailTemplateFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndEmailTemplateFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndEmailTemplateFilter ex`
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [
      adminState.columnsAreLoaded,
      adminState.selectedFilter,
      adminState.filterByLanguage,
    ]);

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCancelLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-email-template"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <DynamicTable
                data={adminState.gridData}
                columnNames={emailTemplateColumns}
                columnStyles={customColumns}
                minorOptions={minorOptions}
                additionalFilters={additionalFilters}
                hiddenColumns={["languageId", "isSystemRecord"]}
                noDataMessage={`${thereAreNoRecordsToShow}.`}
                disableMinorOption={(item) => item.isSystemRecord}
                onIconClicked={(event) => handleIconClick(event)}
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
);

export default EmailTemplatesAdmin;
