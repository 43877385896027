import allThemes from "@/assets/styles/theme";
import TPGlobal from "@/helpers/TPGlobal";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { Icon } from "@iconify/react";
import { FC, useEffect, useState } from "react";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPMailKeypoints from "./TPMailKeyPoints";

const resourceSet = "TPGenAI";

interface Properties {
  caseNumber: number;
  originalEmail: string;
  id?: number | string;
}

export const TPGenAIHeader: FC<Properties> = function ({ caseNumber, id }) {
  const [TPGenAIVisible, setTPGenAIVisible] = useState<boolean>(false);
  const [viewTPGenAIKeyPointsLabel, setViewTPGenAIKeyPointsLabel] =
    useState("");
  const [enabled, setEnabled] = useState<boolean>(false);

  const loadLabels = async function () {
    setViewTPGenAIKeyPointsLabel(
      await TPI18N.GetText(resourceSet, "ViewTPGenAIKeyPointsLabel")
    );
  };

  useEffect(() => {
    if (caseNumber) {
      const taskServiceInstance = new TaskService();
      taskServiceInstance
        .getInboundMailboxReadByCaseId(caseNumber, false, false, [200])
        .then((response) => {
          const mailboxRead = response.find(
            (item: { insertionType: string; taskId: string }) =>
              item.insertionType === "NEWCASE"
          );
          if (mailboxRead) {
            setEnabled(Boolean(TPGlobal.TPGenAIEnabled));
          }
        })
        .catch((error) => {
          console.error(error);
        });
      loadLabels();
    }
  }, [caseNumber]);

  return (
    <>
      {enabled && (
        <>
          <div
            className="accordion"
            style={{
              border: `1px solid ${allThemes.base.darkColor}`,
              padding: "4px",
              borderRadius: "4px",
              overflow: "auto",
              width: "100%",
            }}
          >
            <div className="accordion-item">
              <div id="tpgenai-header" className="accordion-header">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#mainKeyPointsCollapse-${String(id)}`}
                  style={{
                    ...allThemes.base.accordion.collapseButton,
                    borderRadius: "4px",
                  }}
                  onClick={() => setTPGenAIVisible(!TPGenAIVisible)}
                >
                  {TPGenAIVisible ? (
                    <Icon icon="ph:caret-up-bold" />
                  ) : (
                    <Icon icon="ph:caret-down-bold" />
                  )}
                </button>
                <div>
                  <strong>{viewTPGenAIKeyPointsLabel}</strong>
                </div>
                <TPIcon iconType={TPIconTypes.checklist} />
              </div>
              <div
                id={`mainKeyPointsCollapse-${String(id)}`}
                className="accordion-collapse collapse"
                style={{ padding: "8px" }}
              >
                <TPMailKeypoints caseId={caseNumber} type="inbound" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
