import { ReactElement } from "react";
import { StyledTableChip } from "./micro-components-styles";

type TableChipProps = {
  value: boolean;
  justify?: "center" | "flex-end" | "flex-start";
  onLabel?: string;
  offLabel?: string;
  underline?: boolean;
  notBoolean?: boolean;
  onClick?: () => void;
};

function TableChip({
  value,
  justify = "center",
  onLabel = "YES",
  offLabel = "NO",
  underline = false,
  notBoolean = false,
  onClick = () => {},
}: TableChipProps): ReactElement {
  const getBooleanValueLabel = (value: boolean): string => {
    return value ? onLabel : offLabel;
  };

  return (
    <StyledTableChip
      justify={justify}
      underline={underline}
      notBoolean={notBoolean}
      value={value}
      onClick={onClick}
    >
      <p>{getBooleanValueLabel(value)}</p>
    </StyledTableChip>
  );
}

export default TableChip;
