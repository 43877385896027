import createSagaMiddleware from "redux-saga";
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  configureStore,
  PayloadAction,
} from "@reduxjs/toolkit";
import { all, spawn } from "redux-saga/effects";
import { HorizontalTabsSlice } from "@/layouts/Main/_redux/HorizonatalTabsSlice";
import { VerticalTabsAdminContainerSlice } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer/_redux/VerticalTabsAdminContainerSlice";
import { TabsCaseViewerSlice } from "@/layouts/VerticalTabs/_redux/TabsCaseViewerSlice";
import { EmailTemplateCloneFormSlice } from "@/pages/EmailTemplates/EmailTemplateCloneForm/_redux/EmailTemplateCloneFormSlice";
import { FormDesignerExportImportFormSlice } from "@/pages/FormDesigner/FormDesignerExportImportForm/_redux/FormDesignerExportImportFormSlice";
import { GroupCloneFormSlice } from "@/pages/Groups/GroupCloneForm/_redux/GroupCloneFormSlice";
import { TabsCaseViewerWatchAsync } from "@/layouts/VerticalTabs/_redux/TabsCaseViewerSaga";
import { EmailTemplateCloneFormSaga } from "@/pages/EmailTemplates/EmailTemplateCloneForm/_redux/EmailTemplateCloneFormSaga";
import { FormDesignerExportImportFormSaga } from "@/pages/FormDesigner/FormDesignerExportImportForm/_redux/FormDesignerExportImportFormSaga";
import { GroupCloneFormSaga } from "@/pages/Groups/GroupCloneForm/_redux/GroupCloneFormSaga";

/**
 * store model
 */
export interface StoreModel {
  [key: string]: any;
}

/**
 * custom error model
 */
export interface CustomError {
  /**
   * error
   */
  error: any;

  /**
   * message
   */
  message: string;
}

/**
 * result model
 */
export interface ResultModel<M = any> {
  /**
   * action
   */
  action: PayloadAction<M>;

  /**
   * message
   */
  message?: string;

  /**
   * error
   */
  error?: any;
}

/**
 * state model
 */
export interface StateModel<M = any> {
  /**
   * result
   */
  result: ResultModel<M>;
}

/**
 * type of
 *
 * @param action
 * @param reducer
 * @returns
 */
export function TypeOf(
  result: ResultModel<any>,
  reducer: ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload,
) {
  return result?.action?.type === reducer?.type;
}

const slices: Array<any> = [
  HorizontalTabsSlice,
  VerticalTabsAdminContainerSlice,
  TabsCaseViewerSlice,
  EmailTemplateCloneFormSlice,
  GroupCloneFormSlice,
  FormDesignerExportImportFormSlice,
];

const watchAsync: Array<any> = [
  TabsCaseViewerWatchAsync,
  EmailTemplateCloneFormSaga,
  GroupCloneFormSaga,
  FormDesignerExportImportFormSaga,
];

const reducer: any = {};
slices.map((slice) => (reducer[slice.name] = slice.reducer));

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

export default function* rootSaga() {
  yield all(watchAsync.map((saga) => spawn(saga)));
}

sagaMiddleware.run(rootSaga);
