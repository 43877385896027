export const camelCaseToPhrase = (value: string): string => {
  return (
    value
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase()) ?? ""
  );
};

export const camelCaseToId = (value: string): string => {
  return (
    value
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "") ?? ""
  );
};
