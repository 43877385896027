import { useEffect, useRef, useState } from "react";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import {
  ButomUploadImageStyle,
  ContainerVerticalDivStyle,
  UploadDivStyle,
  UploadLabelStyle,
} from "./StyleImageAdmin";
import TPGlobal from "@/helpers/TPGlobal";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import { TPI18N } from "@/services/I18nService";
import {
  TPToastTypes,
  showToast,
} from "@/components/bootstrap/components/toasts/TPToast";

type ModelFile = {
  Type: string;
  Size: number;
};

export const UploadImage: React.FC<ModelFile> = ({ Type, Size }) => {
  const componentFileName: string = "UploadImageComponent";
  const resourceSet: string = "ImagesAdminComponent";
  // Label

  const [nameUploadLabel, setNameUploadLabel] = useState("");
  const [uploadButtonTextLabel, setUploadButtonTextLabel] = useState("");
  const [imageAlertNotAllowed, setImageAlertNotAllowed] = useState("");
  const [imageWeightIsNotAllowed, setImageAlertWeightIsNotAllowedd] =
    useState("");

  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeUpload, setActiveUpload] = useState(false);

  const value = useAppConctextImageAdmin();
  const {
    handleSelected,
    selectedItem,
    selectedFolderParent,
    handleUploadImage,
  } = value;
  const [file, setFile] = useState(null);

  useEffect(() => {
    loadResourcesAndOrganizations();
  }, []);

  const loadResourcesAndOrganizations = async () => {
    setNameUploadLabel(await TPI18N.GetText(resourceSet, "NameUploadLabel"));
    setUploadButtonTextLabel(
      await TPI18N.GetText(resourceSet, "UploadButtonTextLabel"),
    );
    setImageAlertNotAllowed(
      await TPI18N.GetText(resourceSet, "ImageAlertNotAllowed"),
    );
    setImageAlertWeightIsNotAllowedd(
      await TPI18N.GetText(resourceSet, "ImageAlertWeightIsNotAllowed"),
    );
  };

  const handleDrop = (data: any) => {
    if (ValidateFile(data)) {
      handleUploadImage(data[0]);
    }
  };

  const ValidateFile = (fileData: any): boolean => {
    let count = 0;

    let file = fileData[0];
    if (file && file.name != undefined && file.name != "") {
      let fileExtension = "";
      if (file.name != undefined)
        fileExtension = `.${file.name.split(".").pop()}`;

      if (fileExtension !== "") {
        let listType = TPGlobal.allowedImageExtensions.split("|");
        let existType = listType.filter(
          (s) => s.toLocaleLowerCase() == fileExtension.toLocaleLowerCase(),
        );
        if (existType) count = 1;
        if (TPGlobal.imageWeightAllowed <= file.size && count == 1) count = 2;
      }

      if (count == 0) showToast(imageAlertNotAllowed, TPToastTypes.warning);
      if (count == 1) showToast(imageWeightIsNotAllowed, TPToastTypes.warning);
    } else {
      showToast(imageAlertNotAllowed, TPToastTypes.warning);
    }

    return count == 2;
  };

  const GetTypeImage = (): string[] => {
    let list: string[] = [];
    if (TPGlobal.allowedImageExtensions) {
      list = TPGlobal.allowedImageExtensions.split("|");
    }
    return list;
  };

  return (
    <>
      {activeUpload ? (
        <FileUploader
          types={GetTypeImage()}
          label={nameUploadLabel}
          multiple={false}
          handleChange={handleDrop}
          UploadButtonText={uploadButtonTextLabel}
        />
      ) : null}

      {selectedFolderParent &&
      selectedFolderParent.folderName != undefined &&
      selectedFolderParent.folderName != "" ? (
        <ButomUploadImageStyle
          onClick={() => {
            setActiveUpload(!activeUpload);
          }}
        >
          <i
            className={
              selectedFolderParent
                ? !activeUpload
                  ? "bi bi-cloud-arrow-up-fill"
                  : "bi bi-x-lg"
                : "bi bi-folder-x"
            }
          ></i>
        </ButomUploadImageStyle>
      ) : null}
    </>
  );
};

export default UploadImage;
