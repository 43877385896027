import { ReactElement, FC, useState, useEffect, useReducer } from "react";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { v4 as uuidv4 } from "uuid";

import {
  TPPageSectionTitle,
  TPPageTitle,
  TPPageSection,
  TPPageAcceptCancelButtonsContainer,
} from "@/components/TPPage/tpPageStyles";

import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  WorkflowAssignmentInputDTO,
  WorkflowAssignmentInputDTOValidator,
} from "@/models/Workflow/WorkflowAssignmentInputDTO";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { TPI18N } from "@/services/I18nService";

import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { RelationsService } from "@/services/RelationsService";
import { RelationBaseLevelViewModel } from "@/models/Relations/RelationsModels";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { TPActiveOptions, TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { TreeService } from "@/services/TreeService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { WorkflowAssignmentService } from "@/services/WorkflowAssignmentService";
import { BranchService } from "@/services/BranchService";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import TPBranchSelection2 from "@/components/TPBranchSelection/TPBranchSelection2";

type workflowAssignmentInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};
enum commandsEnum {
  "set_GuidId" = 0,
  "change_currentWorkflow" = 1,
  "change_OrganizationRelation" = 2,
  "change_ApplyForAllOrganizationRelation" = 3,
  "change_CurrentBranch" = 4,
  "change_ApplyForAll" = 5,
  "change_Weight" = 6,
  "change_Errors" = 7,
  "set_ScreenForUpdate" = 8,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

type InsertUpdateStateType = {
  guidId: string;
  currentWorkflowType: string;
  currentBaseLevelId: string;
  currentTree1: string;
  currentTree2: string;
  currentTree3: string;
  currentTree4: string;
  currentTree5: string;
  currentLabelTree1: string;
  currentLabelTree2: string;
  currentLabelTree3: string;
  currentLabelTree4: string;
  currentLabelTree5: string;
  currentBranch1: string;
  currentBranch2: string;
  currentBranch3: string;
  currentBranch4: string;
  currentBranch5: string;
  currentDescriptionBranch1: string;
  currentDescriptionBranch2: string;
  currentDescriptionBranch3: string;
  currentDescriptionBranch4: string;
  currentDescriptionBranch5: string;
  weight: string;
  applyForAllOrganizationsRelations: boolean;
  alreadyLoadedRecordForUpdate: boolean;

  //validators
  guidAssignmentErrorMessage: string;
  workflowTypeErrorMessage: string;
  baselevelErrorMessage: string;
  classificator1ErrorMessage: string;
  classificator2ErrorMessage: string;
  classificator3ErrorMessage: string;
  classificator4ErrorMessage: string;
  classificator5ErrorMessage: string;
  weightErrorMessage: string;
  [key: string]: any;
};

const WorkflowAssignmentInsertUpdate: FC<
  workflowAssignmentInsertUpdateProps
> = ({ mode, recordId, callBackResult }): ReactElement => {
  //#region  Init
  const componentFileName: string = "WorkflowAssignmentInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "WorkflowAssignmentInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [workflowTypeLabel, setWorkflowTypeLabel] = useState("");
  const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
    useState("");
  const [dummyClassfierLabel, setDummyClassfierLabel] = useState("");

  const [weightLabel, setWeightLabel] = useState("");
  const [
    errorMustSelectAtLeastOneClassificatorLabel,
    setErrorMustSelectAtLeastOneClassificatorLabel,
  ] = useState("");

  const [
    applyForAllOrganizationsRelationsLabel,
    setApplyForAllOrganizationsRelationsLabel,
  ] = useState("");
  const [
    noteApplyForAllOrganizationsRelationsLabel,
    setNoteApplyForAllOrganizationsRelationsLabel,
  ] = useState("");

  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");

  //TPBranchSelect Resources
  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");
  const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
  const [modalTitleLabel, setmodalTitleLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");

  //Screen state
  const [workflowTypesKeyValue, setWorkflowTypesKeyValue] = useState<
    Array<TPKeyValue>
  >([]);
  const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
    useState<Array<TPKeyValue>>([]);

  const [
    defaultBranchHierarchyDescription1,
    setDefaultBranchHierarchyDescription1,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription2,
    setDefaultBranchHierarchyDescription2,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription3,
    setDefaultBranchHierarchyDescription3,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription4,
    setDefaultBranchHierarchyDescription4,
  ] = useState("");
  const [
    defaultBranchHierarchyDescription5,
    setDefaultBranchHierarchyDescription5,
  ] = useState("");

  //#endregion

  const loadResourcesAndLoadWorkflowAssignmentInfo = async () => {
    let getWorkflowTypeListPromise: Promise<any>;
    let getOrganizationsRelationsListPromise: Promise<any>;
    let getWorkflowAssignmentByIdtPromise: Promise<any>;
    let functionsPromises: Promise<any>[];
    let functionResponses: any;
    let bolR: boolean;
    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setWorkflowTypeLabel(
      await TPI18N.GetText(resourceSet, "WorkflowTypeLabel"),
    );
    setOrganizationsRelationsLabel(
      await TPI18N.GetText(resourceSet, "OrganizationRelationLabel"),
    );

    setDummyClassfierLabel(
      await TPI18N.GetText(resourceSet, "ClassifierLabel"),
    );

    setWeightLabel(await TPI18N.GetText(resourceSet, "WeightLabel"));

    setApplyForAllOrganizationsRelationsLabel(
      await TPI18N.GetText(
        resourceSet,
        "ApplyForAllOrganizationsRelationsLabel",
      ),
    );
    setNoteApplyForAllOrganizationsRelationsLabel(
      await TPI18N.GetText(
        resourceSet,
        "NoteApplyForAllOrganizationsRelations",
      ),
    );

    setErrorMustSelectAtLeastOneClassificatorLabel(
      await TPI18N.GetText(
        resourceSet,
        "MustSelectAtLeastOneClassificatorLabel",
      ),
    );

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    setModalAcceptLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalAcceptLabel"),
    );
    setmodalTitleLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectBranchTitleLabel",
      ),
    );
    setModalCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalCancelLabel"),
    );
    setModalSelectedBranchLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectedBranchLabel",
      ),
    );
    setEmptyLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel"),
    );

    //screen state
    setIsLoadingScreen(true);
    getWorkflowTypeListPromise = new Promise(async (resolve) => {
      resolve(await getWorkflowTypeList());
    });
    getOrganizationsRelationsListPromise = new Promise(async (resolve) => {
      resolve(await getOrganizationsRelationsList());
    });

    setIsLoadingScreen(false);

    if (mode === "Update") {
      setIsLoadingScreen(true);

      getWorkflowAssignmentByIdtPromise = new Promise(async (resolve) => {
        resolve(await getWorkflowAssignmentById(recordId));
      });

      functionsPromises = [
        getWorkflowTypeListPromise,
        getOrganizationsRelationsListPromise,
        getWorkflowAssignmentByIdtPromise,
      ];
      functionResponses = await Promise.allSettled(functionsPromises);

      if (functionResponses[2].status === "fulfilled") {
        bolR = functionResponses[2].value as boolean;
      } else bolR = false;

      if (!bolR) {
        setIsLoadingScreen(false);
        //todo
        return;
      }
      setIsLoadingScreen(false);
    }

    if (mode === "Insert") {
      functionsPromises = [
        getWorkflowTypeListPromise,
        getOrganizationsRelationsListPromise,
      ];
      functionResponses = await Promise.allSettled(functionsPromises);

      let newGuid: string = uuidv4();
      let command1: commandType = {
        type: commandsEnum.set_GuidId,
        payload: { newGuid: newGuid },
      };
      dispatchCommand(command1);
    }

    if (functionResponses[0].status === "fulfilled") {
      setWorkflowTypesKeyValue(functionResponses[0].value as Array<TPKeyValue>);
    }

    setIsLoadingScreen(false);
  };

  const getWorkflowTypeList = async (): Promise<Array<TPKeyValue>> => {
    let serviceClient = new WorkflowTypeService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      let responseRequest = await serviceClient.getByFilterIsActive(
        TPActiveOptions.ALL.toString(),
        false,
        true,
        expectedCodes,
      );
      let newFunctionsList: Array<TPKeyValue> = [];
      let functionsListData: Array<WorkflowTypeViewModel>;
      functionsListData = responseRequest;
      for (i = 0; i <= functionsListData.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: functionsListData[i].id,
          value: functionsListData[i].localizedDescription
            ? functionsListData[i].localizedDescription
            : functionsListData[i].description,
        };
        newFunctionsList.push(keyvalue);
      }
      let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
      newFunctionsList.unshift(intitialKeyValue);
      return newFunctionsList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getWorkflowTypeList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getWorkflowTypeList ex`);
      return [];
    }
  };

  const handleWorkflowTypeChange = async (e: any) => {
    let newWorkflowTypeId: string = e.target.value;
    let command1: commandType = {
      type: commandsEnum.change_currentWorkflow,
      payload: { newWorkflowTypeId: newWorkflowTypeId },
    };
    dispatchCommand(command1);
  };

  const getRelationsList = async (
    newOrganization: string,
  ): Promise<Array<TPKeyValue>> => {
    let serviceClient = new RelationsService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      let responseRequest =
        await serviceClient.getRelationsByOrganizationAndFilter(
          newOrganization,
          TPActiveOptions.ALL.toString(),
          false,
          false,
          expectedCodes,
        );

      let newRelationsListState: Array<TPKeyValue> = [];
      let relationsListData: Array<RelationBaseLevelViewModel>;
      relationsListData = responseRequest;
      for (i = 0; i <= relationsListData.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: relationsListData[i].baseLevelId,
          value: relationsListData[i].localizedName
            ? relationsListData[i].localizedName
            : relationsListData[i].name,
        };
        newRelationsListState.push(keyvalue);
      }
      return newRelationsListState;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRelationsList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRelationsList ex`);
      return [];
    }
  };

  const getOrganizationsRelationsList = async () => {
    let serviceOrganizationRelationClient = new OrganizationsRelationsService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      let responseRequestOrgRela =
        await serviceOrganizationRelationClient.getOrganizationsRelationsByFilter(
          TPActiveOptions.ALL.toString(),
          false,
          true,
          expectedCodes,
        );

      let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
      for (i = 0; i <= responseRequestOrgRela.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: responseRequestOrgRela[i].id,
          value: responseRequestOrgRela[i].localizedDescription,
        };
        newOrganizationsRelationsListState.push(keyvalue);
      }

      let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
      newOrganizationsRelationsListState.unshift(intitialKeyValue);
      setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getOrganizationsRelationsList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getOrganizationsRelationsList ex`,
      );
    }
  };

  const getBaseLevelInfo = async (BaseLevelId: string) => {
    let serviceClient = new OrganizationsRelationsService();
    let expectedCodes: Array<number> = [200];
    let baseLevelData: OrganizationsRelationsViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getById(
        BaseLevelId,
        false,
        true,
        expectedCodes,
      );

      baseLevelData = responseRequest;
      return baseLevelData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBaseLevelInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getBaseLevelInfo ex`);
      return null;
    }
  };

  const getTreeInfo = async (treeId: string) => {
    let serviceClient = new TreeService();
    let expectedCodes: Array<number> = [200];
    let treeData: TreeViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getTreeById(
        treeId,
        false,
        true,
        expectedCodes,
      );
      treeData = responseRequest;
      return treeData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTreeInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTreeInfo ex`);
      return null;
    }
  };

  const getBranchInfo = async (branchId: string) => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200];
    let branchData: BranchViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getBranchById(
        branchId,
        false,
        true,
        expectedCodes,
      );
      branchData = responseRequest;
      return branchData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBranchInfo ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getBranchInfo ex`);
      return null;
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
    multilanguageTableName: string,
    multilanguageFieldName: string,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleOrganizationRelationChange = async (e: any) => {
    let newBaseLevelId: string = e.target.value;

    let newTree1: string;
    let newTree2: string;
    let newTree3: string;
    let newTree4: string;
    let newTree5: string;
    let newLabelTree1: string | null;
    let newLabelTree2: string | null;
    let newLabelTree3: string | null;
    let newLabelTree4: string | null;
    let newLabelTree5: string | null;
    let baseLevelData: OrganizationsRelationsViewModel | null = null;
    let treeData: TreeViewModel | null = null;
    let localizedField: Array<MultilanguageFieldValueViewModel> = [];
    let i;
    let found: boolean;
    newTree1 = "";
    newTree2 = "";
    newTree3 = "";
    newTree4 = "";
    newTree5 = "";
    newLabelTree1 = "";
    newLabelTree2 = "";
    newLabelTree3 = "";
    newLabelTree4 = "";
    newLabelTree5 = "";

    if (
      mode === "Update" &&
      insertUpdateState.alreadyLoadedRecordForUpdate === false
    ) {
      return;
    }

    setIsLoadingScreen(true);

    if (newBaseLevelId !== "") {
      //get base level info
      baseLevelData = await getBaseLevelInfo(newBaseLevelId);
      if (baseLevelData === null) {
        //todo logs
        return;
      }
      newTree1 = baseLevelData.tree1Id;
      newTree2 = baseLevelData.tree2Id;
      newTree3 = baseLevelData.tree3Id;
      newTree4 = baseLevelData.tree4Id;
      newTree5 = baseLevelData.tree5Id;
      if (newTree1 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 1
        treeData = await getTreeInfo(newTree1);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C1",
            "BAseLEvel",
            "Tree1_Classify1Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree1 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree1 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
      if (newTree2 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 2
        treeData = await getTreeInfo(newTree2);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C2",
            "BAseLEvel",
            "Tree2_Classify2Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree2 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree2 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
      if (newTree3 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 3
        treeData = await getTreeInfo(newTree3);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C3",
            "BAseLEvel",
            "Tree3_Classify3Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree3 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree3 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
      if (newTree4 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 4
        treeData = await getTreeInfo(newTree4);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C4",
            "BAseLEvel",
            "Tree4_Classify4Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree4 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree4 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
      if (newTree5 !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 5
        treeData = await getTreeInfo(newTree5);
        if (treeData === null) {
          //todo logs
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C5",
            "BAseLEvel",
            "Tree5_Classify5Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            return;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree5 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree5 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              return false;
            }
          }
        }
      }
    }
    setIsLoadingScreen(false);
    setDefaultBranchHierarchyDescription1("");
    setDefaultBranchHierarchyDescription2("");
    setDefaultBranchHierarchyDescription3("");
    setDefaultBranchHierarchyDescription4("");
    setDefaultBranchHierarchyDescription5("");
    let command1: commandType = {
      type: commandsEnum.change_OrganizationRelation,
      payload: {
        newBaseLevelId: newBaseLevelId,
        newTree1: newTree1,
        newTree2: newTree2,
        newTree3: newTree3,
        newTree4: newTree4,
        newTree5: newTree5,
        newLabelTree1: newLabelTree1,
        newLabelTree2: newLabelTree2,
        newLabelTree3: newLabelTree3,
        newLabelTree4: newLabelTree4,
        newLabelTree5: newLabelTree5,
      },
    };
    dispatchCommand(command1);
  };

  const handleApplyForAllChange = async () => {
    let command1: commandType = {
      type: commandsEnum.change_ApplyForAll,
      payload: null,
    };
    dispatchCommand(command1);
  };

  const handleWeightChange = async (e: any) => {
    let newWeight: string = e.target.value;
    let command1: commandType = {
      type: commandsEnum.change_Weight,
      payload: { newWeight: newWeight },
    };
    dispatchCommand(command1);
  };

  const getWorkflowAssignmentById = async (
    pRecordId: string,
  ): Promise<boolean> => {
    let baseLevelData: OrganizationsRelationsViewModel | null = null;
    let baseLevelData2: OrganizationsRelationsViewModel;
    let treeData: TreeViewModel | null = null;
    let branchData: BranchViewModel | null = null;
    let localizedField: Array<MultilanguageFieldValueViewModel> = [];
    let i;
    let found: boolean;
    let newLabelTree1: string = "";
    let newLabelTree2: string = "";
    let newLabelTree3: string = "";
    let newLabelTree4: string = "";
    let newLabelTree5: string = "";

    let tree1Promise: Promise<any>;
    let tree2Promise: Promise<any>;
    let tree3Promise: Promise<any>;
    let tree4Promise: Promise<any>;
    let tree5Promise: Promise<any>;
    let treePromises: Promise<any>[];
    let treeResponses: any[];

    let serviceClient = new WorkflowAssignmentService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await serviceClient.getWorkflowAssignmentById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );
      if (!responseRequest) {
        //todo logs
        return false;
      }

      let applyForAllOrganizationsRelations: boolean;
      if (!responseRequest.baseLevelId || responseRequest.baseLevelId === "") {
        applyForAllOrganizationsRelations = true;
      } else {
        applyForAllOrganizationsRelations = false;
      }
      //get base level info
      baseLevelData = await getBaseLevelInfo(responseRequest.sourceBaseLevelId);
      if (baseLevelData === null) {
        //todo logs
        return false;
      }

      // #region "tree info"
      baseLevelData2 = baseLevelData;

      tree1Promise = new Promise(async (resolve, reject) => {
        if (baseLevelData2.tree1Id !== TPGlobal.Tree.NA_TreeCode) {
          if (responseRequest.branchTree1Id) {
            //get branch by id in order to get hierarchy description of branch
            branchData = await getBranchInfo(responseRequest.branchTree1Id);
            if (branchData == null) {
              //todo logs
              reject(false);
              return false;
            }
            setDefaultBranchHierarchyDescription1(
              branchData.hierarchyDescription,
            );
          }

          //get tree Info 1
          treeData = await getTreeInfo(baseLevelData2.tree1Id);
          if (treeData === null) {
            //todo logs
            reject(false);
            return false;
          }
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            responseRequest.sourceBaseLevelId + "_C1",
            "BAseLEvel",
            "Tree1_Classify1Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            reject(false);
            return false;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree1 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree1 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              reject(false);
              return false;
            }
          }
        }
        resolve(true);
      });

      tree2Promise = new Promise(async (resolve, reject) => {
        if (baseLevelData2.tree2Id !== TPGlobal.Tree.NA_TreeCode) {
          if (responseRequest.branchTree2Id) {
            //get branch by id in order to get hierarchy description of branch
            branchData = await getBranchInfo(responseRequest.branchTree2Id);
            if (branchData == null) {
              //todo logs
              reject(false);
              return false;
            }
            setDefaultBranchHierarchyDescription2(
              branchData.hierarchyDescription,
            );
          }

          //get tree Info 2
          treeData = await getTreeInfo(baseLevelData2.tree2Id);
          if (treeData === null) {
            //todo logs
            reject(false);
            return false;
          }
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            responseRequest.sourceBaseLevelId + "_C2",
            "BAseLEvel",
            "Tree2_Classify2Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            reject(false);
            return false;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree2 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree2 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              reject(false);
              return false;
            }
          }
        }
        resolve(true);
      });

      tree3Promise = new Promise(async (resolve, reject) => {
        if (baseLevelData2.tree3Id !== TPGlobal.Tree.NA_TreeCode) {
          if (responseRequest.branchTree3Id) {
            //get branch by id in order to get hierarchy description of branch
            branchData = await getBranchInfo(responseRequest.branchTree3Id);
            if (branchData == null) {
              //todo logs
              reject(false);
              return false;
            }
            setDefaultBranchHierarchyDescription3(
              branchData.hierarchyDescription,
            );
          }

          //get tree Info 3
          treeData = await getTreeInfo(baseLevelData2.tree3Id);
          if (treeData === null) {
            //todo logs
            reject(false);
            return false;
          }
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            responseRequest.sourceBaseLevelId + "_C3",
            "BAseLEvel",
            "Tree3_Classify3Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            reject(false);
            return false;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree3 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree3 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              reject(false);
              return false;
            }
          }
        }
        resolve(true);
      });

      tree4Promise = new Promise(async (resolve, reject) => {
        if (baseLevelData2.tree4Id !== TPGlobal.Tree.NA_TreeCode) {
          if (responseRequest.branchTree4Id) {
            //get branch by id in order to get hierarchy description of branch
            branchData = await getBranchInfo(responseRequest.branchTree4Id);
            if (branchData == null) {
              //todo logs
              reject(false);
              return false;
            }
            setDefaultBranchHierarchyDescription4(
              branchData.hierarchyDescription,
            );
          }

          //get tree Info 4
          treeData = await getTreeInfo(baseLevelData2.tree4Id);
          if (treeData === null) {
            //todo logs
            reject(false);
            return false;
          }
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            responseRequest.sourceBaseLevelId + "_C4",
            "BAseLEvel",
            "Tree4_Classify4Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            reject(false);
            return false;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree4 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree4 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              reject(false);
              return false;
            }
          }
        }
        resolve(true);
      });

      tree5Promise = new Promise(async (resolve, reject) => {
        if (baseLevelData2.tree5Id !== TPGlobal.Tree.NA_TreeCode) {
          if (responseRequest.branchTree5Id) {
            //get branch by id in order to get hierarchy description of branch
            branchData = await getBranchInfo(responseRequest.branchTree5Id);
            if (branchData == null) {
              //todo logs
              reject(false);
              return false;
            }
            setDefaultBranchHierarchyDescription5(
              branchData.hierarchyDescription,
            );
          }

          //get tree Info 5
          treeData = await getTreeInfo(baseLevelData2.tree5Id);
          if (treeData === null) {
            //todo logs
            reject(false);
            return false;
          }
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            responseRequest.sourceBaseLevelId + "_C5",
            "BAseLEvel",
            "Tree5_Classify5Caption",
          );
          if (localizedField === null || localizedField.length === 0) {
            //todo logs
            reject(false);
            return false;
          }
          found = false;
          for (i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree5 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree5 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
            if (!found) {
              //todo logs
              reject(false);
              return false;
            }
          }
        }
        resolve(true);
      });

      treePromises = [
        tree1Promise,
        tree2Promise,
        tree3Promise,
        tree4Promise,
        tree5Promise,
      ];
      treeResponses = await Promise.allSettled(treePromises);
      if (
        treeResponses.filter(
          (x) => x.status === "rejected" && x.reason === false,
        ).length > 0
      )
        return false;
      //#endregion "tree info"

      let command1: commandType = {
        type: commandsEnum.set_ScreenForUpdate,
        payload: {
          guidId: responseRequest.guidId,
          currentWorkflowType: responseRequest.workflowTypeId,
          currentBaseLevelId: responseRequest.sourceBaseLevelId,
          currentTree1: baseLevelData.tree1Id,
          currentTree2: baseLevelData.tree2Id,
          currentTree3: baseLevelData.tree3Id,
          currentTree4: baseLevelData.tree4Id,
          currentTree5: baseLevelData.tree5Id,

          currentLabelTree1: newLabelTree1,
          currentLabelTree2: newLabelTree2,
          currentLabelTree3: newLabelTree3,
          currentLabelTree4: newLabelTree4,
          currentLabelTree5: newLabelTree5,

          currentBranch1: responseRequest.branchTree1Id
            ? responseRequest.branchTree1Id
            : "",
          currentBranch2: responseRequest.branchTree2Id
            ? responseRequest.branchTree2Id
            : "",
          currentBranch3: responseRequest.branchTree3Id
            ? responseRequest.branchTree3Id
            : "",
          currentBranch4: responseRequest.branchTree4Id
            ? responseRequest.branchTree4Id
            : "",
          currentBranch5: responseRequest.branchTree5Id
            ? responseRequest.branchTree5Id
            : "",
          currentDescriptionBranch1: responseRequest.branch1Description
            ? responseRequest.branch1Description
            : "",
          currentDescriptionBranch2: responseRequest.branch2Description
            ? responseRequest.branch2Description
            : "",
          currentDescriptionBranch3: responseRequest.branch3Description
            ? responseRequest.branch3Description
            : "",
          currentDescriptionBranch4: responseRequest.branch4Description
            ? responseRequest.branch4Description
            : "",
          currentDescriptionBranch5: responseRequest.branch5Description
            ? responseRequest.branch5Description
            : "",
          weight: responseRequest.weight,
          applyForAllOrganizationsRelations: applyForAllOrganizationsRelations,
        },
      };
      dispatchCommand(command1);
      return true;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getWorkflowAssignmentById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getWorkflowAssignmentById ex`);
      return false;
    }
  };

  const handleOkButtonClick = async () => {
    let hasError: boolean = false;
    let recordInputDTO: WorkflowAssignmentInputDTO = {
      guidId: insertUpdateState.guidId,
      workflowTypeId: insertUpdateState.currentWorkflowType,
      baseLevelId: insertUpdateState.applyForAllOrganizationsRelations
        ? ""
        : insertUpdateState.currentBaseLevelId,
      branchTree1Id:
        insertUpdateState.currentTree1 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch1,
      branchTree2Id:
        insertUpdateState.currentTree2 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch2,
      branchTree3Id:
        insertUpdateState.currentTree3 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch3,
      branchTree4Id:
        insertUpdateState.currentTree4 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch4,
      branchTree5Id:
        insertUpdateState.currentTree5 === TPGlobal.Tree.NA_TreeCode
          ? "NA"
          : insertUpdateState.currentBranch5,
      weight: insertUpdateState.weight,
      typistGuid: TPGlobal.currentUserGuid,
      baseLevelSourceId: insertUpdateState.currentBaseLevelId,
    };
    let inputDTOValidator = new WorkflowAssignmentInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    let command1: commandType = {
      type: commandsEnum.change_Errors,
      payload: {},
    };
    command1.payload.guidAssignmentErrorMessage = "";
    command1.payload.workflowTypeErrorMessage = "";
    command1.payload.baselevelErrorMessage = "";
    command1.payload.classificator1ErrorMessage = "";
    command1.payload.classificator2ErrorMessage = "";
    command1.payload.classificator3ErrorMessage = "";
    command1.payload.classificator4ErrorMessage = "";
    command1.payload.classificator5ErrorMessage = "";
    command1.payload.weightErrorMessage = "";
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      hasError = true;
      //assignment guid
      if (resultValidator.guidId) {
        command1.payload.guidAssignmentErrorMessage = await TPI18N.GetResource(
          resultValidator.guidId,
        );
      }
      //workflow type id
      if (resultValidator.workflowTypeId) {
        command1.payload.workflowTypeErrorMessage = await TPI18N.GetResource(
          resultValidator.workflowTypeId,
        );
      }
      //base level
      if (resultValidator.baseLevelId) {
        command1.payload.baselevelErrorMessage = await TPI18N.GetResource(
          resultValidator.baseLevelId,
        );
      }
      //Source base level
      if (resultValidator.baseLevelSourceId) {
        command1.payload.baselevelErrorMessage = await TPI18N.GetResource(
          resultValidator.baseLevelSourceId,
        );
      }
      //branch1
      if (resultValidator.branchTree1Id) {
        command1.payload.classificator1ErrorMessage = await TPI18N.GetResource(
          resultValidator.branchTree1Id,
        );
      }
      //branch2
      if (resultValidator.branchTree2Id) {
        command1.payload.classificator2ErrorMessage = await TPI18N.GetResource(
          resultValidator.branchTree2Id,
        );
      }
      //branch3
      if (resultValidator.branchTree3Id) {
        command1.payload.classificator3ErrorMessage = await TPI18N.GetResource(
          resultValidator.branchTree3Id,
        );
      }
      //bracnh4
      if (resultValidator.branchTree4Id) {
        command1.payload.classificator4ErrorMessage = await TPI18N.GetResource(
          resultValidator.branchTree4Id,
        );
      }
      //branch5
      if (resultValidator.branchTree5Id) {
        command1.payload.classificator5ErrorMessage = await TPI18N.GetResource(
          resultValidator.branchTree5Id,
        );
      }
      //weight
      if (resultValidator.weight) {
        command1.payload.weightErrorMessage = await TPI18N.GetResource(
          resultValidator.weight,
        );
      }
    }
    if (
      insertUpdateState.currentBranch1 === "" &&
      insertUpdateState.currentBranch2 === "" &&
      insertUpdateState.currentBranch3 === "" &&
      insertUpdateState.currentBranch4 === "" &&
      insertUpdateState.currentBranch5 === ""
    ) {
      command1.payload.baselevelErrorMessage =
        errorMustSelectAtLeastOneClassificatorLabel;
      hasError = true;
    }
    dispatchCommand(command1);
    if (hasError) {
      return;
    }
    //revert not available tree branches
    if (insertUpdateState.currentTree1 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.branchTree1Id = "";
    }
    if (insertUpdateState.currentTree2 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.branchTree2Id = "";
    }
    if (insertUpdateState.currentTree3 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.branchTree3Id = "";
    }
    if (insertUpdateState.currentTree4 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.branchTree4Id = "";
    }
    if (insertUpdateState.currentTree5 === TPGlobal.Tree.NA_TreeCode) {
      recordInputDTO.branchTree5Id = "";
    }
    if (mode == "Insert") {
      await insertWorkflowAssignment(recordInputDTO);
    } else {
      await updateWorkflowAssignment(recordInputDTO);
    }
  };

  const insertWorkflowAssignment = async (
    inputDTO: WorkflowAssignmentInputDTO,
  ) => {
    let serviceClient = new WorkflowAssignmentService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.insertWorkflowAssignment(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertWorkflowAssignment ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertWorkflowAssignment ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateWorkflowAssignment = async (
    inputDTO: WorkflowAssignmentInputDTO,
  ) => {
    let serviceClient = new WorkflowAssignmentService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateWorkflowAssignment(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateWorkflowAssignment ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updateWorkflowAssignment ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const handleOnBranchChange = (
    index: number,
    branchId: string,
    branchDescription: string,
    branchHierachyDescription: string,
  ) => {
    switch (index) {
      case 1:
        setDefaultBranchHierarchyDescription1(branchHierachyDescription);
        break;
      case 2:
        setDefaultBranchHierarchyDescription2(branchHierachyDescription);
        break;
      case 3:
        setDefaultBranchHierarchyDescription3(branchHierachyDescription);
        break;
      case 4:
        setDefaultBranchHierarchyDescription4(branchHierachyDescription);
        break;
      case 5:
        setDefaultBranchHierarchyDescription5(branchHierachyDescription);
        break;
      default:
        break;
    }
    let command1: commandType = {
      type: commandsEnum.change_CurrentBranch,
      payload: {
        treeNumber: index,
        newBranch: branchId,
        newDescriptionBranch: branchDescription,
      },
    };
    dispatchCommand(command1);
  };

  //Initial State
  const initialStateBLL: InsertUpdateStateType = {
    guidId: "",
    currentWorkflowType: "",
    currentBaseLevelId: "",
    currentTree1: "",
    currentTree2: "",
    currentTree3: "",
    currentTree4: "",
    currentTree5: "",
    currentLabelTree1: "",
    currentLabelTree2: "",
    currentLabelTree3: "",
    currentLabelTree4: "",
    currentLabelTree5: "",
    currentBranch1: "",
    currentBranch2: "",
    currentBranch3: "",
    currentBranch4: "",
    currentBranch5: "",
    currentDescriptionBranch1: "",
    currentDescriptionBranch2: "",
    currentDescriptionBranch3: "",
    currentDescriptionBranch4: "",
    currentDescriptionBranch5: "",
    weight: "",
    applyForAllOrganizationsRelations: false,
    alreadyLoadedRecordForUpdate: false,
    //validators
    guidAssignmentErrorMessage: "",
    workflowTypeErrorMessage: "",
    baselevelErrorMessage: "",
    classificator1ErrorMessage: "",
    classificator2ErrorMessage: "",
    classificator3ErrorMessage: "",
    classificator4ErrorMessage: "",
    classificator5ErrorMessage: "",
    weightErrorMessage: "",
  };

  //reducer definition
  const [insertUpdateState, dispatchCommand] = useReducer(
    doCommand,
    initialStateBLL,
  );
  function doCommand(prevState: InsertUpdateStateType, command: commandType) {
    let newInsertUpdateState: InsertUpdateStateType;
    let i: number;
    switch (command.type) {
      case commandsEnum.set_GuidId:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.guidId = command.payload.newGuid;
        newInsertUpdateState.guidAssignmentErrorMessage = "";
        return newInsertUpdateState;
      case commandsEnum.change_currentWorkflow:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.currentWorkflowType =
          command.payload.newWorkflowTypeId;
        newInsertUpdateState.workflowTypeErrorMessage = "";
        return newInsertUpdateState;
      case commandsEnum.change_OrganizationRelation:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.currentBaseLevelId =
          command.payload.newBaseLevelId;
        newInsertUpdateState.currentTree1 = command.payload.newTree1;
        newInsertUpdateState.currentTree2 = command.payload.newTree2;
        newInsertUpdateState.currentTree3 = command.payload.newTree3;
        newInsertUpdateState.currentTree4 = command.payload.newTree4;
        newInsertUpdateState.currentTree5 = command.payload.newTree5;
        newInsertUpdateState.currentLabelTree1 = command.payload.newLabelTree1;
        newInsertUpdateState.currentLabelTree2 = command.payload.newLabelTree2;
        newInsertUpdateState.currentLabelTree3 = command.payload.newLabelTree3;
        newInsertUpdateState.currentLabelTree4 = command.payload.newLabelTree4;
        newInsertUpdateState.currentLabelTree5 = command.payload.newLabelTree5;
        newInsertUpdateState.currentBranch1 = "";
        newInsertUpdateState.currentBranch2 = "";
        newInsertUpdateState.currentBranch3 = "";
        newInsertUpdateState.currentBranch4 = "";
        newInsertUpdateState.currentBranch5 = "";
        newInsertUpdateState.currentDescriptionBranch1 = "";
        newInsertUpdateState.currentDescriptionBranch2 = "";
        newInsertUpdateState.currentDescriptionBranch3 = "";
        newInsertUpdateState.currentDescriptionBranch4 = "";
        newInsertUpdateState.currentDescriptionBranch5 = "";

        newInsertUpdateState.baselevelErrorMessage = "";
        newInsertUpdateState.classificator1ErrorMessage = "";
        newInsertUpdateState.classificator2ErrorMessage = "";
        newInsertUpdateState.classificator3ErrorMessage = "";
        newInsertUpdateState.classificator4ErrorMessage = "";
        newInsertUpdateState.classificator5ErrorMessage = "";

        return newInsertUpdateState;
      case commandsEnum.change_CurrentBranch:
        newInsertUpdateState = { ...prevState };
        switch (command.payload.treeNumber) {
          case 1:
            newInsertUpdateState.currentBranch1 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch1 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator1ErrorMessage = "";
            break;
          case 2:
            newInsertUpdateState.currentBranch2 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch2 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator2ErrorMessage = "";
            break;
          case 3:
            newInsertUpdateState.currentBranch3 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch3 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator3ErrorMessage = "";
            break;
          case 4:
            newInsertUpdateState.currentBranch4 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch4 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator4ErrorMessage = "";
            break;
          case 5:
            newInsertUpdateState.currentBranch5 = command.payload.newBranch;
            newInsertUpdateState.currentDescriptionBranch5 =
              command.payload.newDescriptionBranch;
            newInsertUpdateState.classificator5ErrorMessage = "";
            break;
        }
        return newInsertUpdateState;
      case commandsEnum.change_ApplyForAll:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.applyForAllOrganizationsRelations =
          !newInsertUpdateState.applyForAllOrganizationsRelations;
        return newInsertUpdateState;
      case commandsEnum.change_Weight:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.weight = command.payload.newWeight;
        newInsertUpdateState.weightErrorMessage = "";
        return newInsertUpdateState;
      case commandsEnum.change_Errors:
        newInsertUpdateState = { ...prevState };
        const keys = Object.keys(command.payload);
        for (i = 0; i <= keys.length - 1; i++) {
          newInsertUpdateState[keys[i]] = command.payload[keys[i]];
        }
        return newInsertUpdateState;

      case commandsEnum.set_ScreenForUpdate:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.guidId = command.payload.guidId;
        newInsertUpdateState.currentWorkflowType =
          command.payload.currentWorkflowType;
        newInsertUpdateState.currentBaseLevelId =
          command.payload.currentBaseLevelId;
        newInsertUpdateState.currentTree1 = command.payload.currentTree1;
        newInsertUpdateState.currentTree2 = command.payload.currentTree2;
        newInsertUpdateState.currentTree3 = command.payload.currentTree3;
        newInsertUpdateState.currentTree4 = command.payload.currentTree4;
        newInsertUpdateState.currentTree5 = command.payload.currentTree5;
        newInsertUpdateState.currentLabelTree1 =
          command.payload.currentLabelTree1;
        newInsertUpdateState.currentLabelTree2 =
          command.payload.currentLabelTree2;
        newInsertUpdateState.currentLabelTree3 =
          command.payload.currentLabelTree3;
        newInsertUpdateState.currentLabelTree4 =
          command.payload.currentLabelTree4;
        newInsertUpdateState.currentLabelTree5 =
          command.payload.currentLabelTree5;
        newInsertUpdateState.currentBranch1 = command.payload.currentBranch1;
        newInsertUpdateState.currentBranch2 = command.payload.currentBranch2;
        newInsertUpdateState.currentBranch3 = command.payload.currentBranch3;
        newInsertUpdateState.currentBranch4 = command.payload.currentBranch4;
        newInsertUpdateState.currentBranch5 = command.payload.currentBranch5;
        newInsertUpdateState.currentDescriptionBranch1 =
          command.payload.currentDescriptionBranch1;
        newInsertUpdateState.currentDescriptionBranch2 =
          command.payload.currentDescriptionBranch2;
        newInsertUpdateState.currentDescriptionBranch3 =
          command.payload.currentDescriptionBranch3;
        newInsertUpdateState.currentDescriptionBranch4 =
          command.payload.currentDescriptionBranch4;
        newInsertUpdateState.currentDescriptionBranch5 =
          command.payload.currentDescriptionBranch5;
        newInsertUpdateState.weight = command.payload.weight;
        newInsertUpdateState.applyForAllOrganizationsRelations =
          command.payload.applyForAllOrganizationsRelations;
        newInsertUpdateState.alreadyLoadedRecordForUpdate = true;
        return newInsertUpdateState;
      default:
        return prevState;
    }
  }

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadWorkflowAssignmentInfo();
  }, []);

  //#region  Render
  return (
    <>
      <ContentVerticalTabInsertUpdateStyled>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col-10">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <TPPageSection>
                {/* Workflow to assign  */}
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        onChange={handleWorkflowTypeChange}
                        dataSource={workflowTypesKeyValue}
                        value={insertUpdateState.currentWorkflowType}
                        labelText={workflowTypeLabel}
                        isHorizontal={false}
                        isMandatory={true}
                        errorMessage={
                          insertUpdateState.workflowTypeErrorMessage
                        }
                      ></TPSelect>
                    </div>
                  </div>
                </div>
                {/* Organization Relation */}
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        onChange={handleOrganizationRelationChange}
                        dataSource={organizationsRelationsKeyValue}
                        value={insertUpdateState.currentBaseLevelId}
                        labelText={organizationsRelationsLabel}
                        isHorizontal={false}
                        isMandatory={true}
                        errorMessage={insertUpdateState.baselevelErrorMessage}
                      ></TPSelect>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <TPTextBox
                        id="IdTextBox"
                        onChange={handleWeightChange}
                        value={insertUpdateState.weight}
                        labelText={weightLabel}
                        isMandatory={true}
                        errorMessage={insertUpdateState.weightErrorMessage}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Classificator 1 */}
                  {insertUpdateState.currentBaseLevelId === "" ? (
                    <>
                      <div className="col-6 mt-2">
                        <div className="form-group">
                          <span className="tpcircle-text">1</span>&nbsp;{"-"}
                          &nbsp;
                          <TPTextBox
                            id="IdTextBox"
                            onChange={() => {
                              TPGlobal.foo();
                            }}
                            value=""
                            disabled={true}
                            labelText={dummyClassfierLabel}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {insertUpdateState.currentTree1 !== "" &&
                        insertUpdateState.currentTree1 !==
                          TPGlobal.Tree.NA_TreeCode && (
                          <div className="col-6 mt-2">
                            <div className="form-group">
                              <span className="tpcircle-text">1</span>&nbsp;
                              {"-"}
                              &nbsp;
                              <TPBranchSelection2
                                labelText={insertUpdateState.currentLabelTree1}
                                disabledTextBox={false}
                                treeId={insertUpdateState.currentTree1}
                                modalAcceptLabel={modalAcceptLabel}
                                modalCancelLabel={modalCancelLabel}
                                modalSelectedBranchLabel={
                                  modalSelectedBranchLabel
                                }
                                modalTitle={modalTitleLabel}
                                mustSelectLastLevelBranch={false}
                                value={{
                                  branchId: insertUpdateState.currentBranch1,
                                  branchDescription:
                                    insertUpdateState.currentDescriptionBranch1,
                                  branchHierarchyDescription:
                                    defaultBranchHierarchyDescription1,
                                }}
                                onChange={(
                                  branchId: string,
                                  branchDescription: string,
                                  branchHierachyDescription: string,
                                ) => {
                                  handleOnBranchChange(
                                    1,
                                    branchId,
                                    branchDescription,
                                    branchHierachyDescription,
                                  );
                                }}
                                emptyLabel={emptyLabel}
                                errorMessage={
                                  insertUpdateState.classificator1ErrorMessage
                                }
                                onIsLoadingProgress={(value: boolean) => {
                                  setIsLoadingScreen(value);
                                }}
                              ></TPBranchSelection2>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                  {/* Classificator 2 */}
                  {insertUpdateState.currentBaseLevelId === "" ? (
                    <>
                      <div className="col-6 mt-2">
                        <div className="form-group">
                          <span className="tpcircle-text">2</span>&nbsp;{"-"}
                          &nbsp;
                          <TPTextBox
                            id="IdTextBox"
                            onChange={() => {
                              TPGlobal.foo();
                            }}
                            value=""
                            disabled={true}
                            labelText={dummyClassfierLabel}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {insertUpdateState.currentTree2 !== "" &&
                        insertUpdateState.currentTree2 !==
                          TPGlobal.Tree.NA_TreeCode && (
                          <div className="col-6 mt-2">
                            <div className="form-group">
                              <span className="tpcircle-text">2</span>&nbsp;
                              {"-"}
                              &nbsp;
                              <TPBranchSelection2
                                labelText={insertUpdateState.currentLabelTree2}
                                disabledTextBox={false}
                                treeId={insertUpdateState.currentTree2}
                                modalAcceptLabel={modalAcceptLabel}
                                modalCancelLabel={modalCancelLabel}
                                modalSelectedBranchLabel={
                                  modalSelectedBranchLabel
                                }
                                modalTitle={modalTitleLabel}
                                mustSelectLastLevelBranch={false}
                                value={{
                                  branchId: insertUpdateState.currentBranch2,
                                  branchDescription:
                                    insertUpdateState.currentDescriptionBranch2,
                                  branchHierarchyDescription:
                                    defaultBranchHierarchyDescription2,
                                }}
                                onChange={(
                                  branchId: string,
                                  branchDescription: string,
                                  branchHierachyDescription: string,
                                ) => {
                                  handleOnBranchChange(
                                    2,
                                    branchId,
                                    branchDescription,
                                    branchHierachyDescription,
                                  );
                                }}
                                emptyLabel={emptyLabel}
                                errorMessage={
                                  insertUpdateState.classificator2ErrorMessage
                                }
                                onIsLoadingProgress={(value: boolean) => {
                                  setIsLoadingScreen(value);
                                }}
                              ></TPBranchSelection2>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </div>
                <div className="row">
                  {/* Classificator 3 */}
                  {insertUpdateState.currentBaseLevelId === "" ? (
                    <>
                      <div className="col-6 mt-2">
                        <div className="form-group">
                          <span className="tpcircle-text">3</span>&nbsp;{"-"}
                          &nbsp;
                          <TPTextBox
                            id="IdTextBox"
                            onChange={() => {
                              TPGlobal.foo();
                            }}
                            value=""
                            disabled={true}
                            labelText={dummyClassfierLabel}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {insertUpdateState.currentTree3 !== "" &&
                        insertUpdateState.currentTree3 !==
                          TPGlobal.Tree.NA_TreeCode && (
                          <div className="col-6 mt-2">
                            <div className="form-group">
                              <span className="tpcircle-text">3</span>&nbsp;
                              {"-"}
                              &nbsp;
                              <TPBranchSelection2
                                labelText={insertUpdateState.currentLabelTree3}
                                disabledTextBox={false}
                                treeId={insertUpdateState.currentTree3}
                                modalAcceptLabel={modalAcceptLabel}
                                modalCancelLabel={modalCancelLabel}
                                modalSelectedBranchLabel={
                                  modalSelectedBranchLabel
                                }
                                modalTitle={modalTitleLabel}
                                mustSelectLastLevelBranch={false}
                                value={{
                                  branchId: insertUpdateState.currentBranch3,
                                  branchDescription:
                                    insertUpdateState.currentDescriptionBranch3,
                                  branchHierarchyDescription:
                                    defaultBranchHierarchyDescription3,
                                }}
                                onChange={(
                                  branchId: string,
                                  branchDescription: string,
                                  branchHierachyDescription: string,
                                ) => {
                                  handleOnBranchChange(
                                    3,
                                    branchId,
                                    branchDescription,
                                    branchHierachyDescription,
                                  );
                                }}
                                emptyLabel={emptyLabel}
                                errorMessage={
                                  insertUpdateState.classificator3ErrorMessage
                                }
                                onIsLoadingProgress={(value: boolean) => {
                                  setIsLoadingScreen(value);
                                }}
                              ></TPBranchSelection2>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                  {/* Classificator 4 */}
                  {insertUpdateState.currentBaseLevelId === "" ? (
                    <>
                      <div className="col-6 mt-2">
                        <div className="form-group">
                          <span className="tpcircle-text">4</span>&nbsp;{"-"}
                          &nbsp;
                          <TPTextBox
                            id="IdTextBox"
                            onChange={() => {
                              TPGlobal.foo();
                            }}
                            value=""
                            disabled={true}
                            labelText={dummyClassfierLabel}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {insertUpdateState.currentTree4 !== "" &&
                        insertUpdateState.currentTree4 !==
                          TPGlobal.Tree.NA_TreeCode && (
                          <div className="col-6 mt-2">
                            <div className="form-group">
                              <span className="tpcircle-text">4</span>&nbsp;
                              {"-"}
                              &nbsp;
                              <TPBranchSelection2
                                labelText={insertUpdateState.currentLabelTree4}
                                disabledTextBox={false}
                                treeId={insertUpdateState.currentTree4}
                                modalAcceptLabel={modalAcceptLabel}
                                modalCancelLabel={modalCancelLabel}
                                modalSelectedBranchLabel={
                                  modalSelectedBranchLabel
                                }
                                modalTitle={modalTitleLabel}
                                mustSelectLastLevelBranch={false}
                                value={{
                                  branchId: insertUpdateState.currentBranch4,
                                  branchDescription:
                                    insertUpdateState.currentDescriptionBranch4,
                                  branchHierarchyDescription:
                                    defaultBranchHierarchyDescription4,
                                }}
                                onChange={(
                                  branchId: string,
                                  branchDescription: string,
                                  branchHierachyDescription: string,
                                ) => {
                                  handleOnBranchChange(
                                    4,
                                    branchId,
                                    branchDescription,
                                    branchHierachyDescription,
                                  );
                                }}
                                emptyLabel={emptyLabel}
                                errorMessage={
                                  insertUpdateState.classificator4ErrorMessage
                                }
                                onIsLoadingProgress={(value: boolean) => {
                                  setIsLoadingScreen(value);
                                }}
                              ></TPBranchSelection2>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </div>
                <div className="row">
                  {/* Classificator 5 */}
                  {insertUpdateState.currentBaseLevelId === "" ? (
                    <>
                      <div className="col-6 mt-2">
                        <div className="form-group">
                          <span className="tpcircle-text">5</span>&nbsp;{"-"}
                          &nbsp;
                          <TPTextBox
                            id="IdTextBox"
                            onChange={() => {
                              TPGlobal.foo();
                            }}
                            value=""
                            disabled={true}
                            labelText={dummyClassfierLabel}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {insertUpdateState.currentTree5 !== "" &&
                        insertUpdateState.currentTree5 !==
                          TPGlobal.Tree.NA_TreeCode && (
                          <>
                            <div className="col-6 mt-2">
                              <div className="form-group">
                                <span className="tpcircle-text">5</span>&nbsp;
                                {"-"}
                                &nbsp;
                                <TPBranchSelection2
                                  labelText={
                                    insertUpdateState.currentLabelTree5
                                  }
                                  disabledTextBox={false}
                                  treeId={insertUpdateState.currentTree5}
                                  modalAcceptLabel={modalAcceptLabel}
                                  modalCancelLabel={modalCancelLabel}
                                  modalSelectedBranchLabel={
                                    modalSelectedBranchLabel
                                  }
                                  modalTitle={modalTitleLabel}
                                  mustSelectLastLevelBranch={false}
                                  value={{
                                    branchId: insertUpdateState.currentBranch5,
                                    branchDescription:
                                      insertUpdateState.currentDescriptionBranch5,
                                    branchHierarchyDescription:
                                      defaultBranchHierarchyDescription5,
                                  }}
                                  onChange={(
                                    branchId: string,
                                    branchDescription: string,
                                    branchHierachyDescription: string,
                                  ) => {
                                    handleOnBranchChange(
                                      5,
                                      branchId,
                                      branchDescription,
                                      branchHierachyDescription,
                                    );
                                  }}
                                  emptyLabel={emptyLabel}
                                  errorMessage={
                                    insertUpdateState.classificator5ErrorMessage
                                  }
                                  onIsLoadingProgress={(value: boolean) => {
                                    setIsLoadingScreen(value);
                                  }}
                                ></TPBranchSelection2>
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  )}
                </div>
                {/* ApplyForAllOrganizationsRelation */}
                <div className="row mt-2">
                  <div className="col-6">
                    <div className="form-group">
                      <TPCheckBox
                        id="IdCheckBox"
                        labelText={applyForAllOrganizationsRelationsLabel}
                        checked={
                          insertUpdateState.applyForAllOrganizationsRelations
                        }
                        onChange={(e: any) => handleApplyForAllChange()}
                      ></TPCheckBox>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-10"></div>
                  <div className="alert alert-info">
                    {noteApplyForAllOrganizationsRelationsLabel}
                  </div>
                </div>
                {/* Note ApplyForAllOrganizationsRelation */}
              </TPPageSection>
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <TPPageAcceptCancelButtonsContainer>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={handleOkButtonClick}
                >
                  {saveButtonLabel}
                </TPButton>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.link}
                  onClick={handleCancelButtonClick}
                  className={"ms-2"}
                >
                  {cancelButtonLabel}
                </TPButton>
              </TPPageAcceptCancelButtonsContainer>
            </div>
          </div>
        </TPLoadingOverlay>
      </ContentVerticalTabInsertUpdateStyled>
    </>
  );
  //#endregion
};

export default WorkflowAssignmentInsertUpdate;
