import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageSectionTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { GroupsViewModel } from "@/models/Groups/GroupsModels";
import { GroupsService } from "@/services/GroupsService";
import { TPI18N } from "@/services/I18nService";
import FileSaver from "file-saver";
import { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { tableStyles } from "../FormDesigner/StyeFromDesigner";

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<GroupsViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

type commandType = {
  type: commandsEnum;
  payload: any;
};

interface properties {
  currentUser: any;
  newUser: any;
  callback: Function;
}

interface ISelectedRows {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: GroupsViewModel[] | never[];
}

const ReassignUsersGroupsComponent: React.FC<properties> = ({
  currentUser,
  newUser,
  callback,
}) => {
  const componentFileName: string = "ReassignUsersGroupsComponent.tsx";

  const resourceGroupsModal = "ReassignUsersGroupsModalComponent";
  const resourceGroupsTable = "ReassignUsersGroupsTableComponent";

  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

  const [searchLabel, setSearchLabel] = useState("");
  const [exportLabel, setExportLabel] = useState("");
  const [refreshLabel, setRefreshLabel] = useState("");

  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalSuccessTitle, setModalSuccessTitle] = useState("");
  const [modalActions, setModalActions] = useState(false);

  const [selectedRecordsLabel, setSelectedRecordsLabel] = useState("");
  const [recordsProcessedLabel, setRecordsProcessedLabel] = useState("");
  const [recordsFailedLabel, setRecordsFailedLabel] = useState("");

  const [reassignUserButtonDisabled, setReassignUserButtonDisabled] =
    useState(true);

  const [reassignButtonLabel, setReassignButtonLabel] = useState("");

  const [groupIdColumnLabel, setgroupIdColumnLabel] = useState("");
  const [descriptionColumnLabel, setdescriptionColumnLabel] = useState("");

  const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");

  const [data, setData] = useState<GroupsViewModel[]>([]);
  const [filteredData, setFilteredData] = useState<GroupsViewModel[]>([]);

  const loadResourcesAndUsersFilter = async () => {
    setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
    setExportLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
    );
    setRefreshLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
    );
    setSelectedRecordsLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectedRecordsLabel")
    );
    setRecordsProcessedLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "RecordsProcessedLabel")
    );
    setRecordsFailedLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "RecordsFailedLabel")
    );
    setReassignButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReassignButtonLabel")
    );
    setModalSuccessTitle(
      await TPI18N.GetText(
        resourceGroupsModal,
        "ReassignUsersModalSuccessTitle"
      )
    );
    setgroupIdColumnLabel(
      await TPI18N.GetText(resourceGroupsTable, "groupIdColumnLabel")
    );
    setdescriptionColumnLabel(
      await TPI18N.GetText(resourceGroupsTable, "descriptionColumnLabel")
    );
    setThereAreNoRecordsToShow(
      await TPI18N.GetText(resourceGroupsTable, "ThereAreNoRecordsToShow")
    );
  };

  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  const [groupsSelectedRows, setGroupsSelectedRows] = useState<ISelectedRows>({
    allSelected: false,
    selectedCount: 0,
    selectedRows: [],
  });

  useEffect(() => {
    setReassignUserButtonDisabled(
      !newUser || groupsSelectedRows.selectedCount == 0
    );
  }, [newUser, groupsSelectedRows]);

  const fetchData = async function () {
    setIsLoadingScreen(true);
    const serviceClientInstance = new GroupsService();
    const response = await serviceClientInstance.getGroupsByUserId(
      currentUser.key,
      false,
      false,
      [200]
    );

    setData(response);
    setFilteredData(response);
    callback(response.length);
    setIsLoadingScreen(false);
  };

  const setupGridColumns = (prevState: AdminStateType) => {
    try {
      let newState: AdminStateType;
      newState = { ...prevState };
      let newColumns: Array<any> = [];

      //name primary language
      newColumns.push({
        name: groupIdColumnLabel,
        selector: (row: { [x: string]: any }) => row["groupId"],
        Cell: (row: { [x: string]: any }) => row["groupId"],
        sortable: true,
      });

      //name login
      newColumns.push({
        name: descriptionColumnLabel,
        selector: (row: { [x: string]: any }) => row["description"],
        cell: (row: { [x: string]: any }) => row["user"]["firstName"],
        sortable: true,
      });

      newState.gridColumns = [...newColumns];
      return newState;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} setupGridColumns ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} setupGridColumns ex`);
      return prevState;
    }
  };

  const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
  };

  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);

  function doCommand(prevState: AdminStateType, command: commandType) {
    switch (command.type) {
      case commandsEnum.set_filterIsLoaded:
        let newStateFilter: AdminStateType;
        newStateFilter = { ...prevState };
        newStateFilter.filterIsLoaded = true;
        return newStateFilter;
        break;
      case commandsEnum.setup_grid_columns:
        let newStateColumns: AdminStateType = setupGridColumns(prevState);
        newStateColumns.columnsAreLoaded = true;
        return newStateColumns;
        break;
      case commandsEnum.reload_grid:
        let newStateGrid: AdminStateType;
        newStateGrid = { ...prevState };
        newStateGrid.gridData = command.payload;
        return newStateGrid;
        break;
      case commandsEnum.change_selectedFilter:
        let newStateChangeFilter: AdminStateType;
        newStateChangeFilter = { ...prevState };
        newStateChangeFilter.selectedFilter = command.payload;
        return newStateChangeFilter;
      case commandsEnum.change_search_pattern:
        let newStatePattern: AdminStateType;
        newStatePattern = { ...prevState };
        newStatePattern.searchPattern = command.payload;
        return newStatePattern;
        break;
      default:
        return prevState;
    }
  }

  const handleReassignUser = async () => {
    let serviceClient = new GroupsService();
    let expectedCodes: Array<number> = [200, 404];

    let guids = [];

    for (let i = 0; i <= groupsSelectedRows.selectedRows.length - 1; i++) {
      guids.push(groupsSelectedRows.selectedRows[i].groupId);
    }

    let DTO = {
      GroupIds: guids,
      GuidUserNew: newUser.key,
      GuidUserCurrent: currentUser.key,
    };

    try {
      let responseRequest = await serviceClient.reassignGroups(
        DTO,
        false,
        true,
        expectedCodes
      );

      setModalSuccess(true);

      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reloadDataGrid ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} reloadDataGrid ex`);
      return [];
    }
  };

  const reloadDataGrid = async (selectedFilter: string) => {
    let serviceClient = new GroupsService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getGroupsByUserId(
        currentUser.key,
        false,
        true,
        expectedCodes
      );

      setIsLoadingScreen(false);
      callback(responseRequest.length);

      return [...responseRequest];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} reloadDataGrid ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} reloadDataGrid ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const reloadGridCommand = () => {
    reloadDataGrid(adminState.selectedFilter)
      .then(function (result) {
        let command1: commandType = {
          type: commandsEnum.reload_grid,
          payload: result,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadGridCommand ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadGridCommand ex`);
      });
  };

  useEffect(() => {
    if (adminState.columnsAreLoaded) {
      reloadGridCommand();
    }
  }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

  const handleSearchPatternChange = (newValue: string) => {
    let command1: commandType = {
      type: commandsEnum.change_search_pattern,
      payload: newValue,
    };
    dispatchCommand(command1);
  };

  useEffect(() => {
    let searcheableColumns: Array<any> = ["groupId", "user"];
    let i: number;
    let search: string;
    search = adminState.searchPattern.trim();
    setFilteredData(
      data.filter(function (item: any, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]]["firstName"]
              ? itemany[searcheableColumns[i]]["firstName"]
                  .toString()
                  .toLowerCase()
                  .includes(search.toLowerCase())
              : false
          ) {
            return item[searcheableColumns[i]]["firstName"]
          } else if (
            itemany[searcheableColumns[i]]
              ? itemany[searcheableColumns[i]]
                  .toString()
                  .toLowerCase()
                  .includes(search.toLowerCase())
              : false
          ) {
            return item;
          }
        }
      })
    );
  }, [adminState.searchPattern]);

  useEffect(() => {
    fetchData();
    loadResourcesAndUsersFilter()
      .then(function () {
        //set filter is loaded
        let command1: commandType = {
          type: commandsEnum.set_filterIsLoaded,
          payload: null,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResourcesAndUsersFilter ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} loadResourcesAndUsersFilter ex`
        );
      });
  }, []);

  //Run when filter is loaded to get columns
  useEffect(() => {
    if (adminState.filterIsLoaded) {
      let command1: commandType = {
        type: commandsEnum.setup_grid_columns,
        payload: null,
      };
      dispatchCommand(command1);
    }
  }, [adminState.filterIsLoaded]);

  const handleSelectedRowsChange = (data: ISelectedRows) => {
    setGroupsSelectedRows(data);
  };

  const exportToCSV = (
    apiData = filteredData,
    fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  ) => {
    let newApiData = [];

    for (let i = 0; i < apiData.length; i++) {
      newApiData.push({
        groupId: apiData[i].groupId,
        description: apiData[i].user.firstName,
      });
    }
    const ws = XLSX.utils.json_to_sheet(newApiData);
    /* custom headers */
    XLSX.utils.sheet_add_aoa(ws, [["groupId", "description"]], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const handleClick = () => {
    const element = document.getElementsByName("select-all-rows");
    if (!groupsSelectedRows.allSelected) element[0]?.click();
    setTimeout(() => element[0]?.click(), 200);
  };

  useEffect(() => {
    if (currentUser) fetchData();
  }, [currentUser]);

  const handleFetchData = () => {
    fetchData();
    handleClick();
  };

  return (
    <>
      <TPPageFirstRow>
        <TPPageSearchContainer>
          <TPTextBox
            id="IdTextBox"
            icon={TPIconTypes.search}
            withIcon={true}
            value={adminState.searchPattern}
            placeholder={searchLabel}
            onChange={(e: any) => handleSearchPatternChange(e.target.value)}
            isHorizontal={true}
          />
        </TPPageSearchContainer>

        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TPButton
            id="IdButton"
            type={TPButtonTypes.icon}
            onClick={() =>
              exportToCSV(filteredData, "ReassignUsers-Groups-Data")
            }
            icon={TPIconTypes.excel}
            text={exportLabel}
          />
          <TPButton
            id="IdButton"
            type={TPButtonTypes.icon}
            onClick={() => handleFetchData()}
            text={refreshLabel}
            icon={TPIconTypes.refresh}
          />
          <TPPageAcceptCancelButtonsContainer>
            <TPButton
              id="IdButton"
              type={TPButtonTypes.primary}
              onClick={handleReassignUser}
              disabled={reassignUserButtonDisabled}
            >
              {reassignButtonLabel}
            </TPButton>
          </TPPageAcceptCancelButtonsContainer>
        </div>
      </TPPageFirstRow>
      <DataTable
        persistTableHead={true}
        fixedHeader={true}
        fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
        responsive={true}
        striped={true}
        highlightOnHover={true}
        pagination
        paginationPerPage={10}
        paginationComponentOptions={TPGlobal.paginationComponentOptions}
        columns={adminState.gridColumns}
        data={filteredData as any}
        noDataComponent={thereAreNoRecordsToShow}
        sortFunction={TPGlobal.datatableCustomSort}
        customStyles={tableStyles}
        onSelectedRowsChange={handleSelectedRowsChange}
        selectableRows={true}
        selectableRowsHighlight
      />
      <TPModalSuccess
        modalState={{
          title: "success",
          yesLabel: "Ok",
          isShown: modalSuccess,
          callBackFunction: () => {
            setModalSuccess(false);
            setModalActions(false);
            fetchData();
            handleClick();
          },
        }}
      >
        <TPPageSectionTitle>{modalSuccessTitle}</TPPageSectionTitle>
        <br />
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{selectedRecordsLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge tp-bg-primary"
            }
          >
            {groupsSelectedRows.selectedCount}
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{recordsProcessedLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge"
            }
            style={{ backgroundColor: "green" }}
          >
            {groupsSelectedRows.selectedCount}
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "20px",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <p>{recordsFailedLabel}</p>
          <div
            className={
              "d-flex align-items-center justify-content-center badge tp-badge"
            }
            style={{ backgroundColor: "red" }}
          >
            0
          </div>
        </div>
      </TPModalSuccess>
    </>
  );
};

export default ReassignUsersGroupsComponent;
