import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import React, { ReactElement, useRef, useState } from "react";
import { StyledSearchInput } from "../design-system-styles";

type SearchInputProps = {
  id?: string;
  placeholder?: string;
  debounce?: boolean;
  debounceTime?: number;
  width?: string;
  onChange: (value: string) => void;
};

function SearchInput({
  id,
  placeholder,
  debounce = false,
  debounceTime = 500,
  width = "16em",
  onChange,
}: SearchInputProps): ReactElement {
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const inputId = `${id && `${id}-`}table-search-input`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (!debounce) {
      return onChange(value);
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      onChange(value);
    }, debounceTime);
  };

  const handleCleanInput = () => {
    setInputValue("");
    onChange("");
  };

  return (
    <StyledSearchInput width={width} focus={isFocused}>
      <input
        type="text"
        id={inputId}
        value={inputValue}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder ? placeholder : "Search"}
      />
      <TPIcon
        iconType={TPIconTypes.close}
        className="clean-input"
        style={{ opacity: inputValue ? "1" : "0" }}
        onClick={handleCleanInput}
      />
      <TPIcon
        style={{ color: !isFocused ? "#2E2E2E" : "#780096" }}
        iconType={TPIconTypes.search}
      />
    </StyledSearchInput>
  );
}

export default SearchInput;
