import allThemes from "@/assets/styles/theme";
import { Base } from "@/assets/styles/themeInterfaces";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import React from "react";
import { useEffect, useState } from "react";
import TPButton from "../bootstrap/components/buttons/TPButton";
import TPIcon from "../bootstrap/extend/TPIcons/TPIcon";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import { TPColorPicker } from "../TPColorPicker/TPColorPicker";
import TPLanguage from "../TPLanguage/TPLanguage";
import { TPThemeCreatorContainer } from "./TPThemeCreatorStyles";
import { ThemePreferenceContext } from "../../App";
import { TPPageTitle } from "../TPPage/tpPageStyles";
import TPLoadingOverlay from "../bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPI18N } from "@/services/I18nService";
import TPLabel from "../bootstrap/forms/TPLabel/TPLabel";
import TPCheckBox from "../bootstrap/forms/checkbox/TPCheckBox";
import TPWizardStep from "../TPWizard/TPWizardStep";
import TPWizard from "../TPWizard/TPWizard";
import TPWizardContent from "../TPWizard/TPWizardContent";
import TPFixedTable from "../TPFixedTable/TPFixedTable";
import {
  DataTableContainer,
  TableContainer,
  tableStyles,
} from "../bootstrap/content/tables/tpTableStyles";
import DataTable from "react-data-table-component";
import TPGlobal from "@/helpers/TPGlobal";
import TPModal from "@/layouts/TPModal/TPModal";

const componentName = "TPThemeCreator";

export const TPThemeCreatorColorPicker = ({
  onChange,
  propertyName,
  value,
  componentName,
}: any) => {
  const [color, setColor] = useState(value);
  const [hintText, setHintText] = useState("");

  const handleColorChanged = (newColor: any) => {
    if (newColor) {
      onChange && onChange(propertyName, newColor);
    }
  };

  var find = function (root: any, path: any) {
    var segments = path.split("."),
      cursor = root,
      target;

    for (var i = 0; i < segments.length; ++i) {
      target = cursor[segments[i]];
      if (typeof target == "undefined") return void 0;
      cursor = target;
    }

    return cursor;
  };

  useEffect(() => {
    // setColor(value);
    const newColor = find(value, propertyName);
    setColor(newColor);
  }, [value]);

  useEffect(() => {
    const textToSearch = toCamelCase(propertyName) + "HintText";
    TPI18N.GetText(componentName, textToSearch)
      .then((textTranslated) => {
        setHintText(textToSearch != textTranslated ? textTranslated : "");
      })
      .catch(() => {});
  }, []);

  const toCamelCase = (str: string) => {
    return str
      .split(".")
      .map(function (word, index, arr) {
        if (index == arr.length - 1) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ".";
      })
      .join("");
  };

  return (
    <>
      <TPColorPicker
        hexMode={true}
        labelText={
          <TPLanguage
            resourceId={toCamelCase(propertyName)}
            resourceSet={componentName}
          />
        }
        onChange={handleColorChanged}
        value={color}
      />

      {hintText && (
        <div className="form-text text-info d-flex align-items-center gap-1">
          <TPIcon iconType={TPIconTypes.help} />
          <span>{hintText}</span>
        </div>
      )}
    </>
  );
};

export const TPThemeCreatorPreviewSection = ({
  children,
  componentName,
  sectionTitleResourceId,
}: {
  children: any;
  componentName: string;
  sectionTitleResourceId: string;
}) => {
  return (
    <div className="mt-4">
      <TPPageTitle>
        <TPLanguage
          resourceId={sectionTitleResourceId}
          resourceSet={componentName}
        />
      </TPPageTitle>
      <hr />
      <div className="d-flex justify-content-center gap-3">{children}</div>
    </div>
  );
};

export const TPThemeCreatorTPLanguage = ({
  resourceId,
}: {
  resourceId: string;
}) => <TPLanguage resourceId={resourceId} resourceSet={componentName} />;

export const TPThemeCreator = () => {
  const themeContextData: any = React.useContext(ThemePreferenceContext);

  const [allThemesFromDb, setAllThemesFromDb] = useState(allThemes);
  const [baseTheme, setBaseTheme] = useState<Base>(allThemesFromDb["base"]);
  const [themesNames, setThemesNames] = useState<Array<TPKeyValue>>([]);
  const [currentTheme, setCurrentTheme] = useState("base");
  const relations: any = {
    primary: [
      "link.fontColor",
      "button.primary.backgroundColor",
      "button.primary.borderColor",
      "button.outlinePrimary.fontColor",
      "button.outlinePrimary.borderColor",
      "button.outlinePrimary.hover.backgroundColor",
      "button.link.fontColor",
      "tree.branch.conventions.selected.active.invisible.borderColor",
      "tree.branch.conventions.selected.inactive.visible.borderColor",
      "tree.branch.conventions.selected.inactive.invisible.borderColor",
      "checkbox.checked.backgroundColor",
      "checkbox.checked.borderColor",
      "checkbox.focus.borderColor",
      "select.option.hover.backgroundColor",
      "conditionsTable.totalConditions.backgroundColor",
      "configValues.category.itemContainer.hover.fontColor",
      "configValues.submenu.hover.backgroundColor",
      "wizard.step.active.number.backgroundColor",
      "wizard.step.active.number.fontColor",
      "wizard.step.active.number.borderColor",
      "wizard.step.active.title.fontColor",
    ],
    secondary: [
      "button.secondary.backgroundColor",
      "button.secondary.borderColor",
    ],
    redColor: [
      "mandatory",
      "errorColor",
      "errorBackground",
      "tree.branch.conventions.inactive.visible.fontColor",
      "tree.branch.conventions.inactive.invisible.fontColor",
      "tree.branch.conventions.selected.inactive.visible.fontColor",
      "notificationCenter.item.error.borderColor",
    ],
    blackColor: [
      "defaultColor",
      "configValues.submenu.a.fontColor",
      "leftMenu.logoAndStation.station.name.fontColor",
      "notificationCenter.fontColor",
    ],
    yellowColor: [
      "tree.branch.conventions.active.invisible.backgroundColor",
      "tree.branch.conventions.inactive.invisible.backgroundColor",
    ],
  };

  const [addingNewTheme, setAddingNewTheme] = useState(false);
  const [newThemeName, setNewThemeName] = useState("");

  const handleColorChanged = (propertyName: string, newColor: any) => {
    const copyBaseTheme: any = { ...baseTheme };
    const copyAllThemesFromDb: any = { ...allThemesFromDb };
    set(copyBaseTheme, propertyName, newColor?.hex);

    if (relations[propertyName])
      relations[propertyName].forEach((element: any) => {
        set(copyBaseTheme, element, newColor?.hex);
      });

    copyAllThemesFromDb[currentTheme] = copyBaseTheme;
    setBaseTheme(copyBaseTheme);
    setAllThemesFromDb(copyAllThemesFromDb);
  };

  var set = function (root: any, path: any, value: any) {
    var segments = path.split("."),
      cursor = root,
      target;

    for (var i = 0; i < segments.length - 1; ++i) {
      cursor = cursor[segments[i]] || {};
    }

    cursor[segments[segments.length - 1]] = value;
  };

  useEffect(() => {
    const themesNamesCopy: Array<TPKeyValue> = [];
    for (const [key] of Object.entries(allThemesFromDb)) {
      themesNamesCopy.push({
        key: key,
        value: key,
      });
    }
    setThemesNames(themesNamesCopy);
  }, [allThemesFromDb]);

  useEffect(() => {
    setBaseTheme({ ...allThemesFromDb[currentTheme] });
  }, [currentTheme]);

  return (
    <TPLoadingOverlay active={false}>
      <ContentVerticalNoTabsStyled>
        <TPThemeCreatorContainer>
          <div
            style={{ height: "calc(100% - 120px)", overflow: "auto" }}
            className="container-fluid"
          >
            <div className="row">
              <div className="col">
                <TPPageTitle>
                  <TPLanguage
                    resourceSet={componentName}
                    resourceId="TitleLabel"
                  />
                </TPPageTitle>
                <hr />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div className="d-flex align-items-center justify-content-space-between gap-3">
                  <TPSelect
                    id="IdSelect"
                    labelText={
                      <TPLanguage
                        resourceId="CurrentThemes"
                        resourceSet={componentName}
                      />
                    }
                    dataSource={themesNames}
                    value={currentTheme}
                    onChange={(e: any) => {
                      setCurrentTheme(e.target.value);
                      themeContextData.setAllThemesFromDb({
                        ...allThemesFromDb,
                      });
                      themeContextData.setCurrentTheme(e.target.value);
                    }}
                  />
                  <TPButton
                    onClick={() => {
                      setAddingNewTheme(true);
                    }}
                    type={TPButtonTypes.icon}
                    icon={TPIconTypes.libraryAdd}
                    text={
                      <TPLanguage
                        resourceId="Add"
                        resourceSet={componentName}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="accordion" id="accordionThemeComponents">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="primaryColors">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsePrimaryColors"
                        aria-expanded="true"
                        aria-controls="collapsePrimaryColors"
                      >
                        <TPLanguage
                          resourceId={"PrimaryColorsLabel"}
                          resourceSet={componentName}
                        />
                      </button>
                    </h2>
                    <div
                      id="collapsePrimaryColors"
                      className="accordion-collapse collapse show"
                      aria-labelledby="primaryColors"
                      data-bs-parent="#accordionThemeComponents"
                    >
                      <div className="accordion-body">
                        <div className="alert alert-primary" role="alert">
                          <TPLanguage
                            resourceId={"PrimaryColorsDescription"}
                            resourceSet={componentName}
                          />
                        </div>

                        <div className="row">
                          <div className="col">
                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"primary"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"secondary"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"mandatory"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"errorColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"errorBackground"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"defaultFontColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"whiteColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"redColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"greenColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"cherryColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"graySlightColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"borderGrayColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"whiteSmoke"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"darkGray"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"redGray"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"blackColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"yellowColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />

                            <TPThemeCreatorColorPicker
                              onChange={handleColorChanged}
                              propertyName={"darkColor"}
                              value={baseTheme}
                              componentName={componentName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="components">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseComponents"
                        aria-expanded="false"
                        aria-controls="collapseComponents"
                      >
                        <TPLanguage
                          resourceId={"ComponentsColorsLabel"}
                          resourceSet={componentName}
                        />
                      </button>
                    </h2>
                    <div
                      id="collapseComponents"
                      className="accordion-collapse collapse"
                      aria-labelledby="components"
                      data-bs-parent="#accordionThemeComponents"
                    >
                      <div className="accordion-body">
                        <div className="alert alert-primary" role="alert">
                          <TPLanguage
                            resourceId={"ComponentsColorsDescription"}
                            resourceSet={componentName}
                          />
                        </div>
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"link.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"legend.sectionBorder.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"legend.sectionBorder.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"legend.sectionBorder.color"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.primary.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.primary.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.primary.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.secondary.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.secondary.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.outlinePrimary.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.outlinePrimary.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.outlinePrimary.hover.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "button.outlinePrimary.hover.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.link.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"button.empty.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"fixedHeadTable.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"fixedHeadTable.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "fixedHeadTable.noTransparent.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "fixedHeadTable.thead.th.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "fixedHeadTable.showTotalRows.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "fixedHeadTable.highlightOnHover.tbody.tr.hover.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "fixedHeadTable.noHighlightOnHover.tbody.tr.hover.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"table.tableContainer.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"table.tableContainer.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"table.dataTableContainer.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "table.dataTableContainer.dropdown.menu.item.fontcolor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "table.dataTableContainer.dropdown.menu.item.hover.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"table.icon.isActive.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "table.condensed.tbody.tr.selected.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"table.condensed.td.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.active.visible.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.active.invisible.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.active.invisible.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.inactive.visible.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.inactive.invisible.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.inactive.invisible.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.selected.active.invisible.borderColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.selected.active.invisible.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.selected.inactive.visible.borderColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.selected.inactive.visible.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.selected.inactive.visible.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.selected.inactive.invisible.borderColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "tree.branch.conventions.selected.inactive.invisible.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "loading.loadingOverlay.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"checkbox.checked.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"checkbox.checked.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"checkbox.focus.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"select.option.hover.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"select.option.hover.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"scrollbar.color"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="pages">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsePages"
                        aria-expanded="false"
                        aria-controls="collapsePages"
                      >
                        <TPLanguage
                          resourceId={"PagesColorsLabel"}
                          resourceSet={componentName}
                        />
                      </button>
                    </h2>
                    <div
                      id="collapsePages"
                      className="accordion-collapse collapse"
                      aria-labelledby="pages"
                      data-bs-parent="#accordionThemeComponents"
                    >
                      <div className="accordion-body">
                        <div className="alert alert-primary" role="alert">
                          <TPLanguage
                            resourceId={"PagesColorsLabel"}
                            resourceSet={componentName}
                          />
                        </div>
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"conditionsTable.container.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "conditionsTable.totalConditions.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"configValues.menu.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "configValues.menu.item.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "configValues.category.itemContainer.hover.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "configValues.category.content.title.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"configValues.submenu.a.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "configValues.submenu.hover.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "configValues.submenu.hover.a.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"configValues.content.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"configValues.footer.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.number.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.number.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.number.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.title.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.active.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.active.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "wizard.step.active.number.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.active.number.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.active.number.borderColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"wizard.step.active.title.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"leftMenu.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"leftMenu.footer.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"leftMenu.logoAndStation.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "leftMenu.logoAndStation.logoCircle.borderColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "leftMenu.logoAndStation.station.label.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "leftMenu.logoAndStation.station.number.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "leftMenu.logoAndStation.station.name.fontColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"notificationCenter.backgroundColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"notificationCenter.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "notificationCenter.item.backgroundColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "notificationCenter.item.success.borderColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "notificationCenter.item.warning.borderColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "notificationCenter.item.error.borderColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />
                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={
                            "notificationCenter.item.info.borderColor"
                          }
                          value={baseTheme}
                          componentName={componentName}
                        />

                        <TPThemeCreatorColorPicker
                          onChange={handleColorChanged}
                          propertyName={"notificationCenter.date.fontColor"}
                          value={baseTheme}
                          componentName={componentName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div
                  style={{
                    border: "1px solid rgb(206, 212, 218)",
                    padding: "20px",
                  }}
                >
                  <TPThemeCreatorPreviewSection
                    componentName={componentName}
                    sectionTitleResourceId="TextBoxesSection"
                  >
                    <div>
                      <TPTextBox
                        id="IdTextBox"
                        onChange={() => {}}
                        value={""}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="TextboxNormal" />
                        }
                      />
                    </div>
                    <div>
                      <TPTextBox
                        id="IdTextBox"
                        onChange={() => {}}
                        value={""}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="TextboxDisabled" />
                        }
                        disabled={true}
                      />
                    </div>
                    <div>
                      <TPTextBox
                        id="IdTextBox"
                        onChange={() => {}}
                        value={""}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="TextboxFocused" />
                        }
                        className={"focus"}
                      />
                    </div>
                  </TPThemeCreatorPreviewSection>
                  <TPThemeCreatorPreviewSection
                    componentName={componentName}
                    sectionTitleResourceId="SelectsSection"
                  >
                    <div>
                      <TPSelect
                        id="IdSelect"
                        dataSource={[
                          { key: "1", value: "1" },
                          { key: "2", value: "2" },
                          { key: "3", value: "3" },
                          { key: "4", value: "4" },
                          { key: "5", value: "5" },
                        ]}
                        onChange={() => {}}
                        value={""}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="SelectNormal" />
                        }
                      />
                    </div>
                    <div>
                      <TPSelect
                        id="IdSelect"
                        dataSource={[
                          { key: "1", value: "1" },
                          { key: "2", value: "2" },
                          { key: "3", value: "3" },
                          { key: "4", value: "4" },
                          { key: "5", value: "5" },
                        ]}
                        onChange={() => {}}
                        value={""}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="SelectDisabled" />
                        }
                        disabled={true}
                      />
                    </div>
                    <div>
                      <TPSelect
                        id="IdSelect"
                        dataSource={[
                          { key: "1", value: "1" },
                          { key: "2", value: "2" },
                          { key: "3", value: "3" },
                          { key: "4", value: "4" },
                          { key: "5", value: "5" },
                        ]}
                        onChange={() => {}}
                        value={""}
                        className={"focus"}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="SelectFocused" />
                        }
                      />
                    </div>
                  </TPThemeCreatorPreviewSection>

                  <TPThemeCreatorPreviewSection
                    componentName={componentName}
                    sectionTitleResourceId="CheckboxesSection"
                  >
                    <div>
                      <TPCheckBox
                        id="IdCheckBox"
                        checked={true}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="ChekboxSelectedNormal" />
                        }
                      />
                    </div>
                    <div>
                      <TPCheckBox
                        id="IdCheckBox"
                        checked={false}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="CheckboxUnselectedNormal" />
                        }
                      />
                    </div>
                    <div>
                      <TPCheckBox
                        id="IdCheckBox"
                        disabled={true}
                        checked={true}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="ChekboxSelectedDisabled" />
                        }
                      />
                    </div>
                    <div>
                      <TPCheckBox
                        id="IdCheckBox"
                        disabled={true}
                        checked={false}
                        labelText={
                          <TPThemeCreatorTPLanguage resourceId="CheckboxUnselectedDisabled" />
                        }
                      />
                    </div>
                  </TPThemeCreatorPreviewSection>

                  <TPThemeCreatorPreviewSection
                    componentName={componentName}
                    sectionTitleResourceId="ButtonsSection"
                  >
                    <TPButton type={TPButtonTypes.primary} onClick={() => {}}>
                      <TPThemeCreatorTPLanguage resourceId="ButtonPrimary" />
                    </TPButton>
                    <TPButton type={TPButtonTypes.secondary} onClick={() => {}}>
                      <TPThemeCreatorTPLanguage resourceId="ButtonSecondary" />
                    </TPButton>
                    <TPButton type={TPButtonTypes.danger} onClick={() => {}}>
                      <TPThemeCreatorTPLanguage resourceId="ButtonDanger" />
                    </TPButton>
                    <TPButton type={TPButtonTypes.link} onClick={() => {}}>
                      <TPThemeCreatorTPLanguage resourceId="ButtonLink" />
                    </TPButton>
                  </TPThemeCreatorPreviewSection>
                  <TPThemeCreatorPreviewSection
                    componentName={componentName}
                    sectionTitleResourceId="AlertsSection"
                  >
                    <div className="alert alert-info">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Laudantium perspiciatis, labore maxime quaerat odit,
                      voluptates minus dicta consectetur autem iste consequuntur
                      amet optio animi incidunt. Sunt neque corrupti suscipit
                      labore!
                    </div>
                    <div className="alert alert-warning">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Laudantium perspiciatis, labore maxime quaerat odit,
                      voluptates minus dicta consectetur autem iste consequuntur
                      amet optio animi incidunt. Sunt neque corrupti suscipit
                      labore!
                    </div>
                    <div className="alert alert-primary">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Laudantium perspiciatis, labore maxime quaerat odit,
                      voluptates minus dicta consectetur autem iste consequuntur
                      amet optio animi incidunt. Sunt neque corrupti suscipit
                      labore!
                    </div>
                    <div className="alert alert-success">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Laudantium perspiciatis, labore maxime quaerat odit,
                      voluptates minus dicta consectetur autem iste consequuntur
                      amet optio animi incidunt. Sunt neque corrupti suscipit
                      labore!
                    </div>
                  </TPThemeCreatorPreviewSection>
                  <TPThemeCreatorPreviewSection
                    componentName={componentName}
                    sectionTitleResourceId="WizardSection"
                  >
                    <TPWizard>
                      <TPWizardStep
                        isActive={true}
                        id="tab1"
                        labelText={"Lorem1"}
                        numberText="1"
                        name="TPWizardStep"
                      />
                      <TPWizardStep
                        isActive={false}
                        id="tab2"
                        labelText={"Lorem2"}
                        numberText="2"
                        name="TPWizardStep"
                      />
                      <TPWizardStep
                        isActive={false}
                        id="tab3"
                        labelText={"Lorem3"}
                        numberText="3"
                        name="TPWizardStep"
                      />
                      <TPWizardContent
                        id="tab1"
                        isActive={true}
                        name="TPWizardContent"
                      >
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Animi provident fuga, modi tenetur ad hic. Optio
                        enim doloribus qui cum harum placeat ipsam voluptates,
                        ratione beatae dolorum et saepe. Eveniet!
                      </TPWizardContent>
                    </TPWizard>
                  </TPThemeCreatorPreviewSection>
                  {/* <TPThemeCreatorPreviewSection
                    componentName={componentName}
                    sectionTitleResourceId="Tables"
                  >
                    <TableContainer>
                      <DataTableContainer>
                        <DataTable
                          persistTableHead={true}
                          fixedHeader={true}
                          fixedHeaderScrollHeight={`200px`}
                          responsive={true}
                          striped={true}
                          highlightOnHover={true}
                          pagination
                          paginationPerPage={10}
                          paginationComponentOptions={
                            TPGlobal.paginationComponentOptions
                          }
                          columns={[{}]}
                          data={[]}
                          noDataComponent={""}
                          sortFunction={TPGlobal.datatableCustomSort}
                          customStyles={tableStyles}
                          onChangeRowsPerPage={() => {}}
                        />
                      </DataTableContainer>
                    </TableContainer>
                  </TPThemeCreatorPreviewSection> */}
                </div>
              </div>
            </div>

            <div
              className="row justify-content-md-center"
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
                left: "0",
                height: "120px",
              }}
            >
              <div className="col-sm-2">
                <TPButton
                  type={TPButtonTypes.primary}
                  onClick={() => {
                    themeContextData.setAllThemesFromDb({ ...allThemesFromDb });
                    themeContextData.setCurrentTheme(currentTheme);
                  }}
                >
                  <TPLanguage
                    resourceId={"SaveButtonLabel"}
                    resourceSet={componentName}
                  />
                </TPButton>
              </div>
            </div>
          </div>
        </TPThemeCreatorContainer>
      </ContentVerticalNoTabsStyled>
      <TPModal
        modalState={{
          enableAcceptButton: !!newThemeName,
          acceptLabel: (
            <TPThemeCreatorTPLanguage resourceId="AcceptModalLabel" />
          ),
          cancelLabel: (
            <TPThemeCreatorTPLanguage resourceId="CancelModalLabel" />
          ),
          callBackData: newThemeName,
          callBackAnswer: (response: boolean, data: any) => {
            if (!response) setAddingNewTheme(false);
            else {
              const copyAllThemes = { ...allThemesFromDb };
              copyAllThemes[newThemeName] = {
                ...copyAllThemes["base"],
              };
              setAllThemesFromDb(copyAllThemes);
              setAddingNewTheme(false);
              setNewThemeName("");
            }
          },
          isShown: addingNewTheme,
          titleModal: (
            <TPThemeCreatorTPLanguage resourceId="AddNewThemeModalTitleLabel" />
          ),
        }}
      >
        <TPTextBox
          id="IdTextBox"
          labelText={
            <TPLanguage resourceId="ThemeName" resourceSet={componentName} />
          }
          onChange={(e: any) => {
            setNewThemeName(e.target.value);
          }}
          value={newThemeName}
        />
      </TPModal>
    </TPLoadingOverlay>
  );
};
