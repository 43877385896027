import React, { ReactElement, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import allThemes from "@/assets/styles/theme";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

import AppTransfer from "./AppTransfer";
import Main from "@/layouts/Main/Main";
import TPGeneralContext from "@/contexts/TPGeneralContext";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./helpers/AppInsights";
import HealthProbe from "./pages/HealthProbes/HealthProbe";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import {createTheme} from "@mui/material";

export const ThemePreferenceContext = React.createContext({});

// MSAL configuration
const azureAppId: string = process.env.REACT_APP_ID as string;

const configuration: Configuration = {
  auth: {
    clientId: azureAppId,
    authority:
      "https://login.microsoftonline.com/638fcbaf-ba4c-43e1-adae-5475c970fe10",
  },
};

const pca = new PublicClientApplication(configuration);

const App = (): ReactElement => {
  const [currentTheme, setCurrentTheme] = useState<any>("base");
  const [allThemesFromDb, setAllThemesFromDb] = useState<any>(allThemes);

  const [isLeftMenuCollapsed, setIsLeftMenuCollapsed] = useState<boolean>(true);

  const [globalUserInfo, setGlobalUserInfo] = useState<any>({});
  const [companyLogo, setCompanyLogo] = useState<string>("");

  const theme = createTheme({
    ...allThemesFromDb["base"],
    colors: allThemesFromDb[currentTheme],
    palette: {
      primary: {
        main: allThemesFromDb[currentTheme].primary,
        contrastText: '#ffffff',
      },
    },
  });

  useEffect(() => {}, [allThemesFromDb]);

  return (
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <TPGeneralContext.Provider
            value={{
              isLeftMenuCollapsed,
              setIsLeftMenuCollapsed,
              globalUserInfo,
              setGlobalUserInfo,
              companyLogo,
              setCompanyLogo,
            }}
          >
            {/* <select
              value={currentTheme}
              onChange={(e) => setCurrentTheme(e.target.value)}
              style={{ display: "none", position: "fixed", top: "0", right: "0" }}
            >
              <option value="light">Light</option>
              <option value="dark">Red</option>
              <option value="orange">Orange</option>
              <option value="blue">Blue</option>
              <option value="green">Green</option>
            </select> */}
            <ThemePreferenceContext.Provider
              value={{ currentTheme, setCurrentTheme, setAllThemesFromDb }}
            >
              <ThemeProvider theme={theme}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/">
                      <Route path=":tenantidhex">
                        <Route path=":languageid">
                          <Route
                            path=":previousurlhex"
                            element={<AppTransfer />}
                          ></Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route path="/start" element={<Main />}></Route>
                    <Route path="/healthz" element={<HealthProbe />}></Route>
                    <Route
                      path="*"
                      element={
                        <main style={{ padding: "1rem" }}>
                          <p>There's nothing here!</p>
                        </main>
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </ThemeProvider>
            </ThemePreferenceContext.Provider>
          </TPGeneralContext.Provider>
        </AppInsightsContext.Provider>
      </MsalProvider>
    </Provider>
  );
};

export default App;
