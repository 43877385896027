import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  AdditionalDataRelateViewModel,
  AdditionalDataTaskTypeViewModel,
} from "@/models/AdditionalData/AdditionalDataModels";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { AdditionalDataTaskTypeService } from "@/services/AdditionalDataService";
import { TPI18N } from "@/services/I18nService";
import * as FileSaver from "file-saver";
import { FC, useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import TaskTypeAdditionalDataInsertUpdate from "../TaskType/TaskTypeAdditionalDataInsertUpdate";

export enum AdditionalDataRelatedTypesEnum {
  taskType = 1,
  Brnach = 2,
}

type AdditionalDataRelatedProps = {
  type: AdditionalDataRelatedTypesEnum;
  inputRecord: string;
  callBackCommands: Function;
};

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<AdditionalDataRelateViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_search_pattern" = 3,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const AdditionalDataRelateAdmin: FC<AdditionalDataRelatedProps> = ({
  type,
  inputRecord, //Parent Id
  callBackCommands,
}) => {
  const componentFileName: string = "TaskTypeAdmin.tsx";
  const resourceSet: string = "AdditionalDataRelateAdmin";
  const resourceSetAdDaTaskTypeInsertUpdate: string =
    "AdditionalDataTaskTypeInsertUpdate";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //Screen resources
  const [titleLabel, setTitleLabel] = useState("");
  const [refreshLabel, setRefreshLabel] = useState("");
  const [newLabel, setNewLabel] = useState("");
  const [exportLabel, setExportLabel] = useState("");
  const [deleteLabel, setDeleteLabel] = useState("");
  const [updateLabel, setUpdateLabel] = useState("");

  const [searchLabel, setSearchLabel] = useState("");
  const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
  //modal resources
  const [deleteQuestion, setDeleteQuestion] = useState("");
  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteOkLabel, setDeleteOkLabel] = useState("");
  const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

  //State modal
  let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
  };
  const [modalQuestionState, setModalQuestionState] = useState(
    modalQuestionInitialState,
  );

  //modal TasktypeInsertUpdate state
  const [showDetail, setShowDetail] = useState(false);
  const [titleDetailInsert, setTitleDetailInsert] = useState("");
  const [titleDetailUpdate, setTitleDetailUpdate] = useState("");
  const [recordId, setRecordId] = useState("");
  const [detailMode, setDetailMode] = useState("Insert");

  //grid columns
  const [idColumnLabel, setIdColumnLabel] = useState("");
  const [
    additionalDataDescriptionColumnLabel,
    setAdditionalDataDescriptionColumnLabel,
  ] = useState("");
  const [orderColumnLabel, setOrderColumnLabel] = useState("");
  const [isMandatoryColumnLabel, setIsMandatoryColumnLabel] = useState("");

  //Load Resources and fill Active Filter
  const loadResourcesAndFilter = async () => {
    //modal
    setDeleteQuestion(await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"));
    setDeleteTitle(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
    );
    setDeleteOkLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
    setDeleteCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    //screen
    setDeleteLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
    );
    setUpdateLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
    );
    setRefreshLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
    );
    setExportLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
    );
    setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"));
    setThereAreNoRecordsToShow(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DataTableNoCurrentData",
      ),
    );

    //grid columns
    setIdColumnLabel(
      await TPI18N.GetText(resourceSet, "additionalDataIdLabel"),
    );
    setAdditionalDataDescriptionColumnLabel(
      await TPI18N.GetText(resourceSet, "LocalizedAddionalDescriptionLabel"),
    );
    setOrderColumnLabel(await TPI18N.GetText(resourceSet, "OrderLabel"));
    setIsMandatoryColumnLabel(
      await TPI18N.GetText(resourceSet, "IsMandatoryLabel"),
    );

    switch (type) {
      case AdditionalDataRelatedTypesEnum.taskType:
        var resourceSetAddaTaskType = "AdditionalDataRelateAdmin";
        setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
        setTitleLabel(
          await TPI18N.GetText(resourceSetAddaTaskType, `TitleLabel`),
        );
        //Modal Additiona data InsertUpdate
        setTitleDetailInsert(
          await TPI18N.GetText(resourceSetAdDaTaskTypeInsertUpdate, "TitleNew"),
        );
        setTitleDetailUpdate(
          await TPI18N.GetText(
            resourceSetAdDaTaskTypeInsertUpdate,
            "TitleEdit",
          ),
        );
        break;

      default:
        break;
    }
  };

  const setupGridColumns = (prevState: AdminStateType) => {
    try {
      let newState: AdminStateType;
      newState = { ...prevState };
      let newColumns: Array<any> = [];

      //delete
      newColumns.push({
        name: "",
        width: "50px",
        center: true,
        cell: (row: { [x: string]: any }) => {
          return (
            <div className="dropdown">
              <TPButton
                id="IdButton"
                dataBsToggle={true}
                type={TPButtonTypes.empty}
                onClick={() => {
                  TPGlobal.foo();
                }}
                className={"menu-button"}
              >
                <TPIcon iconType={TPIconTypes.moreVert} />
              </TPButton>
              <ul className="dropdown-menu">
                <li>
                  <a
                    onClick={(id: any) =>
                      handleDeleteClick(row["additionalDataId"])
                    }
                    className="dropdown-item"
                    href="#"
                  >
                    {deleteLabel}
                  </a>
                </li>
                <li>
                  <a
                    onClick={(id: any) =>
                      handleUpdateClick(row["additionalDataId"])
                    }
                    className="dropdown-item"
                    href="#"
                  >
                    {updateLabel}
                  </a>
                </li>
              </ul>
            </div>
          );
        },
      });
      //update
      newColumns.push({
        width: "50px",
        style: { padding: 0 },
        cell: (row: { [x: string]: any }) => {
          return (
            <TPButton
              id="IdButton"
              type={TPButtonTypes.primary}
              onClick={(id: string) =>
                handleUpdateClick(row["additionalDataId"])
              }
              className="update-button"
            >
              <TPIcon iconType={TPIconTypes.chevronRight} />
            </TPButton>
          );
        },
        selector: (row: { [x: string]: any }) => row["additionalDataId"],
        sortable: true,
      });
      //id
      newColumns.push({
        name: idColumnLabel,
        cell: (row: { [x: string]: any }) => {
          return row["additionalDataId"];
        },
        selector: (row: { [x: string]: any }) => row["additionalDataId"],
        sortable: true,
      });
      //Localized Additional Data Description
      let languageColumnLabel: string;
      languageColumnLabel = additionalDataDescriptionColumnLabel;
      languageColumnLabel = (languageColumnLabel +
        " (" +
        TPGlobal.TPClientAvailableLanguages[0].name) as string;
      languageColumnLabel = languageColumnLabel + ")";
      newColumns.push({
        name: languageColumnLabel,
        selector: (row: { [x: string]: any }) =>
          row["localizedAdditionalDataDescription"],
        sortable: true,
      });

      //Order
      newColumns.push({
        name: orderColumnLabel,
        selector: (row: { [x: string]: any }) => row["order"],
        sortable: true,
      });
      //IsMandatoryLabel
      newColumns.push({
        name: isMandatoryColumnLabel,
        selector: (row: { [x: string]: any }) => row["isMandatory"],
        width: "150px",
        center: true,
        cell: (row: { [x: string]: any }) => {
          let currentValue: boolean = true;
          currentValue = row["isMandatory"];
          return (
            <IsActiveIcon
              className={currentValue ? "active" : "inactive"}
              iconType={TPIconTypes.activeInactive}
            />
          );
        },
      });

      newState.gridColumns = [...newColumns];
      return newState;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} setupGridColumns ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} setupGridColumns ex`);
      return prevState;
    }
  };

  //Modal Question to delete
  const handleDeleteClick = (id: string) => {
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = true;
    newModalQuestionState.callBackData = { recordId: id };
    setModalQuestionState(newModalQuestionState);
  };

  //Delete function after question confirmation
  const handleCallBackModal = async (
    confirmDelete: boolean,
    callBackData: any,
  ) => {
    switch (type) {
      case AdditionalDataRelatedTypesEnum.taskType:
        deleteTaskTypeRecord(confirmDelete, callBackData);
        break;
      default:
        break;
    }
  };

  const deleteTaskTypeRecord = async (
    confirmDelete: boolean,
    callBackData: any,
  ) => {
    let expectedCodes: Array<number> = [200];
    let serviceClient = new AdditionalDataTaskTypeService();
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = false;
    newModalQuestionState.callBackData = {};
    setModalQuestionState(newModalQuestionState);
    if (confirmDelete) {
      try {
        setIsLoadingScreen(true);
        let responseRequest =
          await serviceClient.deleteAdditionalDataTaskTypeById(
            inputRecord,
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
        setIsLoadingScreen(false);
        reloadGridCommand();
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleCallBackModal ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} handleCallBackModal ex`);
        setIsLoadingScreen(false);
      }
    }
  };

  //Get task type by Filter
  const reloadDataGrid = async () => {
    switch (type) {
      case AdditionalDataRelatedTypesEnum.taskType:
        return getByTaskTypeId();
      default:
        break;
    }
  };

  const getByTaskTypeId = async () => {
    let serviceClient = new AdditionalDataTaskTypeService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getByTaskTypeId(
        inputRecord,
        false,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      return [...responseRequest];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getByTaskTypeId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getByTaskTypeId ex`);
      setIsLoadingScreen(false);
    }
  };

  const reloadGridCommand = () => {
    reloadDataGrid()
      .then(function (result) {
        let command1: commandType = {
          type: commandsEnum.reload_grid,
          payload: result,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadGridCommand ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadGridCommand ex`);
      });
  };

  //Update
  const handleUpdateClick = (id: string) => {
    // let command: any = { command: "update", recordId: id };
    // callBackCommands(command);
    setRecordId(id);
    setDetailMode("Update");
    setShowDetail(true);
  };

  //New
  const handleNewClick = () => {
    // let command: any = { command: "new" };
    // callBackCommands(command);
    setDetailMode("Insert");
    setShowDetail(true);
  };
  //Refresh
  const handleRefreshClick = () => {
    reloadDataGrid();
  };

  //Handler to filter data inside data grid
  const handleSearchPatternChange = (newValue: string) => {
    let command1: commandType = {
      type: commandsEnum.change_search_pattern,
      payload: newValue,
    };
    dispatchCommand(command1);
  };

  //Filtered data based on selected pattern on search box
  const filteredData = () => {
    let searcheableColumns: Array<string> = [
      "id",
      "localizedAdditionalDataDescription",
      "localizedAdditionalDataTypeDescription",
    ];
    let i: number;
    let search: string;
    search = adminState.searchPattern.trim();
    return adminState.gridData.filter(function (item, index) {
      if (search == "" || search.length <= 2) {
        return item;
      }
      for (i = 0; i <= searcheableColumns.length - 1; i++) {
        let itemany: any;
        itemany = item;
        if (
          itemany[searcheableColumns[i]] &&
          itemany[searcheableColumns[i]]
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return item;
        }
      }
    });
  };

  const exportToCSV = (
    apiData = filteredData(),
    fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  ) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    /* custom headers */
    XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const handleCallbackDetailResult = (modalResult: any) => {
    switch (modalResult.result) {
      case "CANCEL":
        setShowDetail(false);
        break;
      case "OK":
        setShowDetail(false);
        reloadGridCommand();
        break;
      default:
        break;
    }
  };

  //State grid and current filter
  const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
    columnsAreLoaded: false,
  };
  //reducer definition
  const [adminState, dispatchCommand] = useReducer(doCommand, initialStateBLL);
  function doCommand(prevState: AdminStateType, command: commandType) {
    switch (command.type) {
      case commandsEnum.set_filterIsLoaded:
        let newStateFilter: AdminStateType;
        newStateFilter = { ...prevState };
        newStateFilter.filterIsLoaded = true;
        return newStateFilter;
      case commandsEnum.setup_grid_columns:
        let newStateColumns: AdminStateType = setupGridColumns(prevState);
        newStateColumns.columnsAreLoaded = true;
        return newStateColumns;
      case commandsEnum.reload_grid:
        let newStateGrid: AdminStateType;
        newStateGrid = { ...prevState, gridData: [] };
        const tmpAdditionaldata = [...command.payload];
        for (let i = 0; i < tmpAdditionaldata.length; i++) {
          const element: AdditionalDataTaskTypeViewModel = tmpAdditionaldata[i];
          const additionalDataElement: AdditionalDataRelateViewModel = {
            ...element,
            recordId: element.taskTypeId,
          };
          newStateGrid.gridData.push(additionalDataElement);
        }
        //newStateGrid.gridData = command.payload;
        return newStateGrid;
      case commandsEnum.change_search_pattern:
        let newStatePattern: AdminStateType;
        newStatePattern = { ...prevState };
        newStatePattern.searchPattern = command.payload;
        return newStatePattern;
      default:
        return prevState;
    }
  }

  //Run only once to load resources and active filters
  useEffect(() => {
    loadResourcesAndFilter()
      .then(function () {
        //set filter is loaded
        let command1: commandType = {
          type: commandsEnum.set_filterIsLoaded,
          payload: null,
        };
        dispatchCommand(command1);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResourcesAndFilter ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} loadResourcesAndFilter ex`);
      });
  }, []);

  //Run when filter is loaded to get columns
  useEffect(() => {
    if (adminState.filterIsLoaded) {
      let command1: commandType = {
        type: commandsEnum.setup_grid_columns,
        payload: null,
      };
      dispatchCommand(command1);
    }
  }, [adminState.filterIsLoaded]);

  //Run to populate grid columns when columns are loaded or
  //user change filter
  useEffect(() => {
    if (adminState.columnsAreLoaded) {
      reloadGridCommand();
    }
  }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

  const handleRowsPerPageChanged = (e: any) => {
    const recordSize = fixedHeaderScrollHeight / 10;
    const newRecordSize = recordSize * e;
    setFixedHeaderScrollHeight(newRecordSize);
  };

  return (
    <>
      {showDetail && (
        <div
          className={showDetail ? "modal show" : "modal"}
          tabIndex={-1}
          data-bs-backdrop="static"
          style={showDetail ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header justify-content-between">
                <h5 className="modal-title">
                  {detailMode == "Insert"
                    ? titleDetailInsert
                    : titleDetailUpdate}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => setShowDetail(false)}
                ></button>
              </div>
              <div className="modal-body" style={{ height: "300px" }}>
                {type == AdditionalDataRelatedTypesEnum.taskType && (
                  <TaskTypeAdditionalDataInsertUpdate
                    topic={type}
                    parentRecordId={inputRecord}
                    recordId={recordId}
                    mode={detailMode}
                    callBackResult={handleCallbackDetailResult}
                  ></TaskTypeAdditionalDataInsertUpdate>
                )}
                {/* <WorkflowDetailInsertUpdate
                    onCancelClick={() => {
                      setShowDetail(false);
                    }}
                    onSaveClick={() => setShowDetail(false)}
                    mode={detailMode}
                    idWorkflowType={workflowTypeId}
                    idWorkflowDetail={idWorkflowDetail}
                    //callBackResult={undefined}
                  ></WorkflowDetailInsertUpdate> */}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={showDetail ? "modal-backdrop show" : ""}></div>
      <TPModalQuestion
        title={deleteTitle}
        yesLabel={deleteOkLabel}
        noLabel={deleteCanceLabel}
        question={deleteQuestion.replace(
          "{recordId}",
          modalQuestionState.callBackData.recordId,
        )}
        callBackData={modalQuestionState.callBackData}
        isShown={modalQuestionState.isShown}
        callBackAnswer={handleCallBackModal}
      ></TPModalQuestion>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            <hr />
            <TPPageFirstRow>
              <TPPageActions>
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.icon}
                  onClick={() => handleNewClick()}
                  text={newLabel}
                  icon={TPIconTypes.newEntity}
                />
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.icon}
                  onClick={() => handleRefreshClick()}
                  text={refreshLabel}
                  icon={TPIconTypes.refresh}
                />
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.icon}
                  onClick={() =>
                    exportToCSV(
                      filteredData(),
                      "additional-data-relate-admin-data",
                    )
                  }
                  text={exportLabel}
                  icon={TPIconTypes.fileDownload}
                />
              </TPPageActions>
              <TPFilterAndSearch>
                <TPPageSearchContainer>
                  <TPTextBox
                    id="IdTextBox"
                    icon={TPIconTypes.search}
                    withIcon={true}
                    value={adminState.searchPattern}
                    placeholder={searchLabel}
                    onChange={(e: any) =>
                      handleSearchPatternChange(e.target.value)
                    }
                    isHorizontal={true}
                  />
                </TPPageSearchContainer>
              </TPFilterAndSearch>
            </TPPageFirstRow>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {/* TableContainer,DataTableContainer,TableHeaderContainer,TableSearchContainer,TableToolbar */}

            <TableContainer>
              <DataTableContainer>
                <DataTable
                  persistTableHead={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                  responsive={true}
                  striped={true}
                  highlightOnHover={true}
                  pagination
                  paginationPerPage={10}
                  paginationComponentOptions={
                    TPGlobal.paginationComponentOptions
                  }
                  columns={adminState.gridColumns}
                  data={filteredData()}
                  noDataComponent={thereAreNoRecordsToShow}
                  sortFunction={TPGlobal.datatableCustomSort}
                  customStyles={tableStyles}
                  onChangeRowsPerPage={handleRowsPerPageChanged}
                />
              </DataTableContainer>
            </TableContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  );
};

export default AdditionalDataRelateAdmin;
