import TPBranchSelection2, {
  TPBranchSelectionValue,
} from "@/components/TPBranchSelection/TPBranchSelection2";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPLanguageFilterEnum,
} from "@/models/Global/TPGlobalEnums";
import {
  ValidationUserGroupModeEnum,
} from "@/models/Users/UserInputDTO";
import { UserViewModel } from "@/models/Users/UserModels";
import {
  UsersBulkLoadInputDTO,
  UsersBulkLoadInputDTOValidator,
} from "@/models/Users/UsersBulkLoadInputDTO";
import { BranchService } from "@/services/BranchService";
import { CalendarsService } from "@/services/CalendarsService";
import { TPI18N } from "@/services/I18nService";
import LanguageService from "@/services/LanguageService";
import { ProfileService } from "@/services/ProfileService";
import { SchedulesService } from "@/services/SchedulesService";
import { TimeZonesService } from "@/services/TimeZonesService";
import { UserService } from "@/services/UserService";
import { HotTable } from "@handsontable/react";
import { ValidationErrors } from "fluentvalidation-ts/dist/ValidationErrors";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import "./style.css";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

type InsertUpdateStateType = {
  login: string;
  firstName: string;
  lastName: string;
  initials: string;
  profileId: string;
  superiorObj: Array<TPKeyValue>;
  email: string;
  currentDescriptionTeamId: string;
  currentHierarchyDescriptionTeamId: string;
  functionId: string;
  phone: string;
  mobile: string;
  typistIdGuid: string;
  calendarId: string;
  timeZoneId: string;
  scheduleId: string;
  authenticationMethodId: string;
  microsoftIdentity: string;
  defaultLanguage: string;
  maxSimultaneousChat: number | null;
  isActive: boolean;
  isSystem: boolean;
  //validator
  idErrorMessage: string;
  areaIdErrorMessage: string;
  authenticationMethodIdErrorMessage: string;
  calendarIdErrorMessage: string;
  defaultLanguageErrorMessage: string;
  emailErrorMessage: string;
  firstNameErrorMessage: string;
  functionIdErrorMessage: string;
  initialsErrorMessage: string;
  lastNameErrorMessage: string;
  loginErrorMessage: string;
  maxSimultaneousChatErrorMessage: string;
  microsoftIdentityErrorMessage: string;
  mobileErrorMessage: string;
  phoneErrorMessage: string;
  profileIdErrorMessage: string;
  scheduleIdErrorMessage: string;
  superiorObjErrorMessage: string;
  teamIdErrorMessage: string;
  timeZoneIdErrorMessage: string;
  typistIdGuidErrorMessage: string;
  isActiveErrorMessage: string;
  [key: string]: any;
};

const UserBulkLoad: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  const resourceSet: string = "UserBulkComponent";
  const componentFileName: string = "UserBulkLoad.tsx";
  const areaTreeJson: any = JSON.parse(TPGlobal.userTreeBranchAreaMapping);
  const teamTreeJson: any = JSON.parse(TPGlobal.userTreeBranchTeamMapping);
  //Screen state
  let insertUpdateInitialState: InsertUpdateStateType = {
    //areaId: "",
    authenticationMethodId: "",
    calendarId: "",
    defaultLanguage: "",
    email: "",
    firstName: "",
    functionId: "",
    initials: "",
    lastName: "",
    login: "",
    maxSimultaneousChat: 0,
    microsoftIdentity: "",
    mobile: "",
    phone: "",
    profileId: "",
    scheduleId: "",
    superiorObj: [],
    //teamId: "",
    timeZoneId: "",
    typistIdGuid: TPGlobal.currentUserGuid,
    isActive: true,
    idErrorMessage: "",
    areaIdErrorMessage: "",
    authenticationMethodIdErrorMessage: "",
    calendarIdErrorMessage: "",
    defaultLanguageErrorMessage: "",
    emailErrorMessage: "",
    firstNameErrorMessage: "",
    functionIdErrorMessage: "",
    initialsErrorMessage: "",
    lastNameErrorMessage: "",
    loginErrorMessage: "",
    maxSimultaneousChatErrorMessage: "",
    microsoftIdentityErrorMessage: "",
    mobileErrorMessage: "",
    phoneErrorMessage: "",
    profileIdErrorMessage: "",
    scheduleIdErrorMessage: "",
    superiorObjErrorMessage: "",
    teamIdErrorMessage: "",
    timeZoneIdErrorMessage: "",
    isSystem: false,
    currentDescriptionTeamId: "",
    currentHierarchyDescriptionTeamId: "",
    isActiveErrorMessage: "",
    typistIdGuidErrorMessage: "",
  };

  //Areas State
  const initialSelectedAreaBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };

  //Teams state
  const initialSelectedTeamBranch: TPBranchSelectionValue = {
    branchId: "",
    branchDescription: "",
    branchHierarchyDescription: "",
  };

  const [insertUpdateState, setInsertUpdateState] =
    useState<InsertUpdateStateType>(insertUpdateInitialState);
  const [profilesToSelect, setProfilesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [calendarsToSelect, setCalendarsToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [languagesToSelect, setLanguagesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [schedulesToSelect, setSchedulesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [timezonesToSelect, setSTimeZonesToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [superiorsToSelect, setSuperiorsToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [functionsToSelect, setFunctionsToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [autocompleteSuperiorOptions, setAutocompleteSuperiorOptions] =
    useState<Array<TPKeyValue>>([]);
  //top n Superior options for arrow icon
  const [autocompleteSuperiorTopNOptions, setAutocompleteSuperiorTopNOptions] =
    useState<Array<TPKeyValue>>([]);
  const [isActiveLabel, setIsActiveLabel] = useState("");

  // const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  // const [modalCancelLabel, setModalCancelLabel] = useState("");
  // const [modalSelectedBranchLabel, setModalSelectedBranchLabel] = useState("");
  // const [modalTitleLabel, setmodalTitleLabel] = useState("");
  const [selectedAreaBranchListValue, setSelectedAreaBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedAreaBranch);
  const [selectedTeamBranchListValue, setSelectedTeamBranchListValue] =
    useState<TPBranchSelectionValue>(initialSelectedTeamBranch);

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Handsontable states
  const hotTableRef = useRef<any>(null);
  const [data, setData] = useState([
    {
      login: "",
      firstName: "",
      lastName: "",
      initials: "",
      email: "",
      phone: "",
      mobile: "",
      result: "",
    },
  ]);

  //Labels
  const [titleLabel, setTitleLabel] = useState("");
  const [parameter, setParameterLabel] = useState("");
  const [userLabel, setUserLabel] = useState("");
  const [superiorLabel, setSupervisorLabel] = useState("");
  const [functionLabel, setFunctionLabel] = useState("");
  const [teamsLabel, setTeamsLabel] = useState("");
  const [calendarLabel, setCalendarLabel] = useState("");
  const [scheduleLabel, setScheduleLabel] = useState("");
  const [timeLabel, setTimeLabel] = useState("");
  const [languageLabel, setLanguageLabel] = useState("");
  const [areaLabel, setAreaLabel] = useState("");
  const [activeLabel, setActiveLabel] = useState("");
  const [userInfoLabel, setUserInfoLabel] = useState("");

  const [loginLabel, setLoginLabel] = useState("");
  const [firstNameLabel, setFirstName] = useState("");
  const [lastNameLabel, setLastNameLabel] = useState("");
  const [initialsLabel, setInitialsLabel] = useState("");
  const [emailLabel, setEmailLabel] = useState("");
  const [phoneLabel, setPhoneLabel] = useState("");
  const [mobileLabel, setMobileLabel] = useState("");
  const [resultLabel, setResultLabel] = useState("");

  const [mandatoryFields, setMandatoryFields] = useState("");
  const [mandatoryLoginAndFirstName, setMandatoryLoginAndFirstName] =
    useState("");
  const [mandatoryLoginAndInitials, setMandatoryLoginAndInitials] =
    useState("");
  const [mandatoryFirstNameAndInitials, setMandatoryFirstNameAndInitials] =
    useState("");
  const [mandatoryLogin, setMandatoryLogin] = useState("");
  const [mandatoryFirstName, setMandatoryFirstName] = useState("");
  const [mandatoryInitial, setMandatoryInitial] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [selectBranch, setSelectBranchLabel] = useState("");
  const [selectedBranch, setSelectedBranchLabel] = useState("");

  const [clearButtonLabel, setClearButtonLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [OkButtonLabel, setOkButtonLabel] = useState("");

  const getResourceNames = async () => {
    setTitleLabel(await TPI18N.GetText(resourceSet, "UserBulkTitle"));
    setParameterLabel(await TPI18N.GetText(resourceSet, "ParameterLabel"));
    setUserLabel(await TPI18N.GetText(resourceSet, "UserLabel"));
    setSupervisorLabel(await TPI18N.GetText(resourceSet, "SupervisorLabel"));
    setFunctionLabel(await TPI18N.GetText(resourceSet, "FunctionLabel"));
    setTeamsLabel(await TPI18N.GetText(resourceSet, "TeamsLabel"));
    setCalendarLabel(await TPI18N.GetText(resourceSet, "CalendarLabel"));
    setScheduleLabel(await TPI18N.GetText(resourceSet, "ScheduleLabel"));
    setTimeLabel(await TPI18N.GetText(resourceSet, "TimeLabel"));
    setLanguageLabel(await TPI18N.GetText(resourceSet, "LanguageLabel"));
    setAreaLabel(await TPI18N.GetText(resourceSet, "AreaLabel"));
    setActiveLabel(await TPI18N.GetText(resourceSet, "ActiveLabel"));
    setUserInfoLabel(await TPI18N.GetText(resourceSet, "UserInfoLabel"));

    setLoginLabel(await TPI18N.GetText(resourceSet, "LoginLabel"));
    setFirstName(await TPI18N.GetText(resourceSet, "FirstNameLabel"));
    setLastNameLabel(await TPI18N.GetText(resourceSet, "LastNameLabel"));
    setInitialsLabel(await TPI18N.GetText(resourceSet, "InitialsLabel"));
    setEmailLabel(await TPI18N.GetText(resourceSet, "EmailLabel"));
    setPhoneLabel(await TPI18N.GetText(resourceSet, "PhoneLabel"));
    setMobileLabel(await TPI18N.GetText(resourceSet, "MobileLabel"));
    setResultLabel(await TPI18N.GetText(resourceSet, "ResultLabel"));

    setMandatoryFields(
      await TPI18N.GetText(resourceSet, "MandatoryFieldsLabel"),
    );
    setMandatoryLoginAndFirstName(
      await TPI18N.GetText(resourceSet, "MandatoryLoginAndFirstNameLabel"),
    );
    setMandatoryLoginAndInitials(
      await TPI18N.GetText(resourceSet, "MandatoryLoginAndInitials"),
    );
    setMandatoryFirstNameAndInitials(
      await TPI18N.GetText(resourceSet, "MandatoryFirstNameAndInitials"),
    );
    setMandatoryLogin(await TPI18N.GetText(resourceSet, "MandatoryLogin"));
    setMandatoryFirstName(
      await TPI18N.GetText(resourceSet, "MandatoryFirstName"),
    );
    setMandatoryInitial(await TPI18N.GetText(resourceSet, "MandatoryInitial"));
    setValidEmail(await TPI18N.GetText(resourceSet, "ValidEmail"));
    setSelectBranchLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectBranchLabel"),
    );
    setSelectedBranchLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SelectedBranchLabel"),
    );

    setClearButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ClearButton"),
    );
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setOkButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
  };

  const getProfiles = async () => {
    const profileClient = new ProfileService();
    const expectedCodes: Array<number> = [200, 404];
    const profiles = await profileClient.getProfilesByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const profilesToSelectConverted: Array<TPKeyValue> = profiles.map((k) => {
      return {
        key: k.id,
        value: k.description,
      };
    });
    profilesToSelectConverted.unshift({ key: "", value: "--" });
    setProfilesToSelect(profilesToSelectConverted);
  };

  const getCalendars = async () => {
    const calendarClient = new CalendarsService();
    const expectedCodes: Array<number> = [200, 404];
    const schedules = await calendarClient.getCalendarsByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const calendarsToSelectConverted: Array<TPKeyValue> = schedules
      .filter((k) => k.isActive)
      .map((k) => {
        return {
          key: k.id,
          value: k.description,
        };
      });
    calendarsToSelectConverted.unshift({ key: "", value: "--" });
    setCalendarsToSelect(calendarsToSelectConverted);
  };

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false,
      true,
      expectedCodes,
    );

    const languages = response.responseData.data;

    const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
      (k: any) => {
        return {
          key: k.id,
          value: k.name,
        };
      },
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguagesToSelect(languagesToSelectConverted);
  };

  const getSchedules = async () => {
    const scheduleClient = new SchedulesService();
    const expectedCodes: Array<number> = [200, 404];
    const schedules = await scheduleClient.getSchedulesByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const schedulesToSelectConverted: Array<TPKeyValue> = schedules
      .filter((k) => k.isActive)
      .map((k) => {
        return {
          key: k.id,
          value: k.description,
        };
      });
    schedulesToSelectConverted.unshift({ key: "", value: "--" });
    setSchedulesToSelect(schedulesToSelectConverted);
  };

  const getTimezones = async () => {
    const TimeZoneClient = new TimeZonesService();
    const expectedCodes: Array<number> = [200, 404];
    const timezones = await TimeZoneClient.getTimeZonesCollection(
      false,
      true,
      expectedCodes,
    );
    const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
      return {
        key: k.id,
        value: k.displayName,
      };
    });
    timezonesToSelectConverted.unshift({ key: "", value: "--" });
    setSTimeZonesToSelect(timezonesToSelectConverted);
  };

  const getSuperiors = async () => {
    const userClient = new UserService();
    const expectedCodes: Array<number> = [200, 404];
    const superiors: Array<UserViewModel> = await userClient.getUsersByFilter(
      TPActiveOptions.ALL.toString(),
      false,
      true,
      expectedCodes,
    );
    const superiorsToSelectConverted: Array<TPKeyValue> = superiors.map((k) => {
      return {
        key: k.userGuid,
        value: k.name,
      };
    });
    //superiorsToSelectConverted.unshift({ key: "", value: "--" });
    setSuperiorsToSelect(superiorsToSelectConverted);
  };

  const handleSuperiorOnAutocompleteQuery = async (query: string) => {
    let newSuperiorKeyValueList: Array<TPKeyValue>;

    newSuperiorKeyValueList = superiorsToSelect.filter((superior) =>
      superior.value.toLowerCase().includes(query.toLowerCase()),
    );

    setAutocompleteSuperiorOptions(newSuperiorKeyValueList);
    return newSuperiorKeyValueList;
  };

  const handleAutoCompleteTopNSuperiorClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteSuperiorTopNOptions.length === 0) {
      newTopNOptions = await handleSuperiorOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteSuperiorTopNOptions([...newTopNOptions]);
        setAutocompleteSuperiorOptions([...newTopNOptions]);
        handleSuperiorChange([]);
      }
    } else {
      //use cached values;
      setAutocompleteSuperiorOptions([...autocompleteSuperiorTopNOptions]);
      handleSuperiorChange([]);
    }
  };

  const handleSuperiorChange = (newSelectedValue: Array<TPKeyValue>) => {
    handleInputTextChange("superiorObj", newSelectedValue);
  };

  const handleSuperiorOnAutocompleteKeyDown = (event: any) => {
    if (
      event.keyCode != 37 && //left arrow
      event.keyCode != 38 && //down arrow
      event.keyCode != 39 && //right arrow
      event.keyCode != 40 && //up arrow
      event.keyCode != 13 && //enter
      event.keyCode != 35 && //home
      event.keyCode != 36 //end
    ) {
      setAutocompleteSuperiorOptions([]);
      handleSuperiorChange([]);
    }
  };

  const handleInputCheckBoxChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
    setInsertUpdateState(newInsertUpdateState);
  };

  // Handsontable
  const columns = [
    { data: loginLabel, type: "text" },
    { data: firstNameLabel, type: "text" },
    { data: lastNameLabel, type: "text" },
    { data: initialsLabel, type: "text" },
    { data: emailLabel, type: "text" },
    { data: phoneLabel, type: "text" },
    { data: mobileLabel, type: "text" },
    { data: resultLabel, type: "text", readOnly: true },
  ];

  const handleSave = () => {
    let result = "";
    if (!hotTableRef.current) return;

    const hotInstance = hotTableRef.current.hotInstance;
    if (!hotInstance) return;

    const rowData = hotInstance.getData();
    const newErrorCells: any = [];
    hotInstance.batch(() => {
      rowData?.forEach((row: any[], rowIndex: any) => {
        if (rowData.length > 1 && rowIndex === rowData.length - 1) {
          let isRowEmpty = true;
          for (let i=0; i<row.length; i++) {
            if (row[i]) isRowEmpty = false;
          }
          if (isRowEmpty) return;
        };
        let hasError = false;

        // Determine which error message to set based on the missing fields
        switch (true) {
          case !row[0] && !row[1] && !row[3]:
            result = mandatoryFields;
            hasError = true;
            break;
          case !row[0] && !row[1]:
            result = mandatoryLoginAndFirstName;
            hasError = true;
            break;
          case !row[0] && !row[3]:
            result = mandatoryLoginAndInitials;
            hasError = true;
            break;
          case !row[1] && !row[3]:
            result = mandatoryFirstNameAndInitials;
            hasError = true;
            break;
          case !row[0]:
            result = mandatoryLogin;
            hasError = true;
            break;
          case !row[1]:
            result = mandatoryFirstName;
            hasError = true;
            break;
          case !row[3]:
            result = mandatoryInitial;
            hasError = true;
            break;
          case row[4] && !/.+@.+/.test(row[4]):
            result = validEmail;
            hasError = true;
            break;
          default:
            break;
        }

        // Set the error message in the table cell
        hotInstance.setDataAtCell(rowIndex, 7, result);

        // Track rows with errors
        if (hasError) {
          newErrorCells.push(rowIndex);
        }
      });
    });
    return result;
  };

  const afterChange = (changes: any, source: string) => {
    if (source === "loadData") {
      return;
    }

    if (!hotTableRef.current) return;

    const hotInstance = hotTableRef.current.hotInstance;
    if (!hotInstance) return;

    const rowData = hotInstance.getData();

    // Remove the row
    changes?.forEach(([row, prop, oldValue, newValue]: any) => {
      if (prop === loginLabel && newValue === "") {
        if (rowData.length > 1) {
          // Remove the current row
          hotInstance.alter("remove_row", row);
          setData((prevData) => {
            const newData = prevData.filter((_, index) => index !== row);
            return newData.length > 0
              ? newData
              : [
                  {
                    login: "",
                    firstName: "",
                    lastName: "",
                    initials: "",
                    email: "",
                    phone: "",
                    mobile: "",
                    result: "",
                  },
                ];
          });
        }
      }
    });

    // Add a new row if the last row has its login field is filled
    if (
      rowData.length > 0 &&
      rowData[rowData.length - 1][0] &&
      rowData.length === data.length
    ) {
      setData([
        ...data,
        {
          login: "",
          firstName: "",
          lastName: "",
          initials: "",
          email: "",
          phone: "",
          mobile: "",
          result: "",
        },
      ]);
    }
  };

  const afterRender = () => {
    if (!hotTableRef.current) return;

    const hotInstance = hotTableRef.current.hotInstance;
    if (!hotInstance) return;

    // showing the error messages color & text color
    hotInstance?.getData()?.forEach((row: any[], rowIndex: any) => {
      const cell = hotInstance.getCell(rowIndex, 7);
      if (cell) {
        if (row[7]) {
          cell.style.backgroundColor = "#ff0000";
          cell.style.color = "#ffffff";
        } else {
          cell.style.backgroundColor = "";
          cell.style.color = "";
        }
      }
    });
  };

  // const handleClear = () => {
  //   setInsertUpdateState(insertUpdateInitialState);
  //   setSelectedAreaBranchListValue(initialSelectedAreaBranch);
  //   setSelectedTeamBranchListValue(initialSelectedTeamBranch);
  //   setData([{ login: "", firstName: "", lastName: "", initials: "", email: "", phone: "", mobile: "", result: "" },])
  // }

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const handleOnAreaBranchChange = (
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.areaIdErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);

    let newSelectedAreaBranchListValue: TPBranchSelectionValue = {
      ...selectedAreaBranchListValue,
    };
    newSelectedAreaBranchListValue.branchId = newBranchId;
    newSelectedAreaBranchListValue.branchDescription = newBranchDescription;
    newSelectedAreaBranchListValue.branchHierarchyDescription =
      newBranchHierarchyDescription;
    setSelectedAreaBranchListValue(newSelectedAreaBranchListValue);
  };

  const handleOnTeamBranchChange = (
    newBranchId: string,
    newBranchDescription: string,
    newBranchHierarchyDescription: string,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.teamIdErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);

    let newSelectedTeamBranchListValue: TPBranchSelectionValue = {
      ...selectedTeamBranchListValue,
    };
    newSelectedTeamBranchListValue.branchId = newBranchId;
    newSelectedTeamBranchListValue.branchDescription = newBranchDescription;
    newSelectedTeamBranchListValue.branchHierarchyDescription =
      newBranchHierarchyDescription;
    setSelectedTeamBranchListValue(newSelectedTeamBranchListValue);
  };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState[propertyName] = newValue;
    newInsertUpdateState[propertyName + "ErrorMessage"] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async (result: any) => {
    let recordInputDTO: UsersBulkLoadInputDTO = {
      profileId: insertUpdateState.profileId ?? "",
      superiorIdGuid: null,
      teamId: selectedTeamBranchListValue.branchId ?? "",
      functionId: insertUpdateState.functionId ?? "",
      areaId: selectedAreaBranchListValue.branchId ?? "",
      typistIdGuid: TPGlobal.currentUserGuid,
      calendarId: insertUpdateState.calendarId ?? "",
      timeZoneId: insertUpdateState.timeZoneId ?? "",
      scheduleId: insertUpdateState.scheduleId ?? "",
      defaultLanguage: insertUpdateState.defaultLanguage ?? "",
      users: data,
    };

    let inputDTOValidator = new UsersBulkLoadInputDTOValidator(
      ValidationUserGroupModeEnum.user,
    );

    if (recordInputDTO.users.length > 1) {
      recordInputDTO.users.pop();
    }
    // Transforming the users array
    let transformedUsers = recordInputDTO.users.map((user: any) => ({
      login: user[loginLabel] ? user[loginLabel] : "",
      firstName: user[firstNameLabel] ? user[firstNameLabel] : "",
      lastName: user[lastNameLabel] ? user[lastNameLabel] : "",
      initials: user[initialsLabel] ? user[initialsLabel] : "",
      email: user[emailLabel] ? user[emailLabel] : "",
      phone: user[phoneLabel] ? user[phoneLabel] : "",
      mobile: user[mobileLabel] ? user[mobileLabel] : "",
    }));

    recordInputDTO = { ...recordInputDTO, users: transformedUsers };
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (result.trim().length > 0 || !TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      for (let key in resultValidator) {
        if (resultValidator.hasOwnProperty(key)) {
          const name: string = resultValidator[
            key as keyof ValidationErrors<UsersBulkLoadInputDTO>
          ] as string;
          const toUpdate = await TPI18N.GetResource(name);
          newInsertUpdateState[key + "ErrorMessage"] = toUpdate;
        }
      }
      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    await createBulkUser(recordInputDTO);
  };

  const createBulkUser = async (inputDTO: any) => {
    let serviceClient = new UserService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateUsersBulk(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const getFunctions = async () => {
    const branchClient = new BranchService();
    const expectedCodes: Array<number> = [200, 404];
    //TODO: Guardar esta constante en un archivo "TEXTTREE"
    const functions = await branchClient.getFirstLevelBranches(
      "TEXTTREEE",
      TPActiveOptions.ALL,
      1,
      false,
      true,
      expectedCodes,
    );
    const functionsToSelectConverted: Array<TPKeyValue> = functions.map((k) => {
      return {
        key: k.id,
        value: k.description,
      };
    });
    functionsToSelectConverted.unshift({ key: "", value: "--" });
    setFunctionsToSelect(functionsToSelectConverted);
  };

  useEffect(() => {
    getResourceNames();
    getProfiles();
    getCalendars();
    getLanguages();
    getSchedules();
    getTimezones();
    getSuperiors();
    getFunctions();
    // getAuthenticationMethods();
  }, []);

  return (
    <>
      <div className="col-6">
        <TPPageTitle>{titleLabel}</TPPageTitle>
        <hr />
      </div>

      <div className="row">
        <div className="col-6">
          <TPPageSectionTitle>{parameter}</TPPageSectionTitle>
        </div>
      </div>
      <div className="row">
        <div className="col-11">
          <TPPageSection>
            <div className="row">
              <div className="form-group col-sm-6">
                <TPSelect
                  id="IdSelect"
                  isMandatory={true}
                  onChange={(e: any) => {
                    handleInputTextChange("profileId", e.target.value);
                  }}
                  dataSource={profilesToSelect}
                  value={insertUpdateState.profileId}
                  labelText={userLabel}
                  errorMessage={insertUpdateState.profileIdErrorMessage}
                  disabled={insertUpdateState.isSystem}
                />
              </div>

              <div className="form-group col-sm-6">
                <TPAutoComplete
                  isMandatory={false}
                  labelText={superiorLabel}
                  onValueChange={handleSuperiorChange}
                  onSearch={(query: string) => {
                    handleSuperiorOnAutocompleteQuery(query);
                  }}
                  isLoading={false}
                  options={autocompleteSuperiorOptions}
                  withIcon={true}
                  emptyLabel={""}
                  onKeyDown={handleSuperiorOnAutocompleteKeyDown}
                  selected={insertUpdateState.superiorObj}
                  errorMessage={insertUpdateState.superiorObjErrorMessage}
                  downArrowClick={handleAutoCompleteTopNSuperiorClick}
                  disabled={insertUpdateState.isSystem}
                ></TPAutoComplete>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <TPBranchSelection2
                  labelText={teamsLabel}
                  disabledTextBox={insertUpdateState.isSystem}
                  treeId={teamTreeJson.TreeCode}
                  modalAcceptLabel={OkButtonLabel}
                  modalCancelLabel={cancelButtonLabel}
                  modalSelectedBranchLabel={selectedBranch}
                  modalTitle={selectBranch}
                  mustSelectLastLevelBranch={true}
                  value={selectedTeamBranchListValue}
                  onChange={(
                    branchId: string,
                    branchDescription: string,
                    branchHierachyDescription: string,
                  ) => {
                    handleOnTeamBranchChange(
                      branchId,
                      branchDescription,
                      branchHierachyDescription,
                    );
                  }}
                  emptyLabel={""}
                  errorMessage={insertUpdateState.teamIdErrorMessage}
                  onIsLoadingProgress={(value: boolean) => {
                    setIsLoadingScreen(value);
                  }}
                  loadChildsFromThisParent={
                    teamTreeJson.ParentCode.startsWith("[Root:")
                      ? ""
                      : teamTreeJson.ParentCode
                  }
                ></TPBranchSelection2>
              </div>

              <div className="form-group col-sm-6">
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("functionId", e.target.value);
                  }}
                  dataSource={functionsToSelect}
                  value={insertUpdateState.functionId}
                  labelText={functionLabel}
                  errorMessage={insertUpdateState.functionIdErrorMessage}
                  disabled={insertUpdateState.isSystem}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("calendarId", e.target.value);
                  }}
                  dataSource={calendarsToSelect}
                  value={insertUpdateState.calendarId}
                  labelText={calendarLabel}
                  isMandatory={true}
                  errorMessage={insertUpdateState.calendarIdErrorMessage}
                  disabled={insertUpdateState.isSystem}
                />
              </div>

              <div className="form-group col-sm-6">
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("scheduleId", e.target.value);
                  }}
                  dataSource={schedulesToSelect}
                  value={insertUpdateState.scheduleId}
                  labelText={scheduleLabel}
                  isMandatory={true}
                  errorMessage={insertUpdateState.scheduleIdErrorMessage}
                  disabled={insertUpdateState.isSystem}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("timeZoneId", e.target.value);
                  }}
                  dataSource={timezonesToSelect}
                  value={insertUpdateState.timeZoneId}
                  labelText={timeLabel}
                  isMandatory={true}
                  errorMessage={insertUpdateState.timeZoneIdErrorMessage}
                  disabled={insertUpdateState.isSystem}
                />
              </div>
              <div className="form-group col-sm-6">
                <TPSelect
                  id="IdSelect"
                  onChange={(e: any) => {
                    handleInputTextChange("defaultLanguage", e.target.value);
                  }}
                  isMandatory={true}
                  dataSource={languagesToSelect}
                  value={insertUpdateState.defaultLanguage}
                  labelText={languageLabel}
                  errorMessage={insertUpdateState.defaultLanguageErrorMessage}
                  disabled={insertUpdateState.isSystem}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <TPBranchSelection2
                  labelText={areaLabel}
                  disabledTextBox={insertUpdateState.isSystem}
                  treeId={areaTreeJson.TreeCode}
                  modalAcceptLabel={OkButtonLabel}
                  modalCancelLabel={cancelButtonLabel}
                  modalSelectedBranchLabel={selectedBranch}
                  modalTitle={selectBranch}
                  mustSelectLastLevelBranch={true}
                  value={selectedAreaBranchListValue}
                  onChange={(
                    branchId: string,
                    branchDescription: string,
                    branchHierachyDescription: string,
                  ) => {
                    handleOnAreaBranchChange(
                      branchId,
                      branchDescription,
                      branchHierachyDescription,
                    );
                  }}
                  emptyLabel={""}
                  errorMessage={insertUpdateState.teamIdErrorMessage}
                  onIsLoadingProgress={(value: boolean) => {
                    setIsLoadingScreen(value);
                  }}
                  loadChildsFromThisParent={
                    areaTreeJson.ParentCode.startsWith("[Root:")
                      ? ""
                      : areaTreeJson.ParentCode
                  }
                ></TPBranchSelection2>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6 mt-4">
                <TPCheckBox
                  id="IdCheckBox"
                  labelText={activeLabel}
                  checked={insertUpdateState.isActive}
                  onChange={handleInputCheckBoxChange}
                  disabled={insertUpdateState.isSystem}
                ></TPCheckBox>
              </div>
            </div>
          </TPPageSection>
        </div>

        <h4>{userInfoLabel}</h4>
        <div style={{ height: "256px", width: '80%', overflow:'auto' }}>
          <HotTable
            data={data}
            colWidths={100}
            colHeaders={[
              loginLabel,
              firstNameLabel,
              lastNameLabel,
              initialsLabel,
              emailLabel,
              phoneLabel,
              mobileLabel,
              resultLabel,
            ]}
            columns={columns}
            ref={hotTableRef}
            contextMenu={["copy", "cut"]}
            licenseKey="non-commercial-and-evaluation"
            afterChange={afterChange}
            afterRender={afterRender}
            width="100%"
            height="100%"
          />
        </div>

        <div className="row">
          <div className="col-11">
            <TPPageAcceptCancelButtonsContainer>
              {true && (
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={() => {
                    let result = handleSave();
                    handleOkButtonClick(result);
                  }}
                >
                  {saveButtonLabel}
                </TPButton>
              )}
              {/* <TPButton
                id="IdButton"
                type={TPButtonTypes.light}
                onClick={() => { handleClear() }}
                className={"ms-2"}
              >
                {clearButtonLabel}
              </TPButton> */}
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBulkLoad;
