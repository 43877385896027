import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import {
  TPGenAIInsertTrackingModel,
  TPGenAIKeyPointsRequest,
  TPGenAIKeyPointsResponse,
  TPGenAIKeyPointsUpdateModel,
  TPGenAIMail2SummaryRequest,
  TPGenAISummaryResponse,
} from "@/models/TPGenAI/TPGenAIModel";
import { HttpClient } from "@microsoft/signalr";

export class TPGenAIService {
  serviceFileName: string = "TPGenAIService.ts";

  public async processMailToKeyPoints(
    requestBody: TPGenAIKeyPointsRequest,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<TPGenAIKeyPointsResponse> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/tpgenai/mail2keypoints";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return {
        items: response.responseData.data,
      };
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to get TPGenAI response: ${error}`,
      );
    }
  }

  public async updateMailKeypoints(
    requestBody: TPGenAIKeyPointsUpdateModel,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<void> {
    const httpClientInstance = new TPHTTPService();
    const url =
      TPGlobal.baseAPIGateway +
      "api/tenant/" +
      TPGlobal.tenantHex +
      "/inboundmailboxread/tpgenai/keypoints";
    const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    try {
      const response: HTTPResponse = await httpClientInstance.putData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to update the mail's key points generated from TPGenAI: ${error}`,
      );
    }
  }

  public async insertTracking(
    insertData: TPGenAIInsertTrackingModel,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url =
        TPGlobal.baseAPIGateway +
        "api/tenant/" +
        TPGlobal.tenantHex +
        "/tpgenai/tracking";
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        insertData,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
    } catch (error) {
      console.error(error);
    }
  }

  public async keyPointsToMail(
    requestBody: TPGenAIMail2SummaryRequest,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
  ): Promise<TPGenAISummaryResponse> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url =
        TPGlobal.baseAPIGateway +
        "api/tenant/" +
        TPGlobal.tenantHex +
        "/tpgenai/mail2summary";
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

      const response: HTTPResponse = await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );

      return {
        output: response.responseData.data,
      };
    } catch (error) {
      console.error(error);
      throw new Error(
        `Unexpected error when trying to get the mail's summary from generated key points: ${error}`,
      );
    }
  }
}
