import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModal from "@/layouts/TPModal/TPModal";
import TPModalError from "@/layouts/TPModalError/TPModalError";
import TPModalSuccess from "@/layouts/TPModalSuccess/TPModalSuccess";
import {
  AttachmentType,
  InboundMailboxInProcessActionsType,
  InboundMailboxInProcessType,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  WebServiceClassifierType,
} from "@/models/Global/TPGlobalEnums";
import {
  ApplyRules,
  ApplyRulesDTO,
  InboundMailboxInProcessInputDTOValidator,
  isWithFixedClassifier,
} from "@/models/InboundMailboxInProcess/ApplyRulesDTO";
import { InboundMailboxInProcessViewModel } from "@/models/InboundMailboxInProcess/InboundMailboxInProcessViewModel";
import { DownloadFileService } from "@/services/DownloadFileService";
import { TPI18N } from "@/services/I18nService";
import { InboundMailboxesRulesService } from "@/services/InboundMailboxesRulesService";
import { InboundMailboxesService } from "@/services/InboundMailboxesService";
import { InboundMailboxInProcessService } from "@/services/InboundMailboxInProcessService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { StorageService } from "@/services/StorageService";
import * as FileSaver from "file-saver";
import moment from "moment";
import React, {
  forwardRef,
  HTMLProps,
  Ref,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import RuleApplied from "./RuleApplied";

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<InboundMailboxInProcessViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface InboundMailboxInProcessAdminInterface {
  callBackCommands: Function;
}

interface ApplyRulesResponseType {
  key: string;
  value: string;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

interface ISelectedRows {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: InboundMailboxInProcessViewModel[] | never[];
}

type commandType = {
  type: commandsEnum;
  payload: any;
  selectName?: string;
};

interface FormStateType {
  insertionType: string;
  ruleDefinitionId: string;
  action: string | null;
  classifier: string | null;

  actionErrorMessage: string;
  classifierErrorMessage: string;
  inboundMailboxInProcessIdErrorMessage: string;

  [key: string]: any;
}

const InboundMailboxInProcessAdmin = React.forwardRef(
  ({ callBackCommands }: InboundMailboxInProcessAdminInterface, ref) => {
    //Groups called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const componentFileName: string = "InboundMailboxInProcessAdmin.tsx";
    const resourceSet: string = "InboundMailboxInProcessAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [insertTypeLabel, setinsertTypeLabel] = useState("");
    const [inboundMailboxLabel, setInboundMailboxLabel] = useState("");
    const [actionLabel, setActionLabel] = useState("");
    const [classifierLabel, setClassifierLabel] = useState("");

    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    // Modal
    const [okButtonLabel, setOkButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [testInerHtmlComponent, setTestInerHtmlComponent] = useState("");

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [downloadColumnLabel, setDownloadColumnLabel] = useState("");
    const [typeColumnLabel, setTypeColumnLabel] = useState("");
    const [viewMailColumnLabel, setViewMailColumnLabel] = useState("");
    const [fromNameColumnLabel, setFromNameColumnLabel] = useState("");
    const [addressTOColumnLabel, setAddressTOColumnLabel] = useState("");
    const [addressCcColumnLabel, setAddressCcColumnLabel] = useState("");
    const [subjectColumnLabel, setSubjectColumnLabel] = useState("");
    const [htmlBodyColumnLabel, setHtmlBodyColumnLabel] = useState("");
    const [emailGMTDateColumnLabel, setEmailGMTDateColumnLabel] = useState("");
    const [insertDateColumnLabel, setInsertDateColumnLabel] = useState("");
    const [inboundMailboxColumnLabel, setInboundMailboxColumnLabel] =
      useState("");
    const [ruleDefinitionIdColumnLabel, setRuleDefinitionIdColumnLabel] =
      useState("");
    const [modalSuccessTitle, setModalSuccessTitle] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
    const [modalConfirmTitle, setModalConfirmTitle] = useState("");
    const [modalConfirmQuestion, setModalConfirmQuestion] = useState("");

    //Value resources
    const [otherMailboxTypeValue, setOtherMailboxTypeValue] = useState("");
    const [basicMailboxTypeValue, setBasicMailboxTypeValue] = useState("");
    const [exchangeOnlineMailboxTypeValue, setExchangeOnlineMailboxTypeValue] =
      useState("");
    const [pop3ConnectionProtocolValue, setPop3ConnectionProtocolValue] =
      useState("");
    const [imapConnectionProtocolValue, setImapConnectionProtocolValue] =
      useState("");
    const [noneSecurityTypeValue, setNoneSecurityTypeValue] = useState("");
    const [autoSecurityTypeValue, setAutoSecurityTypeValue] = useState("");
    const [sslOnConnectSecurityTypeValue, setSslOnConnectSecurityTypeValue] =
      useState("");
    const [startTlsSecurityTypeValue, setStartTlsSecurityTypeValue] =
      useState("");
    const [
      startTlsWhenAvailableSecurityTypeValue,
      setStartTlsWhenAvailableSecurityTypeValue,
    ] = useState("");
    const [flaggedIMAPFolderValue, setFlaggedIMAPFolderValue] = useState("");
    const [draftsIMAPFolderValue, setDraftsIMAPFolderValue] = useState("");
    const [trashIMAPFolderValue, setTrashIMAPFolderValue] = useState("");
    const [inboxIMAPFolderValue, setInboxIMAPFolderValue] = useState("");
    const [junkIMAPFolderValue, setJunkIMAPFolderValue] = useState("");
    const [sentIMAPFolderValue, setSentIMAPFolderValue] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);
    const [insertTypeKeyValue, setinsertTypeKeyValue] =
      useState(initialStateFilter);
    const [inboundMailboxKeyValue, setinboundMailboxKeyValue] =
      useState(initialStateFilter);
    const [
      filterCopyInboundMailboxKeyValue,
      setFilterCopyInboundMailboxKeyValue,
    ] = useState(initialStateFilter);
    const [actionsKeyValue, setActionsKeyValue] = useState(initialStateFilter);
    const [classifierKeyValue, setClassifierKeyValue] =
      useState(initialStateFilter);

    const [insertTypeValue, setInsertTypeValue] = useState(
      `${InboundMailboxInProcessType.InboundMailboxTypeAll}`,
    );
    const [inboundMailboxValue, setInboundMailboxValue] = useState("");
    const [actionsValue, setActionsValue] = useState("");
    const [classifierValue, setClassifierValue] = useState("");

    const [applyLabel, setApplyLabel] = useState("");

    const [inboundMailboxInProcessList, setInboundMailboxInProcessList] =
      useState<InboundMailboxInProcessViewModel[]>([]);
    const [mailboxInProcessSelected, setMailboxInProcessSelected] = useState<
      number[]
    >([]);
    let formInitialState: FormStateType = {
      insertionType: `${InboundMailboxInProcessType.InboundMailboxTypeAll}`,
      inboundMailbox: "",
      ruleDefinitionId: "",
      action: "",
      classifier: "",

      inboundMailboxErrorMessage: "",
      insertionTypeErrorMessage: "",
      actionErrorMessage: "",
      classifierErrorMessage: "",
      inboundMailboxInProcessIdErrorMessage: "",
    };

    const [formState, setFormState] = useState<FormStateType>(formInitialState);

    const [
      inboundMailboxInProcessSelectedRows,
      setInboundMailboxInProcessSelectedRows,
    ] = useState<ISelectedRows>({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });

    const [isDisableClassifier, setIsDisableClassifier] = useState(true);
    const [isDisableAction, setIsDisableAction] = useState(true);
    const [isValidApplyAction, setIsValidApplyAction] = useState(false);

    // Checkbox
    const [isCheckedAll, setIsCheckedAll] = useState(false);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    //#endregion

    const [isShownModalBodySelect, setIsShownModalBodySelect] = useState(false);

    const [modalQuestionProcessMails, setModalQuestionProcessMails] = useState(
      modalQuestionInitialState,
    );

    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalError, setModalError] = useState(false);

    const [applyRulesResponse, setApplyRulesResponse] = useState<
      ApplyRulesResponseType[]
    >([]);

    const inerHtmlComponent = (inerHtml: string) => {
      setTestInerHtmlComponent(inerHtml);
    };

    const handleCallbackAnser = (status: boolean, callbackData: any) => {
      setIsShownModalBodySelect(false);
    };

    //Load Resources and fill Active Filter
    const loadResourcesAndFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      setModalConfirmTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );

      setModalConfirmQuestion(
        await TPI18N.GetText(resourceSet, "ModalConfirmQuestion"),
      );

      //Value resources
      setOtherMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "OtherMailboxTypeValue"),
      );
      setBasicMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "BasicMailboxTypeValue"),
      );
      setExchangeOnlineMailboxTypeValue(
        await TPI18N.GetText(resourceSet, "ExchangeOnlineMailboxTypeValue"),
      );
      setPop3ConnectionProtocolValue(
        await TPI18N.GetText(resourceSet, "Pop3ConnectionProtocolValue"),
      );
      setImapConnectionProtocolValue(
        await TPI18N.GetText(resourceSet, "ImapConnectionProtocolValue"),
      );
      setNoneSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "NoneSecurityTypeValue"),
      );
      setAutoSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "AutoSecurityTypeValue"),
      );
      setSslOnConnectSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "SslOnConnectSecurityTypeValue"),
      );
      setStartTlsSecurityTypeValue(
        await TPI18N.GetText(resourceSet, "StartTlsSecurityTypeValue"),
      );
      setStartTlsWhenAvailableSecurityTypeValue(
        await TPI18N.GetText(
          resourceSet,
          "StartTlsWhenAvailableSecurityTypeValue",
        ),
      );
      setFlaggedIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "FlaggedIMAPFolderValue"),
      );
      setDraftsIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "DraftsIMAPFolderValue"),
      );
      setTrashIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "TrashIMAPFolderValue"),
      );
      setInboxIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "InboxIMAPFolderValue"),
      );
      setJunkIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "JunkIMAPFolderValue"),
      );
      setSentIMAPFolderValue(
        await TPI18N.GetText(resourceSet, "SentIMAPFolderValue"),
      );

      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));
      setinsertTypeLabel(
        await TPI18N.GetText(resourceSet, "InboundMailboxTypeLabel"),
      );
      setInboundMailboxLabel(
        await TPI18N.GetText(resourceSet, "InboundMailboxLabel"),
      );
      setActionLabel(await TPI18N.GetText(resourceSet, "ActionLabel"));
      setClassifierLabel(await TPI18N.GetText(resourceSet, "ClassifierLabel"));
      setApplyLabel(await TPI18N.GetText(resourceSet, "ApplyLabel"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setDownloadColumnLabel(
        await TPI18N.GetText(resourceSet, "DownloadLabel"),
      );
      setTypeColumnLabel(
        await TPI18N.GetText(resourceSet, "InsertionTypeLabel"),
      );
      setViewMailColumnLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
      setFromNameColumnLabel(
        await TPI18N.GetText(resourceSet, "FromNameLabel"),
      );
      setAddressTOColumnLabel(
        await TPI18N.GetText(resourceSet, "AddressToLabel"),
      );
      setAddressCcColumnLabel(
        await TPI18N.GetText(resourceSet, "AddressCcLabel"),
      );
      setSubjectColumnLabel(await TPI18N.GetText(resourceSet, "SubjectLabel"));
      setHtmlBodyColumnLabel(
        await TPI18N.GetText(resourceSet, "HtmlBodyLabel"),
      );
      setEmailGMTDateColumnLabel(
        await TPI18N.GetText(resourceSet, "EmailGMTDateLabel"),
      );
      setInsertDateColumnLabel(
        await TPI18N.GetText(resourceSet, "InsertDateLabel"),
      );
      setInboundMailboxColumnLabel(
        await TPI18N.GetText(resourceSet, "InboundMailboxLabel"),
      );
      setRuleDefinitionIdColumnLabel(
        await TPI18N.GetText(resourceSet, "RuleDefinitionLabel"),
      );
      setModalSuccessTitle(
        await TPI18N.GetText(resourceSet, "ModalSuccessTitle"),
      );

      //Modal
      setOkButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setCancelButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );

      await getInboundMailbox();
      await getClassifier();
      await getActions();
      await getTypes();
    };

    const handleInputTextChange = (propertyName: string, newValue: any) => {
      setFormState({
        ...formState,
        [propertyName]: newValue,
        [`${propertyName}ErrorMessage`]: "",
      });
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];

        //delete
        // newColumns.push({
        //   name: "",
        //   width: "50px",
        //   style: { padding: 0 },
        //   center: true,
        //   cell: (row: { [x: string]: any }) => {
        //     return (
        //       <div className="dropdown">
        //         <TPButton
        //           dataBsToggle={true}
        //           type={TPButtonTypes.empty}
        //           onClick={() => {
        //             TPGlobal.foo();
        //           }}
        //           className={"menu-button"}
        //         >
        //           <TPIcon iconType={TPIconTypes.moreVert} />
        //         </TPButton>
        //         <ul className="dropdown-menu">
        //           <li>
        //             <a
        //               onClick={(id: any) =>
        //                 handleDeleteClick(row["id"].toString())
        //               }
        //               className="dropdown-item"
        //               href="#"
        //             >
        //               {deleteLabel}
        //             </a>
        //           </li>
        //           {!row["isSystemRecord"] && (
        //             <>
        //               <li>
        //                 <a
        //                   onClick={(id: any) =>
        //                     handleUpdateClick(row["id"].toString())
        //                   }
        //                   className="dropdown-item"
        //                   href="#"
        //                 >
        //                   {updateLabel}
        //                 </a>
        //               </li>
        //             </>
        //           )}
        //         </ul>
        //       </div>
        //     );
        //   },
        // });

        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          name: "",
          cell: (row: { [x: string]: any }) => {
            if (!row["isSystemRecord"]) {
              return (
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={(id: string) => handleUpdateClick(`${row["id"]}`)}
                  className="update-button"
                >
                  <TPIcon iconType={TPIconTypes.chevronRight} />
                </TPButton>
              );
            } else {
              return null;
            }
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });

        //download
        newColumns.push({
          name: downloadColumnLabel,
          cell: (row: { [x: string]: any }) => (
            <TPButton
              id="IdButton"
              type={TPButtonTypes.icon}
              onClick={() => {
                handleDownloadEML(row["id"], row["storageIdEML"]);
              }}
              className=""
              icon={TPIconTypes.fileDownload}
            ></TPButton>
          ),
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });

        //viewMail
        newColumns.push({
          name: viewMailColumnLabel,
          cell: (row: { [x: string]: any }) => row["id"],
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });

        //type
        newColumns.push({
          name: typeColumnLabel,
          cell: (row: { [x: string]: any }) => (
            <TPLanguage
              resourceSet={TPGlobal.globalResourceSet}
              resourceId={`ACTION${row["insertionType"]}`}
            />
          ),
          selector: (row: { [x: string]: any }) => row["insertionType"],
          sortable: true,
        });

        //fromName
        newColumns.push({
          name: fromNameColumnLabel,
          cell: (row: { [x: string]: any }) => row["fromName"],
          selector: (row: { [x: string]: any }) => row["fromName"],
          sortable: true,
        });

        //addressTO
        newColumns.push({
          name: addressTOColumnLabel,
          cell: (row: { [x: string]: any }) => row["addressTO"],
          selector: (row: { [x: string]: any }) => row["addressTO"],
          sortable: true,
        });

        //addressCC
        newColumns.push({
          name: addressCcColumnLabel,
          cell: (row: { [x: string]: any }) => row["addressCC"],
          selector: (row: { [x: string]: any }) => row["addressCC"],
          sortable: true,
        });

        //subject
        newColumns.push({
          name: subjectColumnLabel,
          cell: (row: { [x: string]: any }) => row["subject"],
          selector: (row: { [x: string]: any }) => row["subject"],
          sortable: true,
        });

        //htmlBody
        newColumns.push({
          name: htmlBodyColumnLabel,
          cell: (row: { [x: string]: any }) => (
            <div>
              <span
                className="d-inline-block text-truncate"
                style={{ maxWidth: "100px" }}
              >
                {row["htmlBody"]}
              </span>
              <div className="d-flex justify-content-center align-items-center">
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.icon}
                  onClick={() => {
                    setIsShownModalBodySelect(true);
                    inerHtmlComponent(row["htmlBody"]);
                  }}
                  className="update-button"
                  icon={TPIconTypes.addCircle}
                ></TPButton>
              </div>
            </div>
          ),
          selector: (row: { [x: string]: any }) => row["htmlBody"],
          sortable: true,
        });

        //emailGMTDate
        newColumns.push({
          name: emailGMTDateColumnLabel,
          cell: (row: { [x: string]: any }) =>
            `${moment(row["emailGMTDate"]).format("YYYY/MM/DD HH:mm")}`,
          selector: (row: { [x: string]: any }) => row["emailGMTDate"],
          sortable: true,
        });

        //insertDate
        newColumns.push({
          name: insertDateColumnLabel,
          cell: (row: { [x: string]: any }) =>
            `${moment(row["insertDate"]).format("YYYY/MM/DD HH:mm")}`,
          selector: (row: { [x: string]: any }) => row["insertDate"],
          sortable: true,
        });

        //inboundMailbox
        newColumns.push({
          name: inboundMailboxColumnLabel,
          cell: (row: { [x: string]: any }) =>
            inboundMailboxKeyValue.find((res) => res.key === row["mailboxId"])
              ?.value,
          selector: (row: { [x: string]: any }) => row["mailboxId"],
          sortable: true,
        });

        //ruleDefinitionId
        newColumns.push({
          width: "500px",
          name: ruleDefinitionIdColumnLabel,
          cell: (row: { [x: string]: any }) => (
            <RuleApplied inboundMailRuleJson={JSON.parse(row["jsonRule"])} />
          ),
          selector: (row: { [x: string]: any }) => row["jsonRule"],
          sortable: true,
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    const getFileUriSas = async (storageIdEML: string) => {
      const storageService = new StorageService();
      let expectedCodes: Array<number> = [200];
      try {
        setIsLoadingScreen(true);
        let responseRequest = await storageService.getFileUriSas(
          storageIdEML,
          AttachmentType.MAILREADEREML,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        return responseRequest;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getFileUriSas ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getFileUriSas ex`);
        setIsLoadingScreen(false);
        return null;
      }
    };

    const handleDownloadEML = async (id: string, storageIdEML: string) => {
      const downloadFileService = new DownloadFileService();
      const res = await getFileUriSas(storageIdEML);
      try {
        if (res) {
          setIsLoadingScreen(true);
          const { sasUri, originalFileName, mimeType } = res;
          await downloadFileService.downloadFileUri({
            // isProxy: true,
            sasUri,
            originalFileName,
            mimeType,
          });
          setIsLoadingScreen(false);
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleDownloadEML ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} handleDownloadEML ex`);
        setIsLoadingScreen(false);
      }
    };

    //Get groups by Filter
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new InboundMailboxInProcessService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getInboundMailboxInProcess(
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        const response = await getJsonRule(responseRequest);
        return [...response];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const getInboundMailboxInProcessByFilter = async (
      insertType: string,
      inboundMailboxId: string,
    ) => {
      let serviceClient = new InboundMailboxInProcessService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest =
          await serviceClient.getInboundMailboxInProcessByFilter(
            insertType,
            inboundMailboxId,
            false,
            true,
            expectedCodes,
          );
        setIsLoadingScreen(false);
        setIsLoadingScreen(false);
        const response = await getJsonRule(responseRequest);
        return [...response];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailboxInProcessByFilter ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getInboundMailboxInProcessByFilter ex`,
        );
        setIsLoadingScreen(false);
        return [];
      }
    };

    const applyRulesInboundMailboxInProcess = async (data: ApplyRulesDTO) => {
      let serviceClient = new InboundMailboxInProcessService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);

        let responseRequest =
          await serviceClient.applyRulesInboundMailboxInProcess(
            data,
            false,
            true,
            expectedCodes,
          );
        const res = responseRequest.responseData?.keyList;
        setApplyRulesResponse(res);
        if (res?.length) setModalSuccess(true);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} applyRulesInboundMailboxInProcess ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} applyRulesInboundMailboxInProcess ex`,
        );
        setIsLoadingScreen(false);
        return [];
      }
    };

    const getInboundMailboxs = async () => {
      let serviceClient = new InboundMailboxesService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getInboundMailboxesByFilter(
          adminState.selectedFilter,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        const inboundMailboxesList = responseRequest.map((res) => ({
          key: res.id,
          value: res.description,
        }));
        return inboundMailboxesList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailboxs ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getInboundMailboxs ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const getQuickClassifiersByType = async () => {
      const quickClassifierClient = new QuickClassifierService();
      const expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        const responseRequest =
          await quickClassifierClient.getQuickClassifiersByType(
            WebServiceClassifierType.MailReader,
            TPActiveOptions.ACTIVE,
            false,
            true,
            expectedCodes,
          );
        setIsLoadingScreen(false);
        const quickClassifierList = responseRequest.map((res) => ({
          key: res.id,
          value: res.description,
        }));
        return quickClassifierList;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getQuickClassifiersByType ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getQuickClassifiersByType ex`,
        );
        setIsLoadingScreen(false);
        return [];
      }
    };

    const getInboundMailRuledefinitionById = async (
      ruleDefinitionId: string,
    ) => {
      let serviceClient = new InboundMailboxesRulesService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest =
          await serviceClient.getInboundMailRuleDefinitionById(
            ruleDefinitionId,
            false,
            true,
            expectedCodes,
          );
        setIsLoadingScreen(false);
        return responseRequest;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailRuledefinitionById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getInboundMailRuledefinitionById ex`,
        );
        setIsLoadingScreen(false);
        return null;
      }
    };

    const getJsonRule = async (
      inboundMailboxInProcess: InboundMailboxInProcessViewModel[],
    ) => {
      let response = [];
      for (const item of inboundMailboxInProcess) {
        const inboundMailRuledefinition =
          await getInboundMailRuledefinitionById(item.ruleDefinitionId);
        const jsonRule = inboundMailRuledefinition?.jsonRule || "[]";
        response.push({ ...item, jsonRule: jsonRule });
      }
      setInboundMailboxInProcessList(response);
      return response;
    };

    //Filter Inbound Mailbox
    const getInboundMailbox = async () => {
      let newinboundMailboxKeyValue: TPKeyValue[] = [{ key: "", value: "--" }];
      const inboundMailboxs = await getInboundMailboxs();
      setinboundMailboxKeyValue([
        ...newinboundMailboxKeyValue,
        ...inboundMailboxs,
      ]);
      setFilterCopyInboundMailboxKeyValue([
        ...newinboundMailboxKeyValue,
        {
          key: `${InboundMailboxInProcessType.InboundMailboxTypeAll}`,
          value: await TPI18N.GetText(
            resourceSet,
            "InboundMailboxTypeAllValue",
          ),
        },
        ...inboundMailboxs,
      ]);
    };

    const getClassifier = async () => {
      // Filter Classifier
      let newclassifierKeyValue: TPKeyValue[] = [{ key: "", value: "--" }];
      const quickClassifiers = await getQuickClassifiersByType();
      setClassifierKeyValue([...newclassifierKeyValue, ...quickClassifiers]);
    };

    //Filter Actions
    const getActions = async () => {
      const actionsEntries = Object.entries(InboundMailboxInProcessActionsType);

      let actionsKeyValue: TPKeyValue[] = await Promise.all(
        actionsEntries.map(async (element: any) => {
          return {
            key: element[1],
            value: await TPI18N.GetText(resourceSet, `${element[0]}Value`),
          };
        }),
      );
      actionsKeyValue = [{ key: "", value: "--" }, ...actionsKeyValue];
      setActionsKeyValue(actionsKeyValue);
    };

    // Filter types
    const getTypes = async () => {
      const typesEntries = Object.entries(InboundMailboxInProcessType);
      let typesKeyValue: TPKeyValue[] = await Promise.all(
        typesEntries.map(async (element: any) => {
          return {
            key: element[1],
            value: await TPI18N.GetText(resourceSet, `${element[0]}Value`),
          };
        }),
      );
      setinsertTypeKeyValue(typesKeyValue);
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const handleApplyAction = async () => {
      setIsValidApplyAction(false);
      const data = {
        InsertionType: formState.insertionType,
        InboundMailbox: formState.inboundMailbox,
        Action: Number(formState.action),
        InboundMailboxInProcessId: mailboxInProcessSelected,
        FixedClassifier: formState.classifier || null,
      };
      formValidate(data);
    };

    const formValidate = async (recordInputDTO: ApplyRules) => {
      let newformState = { ...formState };
      let inputDTOGroupValidator =
        new InboundMailboxInProcessInputDTOValidator();
      // let hasError: boolean = false;
      let resultValidator = inputDTOGroupValidator.validate(recordInputDTO);
      if (TPGlobal.TPIsEmpty(resultValidator)) {
        const newModalQuestionProcessMails = {
          ...modalQuestionProcessMails,
          isShown: true,
        };
        setModalQuestionProcessMails(newModalQuestionProcessMails);
      } else {
        // Message Errors
        if (resultValidator?.InboundMailbox) {
          newformState.inboundMailboxErrorMessage = await TPI18N.GetResource(
            resultValidator.InboundMailbox,
          );
        } else {
          newformState.actionErrorMessage = "";
        }
        if (resultValidator?.Action) {
          newformState.actionErrorMessage = await TPI18N.GetResource(
            resultValidator.Action,
          );
        } else {
          newformState.actionErrorMessage = "";
        }

        if (resultValidator?.FixedClassifier) {
          newformState.classifierErrorMessage = await TPI18N.GetResource(
            resultValidator.FixedClassifier,
          );
        } else {
          newformState.classifierErrorMessage = "";
        }

        if (resultValidator?.InboundMailboxInProcessId) {
          newformState.inboundMailboxInProcessIdErrorMessage =
            await TPI18N.GetResource(
              `${resultValidator.InboundMailboxInProcessId}`,
            );
          if (Object.keys(resultValidator).length === 1) setModalError(true);
        } else {
          newformState.inboundMailboxInProcessIdErrorMessage = "";
        }

        setFormState(newformState);
      }
    };

    //Filter Active Change
    const handleFilterChangeX = (e: React.ChangeEvent<HTMLSelectElement>) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Group
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
      setFormState(formInitialState);
      handleClick();
      const filterInboundMailboxKeyValue = [
        ...filterCopyInboundMailboxKeyValue,
      ].filter(
        (res: any) =>
          res.key !== `${InboundMailboxInProcessType.InboundMailboxTypeAll}`,
      );
      setinboundMailboxKeyValue(filterInboundMailboxKeyValue);
    };

    //Update
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Delete Group after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new InboundMailboxesService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteInboundMailboxById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "id",
        "description",
        "insertionType",
        "fromName",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search === "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemValue: any;
          let itemany: any;
          itemany = item;
          if (searcheableColumns[i].split(".").length === 2) {
            let itemSub: any = searcheableColumns[i].split(".");
            itemValue = itemany[itemSub[0]][itemSub[1]];
          } else {
            itemValue = itemany[searcheableColumns[i]];
          }

          if (
            itemValue &&
            itemValue.toString().toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };
    const doCommand = (prevState: AdminStateType, command: commandType) => {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        // case commandsEnum.set_filter_type:
        //   let newStateType: AdminStateType;
        //   newStateType = { ...prevState };
        //   newStateType.searchPattern = command.payload;
        //   return newStateType;
        // case commandsEnum.set_filter_inbound_mailbox:
        //   let newStateInboundMailbox: AdminStateType;
        //   newStateInboundMailbox = { ...prevState };
        //   newStateInboundMailbox.searchPattern = command.payload;
        //   return newStateInboundMailbox;
        // case commandsEnum.set_filter_actions:
        //   let newStateActions: AdminStateType;
        //   newStateActions = { ...prevState };
        //   newStateActions.searchPattern = command.payload;
        //   return newStateActions;
        // case commandsEnum.set_filter_classifier:
        //   let newStateClassifier: AdminStateType;
        //   newStateClassifier = { ...prevState };
        //   newStateClassifier.searchPattern = command.payload;
        //   return newStateClassifier;
        default:
          return prevState;
      }
    };

    const handleCallBackModalConfirm = async (confirm: boolean) => {
      const newModalQuestionProcessMails = {
        ...modalQuestionProcessMails,
        callBackData: {},
        isShown: false,
      };
      if (confirm) {
        applyRulesInboundMailboxInProcess({
          Action: Number(formState.action),
          FixedClassifier: formState.classifier || null,
          InboundMailboxInProcessId: mailboxInProcessSelected,
        });
      }
      setModalQuestionProcessMails(newModalQuestionProcessMails);
    };

    const callBackSaveSuccess = () => {
      setModalSuccess(false);
      handleClick();
      setFormState({ ...formInitialState });
    };

    const callBackSaveError = () => {
      setModalError(false);
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} loadResourcesAndFilter ex`);
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    useEffect(() => {
      const { insertionType, inboundMailbox } = formState;
      (async () => {
        if (insertionType && inboundMailbox) {
          await getInboundMailboxInProcessByFilter(
            insertionType,
            inboundMailbox,
          );
          if (mailboxInProcessSelected.length) handleClick();
        }
      })();
    }, [formState.insertionType, formState.inboundMailbox]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    const Checkbox = forwardRef(
      (props: CheckboxProps, ref: Ref<HTMLInputElement>) => {
        return (
          <>
            <div className="form-check pb-5" style={{ backgroundColor: "" }}>
              <input
                type="checkbox"
                className="form-check-input"
                style={{ height: "20px", width: "20px" }}
                ref={ref}
                {...props}
              />
              <label className="form-check-label" id="booty-check" />
            </div>
          </>
        );
      },
    );

    const handleClick = () => {
      const element = document.getElementsByName("select-all-rows");
      if (!inboundMailboxInProcessSelectedRows.allSelected) element[0]?.click();
      setTimeout(() => element[0]?.click(), 200);
    };

    const handleSelectedRowsChange = (data: ISelectedRows) => {
      // const { allSelected, selectedCount, selectedRows} = data;
      const selectedRowsId = data.selectedRows.map(
        (res: InboundMailboxInProcessViewModel) => res.id,
      );
      setInboundMailboxInProcessSelectedRows(data);
      setMailboxInProcessSelected(selectedRowsId);
    };

    return (
      <>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <TPPageFilterContainer>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        onChange={(e: any) => {
                          handleInputTextChange(
                            "insertionType",
                            e.target.value,
                          );
                        }}
                        dataSource={insertTypeKeyValue}
                        value={formState.insertionType}
                        labelText={insertTypeLabel}
                        isMandatory={true}
                        errorMessage={formState.insertionTypeErrorMessage}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        onChange={(e: any) => {
                          handleInputTextChange(
                            "inboundMailbox",
                            e.target.value,
                          );
                        }}
                        dataSource={inboundMailboxKeyValue}
                        value={formState.inboundMailbox}
                        labelText={inboundMailboxLabel}
                        isMandatory={true}
                        errorMessage={formState.inboundMailboxErrorMessage}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        onChange={(e: any) => {
                          handleInputTextChange("action", e.target.value);
                        }}
                        dataSource={actionsKeyValue}
                        value={formState.action}
                        labelText={actionLabel}
                        isMandatory={true}
                        errorMessage={formState.actionErrorMessage}
                        // disabled={!formState?.inboundMailbox}
                      />
                    </div>
                  </div>
                </div>

                {isWithFixedClassifier(`${formState?.action}`) && (
                  <div
                    className="row"
                    // style={{ maxWidth: "400px" }}
                  >
                    <div className="col">
                      <div className="form-group">
                        <TPSelect
                          id="IdSelect"
                          onChange={(e: any) => {
                            handleInputTextChange("classifier", e.target.value);
                          }}
                          dataSource={classifierKeyValue}
                          value={formState.classifier}
                          labelText={classifierLabel}
                          isMandatory={true}
                          errorMessage={formState.classifierErrorMessage}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mt-1">
                  <div className="col">
                    <TPButton
                      id="IdButton"
                      type={TPButtonTypes.primary}
                      onClick={handleApplyAction}
                      className="ms-2 mt-4"
                    >
                      {applyLabel}
                    </TPButton>
                  </div>
                </div>
              </TPPageFilterContainer>
              <TPPageFirstRow>
                <TPPageActions>
                  {/* <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewClick()}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  /> */}
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() =>
                      exportToCSV(filteredData(), "inbound-mail-box-admin-data")
                    }
                    text={exportLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                </TPPageActions>
                <TPFilterAndSearch>
                  <TPPageFilterContainer>
                    {/* <TPSelect
                      onChange={handleFilterChange}
                      dataSource={filterKeyValue}
                      value={adminState.selectedFilter}
                      labelText={filterIsActiveLabel}
                      isHorizontal={true}
                    ></TPSelect> */}
                  </TPPageFilterContainer>
                  <TPPageSearchContainer>
                    <TPTextBox
                      id="IdTextBox"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    // data={inboundMailboxInProcessList}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    onSelectedRowsChange={handleSelectedRowsChange}
                    selectableRows={true}
                    selectableRowsHighlight
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </TPLoadingOverlay>

        {/* modal pop-up code */}
        <TPModal
          modalState={{
            titleModal: `${htmlBodyColumnLabel} HTML`,
            acceptLabel: okButtonLabel,
            cancelLabel: cancelButtonLabel,
            callBackAnswer: handleCallbackAnser,
            callBackData: TPGlobal.foo,
            isShown: isShownModalBodySelect,
            modalWidth: undefined,
          }}
        >
          <div
            style={{ height: "400px", overflow: "auto" }}
            dangerouslySetInnerHTML={{ __html: `${testInerHtmlComponent}` }}
          />
        </TPModal>

        <TPModalQuestion
          id="IdModalQuestion"
          title={modalConfirmTitle}
          yesLabel={okButtonLabel}
          noLabel={cancelButtonLabel}
          question={modalConfirmQuestion}
          callBackData={modalQuestionProcessMails.callBackData}
          isShown={modalQuestionProcessMails.isShown}
          callBackAnswer={handleCallBackModalConfirm}
        ></TPModalQuestion>

        <TPModalSuccess
          modalState={{
            id: "IdModalSuccess",
            yesLabel: okButtonLabel,
            isShown: modalSuccess,
            title: modalSuccessTitle,
            callBackFunction: () => callBackSaveSuccess(),
          }}
        >
          {applyRulesResponse &&
            applyRulesResponse.map(
              ({ key, value }, index) =>
                index > 0 && (
                  <div key={key} className="d-flex bd-highlight px-2">
                    <div className="p-2 w-100 bd-highlight">
                      <TPLanguage
                        resourceSet={resourceSet}
                        resourceId={`${key}Label`}
                      />
                    </div>
                    <div className="p-2 flex-shrink-1 bd-highlight">{`${value}`}</div>
                  </div>
                ),
            )}
        </TPModalSuccess>
        <TPModalError
          modalState={{
            id: "IdModalError",
            title: "",
            yesLabel: okButtonLabel,
            isShown: modalError,
            errorMessage: formState.inboundMailboxInProcessIdErrorMessage,
            callBackFunction: () => callBackSaveError(),
          }}
        />
      </>
    );
  },
);

export default InboundMailboxInProcessAdmin;
