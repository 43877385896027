import { TPI18N } from "@/services/I18nService";
import { createTheme, ThemeProvider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useEffect, useState } from "react";
import { PaginationComponent } from "react-data-table-component/dist/DataTable/types";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import "./Styles.css";

const labelsResourceSet = "TPPaginator";

export const TPPaginator: PaginationComponent = function ({
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  rowCount,
  rowsPerPage,
}) {
  const pages = Math.ceil(rowCount / rowsPerPage);
  const theme = createTheme({
    palette: {
      primary: {
        light: "#a00095",
        main: "#a00095",
        dark: "#a00095",
        contrastText: "#ffffff",
      },
    },
  });

  const [youAreViewingLabel, setYouAreViewingLabel] = useState<string>("");
  const [itemsPerPageLabel, setItemsPerPageLabel] = useState<string>("");

  const loadLabels = async function () {
    setYouAreViewingLabel(
      await TPI18N.GetText(labelsResourceSet, "YouAreViewingLabel"),
    );
    setItemsPerPageLabel(
      await TPI18N.GetText(labelsResourceSet, "ItemsPerPageLabel"),
    );
  };

  useEffect(() => {
    loadLabels();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div id="tp-paginator">
          <div style={{ color: "gray" }}>
            {youAreViewingLabel
              .replace(
                youAreViewingLabel.includes("#") ? "#" : "/d+",
                String(
                  pages == 0
                    ? 0
                    : currentPage == pages
                      ? rowCount % rowsPerPage || rowsPerPage
                      : Math.min(rowsPerPage, rowCount),
                ),
              )
              .replace(
                youAreViewingLabel.includes("#") ? "#" : "/d+",
                String(rowCount),
              )}
          </div>
          <div className="rows-per-page-select">
            {itemsPerPageLabel}
            <TPSelect
              onChange={(_e: any, item: any) =>
                onChangeRowsPerPage(item.value, currentPage)
              }
              dataSource={[
                { key: "10", value: 10 },
                { key: "25", value: 25 },
                { key: "50", value: 50 },
                { key: "100", value: 100 },
              ]}
              value={rowsPerPage}
            />
          </div>
          <Pagination
            count={pages}
            page={currentPage}
            onChange={(event, page) => onChangePage(page, rowsPerPage)}
            shape="rounded"
            color="primary"
          />
        </div>
      </ThemeProvider>
    </>
  );
};
