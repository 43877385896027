import { TPWizardContainer } from "./tpWizardStyles";
import TPWizardSteps from "./TPWizardSteps";
import { ITPWizardStep } from "./TPWizardStep";
import { ITPWizardContent } from "./TPWizardContent";
import TPWizardContents from "./TPWizardContents";

const TPWizard = (props: any) => {
  const steps: ITPWizardStep[] = props.children
    .filter((k: any) => k.props.name === "TPWizardStep")
    .map((k: any) => {
      return {
        id: k.props.id,
        isActive: k.props.isActive,
        labelText: k.props.labelText,
        numberText: k.props.numberText,
        withIcon: k.props.withIcon,
        icon: k.props.icon,
      };
    });

  var contents: ITPWizardContent[] = props.children
    .filter((k: any) => k.props.name == "TPWizardContent")
    .map((k: any) => {
      return {
        id: k.props.id,
        children: k.props.children,
        isActive: k.props.isActive,
      };
    });
  return (
    <TPWizardContainer>
      <TPWizardSteps tpWizardSteps={steps}></TPWizardSteps>
      <TPWizardContents tpWizardContents={contents}></TPWizardContents>
    </TPWizardContainer>
  );
};

export default TPWizard;
