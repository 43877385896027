import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import FileSaver from "file-saver";
import { ReactElement } from "react";
import * as XLSX from "xlsx";
import { camelCaseToId } from "../../utils/text-regex";
import { StyledTableIcon } from "./dynamic-table-styles";
import { CustomColumnNames } from "./DynamicTable";
import { TableViews } from "./SwitchTableView";

type TableIconsProps<T extends object> = {
  id?: string;
  icons?: TableIcon[];
  exportData?: T[];
  tableView?: TableViews;
  hiddenColumns?: (keyof T)[];
  visibleColumns?: (keyof T)[];
  columnNames?: CustomColumnNames<T>;
  exportLabel: string;
  refreshLabel: string;
  onIconClick?: (item: TPIconTypes) => void;
};

export type TableIcon = {
  type: TPIconTypes;
  status: boolean;
  tooltip?: string;
};

function TableIcons<T extends object>({
  id,
  icons,
  exportData,
  tableView,
  hiddenColumns,
  visibleColumns,
  columnNames,
  exportLabel,
  refreshLabel,
  onIconClick = () => {},
}: TableIconsProps<T>): ReactElement {
  const fileType: string =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const defaultIcons: TableIcon[] = [
    ...(icons ?? []),
    {
      type: TPIconTypes.excel,
      tooltip: exportLabel,
      status: tableView === TableViews.TABLE,
    },
    { type: TPIconTypes.loop, tooltip: refreshLabel, status: true },
  ];

  const getIconId = (iconType: TPIconTypes): string | undefined => {
    return id && iconType
      ? `${id}-${camelCaseToId(iconType)}-button`
      : undefined;
  };

  const handleManageIconAction = (type: TPIconTypes) => {
    if (type !== TPIconTypes.excel) {
      return onIconClick(type);
    }

    exportTableToExcel();
  };

  const getFileName = () => {
    return `${id ? `${id}-table-data` : "table-data"}.xlsx`;
  };

  const getFilteredTableByVisibility = () => {
    if (!exportData || !visibleColumns) {
      return [];
    }

    return exportData.map((item) => {
      const filteredItem: Partial<T> = {};
      visibleColumns
        .filter((key) => !hiddenColumns?.includes(key as keyof T))
        .forEach((column) => {
          if (isCustomColumnsEnabled(column)) {
            const customColumnName = columnNames![column as keyof T];
            if (customColumnName) {
              filteredItem[customColumnName as keyof T] = item[column];
            } else {
              filteredItem[column] = item[column];
            }
          } else {
            filteredItem[column] = item[column];
          }
        });
      return filteredItem;
    });
  };

  const isCustomColumnsEnabled = (key: keyof T): boolean => {
    return (columnNames && columnNames[key] !== undefined) || false;
  };

  const exportTableToExcel = () => {
    if (exportData && exportData.length > 0) {
      const dataToExport = getFilteredTableByVisibility();
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: fileType,
      });
      FileSaver.saveAs(data, `${getFileName()}`);
    } else {
      console.error("No data for export");
    }
  };

  return (
    <StyledTableIcon>
      {defaultIcons
        .filter((icon) => icon.status)
        .map((icon, index) => (
          <TPIcon
            key={index}
            id={getIconId(icon.type)}
            iconType={icon.type}
            tooltip={icon.tooltip}
            isTooltip={icon.tooltip ? true : false}
            onClick={() => handleManageIconAction(icon.type)}
          />
        ))}
    </StyledTableIcon>
  );
}

export default TableIcons;
