import { useEffect, useState } from "react";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { MenuItemDivStyle, IcoMenuIStyle } from "./StyleImageAdmin";

import { DataType, OperationMode } from "./useStatusImagesAdmin";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";

export type EventModel = {
  Cancel: () => void;
  handleAdd: () => void;
  handleActiveEditName?: () => void;
  handleMultiSelect?: () => void;
  handleRemove: (data: RecursiveRoutes) => void;
  context: RecursiveRoutes;
  select: RecursiveRoutes;
  operationMode: string;
  right: string;
  top: string;
};

export const ImagesAdminPopupMenu: React.FC<EventModel> = ({
  Cancel,
  handleAdd,
  handleActiveEditName,
  handleRemove,
  handleMultiSelect,
  operationMode,
  select,
  context,
  right,
  top,
}) => {
  const componentFileName: string = "ImagesAdminPopupMenu.tsx";

  const [menuPosition, setMenuPosition] = useState({
    x: parseInt(right),
    y: parseInt(top),
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setDragOffset({
      x: e.clientX - menuPosition.x,
      y: e.clientY - menuPosition.y,
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      setMenuPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const value = useAppConctextImageAdmin();
  const { listMultiFileSelected } = value;

  return (
    <>
      <MenuItemDivStyle
        right={right}
        top={top}
        style={{
          transform: `translate(${menuPosition.x}px, ${menuPosition.y}px)`,
        }}
      >
        <IcoMenuIStyle
          onMouseDown={handleMouseDown}
          className="bi bi-arrows-move"
          style={{ cursor: `move` }}
        ></IcoMenuIStyle>

        <IcoMenuIStyle
          onClick={Cancel}
          className="bi bi-x-circle"
        ></IcoMenuIStyle>

        {context.type == DataType.folder && context.isActive ? (
          <IcoMenuIStyle
            onClick={handleAdd}
            className="bi bi-folder-plus"
          ></IcoMenuIStyle>
        ) : null}
        {context.isActive && listMultiFileSelected.length <= 0 ? (
          <IcoMenuIStyle
            onClick={handleActiveEditName}
            className={
              select.id != null &&
              select.id === context.id &&
              (operationMode == OperationMode.EditFile ||
                operationMode == OperationMode.EditFolder)
                ? "bi bi-floppy2-fill"
                : "bi bi-pencil-square"
            }
          ></IcoMenuIStyle>
        ) : null}

        {context.type == DataType.file ? (
          <IcoMenuIStyle
            onClick={handleMultiSelect}
            className={"bi bi-ui-checks"}
          ></IcoMenuIStyle>
        ) : null}

        <IcoMenuIStyle
          onClick={() => {
            handleRemove(context);
          }}
          className={context.isActive ? "bi bi-lock-fill" : "bi bi-unlock"}
        ></IcoMenuIStyle>
      </MenuItemDivStyle>
    </>
  );
};
