import React, {CSSProperties, useEffect, useState} from "react";
import {ProjectDetailEnum as e, TableProjectDetailModel} from "@/models/Project/Projects";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {TPButtonTypes} from "@/models/Global/TPGlobalEnums";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import {TPKeyValue} from "@/helpers/TPKeyValue";
import {UserGroupService} from "@/services/UserGroupService";
import {UserGroupViewModel} from "@/models/UserGroups/UserGroupModels";
import ProjectReassignTable from "@/pages/Projects/ProjectReassign/ProjectReassignTable";

interface ProjectReassignProps {
    m: { [attribute: string]: string };
    detail: TableProjectDetailModel;
    queues: Array<TPKeyValue>;
}

export default function ProjectReassign({m, detail, queues}: ProjectReassignProps) {
    const [open, setOpen] = useState<boolean>(false);
    const [queue, setQueue] = useState<string>('');
    const [users, setUsers] = useState<Array<UserGroupViewModel>>([]);
    const [selected, setSelected] = useState<Array<string>>([]);
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setDisabled(selected.length === 0);
    }, [selected]);

    function onClicSwitchModalHandler() {
        setOpen(!open);
    }

    function onChangeSelectHandler(groupId: string) {
        setQueue(groupId)

        if (groupId) {
            const {getAllGroupByGroupId} = new UserGroupService()

            getAllGroupByGroupId(groupId, false, true, [200, 404])
                .then(response => setUsers(response))
                .catch(error => console.error(error))
        } else {
            setUsers([])
        }
    }

    function onClickButtonSaveHandler() {
        const request: any = {
            GroupCurrent: detail.queueId,
            GroupNew: queue,
            GuidUsers: selected,
        }

        const {reassign} = new UserGroupService()
        reassign(request)
            .then(() => {
                setQueue('')
                setSelected([])
                setUsers([])
                setOpen(!open);
            })
            .catch(error => console.error(error))
    }

    function onClickButtonCloseHandler() {
        setQueue('')
        setSelected([])
        setUsers([])
        setOpen(!open);
    }

    return (
        <>
            <a id={`projectLinkReassign${detail?.queueName}`}
               key={`projectLinkReassign${detail?.queueName}`}
               style={styles.reassign}
               onClick={onClicSwitchModalHandler}>
                {m?.[e.TableColumnActionReassignCELabel]}
            </a>

            {open && !!detail && (
                <>
                    <div className={`modal${open ? " show" : ""}`}
                         style={{...styles.modal, display: open ? "block" : "none"}}
                         tabIndex={-1}
                         data-bs-backdrop="static">
                        <div className="modal-dialog modal-dialog-centered"
                             style={styles.containerModal}>
                            <div className="modal-content"
                                 style={styles.modal}>
                                <h5 style={styles.title}>{m?.[e.ModalTitleLabel]}</h5>

                                <div style={{...styles.row, gap: "10px"}}>
                                    <p style={styles.currentQueueLabel}>{m?.[e.ModalCurrentQueueLabel]}</p>

                                    <p style={styles.p}>{detail.queueName}</p>
                                </div>

                                <p style={styles.p}>{m?.[e.ModalOption1Label]}</p>

                                <TPSelect
                                    id="actionInputSort"
                                    key="actionInputSort"
                                    containerStyle={styles.input}
                                    dataSource={queues}
                                    value={`${queue ? queue : ''}`}
                                    onChange={({target}: any) => onChangeSelectHandler(target.value)}
                                />

                                <p style={styles.p}>{m?.[e.ModalOption2Label]}</p>

                                <ProjectReassignTable
                                    m={m}
                                    users={users}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <div id={`projectReassignActions${detail.queueName}`}
                                     key={`projectReassignActions${detail.queueName}`}
                                     style={styles.actionSection}>
                                    <TPButton id={`projectReassignButtonCancel${detail.queueName}`}
                                              key={`projectReassignButtonCancel${detail.queueName}`}
                                              style={styles.cancelButton}
                                              type={TPButtonTypes.primary}
                                              onClick={onClickButtonCloseHandler}>
                                        {m?.[e.ModalActionCancelLabel]}
                                    </TPButton>

                                    <TPButton id={`projectReassignButtonAccept${detail.queueName}`}
                                              key={`projectReassignButtonAccept${detail.queueName}`}
                                              type={TPButtonTypes.primary}
                                              disabled={disabled}
                                              onClick={onClickButtonSaveHandler}>
                                        {m?.[e.ModalActionReassignLabel]}
                                    </TPButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${open ? "modal-backdrop show" : ""}`}/>
                </>
            )}
        </>
    );
}

const styles = {
    column: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
    } as CSSProperties,
    row: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
    reassign: {
        color: "#A00095",
        cursor: "pointer",
        textDecoration: "underline",
    } as CSSProperties,
    containerModal: {
        minWidth: "70vw",
    } as CSSProperties,
    modal: {
        borderRadius: "5px",
        padding: "40px 30px",
        border: "none",
        outline: " none",
        gap: "20px",
    } as CSSProperties,
    title: {
        fontWeight: "bold",
        margin: "0",
    } as CSSProperties,
    p: {
        fontSize: "14px",
        margin: "0",
    } as CSSProperties,
    input: {
        width: "250px",
    } as CSSProperties,
    currentQueueLabel: {
        fontSize: "14px",
        fontWeight: "bold",
        margin: "0",
    } as CSSProperties,
    actionSection: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "flex-end",
        alignItems: "flex-end",
        gap: "10px",
    } as CSSProperties,
    cancelButton: {
        backgroundColor: "white",
        color: "#a00095",
        border: "1px solid #a00095",
    } as CSSProperties,
};
