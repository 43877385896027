import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import TPSearchCustomer from "@/components/bootstrap/forms/TPSearchCustomer/TPSearchCustomer";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import { EmailTemplateViewModel } from "@/models/EmailTemplates/EmailTemplateModels";
import {
  ConnectionProtocol,
  IMAPFolder,
  MailboxType,
  OnExistingCustomerInboundMailBox,
  OnNewCustomerInboundMailBox,
  SecurityType,
  SequeceGeneratorSequencesNameEnum,
  SSLProtocol,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  TPLanguageFilterEnum,
} from "@/models/Global/TPGlobalEnums";
import { GroupsViewModel } from "@/models/Groups/GroupsModels";
import { InboundMailAzureApplicationViewModel } from "@/models/InboundMailAzureApplication/InboundMailAzureApplicationViewModel";
import {
  InboundMailboxesInputDTO,
  InboundMailboxesInputDTOValidator,
} from "@/models/InboundMailboxes/InboundMailboxesInputDTO";
import { InboundMailboxesViewModel } from "@/models/InboundMailboxes/InboundMailboxesViewModel";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { ClientService } from "@/services/ClientService";
import { CustomerTypeService } from "@/services/CustomerTypeService";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import { GroupsService } from "@/services/GroupsService";
import { TPI18N } from "@/services/I18nService";
import { InboundMailAzureApplicationService } from "@/services/InboundMailAzureApplicationService";
import { InboundMailboxesService } from "@/services/InboundMailboxesService";
import LanguageService from "@/services/LanguageService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { SequenceService } from "@/services/SequenceService";
import { FC, useEffect, useState } from "react";

type InboundMailboxesInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  onDescriptionFetched: Function;
};
type InsertUpdateStateType = {
  id: string;
  description: string;
  mailboxType: string;
  userEmailAccount: string;
  passwordEmailAccount: string;
  authenticationAzureAppId: string | null;
  server: string;
  connectionProtocol: string;
  securityType: number;
  port: number;
  sslProtocol: string;
  folderToRead: string;
  emailTemplateNotify: string;
  emailNotifyErrors: string;
  language: string;
  responsableGroupInProcess: string | null;
  isActive: boolean;

  recordLanguageList: Array<TPKeyValue>;

  autoReplyTemplateForSubsequentEmail: string;
  defaultClassifier: string;
  autoresponseEmailTemplate: string | null;
  reopenCaseWhenPatternExists: boolean;
  enableEditCaseComments: boolean;
  whenEmailExistsMoreThanOnce: number;
  useThisCustomerMoreThanOnce: number | null;
  useThisCustomerMoreThanOnceValueChange: string;
  whenEmailIsNew: number;
  useThisCustomerIsNew: number | null;
  useThisCustomerIsNewValueChange: string;
  customerTypeNewCustomer: string;

  //validator
  idErrorMessage: string;
  descriptionErrorMessage: string;
  mailboxTypeErrorMessage: string;
  userEmailAccountErrorMessage: string;
  passwordEmailAccountErrorMessage: string;
  authenticationAzureAppIdErrorMessage: string;
  serverErrorMessage: string;
  connectionProtocolErrorMessage: string;
  securityTypeErrorMessage: string;
  portErrorMessage: string;
  sslProtocolErrorMessage: string;
  folderToReadErrorMessage: string;
  emailTemplateNotifyErrorErrorMessage: string;
  emailNotifyErrorsErrorMessage: string;
  languageErrorMessage: string;
  responsableGroupInProcessErrorMessage: string;

  descriptionErrorMessages: Array<string>;

  autoReplyTemplateForSubsequentEmailErrorMessage: string;
  defaultClassifierErrorMessage: string;
  autoresponseEmailTemplateErrorMessage: string;
  reopenCaseWhenPatternExistsErrorMessage: string;
  enableEditCaseCommentsErrorMessage: string;
  whenEmailExistsMoreThanOnceErrorMessage: string;
  useThisCustomerMoreThanOnceErrorMessage: string;
  whenEmailIsNewErrorMessage: string;
  useThisCustomerIsNewErrorMessage: string;
  customerTypeNewCustomerErrorMessage: string;

  [key: string]: any;
};

const InboundMailboxesInsertUpdate: FC<InboundMailboxesInsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  onDescriptionFetched,
}) => {
  const componentFileName: string = "InboundMailboxesInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //Screen resources
  const resourceSet: string = "InboundMailboxesInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [caseOrCustomerInfoSectionLabel, setCaseOrCustomerInfoSectionLabel] =
    useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");

  const [idLabel, setIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [mailboxTypeLabel, setMailboxTypeLabel] = useState("");
  const [userEmailAccountLabel, setUserEmailAccountLabel] = useState("");
  const [passwordEmailAccountLabel, setPasswordEmailAccountLabel] =
    useState("");
  const [authenticationAzureAppIdLabel, setAuthenticationAzureAppIdLabel] =
    useState("");
  const [serverLabel, setServerLabel] = useState("");
  const [connectionProtocolLabel, setConnectionProtocolLabel] = useState("");
  const [securityTypeLabel, setSecurityTypeLabel] = useState("");
  const [portLabel, setPortLabel] = useState("");
  const [sslProtocolLabel, setSslProtocolLabel] = useState("");
  const [folderToReadLabel, setFolderToReadLabel] = useState("");
  const [emailTemplateNotifyErrorLabel, setEmailTemplateNotifyErrorLabel] =
    useState("");
  const [emailNotifyErrorsLabel, setEmailNotifyErrorsLabel] = useState("");
  const [emailNotifyTooltipMessageLabel, setEmailNotifyTooltipMessageLabel] =
    useState("");
  const [languageLabel, setLanguageLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [responsibleGroupInProcessLabel, setResponsibleGroupInProcessLabel] =
    useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState("");

  const [
    autoReplyTemplateForSubsequentEmailLabel,
    setAutoReplyTemplateForSubsequentEmailLabel,
  ] = useState("");
  const [defaultClassifierLabel, setDefaultClassifierLabel] = useState("");
  const [autoresponseEmailTemplateLabel, setAutoresponseEmailTemplateLabel] =
    useState("");
  const [
    reopenCaseWhenPatternExistsLabel,
    setReopenCaseWhenPatternExistsLabel,
  ] = useState("");
  const [enableEditCaseCommentsLabel, setEnableEditCaseCommentsLabel] =
    useState("");
  const [
    whenEmailExistsMoreThanOnceLabel,
    setWhenEmailExistsMoreThanOnceLabel,
  ] = useState("");
  const [
    useThisCustomerMoreThanOnceLabel,
    setUseThisCustomerMoreThanOnceLabel,
  ] = useState("");
  const [whenEmailIsNewLabel, setWhenEmailIsNewLabel] = useState("");
  const [useThisCustomerIsNewLabel, setUseThisCustomerIsNewLabel] =
    useState("");
  const [customerTypeNewCustomerLabel, setCustomerTypeNewCustomerLabel] =
    useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");

  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);

  //Multilanguage const
  const multilanguageTableName: String = "INboundMailBox";
  const multilanguageFieldName: String = "Description_INMB";

  //TPBranchSelect Resources
  const [emptyLabel, setEmptyLabel] = useState("");

  const [sslProtocolsToSelect, setSSLProtocolsToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [folderToReadsToSelect, setFolderToReadsToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [mailboxTypesToSelect, setMailboxTypesToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [onNewCustomersToSelect, setOnNewCustomersToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [onExistingCustomersToSelect, setOnExistingCustomersToSelect] =
    useState<Array<TPKeyValue>>([]);
  const [securityTypesToSelect, setSecurityTypesToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [connectionProtocolsToSelect, setConnectionProtocolsToSelect] =
    useState<Array<TPKeyValue>>([]);
  const [languagesToSelect, setLanguagesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [quickClassifiersToSelect, setQuickClassifiersToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [
    authenticationsAzureAppIdToSelect,
    setAuthenticationsAzureAppIdToSelect,
  ] = useState<Array<TPKeyValue>>([]);
  const [customerTypesToSelect, setCustomerTypesToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [responsibleGroupToSelect, setResponsibleGroupToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [
    emailTemplateNotifyErrorToSelect,
    setEmailTemplateNotifyErrorToSelect,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autoReplyTemplateForSubsequentEmailToSelect,
    setAutoReplyTemplateForSubsequentEmailToSelect,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autoresponseEmailTemplateToSelect,
    setAutoresponseEmailTemplateToSelect,
  ] = useState<Array<TPKeyValue>>([]);
  const [referenceId, setReferenceId] = useState("");
  //AutoComplete ResponsibleGroup
  const [selectedResponsibleGroup, setSelectedResponsibleGroup] = useState<
    Array<TPKeyValue>
  >([]);
  const [
    autocompleteResponsibleGroupOptions,
    setAutocompleteResponsibleGroupOptions,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteResponsibleGroupTopNOptions,
    setAutocompleteResponsibleGroupTopNOptions,
  ] = useState<Array<TPKeyValue>>([]);

  //AutoComplete EmailTemplateNotifyError
  const [
    selectedEmailTemplateNotifyError,
    setSelectedEmailTemplateNotifyError,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteEmailTemplateNotifyErrorOptions,
    setAutocompleteEmailTemplateNotifyErrorOptions,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteEmailTemplateNotifyErrorTopNOptions,
    setAutocompleteEmailTemplateNotifyErrorTopNOptions,
  ] = useState<Array<TPKeyValue>>([]);

  //AutoComplete AutoReplyTemplateForSubsequentEmail
  const [
    selectedAutoReplyTemplateForSubsequentEmail,
    setSelectedAutoReplyTemplateForSubsequentEmail,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteAutoReplyTemplateForSubsequentEmailOptions,
    setAutocompleteAutoReplyTemplateForSubsequentEmailOptions,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteAutoReplyTemplateForSubsequentEmailTopNOptions,
    setAutocompleteAutoReplyTemplateForSubsequentEmailTopNOptions,
  ] = useState<Array<TPKeyValue>>([]);

  //AutoComplete AutoresponseEmailTemplate
  const [
    selectedAutoresponseEmailTemplate,
    setSelectedAutoresponseEmailTemplate,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteAutoresponseEmailTemplateOptions,
    setAutocompleteAutoresponseEmailTemplateOptions,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteAutoresponseEmailTemplateTopNOptions,
    setAutocompleteAutoresponseEmailTemplateTopNOptions,
  ] = useState<Array<TPKeyValue>>([]);

  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }

  let insertUpdateInitialState: InsertUpdateStateType = {
    id: "",
    description: "",
    mailboxType: "",
    userEmailAccount: "",
    passwordEmailAccount: "",
    authenticationAzureAppId: "",
    server: "",
    connectionProtocol: "",
    securityType: 0,
    port: 0,
    sslProtocol: "",
    folderToRead: "",
    emailTemplateNotify: "",
    emailNotifyErrors: "",
    language: "",
    responsableGroupInProcess: "",
    isActive: true,

    autoReplyTemplateForSubsequentEmail: "",
    defaultClassifier: "",
    autoresponseEmailTemplate: "",
    reopenCaseWhenPatternExists: true,
    enableEditCaseComments: true,
    whenEmailExistsMoreThanOnce: 0,
    useThisCustomerMoreThanOnce: null,
    useThisCustomerMoreThanOnceValueChange: "",
    whenEmailIsNew: 0,
    useThisCustomerIsNew: null,
    useThisCustomerIsNewValueChange: "",
    customerTypeNewCustomer: "",

    recordLanguageList: [],

    idErrorMessage: "",
    descriptionErrorMessage: "",
    mailboxTypeErrorMessage: "",
    userEmailAccountErrorMessage: "",
    passwordEmailAccountErrorMessage: "",
    authenticationAzureAppIdErrorMessage: "",
    serverErrorMessage: "",
    connectionProtocolErrorMessage: "",
    securityTypeErrorMessage: "",
    portErrorMessage: "",
    sslProtocolErrorMessage: "",
    folderToReadErrorMessage: "",
    emailTemplateNotifyErrorErrorMessage: "",
    emailNotifyErrorsErrorMessage: "",
    languageErrorMessage: "",
    responsableGroupInProcessErrorMessage: "",

    descriptionErrorMessages: [...initialErrorMessages],

    autoReplyTemplateForSubsequentEmailErrorMessage: "",
    defaultClassifierErrorMessage: "",
    autoresponseEmailTemplateErrorMessage: "",
    reopenCaseWhenPatternExistsErrorMessage: "",
    enableEditCaseCommentsErrorMessage: "",
    whenEmailExistsMoreThanOnceErrorMessage: "",
    useThisCustomerMoreThanOnceErrorMessage: "",
    whenEmailIsNewErrorMessage: "",
    useThisCustomerIsNewErrorMessage: "",
    customerTypeNewCustomerErrorMessage: "",
  };

  const [insertUpdateState, setInsertUpdateState] =
    useState<InsertUpdateStateType>(insertUpdateInitialState);

  const loadResourcesAndLoadInfo = async () => {
    //resources state
    let i: number;
    setTitleLabel(
      await TPI18N.GetText(resourceSet, "Title" + realMode + "Label"),
    );
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setCaseOrCustomerInfoSectionLabel(
      await TPI18N.GetText(
        resourceSet,
        "CaseOrCustomerInformationSectionLabel",
      ),
    );

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setMailboxTypeLabel(
      await TPI18N.GetText(resourceSet, "EmailAccountTypeLabel"),
    );
    setUserEmailAccountLabel(
      await TPI18N.GetText(resourceSet, "UserEmailAccountLabel"),
    );
    setPasswordEmailAccountLabel(
      await TPI18N.GetText(resourceSet, "PasswordEmailAccountLabel"),
    );
    setAuthenticationAzureAppIdLabel(
      await TPI18N.GetText(resourceSet, "AuthenticationAzureAppIdLabel"),
    );
    setServerLabel(
      await TPI18N.GetText(resourceSet, "ServerInboundMailboxLabel"),
    );
    setConnectionProtocolLabel(
      await TPI18N.GetText(resourceSet, "ConnectionProtocolLabel"),
    );
    setSecurityTypeLabel(
      await TPI18N.GetText(resourceSet, "SecurityTypeLabel"),
    );
    setPortLabel(await TPI18N.GetText(resourceSet, "PortLabel"));
    setSslProtocolLabel(await TPI18N.GetText(resourceSet, "SslProtocolLabel"));
    setFolderToReadLabel(
      await TPI18N.GetText(resourceSet, "FolderToReadLabel"),
    );
    setEmailTemplateNotifyErrorLabel(
      await TPI18N.GetText(resourceSet, "EmailTemplateToNotifyErrorsLabel"),
    );
    setEmailNotifyErrorsLabel(
      await TPI18N.GetText(resourceSet, "EmailAddresToNotifyErrorsLabel"),
    );
    setEmailNotifyTooltipMessageLabel(
      await TPI18N.GetText(resourceSet, "EmailNotifyTooltipMessageLabel"),
    );
    setLanguageLabel(
      await TPI18N.GetText(resourceSet, "LanguageInboundMailBoxLabel"),
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "ActiveLabel"));
    setResponsibleGroupInProcessLabel(
      await TPI18N.GetText(resourceSet, "ResponsibleGroupForInProcessLabel"),
    );

    setAutoReplyTemplateForSubsequentEmailLabel(
      await TPI18N.GetText(
        resourceSet,
        "AutoReplyTemplateForSubsequentEmailLabel",
      ),
    );
    setDefaultClassifierLabel(
      await TPI18N.GetText(resourceSet, "DefaultClassifierLabel"),
    );
    setAutoresponseEmailTemplateLabel(
      await TPI18N.GetText(resourceSet, "AutoresponseEmailTemplateLabel"),
    );
    setReopenCaseWhenPatternExistsLabel(
      await TPI18N.GetText(resourceSet, "ReopenCaseLabel"),
    );
    setEnableEditCaseCommentsLabel(
      await TPI18N.GetText(resourceSet, "EnabledEditingOfCaseCommentsLabel"),
    );
    setWhenEmailExistsMoreThanOnceLabel(
      await TPI18N.GetText(resourceSet, "WhenTheEmailExistisMoreThanOnceLabel"),
    );
    setUseThisCustomerMoreThanOnceLabel(
      await TPI18N.GetText(resourceSet, "UseThisCustomerLabel"),
    );
    setWhenEmailIsNewLabel(
      await TPI18N.GetText(resourceSet, "WhenEmailAddressIsNewLabel"),
    );
    setUseThisCustomerIsNewLabel(
      await TPI18N.GetText(resourceSet, "UseThisCustomerLabel"),
    );
    setCustomerTypeNewCustomerLabel(
      await TPI18N.GetText(resourceSet, "CustomerTypeForNewCustomersLabel"),
    );
    setIsInvalidEmail(await TPI18N.GetText(resourceSet, "InvalidEmail"));

    setEmptyLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel"),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );

    //screen state

    await getOnNewCustomers();
    await getOnExistingCustomers();
    await getConnectionProtocols();
    await getInboundMailAzureApplication();
    await getSecurityTypes();
    await getSSLProtocols();
    await getFolderToReads();
    await getMailboxTypes();
    await getLanguages();
    await getGroups();
    await getEmailTemplates();
    await getQuickClassifiers();
    await getCustomerTypes();

    if (realMode === "Update") {
      await getById(realRecordId);
    }

    if (realMode === "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }

      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }

    setIsLoadingScreen(false);
  };

  const getById = async (pRecordId: string) => {
    let serviceClient = new InboundMailboxesService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    let newCustomer_SUOR: ClientAdminViewModel | null;
    let existingCustomer_SUOR: ClientAdminViewModel | null;

    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getInboundMailboxById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );
      let recordInfo: InboundMailboxesViewModel;
      recordInfo = { ...responseRequest };
      setOriginalRecordDescription(recordInfo.localizedDescription);
      onDescriptionFetched(recordInfo.localizedDescription);
      let newInsertUpdateState = { ...insertUpdateState };

      newInsertUpdateState.recordLanguageList = [];

      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }

      newInsertUpdateState.id = recordInfo.id;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      newInsertUpdateState.mailboxType = recordInfo.authenticationType;
      newInsertUpdateState.userEmailAccount = recordInfo.emailAccount;
      newInsertUpdateState.passwordEmailAccount =
        recordInfo.passwordEmailAccount ?? "";
      newInsertUpdateState.authenticationAzureAppId =
        recordInfo.authenticationAzureAppId;
      newInsertUpdateState.server = recordInfo.serverEmailAccount;
      newInsertUpdateState.connectionProtocol =
        recordInfo.protocolTypeEmailAccount;
      newInsertUpdateState.securityType = recordInfo.sslType;
      newInsertUpdateState.port = recordInfo.port;
      newInsertUpdateState.sslProtocol = recordInfo.sslProtocol;
      newInsertUpdateState.folderToRead = recordInfo.imapFolder;
      setSelectedEmailTemplateNotifyError(
        await GetEmailTemplateRecordById(recordInfo.templateProcessErrorId),
      );
      newInsertUpdateState.emailNotifyErrors =
        recordInfo.emailAddressProcessError;
      newInsertUpdateState.language = recordInfo.languageId;
      setSelectedResponsibleGroup(
        await GetGroupRecordById(recordInfo.responsibleGroupId),
      );
      newInsertUpdateState.isActive = recordInfo.isActive;
      setSelectedAutoReplyTemplateForSubsequentEmail(
        await GetEmailTemplateRecordById(
          recordInfo.templateAutoResponderSubsequentEmailId,
        ),
      );
      setSelectedAutoresponseEmailTemplate(
        await GetEmailTemplateRecordById(recordInfo.templateAutoRespondeId),
      );
      newInsertUpdateState.defaultClassifier = recordInfo.defaultClassifierId;
      newInsertUpdateState.reopenCaseWhenPatternExists =
        recordInfo.reopenCaseOnAdd;
      newInsertUpdateState.enableEditCaseComments =
        recordInfo.enableEditingCaseComments;
      newInsertUpdateState.whenEmailExistsMoreThanOnce =
        recordInfo.onExistingCustomerAction;
      newInsertUpdateState.whenEmailIsNew = recordInfo.onNewCustomerAction;
      newInsertUpdateState.customerTypeNewCustomer = recordInfo.customerTypeId;

      existingCustomer_SUOR = await GetSubsidiaryOrganizationRecordById(
        recordInfo.onExistingCustomerFixedSubsidiaryOrganizationId,
      );
      if (existingCustomer_SUOR !== null && existingCustomer_SUOR !== null) {
        newInsertUpdateState.useThisCustomerMoreThanOnce =
          existingCustomer_SUOR?.id ?? null;
        newInsertUpdateState.useThisCustomerMoreThanOnceValueChange =
          existingCustomer_SUOR?.name + " " + existingCustomer_SUOR?.lastName;
      } else {
        newInsertUpdateState.useThisCustomerMoreThanOnce = null;
        newInsertUpdateState.useThisCustomerMoreThanOnceValueChange = "";
      }

      newCustomer_SUOR = await GetSubsidiaryOrganizationRecordById(
        recordInfo.onNewCustomerFixedSubsidiaryOrganizationId,
      );
      if (newCustomer_SUOR !== null && newCustomer_SUOR !== null) {
        newInsertUpdateState.useThisCustomerIsNew =
          newCustomer_SUOR?.id ?? null;
        newInsertUpdateState.useThisCustomerIsNewValueChange =
          newCustomer_SUOR?.name + " " + newCustomer_SUOR?.lastName;
      } else {
        newInsertUpdateState.useThisCustomerIsNew = null;
        newInsertUpdateState.useThisCustomerIsNewValueChange = "";
      }

      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length === 0) {
        TPLog.Log(
          `Error ${componentFileName} getById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getById getRecordLanguageList is empty`,
        );
      } else {
        for (
          i = 0;
          i <= newInsertUpdateState.recordLanguageList.length - 1;
          i++
        ) {
          for (j = 0; j <= recordLanguagesList.length - 1; j++) {
            if (
              newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ===
              recordLanguagesList[j].languageId.toLowerCase()
            ) {
              newInsertUpdateState.recordLanguageList[i].value =
                recordLanguagesList[j].recordDescription;
              break;
            }
          }
        }
      }

      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const getSSLProtocols = async () => {
    const sslProtocolsValues = Object.values(SSLProtocol);

    const sslProtocolsToSelectConverted: Array<TPKeyValue> = await Promise.all(
      sslProtocolsValues.map(async (k: any) => {
        return {
          key: k,
          value: k,
        };
      }),
    );

    sslProtocolsToSelectConverted.unshift({ key: "", value: "--" });
    setSSLProtocolsToSelect(sslProtocolsToSelectConverted);
  };

  const getFolderToReads = async () => {
    const folderToReadsValues = Object.values(IMAPFolder);

    const folderToReadsToSelectConverted: Array<TPKeyValue> = await Promise.all(
      folderToReadsValues.map(async (k: any) => {
        return {
          key: k,
          value: await TPI18N.GetText(resourceSet, k + "ItemKeyValue"),
        };
      }),
    );

    folderToReadsToSelectConverted.unshift({ key: "", value: "--" });
    setFolderToReadsToSelect(folderToReadsToSelectConverted);
  };

  const getOnExistingCustomers = async () => {
    const onExistingCustomerValues = Object.values(
      OnExistingCustomerInboundMailBox,
    );
    const onExistingCustomerKeys = Object.keys(
      OnExistingCustomerInboundMailBox,
    );

    const onExistingCustomersToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        onExistingCustomerKeys.map(async (k: any, index: number) => {
          return {
            key: onExistingCustomerValues[index],
            value: await TPI18N.GetText(resourceSet, k + "ItemKeyValue"),
          };
        }),
      );

    onExistingCustomersToSelectConverted.unshift({ key: "", value: "--" });
    setOnExistingCustomersToSelect(onExistingCustomersToSelectConverted);
  };

  const getOnNewCustomers = async () => {
    const onNewCustomerValues = Object.values(OnNewCustomerInboundMailBox);
    const onNewCustomerKeys = Object.keys(OnNewCustomerInboundMailBox);

    const onNewCustomersToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        onNewCustomerKeys.map(async (k: any, index: number) => {
          return {
            key: onNewCustomerValues[index],
            value: await TPI18N.GetText(resourceSet, k + "ItemKeyValue"),
          };
        }),
      );

    onNewCustomersToSelectConverted.unshift({ key: "", value: "--" });
    setOnNewCustomersToSelect(onNewCustomersToSelectConverted);
  };

  const getMailboxTypes = async () => {
    const mailboxTypesValues = Object.values(MailboxType);

    const mailboxTypesToSelectConverted: Array<TPKeyValue> = await Promise.all(
      mailboxTypesValues.map(async (k: any) => {
        return {
          key: k,
          value: await TPI18N.GetText(resourceSet, k + "ItemKeyValue"),
        };
      }),
    );

    mailboxTypesToSelectConverted.unshift({ key: "", value: "--" });
    setMailboxTypesToSelect(mailboxTypesToSelectConverted);
  };

  const getSecurityTypes = async () => {
    const securityTypesKeys = Object.keys(SecurityType);
    const securityTypesValues = Object.values(SecurityType);

    const securityTypesToSelectConverted: Array<TPKeyValue> = await Promise.all(
      securityTypesKeys.map(async (k: any, index: number) => {
        return {
          key: securityTypesValues[index],
          value: await TPI18N.GetText(resourceSet, k + "ItemKeyValue"),
        };
      }),
    );

    securityTypesToSelectConverted.unshift({ key: "", value: "--" });
    setSecurityTypesToSelect(securityTypesToSelectConverted);
  };

  const getConnectionProtocols = async () => {
    const connectionProtocolsValues = Object.values(ConnectionProtocol);

    const connectionProtocolsToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        connectionProtocolsValues.map(async (k: any) => {
          return {
            key: k,
            value: await TPI18N.GetText(resourceSet, k + "ItemKeyValue"),
          };
        }),
      );

    connectionProtocolsToSelectConverted.unshift({ key: "", value: "--" });
    setConnectionProtocolsToSelect(connectionProtocolsToSelectConverted);
  };

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false,
      true,
      expectedCodes,
    );

    const languages = response.responseData.data;

    const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
      (k: any) => {
        return {
          key: k.id,
          value: k.name,
        };
      },
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguagesToSelect(languagesToSelectConverted);
  };

  const getInboundMailAzureApplication = async () => {
    const serviceClient = new InboundMailAzureApplicationService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await serviceClient.getInboundMailAzureApplicationByFilter(
      TPActiveOptions.ACTIVE.toString(),
      false,
      true,
      expectedCodes,
    );

    const inboundMailAzureApplication: Array<InboundMailAzureApplicationViewModel> =
      response ?? [];

    const inboundMailAzureAppToSelectConverted: Array<TPKeyValue> =
      inboundMailAzureApplication.map((k: any) => {
        return {
          key: k.id,
          value: k.localizedDescription,
        };
      });
    inboundMailAzureAppToSelectConverted.unshift({ key: "", value: "--" });
    setAuthenticationsAzureAppIdToSelect(inboundMailAzureAppToSelectConverted);
  };

  const getQuickClassifiers = async () => {
    const quickClassifierClient = new QuickClassifierService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await quickClassifierClient.getQuickClassifierByFilter(
      TPActiveOptions.ACTIVE.toString(),
      false,
      true,
      expectedCodes,
    );

    const quickClassifiers = response;

    const quickClassifiersToSelectConverted: Array<TPKeyValue> =
      quickClassifiers.map((k: any) => {
        return {
          key: k.id,
          value: k.description,
        };
      });
    quickClassifiersToSelectConverted.unshift({ key: "", value: "--" });
    setQuickClassifiersToSelect(quickClassifiersToSelectConverted);
  };

  const getCustomerTypes = async () => {
    const customerTypeClient = new CustomerTypeService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await customerTypeClient.getCustomerTypeByFilter(
      TPActiveOptions.ACTIVE.toString(),
      false,
      true,
      expectedCodes,
    );

    const customerTypes = response;

    const customerTypesToSelectConverted: Array<TPKeyValue> = customerTypes.map(
      (k: any) => {
        return {
          key: k.id,
          value: k.description,
        };
      },
    );
    customerTypesToSelectConverted.unshift({ key: "", value: "--" });
    setCustomerTypesToSelect(customerTypesToSelectConverted);
  };

  const getGroups = async () => {
    const groupClient = new GroupsService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await groupClient.getGroupsByFilter(
      TPActiveOptions.ACTIVE.toString(),
      false,
      true,
      expectedCodes,
    );

    const groupsToSelectConverted: Array<TPKeyValue> = response.map(
      (k: any) => {
        return {
          key: k.groupId,
          value: k.user.firstName,
        };
      },
    );
    groupsToSelectConverted.unshift({ key: "", value: "--" });

    setSelectedResponsibleGroup([]);
    setResponsibleGroupToSelect(groupsToSelectConverted);
  };

  const GetGroupRecordById = async (idGroup: number | null) => {
    let group: Array<TPKeyValue> = [];
    if (idGroup === null) return group;

    let serviceClient = new GroupsService();
    let expectedCodes: Array<number> = [200];

    try {
      let responseRequest = await serviceClient.getGroupById(
        idGroup.toString(),
        false,
        true,
        expectedCodes,
      );
      let recordInfo: GroupsViewModel;
      recordInfo = { ...responseRequest };

      let keyvalue: TPKeyValue = {
        key: recordInfo.groupId,
        value: recordInfo.user.firstName,
      };
      group.push(keyvalue);

      return group;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} GetGroupRecordById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} GetGroupRecordById ex`);
      return [];
    }
  };

  const getEmailTemplates = async () => {
    const emailTemplateClient = new EmailTemplateService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await emailTemplateClient.getEmailTemplateFilterByLanguage(
      "ALL",
      TPActiveOptions.ACTIVE.toString(),
      false,
      true,
      expectedCodes,
    );

    let emailTemplateToSelectConverted: Array<TPKeyValue> = response.map(
      (k: any) => {
        return {
          key: k.id,
          value: k.description,
        };
      },
    );
    emailTemplateToSelectConverted.unshift({ key: "", value: "--" });
    emailTemplateToSelectConverted = DistinctList(
      emailTemplateToSelectConverted,
    );

    setSelectedEmailTemplateNotifyError([]);
    setEmailTemplateNotifyErrorToSelect(emailTemplateToSelectConverted);

    setSelectedAutoReplyTemplateForSubsequentEmail([]);
    setAutoReplyTemplateForSubsequentEmailToSelect(
      emailTemplateToSelectConverted,
    );

    setSelectedAutoresponseEmailTemplate([]);
    setAutoresponseEmailTemplateToSelect(emailTemplateToSelectConverted);
  };

  const GetEmailTemplateRecordById = async (idEmailtemplate: string | null) => {
    let emailtemplate: Array<TPKeyValue> = [];
    if (idEmailtemplate === null || idEmailtemplate === "")
      return emailtemplate;

    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      let responseRequest = await serviceClient.getEmailTemplateByTemplateId(
        idEmailtemplate,
        false,
        false,
        expectedCodes,
      );

      if (responseRequest === undefined) return emailtemplate;

      let recordInfo: EmailTemplateViewModel;
      recordInfo = { ...responseRequest };

      let keyvalue: TPKeyValue = {
        key: recordInfo.id,
        value: recordInfo.description,
      };
      emailtemplate.push(keyvalue);

      return emailtemplate;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} GetEmailTemplateRecordById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} GetEmailTemplateRecordById ex`);
      return [];
    }
  };

  const GetSubsidiaryOrganizationRecordById = async (id: number) => {
    if (id <= 0) return null;

    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200];

    try {
      let responseRequest =
        await serviceClient.getClientBySubsidiaryOrganizationId(
          id,
          false,
          true,
          expectedCodes,
        );

      if (responseRequest === undefined || responseRequest.length === 0)
        return null;

      return responseRequest[0];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} GetSubsidiaryOrganizationRecordById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} GetSubsidiaryOrganizationRecordById ex`,
      );
      return null;
    }
  };

  const SearchQueryAutocomplete = (query: string, listValues: TPKeyValue[]) => {
    query = query.toLowerCase();

    return listValues.filter((x) => `${x.value}`.toLowerCase().includes(query));
  };

  const DistinctList = (arrayList: TPKeyValue[]) => {
    let intI: number;
    let intK: number;
    let bolWExists: boolean;
    let finalArray: Array<TPKeyValue>;

    finalArray = [];

    for (intI = 0; intI <= arrayList.length - 1; intI++) {
      bolWExists = false;
      for (intK = 0; intK <= finalArray.length - 1; intK++) {
        if (arrayList[intI].key == finalArray[intK].key) {
          bolWExists = true;
          break;
        }
      }
      if (!bolWExists) {
        finalArray.push(arrayList[intI]);
      }
    }

    return finalArray;
  };

  const IsValidOnKeyDown = (event: any) => {
    return (
      event.keyCode !== 9 && //tab
      event.keyCode !== 13 && //enter
      event.keyCode !== 35 && //home
      event.keyCode !== 36 && //end
      event.keyCode !== 37 && //left arrow
      event.keyCode !== 38 && //down arrow
      event.keyCode !== 39 && //right arrow
      event.keyCode !== 40 //up arrow
    );
  };

  const GetAutoCompleteSelected = (object: any) => {
    if (object?.length > 0) {
      return object[0].key;
    }
    return null;
  };

  //#region "Responsible Group Autocomplete Functions"

  const handleResponsibleGroupOnAutocompleteQuery = async (query: string) => {
    let newKeyValueList: Array<TPKeyValue>;
    newKeyValueList = SearchQueryAutocomplete(query, responsibleGroupToSelect);

    setAutocompleteResponsibleGroupOptions(newKeyValueList);
    return newKeyValueList;
  };

  const handleResponsibleGroupOnAutocompleteText = (text: string) => {
    if (!text) {
      setAutocompleteResponsibleGroupOptions(responsibleGroupToSelect);
      handleResponsibleGroupChange([]);
    }
  };

  const handleResponsibleGroupOnAutocompleteKeyDown = (event: any) => {
    // if (IsValidOnKeyDown(event)) {
    //   setAutocompleteResponsibleGroupOptions([]);
    //   handleResponsibleGroupChange([]);
    // }
  };

  const handleAutoCompleteTopNResponsibleGroupClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteResponsibleGroupTopNOptions.length === 0) {
      newTopNOptions = await handleResponsibleGroupOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteResponsibleGroupTopNOptions([...newTopNOptions]);
        setAutocompleteResponsibleGroupOptions([...newTopNOptions]);
        handleResponsibleGroupChange([]);
      }
    } else {
      //use cached values;
      setAutocompleteResponsibleGroupTopNOptions([
        ...autocompleteResponsibleGroupTopNOptions,
      ]);
      handleResponsibleGroupChange([]);
    }
  };

  const handleResponsibleGroupChange = (
    newSelectedValue: Array<TPKeyValue>,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.responsableGroupInProcessErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
    setSelectedResponsibleGroup(newSelectedValue);
  };

  //#endregion "Responsible Group Autocomplete Functions"

  //#region "Email Template Notify Error Autocomplete Functions"

  const handleEmailTemplateNotifyErrorOnAutocompleteQuery = async (
    query: string,
  ) => {
    let newKeyValueList: Array<TPKeyValue>;
    newKeyValueList = SearchQueryAutocomplete(
      query,
      emailTemplateNotifyErrorToSelect,
    );

    setAutocompleteEmailTemplateNotifyErrorOptions(newKeyValueList);
    return newKeyValueList;
  };

  const handleEmailTemplateNotifyErrorOnAutocompleteKeyDown = (
    event: any,
  ) => {};

  const handleEmailTemplateNotifyErrorOnAutocompleteText = (text: string) => {
    if (!text) {
      setAutocompleteEmailTemplateNotifyErrorOptions(
        emailTemplateNotifyErrorToSelect,
      );
      handleEmailTemplateNotifyErrorChange([]);
    }
  };

  const handleAutoCompleteTopNEmailTemplateNotifyErrorClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteEmailTemplateNotifyErrorTopNOptions.length === 0) {
      newTopNOptions =
        await handleEmailTemplateNotifyErrorOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteEmailTemplateNotifyErrorTopNOptions([...newTopNOptions]);
        setAutocompleteEmailTemplateNotifyErrorOptions([...newTopNOptions]);
        handleEmailTemplateNotifyErrorChange([]);
      }
    } else {
      //use cached values;
      setAutocompleteEmailTemplateNotifyErrorTopNOptions([
        ...autocompleteEmailTemplateNotifyErrorTopNOptions,
      ]);
      handleEmailTemplateNotifyErrorChange([]);
    }
  };

  const handleEmailTemplateNotifyErrorChange = (
    newSelectedValue: Array<TPKeyValue>,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.emailTemplateNotifyErrorErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
    setSelectedEmailTemplateNotifyError(newSelectedValue);
  };

  //#endregion "Email Template Notify Error Autocomplete Functions"

  //#region "Auto Reply Template For Subsequent Email Autocomplete Functions"

  const handleAutoReplyTemplateForSubsequentEmailOnAutocompleteQuery = async (
    query: string,
  ) => {
    let newKeyValueList: Array<TPKeyValue>;
    newKeyValueList = SearchQueryAutocomplete(
      query,
      autoReplyTemplateForSubsequentEmailToSelect,
    );

    setAutocompleteAutoReplyTemplateForSubsequentEmailOptions(newKeyValueList);
    return newKeyValueList;
  };

  const handleAutoReplyTemplateForSubsequentEmailOnAutocompleteText = (
    text: string,
  ) => {
    if (!text) {
      setAutocompleteAutoReplyTemplateForSubsequentEmailOptions(
        autoReplyTemplateForSubsequentEmailToSelect,
      );
      handleAutoReplyTemplateForSubsequentEmailChange([]);
    }
  };

  const handleAutoReplyTemplateForSubsequentEmailOnAutocompleteKeyDown = (
    event: any,
  ) => {
    // if (IsValidOnKeyDown(event)) {
    //   setAutocompleteAutoReplyTemplateForSubsequentEmailOptions([]);
    //   handleAutoReplyTemplateForSubsequentEmailChange([]);
    // }
  };

  const handleAutoCompleteTopNAutoReplyTemplateForSubsequentEmailClick =
    async () => {
      let newTopNOptions: Array<TPKeyValue> = [];
      if (
        autocompleteAutoReplyTemplateForSubsequentEmailTopNOptions.length === 0
      ) {
        newTopNOptions =
          await handleAutoReplyTemplateForSubsequentEmailOnAutocompleteQuery(
            "",
          );

        if (newTopNOptions.length >= 1) {
          //save on cache
          setAutocompleteAutoReplyTemplateForSubsequentEmailTopNOptions([
            ...newTopNOptions,
          ]);
          setAutocompleteAutoReplyTemplateForSubsequentEmailOptions([
            ...newTopNOptions,
          ]);
          handleAutoReplyTemplateForSubsequentEmailChange([]);
        }
      } else {
        //use cached values;
        setAutocompleteAutoReplyTemplateForSubsequentEmailTopNOptions([
          ...autocompleteAutoReplyTemplateForSubsequentEmailTopNOptions,
        ]);
        handleAutoReplyTemplateForSubsequentEmailChange([]);
      }
    };

  const handleAutoReplyTemplateForSubsequentEmailChange = (
    newSelectedValue: Array<TPKeyValue>,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.AutoReplyTemplateForSubsequentEmailErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
    setSelectedAutoReplyTemplateForSubsequentEmail(newSelectedValue);
  };

  //#endregion "Auto Reply Template For Subsequent Email Autocomplete Functions"

  //#region "Autoresponse Email Template Autocomplete Functions"

  const handleAutoresponseEmailTemplateOnAutocompleteQuery = async (
    query: string,
  ) => {
    let newKeyValueList: Array<TPKeyValue>;
    newKeyValueList = SearchQueryAutocomplete(
      query,
      autoresponseEmailTemplateToSelect,
    );

    setAutocompleteAutoresponseEmailTemplateOptions(newKeyValueList);
    return newKeyValueList;
  };

  const handleAutoresponseEmailTemplateOnAutocompleteText = (text: string) => {
    if (!text) {
      setAutocompleteAutoresponseEmailTemplateOptions(
        autoresponseEmailTemplateToSelect,
      );
      handleAutoresponseEmailTemplateChange([]);
    }
  };

  const handleAutoresponseEmailTemplateOnAutocompleteKeyDown = (event: any) => {
    // if (IsValidOnKeyDown(event)) {
    //   setAutocompleteAutoresponseEmailTemplateOptions([]);
    //   handleAutoresponseEmailTemplateChange([]);
    // }
  };

  const handleAutoCompleteTopNAutoresponseEmailTemplateClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteAutoresponseEmailTemplateTopNOptions.length === 0) {
      newTopNOptions =
        await handleAutoresponseEmailTemplateOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteAutoresponseEmailTemplateTopNOptions([
          ...newTopNOptions,
        ]);
        setAutocompleteAutoresponseEmailTemplateOptions([...newTopNOptions]);
        handleAutoresponseEmailTemplateChange([]);
      }
    } else {
      //use cached values;
      setAutocompleteAutoresponseEmailTemplateTopNOptions([
        ...autocompleteAutoresponseEmailTemplateTopNOptions,
      ]);
      handleAutoresponseEmailTemplateChange([]);
    }
  };

  const handleAutoresponseEmailTemplateChange = (
    newSelectedValue: Array<TPKeyValue>,
  ) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.AutoresponseEmailTemplateErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
    setSelectedAutoresponseEmailTemplate(newSelectedValue);
  };

  //#endregion "Autoresponse Email Template Autocomplete Functions"

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    let newInsertUpdateState = { ...insertUpdateState };

    // Check for email fields and validate
    if (propertyName === "emailNotifyErrors") {
      if (!validateEmail(newValue)) {
        newInsertUpdateState[propertyName + "ErrorMessage"] = isInvalidEmail;
      } else {
        newInsertUpdateState[propertyName + "ErrorMessage"] = "";
      }
    }

    newInsertUpdateState[propertyName] = newValue;
    newInsertUpdateState[propertyName + "ErrorMessage"] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleSearchCustomerOnFound = (
    propertyName: string,
    selectedCustomer: any,
  ) => {
    let textValue: string;
    let idValue: number | null;

    if (selectedCustomer === null || selectedCustomer === undefined) {
      idValue = null;
      textValue = "";
    } else {
      idValue = selectedCustomer.subsidiaryOrganizationId;
      textValue = selectedCustomer.name + " " + selectedCustomer.lastName;
    }

    handleSearchCustomerChange(propertyName, textValue, idValue);
  };

  const handleSearchCustomerChange = (
    propertyName: string,
    newValue: any,
    newId: any,
  ) => {
    setInsertUpdateState({
      ...insertUpdateState,
      [propertyName]: newId,
      [propertyName + "ValueChange"]: newValue,
      [propertyName + "ErrorMessage"]: "",
    });
  };

  const handleCheckBoxChange = (propertyName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState[propertyName] = !newInsertUpdateState[propertyName];
    if (
      propertyName === "assigToLoggedUser" &&
      !newInsertUpdateState[propertyName]
    ) {
      newInsertUpdateState.rotatingUmbral = 0;
    }
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const handleOkButtonClick = async () => {
    let i: number;
    let n: number;

    let recordInputDTO: InboundMailboxesInputDTO = {
      // Id: insertUpdateState.id,
      Id: realMode === "Insert" ? referenceId : recordId,
      Description: insertUpdateState?.recordLanguageList[0]?.value,
      AuthenticationType: insertUpdateState.mailboxType,
      EmailAccount: insertUpdateState.userEmailAccount,
      PasswordEmailAccount: insertUpdateState.passwordEmailAccount,
      AuthenticationAzureAppId: insertUpdateState.authenticationAzureAppId,
      ServerEmailAccount: insertUpdateState.server,
      ProtocolTypeEmailAccount: insertUpdateState.connectionProtocol,
      SSLType: insertUpdateState.securityType,
      Port: insertUpdateState.port,
      SSLProtocol: insertUpdateState.sslProtocol,
      ImapFolder: insertUpdateState.folderToRead,
      TemplateProcessErrorId: GetAutoCompleteSelected(
        selectedEmailTemplateNotifyError,
      ),
      EmailAddressProcessError: insertUpdateState.emailNotifyErrors,
      LanguageId: insertUpdateState.language,
      ResponsibleGroupId: GetAutoCompleteSelected(selectedResponsibleGroup),
      IsActive: insertUpdateState.isActive,
      TemplateAutoResponderSubsequentEmailId: GetAutoCompleteSelected(
        selectedAutoReplyTemplateForSubsequentEmail,
      ),
      DefaultClassifierId: insertUpdateState.defaultClassifier,
      TemplateAutoRespondeId: GetAutoCompleteSelected(
        selectedAutoresponseEmailTemplate,
      ),
      ReopenCaseOnAdd: insertUpdateState.reopenCaseWhenPatternExists,
      EnableEditingCaseComments: insertUpdateState.enableEditCaseComments,
      OnExistingCustomerAction: insertUpdateState.whenEmailExistsMoreThanOnce,
      OnExistingCustomerFixedSubsidiaryOrganizationId:
        insertUpdateState.useThisCustomerMoreThanOnce ?? 0,
      OnNewCustomerAction: insertUpdateState.whenEmailIsNew,
      OnNewCustomerFixedSubsidiaryOrganizationId:
        insertUpdateState.useThisCustomerIsNew ?? 0,
      CustomerTypeId: insertUpdateState.customerTypeNewCustomer,
      DescriptionLocalizedValues: [],
    };

    switch (recordInputDTO.AuthenticationType) {
      case MailboxType.BASIC:
        // case MailboxType.OTHER:
        recordInputDTO.AuthenticationAzureAppId = null;
        break;

      case MailboxType.EXCHANGEONLINE:
        recordInputDTO.PasswordEmailAccount = null;
    }

    if (
      recordInputDTO.ProtocolTypeEmailAccount === "" ||
      insertUpdateState.connectionProtocol === ConnectionProtocol.POP3
    ) {
      recordInputDTO.ImapFolder = null;
    }

    if (
      recordInputDTO.ProtocolTypeEmailAccount === "" &&
      recordInputDTO.SSLType?.toString() === ""
    ) {
      recordInputDTO.SSLType = Number(SecurityType.None);
    }

    n = insertUpdateState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = insertUpdateState.recordLanguageList[i];
      recordInputDTO.DescriptionLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }

    let newInsertUpdateState = { ...insertUpdateState };
    let inputDTOGroupValidator = new InboundMailboxesInputDTOValidator();
    let hasError: boolean = false;
    let resultValidator = inputDTOGroupValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      if (resultValidator.Id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.Id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }

      if (resultValidator.Description) {
        newInsertUpdateState.descriptionErrorMessages[0] =
          await TPI18N.GetResource(resultValidator.Description);
      } else {
        newInsertUpdateState.descriptionErrorMessages[0] = "";
      }

      if (resultValidator.DescriptionLocalizedValues) {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.DescriptionLocalizedValues[i] != null) {
            newInsertUpdateState.descriptionErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.DescriptionLocalizedValues[i]),
              );
          }
        }
      } else {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.descriptionErrorMessages[i] = "";
        }
      }

      if (resultValidator.AuthenticationType) {
        newInsertUpdateState.mailboxTypeErrorMessage = await TPI18N.GetResource(
          resultValidator.AuthenticationType,
        );
      } else {
        newInsertUpdateState.mailboxTypeErrorMessage = "";
      }

      if (resultValidator.EmailAccount) {
        newInsertUpdateState.userEmailAccountErrorMessage =
          await TPI18N.GetResource(resultValidator.EmailAccount);
      } else {
        newInsertUpdateState.userEmailAccountErrorMessage = "";
      }

      if (resultValidator.PasswordEmailAccount) {
        newInsertUpdateState.passwordEmailAccountErrorMessage =
          await TPI18N.GetResource(resultValidator.PasswordEmailAccount);
      } else {
        newInsertUpdateState.passwordEmailAccountErrorMessage = "";
      }

      if (resultValidator.AuthenticationAzureAppId) {
        newInsertUpdateState.authenticationAzureAppIdErrorMessage =
          await TPI18N.GetResource(resultValidator.AuthenticationAzureAppId);
      } else {
        newInsertUpdateState.authenticationAzureAppIdErrorMessage = "";
      }

      if (resultValidator.ServerEmailAccount) {
        newInsertUpdateState.serverErrorMessage = await TPI18N.GetResource(
          resultValidator.ServerEmailAccount,
        );
      } else {
        newInsertUpdateState.serverErrorMessage = "";
      }

      if (resultValidator.ProtocolTypeEmailAccount) {
        newInsertUpdateState.connectionProtocolErrorMessage =
          await TPI18N.GetResource(resultValidator.ProtocolTypeEmailAccount);
      } else {
        newInsertUpdateState.connectionProtocolErrorMessage = "";
      }

      if (resultValidator.SSLType) {
        newInsertUpdateState.securityTypeErrorMessage =
          await TPI18N.GetResource(resultValidator.SSLType);
      } else {
        newInsertUpdateState.securityTypeErrorMessage = "";
      }

      if (resultValidator.Port) {
        newInsertUpdateState.portErrorMessage = await TPI18N.GetResource(
          resultValidator.Port,
        );
      } else {
        newInsertUpdateState.portErrorMessage = "";
      }

      if (resultValidator.SSLProtocol) {
        newInsertUpdateState.sslProtocolErrorMessage = await TPI18N.GetResource(
          resultValidator.SSLProtocol,
        );
      } else {
        newInsertUpdateState.sslProtocolErrorMessage = "";
      }

      if (resultValidator.ImapFolder) {
        newInsertUpdateState.folderToReadErrorMessage =
          await TPI18N.GetResource(resultValidator.ImapFolder);
      } else {
        newInsertUpdateState.folderToReadErrorMessage = "";
      }

      //emailTemplateNotifyErrorErrorMessage
      if (resultValidator.TemplateProcessErrorId) {
        newInsertUpdateState.emailTemplateNotifyErrorErrorMessage =
          await TPI18N.GetResource(resultValidator.TemplateProcessErrorId);
      } else {
        newInsertUpdateState.emailTemplateNotifyErrorErrorMessage = "";
      }

      if (resultValidator.EmailAddressProcessError) {
        newInsertUpdateState.emailNotifyErrorsErrorMessage =
          await TPI18N.GetResource(resultValidator.EmailAddressProcessError);
      } else {
        newInsertUpdateState.emailNotifyErrorsErrorMessage = "";
      }

      if (resultValidator.LanguageId) {
        newInsertUpdateState.languageErrorMessage = await TPI18N.GetResource(
          resultValidator.LanguageId,
        );
      } else {
        newInsertUpdateState.languageErrorMessage = "";
      }

      if (resultValidator.ResponsibleGroupId) {
        newInsertUpdateState.responsableGroupInProcessErrorMessage =
          await TPI18N.GetResource(resultValidator.ResponsibleGroupId);
      } else {
        newInsertUpdateState.responsableGroupInProcessErrorMessage = "";
      }

      if (resultValidator.IsActive) {
        newInsertUpdateState.isActiveErrorMessage = await TPI18N.GetResource(
          resultValidator.IsActive,
        );
      } else {
        newInsertUpdateState.isActiveErrorMessage = "";
      }

      if (resultValidator.TemplateAutoResponderSubsequentEmailId) {
        newInsertUpdateState.autoReplyTemplateForSubsequentEmailErrorMessage =
          await TPI18N.GetResource(
            resultValidator.TemplateAutoResponderSubsequentEmailId,
          );
      } else {
        newInsertUpdateState.autoReplyTemplateForSubsequentEmailErrorMessage =
          "";
      }

      if (resultValidator.DefaultClassifierId) {
        newInsertUpdateState.defaultClassifierErrorMessage =
          await TPI18N.GetResource(resultValidator.DefaultClassifierId);
      } else {
        newInsertUpdateState.defaultClassifierErrorMessage = "";
      }

      if (resultValidator.TemplateAutoRespondeId) {
        newInsertUpdateState.autoresponseEmailTemplateErrorMessage =
          await TPI18N.GetResource(resultValidator.TemplateAutoRespondeId);
      } else {
        newInsertUpdateState.autoresponseEmailTemplateErrorMessage = "";
      }

      if (resultValidator.ReopenCaseOnAdd) {
        newInsertUpdateState.reopenCaseWhenPatternExistsErrorMessage =
          await TPI18N.GetResource(resultValidator.ReopenCaseOnAdd);
      } else {
        newInsertUpdateState.reopenCaseWhenPatternExistsErrorMessage = "";
      }

      if (resultValidator.EnableEditingCaseComments) {
        newInsertUpdateState.enableEditCaseCommentsErrorMessage =
          await TPI18N.GetResource(resultValidator.EnableEditingCaseComments);
      } else {
        newInsertUpdateState.enableEditCaseCommentsErrorMessage = "";
      }

      if (resultValidator.OnExistingCustomerAction) {
        newInsertUpdateState.whenEmailExistsMoreThanOnceErrorMessage =
          await TPI18N.GetResource(resultValidator.OnExistingCustomerAction);
      } else {
        newInsertUpdateState.whenEmailExistsMoreThanOnceErrorMessage = "";
      }

      if (resultValidator.OnExistingCustomerFixedSubsidiaryOrganizationId) {
        newInsertUpdateState.useThisCustomerMoreThanOnceErrorMessage =
          await TPI18N.GetResource(
            resultValidator.OnExistingCustomerFixedSubsidiaryOrganizationId,
          );
      } else {
        newInsertUpdateState.useThisCustomerMoreThanOnceErrorMessage = "";
      }

      if (resultValidator.OnNewCustomerAction) {
        newInsertUpdateState.whenEmailIsNewErrorMessage =
          await TPI18N.GetResource(resultValidator.OnNewCustomerAction);
      } else {
        newInsertUpdateState.whenEmailIsNewErrorMessage = "";
      }

      if (resultValidator.OnNewCustomerFixedSubsidiaryOrganizationId) {
        newInsertUpdateState.useThisCustomerIsNewErrorMessage =
          await TPI18N.GetResource(
            resultValidator.OnNewCustomerFixedSubsidiaryOrganizationId,
          );
      } else {
        newInsertUpdateState.useThisCustomerIsNewErrorMessage = "";
      }

      if (resultValidator.CustomerTypeId) {
        newInsertUpdateState.customerTypeNewCustomerErrorMessage =
          await TPI18N.GetResource(resultValidator.CustomerTypeId);
      } else {
        newInsertUpdateState.customerTypeNewCustomerErrorMessage = "";
      }
      setInsertUpdateState(newInsertUpdateState);
      hasError = true;
    }

    if (!hasError) {
      if (realMode === "Insert") {
        await insertInboundMailbox(recordInputDTO);
      } else {
        await updateInboundMailbox(recordInputDTO);
      }
    }
    
  };

  const insertInboundMailbox = async (inputDTO: InboundMailboxesInputDTO) => {
    let serviceClient = new InboundMailboxesService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertInboundMailboxes(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      if (responseRequest.responseResult) {
        callBackResult({
          result: "save_general_insert_and_change_mode",
          recordId: responseRequest.responseData.keyList[0].value,
          // recordDescription: inputDTO.Description,
        });
        //change mode
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleUpdateLabel"));
        setRealRecordId(responseRequest.responseData.keyList[0].value);
        setOriginalRecordDescription(
          inputDTO.Description.length > 100
            ? inputDTO.Description.substring(0, 100) + "..."
            : inputDTO.Description,
        );
        onDescriptionFetched(inputDTO.Description);
        setRealMode("Update");
      }

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertInboundMailbox ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertInboundMailbox ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateInboundMailbox = async (inputDTO: InboundMailboxesInputDTO) => {
    let serviceClient = new InboundMailboxesService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateInboundMailboxes(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({
          result: "save_general_update",
          recordId: inputDTO.Id,
        });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateInboundMailbox ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updateInboundMailbox ex`);
      setIsLoadingScreen(false);
    }
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequeceGeneratorSequencesNameEnum.SQINMB,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadInfo();
    realMode === "Insert" && generalAutomaticId();
  }, []);

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-6">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            {realMode === "Update" ? (
              <TPPageSubTitle>
                {`${subTitleLabel} ${recordId} / ${originalRecordDescription}`}
              </TPPageSubTitle>
            ) : null}
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={idLabel}
                      isMandatory={true}
                      // value={insertUpdateState.id}
                      value={realMode === "Insert" ? referenceId : recordId}
                      onChange={
                        realMode === "Insert"
                          ? (e: any) =>
                              handleInputTextChange("id", e.target.value)
                          : () => TPGlobal.foo()
                      }
                      maxLength={20}
                      // disabled={realMode !== "Insert"}
                      disabled
                      errorMessage={insertUpdateState.idErrorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {insertUpdateState.recordLanguageList.length > 0 &&
                  TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                      index === 0 && (
                        <div className="col-6" key={`languageItem-${item.id}`}>
                          <div className="form-group">
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={index === 0}
                              labelText={`${descriptionLabel} (${item.name})`}
                              value={
                                insertUpdateState.recordLanguageList[index]
                                  .value
                              }
                              onChange={(e: any) =>
                                handleLanguageChange(index, e.target.value)
                              }
                              maxLength={200}
                              errorMessage={
                                insertUpdateState.descriptionErrorMessages[
                                  index
                                ]
                              }
                            />
                          </div>
                        </div>
                      ),
                  )}
                {insertUpdateState.recordLanguageList.length > 1 && (
                  <>
                    <div className="col-4">
                      <div className="pt-4">
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          icon={TPIconTypes.language}
                          text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                          tooltip={languageListLabel}
                          className={"pt-3"}
                          onClick={handleOpenModalLanguageList}
                        />
                      </div>
                    </div>
                    <TPModalLanguageList
                      isOpen={isOpenModalLanguageList}
                      title={languageListLabel}
                      acceptLabel={saveButtonLabel}
                      cancelLabel={cancelButtonLabel}
                      saveChanges={handleSaveChangesModalLanguageList}
                      closeModal={handleCloseModalLanguageList}
                    >
                      <div
                        className="row overflow-auto"
                        style={{ height: "200px" }}
                      >
                        {TPGlobal.TPClientAvailableLanguages.map(
                          (item, index) =>
                            index > 0 && (
                              <div
                                className="col-12"
                                key={`languageItem-${item.id}`}
                              >
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={false}
                                    labelText={`${descriptionLabel} (${item.name})`}
                                    value={
                                      insertUpdateState.recordLanguageList[
                                        index
                                      ].value
                                    }
                                    onChange={(e: any) =>
                                      handleLanguageChange(
                                        index,
                                        e.target.value,
                                      )
                                    }
                                    maxLength={200}
                                    errorMessage={
                                      insertUpdateState
                                        .descriptionErrorMessages[index]
                                    }
                                  />
                                </div>
                              </div>
                            ),
                        )}
                      </div>
                    </TPModalLanguageList>
                  </>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange("mailboxType", e.target.value);
                      }}
                      dataSource={mailboxTypesToSelect}
                      value={insertUpdateState.mailboxType}
                      labelText={mailboxTypeLabel}
                      isMandatory={true}
                      errorMessage={insertUpdateState.mailboxTypeErrorMessage}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={userEmailAccountLabel}
                      isMandatory={true}
                      value={insertUpdateState.userEmailAccount}
                      onChange={(e: any) =>
                        handleInputTextChange(
                          "userEmailAccount",
                          e.target.value,
                        )
                      }
                      maxLength={200}
                      errorMessage={
                        insertUpdateState.userEmailAccountErrorMessage
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    {(() => {
                      switch (insertUpdateState.mailboxType.toString()) {
                        case MailboxType.BASIC:
                          // case MailboxType.OTHER:
                          return (
                            <TPTextBox
                              id="IdTextBox"
                              labelText={passwordEmailAccountLabel}
                              value={insertUpdateState.passwordEmailAccount}
                              onChange={(e: any) =>
                                handleInputTextChange(
                                  "passwordEmailAccount",
                                  e.target.value,
                                )
                              }
                              maxLength={500}
                              type={"password"}
                              withIcon={true}
                              icon={TPIconTypes.lock}
                              errorMessage={
                                insertUpdateState.passwordEmailAccountErrorMessage
                              }
                            />
                          );
                        case MailboxType.EXCHANGEONLINE:
                          return (
                            <TPSelect
                              id="IdSelect"
                              onChange={(e: any) => {
                                handleInputTextChange(
                                  "authenticationAzureAppId",
                                  e.target.value,
                                );
                              }}
                              dataSource={authenticationsAzureAppIdToSelect}
                              value={insertUpdateState.authenticationAzureAppId}
                              labelText={authenticationAzureAppIdLabel}
                              errorMessage={
                                insertUpdateState.authenticationAzureAppIdErrorMessage
                              }
                            />
                          );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={serverLabel}
                      isMandatory={true}
                      value={insertUpdateState.server}
                      onChange={(e: any) =>
                        handleInputTextChange("server", e.target.value)
                      }
                      maxLength={50}
                      errorMessage={insertUpdateState.serverErrorMessage}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPNumeric
                      id="IdTPNumeric"
                      labelText={portLabel}
                      isMandatory={true}
                      value={insertUpdateState.port}
                      onChange={(e: any) => {
                        handleInputTextChange("port", e);
                      }}
                      maxLength={10}
                      decimalSeparator={""}
                      fixedDecimal={0}
                      errorMessage={insertUpdateState.portErrorMessage}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange(
                          "connectionProtocol",
                          e.target.value,
                        );
                      }}
                      dataSource={connectionProtocolsToSelect}
                      value={insertUpdateState.connectionProtocol}
                      labelText={connectionProtocolLabel}
                      isMandatory={true}
                      errorMessage={
                        insertUpdateState.connectionProtocolErrorMessage
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    {insertUpdateState.connectionProtocol !== "" &&
                      insertUpdateState.connectionProtocol !==
                        ConnectionProtocol.POP3 && (
                        <TPSelect
                          id="IdSelect"
                          onChange={(e: any) => {
                            handleInputTextChange(
                              "folderToRead",
                              e.target.value,
                            );
                          }}
                          dataSource={folderToReadsToSelect}
                          value={insertUpdateState.folderToRead}
                          labelText={folderToReadLabel}
                          errorMessage={
                            insertUpdateState.folderToReadErrorMessage
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange("securityType", e.target.value);
                      }}
                      dataSource={securityTypesToSelect}
                      value={insertUpdateState.securityType}
                      labelText={securityTypeLabel}
                      isMandatory={true}
                      errorMessage={insertUpdateState.securityTypeErrorMessage}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    {insertUpdateState.connectionProtocol !== "" &&
                      insertUpdateState.securityType?.toString() !== "" &&
                      insertUpdateState.securityType.toString() !==
                        SecurityType.None && (
                        <TPSelect
                          id="IdSelect"
                          onChange={(e: any) => {
                            handleInputTextChange(
                              "sslProtocol",
                              e.target.value,
                            );
                          }}
                          dataSource={sslProtocolsToSelect}
                          value={insertUpdateState.sslProtocol}
                          labelText={sslProtocolLabel}
                          errorMessage={
                            insertUpdateState.sslProtocolErrorMessage
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPAutoComplete
                      isMandatory={true}
                      labelText={emailTemplateNotifyErrorLabel}
                      onValueChange={handleEmailTemplateNotifyErrorChange}
                      onSearch={(query: string) => {
                        handleEmailTemplateNotifyErrorOnAutocompleteQuery(
                          query,
                        );
                      }}
                      onText={(text: string) => {
                        handleEmailTemplateNotifyErrorOnAutocompleteText(text);
                      }}
                      isLoading={false}
                      options={autocompleteEmailTemplateNotifyErrorOptions}
                      withIcon={true}
                      emptyLabel={emptyLabel}
                      onKeyDown={
                        handleEmailTemplateNotifyErrorOnAutocompleteKeyDown
                      }
                      selected={selectedEmailTemplateNotifyError}
                      errorMessage={
                        insertUpdateState.emailTemplateNotifyErrorErrorMessage
                      }
                      downArrowClick={
                        handleAutoCompleteTopNEmailTemplateNotifyErrorClick
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={emailNotifyErrorsLabel}
                      isMandatory={true}
                      value={insertUpdateState.emailNotifyErrors}
                      onChange={(e: any) =>
                        handleInputTextChange(
                          "emailNotifyErrors",
                          e.target.value,
                        )
                      }
                      maxLength={200}
                      withIcon={true}
                      icon={TPIconTypes.help}
                      tooltip={emailNotifyTooltipMessageLabel}
                      errorMessage={
                        insertUpdateState.emailNotifyErrorsErrorMessage
                      }
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange("language", e.target.value);
                      }}
                      dataSource={languagesToSelect}
                      value={insertUpdateState.language}
                      labelText={languageLabel}
                      isMandatory={true}
                      errorMessage={insertUpdateState.languageErrorMessage}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPAutoComplete
                      labelText={responsibleGroupInProcessLabel}
                      onValueChange={handleResponsibleGroupChange}
                      onSearch={(query: string) => {
                        handleResponsibleGroupOnAutocompleteQuery(query);
                      }}
                      onText={(text: string) => {
                        handleResponsibleGroupOnAutocompleteText(text);
                      }}
                      isLoading={false}
                      options={autocompleteResponsibleGroupOptions}
                      withIcon={true}
                      icon={TPIconTypes.teams}
                      emptyLabel={emptyLabel}
                      onKeyDown={handleResponsibleGroupOnAutocompleteKeyDown}
                      selected={selectedResponsibleGroup}
                      errorMessage={
                        insertUpdateState.responsableGroupInProcessErrorMessage
                      }
                      downArrowClick={
                        handleAutoCompleteTopNResponsibleGroupClick
                      }
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-4 mt-3">
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      checked={insertUpdateState.isActive}
                      labelText={isActiveLabel}
                      onChange={(e: any) => handleCheckBoxChange("isActive")}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>
              {caseOrCustomerInfoSectionLabel}
            </TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <TPAutoComplete
                      labelText={autoReplyTemplateForSubsequentEmailLabel}
                      onValueChange={
                        handleAutoReplyTemplateForSubsequentEmailChange
                      }
                      onSearch={(query: string) => {
                        handleAutoReplyTemplateForSubsequentEmailOnAutocompleteQuery(
                          query,
                        );
                      }}
                      onText={(text: string) => {
                        handleAutoReplyTemplateForSubsequentEmailOnAutocompleteText(
                          text,
                        );
                      }}
                      isLoading={false}
                      options={
                        autocompleteAutoReplyTemplateForSubsequentEmailOptions
                      }
                      withIcon={true}
                      emptyLabel={emptyLabel}
                      onKeyDown={
                        handleAutoReplyTemplateForSubsequentEmailOnAutocompleteKeyDown
                      }
                      selected={selectedAutoReplyTemplateForSubsequentEmail}
                      errorMessage={
                        insertUpdateState.autoReplyTemplateForSubsequentEmailErrorMessage
                      }
                      downArrowClick={
                        handleAutoCompleteTopNAutoReplyTemplateForSubsequentEmailClick
                      }
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <TPAutoComplete
                      labelText={autoresponseEmailTemplateLabel}
                      onValueChange={handleAutoresponseEmailTemplateChange}
                      onSearch={(query: string) => {
                        handleAutoresponseEmailTemplateOnAutocompleteQuery(
                          query,
                        );
                      }}
                      onText={(text: string) => {
                        handleAutoresponseEmailTemplateOnAutocompleteText(text);
                      }}
                      isLoading={false}
                      options={autocompleteAutoresponseEmailTemplateOptions}
                      withIcon={true}
                      emptyLabel={emptyLabel}
                      onKeyDown={
                        handleAutoresponseEmailTemplateOnAutocompleteKeyDown
                      }
                      selected={selectedAutoresponseEmailTemplate}
                      errorMessage={
                        insertUpdateState.autoresponseEmailTemplateErrorMessage
                      }
                      downArrowClick={
                        handleAutoCompleteTopNAutoresponseEmailTemplateClick
                      }
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <TPSelect
                      onChange={(e: any) => {
                        handleInputTextChange(
                          "defaultClassifier",
                          e.target.value,
                        );
                      }}
                      dataSource={quickClassifiersToSelect}
                      value={insertUpdateState.defaultClassifier}
                      labelText={defaultClassifierLabel}
                      isMandatory={true}
                      errorMessage={
                        insertUpdateState.defaultClassifierErrorMessage
                      }
                      withIcon={true}
                      icon={TPIconTypes.accountTree}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange(
                          "whenEmailExistsMoreThanOnce",
                          e.target.value,
                        );
                      }}
                      dataSource={onExistingCustomersToSelect}
                      value={insertUpdateState.whenEmailExistsMoreThanOnce}
                      labelText={whenEmailExistsMoreThanOnceLabel}
                      isMandatory={true}
                      errorMessage={
                        insertUpdateState.whenEmailExistsMoreThanOnceErrorMessage
                      }
                    />
                  </div>
                </div>
                {insertUpdateState.whenEmailExistsMoreThanOnce?.toString() ===
                  OnExistingCustomerInboundMailBox.UseFixedCustomer && (
                  <div className="col-6">
                    <div className="form-group">
                      <TPSearchCustomer
                        labelText={useThisCustomerMoreThanOnceLabel}
                        value={
                          insertUpdateState.useThisCustomerMoreThanOnceValueChange
                        }
                        maxLength={50}
                        onChange={(e: any) =>
                          handleSearchCustomerChange(
                            "useThisCustomerMoreThanOnce",
                            e.target.value,
                            null,
                          )
                        }
                        onCustomerFound={(obj: any) =>
                          handleSearchCustomerOnFound(
                            "useThisCustomerMoreThanOnce",
                            obj,
                          )
                        }
                        errorMessage={
                          insertUpdateState.useThisCustomerMoreThanOnceErrorMessage
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange("whenEmailIsNew", e.target.value);
                      }}
                      dataSource={onNewCustomersToSelect}
                      value={insertUpdateState.whenEmailIsNew}
                      labelText={whenEmailIsNewLabel}
                      isMandatory={true}
                      errorMessage={
                        insertUpdateState.whenEmailIsNewErrorMessage
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    {(() => {
                      switch (insertUpdateState.whenEmailIsNew.toString()) {
                        case OnNewCustomerInboundMailBox.UseFixedCustomer:
                          return (
                            <TPSearchCustomer
                              labelText={useThisCustomerIsNewLabel}
                              value={
                                insertUpdateState.useThisCustomerIsNewValueChange
                              }
                              maxLength={50}
                              onChange={(e: any) =>
                                handleSearchCustomerChange(
                                  "useThisCustomerIsNew",
                                  e.target.value,
                                  null,
                                )
                              }
                              onCustomerFound={(obj: any) =>
                                handleSearchCustomerOnFound(
                                  "useThisCustomerIsNew",
                                  obj,
                                )
                              }
                              errorMessage={
                                insertUpdateState.useThisCustomerIsNewErrorMessage
                              }
                            />
                          );

                        case OnNewCustomerInboundMailBox.CreateNewCustomer:
                          return (
                            <TPSelect
                              id="IdSelect"
                              onChange={(e: any) => {
                                handleInputTextChange(
                                  "customerTypeNewCustomer",
                                  e.target.value,
                                );
                              }}
                              dataSource={customerTypesToSelect}
                              value={insertUpdateState.customerTypeNewCustomer}
                              labelText={customerTypeNewCustomerLabel}
                              errorMessage={
                                insertUpdateState.customerTypeNewCustomerErrorMessage
                              }
                            />
                          );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6 mt-3">
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      checked={insertUpdateState.reopenCaseWhenPatternExists}
                      labelText={reopenCaseWhenPatternExistsLabel}
                      onChange={(e: any) =>
                        handleCheckBoxChange("reopenCaseWhenPatternExists")
                      }
                    />
                  </div>
                </div>
                <div className="col-6 mt-3">
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      checked={insertUpdateState.enableEditCaseComments}
                      labelText={enableEditCaseCommentsLabel}
                      onChange={(e: any) =>
                        handleCheckBoxChange("enableEditCaseComments")
                      }
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-10">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );
};

export default InboundMailboxesInsertUpdate;
