import TPGlobal from "@/helpers/TPGlobal";
import { Validator } from "fluentvalidation-ts";
import { RecordLocalizedModel } from "../Global/TPGlobalModels";
import {
  ConnectionProtocol,
  IMAPFolder,
  MailboxType,
  OnExistingCustomerInboundMailBox,
  OnNewCustomerInboundMailBox,
  SecurityType,
  SSLProtocol,
} from "@/models/Global/TPGlobalEnums";

export interface InboundMailboxesInputDTO {
  Id: string;
  Description: string;
  AuthenticationType: string;
  EmailAccount: string;
  PasswordEmailAccount: string | null;
  AuthenticationAzureAppId: string | null;
  ServerEmailAccount: string;
  ProtocolTypeEmailAccount: string;
  SSLType: number;
  Port: number;
  SSLProtocol: string;
  ImapFolder: string | null;
  TemplateProcessErrorId: string;
  EmailAddressProcessError: string;
  LanguageId: string;
  ResponsibleGroupId: string | null;
  IsActive: boolean;
  TemplateAutoResponderSubsequentEmailId: string;
  DefaultClassifierId: string;
  TemplateAutoRespondeId: string | null;
  ReopenCaseOnAdd: boolean;
  EnableEditingCaseComments: boolean;
  OnExistingCustomerAction: number;
  OnExistingCustomerFixedSubsidiaryOrganizationId: number;
  OnNewCustomerAction: number;
  OnNewCustomerFixedSubsidiaryOrganizationId: number;
  CustomerTypeId: string;
  DescriptionLocalizedValues: Array<RecordLocalizedModel>;
}

export class InboundMailboxesInputDTOValidator extends Validator<InboundMailboxesInputDTO> {
  constructor() {
    super();
    const resourceSet = "InboundMailboxesInsertUpdateComponent";
    try {
      this.ruleFor("Id")
        .notNull()
        .withMessage(resourceSet + "|InputDTOIdNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")

        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")

        .must((id) => {
          return TPGlobal.TPSanitize(id) === id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")

        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("Description")
        .notNull()
        .withMessage(resourceSet + "|InputDTODescriptionNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTODescriptionEmpty")

        .must((Description) => {
          return TPGlobal.TPSanitize(Description) === Description;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")

        .maxLength(200)
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");

      this.ruleForEach("DescriptionLocalizedValues")
        .must((DescriptionLocalizedValues) => {
          if (
            DescriptionLocalizedValues.localizedValue.trim() === "" ||
            DescriptionLocalizedValues.order === 1
          ) {
            return true;
          }
          return (
            TPGlobal.TPSanitize(DescriptionLocalizedValues.localizedValue) ===
            DescriptionLocalizedValues.localizedValue
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLocalizedValues")
        .must((DescriptionLocalizedValues) => {
          if (
            DescriptionLocalizedValues.localizedValue.trim() === "" ||
            DescriptionLocalizedValues.order === 1
          ) {
            return true;
          }
          return DescriptionLocalizedValues.localizedValue.length <= 200;
        })
        .withMessage(
          resourceSet + "|InputDTOInvalidDescriptionLocalizedValuesLength",
        );

      this.ruleFor("AuthenticationType")
        .notNull()
        .withMessage(resourceSet + "|InputDTOAuthenticationTypeNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOAuthenticationTypeEmpty")

        .must((AuthenticationType) => {
          return TPGlobal.TPSanitize(AuthenticationType) === AuthenticationType;
        })
        .withMessage(resourceSet + "|InputDTOInvalidAuthenticationType")

        .must((AuthenticationType) => {
          const values = Object.values(MailboxType);
          return values.includes(AuthenticationType as unknown as MailboxType);
        })
        .withMessage(resourceSet + "|InputDTOInvalidAuthenticationType")

        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidAuthenticationTypeLength");

      this.ruleFor("EmailAccount")
        .notNull()
        .withMessage(resourceSet + "|InputDTOEmailAccountNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOEmailAccountEmpty")

        .must((EmailAccount) => {
          return TPGlobal.TPSanitize(EmailAccount) === EmailAccount;
        })
        .withMessage(resourceSet + "|InputDTOInvalidEmailAccount")

        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidEmailAccountLength");

      this.ruleFor("PasswordEmailAccount")
        .must((PasswordEmailAccount) => {
          if (PasswordEmailAccount !== null) {
            return (
              TPGlobal.TPSanitize(PasswordEmailAccount) === PasswordEmailAccount
            );
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidPasswordEmailAccount")

        .maxLength(500)
        .withMessage(
          resourceSet + "|InputDTOInvalidPasswordEmailAccountLength",
        );

      this.ruleFor("AuthenticationAzureAppId")
        .must((AuthenticationAzureAppId) => {
          if (AuthenticationAzureAppId !== null) {
            return (
              TPGlobal.TPSanitize(AuthenticationAzureAppId) ===
              AuthenticationAzureAppId
            );
          }
          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidAuthenticationAzureAppId")

        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidAuthenticationAzureAppIdLength",
        );

      this.ruleFor("ServerEmailAccount")
        .notNull()
        .withMessage(resourceSet + "|InputDTOServerEmailAccountNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOServerEmailAccountEmpty")

        .must((ServerEmailAccount) => {
          return TPGlobal.TPSanitize(ServerEmailAccount) === ServerEmailAccount;
        })
        .withMessage(resourceSet + "|InputDTOInvalidServerEmailAccount")

        .maxLength(50)
        .withMessage(resourceSet + "|InputDTOInvalidServerEmailAccountLength");

      this.ruleFor("ProtocolTypeEmailAccount")
        .notNull()
        .withMessage(resourceSet + "|InputDTOProtocolTypeEmailAccountNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOProtocolTypeEmailAccountEmpty")

        .must((ProtocolTypeEmailAccount) => {
          return (
            TPGlobal.TPSanitize(ProtocolTypeEmailAccount) ===
            ProtocolTypeEmailAccount
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidProtocolTypeEmailAccount")

        .must((ProtocolTypeEmailAccount) => {
          const values = Object.values(ConnectionProtocol);
          return values.includes(
            ProtocolTypeEmailAccount as unknown as ConnectionProtocol,
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidValue")

        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidProtocolTypeEmailAccountLength",
        );

      this.ruleFor("SSLType")
        .notNull()
        .withMessage(resourceSet + "|InputDTOSSLTypeNull")

        .must((SSLType) => {
          const values = Object.values(SecurityType);
          return values.includes(SSLType.toString() as unknown as SecurityType);
        })
        .withMessage(resourceSet + "|InputDTOInvalidSSLType");

      this.ruleFor("Port")
        .notNull()
        .withMessage(resourceSet + "|InputDTOPortNull")

        .must((Port) => {
          return Port.toString() !== "";
        })
        .withMessage(resourceSet + "|InputDTOInvalidPort");

      this.ruleFor("SSLProtocol")
        .must((sslProtocol) => {
          if (sslProtocol !== null && sslProtocol !== "") {
            if (TPGlobal.TPSanitize(sslProtocol) !== sslProtocol) return false;

            const values = Object.values(SSLProtocol);
            return values.includes(sslProtocol as unknown as SSLProtocol);
          }

          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidSSLProtocol")

        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidSSLProtocolLength");

      this.ruleFor("ImapFolder")
        .must((ImapFolder) => {
          if (ImapFolder !== null && ImapFolder !== "") {
            if (TPGlobal.TPSanitize(ImapFolder) !== ImapFolder) return false;

            const values = Object.values(IMAPFolder);
            return values.includes(ImapFolder as unknown as IMAPFolder);
          }

          return true;
        })
        .withMessage(resourceSet + "|InputDTOInvalidImapFolder")

        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidImapFolderLength");

      this.ruleFor("TemplateProcessErrorId")
        .notNull()
        .withMessage(resourceSet + "|InputDTOTemplateProcessErrorIdNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOTemplateProcessErrorIdEmpty")

        .must((TemplateProcessErrorId) => {
          return (
            TPGlobal.TPSanitize(TemplateProcessErrorId) ===
            TemplateProcessErrorId
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidTemplateProcessErrorId")

        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidTemplateProcessErrorIdLength",
        );

      this.ruleFor("EmailAddressProcessError")
        .notNull()
        .withMessage(resourceSet + "|InputDTOEmailAddressProcessErrorNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOEmailAddressProcessErrorEmpty")

        .must((email) => TPGlobal.validateEmail(email))
        .withMessage(resourceSet + "|InputDTOInvalidEmailAddressProcessError")

        .maxLength(200)
        .withMessage(
          resourceSet + "|InputDTOInvalidEmailAddressProcessErrorLength",
        );

      this.ruleFor("LanguageId")
        .notNull()
        .withMessage(resourceSet + "|InputDTOLanguageIdNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTOLanguageIdEmpty")

        .must((LanguageId) => {
          return TPGlobal.TPSanitize(LanguageId) === LanguageId;
        })
        .withMessage(resourceSet + "|InputDTOInvalidLanguageId")

        .maxLength(10)
        .withMessage(resourceSet + "|InputDTOInvalidLanguageIdLength");

      this.ruleFor("TemplateAutoResponderSubsequentEmailId")
        .maxLength(20)
        .withMessage(
          resourceSet +
            "|InputDTOInvalidTemplateAutoResponderSubsequentEmailIdLength",
        );

      this.ruleFor("TemplateAutoRespondeId")
        .maxLength(20)
        .withMessage(
          resourceSet + "|InputDTOInvalidTemplateAutoRespondeIdLength",
        );

      this.ruleFor("DefaultClassifierId")
        .notNull()
        .withMessage(resourceSet + "|InputDTODefaultClassifierIdNull")

        .notEmpty()
        .withMessage(resourceSet + "|InputDTODefaultClassifierIdEmpty")

        .must((DefaultClassifierId) => {
          return (
            TPGlobal.TPSanitize(DefaultClassifierId) === DefaultClassifierId
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidDefaultClassifierId")

        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidDefaultClassifierIdLength");

      this.ruleFor("ReopenCaseOnAdd")
        .notNull()
        .withMessage(resourceSet + "|InputDTOReopenCaseOnAddNull");

      this.ruleFor("EnableEditingCaseComments")
        .notNull()
        .withMessage(resourceSet + "|InputDTOEnableEditingCaseCommentsNull");

      this.ruleFor("IsActive")
        .notNull()
        .withMessage(resourceSet + "|InputDTOIsActiveNull");

      this.ruleFor("OnExistingCustomerAction")
        .notNull()
        .withMessage(resourceSet + "|InputDTOOnExistingCustomerActionNull")

        .must((OnExistingCustomerAction) => {
          const values = Object.values(OnExistingCustomerInboundMailBox);
          return values.includes(
            OnExistingCustomerAction.toString() as unknown as OnExistingCustomerInboundMailBox,
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidOnExistingCustomerAction");

      this.ruleFor("OnNewCustomerAction")
        .notNull()
        .withMessage(resourceSet + "|InputDTOOnNewCustomerActionNull")

        .must((OnNewCustomerAction) => {
          const values = Object.values(OnNewCustomerInboundMailBox);
          return values.includes(
            OnNewCustomerAction.toString() as unknown as OnNewCustomerInboundMailBox,
          );
        })
        .withMessage(resourceSet + "|InputDTOInvalidOnNewCustomerAction");

      this.ruleFor("CustomerTypeId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidCustomerTypeIdLength");
    } catch (error) {}
  }
}
