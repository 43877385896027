import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { TPChip } from "@/components/TPChip/TPChip";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { GroupTakeNextGroupModel } from "@/models/GroupTakeNext/GroupTakeNextModel";
import { TPI18N } from "@/services/I18nService";
import { Icon } from "@iconify/react";
import { FC, useEffect, useState } from "react";

interface GroupTakeNextCardProperties {
  data: GroupTakeNextGroupModel;
  selected: boolean;
  onSelect: () => any;
}

const labelsResourceSet = "GroupTakeNext";

const GroupTakeNextCard: FC<GroupTakeNextCardProperties> = function ({
  data,
  selected,
  onSelect,
}) {
  const [readyHeaderLabel, setReadyHeaderLabel] = useState<string>("");
  const [processingHeaderLabel, setProcessingHeaderLabel] =
    useState<string>("");
  const [inQueueHeaderLabel, setInQueueHeaderLabel] = useState<string>("");
  const [availableLabel, setAvailableLabel] = useState<string>("");
  const [notAvailableLabel, setNotAvailableLabel] = useState<string>("");
  const [caseLabel, setCaseLabel] = useState<string>("");

  const loadLabels = async function () {
    setReadyHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "ReadyHeaderLabel"),
    );
    setProcessingHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "ProcessingHeaderLabel"),
    );
    setInQueueHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "InQueueHeaderLabel"),
    );
    setAvailableLabel(
      await TPI18N.GetText(labelsResourceSet, "AvailableLabel"),
    );
    setNotAvailableLabel(
      await TPI18N.GetText(labelsResourceSet, "NotAvailableLabel"),
    );
    setCaseLabel(await TPI18N.GetText(labelsResourceSet, "CaseLabel"));
  };

  useEffect(() => {
    loadLabels();
  });

  return (
    <>
      <div className="GTN-card">
        <div
          className="GTN-card-header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <TPCheckBox checked={selected} onChange={onSelect} />
            <b>{data.description}</b>
          </div>
          <TPChip
            backgroundColor="#FBEBFF"
            iconColor="#C30091"
            icon={TPIconTypes.circle}
            label={caseLabel}
          />
        </div>
        <div
          className="GTN-card-body"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="GTN-data-display">
            {readyHeaderLabel.toUpperCase()}
            <b>{data.ready}</b>
          </div>
          <div className="GTN-data-display">
            {processingHeaderLabel.toUpperCase()}
            <b>{data.processing}</b>
          </div>
          <div className="GTN-data-display">
            {inQueueHeaderLabel.toUpperCase()}
            <b>{data.inQueue}</b>
          </div>
        </div>
        <div className="GTN-card-footer">
          {data.ready > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#B1F2D7",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <Icon icon="gg:check-o" />
              </div>
              {availableLabel}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFD7DD",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <Icon icon="akar-icons:circle-alert" />
              </div>
              {notAvailableLabel}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GroupTakeNextCard;
