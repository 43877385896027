import React, {CSSProperties, forwardRef, useEffect, useImperativeHandle, useReducer, useRef} from "react";
import {TPPageTitle} from "@/components/TPPage/tpPageStyles";
import {TPI18N} from "@/services/I18nService";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {TPIconTypes} from "@/models/Global/TPGlobalEnums";
import ProjectsAdminTable, {ProjectsAdminTableEvents} from "@/pages/Projects/ProjectsAdmin/ProjectsAdminTable";
import {
    projectListInitialState,
    ProjectListState,
    ProjectsAdminEnum as e, ProjectListStateEvent,
    TableProjectModel
} from "@/models/Project/Projects";
import {commandsEnum as event, commandType} from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import {ButtonCustomType} from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPGlobal from "@/helpers/TPGlobal";
import {EventProjectService} from "@/services/EventProjectService";
import {Action, Event, Sa} from "@/models/Global/TPGlobalModels";

const styles = {
    column: {
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
    } as CSSProperties,
    sectionTitle: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        placeContent: "center space-between",
        alignItems: "center",
        marginBottom: "30px",
    } as CSSProperties,
    title: {
        textTransform: "none",
        margin: "0"
    } as CSSProperties,
}

export interface ProjectsAdminEvents {
    refreshGridFromParent: () => void;
}

interface ProjectsAdminProps {
    verticalTabDispatch: (action: commandType) => void;
}

const ProjectsAdmin = forwardRef(({verticalTabDispatch}: ProjectsAdminProps, ref) => {
    const [state, componentDispatch] = useReducer(componentCommand, projectListInitialState);
    const tableRef = useRef<ProjectsAdminTableEvents>();

    useImperativeHandle(ref, () => ({
        refreshGridFromParent() {
            loadData()
        },
    } as ProjectsAdminEvents));

    useEffect(() => {
        loadResources()
        loadData()
    }, []);

    function componentCommand(s: ProjectListState,
                              action: Action<any>) {
        switch (action.type) {
            case ProjectListStateEvent.getMessage:
                return (() => {
                    const messages = {...s.messages}
                    messages[action.payload] = Sa.loading<string>()

                    return {
                        ...s,
                        messages,
                    } as ProjectListState;
                })()
            case ProjectListStateEvent.getMessageSuccess:
                return (() => {
                    const messages = {...s.messages}
                    messages[action.payload.attribute].setValue(action.payload.message)

                    return {
                        ...s,
                        messages,
                    } as ProjectListState;
                })()
            case ProjectListStateEvent.getMessageError:
                return (() => {
                    const messages = {...s.messages}
                    messages[action.payload.attribute].setError(action.payload.error)

                    return {
                        ...s,
                        messages,
                    } as ProjectListState;
                })()
            case ProjectListStateEvent.find:
                return (() => {
                    const datasource = Sa.loading<Array<TableProjectModel>>([])

                    return {
                        ...s,
                        datasource,
                    } as ProjectListState;
                })()
            case ProjectListStateEvent.findSuccess:
                return (() => {
                    const datasource = s.datasource
                    datasource.setValue(action.payload)

                    return {
                        ...s,
                        datasource,
                    } as ProjectListState;
                })()
            case ProjectListStateEvent.findError:
                const datasource = s.datasource
                datasource.setError(action.payload)

                return {
                    ...s,
                    datasource,
                } as ProjectListState;
            default:
                return s;
        }
    }

    function loadResources() {
        const componentMessage = [
            e.TitleLabel,
            e.NewProjectButtonLabel,
            e.PreferencesInputLabel,
            e.ActionsTableColumnLabel,
            e.IdTableColumnLabel,
            e.NameTableColumnLabel,
            e.StructureTypeTableColumnLabel,
            e.QueuesTableColumnLabel,
            e.ActiveTableColumnLabel,
            e.ActiveColumnYesLabel,
            e.ActiveColumnNoLabel,
            e.QuestionModalLabel,
        ]
        const globalMessage = [
            e.SearchInputLabel,
            e.ExportButtonLabel,
            e.RefreshButtonLabel,
            e.DeleteActionLabel,
            e.UpdateActionLabel,
            e.CloneActionLabel,
            e.titleModalLabel,
            e.YesModalButtonLabel,
            e.NoModalButtonLabel,
        ]

        componentMessage.forEach(attribute => {
            componentDispatch({
                type: ProjectListStateEvent.getMessage,
                payload: attribute
            } as Action<ProjectListStateEvent>)
            TPI18N.GetText(e.ProjectsAdminComponent, attribute)
                .then(message => componentDispatch({
                    type: ProjectListStateEvent.getMessageSuccess,
                    payload: {attribute, message}
                } as Action<ProjectListStateEvent>))
                .catch(error => componentDispatch({
                    type: ProjectListStateEvent.getMessageError,
                    payload: {attribute, error}
                } as Action<ProjectListStateEvent>))
        })
        globalMessage.forEach(attribute => {
            componentDispatch({
                type: ProjectListStateEvent.getMessage,
                payload: attribute
            } as Action<ProjectListStateEvent>)
            TPI18N.GetText(TPGlobal.globalResourceSet, attribute)
                .then(message => componentDispatch({
                    type: ProjectListStateEvent.getMessageSuccess,
                    payload: {attribute, message}
                } as Action<ProjectListStateEvent>))
                .catch(error => componentDispatch({
                    type: ProjectListStateEvent.getMessageError,
                    payload: {attribute, error}
                } as Action<ProjectListStateEvent>))
        })
    }

    function loadData() {
        componentDispatch({type: ProjectListStateEvent.find} as Action<ProjectListStateEvent>)
        const {find} = new EventProjectService()
        find()
            .then(datasource => {
                const payload = datasource
                    .map(({id, name, descriptionStructure, conditionsOrQueues, isActive}) => ({
                        id,
                        name,
                        descriptionStructure,
                        queues: conditionsOrQueues.length,
                        isActive,
                    }))
                componentDispatch({
                    type: ProjectListStateEvent.findSuccess,
                    payload
                } as Action<ProjectListStateEvent>)
            })
            .catch(payload => componentDispatch({
                type: ProjectListStateEvent.findError,
                payload
            } as Action<ProjectListStateEvent>))
    }

    function onClickButtonNewHandler() {
        verticalTabDispatch({type: event.new_vertical_tab});
    }

    return (
        <TPLoadingOverlay
            active={!state.datasource.event || state.datasource.event === Event.loading}>
            <div style={styles.column}>
                <div style={styles.sectionTitle}>
                    <TPPageTitle
                        style={styles.title}
                    >
                        {`${state.messages?.[e.TitleLabel]?.value ?? ""}`}
                    </TPPageTitle>

                    <TPButton
                        isDesignSystem={true}
                        customType={ButtonCustomType.primary}
                        withIcon={TPIconTypes.add}
                        orientationIcon="left"
                        onClick={onClickButtonNewHandler}
                    >
                        {`${state.messages?.[e.NewProjectButtonLabel]?.value ?? ""}`}
                    </TPButton>
                </div>

                <ProjectsAdminTable
                    state={state}
                    verticalTabDispatch={verticalTabDispatch}
                    loadData={loadData}
                    ref={tableRef}
                />
            </div>
        </TPLoadingOverlay>
    );
})

export default ProjectsAdmin
