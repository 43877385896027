import styled from "styled-components";

const chipTypeColor = {
  success: "#B1F2D7",
  warning: "#FFE4B3",
  danger: "#FFD7DD",
};

export const StyledTableChip = styled.div<{
  justify: string;
  underline: boolean;
  notBoolean: boolean;
  value: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify};

  p {
    background-color: ${(props) =>
      chipTypeColor[
        props.value ? "success" : props.notBoolean ? "warning" : "danger"
      ]};
    font-size: 14px;
    width: fit-content;
    padding: 2px ${(props) => (props.underline ? "16px" : "10px")};
    border-radius: 20px;
    margin: 0;
    font-weight: 500;
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    cursor: ${(props) => (props.underline ? "pointer" : "initial")};

    ${(props) =>
      props.underline &&
      `
                user-select: none;
                
                &:hover {
                    opacity: 0.8;
                }
            `}
  }
`;
