import React, {CSSProperties, useEffect, useRef} from "react";
import {
    ProjectDetailEnum as e,
    ProjectType,
    TableProjectDetailModel
} from "@/models/Project/Projects";
import ProjectReassign from "@/pages/Projects/ProjectReassign/ProjectReassign";
import {TPKeyValue} from "@/helpers/TPKeyValue";
import {ColumnStyles, CustomColumnNames} from "@/modules/core/components/dynamic-table/DynamicTable";
import {TPIconTypes} from "@/models/Global/TPGlobalEnums";
import {Chip} from "@mui/material";
import ProjectDetailDynamicTable, {
    ProjectDetailDynamicTableEvents
} from "@/pages/Projects/ProjectDetail/ProjectDetailDynamicTable";

interface ProjectDetailTableProps {
    m: { [attribute: string]: any };
    queues: Array<TPKeyValue>;
    datasource: Array<TableProjectDetailModel>;
    loadData: Function;
    inputSearch: string;
    downloadIcon: boolean;
}

export default function ProjectDetailTable({m, queues, datasource, loadData, inputSearch, downloadIcon}: ProjectDetailTableProps) {

    const tableRef = useRef<ProjectDetailDynamicTableEvents>();

    const columns: CustomColumnNames<TableProjectDetailModel> = {
        queueId: "",
        queueName: `${m?.[e.TableColumnQueueNameLabel] ?? ""}`,
        totalCe: `${m?.[e.TableColumnTotalCELabel] ?? ""}`,
        ceReady: `${m?.[e.TableColumnCEReadyLabel] ?? ""}`,
        online: `${m?.[e.TableColumnOnlineLabel] ?? ""}`,
        working: `${m?.[e.TableColumnWorkingLabel] ?? ""}`,
        offline: `${m?.[e.TableColumnOfflineLabel] ?? ""}`,
        events: `${m?.[e.TableColumnEventsLabel] ?? ""}`,
        worked: `${m?.[e.TableColumnWorkedLabel] ?? ""}`,
        pending: `${m?.[e.TableColumnPendingLabel] ?? ""}`,
        type: `${m?.[e.TableColumnTypeLabel] ?? ""}`,
        reassign: "",
    };

    const columnStyles: ColumnStyles<TableProjectDetailModel> = {
        queueId: ({value, item}) => (<></>),
        ceReady: ({value, item}) => (
            <Chip label={item.ceReady}
                  style={styles.chip}
            />
        ),
        type: ({value, item}) => (
            <Chip label={capitalize(item.type)}
                  style={{backgroundColor: item.type === ProjectType.BACKOFFICE ? "#72f6dd5e" : "#e2d3f8"}}
            />
        ),
        reassign: ({value, item}) => (
            <ProjectReassign
                m={m}
                queues={queues}
                detail={item}
            />
        ),
    };

    const onClickIconHandler = (event: TPIconTypes) => {
        if (event === TPIconTypes.loop) loadData();
    };

    function capitalize(text: string) {
        return text
            .split(" ")
            .map(t => `${t.charAt(0).toUpperCase()}${t.slice(1).toLowerCase()}`)
            .join(" ")
    }

    useEffect(() => {
        tableRef.current?.inputSearch(inputSearch)
    }, [inputSearch]);

    useEffect(() => {
        tableRef.current?.downloadIcon()
    }, [downloadIcon]);

    return (
        <div style={styles.column}>
            <div style={styles.row}>
                <div style={{width: "190px"}}></div>

                <div style={styles.tableTitle}>
                    <p style={{width: "550px", margin: 0}}>{m?.[e.CustomerExpertsLabel]}</p>

                    <p style={{margin: 0}}>{m?.[e.TicketsLabel]}</p>
                </div>
            </div>

            <div style={{overflowY: "hidden", height: "max-content"}}>
                <div style={{marginTop: "-80px", height: "max-content"}}>
                    <ProjectDetailDynamicTable
                        ref={tableRef}
                        id="projectDetailTable"
                        data={datasource}
                        columnNames={columns}
                        columnStyles={columnStyles}
                        onIconClicked={event => onClickIconHandler(event)}
                    />
                </div>
            </div>
        </div>
    );
}

const styles = {
    column: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'column',
    } as CSSProperties,
    row: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
    } as CSSProperties,
    tableTitle: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        borderBottom: "1px solid #b3afaf",
        flex: "1 1 0%",
    } as CSSProperties,
    chip: {
        backgroundColor: "#72f6dd5e" // #ffd3d9
    } as CSSProperties,
};
