import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { FunctionAssignmentsViewModel } from "@/models/Functions/FunctionAssignmentsModels";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import { FunctionAssignmentsService } from "@/services/FunctionAssignmentsService";
import { TPI18N } from "@/services/I18nService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { RelationsService } from "@/services/RelationsService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

type AdminStateType = {
  organizationsRelationsAreLoaded: boolean;
  currentBaseLevel: string;
  gridColumns: Array<any>;
  gridData: Array<FunctionAssignmentsViewModel>;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface FunctionAssignmentsAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_OrganizationsRelationsAreLoaded" = 0,
  "setup_grid_columns" = 1,
  "change_CurrentBaseLevel" = 2,
  "reload_grid" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const FunctionAssignmentsAdmin = React.forwardRef(
  ({ callBackCommands }: FunctionAssignmentsAdminInterface, ref) => {
    const componentFileName: string = "FunctionAssignmentsAdmin.tsx";

    //Function called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "FunctionsAssignmentAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
      useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");

    //organizations/relations filter
    const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
      useState<Array<TPKeyValue>>([]);

    //grid columns
    const [functionColumnLabel, setFunctionColumnLabel] = useState("");
    const [responsibleColumnLabel, setResponsibleColumnLabel] = useState("");
    useState("");
    const [
      classificatorBranchColumnLabel1,
      setClassificatorBranchColumnLabel1,
    ] = useState("");
    const [
      classificatorBranchColumnLabel2,
      setClassificatorBranchColumnLabel2,
    ] = useState("");
    const [
      classificatorBranchColumnLabel3,
      setClassificatorBranchColumnLabel3,
    ] = useState("");
    const [
      classificatorBranchColumnLabel4,
      setClassificatorBranchColumnLabel4,
    ] = useState("");
    const [
      classificatorBranchColumnLabel5,
      setClassificatorBranchColumnLabel5,
    ] = useState("");
    const [
      applyForAllOrganizationsRelationsColumnLabel,
      setApplyForAllOrganizationsRelationsColumnLabel,
    ] = useState("");
    const [weightColumnLabel, setWeightColumnLabel] = useState("");
    const [updateByColumnLabel, setUpdateByColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    //#endregion

    //Load Resources and organizations
    const loadResourcesAndOrganizations = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );

      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );

      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setOrganizationsRelationsLabel(
        await TPI18N.GetText(resourceSet, "OrganizationRelation"),
      );

      //grid columns
      setFunctionColumnLabel(await TPI18N.GetText(resourceSet, "Function"));
      setResponsibleColumnLabel(
        await TPI18N.GetText(resourceSet, "ResponsibleLabel"),
      );
      setClassificatorBranchColumnLabel1(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel1"),
      );
      setClassificatorBranchColumnLabel2(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel2"),
      );
      setClassificatorBranchColumnLabel3(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel3"),
      );
      setClassificatorBranchColumnLabel4(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel4"),
      );
      setClassificatorBranchColumnLabel5(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel5"),
      );
      setApplyForAllOrganizationsRelationsColumnLabel(
        await TPI18N.GetText(
          resourceSet,
          "ApplyForAllOrganizationsRelatiosnLabel",
        ),
      );
      setWeightColumnLabel(await TPI18N.GetText(resourceSet, "Weight"));
      setUpdateByColumnLabel(await TPI18N.GetText(resourceSet, "UpdatedBy"));

      //Organizations/Relations
      await getOrganizationsRelationsList();
      let command1: commandType = {
        type: commandsEnum.set_OrganizationsRelationsAreLoaded,
        payload: null,
      };
      dispatchCommand(command1);
    };

    const getRelationsList = async (
      newOrganization: string,
    ): Promise<Array<TPKeyValue>> => {
      let serviceClient = new RelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        let responseRequest =
          await serviceClient.getRelationsByOrganizationAndFilter(
            newOrganization,
            TPActiveOptions.ALL.toString(),
            false,
            false,
            expectedCodes,
          );

        let newRelationsListState: Array<TPKeyValue> = [];
        let relationsListData: Array<RelationsWithRestrictionsViewModel>;
        relationsListData = responseRequest;
        for (i = 0; i <= relationsListData.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: relationsListData[i].baseLevelId,
            value: relationsListData[i].localizedName
              ? relationsListData[i].localizedName
              : relationsListData[i].name,
          };
          newRelationsListState.push(keyvalue);
        }
        return newRelationsListState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRelationsList ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getRelationsList ex`);
        return [];
      }
    };

    const getOrganizationsRelationsList = async () => {
      let serviceOrganizationRelationClient =
        new OrganizationsRelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        setIsLoadingScreen(true);
        let responseRequestOrgRela =
          await serviceOrganizationRelationClient.getOrganizationsRelationsByFilter(
            TPActiveOptions.ALL.toString(),
            false,
            true,
            expectedCodes,
          );

        let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
        for (i = 0; i <= responseRequestOrgRela.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: responseRequestOrgRela[i].id,
            value: responseRequestOrgRela[i].localizedDescription,
          };
          newOrganizationsRelationsListState.push(keyvalue);
        }

        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
        newOrganizationsRelationsListState.unshift(intitialKeyValue);
        setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
        );
        setIsLoadingScreen(false);
      }
    };

    const conditionalRowStyles = [
      {
        when: (row: any) => !row.baseLevelId,
        style: { color: "#04b469" },
      },
    ];

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        //delete
        newColumns.push({
          name: "",
          width: "50px",
          style: { padding: 0 },
          center: true,
          cell: (row: { [x: string]: any }) => {
            return (
              <div className="dropdown">
                <TPButton
                  dataBsToggle={true}
                  type={TPButtonTypes.empty}
                  onClick={() => {
                    TPGlobal.foo();
                  }}
                  className={"menu-button"}
                >
                  <TPIcon iconType={TPIconTypes.moreVert} />
                </TPButton>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      onClick={(id: any) => handleDeleteClick(row["guidId"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {deleteLabel}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(id: any) => handleUpdateClick(row["guidId"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {updateLabel}
                    </a>
                  </li>
                </ul>
              </div>
            );
          },
        });
        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) => {
            return (
              <TPButton
                type={TPButtonTypes.primary}
                onClick={(id: string) => handleUpdateClick(row["guidId"])}
                className="update-button"
              >
                <TPIcon iconType={TPIconTypes.chevronRight} />
              </TPButton>
            );
          },
          selector: (row: { [x: string]: any }) => row["guidId"],
          sortable: true,
        });
        //Base Level Description
        newColumns.push({
          width: "300px",
          name: organizationsRelationsLabel,
          cell: (row: { [x: string]: any }) => {
            let currentValue: string;
            currentValue = row["baseLevelId"]
              ? row["baseLevelDescription"]
              : row["baseLevelSourceDescription"];
            return currentValue;
          },
          selector: (row: { [x: string]: any }) =>
            row["baseLevelSourceDescription"],
          sortable: true,
        });
        //ignore baseLevel
        newColumns.push({
          name: applyForAllOrganizationsRelationsColumnLabel,
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: string;
            currentValue = row["baseLevelId"];
            return (
              <IsActiveIcon
                className={currentValue ? "inactive" : "active"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });
        //Function name
        newColumns.push({
          width: "200px",
          name: functionColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["functionDescription"];
          },
          selector: (row: { [x: string]: any }) => row["functionDescription"],
          sortable: true,
        });
        //Responsible
        newColumns.push({
          width: "200px",
          name: responsibleColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["userDescription"];
          },
          selector: (row: { [x: string]: any }) => row["userDescription"],
          sortable: true,
        });

        //classificator branch1
        newColumns.push({
          width: "200px",
          name: classificatorBranchColumnLabel1,
          cell: (row: { [x: string]: any }) => {
            return row["branch1Description"];
          },
          selector: (row: { [x: string]: any }) => row["branch1Description"],
          sortable: true,
        });

        //classificator branch2
        newColumns.push({
          width: "200px",
          name: classificatorBranchColumnLabel2,
          cell: (row: { [x: string]: any }) => {
            return row["branch2Description"];
          },
          selector: (row: { [x: string]: any }) => row["branch2Description"],
          sortable: true,
        });

        //classificator branch3
        newColumns.push({
          width: "200px",
          name: classificatorBranchColumnLabel3,
          cell: (row: { [x: string]: any }) => {
            return row["branch3Description"];
          },
          selector: (row: { [x: string]: any }) => row["branch3Description"],
          sortable: true,
        });

        //classificator branch4
        newColumns.push({
          width: "200px",
          name: classificatorBranchColumnLabel4,
          cell: (row: { [x: string]: any }) => {
            return row["branch4Description"];
          },
          selector: (row: { [x: string]: any }) => row["branch4Description"],
          sortable: true,
        });

        //classificator branch5
        newColumns.push({
          width: "200px",
          name: classificatorBranchColumnLabel5,
          cell: (row: { [x: string]: any }) => {
            return row["branch5Description"];
          },
          selector: (row: { [x: string]: any }) => row["branch5Description"],
          sortable: true,
        });
        //Weight
        newColumns.push({
          width: "200px",
          name: weightColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["weight"];
          },
          selector: (row: { [x: string]: any }) => row["weight"],
          sortable: true,
        });
        //update by
        newColumns.push({
          width: "200px",
          name: updateByColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["typistUserDescription"];
          },
          selector: (row: { [x: string]: any }) => row["typistUserDescription"],
          sortable: true,
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    const handleOrganizationRelationChange = async (e: any) => {
      let newBaseLevel: string;
      newBaseLevel = e.target.value;
      let command1: commandType = {
        type: commandsEnum.change_CurrentBaseLevel,
        payload: { newBaseLevel: newBaseLevel },
      };
      dispatchCommand(command1);
    };

    //New Function Assignment
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Get function assignments by Filter organizationRelation
    const reloadDataGrid = async (baseLevelId: string) => {
      let serviceClient = new FunctionAssignmentsService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        if (baseLevelId !== "") {
          let responseRequest =
            await serviceClient.getFunctionAssignmentByBaseLevel(
              baseLevelId,
              false,
              true,
              expectedCodes,
            );
          setIsLoadingScreen(false);
          return [...responseRequest];
        } else {
          let responseRequest = await serviceClient.getAll(
            false,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          return [...responseRequest];
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.currentBaseLevel)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Update function assignment
    const handleUpdateClick = (id: string) => {
      let command: any = {
        command: "update",
        recordId: id,
        recordDescription: "",
      };
      callBackCommands(command);
    };

    //Modal Question to delete function assignment
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete function assignment after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new FunctionAssignmentsService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest =
            await serviceClient.deleteFunctionAssignmentById(
              callBackData.recordId,
              true,
              true,
              expectedCodes,
            );
          setIsLoadingScreen(false);
          reloadGridCommand();
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "baseLevelDescription",
        "functionDescription",
        "userDescription",
        "branch1Description",
        "branch2Description",
        "branch3Description",
        "branch4Description",
        "branch5Description",
        "weight",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      organizationsRelationsAreLoaded: false,
      currentBaseLevel: "",
      columnsAreLoaded: false,
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      let newAdminState: AdminStateType;
      switch (command.type) {
        case commandsEnum.set_OrganizationsRelationsAreLoaded:
          newAdminState = { ...prevState };
          newAdminState.organizationsRelationsAreLoaded = true;
          return newAdminState;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.change_CurrentBaseLevel:
          newAdminState = { ...prevState };
          newAdminState.currentBaseLevel = command.payload.newBaseLevel;
          return newAdminState;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndOrganizations();
    }, []);

    //Run when organizations/relations are loaded to get columns
    useEffect(() => {
      if (adminState.organizationsRelationsAreLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.organizationsRelationsAreLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change organization/relation
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.currentBaseLevel]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    return (
      <>
        <TPModalQuestion
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <TPPageFirstRow>
                <TPPageActions>
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewClick()}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() =>
                      exportToCSV(
                        filteredData(),
                        "functions-assignments-admin-data",
                      )
                    }
                    text={exportLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                </TPPageActions>

                <TPFilterAndSearch style={{ width: "60%" }}>
                  <TPPageFilterContainer>
                    <TPSelect
                      id="IdSelect"
                      onChange={handleOrganizationRelationChange}
                      dataSource={organizationsRelationsKeyValue}
                      value={adminState.currentBaseLevel}
                      labelText={organizationsRelationsLabel}
                      isHorizontal={true}
                    ></TPSelect>
                  </TPPageFilterContainer>
                  <TPPageSearchContainer>
                    <TPTextBox
                      id="IdTextBox"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                    conditionalRowStyles={conditionalRowStyles}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);

export default FunctionAssignmentsAdmin;
