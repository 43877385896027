import { VerticalTabItemModel } from "@/models/VerticalTabs/VerticalTabsModel";
import { StateModel } from "@/redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VerticalTabsAdminContainerStateModel extends StateModel {
  tabs: Array<VerticalTabItemModel>;
  focus?: string;
  focusIndex: number;
}

export const VerticalTabsAdminContainerSlice = createSlice({
  name: "VerticalTabsAdminContainer",
  initialState: {
    tabs: [] as Array<VerticalTabItemModel>,
    focusIndex: 0,
    result: {},
  } as VerticalTabsAdminContainerStateModel,
  reducers: {
    clean: (_, action: PayloadAction) =>
      ({
        tabs: [],
        focusIndex: 0,
        result: { action },
      }) as VerticalTabsAdminContainerStateModel,
    clone: (
      state: VerticalTabsAdminContainerStateModel,
      action: PayloadAction<any>,
    ) =>
      ({
        ...state,
        result: { action },
      }) as VerticalTabsAdminContainerStateModel,
    update: (
      state: VerticalTabsAdminContainerStateModel,
      action: PayloadAction<Array<VerticalTabItemModel>>,
    ) => {
      let focus;
      let focusIndex = state.focusIndex;
      const tabs = [...action.payload].map((tab, index) => {
        if (tab.isActive) {
          focus = tab.id;
          focusIndex = index;
        }

        return { ...tab };
      });

      return {
        ...state,
        tabs,
        focus,
        focusIndex,
        result: { action },
      } as VerticalTabsAdminContainerStateModel;
    },
    export: (
      state: VerticalTabsAdminContainerStateModel,
      action: PayloadAction<any>,
    ) =>
      ({
        ...state,
        result: { action },
      }) as VerticalTabsAdminContainerStateModel,
  },
});
