import React, { CSSProperties, useEffect, useState } from "react";
import { useFormik } from "formik";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import {
  ProjectNewControl,
  ProjectNewEnum as e,
  ProjectNewModel,
  ProjectType,
  SetControl,
} from "@/models/Project/Projects";
import { Box } from "@mui/material";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import ProjectNewStep1LanguageModal from "@/pages/Projects/ProjectNew/ProjectNewStep1LanguageModal";
import { SequeceGeneratorSequencesNameEnum } from "@/models/Global/TPGlobalEnums";
import { UserService } from "@/services/UserService";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { SequenceService } from "@/services/SequenceService";
import { EventLoadStructureService } from "@/services/EventLoadStructureService";

interface ProjectNewStep1Props {
  m: { [attribute: string]: any };
  project: ProjectNewModel;
  isUpdate: boolean;
  onChange: Function;
}

export default function ProjectNewStep1({
  m,
  project,
  isUpdate,
  onChange,
}: ProjectNewStep1Props) {
  const types: Array<TPKeyValue> = [
    {
      key: ProjectType.FRONTOFFICE,
      value: m?.[e.FormInputTypeFrontOfficeOptionLabel],
    },
    {
      key: ProjectType.BACKOFFICE,
      value: m?.[e.FormInputTypeBackOfficeOptionLabel],
    },
  ];

  const formik = useFormik({
    initialValues: project,
    validationSchema: ProjectNewControl.validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const [languages, setLanguages] = useState<any>({});
  const [structureTypes, setStructureTypes] = useState<Array<TPKeyValue>>([]);
  const [supervisors, setSupervisors] = useState<Array<TPKeyValue>>([]);

  useEffect(() => {
    const { find } = new EventLoadStructureService();
    find({ filterIsActive: "1" })
      .then((structureTypes) => {
        setStructureTypes(
          structureTypes.map(({ id: key, description: value }) => ({
            key,
            value,
          }))
        );
      })
      .catch((error) => console.error(error));

    const { findSupervisors } = new UserService();
    findSupervisors()
      .then((supervisors) => {
        setSupervisors(
          supervisors.map(({ id: key, name: value }) => ({ key, value }))
        );
      })
      .catch((error) => console.error(error));

    if (project?.projectId) {
      const l = project.nameLanguages
        .filter((_, index) => index !== 0)
        .map(({ languageId, localizedValue }) => [languageId, localizedValue]);
      setLanguages(Object.fromEntries(l));
    } else {
      const { generalAutomaticId } = new SequenceService();
      generalAutomaticId(
        false,
        true,
        [200],
        SequeceGeneratorSequencesNameEnum.SQEVPJ
      )
        .then(({ responseData }) => {
          formik.setFieldValue(
            "projectId",
            responseData?.data[0]?.sequenceCode
          );
        })
        .catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (
      !isUpdate &&
      formik.values.recordType === ProjectType.FRONTOFFICE &&
      (formik.values.structureTypeId ||
        formik.values.conditionsOrQueues!.length > 0)
    ) {
      formik.setValues({
        ...formik.values,
        structureTypeId: null,
        conditionsOrQueues: [],
      });
    }
    if (
      !isUpdate &&
      formik.values.recordType === ProjectType.BACKOFFICE &&
      formik.values.conditionsOrQueues!.length === 0
    ) {
      formik.setValues({
        ...formik.values,
        conditionsOrQueues: [
          { ...SetControl.initialValues, name: `${m?.[e.Step2SetLabel]} 1` },
        ],
      });
    }
  }, [formik.values.recordType]);

  useEffect(() => {
    const l = Object.entries(languages).map(
      ([languageId, localizedValue], order) => ({
        languageId,
        localizedValue,
        order,
      })
    );
    formik.setFieldValue("nameLanguages", l);
  }, [languages]);

  return (
    <form style={styles.form}>
      <h6 style={styles.description}>{m?.[e.Step1DescriptionLabel]}</h6>

      <TPTextBox
        id="projectId"
        containerStyle={styles.marginBottom}
        disabled={true}
        labelText={m?.[e.FormInputIdLabel]}
        isMandatory={true}
        value={formik.values?.projectId ?? ""}
        onChange={() => {}}
        errorMessage={formik.errors.projectId && m?.[formik.errors.projectId]}
      />

      <div style={styles.marginBottom10}>
        <TPRadioGroup
          id="recordType"
          labelStyle={styles.type}
          labelText={m?.[e.FormInputTypeLabel]}
          disabled={isUpdate}
          value={`${formik.values.recordType}`}
          source={types}
          onChange={(e: any) =>
            formik.setFieldValue("recordType", e.target.value)
          }
          errorMessage={
            formik.errors.recordType && m?.[formik.errors.recordType]
          }
        />
      </div>

      <div className="row col-12">
        <div className="col-11">
          <TPTextBox
            id="name"
            containerStyle={styles.marginBottom}
            isMandatory={true}
            labelText={m?.[e.FormInputNameLabel]}
            value={formik.values?.name ?? ""}
            onChange={(e: any) => formik.setFieldValue("name", e.target.value)}
            maxLength={100}
            errorMessage={formik.errors.name && m?.[formik.errors.name]}
          />
        </div>

        <div className="col-1" style={styles.iconButton}>
          <ProjectNewStep1LanguageModal
            m={m}
            languages={languages}
            setLanguages={setLanguages}
          />
        </div>
      </div>

      {formik.values.recordType === ProjectType.BACKOFFICE && (
        <div style={styles.marginBottom}>
          <TPAutoComplete
            isMandatory={true}
            labelText={m?.[e.FormInputStructureTypeLabel]}
            disabled={isUpdate}
            onValueChange={(value: Array<TPKeyValue>) =>
              formik.setFieldValue("structureTypeId", value?.[0]?.key ?? null)
            }
            onSearch={(query: string) => {
              return supervisors;
            }}
            isLoading={false}
            options={structureTypes}
            withIcon={true}
            emptyLabel={" sin valores "}
            onKeyDown={(event: any) => {}}
            selected={structureTypes.filter(
              ({ key }) => key === formik.values?.structureTypeId
            )}
            errorMessage={
              formik.errors.structureTypeId &&
              m?.[formik.errors.structureTypeId]
            }
            downArrowClick={() => {}}
          />
        </div>
      )}

      <Box style={styles.supervisor}>
        <TPAutoComplete
          isMandatory={false}
          labelText={m?.[e.FormInputSupervisorLabel]}
          onValueChange={(value: Array<TPKeyValue>) =>
            formik.setFieldValue("supervisorId", value?.[0]?.key ?? null)
          }
          onSearch={(query: string) => {
            return supervisors;
          }}
          isLoading={false}
          options={supervisors}
          withIcon={true}
          emptyLabel={" sin valores "}
          onKeyDown={(event: any) => {}}
          selected={supervisors.filter(
            ({ key }) => key === formik.values?.supervisorId
          )}
          errorMessage={
            formik.errors.supervisorId && m?.[formik.errors.supervisorId]
          }
          downArrowClick={() => {}}
        />
      </Box>

      <TPCheckBox
        id="isActive"
        labelText={m?.[e.FormInputActiveLabel]}
        checked={formik.values.isActive}
        onChange={() =>
          formik.setFieldValue("isActive", !formik.values.isActive)
        }
      />
    </form>
  );
}

const styles = {
  form: {
    marginBottom: "20px",
  } as CSSProperties,
  description: {
    fontWeight: "bold",
  } as CSSProperties,
  marginBottom: {
    marginBottom: "20px",
  } as CSSProperties,
  marginBottom10: {
    marginBottom: "10px",
  } as CSSProperties,
  type: {
    fontWeight: "bold",
  } as CSSProperties,
  iconButton: {
    margin: "auto 0",
  } as CSSProperties,
  supervisor: {
    marginBottom: "10px",
  } as CSSProperties,
};
