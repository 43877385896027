import React, { FC, useEffect, useState } from "react";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPActiveOptions, TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import {
  PreferredAgentInputDTO,
  PreferredAgentInputDTOValidator,
} from "@/models/PreferredAgent/PreferredAgentInputDTO";
import { PreferredAgentService } from "@/services/PreferredAgentService";
import { TPI18N } from "@/services/I18nService";
import { UserService } from "@/services/UserService";
import { TPPageSection } from "@/components/TPPage/tpPageStyles";
import { UserViewModel } from "@/models/Users/UserModels";
import { PreferredAgentViewModel } from "@/models/PreferredAgent/PreferredAgentViewModel";

export type TPModalPreferredAgentState = {
  mode: string;
  isShown: boolean;
  clientId: number;
  id: number;
  subsidiaryId: number;
  agentId: number;
  priority: number;
};

type TPModalPreferredAgentInsertUpdateProps = {
  acceptLabel: string;
  cancelLabel: string;
  callBackAnswer: Function;
  modalState: TPModalPreferredAgentState;
};

interface ScreenStateProps {
  idSubsidiary: number;
  idUser: number;
  priority: number;
  agentLogin: string;
  agentName: string;
  idSubsidiaryErrorMessage: string;
  idUserErrorMessage: string;
  priorityErrorMessage: string;
  agentLoginErrorMessage: string;
  agentNameErrorMessage: string;
  [key: string]: any;
}

const TPModalPreferredAgentInsertUpdate: FC<
  TPModalPreferredAgentInsertUpdateProps
> = ({ acceptLabel, cancelLabel, callBackAnswer, modalState }) => {
  //#region Init
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  if (modalState.isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const componentFileName: string = "TPModalPreferredAgentInsertUpdate.tsx";
  const resourceSet: string = "TPModalPreferredAgentInsertUpdate";
  const screenStateInitialState: ScreenStateProps = {
    idSubsidiary: modalState.subsidiaryId,
    idUser: -1,
    priority: 1,
    agentLogin: "",
    agentName: "",
    idSubsidiaryErrorMessage: "",
    idUserErrorMessage: "",
    priorityErrorMessage: "",
    agentLoginErrorMessage: "",
    agentNameErrorMessage: "",
  };
  const [screenState, setScreenState] = useState<ScreenStateProps>(
    screenStateInitialState,
  );

  const [agentList, setAgentList] = useState<Array<TPKeyValue>>([]);
  const [priorityList, setPriorityList] = useState<Array<TPKeyValue>>([]);

  //screen resources
  const [titleModal, setTitleModal] = useState("");
  const [subsidiaryLabel, setSubsidiaryLabel] = useState("");
  const [agentLabel, setAgentLabel] = useState("");
  const [priorityLabel, setPriorityLabel] = useState("");

  //#endregion Init

  const loadResources = async () => {
    setSubsidiaryLabel(await TPI18N.GetText(resourceSet, "SubsidiaryLabel"));
    setAgentLabel(await TPI18N.GetText(resourceSet, "AgentLabel"));
    setPriorityLabel(await TPI18N.GetText(resourceSet, "PriorityLabel"));
    setTitleModal(
      await TPI18N.GetText(resourceSet, `Title${modalState.mode}Modal`),
    );

    // Load Agent List
    let userService = new UserService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest: Array<UserViewModel> =
        await userService.getUsersByFilter(
          TPActiveOptions.ALL.toString(),
          false,
          true,
          expectedCodes,
        );

      let newAgentList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id.toString(), value: item.name };
        },
      );
      newAgentList.unshift({ key: "", value: "--" });
      setAgentList(newAgentList);
      setPriorityList(getPriorityOptions());
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResources getUsersByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} loadResources getUsersByFilter ex`,
      );
    }
  };

  const getPriorityOptions = () => {
    let priorityOptions: Array<TPKeyValue> = [{ key: "", value: "--" }];
    for (let i = 1; i <= 10; i++) {
      priorityOptions.push({ key: i.toString(), value: i.toString() });
    }
    return priorityOptions;
  };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    let newScreenState = { ...screenState };
    newScreenState[propertyName] = newValue;
    newScreenState[propertyName + "ErrorMessage"] = "";
    setScreenState(newScreenState);
  };

  const handlerOnOKClick = async () => {
    const selectedAgent = agentList.find(
      (agent) => agent.key === screenState.idUser.toString(),
    );

    let recordInputDTO: PreferredAgentInputDTO = {
      id: modalState.id,
      idSubsidiary: screenState.idSubsidiary,
      idUser: parseInt(screenState.idUser.toString()),
      priority: parseFloat(screenState.priority.toString()),
      agentLogin: selectedAgent ? selectedAgent.value : "",
      agentName: selectedAgent ? selectedAgent.value : "",
    };

    let inputDTOValidator = new PreferredAgentInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newScreenState = { ...screenState };
      if (resultValidator.idUser) {
        newScreenState.idUserErrorMessage = await TPI18N.GetResource(
          resultValidator.idUser,
        );
      } else {
        newScreenState.idUserErrorMessage = "";
      }
      if (resultValidator.priority) {
        newScreenState.priorityErrorMessage = await TPI18N.GetResource(
          resultValidator.priority,
        );
      } else {
        newScreenState.priorityErrorMessage = "";
      }
      if (resultValidator.agentLogin) {
        newScreenState.agentLoginErrorMessage = await TPI18N.GetResource(
          resultValidator.agentLogin,
        );
      } else {
        newScreenState.agentLoginErrorMessage = "";
      }
      if (resultValidator.agentName) {
        newScreenState.agentNameErrorMessage = await TPI18N.GetResource(
          resultValidator.agentName,
        );
      } else {
        newScreenState.agentNameErrorMessage = "";
      }
      setScreenState(newScreenState);
      return;
    }

    if (modalState.mode === "Insert") {
      await insertPreferredAgent(recordInputDTO);
    } else {
      await updatePreferredAgent(recordInputDTO);
    }
  };

  const insertPreferredAgent = async (inputDTO: PreferredAgentInputDTO) => {
    let preferredAgentService = new PreferredAgentService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

            let responseRequest = await preferredAgentService.insertPreferredAgent(
                inputDTO,
                true,
                false,
                expectedCodes
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                const id_CPAG = responseRequest.responseData.keyList.filter(
                    (x: TPKeyValue) => x.key === "id_CPAG"
                )[0]["value"];
                callBackAnswer(true, id_CPAG);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} insertPreferredAgent ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} insertPreferredAgent ex`);
            setIsLoadingScreen(false);
        }
    };

  const updatePreferredAgent = async (inputDTO: PreferredAgentInputDTO) => {
    let preferredAgentService = new PreferredAgentService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await preferredAgentService.updatePreferredAgent(
        inputDTO,
        true,
        false,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        const id_CPAG = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "Id_CPAG",
        )[0]["value"];
        callBackAnswer(true, id_CPAG);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatePreferredAgent ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatePreferredAgent ex`);
      setIsLoadingScreen(false);
    }
  };

  const getPreferredAgentById = async (idUser: number) => {
    let serviceClient = new PreferredAgentService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getPreferredAgentById(
        idUser,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: PreferredAgentViewModel = responseRequest;
      let newScreenState: ScreenStateProps = { ...screenState };

      if (recordInfo) {
        newScreenState.agentLogin = recordInfo.agentLogin;
        newScreenState.agentName = recordInfo.agentName;
        newScreenState.priority = recordInfo.priority;
        newScreenState.idUser = recordInfo.idUser;
        newScreenState.idSubsidiary = recordInfo.idSubsidiary;
      }

      setScreenState(newScreenState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getPreferredAgentById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getPreferredAgentById ex`);
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (modalState.isShown && modalState.mode === "Update") {
        await getPreferredAgentById(modalState.id);
      }
    };
    loadData();
  }, [modalState]);

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content" style={{ top: "-25px" }}>
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{titleModal}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => callBackAnswer(false, 0)}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "auto" }}>
              <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
                <TPPageSection>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPSelect
                          id="IdSelect"
                          isMandatory={true}
                          labelText={agentLabel}
                          dataSource={agentList}
                          onChange={(e: any) =>
                            handleInputTextChange("idUser", e.target.value)
                          }
                          value={screenState.idUser}
                          errorMessage={screenState.idUserErrorMessage}
                          disabled={modalState.mode === "Update"}
                        ></TPSelect>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="form-group">
                        <TPSelect
                          id="prioritySelect"
                          isMandatory={true}
                          dataSource={priorityList}
                          labelText={priorityLabel}
                          onChange={(e: any) =>
                            handleInputTextChange("priority", e.target.value)
                          }
                          value={screenState.priority}
                          errorMessage={screenState.priorityErrorMessage}
                        ></TPSelect>
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => handlerOnOKClick()}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => callBackAnswer(false, 0)}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalPreferredAgentInsertUpdate;
