import { CustomError, StateModel } from "@/redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import GroupCloneFormModel from "../models/GroupCloneFormModel";
import e from "../models/GroupCloneFormEnum";

export interface GroupCloneFormStateModel extends StateModel {
  message: any;
  form: GroupCloneFormModel;
}

export const GroupCloneFormSlice = createSlice({
  name: e.GroupCloneForm,
  initialState: {
    message: {},
    form: {},
    result: {},
  } as GroupCloneFormStateModel,
  reducers: {
    clean: (_, action: PayloadAction) =>
      ({
        message: {},
        form: {},
        result: { action },
      }) as GroupCloneFormStateModel,
    setValue: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<GroupCloneFormModel>,
    ) =>
      ({
        ...state,
        form: { ...action.payload },
        result: { action },
      }) as GroupCloneFormStateModel,
    setAttribute: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<{ attribute: string; value: any }>,
    ) => {
      const form = { ...state.form } as any;
      form[action.payload.attribute] = action.payload.value;

      return {
        ...state,
        form,
        result: { action },
      } as GroupCloneFormStateModel;
    },
    message: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<Array<string | Array<string>>>,
    ) =>
      ({
        ...state,
        result: { action },
      }) as GroupCloneFormStateModel,
    messageSuccess: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<{
        attribute: string;
        message: string;
      }>,
    ) => {
      const message = { ...state.message };
      message[action.payload.attribute] = action.payload.message;

      return {
        ...state,
        message,
        result: { action },
      } as GroupCloneFormStateModel;
    },
    messageError: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<CustomError>,
    ) =>
      ({
        ...state,
        result: {
          action,
          ...action.payload,
        },
      }) as GroupCloneFormStateModel,
    getId: (state: GroupCloneFormStateModel, action: PayloadAction) =>
      ({
        ...state,
        result: { action },
      }) as GroupCloneFormStateModel,
    getIdSuccess: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<string>,
    ) =>
      ({
        ...state,
        form: {
          ...state.form,
          groupId: action.payload,
        },
        result: { action },
      }) as GroupCloneFormStateModel,
    getIdError: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<CustomError>,
    ) =>
      ({
        ...state,
        result: {
          action,
          ...action.payload,
        },
      }) as GroupCloneFormStateModel,
    save: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<GroupCloneFormModel>,
    ) =>
      ({
        ...state,
        result: { action },
      }) as GroupCloneFormStateModel,
    saveSuccess: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<{
        element: GroupCloneFormModel;
        message: string;
      }>,
    ) =>
      ({
        ...state,
        form: action.payload.element,
        result: { action, message: action.payload.message },
      }) as GroupCloneFormStateModel,
    saveError: (
      state: GroupCloneFormStateModel,
      action: PayloadAction<CustomError>,
    ) =>
      ({
        ...state,
        result: {
          action,
          ...action.payload,
        },
      }) as GroupCloneFormStateModel,
  },
});
