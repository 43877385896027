import React, {CSSProperties, useEffect, useState} from "react";
import {TableProjectDetailModel} from "@/models/Project/Projects";
import ProjectDetailCard from "@/pages/Projects/ProjectDetail/ProjectDetailCard";
import {TPKeyValue} from "@/helpers/TPKeyValue";

interface ProjectDetailCardsProps {
    m: { [attribute: string]: any };
    queues: Array<TPKeyValue>;
    datasource: Array<TableProjectDetailModel>;
    inputSearch: string
}

export default function ProjectDetailCards({m, queues, datasource, inputSearch}: ProjectDetailCardsProps) {

    const [data, setData] = useState<TableProjectDetailModel[]>([]);

    useEffect(() => {
        if (!inputSearch) {
            setData(datasource)
        }

        setData(
            datasource.filter(item => Object.values(item)
                .some(val => String(val).toLowerCase().includes(inputSearch.toLocaleLowerCase())))
        )
    }, [datasource, inputSearch]);

    return (
        <div style={styles.row}>
            {data.map(element => (
                <ProjectDetailCard
                    m={m}
                    queues={queues}
                    element={element}
                />
            ))}
        </div>
    );
}

const styles = {
    row: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        flexFlow: "wrap",
        gap: "10px",
        overflow: "auto",
    } as CSSProperties,
};
