import allThemes from "@/assets/styles/theme";
import TPGlobal from "@/helpers/TPGlobal";
import TPModal from "@/layouts/TPModal/TPModal";
import { TPI18N } from "@/services/I18nService";
import { TaskService } from "@/services/TaskService";
import { Icon } from "@iconify/react";
import { FC, useEffect, useState } from "react";
import TPGenAIOutboundMenu from "./OutboundMailMenu";

const resourceSet = "TPGenAI";

interface Properties {
  caseId: number;
}

const ComposeWithAIAlert: FC<Properties> = function ({ caseId }) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [TPGenAIAvailableLabel, setTPGenAIAvailableLabel] =
    useState<string>("...");
  const [composeOutgoindMailLabel, setComposeOutgoingMailLabel] =
    useState<string>("...");
  const [TPGenAITitleModal, setTPGenAITitleModal] = useState<string>("...");

  const loadLabels = async function () {
    setTPGenAIAvailableLabel(
      await TPI18N.GetText(resourceSet, "TPGenAIAvailableLabel"),
    );
    setComposeOutgoingMailLabel(
      await TPI18N.GetText(resourceSet, "ComposeOutgoingMailLabel"),
    );
    setTPGenAITitleModal(
      await TPI18N.GetText(resourceSet, "TPGenAITitleModal"),
    );
  };

  useEffect(() => {
    if (caseId) {
      const taskServiceInstance = new TaskService();
      taskServiceInstance
        .getInboundMailboxReadByCaseId(caseId, false, false, [200])
        .then((response) => {
          const mailboxRead = response.find(
            (item: { insertionType: string }) =>
              item.insertionType === "NEWCASE",
          );
          if (mailboxRead) {
            setEnabled(Boolean(TPGlobal.TPGenAIEnabled));
          }
        })
        .catch((error) => {
          console.error(error);
        });

      loadLabels();
    }
  }, [caseId]);

  return (
    <>
      {enabled && (
        <>
          <div className="compose-with-ai-alert">
            <div
              className="compose-with-ai-content"
              style={{
                backgroundColor: allThemes.base.highlight.backgroundColor,
              }}
            >
              <div className="compose-with-ai-label">
                <Icon
                  icon="material-symbols:warning"
                  color={allThemes.base.highlight.color}
                />
                <center style={{ color: allThemes.base.highlight.color }}>
                  {TPGenAIAvailableLabel}
                </center>
              </div>
              <button
                className="compose-with-ai-button"
                style={{ backgroundColor: allThemes.base.primary }}
                onClick={() => setModalVisible(true)}
              >
                {composeOutgoindMailLabel}
              </button>
            </div>
          </div>
          <TPModal
            modalState={{
              acceptLabel: "",
              cancelLabel: "",
              titleModal: TPGenAITitleModal,
              callBackAnswer: () => setModalVisible(false),
              isShown: modalVisible,
              enableAcceptButton: false,
              hideFooterButtons: true,
            }}
            children={<TPGenAIOutboundMenu caseId={caseId} />}
          />
        </>
      )}
    </>
  );
};

export default ComposeWithAIAlert;
