import { forwardRef, useEffect, useState } from "react";

import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPPageSection } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPModalImageSelectorState } from "@/layouts/TPModalImageSelector/TPModalImageSelector";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { FormesignerInputDTO } from "@/models/FormDesigner/FormDesigner";
import {
    SequeceGeneratorSequencesNameEnum,
    SystemParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { useAppConctextFormDesigner } from "./ContextFormDesignerAdmin";
import {
    ContainerNotification,
    ContainerPreferences,
} from "./StyeFromDesigner";
import { SequenceService } from "../../services/SequenceService";

interface FormDesignerTableModel {
    callBackCommands: Function;
    recordId: string;
    mode: string;
}

const FormDesignerPreferences = forwardRef(
    ({ callBackCommands, recordId, mode }: FormDesignerTableModel) => {
        const resourceSet: string = "FormDesignerComponent";

        const [baseLanguageOptions, setBaseLanguageOptions] = useState<
            Array<TPKeyValue>
        >([]);
        const [formTypeOptions, setformTypeOptions] = useState<Array<TPKeyValue>>(
            [],
        );

        //State modal
        let modalImageSelectorInitialState: TPModalImageSelectorState = {
            isShown: false,
            callBackData: {},
        };

        const [modalImageSelectorState, setModalImageSelectorState] = useState(
            modalImageSelectorInitialState,
        );
        //screen loading
        const [isLoadingScreen, setIsLoadingScreen] = useState(true);
        const [referenceId, setReferenceId] = useState("");

        const [titleLabel, setTitleLabel] = useState("");
        const [descriptionImageSectionLabel, setDescriptionImageSectionLabel] =
            useState("");

        const [primaryLanguageLabel, setPrimaryLanguageLabel] = useState("");
        const [idLabel, setIdLabel] = useState("");
        const [nameLabel, setNameLabel] = useState("");
        const [languageListLabel, setLanguageListLabel] = useState("");
        const [saveButtonModalLabel, setSaveButtonModalLabel] = useState("");
        const [cancelButtonModalLabel, setCancelButtonModalLabel] = useState("");
        const [formTypeLabel, setFormTypeLabel] = useState("");
        const [imageLabel, setImageLabel] = useState("");
        const [selectImageTitle, setSelectImageTitle] = useState("");
        const [acceptImageButtonLabel, setAcceptImageButtonLabel] = useState("");
        const [cancelImageButtonLabel, setCancelImageButtonLabel] = useState("");
        const [selectedImageLabel, setSelectedImageLabel] = useState("");
        const [saveLabel, setSaveLabel] = useState("");
        const [completeThisinformationLabel, setCompleteThisinformationLabel] =
            useState("");
        const [isHidePersonalDataLabel, setIsHidePersonalDataLabel] = useState("");
        const [showAsActiveLabel, setShowAsActiveLabel] = useState("");
        const [descriptionLabel, setDescriptionLabel] = useState("");

        const [baseLanguageOptionsError, setBaseLanguageOptionsError] = useState("");
        const [recordLocalizedModelError, setRecordLocalizedModelError] = useState("");
        const [formTypeError, setFormTypeError] = useState("");
        const [required, setRequired] = useState("");

        const loadResourcesAndLoadInfo = async () => {
            setTitleLabel(
                await TPI18N.GetText(resourceSet, "TitleFormDesignerLabel"),
            );
            setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
            setPrimaryLanguageLabel(
                await TPI18N.GetText(resourceSet, "PrimaryLanguageLabel"),
            );
            setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
            setLanguageListLabel(
                await TPI18N.GetText(resourceSet, "setLanguageListLabel"),
            );
            setSaveButtonModalLabel(
                await TPI18N.GetText(resourceSet, "SaveButtonModalLabel"),
            );
            setCancelButtonModalLabel(
                await TPI18N.GetText(resourceSet, "CancelButtonModalLabel"),
            );
            setFormTypeLabel(await TPI18N.GetText(resourceSet, "FormTypeLabel"));
            setImageLabel(await TPI18N.GetText(resourceSet, "ImageLabel"));
            setSelectImageTitle(
                await TPI18N.GetText(resourceSet, "SelectImageTitle"),
            );
            setAcceptImageButtonLabel(
                await TPI18N.GetText(resourceSet, "AcceptImageButtonLabel"),
            );
            setSelectedImageLabel(
                await TPI18N.GetText(resourceSet, "SelectedImageLabel"),
            );
            setDescriptionImageSectionLabel(
                await TPI18N.GetText(resourceSet, "DescriptionImageSectionLabel"),
            );
            setSaveLabel(await TPI18N.GetText(resourceSet, "SaveLabel"));
            setCompleteThisinformationLabel(
                await TPI18N.GetText(resourceSet, "CompleteThisinformationLabel"),
            );
            setShowAsActiveLabel(
                await TPI18N.GetText(resourceSet, "ShowAsActiveLabel"),
            );
            setIsHidePersonalDataLabel(
                await TPI18N.GetText(resourceSet, "IsHidePersonalDataLabel"),
            );
            setDescriptionLabel(
                await TPI18N.GetText(resourceSet, "DescriptionLabel"),
            );
            setRequired(await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),);
           
        };

        const callBackVerticalTabTemp = () => {
            let command: any = { command: "new", recordId: 0 };
            callBackCommands(command);
        };

        const value = useAppConctextFormDesigner();
        const {
            rowsFormDesingner,
            isUpdate,
            handleEditForm,
            setStatusLoading,
            setIsUpdate,
        } = value;

        const handleShowModal = () => {
            let newTPModalImageSelectorState: TPModalImageSelectorState;
            newTPModalImageSelectorState = { ...modalImageSelectorState };
            newTPModalImageSelectorState.isShown = true;
            setModalImageSelectorState(newTPModalImageSelectorState);
        };

        const handleActiveSave = () => {
            let status = true;

            if (!rowsFormDesingner) return false;
            if (rowsFormDesingner.id == undefined || rowsFormDesingner.id == "")
                return false;
            if (rowsFormDesingner.primaryLanguage == undefined) return false;
            if (rowsFormDesingner.primaryLanguage.key == "") return false;
            if (rowsFormDesingner.formType == undefined) return false;
            if (rowsFormDesingner.formType.key == "") return false;
            return status;
        };

        const handleCallBackModal = (
            confirmSelection: boolean,
            callBackData: any,
        ) => {
            let newTPModalImageSelectorState: TPModalImageSelectorState;
            newTPModalImageSelectorState = { ...modalImageSelectorState };
            newTPModalImageSelectorState.isShown = false;
            newTPModalImageSelectorState.callBackData = {};
            setModalImageSelectorState(newTPModalImageSelectorState);
            if (confirmSelection) {
                let newInsertUpdateState = { ...rowsFormDesingner };
                newInsertUpdateState.img = {
                    key: callBackData.selectedFile.hierarchyId,
                    value: callBackData.selectedFile.fileName,
                };

                handleEditForm(newInsertUpdateState);
            }
        };

        const SaveForm = async () => {
            setStatusLoading(true);
            let insert = new FormesignerInputDTO();

            let serviceClient = new AdditionalDataFormService();
            let expectedCodes: Array<number> = [200];
            try {
                insert.id = rowsFormDesingner.id;
                insert.description = rowsFormDesingner.recordLocalizedModel[0]
                    ? rowsFormDesingner.recordLocalizedModel[0].localizedValue
                    : "";
                // insert.description = rowsFormDesingner.formName[0].localizedValue; // No existe campo description
                insert.languageId = rowsFormDesingner.primaryLanguage.key;
                insert.formTypeId = rowsFormDesingner.formType.key;
                insert.applyPersonalData = rowsFormDesingner.isHidePersonalData;
                insert.isActive = rowsFormDesingner.showAsActive;
                insert.logo =
                    rowsFormDesingner.img != undefined ? rowsFormDesingner.img.key : "";

                insert.jsonDesigner = new TextEncoder().encode(JSON.stringify(rowsFormDesingner))
                    .join(',');

                insert.otherLocalizedValues =
                    rowsFormDesingner.recordLocalizedModel != undefined
                        ? rowsFormDesingner.recordLocalizedModel
                        : new Array<RecordLocalizedModel>();
                let responseRequest;
                setIsUpdate(true);
                if (!isUpdate) {
                    responseRequest = await serviceClient.insertFormDesingner(
                        insert,
                        true,
                        false,
                        expectedCodes,
                    );
                } else {
                    responseRequest = await serviceClient.updateFormDesingner(
                        insert,
                        true,
                        false,
                        expectedCodes,
                    );
                }
            } catch (error) {
                TPLog.Log(`Error ${resourceSet} insert ex`, TPLogType.ERROR, error);
                console.error(`Error ${resourceSet} insert ex`);
            }

            setStatusLoading(false);
        };


        const Validate = async () => {
            let status = true;
            setBaseLanguageOptionsError("");
            setRecordLocalizedModelError("");
            setFormTypeError("");


            if (!rowsFormDesingner.primaryLanguage || rowsFormDesingner.primaryLanguage.key == "") {
                status = false;
                setBaseLanguageOptionsError(required);
            }
            if (!rowsFormDesingner.recordLocalizedModel || rowsFormDesingner.recordLocalizedModel[0].localizedValue == "") {
                status = false;
                setRecordLocalizedModelError(required);
            }
            if (rowsFormDesingner.formType && rowsFormDesingner.formType.key == "") {
                status = false;
                setFormTypeError(required);
            }

            if (status) SaveForm();
        }



        const loadFormType = async () => {
            let parametersService = new ParametersService();
            let expectedCodes: Array<number> = [200];

            try {
                let responseRequest =
                    await parametersService.getByParentIdAndFilterIsActive(
                        SystemParametersEnum.S_FORMTYPE,
                        TPActiveOptions.ACTIVE.toString(),
                        false,
                        true,
                        expectedCodes,
                    );
                if (responseRequest) {
                    let typeForm: Array<TPKeyValue> = responseRequest.map(
                        function (item) {
                            return {
                                key: item.id,
                                value: item.localizedDescription
                                    ? item.localizedDescription
                                    : item.description,
                            };
                        },
                    );
                    typeForm.unshift({ key: "", value: "--" });
                    setformTypeOptions(typeForm.filter((s) => s.value + "" != ""));
                } else {
                    //todo logs
                    return null;
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} loadFormType ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${resourceSet} loadFormType ex`);
                return null;
            }
        };

        const generalAutomaticId = async () => {
            let serviceClient = new SequenceService();
            let expectedCodes: Array<number> = [200];

            try {
                setStatusLoading(true);

                let responseRequest = await serviceClient.generalAutomaticId(
                    false,
                    true,
                    expectedCodes,
                    SequeceGeneratorSequencesNameEnum.SQADFO,
                );

                console.log("responseRequest", responseRequest);
                setStatusLoading(false);
                if (responseRequest.responseResult) {
                    let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                    let data = { ...rowsFormDesingner };
                    data.id = result;
                    handleEditForm(data);
                    setReferenceId(result);
                }
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} updatetFunction ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(`Error ${resourceSet} updatetFunction ex`);
                setStatusLoading(false);
            }
        };

        const loadLanguages = () => {
            let i: number;
            let expectedCodes: Array<number> = [200];

            let listLanguages: Array<TPKeyValue> = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: item.name };
                listLanguages.push(keyValueElement);
            }

            if (!rowsFormDesingner.recordLocalizedModel)
                rowsFormDesingner.recordLocalizedModel =
                    new Array<RecordLocalizedModel>();

            let listFormName = [...rowsFormDesingner.recordLocalizedModel];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: RecordLocalizedModel = {
                    languageId: item.id,
                    localizedValue: "",
                    order: i,
                };
                listFormName.push(keyValueElement);
            }
            rowsFormDesingner.recordLocalizedModel = listFormName;
            handleEditForm(rowsFormDesingner);

            if (listLanguages) {
                listLanguages.unshift({ key: "", value: "--" });
                let language = TPGlobal.language;
                setBaseLanguageOptions(listLanguages);
            }
        };

        const {
            isOpen: isOpenModalLanguageList,
            openModal: handleOpenModalLanguageList,
            closeModal: handleCloseModalLanguageList,
            saveChanges: handleSaveChangesModalLanguageList,
        } = useModal(false);

        useEffect(() => {
            if (mode == "Insert" && referenceId == "" && rowsFormDesingner.id == "") {
                generalAutomaticId();
            }

            loadResourcesAndLoadInfo();
            loadLanguages();
            loadFormType();
        }, []);

        return (
            <>
                <ContainerPreferences>
                    <div style={{ height: "30px" }}>
                        <ContainerNotification color="#D3E1FC">
                            <TPIcon iconType={TPIconTypes.info} style={{}} />
                            <TPLabel
                                className="form-check-label"
                                labelText={completeThisinformationLabel}
                                style={{ cursor: "ponter" }}
                            />
                        </ContainerNotification>
                    </div>
                    <br></br>
                    <div style={{ width: "554px" }}>
                        <TPLabel
                            className="form-check-label"
                            style={{ textAlign: "left", width: "100%" }}
                            labelText={idLabel}
                        />

                        <TPTextBox
                            id="IdTextTitle"
                            disabled={true}
                            containerStyle={{ width: "550px", gap: "0px" }}
                            textStyle={{ width: "550px" }}
                            icon={TPIconTypes.refresh}
                            withIcon={false}
                            value={rowsFormDesingner.id}
                            placeholder={""}
                            onChange={(e: any) => {
                                let temp = { ...rowsFormDesingner };
                                temp.id = e.target.value;
                                handleEditForm(temp);
                            }}
                            isHorizontal={true}
                        />
                    </div>

                    <TPSelect
                        id="IdSelect"
                        containerStyle={{ width: "100%" }}
                        selectStyle={{ width: "550px" }}
                        onChange={(e: any) => {
                            let temp = { ...rowsFormDesingner };
                            let key = e.target.value;
                            let list = [...baseLanguageOptions];

                            if (list != undefined) {
                                let language = list.find((s) => s.key == key + "");

                                if (language) temp.primaryLanguage = language;
                                handleEditForm(temp);
                            }
                        }}
                        dataSource={baseLanguageOptions}
                        value={
                            rowsFormDesingner.primaryLanguage &&
                                rowsFormDesingner.primaryLanguage.key
                                ? rowsFormDesingner.primaryLanguage.key
                                : ""
                        }
                        labelText={primaryLanguageLabel}
                        isHorizontal={false}
                        isMandatory={true}
                        errorMessage={baseLanguageOptionsError}
                    ></TPSelect>

                    <div style={{ width: "100%", display: "flex", alignItems: "end" }}>
                        {rowsFormDesingner.recordLocalizedModel &&
                            rowsFormDesingner.recordLocalizedModel.length > 0 &&
                            TPGlobal.TPClientAvailableLanguages.map(
                                (item, index) =>
                                    index === 0 && (
                                        <div key={`languageItem-${item.id}`}>
                                            <div className="form-group">
                                                <TPTextBox
                                                    id="IdTextBox"
                                                    textStyle={{ width: "550px" }}
                                                    containerStyle={{ width: "100%" }}
                                                    isMandatory={index === 0}
                                                    labelText={`${nameLabel} (${item.name})`}
                                                    value={
                                                        rowsFormDesingner.recordLocalizedModel.find(
                                                            (s) => s.languageId == item.id,
                                                        )?.localizedValue
                                                    }
                                                    onChange={(e: any) => {
                                                        let temp = { ...rowsFormDesingner };
                                                        let newItem =
                                                            rowsFormDesingner.recordLocalizedModel.find(
                                                                (s) => s.languageId == item.id,
                                                            );
                                                        if (newItem)
                                                            newItem.localizedValue = e.target.value;
                                                        handleEditForm(temp);
                                                    }}
                                                    maxLength={100}
                                                    errorMessage={recordLocalizedModelError}
                                                />
                                            </div>
                                        </div>
                                    ),
                            )}
                        {rowsFormDesingner.recordLocalizedModel &&
                            rowsFormDesingner.recordLocalizedModel.length > 1 && (
                                <>
                                    <TPButton
                                        id="IdButton"
                                        type={TPButtonTypes.icon}
                                        icon={TPIconTypes.language}
                                        text={`+${baseLanguageOptions.length - 1}`}
                                        tooltip={languageListLabel}
                                        className={"pt-3"}
                                        onClick={handleOpenModalLanguageList}
                                    />

                                    <TPModalLanguageList
                                        id="IdModalLanguageList"
                                        isOpen={isOpenModalLanguageList}
                                        title={languageListLabel}
                                        acceptLabel={saveButtonModalLabel}
                                        cancelLabel={cancelButtonModalLabel}
                                        saveChanges={handleSaveChangesModalLanguageList}
                                        closeModal={handleCloseModalLanguageList}
                                    >
                                        <div
                                            className="row overflow-auto"
                                            style={{ height: "200px" }}
                                        >
                                            {TPGlobal.TPClientAvailableLanguages.map(
                                                (item, index) =>
                                                    index > 0 && (
                                                        <div
                                                            className="col-12"
                                                            key={`languageItem-${item.id}`}
                                                        >
                                                            <div className="form-group">
                                                                <TPTextBox
                                                                    id="IdTextBox"
                                                                    isMandatory={index === 0}
                                                                    labelText={`${nameLabel} (${item.name})`}
                                                                    value={
                                                                        rowsFormDesingner.recordLocalizedModel.find(
                                                                            (s) => s.languageId == item.id,
                                                                        )?.localizedValue
                                                                    }
                                                                    onChange={(e: any) => {
                                                                        let temp = { ...rowsFormDesingner };
                                                                        let newItem =
                                                                            rowsFormDesingner.recordLocalizedModel.find(
                                                                                (s) => s.languageId == item.id,
                                                                            );
                                                                        if (newItem)
                                                                            newItem.localizedValue = e.target.value;
                                                                        /// rowsFormDesingner.description = e.target.value;
                                                                        handleEditForm(temp);
                                                                    }}
                                                                    containerStyle={{ with: "100%" }}
                                                                    maxLength={100}
                                                                    errorMessage={""}
                                                                />
                                                            </div>
                                                        </div>
                                                    ),
                                            )}
                                        </div>
                                    </TPModalLanguageList>
                                </>
                            )}
                    </div>

                    <TPSelect
                        id="IdSelect"
                        containerStyle={{ width: "550px", gap: "0px" }}
                        onChange={(e: any) => {
                            let temp = { ...rowsFormDesingner };
                            if (temp) temp.formType = { key: e.target.value, value: "" };
                            handleEditForm(temp);
                        }}
                        dataSource={formTypeOptions}
                        value={
                            rowsFormDesingner.formType && rowsFormDesingner.formType.key
                                ? rowsFormDesingner.formType.key
                                : ""
                        }
                        labelText={formTypeLabel}
                        isHorizontal={false}
                        isMandatory={true}
                        errorMessage={formTypeError}
                    ></TPSelect>

                    {/* 
          <TPTextArea
            id="IdTextArea"
            textAreaStyle={{ width: "550px" }}
            containerStyle={{ width: "550px" }}
            onChange={(e: any) => {
              let value = e.target.value;
              rowsFormDesingner.description = value;
              handleEditForm(rowsFormDesingner);
            }
            }
            value={rowsFormDesingner.description}
            isMandatory={true}
            labelText={descriptionLabel}
            errorMessage={""}
            rows={4}
          />

 */}

                    <TPPageSection style={{ display: "none" }}>
                        <div className="form-group">
                            <TPTextBox
                                id="IdTextBox"
                                labelText={imageLabel}
                                value={
                                    rowsFormDesingner.img && rowsFormDesingner.img.value
                                        ? rowsFormDesingner.img.value
                                        : ""
                                }
                                isMandatory={true}
                                errorMessage={""}
                                maxLength={20}
                                onChange={(e: any) => TPGlobal.foo()}
                                withIcon={true}
                                icon={TPIconTypes.image}
                                iconClick={handleShowModal}
                                disabled
                            />
                        </div>
                    </TPPageSection>

                    <TPCheckBox
                        id="IdIsMandatory"
                        labelText={showAsActiveLabel}
                        checked={rowsFormDesingner.showAsActive}
                        onChange={(e: any) => {
                            let temp = { ...rowsFormDesingner };
                            temp.showAsActive = !temp.showAsActive;
                            handleEditForm(temp);
                        }}
                    ></TPCheckBox>

                    <TPCheckBox
                        id="IdIsMandatory"
                        labelText={isHidePersonalDataLabel}
                        checked={rowsFormDesingner.isHidePersonalData}
                        onChange={(e: any) => {
                            let temp = { ...rowsFormDesingner };
                            temp.isHidePersonalData = !temp.isHidePersonalData;
                            handleEditForm(temp);
                        }}
                    ></TPCheckBox>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "50px",
                            width: "550px",
                            alignItems: "end",
                        }}
                    >
                        <TPButton
                            style={
                                !handleActiveSave()
                                    ? {
                                        backgroundColor: "#E6E6E6",
                                        borderRadius: "4px",
                                        marginRight: "10px",
                                        color: "#989898",
                                        padding: "5px",
                                    }
                                    : {
                                        backgroundColor: "#A00095",
                                        borderRadius: "4px",
                                        marginRight: "10px",
                                        color: "white",
                                        padding: "5px",
                                    }
                            }
                            type={TPButtonTypes.empty}
                            text={"1"}
                            onClick={(e: any) => {
                                Validate();
                            }}
                            icon={TPIconTypes.default}
                        >
                            {saveLabel}
                        </TPButton>
                    </div>
                </ContainerPreferences>
            </>
        );
    },
);

export default FormDesignerPreferences;
