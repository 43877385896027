import React, { FC } from "react";
// import { mockFiles } from "./files";
import folder from "./img/folder.png";
import fileImagePNG from "./img/file.png";
import { HierarchyFoldersViewModel } from "@/models/HierarchyFolders/HierarchyFoldersModels";
import { HierarchyFolderTypeService } from "@/services/HierarchyFolderService";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPI18N } from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import TPButton from "../bootstrap/components/buttons/TPButton";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";

//TODO: Styles to themes

interface breadcrumb {
  id: string | null;
  description: string;
}

interface anchor {
  x: number;
  y: number;
}

interface imagesAdminProps {
  onSelect: Function;
  topic: string;
}

type selectedFileType = {
  fileName: string;
  fileGuid: string;
  hierarchyId: string;
};

const TPImagesAdmin: FC<imagesAdminProps> = ({ onSelect, topic }) => {
  const componentFileName: string = "TPImagesAdmin.tsx";
  const [isLoadingScreen, setIsLoadingScreen] = React.useState(false);
  const [files, setFiles] = React.useState<HierarchyFoldersViewModel[]>([]);
  const [currentFolder, setCurrentFolder] = React.useState<
    HierarchyFoldersViewModel[]
  >([]);
  const [parent, setParent] = React.useState<string | null>(null);
  const initialStateSelectedFile: selectedFileType = {
    fileName: "",
    fileGuid: "",
    hierarchyId: "",
  };
  const [selectedFile, setSelectedFile] = React.useState<selectedFileType>(
    initialStateSelectedFile,
  );
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [homeLabel, setHomeLabel] = React.useState("");
  const [breadcrumbArray, setBreadcrumbArray] = React.useState<breadcrumb[]>(
    [],
  );

  //load resources

  const loadResources = async () => {
    setHomeLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "HomeLabel"));
    let initialBreadcrumb: breadcrumb = {
      id: null,
      description: await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "HomeLabel",
      ),
    };
    setBreadcrumbArray([initialBreadcrumb]);
  };

  const getFilesFromServer = async () => {
    let serviceClient = new HierarchyFolderTypeService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getHierarchyFolderByTopic(
        topic,
        false, //show positive message
        true, //show negative message
        expectedCodes,
      );
      setIsLoadingScreen(false);
      return [...responseRequest];
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFilesFromServer ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getFilesFromServer ex`);
      setIsLoadingScreen(false);
      return [];
    }
  };

  const filesSetter = () => {
    getFilesFromServer()
      .then(function (result) {
        setFiles(result);
      })
      .catch(function (error) {
        TPLog.Log(
          `Error ${componentFileName} filesSetter ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} filesSetter ex`);
      });
  };

  let showingFolder: HierarchyFoldersViewModel[];
  const mapFolder = () => {
    showingFolder = files.filter((file) => file.parentId === parent);
    setCurrentFolder(showingFolder);
  };

  const handleOpenFile = (
    e: React.MouseEvent<HTMLButtonElement>,
    file: HierarchyFoldersViewModel,
  ) => {
    if (file.typeId.toUpperCase() === "S_HIFOFOLDER") {
      setParent(file.id);
      setBreadcrumbArray([
        ...breadcrumbArray,
        { id: file.id, description: file.description },
      ]);
    } else {
      let newSelectedFile: selectedFileType = {
        fileName: file.filename || file.description,
        fileGuid: file.fileId,
        hierarchyId: file.id,
      };
      setSelectedFile(newSelectedFile);
      onSelect(newSelectedFile);
    }
  };

  const handleBreadcrumbClick = (id: string | null, key: number) => {
    setParent(id);
    onSelect(initialStateSelectedFile);
    setSelectedFile(initialStateSelectedFile);
    const newBreadcrumbArray: breadcrumb[] = breadcrumbArray.slice(0, key + 1);
    setBreadcrumbArray(newBreadcrumbArray);
    mapFolder();
  };

  const fileImage = (type: string) => {
    switch (type) {
      case "S_HIFOFOLDER":
        return folder;
      case "S_HIFOIMAGE":
        return fileImagePNG;
    }
  };

  React.useEffect(() => {
    loadResources();
    filesSetter();
  }, []);

  React.useEffect(() => {
    mapFolder();
  }, [parent, files]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "left",
          width: "100%",
          margin: "0 auto",
          columnGap: "2vw",
          rowGap: "3vw",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          {breadcrumbArray.map((item, index, itemsArray) => {
            return (
              <div key={`breadcrumb ${index}`}>
                <TPButton
                  type={TPButtonTypes.link}
                  onClick={() => handleBreadcrumbClick(item.id, index)}
                  className={"tp-bread-crumb"}
                >
                  {item.description}
                </TPButton>
                &nbsp;
                {index !== itemsArray.length - 1 ? "/" : null}
                &nbsp;
              </div>
            );
          })}
        </div>
        {currentFolder.map((file, index) => {
          let fileBackground: string =
            selectedFile.hierarchyId === file.id ? "gray" : "white";
          return (
            <div key={index}>
              <button
                // className={styles.file}
                style={{
                  width: "200px",
                  height: "150px",
                  border: "1px solid #ccc",
                  backgroundColor: fileBackground,
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  userSelect: "none",
                }}
                onClick={(e) => handleOpenFile(e, file)}
              >
                <img
                  style={{ width: "30%" }}
                  src={
                    file.typeId.toUpperCase() === "S_HIFOIMAGE"
                      ? file.thumbnail
                        ? file.thumbnail
                        : fileImage(file.typeId)
                      : fileImage(file.typeId)
                  }
                  alt=""
                />
                {/* <div>{file.id}</div> */}
                <div>{file.filename ? file.filename : file.description}</div>
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TPImagesAdmin;
