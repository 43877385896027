import React, {CSSProperties, useEffect, useState} from "react";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import {
    ProjectDetailEnum as e,
    ProjectDetailModel,
    ProjectModel,
    ProjectType,
    TableProjectDetailModel
} from "@/models/Project/Projects";
import {TPI18N} from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import {EventProjectService} from "@/services/EventProjectService";
import {TPPageTitle} from "@/components/TPPage/tpPageStyles";
import ProjectDetailTable from "@/pages/Projects/ProjectDetail/ProjectDetailTable";
import ProjectDetailCards from "@/pages/Projects/ProjectDetail/ProjectDetailCards";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {TPButtonTypes, TPIconTypes} from "@/models/Global/TPGlobalEnums";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import {TPKeyValue} from "@/helpers/TPKeyValue";

enum ProjectDetailMode {
    TABLE = 'TABLE',
    CARDS = 'CARDS',
}

interface ProjectDetailProps {
    tabId: string;
    element: ProjectModel | any;
    dispatch: Function;
}

export default function ProjectDetail({tabId, element, dispatch}: ProjectDetailProps) {
    const [m, setMessages] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState<ProjectDetailMode>(ProjectDetailMode.TABLE);
    const [inputSearch, setInputSearch] = useState<string>('');
    const [downloadIcon, setDownloadIcon] = useState(false);
    const [sorts, setSorts] = useState<Array<TPKeyValue>>([
        {value: "Sort By", key: ""},
        {value: "More events", key: "1"},
        {value: "More customer experts ready", key: "3"},
        {value: "More pending", key: "5"},
        {value: "More worked", key: "7"},
        {value: "Less events", key: "2"},
        {value: "Less customer experts ready", key: "4"},
        {value: "Less pending", key: "6"},
        {value: "Less worked", key: "8"},
        {value: "A - Z", key: "9"},
        {value: "Z - A", key: "10"},
    ]);
    const [globalReport, setGlobalReport] = useState<ProjectDetailModel | any>();
    const [datasource, setDatasource] = useState<Array<TableProjectDetailModel>>([]);
    const [queues, setQueues] = useState<Array<TPKeyValue>>([{key: "", value: ""}]);
    const [selectSort, setSelectSort] = useState<string>("");

    useEffect(() => {
        loadResources().then(() => setLoading(false))
        loadDatasource()
    }, []);

    async function loadResources() {
        const messages = {...m};
        // title label
        messages[e.TitleLabel] = 'Project detail:'// await TPI18N.GetText(e.ProjectDetailComponent, e.TitleLabel);
        // section action labels
        messages[e.ActionTooltipModeTableLabel] = 'Table mode'// await TPI18N.GetText(e.ProjectDetailComponent, e.ActionTooltipModeTableLabel);
        messages[e.ActionTooltipModeCardsLabel] = 'Card mode'// await TPI18N.GetText(e.ProjectDetailComponent, e.ActionTooltipModeCardsLabel);
        messages[e.ActionInputSearchLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.ActionInputSearchLabel);
        messages[e.ActionTooltipDownloadLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.ActionTooltipDownloadLabel);
        messages[e.ActionTooltipRefreshLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.ActionTooltipRefreshLabel);
        messages[e.ActionInputSortLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.ActionInputSortLabel);
        messages[e.ActionButtonLoadNewFileLabel] = 'Load new File'// await TPI18N.GetText(e.ProjectDetailComponent, e.ActionButtonLoadNewFileLabel);
        // global report labels
        messages[e.GlobalReportLabel] = 'Global report:'// await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportLabel);
        messages[e.GlobalReportEventsLabel] = 'Events:'// await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportRecordsLabel);
        messages[e.GlobalReportWorkedLabel] = 'Worked:'// await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportWorkedLabel);
        messages[e.GlobalReportPendingLabel] = 'Pending:'// await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportPendingLabel);
        messages[e.GlobalReportTotalQueuesLabel] = 'Total Queues:'// await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportTotalQueuesLabel);
        messages[e.GlobalReportCustomerExpertsAvailableLabel] = 'Customer experts available:'// await TPI18N.GetText(e.ProjectDetailComponent, e.GlobalReportCustomerExpertsAvailableLabel);
        // table and cards labels
        messages[e.CustomerExpertsLabel] = 'CUSTOMER EXPERTS'// await TPI18N.GetText(e.ProjectDetailComponent, e.CustomerExpetsLabel);
        messages[e.TicketsLabel] = 'TICKETS'// await TPI18N.GetText(e.ProjectDetailComponent, e.TicketsLabel);
        messages[e.TableColumnQueueNameLabel] = 'QUEUE NAME'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnQueueNameLabel);
        messages[e.TableColumnTotalCELabel] = 'TOTAL CE'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnTotalCELabel);
        messages[e.TableColumnCEReadyLabel] = 'CE READY'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnCEReadyLabel);
        messages[e.TableColumnOnlineLabel] = 'ONLINE'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnOnlineLabel);
        messages[e.TableColumnWorkingLabel] = 'WORKING'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnWorkingLabel);
        messages[e.TableColumnOfflineLabel] = 'OFFLINE'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnOfflineLabel);
        messages[e.TableColumnEventsLabel] = 'EVENTS'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnEventsLabel);
        messages[e.TableColumnWorkedLabel] = 'WORKED'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnWorkedLabel);
        messages[e.TableColumnPendingLabel] = 'PENDING'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnPendingLabel);
        messages[e.TableColumnTypeLabel] = 'TYPE'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnTypeLabel);
        messages[e.TableColumnActionReassignCELabel] = 'Reassign CE'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnActionReassignCELabel);
        messages[e.TableColumnActionReassignCELabel] = 'Reassign CE'// await TPI18N.GetText(e.ProjectDetailComponent, e.TableColumnActionReassignCELabel);
        // modal labels
        messages[e.ModalTitleLabel] = 'Reassign Customer Experts to a new queue'// await TPI18N.GetText(e.ProjectDetailComponent, e.ModalTitleLabel);
        messages[e.ModalCurrentQueueLabel] = 'Current queue:'// await TPI18N.GetText(e.ProjectDetailComponent, e.ModalCurrentQueueLabel);
        messages[e.ModalOption1Label] = '1. Select the new QUEUE:'// await TPI18N.GetText(e.ProjectDetailComponent, e.ModalOption1Label);
        messages[e.ModalOption2Label] = '2. Select the users to reassign:'// await TPI18N.GetText(e.ProjectDetailComponent, e.ModalOption2Label);
        messages[e.ModalActionCancelLabel] = 'Cancel'// await TPI18N.GetText(e.ProjectDetailComponent, e.ModalActionCancelLabel);
        messages[e.ModalActionReassignLabel] = 'Reassign'// await TPI18N.GetText(e.ProjectDetailComponent, e.ModalActionReassignLabel);
        messages[e.ModalActionNextPageLabel] = 'Next Page'// await TPI18N.GetText(e.ProjectDetailComponent, e.ModalActionReassignLabel);
        messages[e.ModalActionSelectAllLabel] = 'Select all'// await TPI18N.GetText(e.ProjectDetailComponent, e.ModalActionReassignLabel);

        setMessages(messages)

        const {findGroups} = new EventProjectService()

        findGroups(element.id)
            .then(response => {
                setQueues([
                    {key: "", value: ""},
                    ...response.map(({id, name: value}) => ({key: `${id}`, value}))
                ])
            })
            .catch(error => console.error(error))
    }

    function loadDatasource(sort?: string) {
        const {findDetail} = new EventProjectService()

        setLoading(true)
        findDetail(element.id)
            .then(response => {
                setLoading(false)

                let d = response.map(r => ({
                    ...r,
                    reassign: "",
                } as TableProjectDetailModel));

                switch (sort) {
                    case "1":
                        d.sort(({events: a}, {events: b}) => Number(a) < Number(b) ? 1 : Number(a) > Number(b) ? -1 : 0);
                        break;
                    case "2":
                        d.sort(({events: a}, {events: b}) => Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0);
                        break;
                    case "3":
                        d.sort(({ceReady: a}, {ceReady: b}) => Number(a) < Number(b) ? 1 : Number(a) > Number(b) ? -1 : 0);
                        break;
                    case "4":
                        d.sort(({ceReady: a}, {ceReady: b}) => Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0);
                        break;
                    case "5":
                        d.sort(({pending: a}, {pending: b}) => Number(a) < Number(b) ? 1 : Number(a) > Number(b) ? -1 : 0);
                        break;
                    case "6":
                        d.sort(({pending: a}, {pending: b}) => Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0);
                        break;
                    case "7":
                        d.sort(({worked: a}, {worked: b}) => Number(a) < Number(b) ? 1 : Number(a) > Number(b) ? -1 : 0);
                        break;
                    case "8":
                        d.sort(({worked: a}, {worked: b}) => Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0);
                        break;
                    case "9":
                        d.sort(({queueName: a}, {queueName: b}) => a > b ? 1 : a < b ? -1 : 0);
                        break;
                    case "10":
                        d.sort(({queueName: a}, {queueName: b}) => a < b ? 1 : a > b ? -1 : 0);
                        break;
                    default:
                        break
                }
 
                setDatasource(d)

                const g = response.reduce(
                    (p, c) => {
                        p.totalCe = `${Number(p.totalCe) + Number(c.totalCe)}`;
                        p.ceReady = `${Number(p.ceReady) + Number(c.ceReady)}`;
                        p.online = `${Number(p.online) + Number(c.online)}`;
                        p.working = `${Number(p.working) + Number(c.working)}`;
                        p.offline = `${Number(p.offline) + Number(c.offline)}`;
                        p.events = `${Number(p.events) + Number(c.events)}`;
                        p.worked = `${Number(p.worked) + Number(c.worked)}`;
                        p.pending = `${Number(p.pending) + Number(c.pending)}`;
                        return p;
                    },
                    {
                        queueId: '0',
                        queueName: '0',
                        totalCe: '0',
                        ceReady: '0',
                        online: '0',
                        working: '0',
                        offline: '0',
                        events: '0',
                        worked: '0',
                        pending: '0',
                        type: ProjectType.FRONTOFFICE,
                    } as ProjectDetailModel
                )
                setGlobalReport(g)
            })
            .catch(error => {
                setLoading(false)
                console.error(error)
            })
    }

    function onClickIconButtonSwitchModeHandler(m: ProjectDetailMode) {
        setMode(m)
    }

    function onChangeInputHandler(value: string) {
        setInputSearch(value)
    }

    function onChangeSelectHandler(value: string) {
        setSelectSort(value)
        loadDatasource(value)
    }

    function onClickIconButtonDownloadHandler() {
        setDownloadIcon(!downloadIcon)
    }

    function onClickIconButtonRefreshHandler() {
        loadDatasource()
    }

    function onClickButtonLoadNewFileHandler() {
        console.log('opened load new file modal')
    }

    return (
        <TPLoadingOverlay active={loading}>
            <div style={styles.column}>
                <TPPageTitle style={styles.title}>
                    {m?.[e.TitleLabel]} {element.name}
                </TPPageTitle>

                <div style={styles.actionSection}>
                    <div style={styles.gap}>
                        <div style={styles.row}>
                            <TPButton
                                id="actionIconButtonModeCards"
                                key="actionIconButtonModeCards"
                                style={{
                                    ...styles.iconButton,
                                    backgroundColor: mode === ProjectDetailMode.CARDS ? "#8e008c" : "#f4f4f4",
                                }}
                                tooltip={m?.[e.ActionTooltipModeCardsLabel]}
                                onClick={() => onClickIconButtonSwitchModeHandler(ProjectDetailMode.CARDS)}
                            >
                                <TPIcon
                                    iconType={TPIconTypes.cardsView}
                                    style={{
                                        ...styles.cardIcon,
                                        color: mode === ProjectDetailMode.CARDS ? "white" : "black",
                                    }}
                                />
                            </TPButton>

                            <TPButton
                                id="actionIconButtonModeTable"
                                key="actionIconButtonModeTable"
                                style={{
                                    ...styles.iconButton,
                                    backgroundColor: mode === ProjectDetailMode.TABLE ? "#8e008c" : "#f4f4f4",
                                }}
                                tooltip={m?.[e.ActionTooltipModeTableLabel]}
                                onClick={() => onClickIconButtonSwitchModeHandler(ProjectDetailMode.TABLE)}
                            >
                                <TPIcon
                                    iconType={TPIconTypes.tableView}
                                    style={{
                                        ...styles.tableIcon,
                                        color: mode === ProjectDetailMode.TABLE ? "white" : "black",
                                    }}
                                />
                            </TPButton>
                        </div>

                        <TPTextBox
                            id="actionInputSearch"
                            key="actionInputSearch"
                            containerStyle={styles.input}
                            icon={TPIconTypes.search}
                            withIcon={true}
                            isHorizontal={true}
                            value={`${inputSearch ? inputSearch : ''}`}
                            placeholder={m?.[e.ActionInputSearchLabel]}
                            onChange={({target}: any) => onChangeInputHandler(target.value)}
                        />
                    </div>

                    <div style={styles.gap}>
                        <TPButton
                            id="actionIconButtonDownload"
                            key="actionIconButtonDownload"
                            type={TPButtonTypes.icon}
                            icon={TPIconTypes.downloadTemplate}
                            tooltip={m?.[e.ActionTooltipDownloadLabel]}
                            onClick={onClickIconButtonDownloadHandler}
                        />

                        <TPButton
                            id="actionIconButtonRefresh"
                            key="actionIconButtonRefresh"
                            iconStyle={styles.iconButtonRefresh}
                            type={TPButtonTypes.icon}
                            icon={TPIconTypes.refreshCircle}
                            tooltip={m?.[e.ActionTooltipRefreshLabel]}
                            onClick={onClickIconButtonRefreshHandler}
                        />

                        <TPSelect
                            id="actionInputSort"
                            key="actionInputSort"
                            labelStyle={{display: "none"}}
                            containerStyle={styles.input}
                            dataSource={sorts}
                            value={`${selectSort ? selectSort : ''}`}
                            onChange={({target}: any) => onChangeSelectHandler(target.value)}
                        />

                        <TPButton
                            id="actionButtonLoadNewFile"
                            key="actionButtonLoadNewFile"
                            style={styles.newButton}
                            onClick={onClickButtonLoadNewFileHandler}>
                            {m?.[e.ActionButtonLoadNewFileLabel]}
                        </TPButton>
                    </div>
                </div>

                <div style={styles.globalReportSection}>
                    <p style={styles.globalReportLabel}>
                        {m?.[e.GlobalReportLabel]}
                    </p>

                    <div style={styles.report}>
                        <p style={styles.reportLabel}>
                            {m?.[e.GlobalReportTotalQueuesLabel]} {globalReport?.totalCe}
                        </p>

                        <p style={styles.reportLabel}>
                            {m?.[e.GlobalReportEventsLabel]} {globalReport?.events}
                        </p>

                        <p style={styles.reportLabel}>
                            {m?.[e.GlobalReportWorkedLabel]} {globalReport?.worked}
                        </p>

                        <p style={styles.reportLabel}>
                            {m?.[e.GlobalReportPendingLabel]} {globalReport?.pending}
                        </p>

                        <p style={styles.reportLabel}>
                            {m?.[e.GlobalReportCustomerExpertsAvailableLabel]} {globalReport?.ceReady}
                        </p>
                    </div>
                </div>

                <div style={{display: mode === ProjectDetailMode.TABLE ? "block" : "none"}}>
                    <ProjectDetailTable
                        m={m}
                        queues={queues}
                        datasource={datasource}
                        loadData={loadDatasource}
                        inputSearch={inputSearch}
                        downloadIcon={downloadIcon}
                    />
                </div>

                {mode === ProjectDetailMode.CARDS ? (
                    <ProjectDetailCards
                        m={m}
                        queues={queues}
                        datasource={datasource}
                        inputSearch={inputSearch}
                    />
                ) : <div>invalid</div>}
            </div>
        </TPLoadingOverlay>
    );
}

const styles = {
    column: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'column',
    } as CSSProperties,
    row: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
    } as CSSProperties,
    title: {
        marginTop: "0",
    } as CSSProperties,
    actionSection: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        gap: "10px",
        placeContent: "center space-between",
        alignItems: "center",
    } as CSSProperties,
    gap: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        gap: "10px",
    } as CSSProperties,
    cardIcon: {
        fontSize: "21px",
    } as CSSProperties,
    tableIcon: {
        fontSize: "29px",
    } as CSSProperties,
    iconButton: {
        minWidth: "35px",
        minHeight: "35px",
        width: "35px",
        height: "35px",
        padding: "0",
        border: "none",
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        placeContent: "center",
        alignItems: "center",
    } as CSSProperties,
    input: {
        width: "250px",
    } as CSSProperties,
    iconButtonRefresh: {
        transform: "rotateZ(74deg)",
    } as CSSProperties,
    newButton: {
        padding: "5px 20px",
        backgroundColor: "#A00095",
        borderRadius: 4,
        color: "white",
        fontWeight: 400,
    } as CSSProperties,
    globalReportSection: {
        margin: "20px 0",
        padding: "10px",
        backgroundColor: "#ebf2ff",
        borderRadius: "5px",
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        gap: "10px",
    } as CSSProperties,
    globalReportLabel: {
        margin: "0",
        fontSize: "13px",
        fontWeight: "bold",
    } as CSSProperties,
    reportLabel: {
        margin: "0",
        fontSize: "13px",
        fontWeight: "600",
    } as CSSProperties,
    report: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        gap: "10px",
        placeContent: "center space-around",
        alignItems: "center",
        flex: "1 1 0%",
    } as CSSProperties,
};
