import styled from "styled-components";

const headerHeight = "120px";
const spaceBetweenSearchAndContent = "20px";
const leftMenuWidth = "250px";
const bottomHeight = "60px";

export const TPConfigValueHeader = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  height: ${headerHeight};
  left: 22px;
  right: 10px;
`;

export const TPConfigValueHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TPConfigValueHeaderTitle = styled.div``;

export const TPConfigValueSearchBar = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: end;
`;

export const TPConfigKeyValueContainerStyled = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const MenuContainer = styled.div(
  ({ theme }) => `
  overflow-x: auto;
  position: absolute;
  top: ${headerHeight};
  bottom: 0;
  left: 22px;
  width: ${leftMenuWidth};
  padding: 10px;
  background-color: ${theme.colors.configValues.menu.backgroundColor};
  border: 1px solid gainsboro;
`,
);

export const MenuStyled = styled.ul`
  padding: 20px;
`;

export const CategoryItemContainer = styled.span(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  &:hover {
    color: ${theme.colors.configValues.category.itemContainer.hover.fontColor};
  }
  `,
);

export const MenuItemStyled = styled.li(
  ({ theme }) => `
  font-weight: 600;

  div {
    background-color: ${theme.colors.configValues.menu.item.backgroundColor};
    padding: 10px !important;
    width: 100%;
  }
`,
);

export const SubMenuStyled = styled.ul``;

export const SubMenuItemStyled = styled.li(
  ({ theme }) => `
  padding: 5px !important;
  padding-left: 22px !important;
  font-weight: 400;
  font-size: 12px;
  margin-left: 22px !important;
  margin-right: 22px !important;

  a {
    text-decoration: none;
    color: ${theme.colors.configValues.submenu.a.fontColor};
    word-break: break-word;
  }

  &:hover {
    background-color: ${theme.colors.configValues.submenu.hover.backgroundColor};
    border-radius: 5px;
    a {
      color: ${theme.colors.configValues.submenu.hover.a.fontColor};
    }
  }
`,
);

export const ContentContainer = styled.div(
  ({ theme }) => `
  overflow-x: auto;
  position: absolute;
  top: ${headerHeight};
  bottom: ${bottomHeight};
  left: calc(${leftMenuWidth} + ${spaceBetweenSearchAndContent});
  right: 0;
  border: 1px solid ${theme.colors.configValues.content.borderColor};
`,
);

export const CategoryContentContainer = styled.div``;

export const CategoryContentTitle = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.configValues.category.content.title.backgroundColor};
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 17px;
`,
);

interface CategoryContentBodyProps {
  collapsed: boolean;
}

export const CategoryContentBody = styled.div(
  ({ collapsed }: CategoryContentBodyProps) => {
    return `
     margin-left: 22px;
     display: ${collapsed ? "none" : "block"};

`;
  },
);

export const MenuContentContainer = styled.div``;

export const MenuContentTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const MenuContentDescription = styled.div`
  margin: 10px;
`;

export const MenuContentBody = styled.div`
  padding: 20px;
`;

export const FooterContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  bottom: 0;
  left: calc(${leftMenuWidth} + ${spaceBetweenSearchAndContent});
  top: calc(100% - ${bottomHeight});
  right: 0;
  padding: 10px;
  height: ${bottomHeight};
  border: 1px solid ${theme.colors.configValues.footer.borderColor};
`,
);

export const TPConfigKeyValueFieldsContainer = styled.div`
  background-color: red;
`;

export const TPStyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  display: block;
  max-height: 350px;
  overflow-y: auto;
  margin: 4px;
`;

export const TPStyledHead = styled.thead``;

export const TPStyledHeaderRow = styled.tr``;

export const TPStyledHeadCell = styled.th`
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
`;

export const TPStyledBody = styled.tbody``;

export const TPStyledBodyRow = styled.tr``;

export const TPStyledBodyCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

export const LanguageCountBadge = styled.div`
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: rgb(37, 41, 45);
  white-space: nowrap;
  background: #19875475;
  max-height: 24px;
  border-radius: 3px;
`;

export const TPStyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 168px;
`;
