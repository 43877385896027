import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";

export type RolesInputDTO = {
  id: string;
  roleGuid: string;
  description: string;
  isActive: boolean;
  descriptionLocalizedValues: Array<RecordLocalizedModel>;
};

export class RolesInputDTOValidator extends Validator<RolesInputDTO> {
  constructor() {
    super();

    const resourceSet = "RolesInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOEmptyName")
        .must((description) => {
          return TPGlobal.TPSanitize(description) == description;
        })
        .withMessage(resourceSet + "|InputDTOInvalidName")
        .maxLength(100)
        .withMessage(resourceSet + "|InputDTOInvalidNameLength");

      this.ruleForEach("descriptionLocalizedValues")
        .must((x) => {
          if (x.languageId !== TPGlobal.TPClientDefaultLanguage) {
            return true
          }

          return x.languageId === TPGlobal.TPClientDefaultLanguage && !!x.localizedValue ;
        })
        .withMessage("name is required")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescription")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 100;
        })
        .withMessage(resourceSet + "|InputDTOInvalidDescriptionLength");
    } catch (error) {}
  }
}
