import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { AdditionalDataViewModel } from "@/models/AdditionalData/AdditionalDataModels";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { TPI18N } from "@/services/I18nService";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";

import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./Styles.css";

type AdminStateType = {
  gridColumns: Array<any>;
  gridData: Array<AdditionalDataViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
  selectedRows: string[];
};

interface AdditionalDataAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "row_select" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

let modalQuestionInitialState: TPModalQuestionState = {
  isShown: false,
  callBackData: {},
};

const resourceSet: string = "AdditionalDataAdminComponent";

const AdditionalDataAdmin = React.forwardRef(
  ({ callBackCommands }: AdditionalDataAdminInterface, ref) => {
    const componentFileName: string = "AdditionalDataAdmin.tsx";
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [data, setData] = useState<any[]>([]);
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );
    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    const [titleLabel, setTitleLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [additionalBulkLoadLabel, setAdditionalBulkLoadLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [dataTypeColumnLabel, setDataTypeColumnLabel] = useState("");
    const [dataCategoryColumnLabel, setDataCategoryColumnLabel] = useState("");
    const [dataCommentsColumnLabel, setDataCommentsColumnLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemRecordColumnLabel] =
      useState("");
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");
    const [exportSelectionLabel, setExportSelectionLabel] = useState("");
    const [selectAllLabel, setSelectAllLabel] = useState("");

    const loadResourcesAndAdditionalDataFilter = async () => {
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setAdditionalBulkLoadLabel(
        await TPI18N.GetText(resourceSet, "AdditionalBulkLoadButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setDescriptionColumnLabel(
        await TPI18N.GetText(resourceSet, "Description"),
      );
      setDataTypeColumnLabel(await TPI18N.GetText(resourceSet, "DataType"));
      setDataCategoryColumnLabel(
        await TPI18N.GetText(resourceSet, "DataCategory"),
      );
      setDataCommentsColumnLabel(
        await TPI18N.GetText(resourceSet, "DataComments"),
      );
      setIsSystemRecordColumnLabel(
        await TPI18N.GetText(resourceSet, "isSystemRecordLabel"),
      );
      setSelectAllLabel(await TPI18N.GetText(resourceSet, "SelectAllLabel"));
      setExportSelectionLabel(
        await TPI18N.GetText(resourceSet, "ExportSelectionLabel"),
      );
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        //delete
        newColumns.push({
          name: "",
          width: "50px",
          style: { padding: 0 },
          center: true,
          cell: (row: { [x: string]: any }) => {
            if (!row["isSystemRecord"]) {
              return (
                <div className="dropdown">
                  <TPButton
                    id="IdButton"
                    dataBsToggle={true}
                    type={TPButtonTypes.empty}
                    onClick={() => {}}
                    className={"menu-button"}
                  >
                    <TPIcon iconType={TPIconTypes.moreVert} />
                  </TPButton>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        onClick={(id: any) => handleDeleteClick(row["id"])}
                        className="dropdown-item"
                        href="#"
                      >
                        {deleteLabel}
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={(id: any) => handleUpdateClick(row["id"])}
                        className="dropdown-item"
                        href="#"
                      >
                        {updateLabel}
                      </a>
                    </li>
                  </ul>
                </div>
              );
            } else {
              return null;
            }
          },
        });
        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) => {
            if (!row["isSystemRecord"]) {
              return (
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={(id: string) => handleUpdateClick(row["id"])}
                  className="update-button"
                >
                  <TPIcon iconType={TPIconTypes.chevronRight} />
                </TPButton>
              );
            } else {
              return null;
            }
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });
        const allRowsSelected =
          newState.selectedRows.length == data.length && data.length > 0;
        newColumns.push({
          name: (
            <div id="add-data-admin-select-all" className="checkbox-label">
              <TPCheckBox
                checked={allRowsSelected}
                onChange={() =>
                  dispatchCommand({
                    type: commandsEnum.row_select,
                    payload: allRowsSelected
                      ? []
                      : data.map((entry: any) => entry.id),
                  })
                }
                labelText={selectAllLabel}
              />
            </div>
          ),
          cell: (row: { [x: string]: any }) => {
            const selected = newState.selectedRows.includes(row["id"]);
            return (
              <div id={`add-data-admin-checkbox-${row["id"]}`}>
                <TPCheckBox
                  checked={selected || allRowsSelected}
                  onChange={() =>
                    dispatchCommand({
                      type: commandsEnum.row_select,
                      payload: selected
                        ? newState.selectedRows.filter(
                            (entry: string) => entry != row["id"],
                          )
                        : [...newState.selectedRows, row["id"]],
                    })
                  }
                />
              </div>
            );
          },
          selector: (row: { [x: string]: any }) => row["selected"],
          sortable: false,
        });
        //id
        newColumns.push({
          name: idColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["id"];
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });
        //Description primary language
        let languageColumnLabel: string;
        languageColumnLabel = descriptionColumnLabel;
        languageColumnLabel = (languageColumnLabel +
          " (" +
          TPGlobal.TPClientAvailableLanguages[0].name) as string;
        languageColumnLabel = languageColumnLabel + ")";
        newColumns.push({
          name: languageColumnLabel,
          selector: (row: { [x: string]: any }) => row["description"],
          sortable: true,
        });
        //description current language if it is different
        if (TPGlobal.TPClientAvailableLanguages[0].id !== TPGlobal.language) {
          languageColumnLabel = descriptionColumnLabel;
          languageColumnLabel = (languageColumnLabel +
            " (" +
            TPGlobal.getLanguageDescriptionByCode(TPGlobal.language)) as string;
          languageColumnLabel = languageColumnLabel + ")";
          newColumns.push({
            name: languageColumnLabel,
            selector: (row: { [x: string]: any }) =>
              row["localizedDescription"],
            sortable: true,
          });
        }
        // data type
        newColumns.push({
          name: dataTypeColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["additionalDataTypeDescription"];
          },
          selector: (row: { [x: string]: any }) =>
            row["additionalDataTypeDescription"],
          sortable: true,
        });

        // data category
        newColumns.push({
          name: dataCategoryColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["additionalDataCategoryDescription"];
          },
          selector: (row: { [x: string]: any }) =>
            row["additionalDataCategoryDescription"],
          sortable: true,
        });

        // data comments
        newColumns.push({
          name: dataCommentsColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["comments"];
          },
          selector: (row: { [x: string]: any }) => row["comments"],
          sortable: true,
        });

        //IsSystemRecord
        newColumns.push({
          name: isSystemRecordColumnLabel,
          selector: (row: { [x: string]: any }) => row["isSystemRecord"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isSystemRecord"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    //Get functions by Filter
    const reloadDataGrid = async () => {
      let serviceClient = new AdditionalDataService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getAll(
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid()
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result.map((entry: any) => {
              return { ...entry, selected: false };
            }),
          };
          setData(result);
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };
    //New Additional data
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update Additional data
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Modal Question to delete additional data
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete function after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new AdditionalDataService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "id",
        "description",
        "localizedDescription",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    //Modified code
    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            "id",
            "description",
            "validationURL",
            "comments",
            "additionalDataTypeId",
            "additionalDataTypeDescription",
            "jsonParameters",
            "categoryId",
            "additionalDataCategoryDescription",
          ],
        ],
        { origin: "A1" },
      );
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      gridColumns: [],
      gridData: [],
      searchPattern: "",
      selectedRows: [],
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        case commandsEnum.row_select:
          let newStateSelection: AdminStateType = setupGridColumns(prevState);
          newStateSelection.selectedRows = command.payload;
          return newStateSelection;
        default:
          return prevState;
      }
    }
    useEffect(() => {
      dispatchCommand({
        type: commandsEnum.row_select,
        payload: adminState.selectedRows,
      });
    }, [adminState.selectedRows]);
    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndAdditionalDataFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndAdditionalDataFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndAdditionalDataFilter ex`,
          );
        });
    }, []);

    //Additional Bulk Load Function
    const handleNewBulLoadTab = (payload?: any) => {
      callBackCommands({
        command: "new_additional_bulk_load",
        payload: payload,
      });
    };

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        />
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <TPPageFirstRow>
                <TPPageActions>
                  <TPButton
                    id="new-data"
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewClick()}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  <TPButton
                    id="refresh"
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    id="export"
                    type={TPButtonTypes.icon}
                    onClick={() =>
                      exportToCSV(filteredData(), "additional-data")
                    }
                    text={exportLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                  <TPButton
                    id="export-selected"
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewBulLoadTab(adminState.selectedRows)}
                    text={exportSelectionLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                  {/*<TPButton
                    id="load-data-button"
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewBulLoadTab()}
                    text={additionalBulkLoadLabel}
                    icon={TPIconTypes.fileDownload}
                  />*/}
                </TPPageActions>
                <TPFilterAndSearch>
                  <TPPageSearchContainer>
                    <TPTextBox
                      id="additional-data-search"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);
export default AdditionalDataAdmin;
