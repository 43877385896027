import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  SequeceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import {
  ProfilesInputDTO,
  ProfilesInputDTOValidator,
} from "@/models/Profiles/ProfilesInputDTO";
import { ProfilesViewModel } from "@/models/Profiles/ProfilesModels";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { ProfileService } from "@/services/ProfileService";
import { SequenceService } from "@/services/SequenceService";
import React, {
  FC,
  ReactElement,
  useState,
  useEffect,
  useReducer,
} from "react";
import { v4 as uuidv4 } from "uuid";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};

enum commandsEnum {
  "set_BaseProfileId" = 0,
  "set_IdProfile" = 1,
  "set_Errors" = 2,
  "set_NameLanguages" = 3,
  "set_UpdateData" = 4,
  "set_IsActive" = 5,
  "set_RequireSupplystationId" = 6,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

type InsertUpdateStateType = {
  id: string;
  recordLanguageList: Array<TPKeyValue>;
  isActive: boolean;
  baseProfileId: string;
  requireSupplystationId: boolean;
  description: string;
  defaultMenuItemId: string;

  //validator
  idErrorMessage: string;
  baseProfileIdErrorMessage: string;
  nameErrorMessages: Array<string>;
};

const ProfilesInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "ProfilesClone.tsx";

  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "ProfilesCloneComponent";
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [titleLabel, setTitleLabel] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [newIdCloneLabel, setNewIdCloneLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [supplyStationIdLabel, setSupplyStationIdColumnLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const [referenceId, setReferenceId] = useState("");

  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);

  // TODO Add another fields
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");

  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }

  //Initial State
  const initialStateBll: InsertUpdateStateType = {
    id: "",
    recordLanguageList: [],
    isActive: false,
    baseProfileId: "",
    requireSupplystationId: false,
    description: "",
    defaultMenuItemId: "NEWCASE",

    //validator
    idErrorMessage: "",
    baseProfileIdErrorMessage: "",
    nameErrorMessages: [],
  };

  //reducer definition
  const [insertUpdateState, dispatchCommand] = useReducer(
    doCommand,
    initialStateBll,
  );

  function doCommand(prevState: InsertUpdateStateType, command: commandType) {
    let newInsertUpdateState: InsertUpdateStateType;
    switch (command.type) {
      case commandsEnum.set_BaseProfileId:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.baseProfileId = command.payload.newBaseProfileId;
        newInsertUpdateState.isActive = command.payload.isActive;
        newInsertUpdateState.requireSupplystationId =
          command.payload.requireSupplystationId;
        newInsertUpdateState.description = "Admin xxx";
        newInsertUpdateState.defaultMenuItemId = "NEWCASE";
        newInsertUpdateState.baseProfileIdErrorMessage = "";
        return newInsertUpdateState;
      case commandsEnum.set_IdProfile:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.id = command.payload.id;
        newInsertUpdateState.idErrorMessage = "";
        return newInsertUpdateState;
      case commandsEnum.set_Errors:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.idErrorMessage = command.payload.idErrorMessage;
        newInsertUpdateState.baseProfileIdErrorMessage =
          command.payload.baseProfileIdErrorMessage;
        return newInsertUpdateState;
      case commandsEnum.set_NameLanguages:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.recordLanguageList =
          command.payload.recordLanguageList;
        newInsertUpdateState.nameErrorMessages =
          command.payload.nameErrorMessages;
        return newInsertUpdateState;
      case commandsEnum.set_UpdateData:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.baseProfileId = command.payload.baseProfileId;
        newInsertUpdateState.isActive = command.payload.isActive;
        newInsertUpdateState.requireSupplystationId =
          command.payload.requireSupplyStationId;
        newInsertUpdateState.id = command.payload.id;
        newInsertUpdateState.recordLanguageList =
          command.payload.recordLanguageList;
        newInsertUpdateState.description = command.payload.description;
        newInsertUpdateState.defaultMenuItemId =
          command.payload.defaultMenuItemId;
        return newInsertUpdateState;
      case commandsEnum.set_IsActive:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.isActive = command.payload.isActive;
        return newInsertUpdateState;
      case commandsEnum.set_RequireSupplystationId:
        newInsertUpdateState = { ...prevState };
        newInsertUpdateState.requireSupplystationId =
          command.payload.requireSupplystationId;
        return newInsertUpdateState;
      default:
        return prevState;
    }
  }

  const handleIsActiveChange = async (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
    let command1: commandType = {
      type: commandsEnum.set_IsActive,
      payload: { ...newInsertUpdateState },
    };
    dispatchCommand(command1);
  };

  const handleRequereSupplyIdChange = async (e: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.requireSupplystationId =
      !newInsertUpdateState.requireSupplystationId;
    let command1: commandType = {
      type: commandsEnum.set_RequireSupplystationId,
      payload: { ...newInsertUpdateState },
    };
    dispatchCommand(command1);
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "CANCEL", recordId: recordId });
  };

  const handleOkButtonClick = async () => {
    let i: number;
    let n: number;
    let recordInputDTO: ProfilesInputDTO = {
      // id: insertUpdateState.id,
      id: referenceId,
      guid: uuidv4(),
      isActive: insertUpdateState.isActive,
      profileId_Base: insertUpdateState.baseProfileId,
      nameLocalizedValues: [],
      description: insertUpdateState.recordLanguageList[0].value,
      defaultMenuItemId: "NEWCASE", //TODO
      requireSupplyStationId: insertUpdateState.requireSupplystationId,
    };

    n = insertUpdateState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = insertUpdateState.recordLanguageList[i];
      recordInputDTO.nameLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }

    let inputDTOValidator = new ProfilesInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      if (resultValidator.profileId_Base) {
        newInsertUpdateState.baseProfileIdErrorMessage =
          await TPI18N.GetResource(resultValidator.profileId_Base);
      } else {
        newInsertUpdateState.baseProfileIdErrorMessage = "";
      }
      if (resultValidator.description) {
        newInsertUpdateState.nameErrorMessages[0] = await TPI18N.GetResource(
          resultValidator.description,
        );
      } else {
        newInsertUpdateState.nameErrorMessages[0] = "";
      }
      if (resultValidator.nameLocalizedValues) {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.nameLocalizedValues[i] != null) {
            newInsertUpdateState.nameErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.nameLocalizedValues[i]),
              );
          }
        }
      } else {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.nameErrorMessages[i] = "";
        }
      }
      let command: commandType = {
        type: commandsEnum.set_Errors,
        payload: {
          ...newInsertUpdateState,
        },
      };
      dispatchCommand(command);
      return;
    }
    await cloneProfile(recordInputDTO);
  };

  const cloneProfile = async (inputDTO: ProfilesInputDTO) => {
    let serviceClient = new ProfileService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.cloneProfile(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "ok", recordId: recordId });

        //change mode
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleCloneLabel"));
        setRealRecordId(inputDTO.id);
        setRealMode("Clone");
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} cloneProfile ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} cloneProfile ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleOnIdProfileChange = (newIdProfile: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.id = newIdProfile;
    newInsertUpdateState.idErrorMessage = "";
    let command: commandType = {
      type: commandsEnum.set_IdProfile,
      payload: {
        ...newInsertUpdateState,
      },
    };
    dispatchCommand(command);
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.nameErrorMessages[index] = "";
    let command: commandType = {
      type: commandsEnum.set_NameLanguages,
      payload: {
        ...newInsertUpdateState,
      },
    };
    dispatchCommand(command);
  };

  //Multilanguage const
  const multilanguageTableName: String = "PROFiles";
  const multilanguageFieldName: String = "Description_PROF";

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const getProfileById = async (pRecordId: string) => {
    let serviceClient = new ProfileService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getProfileById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: ProfilesViewModel;
      recordInfo = { ...responseRequest };

      setOriginalRecordDescription(
        recordInfo.description.length <= 100
          ? recordInfo.description
          : recordInfo.description.substring(0, 100) + "...",
      );

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      newInsertUpdateState.isActive = recordInfo.isActive;
      //newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length === 0) {
        TPLog.Log(
          `Error ${componentFileName} getFunctionById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getFunctionById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ===
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            // newInsertUpdateState.recordLanguageList[i].value =
            //   recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      let command: commandType = {
        type: commandsEnum.set_UpdateData,
        payload: {
          baseProfileId: recordInfo.id,
          isActive: recordInfo.isActive,
          requireSupplyStationId: recordInfo.requireSupplyStationId,
          id: "",
          recordLanguageList: newInsertUpdateState.recordLanguageList,
        },
      };
      dispatchCommand(command);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getProfileById ex`,
        TPLogType.ERROR,
        error,
      );
      setIsLoadingScreen(false);
    }
  };

  const loadResourcesAndLoadProfileInfo = async () => {
    //resources state
    setTitleLabel(await TPI18N.GetText(resourceSet, "Title" + mode + "Label"));
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setNewIdCloneLabel(await TPI18N.GetText(resourceSet, "NewIdCloneLabel"));
    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
    setSupplyStationIdColumnLabel(
      await TPI18N.GetText(resourceSet, "RequireSupplyStationIdLabel"),
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );

    //screen state
    if (realMode === "Clone") {
      await getProfileById(realRecordId);
    }
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequeceGeneratorSequencesNameEnum.SQPROF,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadProfileInfo();
    generalAutomaticId();
  }, []);
  console.log("line 560>>>", mode, recordId);
  return (
    //#region  Render
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-6">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="NewIdCloneProfile"
                      labelText={newIdCloneLabel}
                      isMandatory={true}
                      value={
                        originalRecordDescription +
                        " (" +
                        insertUpdateState.baseProfileId +
                        ")"
                      }
                      onChange={() => TPGlobal.foo()}
                      maxLength={20}
                      disabled={true}
                      errorMessage={insertUpdateState.baseProfileIdErrorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={idLabel}
                      isMandatory={true}
                      // value={insertUpdateState.id}
                      value={referenceId}
                      onChange={(e: any) =>
                        handleOnIdProfileChange(e.target.value)
                      }
                      maxLength={20}
                      disabled={true}
                      errorMessage={insertUpdateState.idErrorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {insertUpdateState.recordLanguageList.length > 0 &&
                  TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                      index === 0 && (
                        <div className="col-6" key={`languageItem-${item.id}`}>
                          <div className="form-group">
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={index === 0}
                              labelText={`${nameLabel} (${item.name})`}
                              value={
                                insertUpdateState.recordLanguageList[index]
                                  .value
                              }
                              onChange={(e: any) =>
                                handleLanguageChange(index, e.target.value)
                              }
                              maxLength={100}
                              errorMessage={
                                insertUpdateState.nameErrorMessages[index]
                              }
                            />
                          </div>
                        </div>
                      ),
                  )}
                {insertUpdateState.recordLanguageList.length > 1 && (
                  <>
                    <div className="col-4">
                      <div className="pt-4">
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          icon={TPIconTypes.language}
                          text={`+${
                            insertUpdateState.recordLanguageList.length - 1
                          }`}
                          tooltip={languageListLabel}
                          className={"pt-3"}
                          onClick={handleOpenModalLanguageList}
                        />
                      </div>
                    </div>
                    <TPModalLanguageList
                      isOpen={isOpenModalLanguageList}
                      title={languageListLabel}
                      acceptLabel={saveButtonLabel}
                      cancelLabel={cancelButtonLabel}
                      saveChanges={handleSaveChangesModalLanguageList}
                      closeModal={handleCloseModalLanguageList}
                    >
                      <div
                        className="row overflow-auto"
                        style={{ height: "200px" }}
                      >
                        {TPGlobal.TPClientAvailableLanguages.map(
                          (item, index) =>
                            index > 0 && (
                              <div
                                className="col-12"
                                key={`languageItem-${item.id}`}
                              >
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={index === 0}
                                    labelText={`${nameLabel} (${item.name})`}
                                    value={
                                      insertUpdateState.recordLanguageList[
                                        index
                                      ].value
                                    }
                                    onChange={(e: any) =>
                                      handleLanguageChange(
                                        index,
                                        e.target.value,
                                      )
                                    }
                                    maxLength={100}
                                    errorMessage={
                                      insertUpdateState.nameErrorMessages[index]
                                    }
                                  />
                                </div>
                              </div>
                            ),
                        )}
                      </div>
                    </TPModalLanguageList>
                  </>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSection>
              <div className="form-group">
                <TPCheckBox
                  id="IdCheckBox"
                  labelText={supplyStationIdLabel}
                  checked={insertUpdateState.requireSupplystationId}
                  onChange={handleRequereSupplyIdChange}
                ></TPCheckBox>
                <TPCheckBox
                  id="IdCheckBox"
                  labelText={isActiveLabel}
                  checked={insertUpdateState.isActive}
                  onChange={handleIsActiveChange}
                ></TPCheckBox>
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
    //#endregion
  );
};

export default ProfilesInsertUpdate;
