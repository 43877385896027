import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StoreModel} from "@/redux/store";
import {
    FormDesignerExportImportFormSlice,
    FormDesignerExportImportFormStateModel,
} from "./_redux/FormDesignerExportImportFormSlice";
import e from "./models/FormDesignerExportImportFormEnum";
import {ContentVerticalTabInsertUpdateStyled} from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import FormDesignerExportImportFormTable from "./FormDesignerExportImportFormTable";
import {showToast, TPToastTypes,} from "@/components/bootstrap/components/toasts/TPToast";
import {TPPageAcceptCancelButtonsContainer, TPPageTitle,} from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {SequeceGeneratorSequencesNameEnum, TPButtonTypes,} from "@/models/Global/TPGlobalEnums";
import FormDesignerExportImportFormInfo from "./FormDesignerExportImportFormInfo";
import TPGlobal from "@/helpers/TPGlobal";
import {SequenceService} from "@/services/SequenceService";
import {AdditionalDataFormService} from "@/services/AdditionalDataFormService";
import {commandsEnum as event} from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";
import {LanguagesViewModel} from "@/models/Languages/LanguagesViewModel";

const {execExport, execImport} = new AdditionalDataFormService();

export interface ColumnModel {
    id?: string | number;
    label: string;
    colspan: number;
    readOnly?: boolean;
}

interface FormDesignerExportImportFormProps {
    tabId: string;
    element?: any;
    dispatchAction: Function;
}

export default function FormDesignerExportImportForm({
                                                         tabId,
                                                         element,
                                                         dispatchAction,
                                                     }: FormDesignerExportImportFormProps) {
    const dispatch = useDispatch();

    const {message: m, result: FormDesignerExportImportFormResult} =
        useSelector(
            (s: StoreModel) => s[FormDesignerExportImportFormSlice.name],
        ) as FormDesignerExportImportFormStateModel;

    const [languages, setLanguages] = useState<Array<LanguagesViewModel>>(
        TPGlobal.TPClientAvailableLanguages,
    );
    const [datasource, setDatasource] = useState<any>();
    const [data, setData] = useState<Array<Array<any>>>([]);
    const [columns, setColumns] = useState<Array<Array<ColumnModel>>>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(
            FormDesignerExportImportFormSlice.actions.message([
                e.TitleLabel,
                e.WarningTitleLabel,
                e.WarningDescriptionLabel,
                e.TableColumnIdLabel,
                e.TableColumnDescriptionsLabel,
                e.TableColumnFormTypeIdLabel,
                e.TableColumnLanguageIdsLabel,
                e.TableColumnFormTypeDescriptionsLabel,
                e.TableColumnJsonDesignerUT8Label,
                e.TableColumnLogoUT8Label,
                e.TableColumnApplyPersonalDataLabel,
                e.TableColumnIsActiveLabel,
                e.OkButtonLabel,
                e.NotificationDescriptionMessage,
                e.NotificationTypeMessage,
            ]),
        );
        if (element) {
            load();
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (FormDesignerExportImportFormResult?.message) {
            showToast(
                FormDesignerExportImportFormResult.message,
                FormDesignerExportImportFormResult?.error
                    ? TPToastTypes.error
                    : TPToastTypes.success,
            );
        }
    }, [FormDesignerExportImportFormResult]);

    function load() {
        setLoading(true);
        execExport(element!.id)
            .then((response) => {
                setLoading(false);
                setDatasource(response);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }

    async function onClickSaveButtonHandler() {
        const l = languages.length;
        const descriptions = data[0]
            .slice(0, l)
            .map((v, i) => ({
                languageId: columns[1][i].id,
                localizedValue: v,
                order: i,
            }))
            .filter(({localizedValue}) => !!localizedValue?.trim())

        if (descriptions.length === 0) {
            showToast(m?.[e.NotificationDescriptionMessage], TPToastTypes.warning);
            return;
        }

        const formTypeId = data[0][l];

        if (!formTypeId) {
            showToast(m?.[e.NotificationTypeMessage], TPToastTypes.warning);
            return;
        }

        let jsonDesignerUtf8;
        try {
            jsonDesignerUtf8 = data[0][l + 1]
                ? Array.from(TPGlobal.stringToUTF8(data[0][6]) ?? []).join(",")
                : ""
        } catch (e) {
            console.error(e);
        }

        const {generalAutomaticId} = new SequenceService();
        const response = await generalAutomaticId(false, true, [200], SequeceGeneratorSequencesNameEnum.SQADFO);
        const id = response.responseData.data[0].sequenceCode;

        const element: any = {
            id,
            descriptions,
            languageIds: datasource?.languageIds ?? [{languageId: descriptions[0].languageId, localizedValue: descriptions[0].languageId, order: 0}],
            formTypeId,
            jsonDesignerUtf8,
            applyPersonalData:
                data[0][l + 2] === "true" ? true : data[0][l + 2] === "false" ? false : null,
            isActive: Boolean(
                data[0][l + 3] === "true" ? true : data[0][l + 3] === "false" ? false : null,
            ),
        };

        execImport(element)
            .then((response) => {
                setLoading(false);
                dispatchAction({type: event.reloadGrid});
                dispatchAction({type: event.vertical_tab_close, payload: tabId});
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }

    return (
        <ContentVerticalTabInsertUpdateStyled>
            <TPLoadingOverlay active={loading}>
                <div className="row">
                    <div className="col-11">
                        <TPPageTitle>{m?.[e.TitleLabel]}</TPPageTitle>
                        <hr/>

                        <FormDesignerExportImportFormInfo/>

                        <FormDesignerExportImportFormTable
                            datasource={datasource}
                            data={data}
                            setData={setData}
                            columns={columns}
                            setColumns={setColumns}
                            languages={languages}
                        />

                        <TPPageAcceptCancelButtonsContainer>
                            <TPButton
                                type={TPButtonTypes.primary}
                                onClick={onClickSaveButtonHandler}
                            >
                                {m?.[e.OkButtonLabel]}
                            </TPButton>
                        </TPPageAcceptCancelButtonsContainer>
                    </div>
                </div>
            </TPLoadingOverlay>
        </ContentVerticalTabInsertUpdateStyled>
    );
}
