import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MergeCustomersConflictResolution from "./components/MergeCustomersConflictResolution";
import MergeCustomersSearch from "./components/MergeCustomersSearch";
import {
  resetCustomerMerge,
  setCanMerge,
  setConflicts,
  setCurrentView,
  setMergeWarningVisible,
} from "./redux/MergeCustomersSlice";
import { MergeCustomersState } from "./redux/MergeCustomersStore";
import useLabels from "./styles/labels";
import "./styles/Styles.css";

const MergeCustomersMain = function () {
  const dispatch = useDispatch();
  const { labels } = useLabels();
  const {
    mainCustomerData,
    mergeCustomerData,
    currentView,
    conflicts,
    finalMergeData,
    interestValues,
    canMerge,
  } = useSelector((state: MergeCustomersState) => state.mainData);

  useEffect(() => {
    let conflicts = false;
    Object.entries(finalMergeData).map(([key], index) => {
      if (!interestValues.includes(key)) return;
      const mainVal = String(Object.entries(mainCustomerData)[index][1]);
      const mergeVal = String(Object.entries(mergeCustomerData)[index][1]);
      conflicts = mainVal != mergeVal && mainVal.length > 0;
    });
    dispatch(setConflicts(conflicts));

    if (
      mainCustomerData.id == mergeCustomerData.id &&
      mainCustomerData.id != -1 &&
      mergeCustomerData.id != -1
    ) {
      showToast(labels.MainSameAsMerge, TPToastTypes.error);
    }

    dispatch(
      setCanMerge(
        mainCustomerData.id != -1 &&
          mergeCustomerData.id != -1 &&
          mainCustomerData.id != mergeCustomerData.id,
      ),
    );
  }, [finalMergeData, mainCustomerData, mergeCustomerData]);

  return (
    <ContentVerticalNoTabsStyled>
      <div id="merge-customers-master-container" className="container-fluid">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TPPageTitle>{labels.PageTitle}</TPPageTitle>
          <div id="merge-customers-header-buttons">
            {currentView == "conflict" && (
              <TPButton
                onClick={() => dispatch(resetCustomerMerge())}
                type={TPButtonTypes.danger}
              >
                {labels.GoBack}
              </TPButton>
            )}
            {currentView == "search" && (
              <TPButton
                onClick={() => dispatch(setCurrentView("conflict"))}
                type={
                  canMerge ? TPButtonTypes.primary : TPButtonTypes.secondary
                }
                disabled={!canMerge}
              >
                {labels.CompareCustomers}
              </TPButton>
            )}
            {currentView == "conflict" && (
              <TPButton
                onClick={() => dispatch(setMergeWarningVisible(true))}
                type={
                  !conflicts ? TPButtonTypes.primary : TPButtonTypes.secondary
                }
                disabled={conflicts}
              >
                {labels.Merge}
              </TPButton>
            )}
          </div>
        </div>
        {currentView == "search" && <MergeCustomersSearch />}
        {currentView == "conflict" && <MergeCustomersConflictResolution />}
      </div>
    </ContentVerticalNoTabsStyled>
  );
};

export default MergeCustomersMain;
