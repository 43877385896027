import { propsWithBsClassName } from "react-bootstrap-typeahead/types/utils";

export interface ITPWizardContent {
  children: any;
  id: string;
  isActive: boolean;
  name: string;
}

const TPWizardContent = (props: ITPWizardContent) => {
  return (
    <div
      id={props.id}
      className={`tab-pane ${props.isActive ? "active" : " "}`}
      role="tabpanel"
    >
      {props.children}
    </div>
  );
};

export default TPWizardContent;
