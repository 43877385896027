import { useEffect, useState } from "react";
import ImagesAdminFile from "./ImagesAdminFile";
import {
  ContainerFilesGridDivStyle,
  ContainerFilesListDivStyle,
  ContainerViewDetail,
  TextPStyle,
} from "./StyleImageAdmin";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import ImagesAdminDetail from "./ImagesAdminDetail";
import ImagesAdminSlider from "./ImagesAdminSlider";
import { OperationMode } from "./useStatusImagesAdmin";
import UploadImage from "./imageAdminUploadImage";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

const ImagesAdminContainerFiles = () => {
  const componentFileName: string = "ImagesAdminContainerFiles.tsx";

  const [listFolderTemp, selectedFolderTemp] = useState<Array<RecursiveRoutes>>(
    Array<RecursiveRoutes>,
  );

  const value = useAppConctextImageAdmin();
  const {
    status,
    listFilesModel,
    viewListAndGrid,
    selectedItem,
    operationMode,
    isLoading,
  } = value;

  useEffect(() => {
    selectedFolderTemp(listFilesModel);
  }, [listFilesModel]);

  const handleActivateMenu = (event: any) => {
    event.preventDefault();
  };

  if (!viewListAndGrid) {
    return (
      <>
        <UploadImage Type="jpg" Size={150}></UploadImage>
        {selectedItem && operationMode == OperationMode.SelectFile ? (
          <ImagesAdminSlider files={listFilesModel}></ImagesAdminSlider>
        ) : null}
        <ContainerFilesGridDivStyle>
          {listFilesModel.map((file) => (
            <ImagesAdminFile file={file} key={file.id} />
          ))}
        </ContainerFilesGridDivStyle>
      </>
    );
  } else {
    return (
      <>
        <ContainerViewDetail>
          <TextPStyle>
            <b>Image</b>
          </TextPStyle>
          <TextPStyle>
            <b>Name</b>
          </TextPStyle>
          <TextPStyle>
            <b>Size</b>
          </TextPStyle>
          <TextPStyle>
            <b>Date</b>
          </TextPStyle>
        </ContainerViewDetail>

        <UploadImage Type="jpg" Size={150}></UploadImage>
        {selectedItem && operationMode == OperationMode.SelectFile ? (
          <ImagesAdminSlider files={listFilesModel}></ImagesAdminSlider>
        ) : null}
        <ContainerFilesListDivStyle>
          {listFilesModel.map((file) => (
            <ImagesAdminDetail file={file} key={file.id} />
          ))}
        </ContainerFilesListDivStyle>
      </>
    );
  }
};

export default ImagesAdminContainerFiles;
