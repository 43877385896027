import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import { BranchService } from "@/services/BranchService";
import { TPI18N } from "@/services/I18nService";
import React, { useEffect, useImperativeHandle, useState } from "react";
import TPCheckBox from "../bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "../bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "../bootstrap/forms/select/TPSelect";
import TPLabel from "../bootstrap/forms/TPLabel/TPLabel";
import { TPAddtionalDataUIModes } from "./TPAdditionalDataUImodes";

export enum AdditionalDataValueListBranchRenderMethodEnum {
  Listbox = "Listbox",
  Combobox = "Combobox",
  Option = "Option",
  Checkbox = "Checkbox",
}

export type TPAdditionalDataValueListBranchProps = {
  idControl: string;
  modeUI: TPAddtionalDataUIModes;
  labelText: string;
  labelStyles?: Array<TPKeyValue>;
  isMandatory: boolean;
  defaultValue: any;
  onValueChange?: Function | null;
  callbackIsLoading: Function;
  renderMethod: AdditionalDataValueListBranchRenderMethodEnum;
  parentId: string;
  multipleSelection: boolean;
};

export type TPAdditionalDataValieListCheckboxValue = {
  item: TPKeyValue;
  isChecked: boolean;
};

const TPAdditionalDataValueListBranch = React.forwardRef(
  (
    {
      idControl,
      modeUI,
      labelText,
      labelStyles = [],
      isMandatory,
      defaultValue,
      onValueChange = null,
      renderMethod,
      parentId,
      callbackIsLoading,
      multipleSelection,
    }: TPAdditionalDataValueListBranchProps,
    ref,
  ) => {
    const componentFileName: string = "TPAdditionalDataValueListBranch";
    const [badConfigurationErrorMessage, setBadConfigurationErrorMessage] =
      useState("");
    const [optionList, setOptionList] = useState<Array<TPKeyValue>>([]);
    const [optionCheckboxList, setOptionCheckboxList] = useState<
      Array<TPAdditionalDataValieListCheckboxValue>
    >([]);
    const [errorMessageValue, setErrorMessageValue] = useState("");
    const [invalidCharactersErrorMessage, setInvalidCharactersErrorMessage] =
      useState("");
    const [mandatoryErrorMessage, setMandatoryErrorMessage] = useState("");
    const [additionalDataStrValue, setAdditionalDataStrValue] =
      useState(defaultValue);
    const [branchDescriptionLabel, setBranchDescriptionLabel] = useState("");
    const [isReady, setIsReady] = useState(false);

    const loadResources = async () => {
      setBadConfigurationErrorMessage(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "BadConfigurationErrorMessage",
        ),
      );

      let newOptionList: Array<TPKeyValue> = [];
      let serviceClient = new BranchService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        callbackIsLoading(true);

        let responseRequest = await serviceClient.getRenderListValue(
          "",
          parentId,
          false,
          true,
          expectedCodes,
        );
        callbackIsLoading(false);

        let BranchValueList: Array<BranchViewModel> = [...responseRequest];
        BranchValueList.map((item) => {
          newOptionList.push({
            key: item.id,
            value: item.localizedDescription,
          });
        });

        if (
          renderMethod ==
            AdditionalDataValueListBranchRenderMethodEnum.Combobox ||
          renderMethod == AdditionalDataValueListBranchRenderMethodEnum.Listbox
        ) {
          newOptionList.unshift({ key: "", value: "--" });
        } else {
          if (
            renderMethod ==
            AdditionalDataValueListBranchRenderMethodEnum.Checkbox
          ) {
            let newOptionCheckboxList: Array<TPAdditionalDataValieListCheckboxValue> =
              [];
            let tmpArray: Array<string> = [];
            if (additionalDataStrValue) {
              tmpArray = additionalDataStrValue.toString().split("|");
            }

            BranchValueList.map((item, idx) => {
              newOptionCheckboxList.push({
                isChecked: tmpArray.indexOf(item.id) > -1,
                item: { key: item.id, value: item.localizedDescription },
              });
            });

            setOptionCheckboxList(newOptionCheckboxList);
          }
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} loadResources ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} loadResources ex`);
        callbackIsLoading(false);
        return [];
      }

      setOptionList(newOptionList);
      if (defaultValue) {
        let newBranchDescription = newOptionList.find(
          (x) => x.key === defaultValue,
        );
        if (newBranchDescription) {
          setBranchDescriptionLabel(newBranchDescription.value);
        }
      }

      setInvalidCharactersErrorMessage(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "InputDTOInvalidIdChar",
        ),
      );
      setMandatoryErrorMessage(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "InputDTORequired"),
      );
      setIsReady(true);
    };

    useImperativeHandle(ref, () => ({
      getValueFromParent() {
        switch (renderMethod) {
          case AdditionalDataValueListBranchRenderMethodEnum.Checkbox:
            let valueToReturn: string = "";
            if (
              additionalDataStrValue &&
              additionalDataStrValue.toString().trim() !== ""
            ) {
              let splitedValues: Array<string> =
                additionalDataStrValue.split("|");

              for (let i: number = 0; i <= splitedValues.length - 1; i++) {
                if (splitedValues[i]) {
                  if (valueToReturn != "") {
                    valueToReturn = valueToReturn + "|";
                  }
                  valueToReturn = valueToReturn + splitedValues[i];
                }
              }
            }
            return valueToReturn;
          case AdditionalDataValueListBranchRenderMethodEnum.Combobox:
          case AdditionalDataValueListBranchRenderMethodEnum.Option:
            return additionalDataStrValue;
          case AdditionalDataValueListBranchRenderMethodEnum.Listbox:
            return additionalDataStrValue;
          default:
            return "";
        }
      },
      validateFromParent() {
        switch (renderMethod) {
          case AdditionalDataValueListBranchRenderMethodEnum.Checkbox:
            return true;
          case AdditionalDataValueListBranchRenderMethodEnum.Listbox:
            //todo
            if (isMandatory) {
              if (!multipleSelection && additionalDataStrValue.length == 0) {
                setErrorMessageValue(mandatoryErrorMessage);
                return false;
              } else if (multipleSelection) {
              }
            }
            break;
          case AdditionalDataValueListBranchRenderMethodEnum.Combobox:
          case AdditionalDataValueListBranchRenderMethodEnum.Option:
            if (isMandatory && additionalDataStrValue.length == 0) {
              setErrorMessageValue(mandatoryErrorMessage);
              return false;
            }
            if (isMandatory && additionalDataStrValue.trim() === "") {
              setErrorMessageValue(mandatoryErrorMessage);
              return false;
            }
            if (
              additionalDataStrValue &&
              additionalDataStrValue.trim() !==
                TPGlobal.TPSanitize(additionalDataStrValue.trim())
            ) {
              setErrorMessageValue(invalidCharactersErrorMessage);
              return false;
            }
            break;

          default:
            return false;
        }
        return true;
      },
    }));

    const handleOnchangeStrValue = (e: any, value: Array<TPKeyValue>) => {
      setErrorMessageValue("");
      if (!multipleSelection) {
        setAdditionalDataStrValue(e.target.value);
        if (onValueChange) {
          onValueChange(e.target.value, idControl);
        }
      } else {
        //todo
      }
    };

    const handleOnchangeBoolValue = (e: any, key: string) => {
      let newOptionCheckboxList = [...optionCheckboxList];
      for (let i = 0; i < newOptionCheckboxList.length; i++) {
        const element = newOptionCheckboxList[i];
        if (element.item.key === key) {
          element.isChecked = !element.isChecked;
          break;
        }
      }
      setOptionCheckboxList(newOptionCheckboxList);
      if (optionCheckboxList && optionCheckboxList.length >= 0) {
        let tmpStr: string = optionCheckboxList
          .map((value) => {
            return value.isChecked ? value.item.key : "";
          })
          .join("|");
        setAdditionalDataStrValue(tmpStr);
      }

      // setAdditionalDataBoolValue(!additionalDataBoolValue);
      // if (onValueChange) {
      //   onValueChange(additionalDataBoolValue, idControl);
      // }
    };

    const renderCollectMode = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }
      if (
        renderMethod !==
          AdditionalDataValueListBranchRenderMethodEnum.Listbox &&
        multipleSelection
      ) {
        return <h3>{badConfigurationErrorMessage}</h3>;
      }
      switch (renderMethod) {
        case AdditionalDataValueListBranchRenderMethodEnum.Listbox:
          return (
            <TPSelect
              id="IdSelect"
              labelText={labelText}
              onChange={handleOnchangeStrValue}
              dataSource={optionList}
              value={additionalDataStrValue}
              isMandatory={isMandatory}
              errorMessage={errorMessageValue}
              size={multipleSelection ? 7 : 0}
              multiple={multipleSelection}
            ></TPSelect>
          );
        case AdditionalDataValueListBranchRenderMethodEnum.Checkbox:
          return (
            <>
              <TPLabel labelText={labelText}></TPLabel>
              {optionCheckboxList.map((selected, index) => {
                return (
                  <TPCheckBox
                    id="IdCheckBox"
                    key={"chk" + index.toString()}
                    labelText={selected.item.value}
                    checked={selected.isChecked}
                    onChange={(e: any) =>
                      handleOnchangeBoolValue(e, selected.item.key)
                    }
                  ></TPCheckBox>
                );
              })}
            </>
          );
        case AdditionalDataValueListBranchRenderMethodEnum.Combobox:
          return (
            <TPSelect
              id="IdSelect"
              labelText={labelText}
              onChange={handleOnchangeStrValue}
              dataSource={optionList}
              value={additionalDataStrValue}
              isMandatory={isMandatory}
              errorMessage={errorMessageValue}
            ></TPSelect>
          );
        case AdditionalDataValueListBranchRenderMethodEnum.Option:
          return (
            <TPRadioGroup
              id="IdRadioGroup"
              labelText={labelText}
              value={additionalDataStrValue}
              source={optionList}
              onChange={handleOnchangeStrValue}
              isMandatory={isMandatory}
              errorMessage={errorMessageValue}
            ></TPRadioGroup>
          );
        default:
          return <div>Error</div>;
      }
    };

    const renderViewMode1 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      switch (renderMethod) {
        case AdditionalDataValueListBranchRenderMethodEnum.Listbox:
          return (
            <TPSelect
              id="IdSelect"
              disabled={true}
              labelText={labelText}
              onChange={handleOnchangeStrValue}
              dataSource={optionList}
              value={additionalDataStrValue}
              isMandatory={isMandatory}
              errorMessage={errorMessageValue}
              size={multipleSelection ? 7 : 0}
              multiple={multipleSelection}
            ></TPSelect>
          );
        case AdditionalDataValueListBranchRenderMethodEnum.Checkbox:
          return (
            <>
              <TPLabel labelText={labelText}></TPLabel>
              {optionCheckboxList.map((selected, index) => {
                return (
                  <TPCheckBox
                    id="IdCheckBox"
                    disabled={true}
                    labelText={selected.item.value}
                    checked={selected.isChecked}
                    onChange={(e: any) =>
                      handleOnchangeBoolValue(e, selected.item.key)
                    }
                  ></TPCheckBox>
                );
              })}
            </>
          );
        case AdditionalDataValueListBranchRenderMethodEnum.Combobox:
          return (
            <TPSelect
              id="IdSelect"
              disabled={true}
              labelText={labelText}
              onChange={handleOnchangeStrValue}
              dataSource={optionList}
              value={additionalDataStrValue}
              isMandatory={isMandatory}
              errorMessage={errorMessageValue}
            ></TPSelect>
          );
        case AdditionalDataValueListBranchRenderMethodEnum.Option:
          return (
            <TPRadioGroup
              id="IdRadioGroup"
              disabled={true}
              labelText={labelText}
              value={additionalDataStrValue}
              source={optionList}
              onChange={handleOnchangeStrValue}
              isMandatory={isMandatory}
              errorMessage={errorMessageValue}
            ></TPRadioGroup>
          );
        default:
          return <div>Error</div>;
      }
    };

    const renderViewMode2 = () => {
      let labelStyle: any = null;
      let i: number;
      for (i = 0; i <= labelStyles.length - 1; i++) {
        if (labelStyle == null) {
          labelStyle = {};
        }
        labelStyle[labelStyles[i].key] = labelStyles[i].value;
      }

      return (
        //todo better render
        <React.Fragment>
          <div className="row">
            <div className="col">
              <span className="tpbold" style={labelStyle}>
                {`${labelText}: `}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">{branchDescriptionLabel}</div>
          </div>
        </React.Fragment>
      );
    };

    useEffect(() => {
      loadResources();
    }, []);

    useEffect(() => {
      setAdditionalDataStrValue(defaultValue);
      switch (renderMethod) {
        case AdditionalDataValueListBranchRenderMethodEnum.Checkbox:
          let tmpArray: Array<string> = [];
          if (defaultValue) {
            tmpArray = defaultValue.toString().split("|");
          }

          let newOptionCheckboxList: Array<TPAdditionalDataValieListCheckboxValue> =
            [];
          newOptionCheckboxList = optionCheckboxList.map((obj, index) => {
            obj.isChecked = tmpArray.indexOf(obj.item.key) > -1;
            return obj;
          });
          setOptionCheckboxList(newOptionCheckboxList);
          break;

        default:
          break;
      }
    }, [defaultValue]);

    return (
      <>
        {isReady && (
          <React.Fragment>
            {modeUI == TPAddtionalDataUIModes.Collect
              ? renderCollectMode()
              : modeUI == TPAddtionalDataUIModes.View1
                ? renderViewMode1()
                : renderViewMode2()}
          </React.Fragment>
        )}
      </>
    );
  },
);

export default TPAdditionalDataValueListBranch;
