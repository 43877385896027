import TPRequired from "@/components/bootstrap/forms/TPRequired/TPRequired";
import { ReactElement } from "react";
import { TPLabelStyled } from "./tpLabelStyles";

interface TPLabelInterface {
  labelText?: string | JSX.Element;
  htmlFor?: string;
  isMandatory?: boolean;
  className?: string;
  style?: any;
  onClick?: Function;
}

const TPLabel = ({
  labelText,
  htmlFor = "",
  isMandatory = false,
  className = "",
  style,
  onClick,
}: TPLabelInterface): ReactElement => {
  const handleOnClick = (e: any) => {
    if (onClick) {
      onClick(e);
    }
  };
  const contentIsString = typeof labelText === "string";
  return (
    <TPLabelStyled
      style={style}
      htmlFor={htmlFor}
      className={`form-label  ${className}`}
      onClick={handleOnClick}
    >
      <div style={{ display: "flex" }}>
        {contentIsString ? (
          <span dangerouslySetInnerHTML={{ __html: labelText }} />
        ) : (
          <label style={{ margin: "0px", padding: "0px" }}>{labelText}</label>
        )}
        {isMandatory && <TPRequired />}
      </div>
    </TPLabelStyled>
  );
};

export default TPLabel;
