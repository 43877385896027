import { ReactElement, useRef, useEffect, useId } from "react";
import { TPTextAreaStyled } from "./tpTextAreaStyles";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";

interface TPTextAreaInterface {
  onChange: Function;
  className?: string;
  value: string;
  disabled?: boolean;
  maxLength?: number;
  hasFocus?: boolean;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  errorMessage?: string;
  isHorizontal?: boolean;
  placeholder?: string;
  withIcon?: boolean;
  icon?: TPIconTypes;
  rows: number;
  columns?: number;
  containerStyle?: any;
  labelStyle?: any;
  textAreaStyle?: any;
  id?: string;
}

const TPTextArea = ({
  onChange,
  className = "",
  value,
  disabled,
  maxLength,
  hasFocus,
  labelText,
  isMandatory = false,
  errorMessage = "",
  isHorizontal = false,
  placeholder = "",
  withIcon = false,
  icon = TPIconTypes.default,
  rows,
  columns = 40,
  containerStyle,
  labelStyle,
  textAreaStyle,
  id,
}: TPTextAreaInterface): ReactElement => {
  const inputRef = useRef(null);
  const theId = `textarea-${useId()}`;

  const handleOnChange = (e: any) => {
    onChange(e);
  };

  const theInput = (
    <TPTextAreaStyled
      placeholder={placeholder}
      id={id ? id : theId}
      ref={inputRef}
      value={value}
      className={`form-control ${className} 
        ${errorMessage !== "" ? "is-invalid" : ""} 
        ${withIcon ? "border-end-0" : ""}`}
      onChange={handleOnChange}
      disabled={disabled}
      maxLength={maxLength}
      autoCorrect={"off"}
      autoComplete={"off"}
      rows={rows}
      cols={columns}
      style={textAreaStyle}
    />
  );

  useEffect(() => {
    let inputElement: any;
    if (hasFocus) {
      if (inputRef.current) {
        inputElement = inputRef.current;
        inputElement.focus();
      }
    }
  }, [hasFocus]);

  return (
    <TPFormControlContainerStyled
      isHorizontal={isHorizontal}
      className={`${withIcon ? "input-group" : ""}`}
      style={containerStyle}
    >
      <TPLabel
        htmlFor={id ? id : theId}
        isMandatory={isMandatory}
        labelText={labelText}
        style={labelStyle}
      />
      {theInput}
      {withIcon && (
        <span className="input-group-append">
          <TPIcon iconType={icon} />
        </span>
      )}

      {errorMessage !== "" && (
        <div className="invalid-feedback">{errorMessage}</div>
      )}
    </TPFormControlContainerStyled>
  );
};

export default TPTextArea;
