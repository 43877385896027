import React, { ReactElement, FC, useState, useEffect } from "react";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";

import {
	TPPageSectionTitle,
	TPPageTitle,
	TPPageSection,
	TPPageAcceptCancelButtonsContainer,
	TPPageSubTitle,
} from "@/components/TPPage/tpPageStyles";

import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";

import {
	TPButtonTypes,
	TPActiveOptions,
	TPIconTypes,
	SequeceGeneratorSequencesNameEnum,
} from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";

import { RelationsService } from "@/services/RelationsService";
import { OrganizationsService } from "@/services/OrganizationsService";
import { CalendarsService } from "@/services/CalendarsService";
import { TimeZonesService } from "@/services/TimeZonesService";
import { SchedulesService } from "@/services/SchedulesService";
import { TemplatesService } from "@/services/TemplateService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";

import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { RelationsViewModel } from "@/models/Relations/RelationsModels";
import { OrganizationsViewModel } from "@/models/Organizations/OrganizationsModels";
import { CalendarsViewModel } from "@/models/Calendars/CalendarsModels";
import { ScheduleViewModel } from "@/models/Schedules/SchedulesModels";
import { TemplateViewModel } from "@/models/Templates/TemplatesModels";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { HelperTimeZoneViewModel } from "@/models/TimeZones/TimesZonesModels";
import { TreeService } from "@/services/TreeService";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import {
	OrganizationsRelationsInputDTO,
	OrganizationsRelationsInputDTOValidator,
} from "@/models/OrganizationsRelations/OrganizationsRelationsInputDTO";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPTableCondensedContainer } from "@/components/bootstrap/content/tables/tpTableStyles";
import {
	showToast,
	TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import { SequenceService } from "@/services/SequenceService";
import { copyToClipboard } from "@/helpers/ClipboardManager";

type InsertUpdateProps = {
	mode: string;
	recordId: string;
	callBackResult: Function;
};

type InsertUpdateStateType = {
	idBaseLevel: string;
	informerDataId: string;
	isActive: boolean;

	//todo error messages
	idErrorMessage: string;
	calendarIdErrorMessage: string;
	organizationIdErrorMessage: string;
	relationIdErrorMessage: string;
	treeId1ErrorMessage: string;
	treeId2ErrorMessage: string;
	treeId3ErrorMessage: string;
	treeId4ErrorMessage: string;
	treeId5ErrorMessage: string;
	timeZoneErrorMessage: string;
	scheduleIdErrorMessage: string;
	informerDataIdErrorMessage: string;
	c1ErrorMessages: Array<string>;
	c2ErrorMessages: Array<string>;
	c3ErrorMessages: Array<string>;
	c4ErrorMessages: Array<string>;
	c5ErrorMessages: Array<string>;
	isActiveErrorMessage: string;
};

const OrganizationsRelationsInsertUpdate: FC<InsertUpdateProps> = ({
	mode,
	recordId,
	callBackResult,
}): ReactElement => {
	//#region  Init
	const componentFileName: string = "OrganizationsRelationsInsertUpdate.tsx";
	const multilanguageTableName: string = "BAseLEvel";
	const multilanguageFieldNameC_1: string = "Tree1_Classify1Caption";
	const multilanguageFieldNameC_2: string = "Tree2_Classify2Caption";
	const multilanguageFieldNameC_3: string = "Tree3_Classify3Caption";
	const multilanguageFieldNameC_4: string = "Tree4_Classify4Caption";
	const multilanguageFieldNameC_5: string = "Tree5_Classify5Caption";

	//screen loading
	const [isLoadingScreen, setIsLoadingScreen] = useState(true);

	//Screen resources
	const ResourceSet: string = "BaseLevelInsertUpdateComponent";
	const [titleLabel, setTitleLabel] = useState("");
	const [subTitleLabel, setSubTitleLabel] = useState("");
	const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
	const [parametersSectionLabel, setParametersSectionLabel] = useState("");
	const [idLabel, setIdLabel] = useState("");
	const [organizationLabel, setOrganizationLabel] = useState("");
	const [relationLabel, setRelationLabel] = useState("");
	const [descriptionLabel, setDescriptionLabel] = useState("");
	const [calendarLabel, setCalendarLabel] = useState("");
	const [scheduleLabel, setScheduleLabel] = useState("");
	const [timeZoneLabel, setTimeZoneLabel] = useState("");
	const [templateLabel, setTemplateLabel] = useState("");
	const [classifiersSectionLabel, setClassifiersSectionLabel] = useState("");
	const [treeLabel, setTreeLabel] = useState("");
	const [classificationLabel1, setClassificationLabel1] = useState("");
	const [classificationLabel2, setClassificationLabel2] = useState("");
	const [classificationLabel3, setClassificationLabel3] = useState("");
	const [classificationLabel4, setClassificationLabel4] = useState("");
	const [classificationLabel5, setClassificationLabel5] = useState("");
	const [copyDefaultValueLabel, setCopyDefaultValueLabel] = useState("");
	const [helpMessageTooltip, setHelpMessageTooltip] = useState("");
	const [duplicateTreeMessage, setDuplicateTreeMessage] = useState("");

	const [isActiveLabel, setIsActiveLabel] = useState("");
	const [saveButtonLabel, setSaveButtonLabel] = useState("");
	const [cancelButtonLabel, setCancelButtonLabel] = useState("");

	//Screen state
	let insertUpdateInitialState: InsertUpdateStateType = {
		idBaseLevel: recordId,
		informerDataId: "",
		isActive: true,

		//Validations
		idErrorMessage: "",
		organizationIdErrorMessage: "",
		relationIdErrorMessage: "",
		calendarIdErrorMessage: "",
		treeId1ErrorMessage: "",
		treeId2ErrorMessage: "",
		treeId3ErrorMessage: "",
		treeId4ErrorMessage: "",
		treeId5ErrorMessage: "",
		timeZoneErrorMessage: "",
		scheduleIdErrorMessage: "",
		informerDataIdErrorMessage: "",
		c1ErrorMessages: [],
		c2ErrorMessages: [],
		c3ErrorMessages: [],
		c4ErrorMessages: [],
		c5ErrorMessages: [],
		isActiveErrorMessage: "",
	};
	const [insertUpdateState, setInsertUpdateState] = useState(
		insertUpdateInitialState,
	);

	//combo organizations
	const [organizationsList, setOrganizationsListState] = useState<
		Array<TPKeyValue>
	>([]);
	const [selectedOrganization, setSelectedOrganization] = useState("");
	const [selectedOrganizationName, setSelectedOrganizationName] = useState("");

	//combo relations
	const [relationsList, setRelationsListState] = useState<Array<TPKeyValue>>(
		[],
	);
	const [selectedRelation, setSelectedRelation] = useState("");
	const [selectedRelationName, setSelectedRelationName] = useState("");

	//computed description
	const [selectedDescription, setSelectedDescription] = useState("");

	//combo calendars
	const [calendarsList, setCalendarsListState] = useState<Array<TPKeyValue>>(
		[],
	);
	const [selectedCalendar, setSelectedCalendar] = useState("");

	//combo schedules
	const [schedulesList, setSchedulesListState] = useState<Array<TPKeyValue>>(
		[],
	);
	const [selectedSchedule, setSelectedSchedule] = useState("");

	//combo timezones
	const [timezonesList, setTimezonesListState] = useState<Array<TPKeyValue>>(
		[],
	);
	const [selectedTimeZone, setSelectedTimeZone] = useState(
		TPGlobal.currentUserTimeZoneId,
	);

	//combo tempaltes
	const [templatesList, setTemplatesListState] = useState<Array<TPKeyValue>>(
		[],
	);
	const [selectedTemplate, setSelectedTemplate] = useState("");

	//combo Tree1Id
	const [treeList, setTreeListState] = useState<Array<TPKeyValue>>([]);

	const [selectedTreeId1, setSelectedTreeId1] = useState("");
	const [selectedTreeId2, setSelectedTreeId2] = useState("");
	const [selectedTreeId3, setSelectedTreeId3] = useState("");
	const [selectedTreeId4, setSelectedTreeId4] = useState("");
	const [selectedTreeId5, setSelectedTreeId5] = useState("");

	const [recordLanguageListC1, setRecordLanguageListC1] = useState<
		Array<TPKeyValue>
	>([]);
	const [recordLanguageListC2, setRecordLanguageListC2] = useState<
		Array<TPKeyValue>
	>([]);
	const [recordLanguageListC3, setRecordLanguageListC3] = useState<
		Array<TPKeyValue>
	>([]);
	const [recordLanguageListC4, setRecordLanguageListC4] = useState<
		Array<TPKeyValue>
	>([]);
	const [recordLanguageListC5, setRecordLanguageListC5] = useState<
		Array<TPKeyValue>
	>([]);

	const [referenceId, setReferenceId] = useState("");
	//#endregion

	const loadResourcesAndScreenInfo = async () => {
		let i: number;
		//resources state
		setTitleLabel(await TPI18N.GetText(ResourceSet, "Title" + mode + "Label"));
		setSubTitleLabel(await TPI18N.GetText(ResourceSet, "SubtitleLabel"));
		setDescriptionSectionLabel(
			await TPI18N.GetText(
				TPGlobal.globalResourceSet,
				"DescriptionSectionLabel",
			),
		);
		setParametersSectionLabel(
			await TPI18N.GetText(
				TPGlobal.globalResourceSet,
				"ParametersSectionLabel",
			),
		);
		setSaveButtonLabel(
			await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
		);
		setCancelButtonLabel(
			await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
		);
		setIdLabel(await TPI18N.GetText(ResourceSet, "IdLabel"));
		setOrganizationLabel(
			await TPI18N.GetText(ResourceSet, "OrganizationLabel"),
		);
		setRelationLabel(await TPI18N.GetText(ResourceSet, "RelationLabel"));
		setDescriptionLabel(await TPI18N.GetText(ResourceSet, "DescriptionLabel"));
		setCalendarLabel(await TPI18N.GetText(ResourceSet, "CalendarLabel"));
		setScheduleLabel(await TPI18N.GetText(ResourceSet, "ScheduleLabel"));
		setTimeZoneLabel(await TPI18N.GetText(ResourceSet, "TimezoneLabel"));
		setTemplateLabel(await TPI18N.GetText(ResourceSet, "TemplateLabel"));
		setIsActiveLabel(await TPI18N.GetText(ResourceSet, "IsActiveLabel"));
		setClassifiersSectionLabel(
			await TPI18N.GetText(ResourceSet, "ClassifiersSectionLabel"),
		);
		setTreeLabel(await TPI18N.GetText(ResourceSet, "ClassificationTreeLabel"));
		setClassificationLabel1(
			await TPI18N.GetText(ResourceSet, "ClassificationLabel1"),
		);
		setClassificationLabel2(
			await TPI18N.GetText(ResourceSet, "ClassificationLabel2"),
		);
		setClassificationLabel3(
			await TPI18N.GetText(ResourceSet, "ClassificationLabel3"),
		);
		setClassificationLabel4(
			await TPI18N.GetText(ResourceSet, "ClassificationLabel4"),
		);
		setClassificationLabel5(
			await TPI18N.GetText(ResourceSet, "ClassificationLabel5"),
		);
		setCopyDefaultValueLabel(
			await TPI18N.GetText(ResourceSet, "CopyDefaultValueTooltip"),
		);
		setHelpMessageTooltip(
			await TPI18N.GetText(ResourceSet, "HelpMessageTooltip"),
		);
		setDuplicateTreeMessage(
			await TPI18N.GetText(ResourceSet, "duplicateTreeMessage"),
		);
		//get  organizations list
		await getOrganizationsList();

		//get  relations list
		await getRelationsList();

		//get  calendars list
		await getCalendarsList();

		//get  timezones list
		await getTimeZonesList();

		//get  schedule list
		await getScheduleList();

		//get  templates list
		await getTemplatesList();

		//get Tree1Id list
		await getTree1IdList();

		//screen state
		if (mode == "Update") {
			await getOrganizationRelationById(recordId);
		}
		if (mode == "Insert") {
			let newRecordLanguageListC1 = [...recordLanguageListC1];
			let newRecordLanguageListC2 = [...recordLanguageListC2];
			let newRecordLanguageListC3 = [...recordLanguageListC3];
			let newRecordLanguageListC4 = [...recordLanguageListC4];
			let newRecordLanguageListC5 = [...recordLanguageListC5];
			for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
				let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
				let keyValueElement: TPKeyValue = { key: item.id, value: "" };
				newRecordLanguageListC1.push({ ...keyValueElement });
				newRecordLanguageListC2.push({ ...keyValueElement });
				newRecordLanguageListC3.push({ ...keyValueElement });
				newRecordLanguageListC4.push({ ...keyValueElement });
				newRecordLanguageListC5.push({ ...keyValueElement });
			}

			setRecordLanguageListC1(newRecordLanguageListC1);
			setRecordLanguageListC2(newRecordLanguageListC2);
			setRecordLanguageListC3(newRecordLanguageListC3);
			setRecordLanguageListC4(newRecordLanguageListC4);
			setRecordLanguageListC5(newRecordLanguageListC5);

			setIsLoadingScreen(false);
		}
	};

	const getOrganizationsList = async () => {
		let serviceClient = new OrganizationsService();
		let expectedCodes: Array<number> = [200];
		let i: number;
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.getOrganizationsByFilter(
				TPActiveOptions.ALL.toString(),
				false,
				true,
				expectedCodes,
			);
			let newOrganizationsListState: Array<TPKeyValue> = [];

			let organizationsListData: Array<OrganizationsViewModel>;
			organizationsListData = responseRequest;
			for (i = 0; i <= organizationsListData.length - 1; i++) {
				let keyvalue: TPKeyValue = {
					key: organizationsListData[i].id,
					value: organizationsListData[i].localizedName,
				};
				newOrganizationsListState.push(keyvalue);
			}
			let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
			newOrganizationsListState.unshift(intitialKeyValue);
			setSelectedOrganization("");
			setOrganizationsListState(newOrganizationsListState);
			setIsLoadingScreen(false);
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getOrganizationsList ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getOrganizationsList ex`);
			setIsLoadingScreen(false);
		}
	};

	const getRelationsList = async () => {
		let serviceClient = new RelationsService();
		let expectedCodes: Array<number> = [200];
		let i: number;
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.getRelationsByFilter(
				TPActiveOptions.ALL.toString(),
				false,
				true,
				expectedCodes,
			);
			let newRelationsListState: Array<TPKeyValue> = [];

			let relationsListData: Array<RelationsViewModel>;
			relationsListData = responseRequest;
			for (i = 0; i <= relationsListData.length - 1; i++) {
				let keyvalue: TPKeyValue = {
					key: relationsListData[i].id,
					value: relationsListData[i].localizedName,
				};
				newRelationsListState.push(keyvalue);
			}
			let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
			newRelationsListState.unshift(intitialKeyValue);
			setSelectedRelation("");
			setRelationsListState(newRelationsListState);
			setIsLoadingScreen(false);
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getRelationsList ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getRelationsList ex`);
			setIsLoadingScreen(false);
		}
	};

	const getCalendarsList = async () => {
		let serviceClient = new CalendarsService();
		let expectedCodes: Array<number> = [200];
		let i: number;
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.getCalendarsByFilter(
				TPActiveOptions.ALL.toString(),
				false,
				true,
				expectedCodes,
			);
			let newCalendarsListState: Array<TPKeyValue> = [];

			let calendarsListData: Array<CalendarsViewModel>;
			calendarsListData = responseRequest.filter((k) => k.isActive);
			for (i = 0; i <= calendarsListData.length - 1; i++) {
				let keyvalue: TPKeyValue = {
					key: calendarsListData[i].id,
					value: calendarsListData[i].localizedDescription,
				};
				newCalendarsListState.push(keyvalue);
			}
			let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
			newCalendarsListState.unshift(intitialKeyValue);
			setSelectedCalendar("");
			setCalendarsListState(newCalendarsListState);
			setIsLoadingScreen(false);
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getCalendarsList ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getCalendarsList ex`);
			setIsLoadingScreen(false);
		}
	};

	const getTimeZonesList = async () => {
		let serviceClient = new TimeZonesService();
		let expectedCodes: Array<number> = [200];
		let i: number;
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.getTimeZonesCollection(
				false,
				true,
				expectedCodes,
			);
			let newTimeZonesListState: Array<TPKeyValue> = [];
			let timeZonesListData: Array<HelperTimeZoneViewModel>;
			timeZonesListData = responseRequest;
			for (i = 0; i <= timeZonesListData.length - 1; i++) {
				let keyvalue: TPKeyValue = {
					key: timeZonesListData[i].id,
					value: timeZonesListData[i].displayName,
				};
				newTimeZonesListState.push(keyvalue);
			}
			let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
			newTimeZonesListState.unshift(intitialKeyValue);
			setTimezonesListState(newTimeZonesListState);
			setIsLoadingScreen(false);
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getTimeZonesList ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getTimeZonesList ex`);
			setIsLoadingScreen(false);
		}
	};

	const getScheduleList = async () => {
		let serviceClient = new SchedulesService();
		let expectedCodes: Array<number> = [200];
		let i: number;
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.getSchedulesByFilter(
				TPActiveOptions.ALL.toString(),
				false,
				true,
				expectedCodes,
			);
			let newSchedulesListState: Array<TPKeyValue> = [];
			let schedulesListData: Array<ScheduleViewModel>;
			schedulesListData = responseRequest.filter((k) => k.isActive);
			for (i = 0; i <= schedulesListData.length - 1; i++) {
				let keyvalue: TPKeyValue = {
					key: schedulesListData[i].id,
					value: schedulesListData[i].localizedDescription,
				};
				newSchedulesListState.push(keyvalue);
			}
			let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
			newSchedulesListState.unshift(intitialKeyValue);
			setSelectedSchedule("");
			setSchedulesListState(newSchedulesListState);
			setIsLoadingScreen(false);
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getScheduleList ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getScheduleList ex`);
			setIsLoadingScreen(false);
		}
	};

	const getTemplatesList = async () => {
		let serviceClient = new TemplatesService();
		let expectedCodes: Array<number> = [200];
		let i: number;
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.getTemplateByFilter(
				TPActiveOptions.ALL.toString(),
				TPGlobal.PersonWhoReportsTemplateType,
				false,
				true,
				expectedCodes,
			);
			let newTemplatesListState: Array<TPKeyValue> = [];
			let templatesListData: Array<TemplateViewModel>;
			templatesListData = responseRequest;
			for (i = 0; i <= templatesListData.length - 1; i++) {
				//todo remove filter and create new api to include tempalte filtertype
				if (
					templatesListData[i].templateTypeId.toLocaleLowerCase() ===
					TPGlobal.PersonWhoReportsTemplateType.toLocaleLowerCase()
				) {
					let keyvalue: TPKeyValue = {
						key: templatesListData[i].id,
						value: templatesListData[i].localizedDescription,
					};
					newTemplatesListState.push(keyvalue);
				}
			}
			let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
			newTemplatesListState.unshift(intitialKeyValue);
			setSelectedTemplate("");
			setTemplatesListState(newTemplatesListState);
			setIsLoadingScreen(false);
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getTemplatesList ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getTemplatesList ex`);
			setIsLoadingScreen(false);
		}
	};

	const getTree1IdList = async () => {
		let serviceClient = new TreeService();
		let expectedCodes: Array<number> = [200];
		let i: number;
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.getTreeByFilterIsClassifier(
				TPActiveOptions.ACTIVE.toString(),
				false,
				true,
				expectedCodes,
			);
			let newTreeListState: Array<TPKeyValue> = [];
			let treeListData: Array<TreeViewModel>;
			treeListData = responseRequest;
			let existNA_TREE: boolean = false;
			for (i = 0; i <= treeListData.length - 1; i++) {
				//todo remove filter and create new api to include template filtertype
				if (treeListData[i].id.toUpperCase() === TPGlobal.Tree.NA_TreeCode) {
					existNA_TREE = true;
				}
				let keyvalue: TPKeyValue = {
					key: treeListData[i].id,
					value: treeListData[i].localizedName,
				};
				newTreeListState.push(keyvalue);
			}

			let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
			newTreeListState.unshift(intitialKeyValue);

			//find idx of NA_TREE
			if (existNA_TREE) {
				let idxNATree: number = newTreeListState.findIndex(
					(item) => item.key.toUpperCase() === TPGlobal.Tree.NA_TreeCode,
				);
				let element = newTreeListState.splice(idxNATree, 1);
				newTreeListState.splice(1, 0, {
					key: element[0].key,
					value: element[0].value,
				});
			}
			setSelectedTreeId1("");
			setTreeListState(newTreeListState);
			setIsLoadingScreen(false);
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getTree1IdList ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getTree1IdList ex`);
			setIsLoadingScreen(false);
		}
	};

	const handleOnIdChange = (newId: string) => {
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.idBaseLevel = newId;
		newInsertUpdateState.idErrorMessage = "";
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleOrganizationChange = (e: any, item: TPKeyValue) => {
		let newCalculatedDescription: string;
		setSelectedOrganization(e.target.value);
		if (e.target.value === "" || selectedRelation === "") {
			newCalculatedDescription = "";
		} else {
			newCalculatedDescription = item.value + " / " + selectedRelationName;
		}
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.organizationIdErrorMessage = "";
		setInsertUpdateState(newInsertUpdateState);
		setSelectedDescription(newCalculatedDescription);
		setSelectedOrganizationName(item.value);
	};

	const handleRelationChange = (e: any, item: TPKeyValue) => {
		let newCalculatedDescription: string;
		setSelectedRelation(e.target.value);
		if (selectedOrganization === "" || e.target.value === "") {
			newCalculatedDescription = "";
		} else {
			newCalculatedDescription = selectedOrganizationName + " / " + item.value;
		}
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.relationIdErrorMessage = "";
		setInsertUpdateState(newInsertUpdateState);
		setSelectedDescription(newCalculatedDescription);
		setSelectedRelationName(item.value);
	};

	const handleCalendarsChange = (e: any, item: TPKeyValue) => {
		setSelectedCalendar(e.target.value);
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.calendarIdErrorMessage = "";
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleScheduleChange = (e: any, item: TPKeyValue) => {
		setSelectedSchedule(e.target.value);
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.scheduleIdErrorMessage = "";
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleTimeZoneChange = (e: any, item: TPKeyValue) => {
		setSelectedTimeZone(e.target.value);
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.timeZoneErrorMessage = "";
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleTemplateChange = (e: any, item: TPKeyValue) => {
		setSelectedTemplate(e.target.value);
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.informerDataIdErrorMessage = "";
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleIsActiveChange = (e: any) => {
		setInsertUpdateState({
			...insertUpdateState,
			isActive: !insertUpdateState.isActive,
		});
	};

	const handleTree1Change = (e: any) => {
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.treeId1ErrorMessage = "";
		setSelectedTreeId1(e.target.value);
		setInsertUpdateState(newInsertUpdateState);
		if (e.target.value === TPGlobal.Tree.NA_TreeCode || e.target.value === "") {
			let newRecordLanguageListC1 = [...recordLanguageListC1];
			for (let i = 0; i < newRecordLanguageListC1.length; i++) {
				newRecordLanguageListC1[i].value = "";
			}
			setRecordLanguageListC1(newRecordLanguageListC1);
		}
	};

	const handleTree2Change = (e: any) => {
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.treeId2ErrorMessage = "";
		setSelectedTreeId2(e.target.value);
		setInsertUpdateState(newInsertUpdateState);
		if (e.target.value === TPGlobal.Tree.NA_TreeCode || e.target.value === "") {
			let newRecordLanguageListC2 = [...recordLanguageListC2];
			for (let i = 0; i < newRecordLanguageListC2.length; i++) {
				newRecordLanguageListC2[i].value = "";
			}
			setRecordLanguageListC2(newRecordLanguageListC2);
		}
	};

	const handleTree3Change = (e: any) => {
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.treeId3ErrorMessage = "";
		setSelectedTreeId3(e.target.value);
		setInsertUpdateState(newInsertUpdateState);
		if (e.target.value === TPGlobal.Tree.NA_TreeCode || e.target.value === "") {
			let newRecordLanguageListC3 = [...recordLanguageListC3];
			for (let i = 0; i < newRecordLanguageListC3.length; i++) {
				newRecordLanguageListC3[i].value = "";
			}
			setRecordLanguageListC3(newRecordLanguageListC3);
		}
	};

	const handleTree4Change = (e: any) => {
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.treeId4ErrorMessage = "";
		setSelectedTreeId4(e.target.value);
		setInsertUpdateState(newInsertUpdateState);
		if (e.target.value === TPGlobal.Tree.NA_TreeCode || e.target.value === "") {
			let newRecordLanguageListC4 = [...recordLanguageListC4];
			for (let i = 0; i < newRecordLanguageListC4.length; i++) {
				newRecordLanguageListC4[i].value = "";
			}
			setRecordLanguageListC4(newRecordLanguageListC4);
		}
	};

	const handleTree5Change = (e: any) => {
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.treeId5ErrorMessage = "";
		setSelectedTreeId5(e.target.value);
		setInsertUpdateState(newInsertUpdateState);
		if (e.target.value === TPGlobal.Tree.NA_TreeCode || e.target.value === "") {
			let newRecordLanguageListC5 = [...recordLanguageListC5];
			for (let i = 0; i < newRecordLanguageListC5.length; i++) {
				newRecordLanguageListC5[i].value = "";
			}
			setRecordLanguageListC5(newRecordLanguageListC5);
		}
	};

	const handleLanguageC1Change = (index: number, newName: string) => {
		let newRecordLanguageListC1 = [...recordLanguageListC1];
		newRecordLanguageListC1[index].value = newName;
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.c1ErrorMessages[index] = "";
		setRecordLanguageListC1(newRecordLanguageListC1);
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleLanguageC2Change = (index: number, newName: string) => {
		let newRecordLanguageListC2 = [...recordLanguageListC2];
		newRecordLanguageListC2[index].value = newName;
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.c2ErrorMessages[index] = "";
		setRecordLanguageListC2(newRecordLanguageListC2);
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleLanguageC3Change = (index: number, newName: string) => {
		let newRecordLanguageListC3 = [...recordLanguageListC3];
		newRecordLanguageListC3[index].value = newName;
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.c3ErrorMessages[index] = "";
		setRecordLanguageListC3(newRecordLanguageListC3);
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleLanguageC4Change = (index: number, newName: string) => {
		let newRecordLanguageListC4 = [...recordLanguageListC4];
		newRecordLanguageListC4[index].value = newName;
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.c4ErrorMessages[index] = "";
		setRecordLanguageListC4(newRecordLanguageListC4);
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleLanguageC5Change = (index: number, newName: string) => {
		let newRecordLanguageListC5 = [...recordLanguageListC5];
		newRecordLanguageListC5[index].value = newName;
		let newInsertUpdateState = { ...insertUpdateState };
		newInsertUpdateState.c5ErrorMessages[index] = "";
		setRecordLanguageListC5(newRecordLanguageListC5);
		setInsertUpdateState(newInsertUpdateState);
	};

	const handleOkButtonClick = async () => {
		let i: number;
		let n: number;
		let recordInputDTO: OrganizationsRelationsInputDTO = {
			// id: insertUpdateState.idBaseLevel,
			id: mode == "Insert" ? referenceId : recordId,
			isActive: insertUpdateState.isActive ?? false,
			organizationId: selectedOrganization,
			relationId: selectedRelation,
			tree1Id: selectedTreeId1,
			tree2Id: selectedTreeId2,
			tree3Id: selectedTreeId3,
			tree4Id: selectedTreeId4,
			tree5Id: selectedTreeId5,
			calendarId: selectedCalendar,
			timeZoneId: selectedTimeZone,
			scheduleId: selectedSchedule,
			informerDataId: selectedTemplate,
			jSonTree1DescriptionLanguages: [],
			jSonTree2DescriptionLanguages: [],
			jSonTree3DescriptionLanguages: [],
			jSonTree4DescriptionLanguages: [],
			jSonTree5DescriptionLanguages: [],
		};
		n = recordLanguageListC1.length;
		for (i = 0; i <= n - 1; i++) {
			let item: TPKeyValue;
			item = recordLanguageListC1[i];
			recordInputDTO.jSonTree1DescriptionLanguages.push({
				order: i + 1,
				languageId: item.key,
				localizedValue: item.value,
			});
		}
		n = recordLanguageListC2.length;
		for (i = 0; i <= n - 1; i++) {
			let item: TPKeyValue;
			item = recordLanguageListC2[i];
			recordInputDTO.jSonTree2DescriptionLanguages.push({
				order: i + 1,
				languageId: item.key,
				localizedValue: item.value,
			});
		}
		n = recordLanguageListC3.length;
		for (i = 0; i <= n - 1; i++) {
			let item: TPKeyValue;
			item = recordLanguageListC3[i];
			recordInputDTO.jSonTree3DescriptionLanguages.push({
				order: i + 1,
				languageId: item.key,
				localizedValue: item.value,
			});
		}
		n = recordLanguageListC4.length;
		for (i = 0; i <= n - 1; i++) {
			let item: TPKeyValue;
			item = recordLanguageListC4[i];
			recordInputDTO.jSonTree4DescriptionLanguages.push({
				order: i + 1,
				languageId: item.key,
				localizedValue: item.value,
			});
		}
		n = recordLanguageListC5.length;
		for (i = 0; i <= n - 1; i++) {
			let item: TPKeyValue;
			item = recordLanguageListC5[i];
			recordInputDTO.jSonTree5DescriptionLanguages.push({
				order: i + 1,
				languageId: item.key,
				localizedValue: item.value,
			});
		}
		let inputDTOValidator = new OrganizationsRelationsInputDTOValidator(
			selectedTreeId1,
			selectedTreeId2,
			selectedTreeId3,
			selectedTreeId4,
			selectedTreeId5,
		);
		let resultValidator = inputDTOValidator.validate(recordInputDTO);
		if (!TPGlobal.TPIsEmpty(resultValidator)) {
			let newInsertUpdateState = { ...insertUpdateState };
			if (resultValidator.id) {
				newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
					resultValidator.id,
				);
			} else {
				newInsertUpdateState.idErrorMessage = "";
			}
			if (resultValidator.organizationId) {
				newInsertUpdateState.organizationIdErrorMessage =
					await TPI18N.GetResource(resultValidator.organizationId);
			} else {
				newInsertUpdateState.organizationIdErrorMessage = "";
			}
			if (resultValidator.relationId) {
				newInsertUpdateState.relationIdErrorMessage = await TPI18N.GetResource(
					resultValidator.relationId,
				);
			} else {
				newInsertUpdateState.relationIdErrorMessage = "";
			}
			if (resultValidator.tree1Id) {
				newInsertUpdateState.treeId1ErrorMessage = await TPI18N.GetResource(
					resultValidator.tree1Id,
				);
			} else {
				newInsertUpdateState.treeId1ErrorMessage = "";
			}
			if (resultValidator.tree2Id) {
				newInsertUpdateState.treeId2ErrorMessage = await TPI18N.GetResource(
					resultValidator.tree2Id,
				);
			} else {
				newInsertUpdateState.treeId2ErrorMessage = "";
			}
			if (resultValidator.tree3Id) {
				newInsertUpdateState.treeId3ErrorMessage = await TPI18N.GetResource(
					resultValidator.tree3Id,
				);
			} else {
				newInsertUpdateState.treeId3ErrorMessage = "";
			}
			if (resultValidator.tree4Id) {
				newInsertUpdateState.treeId4ErrorMessage = await TPI18N.GetResource(
					resultValidator.tree4Id,
				);
			} else {
				newInsertUpdateState.treeId4ErrorMessage = "";
			}
			if (resultValidator.tree5Id) {
				newInsertUpdateState.treeId5ErrorMessage = await TPI18N.GetResource(
					resultValidator.tree5Id,
				);
			} else {
				newInsertUpdateState.treeId5ErrorMessage = "";
			}
			if (resultValidator.calendarId) {
				newInsertUpdateState.calendarIdErrorMessage = await TPI18N.GetResource(
					resultValidator.calendarId,
				);
			} else {
				newInsertUpdateState.calendarIdErrorMessage = "";
			}

			if (resultValidator.timeZoneId) {
				newInsertUpdateState.timeZoneErrorMessage = await TPI18N.GetResource(
					resultValidator.timeZoneId,
				);
			} else {
				newInsertUpdateState.timeZoneErrorMessage = "";
			}

			if (resultValidator.scheduleId) {
				newInsertUpdateState.scheduleIdErrorMessage = await TPI18N.GetResource(
					resultValidator.scheduleId,
				);
			} else {
				newInsertUpdateState.scheduleIdErrorMessage = "";
			}

			if (resultValidator.informerDataId) {
				newInsertUpdateState.informerDataIdErrorMessage =
					await TPI18N.GetResource(resultValidator.informerDataId);
			} else {
				newInsertUpdateState.informerDataIdErrorMessage = "";
			}

			if (resultValidator.jSonTree1DescriptionLanguages) {
				n = recordLanguageListC1.length;
				for (i = 0; i < n; i++) {
					if (resultValidator.jSonTree1DescriptionLanguages[i] != null) {
						let languageName: string =
							TPGlobal.TPClientAvailableLanguages[i].name;
						newInsertUpdateState.c1ErrorMessages[i] = await TPI18N.GetResource(
							String(resultValidator.jSonTree1DescriptionLanguages[i]),
						);
						newInsertUpdateState.c1ErrorMessages[i] =
							newInsertUpdateState.c1ErrorMessages[i]
								.replace("{classifierIndex}", "1")
								.replace("{language}", languageName);
					}
				}
			} else {
				n = recordLanguageListC1.length;
				for (i = 0; i < n; i++) {
					newInsertUpdateState.c1ErrorMessages[i] = "";
				}
			}

			if (resultValidator.jSonTree2DescriptionLanguages) {
				n = recordLanguageListC2.length;
				for (i = 0; i < n; i++) {
					if (resultValidator.jSonTree2DescriptionLanguages[i] != null) {
						let languageName: string =
							TPGlobal.TPClientAvailableLanguages[i].name;
						newInsertUpdateState.c2ErrorMessages[i] = await TPI18N.GetResource(
							String(resultValidator.jSonTree2DescriptionLanguages[i]),
						);
						newInsertUpdateState.c2ErrorMessages[i] =
							newInsertUpdateState.c2ErrorMessages[i]
								.replace("{classifierIndex}", "2")
								.replace("{language}", languageName);
					}
				}
			} else {
				n = recordLanguageListC2.length;
				for (i = 0; i < n; i++) {
					newInsertUpdateState.c2ErrorMessages[i] = "";
				}
			}

			if (resultValidator.jSonTree3DescriptionLanguages) {
				n = recordLanguageListC3.length;
				for (i = 0; i < n; i++) {
					if (resultValidator.jSonTree3DescriptionLanguages[i] != null) {
						let languageName: string =
							TPGlobal.TPClientAvailableLanguages[i].name;
						newInsertUpdateState.c3ErrorMessages[i] = await TPI18N.GetResource(
							String(resultValidator.jSonTree3DescriptionLanguages[i]),
						);
						newInsertUpdateState.c3ErrorMessages[i] =
							newInsertUpdateState.c3ErrorMessages[i]
								.replace("{classifierIndex}", "3")
								.replace("{language}", languageName);
					}
				}
			} else {
				n = recordLanguageListC3.length;
				for (i = 0; i < n; i++) {
					newInsertUpdateState.c3ErrorMessages[i] = "";
				}
			}

			if (resultValidator.jSonTree4DescriptionLanguages) {
				n = recordLanguageListC4.length;
				for (i = 0; i < n; i++) {
					if (resultValidator.jSonTree4DescriptionLanguages[i] != null) {
						let languageName: string =
							TPGlobal.TPClientAvailableLanguages[i].name;
						newInsertUpdateState.c4ErrorMessages[i] = await TPI18N.GetResource(
							String(resultValidator.jSonTree4DescriptionLanguages[i]),
						);
						newInsertUpdateState.c4ErrorMessages[i] =
							newInsertUpdateState.c4ErrorMessages[i]
								.replace("{classifierIndex}", "4")
								.replace("{language}", languageName);
					}
				}
			} else {
				n = recordLanguageListC4.length;
				for (i = 0; i < n; i++) {
					newInsertUpdateState.c4ErrorMessages[i] = "";
				}
			}

			if (resultValidator.jSonTree5DescriptionLanguages) {
				n = recordLanguageListC5.length;
				for (i = 0; i < n; i++) {
					if (resultValidator.jSonTree5DescriptionLanguages[i] != null) {
						let languageName: string =
							TPGlobal.TPClientAvailableLanguages[i].name;
						newInsertUpdateState.c5ErrorMessages[i] = await TPI18N.GetResource(
							String(resultValidator.jSonTree5DescriptionLanguages[i]),
						);
						newInsertUpdateState.c5ErrorMessages[i] =
							newInsertUpdateState.c5ErrorMessages[i]
								.replace("{classifierIndex}", "5")
								.replace("{language}", languageName);
					}
				}
			} else {
				n = recordLanguageListC5.length;
				for (i = 0; i < n; i++) {
					newInsertUpdateState.c5ErrorMessages[i] = "";
				}
			}

			setInsertUpdateState(newInsertUpdateState);
			return;
		}
		let duplicateArrayValidator: Array<string> = [];
		duplicateArrayValidator.push(selectedTreeId1);
		duplicateArrayValidator.push(selectedTreeId2);
		duplicateArrayValidator.push(selectedTreeId3);
		duplicateArrayValidator.push(selectedTreeId4);
		duplicateArrayValidator.push(selectedTreeId5);
		if (existsDuplicates(duplicateArrayValidator)) {
			showToast(duplicateTreeMessage, TPToastTypes.error);
			return;
		}
		if (mode == "Insert") {
			await insertOrganizationRelation(recordInputDTO);
		} else {
			await updateOrganizationRelations(recordInputDTO);
		}
	};

	const existsDuplicates = (arr: Array<string>) => {
		let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
		// JS by default uses a crappy string compare.
		// (we use slice to clone the array so the
		// original array won't be modified)
		for (let i = 0; i < sorted_arr.length - 1; i++) {
			if (sorted_arr[i + 1] == sorted_arr[i]) {
				if (
					sorted_arr[i].toUpperCase() == TPGlobal.Tree.NA_TreeCode &&
					sorted_arr[i + 1].toUpperCase() == TPGlobal.Tree.NA_TreeCode
				) {
					return false;
				} else {
					return true;
				}
			}
		}
		return false;
	};

	const handleCopyDefaultValueClick = async (topic: string, language: string, index: number) => {
		let newInsertUpdateState = { ...insertUpdateState };
		switch (topic) {
			case 'C1':
				let newRecordLanguageListC1 = [...recordLanguageListC1];
				newRecordLanguageListC1[index].value = await TPI18N.GetText(
					TPGlobal.globalResourceSet,
					`Tree1DefaultValue`, language
				);
				copyToClipboard(newRecordLanguageListC1[index].value);
				if (newRecordLanguageListC1[index].value === `Tree1DefaultValue`) {
					newRecordLanguageListC1[index].value = '';
				}
				setRecordLanguageListC1(newRecordLanguageListC1);
				newInsertUpdateState.c1ErrorMessages[index] = '';
				setInsertUpdateState(newInsertUpdateState);
				break;
			case 'C2':
				let newRecordLanguageListC2 = [...recordLanguageListC2];
				newRecordLanguageListC2[index].value = await TPI18N.GetText(
					TPGlobal.globalResourceSet,
					`Tree2DefaultValue`, language
				);
				copyToClipboard(newRecordLanguageListC2[index].value);
				if (newRecordLanguageListC2[index].value === `Tree2DefaultValue`) {
					newRecordLanguageListC2[index].value = '';
				}
				setRecordLanguageListC2(newRecordLanguageListC2);
				newInsertUpdateState.c2ErrorMessages[index] = '';
				setInsertUpdateState(newInsertUpdateState);
				break;
			case 'C3':
				let newRecordLanguageListC3 = [...recordLanguageListC3];
				newRecordLanguageListC3[index].value = await TPI18N.GetText(
					TPGlobal.globalResourceSet,
					`Tree3DefaultValue`, language
				);
				copyToClipboard(newRecordLanguageListC3[index].value);
				if (newRecordLanguageListC3[index].value === `Tree3DefaultValue`) {
					newRecordLanguageListC3[index].value = '';
				}
				setRecordLanguageListC3(newRecordLanguageListC3);
				newInsertUpdateState.c3ErrorMessages[index] = '';
				setInsertUpdateState(newInsertUpdateState);
				break;
			case 'C4':
				let newRecordLanguageListC4 = [...recordLanguageListC4];
				newRecordLanguageListC4[index].value = await TPI18N.GetText(
					TPGlobal.globalResourceSet,
					`Tree4DefaultValue`, language
				);
				copyToClipboard(newRecordLanguageListC4[index].value);
				if (newRecordLanguageListC4[index].value === `Tree4DefaultValue`) {
					newRecordLanguageListC4[index].value = '';
				}
				setRecordLanguageListC4(newRecordLanguageListC4);
				newInsertUpdateState.c4ErrorMessages[index] = '';
				setInsertUpdateState(newInsertUpdateState);
				break;
			case 'C5':
				let newRecordLanguageListC5 = [...recordLanguageListC5];
				newRecordLanguageListC5[index].value = await TPI18N.GetText(
					TPGlobal.globalResourceSet,
					`Tree5DefaultValue`, language
				);
				copyToClipboard(newRecordLanguageListC5[index].value);
				if (newRecordLanguageListC5[index].value === `Tree5DefaultValue`) {
					newRecordLanguageListC5[index].value = '';
				}
				setRecordLanguageListC5(newRecordLanguageListC5);
				newInsertUpdateState.c5ErrorMessages[index] = '';
				setInsertUpdateState(newInsertUpdateState);
				break;
			default:
				break;
		}
	};

	const insertOrganizationRelation = async (
		inputDTO: OrganizationsRelationsInputDTO,
	) => {
		let serviceClient = new OrganizationsRelationsService();
		let expectedCodes: Array<number> = [200];
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.insertOrganizationsRelations(
				inputDTO,
				true,
				true,
				expectedCodes,
			);
			setIsLoadingScreen(false);
			if (responseRequest.responseResult) {
				callBackResult({ result: "OK", recordId: recordId });
			}
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} insertOrganizationRelation ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} insertOrganizationRelation ex`);
			setIsLoadingScreen(false);
		}
	};

	const updateOrganizationRelations = async (
		inputDTO: OrganizationsRelationsInputDTO,
	) => {
		let serviceClient = new OrganizationsRelationsService();
		let expectedCodes: Array<number> = [200];
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.updateOrganizationsRelations(
				inputDTO,
				true,
				true,
				expectedCodes,
			);
			setIsLoadingScreen(false);
			if (responseRequest.responseResult) {
				callBackResult({ result: "OK", recordId: recordId });
			}
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} updateOrganizationRelations ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(
				`Error ${componentFileName} updateOrganizationRelations ex`,
			);
			setIsLoadingScreen(false);
		}
	};

	const getOrganizationRelationById = async (pRecordId: string) => {
		let serviceClient = new OrganizationsRelationsService();
		let expectedCodes: Array<number> = [200];
		let multilanguageRecordLanguagesListC1: Array<MultilanguageFieldValueViewModel> =
			[];
		let multilanguageRecordLanguagesListC2: Array<MultilanguageFieldValueViewModel> =
			[];
		let multilanguageRecordLanguagesListC3: Array<MultilanguageFieldValueViewModel> =
			[];
		let multilanguageRecordLanguagesListC4: Array<MultilanguageFieldValueViewModel> =
			[];
		let multilanguageRecordLanguagesListC5: Array<MultilanguageFieldValueViewModel> =
			[];

		let i: number;
		let j: number;
		try {
			setIsLoadingScreen(true);
			let responseRequest = await serviceClient.getById(
				pRecordId,
				false,
				true,
				expectedCodes,
			);
			let recordInfo: OrganizationsRelationsViewModel;
			recordInfo = { ...responseRequest };
			setSelectedOrganization(recordInfo.organizationId);
			setSelectedRelation(recordInfo.relationId);
			if (
				recordInfo.localizedDescription &&
				recordInfo.localizedDescription.indexOf("/") !== -1
			) {
				setSelectedOrganizationName(
					recordInfo.localizedDescription.split("/")[0],
				);
				setSelectedRelationName(recordInfo.localizedDescription.split("/")[1]);
				setSelectedDescription(recordInfo.localizedDescription);
			}

			setSelectedCalendar(recordInfo.calendarId);
			setSelectedTimeZone(recordInfo.timeZoneId);
			setSelectedSchedule(recordInfo.scheduleId);
			setSelectedTemplate(recordInfo.informerDataId);
			setSelectedTreeId1(recordInfo.tree1Id);
			setSelectedTreeId2(recordInfo.tree2Id);
			setSelectedTreeId3(recordInfo.tree3Id);
			setSelectedTreeId4(recordInfo.tree4Id);
			setSelectedTreeId5(recordInfo.tree5Id);
			let newInsertUpdateState = { ...insertUpdateState };
			newInsertUpdateState.idBaseLevel = recordInfo.id;
			newInsertUpdateState.isActive = recordInfo.isActive ?? false;

			let newRecordLanguageListC1 = [];
			let newRecordLanguageListC2 = [];
			let newRecordLanguageListC3 = [];
			let newRecordLanguageListC4 = [];
			let newRecordLanguageListC5 = [];
			for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
				let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
				let keyValueElement: TPKeyValue = { key: item.id, value: "" };
				newRecordLanguageListC1.push({ ...keyValueElement });
				newRecordLanguageListC2.push({ ...keyValueElement });
				newRecordLanguageListC3.push({ ...keyValueElement });
				newRecordLanguageListC4.push({ ...keyValueElement });
				newRecordLanguageListC5.push({ ...keyValueElement });
			}
			if (recordInfo.tree1Id !== TPGlobal.Tree.NA_TreeCode) {
				multilanguageRecordLanguagesListC1 = await getRecordLanguageList(
					pRecordId + "_C1",
					multilanguageFieldNameC_1,
				);
				if (multilanguageRecordLanguagesListC1.length == 0) {
					TPLog.Log(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC1`,
						TPLogType.ERROR,
						"recordLanguagesList multilanguageRecordLanguagesListC1 is empty",
					);
					console.error(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC1 is empty`,
					);
					setIsLoadingScreen(false);
					return;
				}
				for (i = 0; i <= newRecordLanguageListC1.length - 1; i++) {
					for (j = 0; j <= multilanguageRecordLanguagesListC1.length - 1; j++) {
						if (
							newRecordLanguageListC1[i].key.toLowerCase() ==
							multilanguageRecordLanguagesListC1[j].languageId.toLowerCase()
						) {
							newRecordLanguageListC1[i].value =
								multilanguageRecordLanguagesListC1[j].recordDescription;
							break;
						}
					}
				}
			}

			if (recordInfo.tree2Id !== TPGlobal.Tree.NA_TreeCode) {
				multilanguageRecordLanguagesListC2 = await getRecordLanguageList(
					pRecordId + "_C2",
					multilanguageFieldNameC_2,
				);
				if (multilanguageRecordLanguagesListC2.length == 0) {
					TPLog.Log(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC2`,
						TPLogType.ERROR,
						"recordLanguagesList multilanguageRecordLanguagesListC2 is empty",
					);
					console.error(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC2 is empty`,
					);
					setIsLoadingScreen(false);
					return;
				}
				for (i = 0; i <= newRecordLanguageListC2.length - 1; i++) {
					for (j = 0; j <= multilanguageRecordLanguagesListC2.length - 1; j++) {
						if (
							newRecordLanguageListC2[i].key.toLowerCase() ==
							multilanguageRecordLanguagesListC2[j].languageId.toLowerCase()
						) {
							newRecordLanguageListC2[i].value =
								multilanguageRecordLanguagesListC2[j].recordDescription;
							break;
						}
					}
				}
			}

			if (recordInfo.tree3Id !== TPGlobal.Tree.NA_TreeCode) {
				multilanguageRecordLanguagesListC3 = await getRecordLanguageList(
					pRecordId + "_C3",
					multilanguageFieldNameC_3,
				);
				if (multilanguageRecordLanguagesListC3.length == 0) {
					TPLog.Log(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC3`,
						TPLogType.ERROR,
						"recordLanguagesList multilanguageRecordLanguagesListC3 is empty",
					);
					console.error(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC3 is empty`,
					);
					setIsLoadingScreen(false);
					return;
				}
				for (i = 0; i <= newRecordLanguageListC3.length - 1; i++) {
					for (j = 0; j <= multilanguageRecordLanguagesListC3.length - 1; j++) {
						if (
							newRecordLanguageListC3[i].key.toLowerCase() ==
							multilanguageRecordLanguagesListC3[j].languageId.toLowerCase()
						) {
							newRecordLanguageListC3[i].value =
								multilanguageRecordLanguagesListC3[j].recordDescription;
							break;
						}
					}
				}
			}

			if (recordInfo.tree4Id !== TPGlobal.Tree.NA_TreeCode) {
				multilanguageRecordLanguagesListC4 = await getRecordLanguageList(
					pRecordId + "_C4",
					multilanguageFieldNameC_4,
				);
				if (multilanguageRecordLanguagesListC4.length == 0) {
					TPLog.Log(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC4`,
						TPLogType.ERROR,
						"recordLanguagesList multilanguageRecordLanguagesListC4 is empty",
					);
					console.error(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC4 is empty`,
					);
					setIsLoadingScreen(false);
					return;
				}
				for (i = 0; i <= newRecordLanguageListC4.length - 1; i++) {
					for (j = 0; j <= multilanguageRecordLanguagesListC4.length - 1; j++) {
						if (
							newRecordLanguageListC4[i].key.toLowerCase() ==
							multilanguageRecordLanguagesListC4[j].languageId.toLowerCase()
						) {
							newRecordLanguageListC4[i].value =
								multilanguageRecordLanguagesListC4[j].recordDescription;
							break;
						}
					}
				}
			}

			if (recordInfo.tree5Id !== TPGlobal.Tree.NA_TreeCode) {
				multilanguageRecordLanguagesListC5 = await getRecordLanguageList(
					pRecordId + "_C5",
					multilanguageFieldNameC_5,
				);
				if (multilanguageRecordLanguagesListC5.length == 0) {
					TPLog.Log(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC5`,
						TPLogType.ERROR,
						"recordLanguagesList multilanguageRecordLanguagesListC5 is empty",
					);
					console.error(
						`Error ${componentFileName} getOrganizationRelationById getRecordLanguageList multilanguageRecordLanguagesListC5 is empty`,
					);
					setIsLoadingScreen(false);
					return;
				}
				for (i = 0; i <= newRecordLanguageListC5.length - 1; i++) {
					for (j = 0; j <= multilanguageRecordLanguagesListC5.length - 1; j++) {
						if (
							newRecordLanguageListC5[i].key.toLowerCase() ==
							multilanguageRecordLanguagesListC5[j].languageId.toLowerCase()
						) {
							newRecordLanguageListC5[i].value =
								multilanguageRecordLanguagesListC5[j].recordDescription;
							break;
						}
					}
				}
			}

			setRecordLanguageListC1(newRecordLanguageListC1);
			setRecordLanguageListC2(newRecordLanguageListC2);
			setRecordLanguageListC3(newRecordLanguageListC3);
			setRecordLanguageListC4(newRecordLanguageListC4);
			setRecordLanguageListC5(newRecordLanguageListC5);
			setInsertUpdateState(newInsertUpdateState);
			setIsLoadingScreen(false);
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getRelationById ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getRelationById ex`);
			setIsLoadingScreen(false);
		}
	};

	const getRecordLanguageList = async (
		pRecordId: String,
		multilanguageFieldName: string,
	): Promise<Array<MultilanguageFieldValueViewModel>> => {
		let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
		let expectedCodes: Array<number> = [200];
		try {
			let responseRequest =
				await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
					multilanguageTableName,
					multilanguageFieldName,
					pRecordId,
					false,
					true,
					expectedCodes,
				);

			let recordInfo: Array<MultilanguageFieldValueViewModel>;
			recordInfo = [...responseRequest];
			return recordInfo;
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} getRecordLanguageList ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} getRecordLanguageList ex`);
			return [];
		}
	};

	const handleCancelButtonClick = () => {
		callBackResult({ result: "CANCEL", recordId: recordId });
	};

	const generalAutomaticId = async () => {
		let serviceClient = new SequenceService();
		let expectedCodes: Array<number> = [200];
		try {
			setIsLoadingScreen(true);

			let responseRequest = await serviceClient.generalAutomaticId(
				false,
				true,
				expectedCodes,
				SequeceGeneratorSequencesNameEnum.SQBALE,
			);

			setIsLoadingScreen(false);
			if (responseRequest.responseResult) {
				let result = responseRequest?.responseData?.data[0]?.sequenceCode;
				setReferenceId(result);
			}
		} catch (error) {
			TPLog.Log(
				`Error ${componentFileName} updatetFunction ex`,
				TPLogType.ERROR,
				error,
			);
			console.error(`Error ${componentFileName} updatetFunction ex`);
			setIsLoadingScreen(false);
		}
	};

	//Run only once to set resources and load relation in update mode
	useEffect(() => {
		loadResourcesAndScreenInfo();
		mode == "Insert" && generalAutomaticId();
	}, []);

	return (
		//#region  Render
		<ContentVerticalTabInsertUpdateStyled>
			<TPLoadingOverlay active={isLoadingScreen}>
				<div className="row">
					<div className="col-11">
						<TPPageTitle>{titleLabel}</TPPageTitle>
						{mode == "Update" ? (
							<TPPageSubTitle>
								{subTitleLabel}&nbsp;
								{recordId}
							</TPPageSubTitle>
						) : null}
						<hr />
					</div>
				</div>
				<div className="row">
					<div className="col-11">
						<TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
					</div>
				</div>
				<div className="row">
					<div className="col-11">
						<TPPageSection>
							<div className="row">
								<div className="col-4">
									<div className="form-group">
										<TPTextBox
											id="IdTextBox"
											labelText={idLabel}
											isMandatory={true}
											// value={insertUpdateState.idBaseLevel}
											value={mode == "Insert" ? referenceId : recordId}
											onChange={
												mode == "Insert"
													? (e: any) => handleOnIdChange(e.target.value)
													: () => {
														TPGlobal.foo();
													}
											}
											maxLength={20}
											// disabled={mode == "Insert" ? false : true}
											disabled
											errorMessage={insertUpdateState.idErrorMessage}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<div className="form-group">
										<TPSelect
											id="IdSelect"
											isMandatory={true}
											onChange={(e: any, item: TPKeyValue) =>
												handleOrganizationChange(e, item)
											}
											dataSource={organizationsList}
											value={selectedOrganization}
											labelText={organizationLabel}
											errorMessage={
												insertUpdateState.organizationIdErrorMessage
											}
										></TPSelect>
									</div>
								</div>
								<div className="col-4">
									<div className="form-group">
										<TPSelect
											id="IdSelect"
											isMandatory={true}
											onChange={(e: any, item: TPKeyValue) =>
												handleRelationChange(e, item)
											}
											dataSource={relationsList}
											value={selectedRelation}
											labelText={relationLabel}
											errorMessage={insertUpdateState.relationIdErrorMessage}
										></TPSelect>
									</div>
								</div>
								<div className="col-4">
									<TPTextBox
										id="IdTextBox"
										disabled={true}
										value={selectedDescription}
										onChange={() => {
											TPGlobal.foo();
										}}
										labelText={descriptionLabel}
									></TPTextBox>
								</div>
							</div>
						</TPPageSection>
					</div>
				</div>
				<div className="row">
					<div className="col-11">
						<TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
					</div>
				</div>
				<div className="row">
					<div className="col-11">
						<TPPageSection>
							<div className="row">
								<div className="col-4">
									<div className="form-group">
										<TPSelect
											id="IdSelect"
											isMandatory={true}
											onChange={(e: any, item: TPKeyValue) =>
												handleCalendarsChange(e, item)
											}
											dataSource={calendarsList}
											value={selectedCalendar}
											labelText={calendarLabel}
											errorMessage={insertUpdateState.calendarIdErrorMessage}
										></TPSelect>
									</div>
								</div>
								<div className="col-4">
									<div className="form-group">
										<TPSelect
											id="IdSelect"
											isMandatory={true}
											onChange={(e: any, item: TPKeyValue) =>
												handleTimeZoneChange(e, item)
											}
											dataSource={timezonesList}
											value={selectedTimeZone}
											labelText={timeZoneLabel}
											errorMessage={insertUpdateState.timeZoneErrorMessage}
										></TPSelect>
									</div>
								</div>
								<div className="col-4">
									<div className="form-group">
										<TPSelect
											id="IdSelect"
											isMandatory={true}
											onChange={(e: any, item: TPKeyValue) =>
												handleScheduleChange(e, item)
											}
											dataSource={schedulesList}
											value={selectedSchedule}
											labelText={scheduleLabel}
											errorMessage={insertUpdateState.scheduleIdErrorMessage}
										></TPSelect>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-4" style={{ display: "none" }}>
									<div className="form-group">
										<TPSelect
											id="IdSelect"
											onChange={(e: any, item: TPKeyValue) =>
												handleTemplateChange(e, item)
											}
											dataSource={templatesList}
											value={selectedTemplate}
											labelText={templateLabel}
											errorMessage={
												insertUpdateState.informerDataIdErrorMessage
											}
										></TPSelect>
									</div>
								</div>
								<div className="col-4">
									<div className="form-group mt-4">
										<TPCheckBox
											id="IdCheckBox"
											labelText={isActiveLabel}
											checked={insertUpdateState.isActive}
											onChange={handleIsActiveChange}
										></TPCheckBox>
									</div>
								</div>
							</div>
						</TPPageSection>
					</div>
				</div>
				<div className="row">
					<div className="col-11">
						<TPPageSectionTitle>{classifiersSectionLabel}</TPPageSectionTitle>
					</div>
				</div>
				<div className="row">
					<div className="col-11">
						<TPPageSection>
							<div className="row">
								<div className="col-12 text-center">
									<TPTableCondensedContainer className="tpcondensed-table tpw100per">
										<thead>
											<tr>
												<th>{classificationLabel1}</th>
												<th>{classificationLabel2}</th>
												<th>{classificationLabel3}</th>
												<th>{classificationLabel4}</th>
												<th>{classificationLabel5}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<div className="tpalignbaseline">
														<TPSelect
															id="IdSelect"
															isMandatory={true}
															onChange={handleTree1Change}
															dataSource={treeList}
															value={selectedTreeId1}
															labelText={treeLabel}
															errorMessage={
																insertUpdateState.treeId1ErrorMessage
															}
															className="tpmargin-bottom5 tpmax-width-170"
														></TPSelect>
													</div>
												</td>
												<td>
													<div className="tpalignbaseline">
														<TPSelect
															id="IdSelect"
															isMandatory={true}
															onChange={handleTree2Change}
															dataSource={treeList}
															value={selectedTreeId2}
															labelText={treeLabel}
															errorMessage={
																insertUpdateState.treeId2ErrorMessage
															}
															className="tpmargin-bottom5 tpmax-width-170"
														></TPSelect>
													</div>
												</td>
												<td>
													<div className="tpalignbaseline">
														<TPSelect
															id="IdSelect"
															isMandatory={true}
															onChange={handleTree3Change}
															dataSource={treeList}
															value={selectedTreeId3}
															labelText={treeLabel}
															errorMessage={
																insertUpdateState.treeId3ErrorMessage
															}
															className="tpmargin-bottom5 tpmax-width-170"
														></TPSelect>
													</div>
												</td>
												<td>
													<div className="tpalignbaseline">
														<TPSelect
															id="IdSelect"
															isMandatory={true}
															onChange={handleTree4Change}
															dataSource={treeList}
															value={selectedTreeId4}
															labelText={treeLabel}
															errorMessage={
																insertUpdateState.treeId4ErrorMessage
															}
															className="tpmargin-bottom5 tpmax-width-170"
														></TPSelect>
													</div>
												</td>
												<td>
													<div className="tpalignbaseline">
														<TPSelect
															id="IdSelect"
															isMandatory={true}
															onChange={handleTree5Change}
															dataSource={treeList}
															value={selectedTreeId5}
															labelText={treeLabel}
															errorMessage={
																insertUpdateState.treeId5ErrorMessage
															}
															className="tpmargin-bottom5 tpmax-width-170"
														></TPSelect>
													</div>
												</td>
											</tr>
											{recordLanguageListC1.length > 0 &&
												recordLanguageListC2.length > 0 &&
												recordLanguageListC3.length > 0 &&
												recordLanguageListC4.length > 0 &&
												recordLanguageListC5.length > 0 &&
												TPGlobal.TPClientAvailableLanguages.map(
													function (item, index) {
														let rowJSX = (
															<tr>
																<td
																	key={"tdC1" + index.toString()}
																	className="text-start"
																>
																	<div
																		className="tpright"
																		key={"divC1" + index.toString()}
																	>
																		<TPTextBox
																			id="IdTextBox"
																			isMandatory={index == 0}
																			labelText={`${item.name}`}
																			value={recordLanguageListC1[index].value}
																			onChange={(e: any) =>
																				handleLanguageC1Change(
																					index,
																					e.target.value,
																				)
																			}
																			maxLength={100}
																			errorMessage={
																				insertUpdateState.c1ErrorMessages[index]
																			}
																			isHorizontal={true}
																			className="tpmargin-bottom5 tpmax-width-170"
																			disabled={
																				selectedTreeId1 ===
																				TPGlobal.Tree.NA_TreeCode ||
																				selectedTreeId1 === ""
																			}
																		/>
																		{selectedTreeId1 !==
																			TPGlobal.Tree.NA_TreeCode &&
																			selectedTreeId1 !== "" && (
																				<TPButton
																					id="IdButton"
																					type={TPButtonTypes.icon}
																					className="tprotate-90-and-scale2 mt-2"
																					icon={TPIconTypes.arrowcircledown}
																					onClick={() =>
																						handleCopyDefaultValueClick(
																							"C1",
																							recordLanguageListC1[index].key,
																							index,
																						)
																					}
																					tooltip={copyDefaultValueLabel}
																				/>
																			)}
																	</div>
																</td>
																<td
																	key={"tdC2" + index.toString()}
																	className="text-start"
																>
																	<div
																		className="tpright"
																		key={"divC2" + index.toString()}
																	>
																		<TPTextBox
																			id="IdTextBox"
																			isMandatory={index == 0}
																			labelText={`${item.name}`}
																			value={recordLanguageListC2[index].value}
																			onChange={(e: any) =>
																				handleLanguageC2Change(
																					index,
																					e.target.value,
																				)
																			}
																			maxLength={100}
																			errorMessage={
																				insertUpdateState.c2ErrorMessages[index]
																			}
																			isHorizontal={true}
																			className="tpmargin-bottom5 tpmax-width-170"
																			disabled={
																				selectedTreeId2 ===
																				TPGlobal.Tree.NA_TreeCode ||
																				selectedTreeId2 === ""
																			}
																		/>
																		{selectedTreeId2 !==
																			TPGlobal.Tree.NA_TreeCode &&
																			selectedTreeId2 !== "" && (
																				<TPButton
																					type={TPButtonTypes.icon}
																					className="tprotate-90-and-scale2 mt-2"
																					icon={TPIconTypes.arrowcircledown}
																					onClick={() =>
																						handleCopyDefaultValueClick(
																							"C2",
																							recordLanguageListC2[index].key,
																							index,
																						)
																					}
																					tooltip={copyDefaultValueLabel}
																				/>
																			)}
																	</div>
																</td>
																<td
																	key={"tdC3" + index.toString()}
																	className="text-start"
																>
																	<div
																		className="tpright"
																		key={"divC3" + index.toString()}
																	>
																		<TPTextBox
																			id="IdTextBox"
																			isMandatory={index == 0}
																			labelText={`${item.name}`}
																			value={recordLanguageListC3[index].value}
																			onChange={(e: any) =>
																				handleLanguageC3Change(
																					index,
																					e.target.value,
																				)
																			}
																			maxLength={100}
																			errorMessage={
																				insertUpdateState.c3ErrorMessages[index]
																			}
																			isHorizontal={true}
																			className="tpmargin-bottom5 tpmax-width-170"
																			disabled={
																				selectedTreeId3 ===
																				TPGlobal.Tree.NA_TreeCode ||
																				selectedTreeId3 === ""
																			}
																		/>
																		{selectedTreeId3 !==
																			TPGlobal.Tree.NA_TreeCode &&
																			selectedTreeId3 !== "" && (
																				<TPButton
																					id="IdButton"
																					type={TPButtonTypes.icon}
																					className="tprotate-90-and-scale2 mt-2"
																					icon={TPIconTypes.arrowcircledown}
																					onClick={() =>
																						handleCopyDefaultValueClick(
																							"C3",
																							recordLanguageListC3[index].key,
																							index,
																						)
																					}
																					tooltip={copyDefaultValueLabel}
																				/>
																			)}
																	</div>
																</td>
																<td
																	key={"tdC4" + index.toString()}
																	className="text-start"
																>
																	<div
																		className="tpright"
																		key={"divC4" + index.toString()}
																	>
																		<TPTextBox
																			id="IdTextBox"
																			isMandatory={index == 0}
																			labelText={`${item.name}`}
																			value={recordLanguageListC4[index].value}
																			onChange={(e: any) =>
																				handleLanguageC4Change(
																					index,
																					e.target.value,
																				)
																			}
																			maxLength={100}
																			errorMessage={
																				insertUpdateState.c4ErrorMessages[index]
																			}
																			isHorizontal={true}
																			className="tpmargin-bottom5 tpmax-width-170"
																			disabled={
																				selectedTreeId4 ===
																				TPGlobal.Tree.NA_TreeCode ||
																				selectedTreeId4 === ""
																			}
																		/>
																		{selectedTreeId4 !==
																			TPGlobal.Tree.NA_TreeCode &&
																			selectedTreeId4 !== "" && (
																				<TPButton
																					id="IdButton"
																					type={TPButtonTypes.icon}
																					className="tprotate-90-and-scale2 mt-2"
																					icon={TPIconTypes.arrowcircledown}
																					onClick={() =>
																						handleCopyDefaultValueClick(
																							"C4",
																							recordLanguageListC4[index].key,
																							index,
																						)
																					}
																					tooltip={copyDefaultValueLabel}
																				/>
																			)}
																	</div>
																</td>
																<td
																	key={"tdC5" + index.toString()}
																	className="text-start"
																>
																	<div
																		className="tpright"
																		key={"divC5" + index.toString()}
																	>
																		<TPTextBox
																			id="IdTextBox"
																			isMandatory={index == 0}
																			labelText={`${item.name}`}
																			value={recordLanguageListC5[index].value}
																			onChange={(e: any) =>
																				handleLanguageC5Change(
																					index,
																					e.target.value,
																				)
																			}
																			maxLength={100}
																			errorMessage={
																				insertUpdateState.c5ErrorMessages[index]
																			}
																			isHorizontal={true}
																			className="tpmargin-bottom5 tpmax-width-170"
																			disabled={
																				selectedTreeId5 ===
																				TPGlobal.Tree.NA_TreeCode ||
																				selectedTreeId5 === ""
																			}
																		/>
																		{selectedTreeId5 !==
																			TPGlobal.Tree.NA_TreeCode &&
																			selectedTreeId5 !== "" && (
																				<TPButton
																					id="IdButton"
																					type={TPButtonTypes.icon}
																					className="tprotate-90-and-scale2 mt-2"
																					icon={TPIconTypes.arrowcircledown}
																					onClick={() =>
																						handleCopyDefaultValueClick(
																							"C5",
																							recordLanguageListC5[index].key,
																							index,
																						)
																					}
																					tooltip={copyDefaultValueLabel}
																				/>
																			)}
																	</div>
																</td>
															</tr>
														);
														return rowJSX;
													},
												)}
										</tbody>
									</TPTableCondensedContainer>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<div className="alert alert-info">
										<TPIcon
											iconType={TPIconTypes.arrowcircledown}
											className="tprotate-90-and-scale2"
										></TPIcon>{" "}
										{helpMessageTooltip}
									</div>
								</div>
							</div>
						</TPPageSection>
					</div>
				</div>
				<div className="row">
					<div className="col-11">
						<TPPageAcceptCancelButtonsContainer>
							<TPButton
								id="IdButton"
								type={TPButtonTypes.primary}
								onClick={handleOkButtonClick}
							>
								{saveButtonLabel}
							</TPButton>
							<TPButton
								id="IdButton"
								type={TPButtonTypes.link}
								onClick={handleCancelButtonClick}
								className={"ms-2"}
							>
								{cancelButtonLabel}
							</TPButton>
						</TPPageAcceptCancelButtonsContainer>
					</div>
				</div>
			</TPLoadingOverlay>
		</ContentVerticalTabInsertUpdateStyled>

		//#endregion
	);
};

export default OrganizationsRelationsInsertUpdate;
