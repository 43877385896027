import {
  showToast,
  TPToastTypes,
} from "@/components/bootstrap/components/toasts/TPToast";
import axios from "axios";
import TPGlobal from "./TPGlobal";
import { TPKeyValue } from "./TPKeyValue";
import { TPLog, TPLogType } from "./TPLog";
import { HTTPResponse } from "./TPStandardResponse";

export class TPHTTPService {
  public async getData(
    url: string,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    headers?: Array<TPKeyValue>,
  ): Promise<HTTPResponse> {
    let response: HTTPResponse = new HTTPResponse();
    let finalHeaders: any = {};
    let i: number;
    try {
      if (headers != null) {
        for (i = 0; i <= headers.length - 1; i++) {
          finalHeaders[headers[i].key] = headers[i].value;
        }
      }
      let dataAxios = await axios({
        method: "get",
        url: url,
        headers: finalHeaders,
      });

      response.responseResult = expectedCodes.includes(dataAxios.status);
      response.responseData = dataAxios.data;

      if (showPositiveMessage && response.responseResult) {
        if (response.responseData.responseMessage.code >= 0) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.success,
          );
        }
      }

      return response;
    } catch (error: any) {
      TPLog.Log("TPHTTPService.ts  getData ex", TPLogType.ERROR, error);
      if (!localStorage.getItem("avoidforbidden")) {
        if (
          error.response.status.toString() === "401" ||
          error.response.status.toString() === "403"
        ) {
          showToast(TPGlobal.TPGeneralAccessDenied, TPToastTypes.error);
          setTimeout(() => {
            window.location.href = TPGlobal.tenantSelectorURL;
          }, 1000);
          return response;
        }
      }

      response.responseResult = expectedCodes.includes(error.response.status);
      response.responseData = error.response.data;

      if (showNegativeMessage && !response.responseResult) {
        if (
          response.responseData &&
          response.responseData.responseMessage.code < 0
        ) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.error,
          );
        } else {
          showToast(TPGlobal.TPGeneralHttpException, TPToastTypes.error);
        }
      }

      return response;
    }
  }

  public async deleteData(
    url: string,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    headers?: Array<TPKeyValue>,
  ): Promise<HTTPResponse> {
    let response: HTTPResponse = new HTTPResponse();
    let finalHeaders: any = {};
    let i: number;
    try {
      if (headers != null) {
        for (i = 0; i <= headers.length - 1; i++) {
          finalHeaders[headers[i].key] = headers[i].value;
        }
      }
      let dataAxios = await axios({
        method: "delete",
        url: url,
        headers: finalHeaders,
      });

      response.responseResult = expectedCodes.includes(dataAxios.status);
      response.responseData = dataAxios.data;

      if (showPositiveMessage && response.responseResult) {
        if (response.responseData.responseMessage.code >= 0) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.success,
          );
        }
      }

      return response;
    } catch (error: any) {
      TPLog.Log("TPHTTPService.ts deleteData ex", TPLogType.ERROR, error);
      if (!localStorage.getItem("avoidforbidden")) {
        if (
          error.response.status.toString() === "401" ||
          error.response.status.toString() === "403"
        ) {
          showToast(TPGlobal.TPGeneralAccessDenied, TPToastTypes.error);
          setTimeout(() => {
            window.location.href = TPGlobal.tenantSelectorURL;
          }, 1000);
          return response;
        }
      }

      response.responseResult = expectedCodes.includes(error.response.status);
      response.responseData = error.response.data;

      if (showNegativeMessage && !response.responseResult) {
        if (
          response.responseData &&
          response.responseData.responseMessage.code < 0
        ) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.error,
          );
        } else {
          showToast(TPGlobal.TPGeneralHttpException, TPToastTypes.error);
        }
      }

      return response;
    }
  }

  public async postData(
    url: string,
    data: any,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    headers?: Array<TPKeyValue>,
  ): Promise<HTTPResponse> {
    let response: HTTPResponse = new HTTPResponse();
    let finalHeaders: any = {};
    let i: number;
    try {
      if (headers != null) {
        for (i = 0; i <= headers.length - 1; i++) {
          finalHeaders[headers[i].key] = headers[i].value;
        }
      }
      let dataAxios = await axios({
        method: "post",
        data: data,
        url: url,
        headers: finalHeaders,
      });

      response.responseResult = expectedCodes.includes(dataAxios.status);
      response.responseData = dataAxios.data;

      if (showPositiveMessage && response.responseResult) {
        if (response.responseData.responseMessage.code >= 0) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.success,
          );
        }
      }

      return response;
    } catch (error: any) {
      TPLog.Log("TPHTTPService.ts postData ex", TPLogType.ERROR, error);
      if (!localStorage.getItem("avoidforbidden")) {
        if (
          error.response.status.toString() === "401" ||
          error.response.status.toString() === "403"
        ) {
          showToast(TPGlobal.TPGeneralAccessDenied, TPToastTypes.error);
          setTimeout(() => {
            window.location.href = TPGlobal.tenantSelectorURL;
          }, 1000);
          return response;
        }
      }

      response.responseResult = expectedCodes.includes(error.response.status);
      response.responseData = error.response.data;

      if (showNegativeMessage && !response.responseResult) {
        if (
          response.responseData &&
          response.responseData.responseMessage.code < 0
        ) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.error,
          );
        } else {
          showToast(TPGlobal.TPGeneralHttpException, TPToastTypes.error);
        }
      }

      return response;
    }
  }

  public async putData(
    url: string,
    data: any,
    expectedCodes: Array<number>,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    headers?: Array<TPKeyValue>,
  ): Promise<HTTPResponse> {
    let response: HTTPResponse = new HTTPResponse();
    let finalHeaders: any = {};
    let i: number;
    try {
      if (headers != null) {
        for (i = 0; i <= headers.length - 1; i++) {
          finalHeaders[headers[i].key] = headers[i].value;
        }
      }
      let dataAxios = await axios({
        method: "put",
        data: data,
        url: url,
        headers: finalHeaders,
      });

      response.responseResult = expectedCodes.includes(dataAxios.status);
      response.responseData = dataAxios.data;

      if (showPositiveMessage && response.responseResult) {
        if (response.responseData.responseMessage.code >= 0) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.success,
          );
        }
      }

      return response;
    } catch (error: any) {
      TPLog.Log("TPHTTPService.ts putData ex", TPLogType.ERROR, error);
      if (!localStorage.getItem("avoidforbidden")) {
        if (
          error.response.status.toString() === "401" ||
          error.response.status.toString() === "403"
        ) {
          showToast(TPGlobal.TPGeneralAccessDenied, TPToastTypes.error);
          setTimeout(() => {
            window.location.href = TPGlobal.tenantSelectorURL;
          }, 1000);
          return response;
        }
      }

      response.responseResult = expectedCodes.includes(error.response.status);
      response.responseData = error.response.data;

      if (showNegativeMessage && !response.responseResult) {
        if (
          response.responseData &&
          response.responseData.responseMessage.code < 0
        ) {
          showToast(
            response.responseData.responseMessage.message,
            TPToastTypes.error,
          );
        } else {
          showToast(TPGlobal.TPGeneralHttpException, TPToastTypes.error);
        }
      }

      return response;
    }
  }
}
