import React, { CSSProperties, useState } from "react";
import { useSelector } from "react-redux";
import { StoreModel } from "@/redux/store";
import {
  FormDesignerExportImportFormSlice,
  FormDesignerExportImportFormStateModel,
} from "./_redux/FormDesignerExportImportFormSlice";
import e from "./models/FormDesignerExportImportFormEnum";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";

export default function FormDesignerExportImportFormInfo() {
  const { message: m } = useSelector(
    (s: StoreModel) => s[FormDesignerExportImportFormSlice.name],
  ) as FormDesignerExportImportFormStateModel;

  const [open, setOpen] = useState(true);

  return (
    <div className="accordion" style={styles.container}>
      <div className="accordion-item">
        <p className="accordion-header" style={styles.titleContainer}>
          <div style={styles.title}>
            <TPIcon iconType={TPIconTypes.circleInfo} style={styles.icon} />

            <span>{m?.[e.WarningTitleLabel]}</span>
          </div>

          <button
            className={`accordion-button${open ? " collapsed" : ""}`}
            style={styles.button}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse1"
            onClick={() => setOpen(!open)}
          />
        </p>
        <div id="collapse1" className={`accordion-collapse collapse`}>
          <div
            className={`accordion-body${open ? " show" : ""}`}
            style={styles.description}
          >
            {m?.[e.WarningDescriptionLabel]}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    marginBottom: "20px",
    border: "2px solid #c7c8c9",
    borderRadius: "5px",
    padding: "5px",
  } as CSSProperties,
  titleContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    placeContent: "center space-around",
    alignItems: "center",
    padding: "0 5px",
  } as CSSProperties,
  title: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    flex: "1 1 0%",
    gap: "10px",
  } as CSSProperties,
  button: {
    width: "30px",
    height: "30px",
    flex: "1 1 30px",
    boxSizing: "border-box",
    maxWidth: "30px",
    borderBottom: "0",
  } as CSSProperties,
  icon: {
    boxSizing: "border-box",
    display: "flex",
    placeContent: "center",
    alignItems: "center",
    color: "blue",
  } as CSSProperties,
  description: {
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  } as CSSProperties,
};
