import styled from "styled-components";

export const ImagesAdminFolderDivStyle = styled.div`
  display: flex;
  justify-content: start;
  background-color: transparent;
  padding: 2px;
  min-width: 80px;
  gap: 5px;
  cursor: pointer;
  position: relative;
`;

export const ImagesAdminContainerFolderDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: start;
  -webkit-box-align: start;
  align-items: start;
  background-color: rgb(245, 245, 245);
  height: 100vh;
  width: 200px;
  overflow: auto;
  box-shadow: black 0px 0px 2px -1px;
  border-radius: 2px 0px 5px;
  top: 0px;
  border-right: 1px solid rgb(128, 128, 128);
  border-bottom: 1px solid rgb(128, 128, 128);
  gap: 5px;
  padding-top: 5px;
  padding-right: 20px;
`;

export const IcoFolderIStyle = styled.i`
  text-shadow: 0px -1px 1px black;
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: ${({ color }) => color || "black"};

  &:hover {
    background-color: #c0c0c0;
    filter: drop-shadow(black 0px -1px 1px);
  }
`;

export const IcoIStyle = styled.i<MenuItemDivStyleProps>`
  text-shadow: black 0px -1px 1px;
  width: 20px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  background-color: ${({ background_color }) => background_color};
  font-size: 25px;
  color: ${({ color }) => color};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  cursor: pointer;
`;

export const IcoMenuIStyle = styled.i`
  text-shadow: black 0px -1px 1px;
  cursor: pointer;
  min-width: 30px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  padding: 3px;
  &:hover {
    background-color: #c0c0c0;
    filter: drop-shadow(black 0px -1px 1px);
  }
`;

export const NameFolderPStyle = styled.p`
  font-size: 12px;
  display: flex;
  -webkit-box-align: start;
  align-items: start;
  -webkit-box-pack: start;
  justify-content: start;
  padding: 0px;
  margin: 0px;
  width: auto;
  cursor: pointer;
  //text-align: end;
  overflow: hidden;
  max-width: 88px;
  height: 20px;
`;

export const NameFolderInputStyle = styled.input`
  font-size: 12px;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 5px;
  width: 150px;
  display: flex;
  margin-top: 9px;
  width: ${({ width }) => width};
`;

export const NivelDivStyle = styled.div`
  margin-left: 4px;
`;

interface MenuItemDivStyleProps {
  right?: string;
  top?: string;
  bottom?: string;
  background_color?: string;
}

export const MenuItemDivStyle = styled.div<MenuItemDivStyleProps>`
  display: flex;
  -webkit-box-align: center;
  align-items: start;
  column-gap: 10px;
  width: auto;
  background-color: #d9d9d9;
  -webkit-box-pack: start;
  justify-content: flex-end;
  padding-right: 10px;
  box-shadow: black 0px 0px 3px;
  border: 1px solid #f9f9f9;
  position: absolute;
  top: 26px;
  right: "10%";
  right: ${({ right }) => right || "10%"};
  top: ${({ top }) => top || "26px"};
  z-index: 10000;
  gap: 10px;
  height: auto;
  flex-direction: column;
  padding: 1px;
  border-radius: 0px 5px 5px 5px;
`;

export const ContainerImageAdminDivStyle = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ff000000;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  margin-top: -10px;
  padding-left: 2px;
  padding-top: 1px;
  padding-right: 2px;
`;

export const ContainerVerticalDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  //background-color:#f3f8fb;
  width: 100%;
  height: 100%;
  padding-right: 1px;
  border-radius: 2px;
`;

export const ContainerHorizontalFlexRowStartDivStyle = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: start;
  -webkit-box-align: stretch;
  align-items: center;
  min-width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  gap: 5px;
`;

export const ContainerFilterDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 100px;
  box-shadow: 0px 0px 5px gray;
`;

export const ContainerItemFilterDivStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 190px;
  height: 37px;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  border-radius: 5px;
  border: var(--bs-border-width) solid #dee2e6;
  overflow: hidden;
  margin-top: 4px;
`;

export const FilterInputStyle = styled.input`
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

export const FilterButtomStyle = styled.div``;

export const IcoBtnIStyle = styled.i`
  text-shadow: black 0px -1px 1px;
  cursor: pointer;
  min-width: 40px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 5px;
  padding: 3px;
  height: 37px;
  margin-top: 4px;
  &:hover {
    box-shadow: 0px 0px 5px gray inset;
  }
`;

export const IcoBtnSearchIStyle = styled.i`
  text-shadow: black 0px -1px 1px;
  cursor: pointer;
  min-width: 40px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 5px;
  padding: 3px;
  height: 37px;
`;

export const VerticalCenterDivStyle = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerFilesGridDivStyle = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100px;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  background-color: transparent;
  gap: 10px;
  padding: 5px;
  justify-content: start;
  align-items: start;
`;

export const ContainerFilesListDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  height: 100%;
  width: 100%;
  //background-color: #fbff00;
  gap: 0px;
`;

interface FileDivStyleProps {
  grayscale: number;
}
export const FileDivStyle = styled.div<FileDivStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ListFileDivStyle = styled.div`
  display: flex;
`;

export const ContainerImageStyle = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
`;

export const ImageStyle = styled.img`
  height: 100px;
  width: 100px;
  background-color: gray;
  border-radius: 5px;
`;

export const ImageIcoStyle = styled.img`
  height: 40px;
  width: 40px;
  background-color: gray;
  border-radius: 5px;
`;

export const ContainerViewDetail = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  justify-content: center;
  border-radius: 0px;
  position: relative;
  align-items: start;
  border-bottom: 1px solid #cfc8c8;
  border-top: 1px solid #cfc8c8;
  padding: 5px;
  position: relative;
`;

export const PopupDetailDivStyle = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  padding: 10px;
  z-index: 1000;
  min-width: 250px;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    padding: 1px;
    width: 15px;
    border-color: transparent transparent #ccc transparent;
    z-index: -1;
  }

  ${FileDivStyle}:hover & {
    display: flex;
    bottom: -130%;
  }

  ${ContainerViewDetail}:hover & {
    display: flex;
    bottom: -250%;
    right: 40px;
  }
`;

export const TextPStyle = styled.p`
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-items: start;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ContainerSliderStyle = styled.div`
  position: absolute;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  -webkit-box-pack: center;
  justify-content: space-around;
  -webkit-box-align: center;
  align-items: start;
  background-color: rgba(37, 35, 35, 0.47);
  z-index: 110;
  padding-top: 100px;
`;

export const ItemSliderStyle = styled.div`
  height: auto;
  width: auto;
  max-width: 80%;
  background-color: rgb(123, 208, 198);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding-top: 0px;
  position: relative;
`;

export const TitleImageSlider = styled.p`
  font-size: 11pxs;
  color: #ffffffa8;
  top: 5px;
  left: 5px;
  position: absolute;
`;

export const ImageSliderStyle = styled.img`
  height: 280px;
  width: auto;
  background-color: gray;
  border-radius: 5px;
  object-fit: cover;
`;

export const ButtonStyle = styled.button`
  background-color: #7bd0c6;
  color: white;
  border: none;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -1px #242525;
  margin-bottom: 3px;
  cursor: pointer;
  &:hover {
    background-color: #5a817d;
  }
`;

export const ButomUploadImageStyle = styled.button`
  height: 50px;
  width: 50px;
  font-size: 30px;
  text-shadow: black 0px -1px 1px;
  background-color: rgb(15, 175, 156);
  color: white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 100%;
  box-shadow: black 0px 0px 5px;
  border: none;
  z-index: 2;
  &:hover {
    background-color: #68a59e;
  }
`;

export const UploadDivStyle = styled.div`
  width: 500px;
  height: 200px;
  box-shadow: gray 0px 0px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: fixed;
  bottom: 50px;
  left: 35%;
  &:hover {
    box-shadow: 0px 0px 5px 2px #68a59e;
  }
`;

export const UploadLabelStyle = styled.label`
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0px;
  left: 0px;
`;
