import { Validator } from "fluentvalidation-ts";
import TPGlobal from "@/helpers/TPGlobal";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";
import { TaskTypeComponentEnum } from "../Global/TPGlobalEnums";

export type TaskTypeInputDTO = {
  id: string;
  description: string;
  componentId: string;
  isDecision: boolean;
  escalates: boolean;
  addable: boolean;
  isCommentRequired: boolean;
  isPrivate: boolean;
  isVisibleTaskReport: boolean;
  jSONParameters: string;
  helpNotes: string;
  externalTaskRSAPublicKey: string;
  isActive: boolean;
  nameLocalizedValues: Array<RecordLocalizedModel>;
  helpNotesLocalizedValues: Array<RecordLocalizedModel>;
  OutboundAccount: string;
  EmailTemplate: string;
};

export type SendEmailExtendedTaskModel = {
  outboundMailAccountId: string;
  emailTemplateId: string;
};

export type ExportCaseExtendedTaskModel = {
  exportDestinationId: string;
  exportComments: string;
};

export type ExternalExtendedTaskModel = {
  externalTaskURL: string;
};

export class TaskTypeInputDTOValidator extends Validator<TaskTypeInputDTO> {
  constructor() {
    super();

    const resourceSet = "TaskTypeInsertUpdateComponent";

    try {
      this.ruleFor("id")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((id) => {
          return TPGlobal.TPSanitize(id) == id;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidIdLength");

      this.ruleFor("description")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTONameEmpty")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidName")
        .maxLength(500)
        .withMessage(resourceSet + "|InputDTOInvalidNameLength");

      this.ruleFor("componentId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOComponentIdEmpty")
        .must((id) => {
          let check = TPGlobal.checkSpecialCharacters(id);
          return check.result;
        })
        .withMessage(TPGlobal.globalResourceSet + "|InputDTOInvalidIdChar")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidComponentId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidComponentIdLength");

      this.ruleFor("jSONParameters")
        .notNull()
        .withMessage(resourceSet + "|InputDTOJSONParametersEmpty")
        .when(
          (formModel) =>
            (formModel.componentId != "" &&
              formModel.componentId.toLowerCase() ===
                TaskTypeComponentEnum.caseResponseEmailTaskComponent.toLowerCase()) ||
            formModel.componentId.toLowerCase() ===
              TaskTypeComponentEnum.caseResponseEmailAutoTaskComponent.toLowerCase() ||
            formModel.componentId.toLowerCase() ===
              TaskTypeComponentEnum.caseResponseExchangeTaskComponent.toLowerCase() ||
            formModel.componentId.toLowerCase() ===
              TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent.toLowerCase() ||
            formModel.componentId.toLowerCase() ===
              TaskTypeComponentEnum.externalTaskComponent.toLowerCase(),
        );

      this.ruleFor("jSONParameters")
        .maxLength(4000)
        .withMessage(resourceSet + "|InputDTOInvalidJSONParametersLength")
        .must((jSONParameters) => {
          return TPGlobal.TPSanitize(jSONParameters) == jSONParameters;
        })
        .withMessage(resourceSet + "|InputDTOInvalidJSONParameters");

      // this.ruleFor("externalTaskRSAPublicKey")
      //   .must((name) => {
      //     return TPGlobal.TPSanitize(name) == name;
      //   })
      //   .withMessage(resourceSet + "|InputDTOInvalidExternalTaskRSAPublicKey")
      //   .maxLength(1000)
      //   .withMessage(
      //     resourceSet + "|InputDTOInvalidExternalTaskRSAPublicKeyLength"
      //   );
      this.ruleForEach("nameLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidName")
        .must((x) => {
          if (x.localizedValue.trim() == "" || x.order == 1) {
            return true;
          }
          return x.localizedValue.length <= 500;
        })
        .withMessage(resourceSet + "|InputDTOInvalidNameLength");

      this.ruleFor("helpNotes")
        .must((name) => {
          return TPGlobal.TPSanitize(name) == name;
        })
        .withMessage(resourceSet + "|InputDTOInvalidHelpNotes")
        .maxLength(500)
        .withMessage(resourceSet + "|InputDTOInvalidHelpNotesLength");

      this.ruleFor("helpNotesLocalizedValues")
        .must((x) => {
          let result: boolean = true;
          let i: number;
          for (i = 0; i <= x.length - 1; i++) {
            if (i == 0) {
              if (x[i].localizedValue.trim() != "") {
                result = true;
                break;
              }
            } else {
              if (
                x[0].localizedValue.trim() == "" &&
                x[i].localizedValue.trim() != ""
              ) {
                result = false;
                break;
              }
            }
          }
          return result;
        })
        .withMessage(resourceSet + "|InputDTOInvalidHelpNotes");

      this.ruleForEach("helpNotesLocalizedValues")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return TPGlobal.TPSanitize(x.localizedValue) == x.localizedValue;
        })
        .withMessage(resourceSet + "|InputDTOInvalidLocalizedHelpValue")
        .must((x) => {
          if (x.localizedValue.trim() == "") {
            return true;
          }
          return x.localizedValue.length <= 5000;
        })
        .withMessage(resourceSet + "|InputDTOInvalidLocalizedHelpValueLength");
    } catch (error) {}
  }
}

export class SendEmailExtendedTaskValidator extends Validator<SendEmailExtendedTaskModel> {
  constructor(componentId: string) {
    super();
    const resourceSet = "TaskTypeInsertUpdateComponent";

    try {
      if (
        componentId.toUpperCase() ===
        TaskTypeComponentEnum.caseResponseEmailTaskComponent
      ) {
        this.ruleFor("outboundMailAccountId")
          .must((intent) => {
            return TPGlobal.TPSanitize(intent) == intent;
          })
          .withMessage(resourceSet + "|InputDTOInvalidOutboundMailAccountId")
          .maxLength(20)
          .withMessage(
            resourceSet + "|InputDTOInvalidOutboundMailAccountIdLength",
          );

        this.ruleFor("emailTemplateId")
          .must((intent) => {
            return TPGlobal.TPSanitize(intent) == intent;
          })
          .withMessage(resourceSet + "|InputDTOInvalidEmailTemplateId")
          .maxLength(20)
          .withMessage(resourceSet + "|InputDTOInvalidEmailTemplateIdLength");
      } else {
        this.ruleFor("outboundMailAccountId")
          .must((intent) => {
            return TPGlobal.TPSanitize(intent) == intent;
          })
          .withMessage(resourceSet + "|InputDTOInvalidOutboundMailAccountId")
          .maxLength(20)
          .withMessage(
            resourceSet + "|InputDTOInvalidOutboundMailAccountIdLength",
          );

        this.ruleFor("emailTemplateId")
          .notEmpty()
          .withMessage(resourceSet + "|InputDTOInvalidEmailTemplateIdEmpty")
          .must((intent) => {
            return TPGlobal.TPSanitize(intent) == intent;
          })
          .withMessage(resourceSet + "|InputDTOInvalidEmailTemplateId")
          .maxLength(20)
          .withMessage(resourceSet + "|InputDTOInvalidEmailTemplateIdLength");
      }
    } catch {}
  }
}

export class ExportCaseExtendedTaskValidator extends Validator<ExportCaseExtendedTaskModel> {
  constructor(componentId: string) {
    super();
    const resourceSet = "TaskTypeInsertUpdateComponent";
    if (
      componentId.toLowerCase() ===
      TaskTypeComponentEnum.caseResponseExchangeTaskComponent.toLowerCase()
    ) {
      this.ruleFor("exportDestinationId")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidExportDestinationIdLength")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(resourceSet + "|InputDTOInvalidExportDestinationId");
    } else {
      this.ruleFor("exportDestinationId")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOInvalidExportDestinationIdEmpty")
        .maxLength(20)
        .withMessage(resourceSet + "|InputDTOInvalidExportDestinationIdLength")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(resourceSet + "|InputDTOInvalidExportDestinationId");

      this.ruleFor("exportComments")
        .maxLength(1000)
        .withMessage(resourceSet + "|InputDTOInvalidExportCommentsLength")
        .notEmpty()
        .withMessage(resourceSet + "|InputDTOInvalidExportCommentsEmpty")
        .must((intent) => {
          return TPGlobal.TPSanitize(intent) == intent;
        })
        .withMessage(resourceSet + "|InputDTOInvalidExportComments");
    }
  }
}

export class ExternalExtendedTaskValidator extends Validator<ExternalExtendedTaskModel> {
  constructor() {
    super();
    const resourceSet = "TaskTypeInsertUpdateComponent";
    this.ruleFor("externalTaskURL")
      .notEmpty()
      .withMessage(resourceSet + "|InputDTOInvalidExternalTaskURLEmpty")
      .maxLength(1000)
      .withMessage(resourceSet + "|InputDTOInvalidExternalTaskURLLength")
      .must((intent) => {
        return TPGlobal.TPSanitize(intent) == intent;
      })
      .withMessage(resourceSet + "|InputDTOInvalidExternalTaskURL");
  }
}
