import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageSection,
  TPPageSectionTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import {
  ClientNewCustomerInputDTO,
  ClientNewCustomerInputDTOValidator,
} from "@/models/Client/ClientNewCustomerInputDTO";
import { CustomerTypeViewModel } from "@/models/CustomerType/CustomerTypeModels";
import {
  ConfigParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { ClientService } from "@/services/ClientService";
import { CustomerTypeService } from "@/services/CustomerTypeService";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type TPModalNewClientProps = {
  mode: string;
  acceptLabel: string;
  cancelLabel: string;
  isShown: boolean;
  callBackAnswer: Function;
  subsidiaryOrgId: number;
};

interface screenStateProps {
  customerType: string;
  documentType: string;
  document: string;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  internalCode: string;

  //validaciones
  customerTypeErrorMessage: string;
  documentTypeErrorMessage: string;
  documentErrorMessage: string;
  nameErrorMessage: string;
  lastNameErrorMessage: string;
  emailErrorMessage: string;
  phoneErrorMessage: string;
  internalCodeErrorMessage: string;
  [key: string]: any;
}

const TPModalNewClient: FC<TPModalNewClientProps> = ({
  mode,
  acceptLabel,
  cancelLabel,
  isShown,
  callBackAnswer,
  subsidiaryOrgId,
}) => {
  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);

  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    styleModal["zIndex"] = "1057";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  const componentFileName: string = "TPModalNewClient.tsx";
  const resourceSet: string = "TPModalNewClient";
  const screenStateInitialState: screenStateProps = {
    customerType: "",
    documentType: "",
    document: "",
    name: "",
    lastName: "",
    email: "",
    phone: "",
    internalCode: "",
    customerTypeErrorMessage: "",
    documentTypeErrorMessage: "",
    documentErrorMessage: "",
    nameErrorMessage: "",
    lastNameErrorMessage: "",
    emailErrorMessage: "",
    phoneErrorMessage: "",
    internalCodeErrorMessage: "",
  };
  const [screenState, setScreenState] = useState<screenStateProps>(
    screenStateInitialState
  );

  const [customerTypeList, setCustomerTypeList] = useState<Array<TPKeyValue>>(
    []
  );
  const [documentsTypeList, setDocumentsTypeList] = useState<Array<TPKeyValue>>(
    []
  );

  //screen reosurces
  const [titleModal, setTitleModal] = useState("");
  const [customerInfoSectionLabel, setCustomerInfoSectionLabel] = useState("");
  const [otherInfoSectionLabel, setOtherInfoSectionLabel] = useState("");
  const [noteInsertMode, setNoteInsertMode] = useState("");

  const [customerTypeLabel, setCustomerTypeLabel] = useState("");
  const [documentTypeLabel, setDocumentTypeLabel] = useState("");
  const [documentLabel, setDocumentLabel] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [lastNameLabel, setLastNameLabel] = useState("");
  const [emailLabel, setEmailLabel] = useState("");
  const [phoneLabel, setPhoneLabel] = useState("");
  const [
    phoneNumberShouldContainOnlyNumbersLabel,
    setPhoneNumberShouldContainOnlyNumbersLabel,
  ] = useState("");
  const [internalCodeLabel, setInternalCodeLabel] = useState("");
  const [isUNKCustomer, setIsUNKCustomer] = useState(false);

  const documentTypeUNK: string = "UNK";

  const loadResources = async () => {
    setCustomerTypeLabel(
      await TPI18N.GetText(resourceSet, "CustomerTypeLabel")
    );
    setDocumentTypeLabel(
      await TPI18N.GetText(resourceSet, "DocumentTypeLabel")
    );
    setDocumentLabel(await TPI18N.GetText(resourceSet, "DocumentLabel"));
    setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
    setLastNameLabel(await TPI18N.GetText(resourceSet, "LastNameLabel"));
    setEmailLabel(await TPI18N.GetText(resourceSet, "EmailLabel"));
    setPhoneLabel(await TPI18N.GetText(resourceSet, "PhoneLabel"));
    setPhoneNumberShouldContainOnlyNumbersLabel(
      await TPI18N.GetText(
        resourceSet,
        "PhoneNumberShouldContainOnlyNumbersLabel"
      )
    );
    setInternalCodeLabel(
      await TPI18N.GetText(resourceSet, "InternalCodeLabel")
    );
    setTitleModal(await TPI18N.GetText(resourceSet, `Title${mode}Modal`));
    setCustomerInfoSectionLabel(
      await TPI18N.GetText(resourceSet, "CustomerInfoSectionLabel")
    );
    setOtherInfoSectionLabel(
      await TPI18N.GetText(resourceSet, "OtherInfoSectionLabel")
    );
    setNoteInsertMode(await TPI18N.GetText(resourceSet, "NoteInsertMode"));

    //Load Customer Type
    let customerTypeService = new CustomerTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await customerTypeService.getCustomerTypeByFilter(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes
      );

      let newFilteredCustomerTypeList: Array<CustomerTypeViewModel> = [];

      if (mode === "Insert") {
        newFilteredCustomerTypeList = responseRequest.filter(
          (x: CustomerTypeViewModel) => x.canCreate
        );
      } else {
        newFilteredCustomerTypeList = responseRequest.filter(
          (x: CustomerTypeViewModel) => x.canUpdate
        );
      }

      let newCustomerTypeList: Array<TPKeyValue> =
        newFilteredCustomerTypeList.map(function (item) {
          return { key: item.id, value: item.localizedDescription };
        });
      newCustomerTypeList.unshift({ key: "", value: "--" });
      setCustomerTypeList(newCustomerTypeList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResources getCustomerTypeByFilter ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} loadResources getCustomerTypeByFilter ex`
      );
    }

    //Load document Type
    let parameterService = new ParametersService();
    expectedCodes = [200];
    try {
      let responseRequest =
        await parameterService.getByParentIdAndFilterIsActive(
          ConfigParametersEnum.Document_Type,
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes
        );

      let newDocumentsTypeList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        }
      );
      newDocumentsTypeList = newDocumentsTypeList.filter((x) => x.key !== "AN");
      newDocumentsTypeList.unshift({ key: "", value: "--" });
      setDocumentsTypeList(newDocumentsTypeList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`
      );
    }

    if (mode === "Update") {
      await getCustomerBySubsidiaryOrganizationId(subsidiaryOrgId);
    }
  };

  const getCustomerBySubsidiaryOrganizationId = async (
    subsidiaryOrgId: number
  ) => {
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest =
        await serviceClient.getClientBySubsidiaryOrganizationId(
          subsidiaryOrgId,
          false,
          true,
          expectedCodes
        );

      let recordInfo: Array<ClientAdminViewModel>;
      recordInfo = [...responseRequest];

      let newScreenStateState: screenStateProps = { ...screenState };
      newScreenStateState.customerType = recordInfo[0].customerTypeId;
      newScreenStateState.documentType = recordInfo[0].documentType;
      newScreenStateState.document = recordInfo[0].document;
      newScreenStateState.email = recordInfo[0].preferredEmail;
      newScreenStateState.internalCode = recordInfo[0].internalCode;
      newScreenStateState.lastName = recordInfo[0].lastName;
      newScreenStateState.name = recordInfo[0].name;
      newScreenStateState.phone = recordInfo[0].preferredPhone;

      setScreenState(newScreenStateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`
      );
      setIsLoadingScreen(false);
    }
  };

  const handlerOnOKClick = async () => {
    let recordInputDTO: ClientNewCustomerInputDTO = {
      documentTypeId: screenState.documentType,
      document: screenState.document,
      name: screenState.name,
      lastName: screenState.lastName,
      preferredEmail: screenState.email,
      preferredPhone: screenState.phone,
      internalCode: screenState.internalCode,
      subsidiaryOrganizationId: subsidiaryOrgId,
      additionalInfo: null,
      isActive: true,
      typistIdGuid: TPGlobal.currentUserGuid,
      subsidiaryName: null,
      preferredAddress: null,
      preferredAddressInfo: null,
      geography: null,
      organizationId: null,
      relationId: null,
      regionId: null,
      channelId: null,
      customerTypeId: screenState.customerType,
    };

    if (recordInputDTO.documentTypeId === documentTypeUNK) {
      recordInputDTO.document = uuidv4().replaceAll("-", "");
    }

    let inputDTOValidator = new ClientNewCustomerInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newScreenState = { ...screenState };
      if (resultValidator.customerTypeId) {
        newScreenState.customerTypeErrorMessage = await TPI18N.GetResource(
          resultValidator.customerTypeId
        );
      } else {
        newScreenState.customerTypeErrorMessage = "";
      }

      if (resultValidator.documentTypeId) {
        newScreenState.documentTypeErrorMessage = await TPI18N.GetResource(
          resultValidator.documentTypeId
        );
      } else {
        newScreenState.documentTypeErrorMessage = "";
      }

      if (resultValidator.document) {
        newScreenState.documentErrorMessage = await TPI18N.GetResource(
          resultValidator.document
        );
      } else {
        newScreenState.documentErrorMessage = "";
      }

      if (resultValidator.name) {
        newScreenState.nameErrorMessage = await TPI18N.GetResource(
          resultValidator.name
        );
      } else {
        newScreenState.nameErrorMessage = "";
      }

      if (resultValidator.preferredEmail) {
        newScreenState.emailErrorMessage = await TPI18N.GetResource(
          resultValidator.preferredEmail
        );
      } else {
        newScreenState.emailErrorMessage = "";
      }

      if (resultValidator.preferredPhone) {
        newScreenState.phoneErrorMessage = await TPI18N.GetResource(
          resultValidator.preferredPhone
        );
      } else {
        newScreenState.phoneErrorMessage = "";
      }

      if (resultValidator.internalCode) {
        newScreenState.internalCodeErrorMessage = await TPI18N.GetResource(
          resultValidator.internalCode
        );
      } else {
        newScreenState.internalCodeErrorMessage = "";
      }
      setScreenState(newScreenState);
      return;
    }

    if (mode === "Insert") {
      await insertNewClient(recordInputDTO);
    } else {
      await updateClient(recordInputDTO);
    }

    setScreenState(screenStateInitialState);
  };

  const insertNewClient = async (inputDTO: ClientNewCustomerInputDTO) => {
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200];
    let Id_SUOR: string;
    let Id_SUBS: string;
    let Id_CLIE: string;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertNewClient(
        inputDTO,
        true,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);

      if (responseRequest.responseResult) {
        //get id_suor
        Id_SUOR = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "id_SUOR"
        )[0]["value"];
        Id_SUBS = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "id_SUBS"
        )[0]["value"];
        Id_CLIE = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "id_CLIE"
        )[0]["value"];
        callBackAnswer(true, Id_SUOR, Id_SUBS, Id_CLIE);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertNewClient ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} insertNewClient ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateClient = async (inputDTO: ClientNewCustomerInputDTO) => {
    let serviceClient = new ClientService();
    let expectedCodes: Array<number> = [200];
    let Id_SUOR: string;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.updateClient(
        inputDTO,
        true,
        true,
        expectedCodes
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        //get id_suor
        Id_SUOR = responseRequest.responseData.keyList.filter(
          (x: TPKeyValue) => x.key === "Id_SUOR"
        )[0]["value"];
        //todo
        callBackAnswer(true, Id_SUOR, "", "");
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateClient ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updateClient ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleOnCancelOrCloseClick = () => {
    setScreenState(screenStateInitialState);
    callBackAnswer(false, "", "", "");
  };

  // const handleInputTextChange = (propertyName: string, newValue: any) => {
  //   let newScreenState = { ...screenState };
  //   newScreenState[propertyName] = newValue;
  //   newScreenState[propertyName + "ErrorMessage"] = "";
  //   setScreenState(newScreenState);
  // };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    // Define validation rules based on propertyName
    switch (propertyName) {
      case "phone":
        if (/^[0-9]*$/.test(newValue)) {
          setScreenState({
            ...screenState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]: "",
          });
        } else {
          setScreenState({
            ...screenState,
            [propertyName]: newValue,
            [propertyName + "ErrorMessage"]:
              phoneNumberShouldContainOnlyNumbersLabel,
          });
        }
        break;
      // Add cases for other specific fields as needed
      default:
        let newScreenState = { ...screenState };
        newScreenState[propertyName] = newValue;
        newScreenState[propertyName + "ErrorMessage"] = "";
        setScreenState(newScreenState);
        break;
    }
  };

  const handleDocumentTypeInputTextChange = (
    propertyName: string,
    newValue: any
  ) => {
    let newScreenState = { ...screenState };
    newScreenState[propertyName] = newValue;
    newScreenState[propertyName + "ErrorMessage"] = "";
    if (newValue === documentTypeUNK) {
      newScreenState["document"] = "";
      newScreenState[document + "ErrorMessage"] = "";
      setIsUNKCustomer(true);
    } else {
      setIsUNKCustomer(false);
    }
    setScreenState(newScreenState);
  };

  useEffect(() => {
    loadResources();
  }, [mode]);

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <TPPageTitle className={"modal-title"} style={{ margin: "0" }}>
                {titleModal}
              </TPPageTitle>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  handleOnCancelOrCloseClick();
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "auto" }}>
              <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
                <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {customerInfoSectionLabel}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <TPPageSection>
                  {mode === "Insert" && (
                    <div className="row">
                      <div className="col">
                        <div className="d-flex mt-2 ">
                          <span className="alert alert-info tpw100per">
                            {noteInsertMode}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPSelect
                          id="IdSelect"
                          isMandatory={true}
                          labelText={customerTypeLabel}
                          onChange={(e: any) =>
                            handleInputTextChange(
                              "customerType",
                              e.target.value
                            )
                          }
                          dataSource={customerTypeList}
                          value={screenState.customerType}
                          disabled={mode === "Insert" ? false : true}
                          errorMessage={screenState.customerTypeErrorMessage}
                        ></TPSelect>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPSelect
                          id="IdSelect"
                          isMandatory={true}
                          labelText={documentTypeLabel}
                          onChange={(e: any) =>
                            handleDocumentTypeInputTextChange(
                              "documentType",
                              e.target.value
                            )
                          }
                          dataSource={documentsTypeList}
                          value={screenState.documentType}
                          disabled={mode === "Insert" ? false : true}
                          errorMessage={screenState.documentTypeErrorMessage}
                        ></TPSelect>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={true}
                          onChange={(e: any) =>
                            handleInputTextChange("document", e.target.value)
                          }
                          value={screenState.document}
                          labelText={documentLabel}
                          maxLength={200}
                          disabled={
                            mode === "Insert"
                              ? isUNKCustomer
                                ? true
                                : false
                              : true
                          }
                          errorMessage={screenState.documentErrorMessage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={true}
                          onChange={(e: any) =>
                            handleInputTextChange("name", e.target.value)
                          }
                          value={screenState.name}
                          labelText={nameLabel}
                          errorMessage={screenState.nameErrorMessage}
                          maxLength={100}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          onChange={(e: any) =>
                            handleInputTextChange("lastName", e.target.value)
                          }
                          value={screenState.lastName}
                          labelText={lastNameLabel}
                          errorMessage={screenState.lastNameErrorMessage}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={true}
                          onChange={(e: any) =>
                            handleInputTextChange("email", e.target.value)
                          }
                          value={screenState.email}
                          labelText={emailLabel}
                          errorMessage={screenState.emailErrorMessage}
                          maxLength={200}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={true}
                          onChange={(e: any) =>
                            handleInputTextChange("phone", e.target.value)
                          }
                          value={screenState.phone}
                          labelText={phoneLabel}
                          errorMessage={screenState.phoneErrorMessage}
                          maxLength={200}
                        />
                      </div>
                    </div>
                  </div>
                </TPPageSection>
                <div className="row">
                  <div className="col-6">
                    <TPPageSectionTitle>
                      {otherInfoSectionLabel}
                    </TPPageSectionTitle>
                  </div>
                </div>
                <TPPageSection>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          onChange={(e: any) =>
                            handleInputTextChange(
                              "internalCode",
                              e.target.value
                            )
                          }
                          value={screenState.internalCode}
                          labelText={internalCodeLabel}
                          errorMessage={screenState.internalCodeErrorMessage}
                          maxLength={300}
                        />
                      </div>
                    </div>
                  </div>
                </TPPageSection>
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => {
                      handlerOnOKClick();
                    }}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => {
                      handleOnCancelOrCloseClick();
                    }}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ zIndex: 1056 }} className={backdropClass}></div>
    </>
  );
};

export default TPModalNewClient;
