import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  TPLanguageFilterEnum,
  RuleActiveType,
  RuleActionType,
  RuleBaseFieldType,
  RuleComparisonOperatorsType,
  RuleLogicalOperatorType,
  BaseFieldType,
  WebServiceClassifierType,
  SequeceGeneratorSequencesNameEnum,
} from "@/models/Global/TPGlobalEnums";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { TPI18N } from "@/services/I18nService";
import LanguageService from "@/services/LanguageService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import React, { FC, useEffect, useState } from "react";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import {
  InboundMailRuleDefinition2DTO,
  InboundMailRuleDefinitionJsonRuleViewModel,
} from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionViewModel";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import RuleApplied from "../InboundMailboxes/RuleApplied";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { InboundMailboxesRulesService } from "@/services/InboundMailboxesRulesService";
import {
  InboundMailRuleDefinitionDTO,
  InboundMailRuleDefinitionInputDTOValidator,
} from "@/models/InboundMailRuleDefinition/InboundMailRuleDefinitionDTO";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { SequenceService } from "@/services/SequenceService";

type InboundMailboxesRulesInsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
};
type InsertUpdateStateType = {
  id: string;
  description: string;
  recordLanguageList: TPKeyValue[];
  classifier: string;
  action: string;
  active: boolean;
  jsonRule: InboundMailRuleDefinitionJsonRuleViewModel[];

  idErrorMessage: string;
  descriptionErrorMessage: string;
  recordLanguageListErrorMessages: string[];
  classifierErrorMessage: string;
  actionErrorMessage: string;
  activeErrorMessage: string;
  jsonRuleErrorMessages: string[];

  [key: string]: any;
};

type PropertyName =
  // | "RuleNumber"
  | "ParenthesisOpen"
  | "BaseField"
  | "ComparisonOperator"
  | "ReferenceValue"
  | "ParenthesisClose"
  | "Connector";

const InboundMailboxesRulesInsertUpdate: FC<
  InboundMailboxesRulesInsertUpdateProps
> = ({ mode, recordId, callBackResult }) => {
  const componentFileName: string = "InboundMailboxesRulesInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //Screen resources
  const resourceSet: string = "InboundMailboxesRulesContainerInsertUpdate"; //InboundMailboxesRulesInsertUpdateComponent
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");

  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");

  const [idLabel, setIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [actionLabel, setActionLabel] = useState("");
  const [classifierLabel, setClassifierLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");

  //table add more translation
  const [conditionLabel, setConditionLabel] = useState("");
  const [baseFieldLabel, setBaseFieldLabel] = useState("");
  const [comparisonOperatorLabel, setComparisonOperatorLabel] = useState("");
  const [referenceValueLabel, setReferenceValueLabel] = useState("");
  const [addNewConditionLabel, setAddNewConditionLabel] = useState("");
  const [conditionsDefinitionLabel, setConditionsDefinitionLabel] =
    useState("");

  const [languageListLabel, setLanguageListLabel] = useState("");
  const [addConditionLabel, setAddConditionLabel] = useState("");
  const [deleteConditionLabel, setDeleteConditionLabel] = useState("");
  const [queryExpressionTitle, setQueryExpressionTitle] = useState("");

  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);

  //Multilanguage const
  const multilanguageTableName: String = "InboundMailRuleDefinition";
  const multilanguageFieldName: String = "Description_IMRD";

  const [mailActionToSelect, setMailActionToSelect] = useState<
    Array<TPKeyValue>
  >([]);
  const [mailBaseFieldsValuesToSelect, setMailBaseFieldsValuesToSelect] =
    useState<Array<TPKeyValue>>([]);
  const [mailComparisionValuesToSelect, setMailComparisionValuesToSelect] =
    useState<Array<TPKeyValue>>([]);

  const [languagesToSelect, setLanguagesToSelect] = useState<Array<TPKeyValue>>(
    [],
  );
  const [quickClassifiersToSelect, setQuickClassifiersToSelect] = useState<
    Array<TPKeyValue>
  >([]);

  const [logicalOperatorToSelect, setLogicalOperatorToSelect] = useState<
    Array<TPKeyValue>
  >([]);

  const [referenceId, setReferenceId] = useState("");

  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }

  const initInboundMailRuleDefinitionJson = [
    {
      RuleNumber: 1,
      ParenthesisOpen: "",
      BaseField: "",
      ComparisonOperator: "",
      ReferenceValue: "",
      ParenthesisClose: "",
      Connector: "",
    },
  ];

  let insertUpdateInitialState: InsertUpdateStateType = {
    id: "",
    description: "",
    recordLanguageList: [],
    classifier: "",
    action: "",
    active: true,
    jsonRule: [...initInboundMailRuleDefinitionJson],

    idErrorMessage: "",
    descriptionErrorMessage: "",
    recordLanguageListErrorMessages: [...initialErrorMessages],
    classifierErrorMessage: "",
    actionErrorMessage: "",
    activeErrorMessage: "",
    jsonRuleErrorMessages: [],
  };

  const [insertUpdateState, setInsertUpdateState] =
    useState<InsertUpdateStateType>(insertUpdateInitialState);

  const [ruleAppliedComponent, setRuleAppliedComponent] = useState<
    InboundMailRuleDefinitionJsonRuleViewModel[] | never[]
  >([]);

  const loadResourcesAndLoadInfo = async () => {
    //resources state
    setTitleLabel(
      await TPI18N.GetText(resourceSet, "Title" + realMode + "Label"),
    );
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));

    setActionLabel(await TPI18N.GetText(resourceSet, "ActionLabel"));
    setClassifierLabel(await TPI18N.GetText(resourceSet, "ClassifierLabel"));
    setConditionLabel(await TPI18N.GetText(resourceSet, "ConditionLabel"));
    setBaseFieldLabel(await TPI18N.GetText(resourceSet, "BaseFieldLabel"));
    setComparisonOperatorLabel(
      await TPI18N.GetText(resourceSet, "ComparisonOperatorLabel"),
    );
    setReferenceValueLabel(
      await TPI18N.GetText(resourceSet, "ReferenceValueLabel"),
    );
    setAddNewConditionLabel(
      await TPI18N.GetText(resourceSet, "AddNewConditionLabel"),
    );
    setConditionsDefinitionLabel(
      await TPI18N.GetText(resourceSet, "ConditionsDefinitionLabel"),
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "ActiveLabel"));
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );
    setAddConditionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "AddConditionLabel"),
    );
    setDeleteConditionLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteConditionLabel"),
    );
    setQueryExpressionTitle(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "QueryExpressionTitle"),
    );

    //screen state

    await getMailRuleAction();
    await getBaseFields();
    await getComparesonOperators();
    await getLanguages();
    await getQuickClassifiersByType();
    await getLogicalOperator();

    if (realMode === "Update") {
      await getInboundMailRuleDefinitionById(realRecordId);
    }

    if (realMode === "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList =
        TPGlobal.TPClientAvailableLanguages.map((item) => ({
          key: item.id,
          value: "",
        }));

      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }

    setIsLoadingScreen(false);
  };

  const getInboundMailRuleDefinitionById = async (pRecordId: string) => {
    let serviceClient = new InboundMailboxesRulesService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;

    try {
      setIsLoadingScreen(true);
      let responseRequest =
        await serviceClient.getInboundMailRuleDefinitionById(
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo = { ...responseRequest };
      setOriginalRecordDescription(`${recordInfo.localizedDescription}`);

      let newInsertUpdateState = { ...insertUpdateState };

      newInsertUpdateState.recordLanguageList =
        TPGlobal.TPClientAvailableLanguages.map((item) => ({
          key: item.id,
          value: "",
        }));

      newInsertUpdateState.id = recordInfo.id;
      newInsertUpdateState.description = recordInfo.description;
      newInsertUpdateState.classifier = recordInfo.webServiceCLassifierId;
      newInsertUpdateState.action = recordInfo.actionType;
      newInsertUpdateState.active = recordInfo.isActive;
      newInsertUpdateState.jsonRule = JSON.parse(recordInfo.jsonRule);

      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length === 0) {
        TPLog.Log(
          `Error ${componentFileName} getInboundMailRuleDefinitionById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getInboundMailRuleDefinitionById getRecordLanguageList is empty`,
        );
      } else {
        for (
          i = 0;
          i <= newInsertUpdateState.recordLanguageList.length - 1;
          i++
        ) {
          for (j = 0; j <= recordLanguagesList.length - 1; j++) {
            if (
              newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ===
              recordLanguagesList[j].languageId.toLowerCase()
            ) {
              newInsertUpdateState.recordLanguageList[i].value =
                recordLanguagesList[j].recordDescription;
              break;
            }
          }
        }
      }

      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getInboundMailRuleDefinitionById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getInboundMailRuleDefinitionById ex`,
      );
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );
      return responseRequest;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const getMailRuleAction = async () => {
    const mailboxRuleTypesEntries = Object.entries(RuleActionType);

    const mailRuleActionToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        mailboxRuleTypesEntries.map(async ([key, value]) => {
          return {
            key: value,
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              `ACTION${key}`,
            ),
          };
        }),
      );

    setMailActionToSelect([
      { key: "", value: "--" },
      ...mailRuleActionToSelectConverted,
    ]);
  };

  const getBaseFields = async () => {
    const mailBaseFieldsEntries = Object.entries(RuleBaseFieldType);

    const mailBaseFieldsValuesToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        mailBaseFieldsEntries.map(async ([key, value]) => {
          return {
            key: `${BaseFieldType.MAILELEMENT}.${value}`,
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              `MAILELEMENT${key}`,
            ),
          };
        }),
      );
    setMailBaseFieldsValuesToSelect([
      { key: "", value: "--" },
      ...mailBaseFieldsValuesToSelectConverted,
    ]);
  };

  const getComparesonOperators = async () => {
    const mailComparesonOperatorsToSelectConvertedTypesEntries = Object.entries(
      RuleComparisonOperatorsType,
    );

    const mailComparesonOperatorsToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        mailComparesonOperatorsToSelectConvertedTypesEntries.map(
          async ([key, value]) => {
            return {
              key: value,
              value: await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                `OPERATOR${key}`,
              ),
            };
          },
        ),
      );

    setMailComparisionValuesToSelect([
      { key: "", value: "--" },
      ...mailComparesonOperatorsToSelectConverted,
    ]);
  };

  const getLogicalOperator = async () => {
    const connectionProtocolsValues = Object.entries(RuleLogicalOperatorType);

    const connectionProtocolsToSelectConverted: Array<TPKeyValue> =
      await Promise.all(
        connectionProtocolsValues.map(async ([key, value]) => {
          return {
            key: value,
            value: await TPI18N.GetText(
              TPGlobal.globalResourceSet,
              `LogicalOperator${key}`,
            ),
          };
        }),
      );

    setLogicalOperatorToSelect(connectionProtocolsToSelectConverted);
  };

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false,
      true,
      expectedCodes,
    );

    const languages = response.responseData.data;

    const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
      (item: any) => {
        return {
          key: item.id,
          value: item.name,
        };
      },
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguagesToSelect(languagesToSelectConverted);
  };

  const getQuickClassifiersByType = async () => {
    const quickClassifierClient = new QuickClassifierService();
    const expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);

      const responseRequest =
        await quickClassifierClient.getQuickClassifiersByType(
          WebServiceClassifierType.MailReader,
          TPActiveOptions.ACTIVE,
          false,
          true,
          expectedCodes,
        );
      setIsLoadingScreen(false);
      const quickClassifierList = responseRequest.map((res) => ({
        key: res.id,
        value: res.description,
      }));
      setQuickClassifiersToSelect([
        { key: "", value: "--" },
        ...quickClassifierList,
      ]);
      return quickClassifierList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getQuickClassifiersByType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getQuickClassifiersByType ex`);
      setIsLoadingScreen(false);
      setQuickClassifiersToSelect([]);
      return [];
    }
  };

  //#endregion "Autoresponse Email Template Autocomplete Functions"

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.recordLanguageListErrorMessages[index] = "";
    if (index === 0) newInsertUpdateState.descriptionErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleInputTextChange = (propertyName: string, newValue: any) => {
    const newInsertUpdateState = { ...insertUpdateState };
    if (
      propertyName === "action" &&
      `${RuleActionType.CREATECASE}` !== newValue
    ) {
      newInsertUpdateState.classifier = "";
    }
    setInsertUpdateState({
      ...newInsertUpdateState,
      [propertyName]: newValue,
      [`${propertyName}ErrorMessage`]: "",
    });
  };

  const handleIsActiveChange = (newValue: any) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.active = !newInsertUpdateState.active;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOkButtonClick = async () => {
    let i: number;
    let n: number;

    let recordInputDTO: InboundMailRuleDefinitionDTO = {
      // id: insertUpdateState.id,
      id: realMode === "Insert" ? referenceId : recordId,
      description: insertUpdateState.recordLanguageList[0].value,
      webServiceCLassifierId: insertUpdateState.classifier,
      actionType: insertUpdateState.action,
      jsonRule: insertUpdateState.jsonRule,
      isActive: insertUpdateState.active,
      descriptionLocalizedValues: [],
    };

    // n = insertUpdateState.recordLanguageList.length;
    // for (i = 0; i <= n - 1; i++) {
    //   let item: TPKeyValue;
    //   item = insertUpdateState.recordLanguageList[i];
    //   recordInputDTO.descriptionLocalizedValues.push({
    //     order: i + 1,
    //     languageId: item.key,
    //     localizedValue: item.value,
    //   });
    // }

    recordInputDTO.descriptionLocalizedValues =
      insertUpdateState.recordLanguageList.map(({ key, value }, index) => ({
        languageId: key,
        localizedValue: value,
        order: index + 1,
      }));

    let newInsertUpdateState = { ...insertUpdateState };
    let inputDTOGroupValidator =
      new InboundMailRuleDefinitionInputDTOValidator();
    let hasError: boolean = false;
    let resultValidator = inputDTOGroupValidator.validate(recordInputDTO);

    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }

      if (resultValidator.description) {
        newInsertUpdateState.descriptionErrorMessage = await TPI18N.GetResource(
          resultValidator.description,
        );
      } else {
        newInsertUpdateState.descriptionErrorMessage = "";
      }

      if (resultValidator.webServiceCLassifierId) {
        newInsertUpdateState.classifierErrorMessage = await TPI18N.GetResource(
          resultValidator.webServiceCLassifierId,
        );
      } else {
        newInsertUpdateState.classifierErrorMessage = "";
      }

      if (resultValidator.actionType) {
        newInsertUpdateState.actionErrorMessage = await TPI18N.GetResource(
          resultValidator.actionType,
        );
      } else {
        newInsertUpdateState.actionErrorMessage = "";
      }

      if (resultValidator.jsonRule) {
        n = insertUpdateState.jsonRule.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.jsonRule[i]) {
            newInsertUpdateState.jsonRuleErrorMessages[i] =
              await TPI18N.GetResource(`${resultValidator.jsonRule[i]}`);
          }
        }
      } else {
        n = insertUpdateState.jsonRule.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.jsonRuleErrorMessages[i] = "";
        }
      }

      if (resultValidator.isActive) {
        newInsertUpdateState.isActiveErrorMessage = await TPI18N.GetResource(
          resultValidator.isActive,
        );
      } else {
        newInsertUpdateState.isActiveErrorMessage = "";
      }

      if (resultValidator.descriptionLocalizedValues) {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.descriptionLocalizedValues[i]) {
            newInsertUpdateState.recordLanguageListErrorMessages[i] =
              await TPI18N.GetResource(
                `${resultValidator.descriptionLocalizedValues[i]}`,
              );
          }
        }
      } else {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.recordLanguageListErrorMessages[i] = "";
        }
      }

      hasError = true;
    }

    if (hasError) {
      setInsertUpdateState(newInsertUpdateState);
      return;
    }

    const dataRecordInputDTO = {
      id: recordInputDTO.id,
      description: recordInputDTO.description,
      webServiceCLassifierId: recordInputDTO.webServiceCLassifierId || null,
      actionType: recordInputDTO.actionType,
      jsonRule: TPGlobal.stringToUTF8(
        JSON.stringify(recordInputDTO.jsonRule),
      ).toString(),
      isActive: recordInputDTO.isActive,
      descriptionLocalizedValues: recordInputDTO.descriptionLocalizedValues,
    };

    if (realMode === "Insert") {
      await insertInboundMailRuleDefinition(dataRecordInputDTO);
    } else {
      await updateInboundMailRuleDefinition(dataRecordInputDTO);
    }
  };

  const handleCancelButtonClick = () => {
    //callBackResult({ result: "CANCEL", recordId: recordId });
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const insertInboundMailRuleDefinition = async (
    inputDTO: InboundMailRuleDefinition2DTO,
  ) => {
    let serviceClient = new InboundMailboxesRulesService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertInboundMailRuleDefinition(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setRealMode("Update");
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        //callBackResult({ result: "OK", recordId: recordId });
        callBackResult({ result: "save_general_insert", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertInboundMailRuleDefinition ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} insertInboundMailRuleDefinition ex`,
      );
      setIsLoadingScreen(false);
    }
  };

  const updateInboundMailRuleDefinition = async (
    inputDTO: InboundMailRuleDefinition2DTO,
  ) => {
    let serviceClient = new InboundMailboxesRulesService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateInboundMailRuleDefinition(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "save_general_update", recordId: recordId });
        //callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateInboundMailRuleDefinition ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} updateInboundMailRuleDefinition ex`,
      );
      setIsLoadingScreen(false);
    }
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const handleAddRow = () => {
    const newJsonRule = {
      ...initInboundMailRuleDefinitionJson[0],
      RuleNumber: insertUpdateState.jsonRule.length + 1,
    };

    setInsertUpdateState({
      ...insertUpdateState,
      jsonRule: [...insertUpdateState.jsonRule, newJsonRule],
    });
  };

  const handleDeleteRows = (rowIndex: number) => {
    const newJsonRule = insertUpdateState.jsonRule.filter(
      (_, index) => index < rowIndex,
    );
    newJsonRule[rowIndex - 1].Connector = "";
    setInsertUpdateState({
      ...insertUpdateState,
      jsonRule: newJsonRule,
    });
  };

  const handleInputChange = (
    rowIndex: number,
    propertyName: PropertyName,
    newValue: string,
  ) => {
    const updateJsonRule = [...insertUpdateState.jsonRule];
    updateJsonRule[rowIndex][propertyName] = newValue;
    setInsertUpdateState({
      ...insertUpdateState,
      jsonRule: updateJsonRule,
    });
  };

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequeceGeneratorSequencesNameEnum.SQIMRD,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  useEffect(() => {
    setRuleAppliedComponent([]);

    const isArrayEqueals =
      JSON.stringify(initInboundMailRuleDefinitionJson) ===
      JSON.stringify(insertUpdateState.jsonRule);

    if (!isArrayEqueals) {
      setTimeout(() => setRuleAppliedComponent(insertUpdateState.jsonRule), 50);
    }
  }, [insertUpdateState.jsonRule]);

  //Only once to set resources and load function in update mode
  useEffect(() => {
    loadResourcesAndLoadInfo();
    realMode === "Insert" && generalAutomaticId();
  }, []);

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-6">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            {realMode === "Update" ? (
              <TPPageSubTitle>
                {`${subTitleLabel} ${recordId} / ${originalRecordDescription}`}
              </TPPageSubTitle>
            ) : null}
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row pt-2">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={idLabel}
                      isMandatory={true}
                      // value={insertUpdateState.id}
                      value={realMode === "Insert" ? referenceId : recordId}
                      onChange={
                        realMode === "Insert"
                          ? (e: any) =>
                              handleInputTextChange("id", e.target.value)
                          : () => TPGlobal.foo()
                      }
                      maxLength={20}
                      // disabled={realMode !== "Insert"}
                      disabled
                      errorMessage={insertUpdateState.idErrorMessage}
                    />
                  </div>
                </div>
                {insertUpdateState.recordLanguageList.length > 0 &&
                  TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                      index === 0 && (
                        <div className="col-5" key={`languageItem-${item.id}`}>
                          <div className="form-group">
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={index === 0}
                              labelText={`${descriptionLabel} (${item.name})`}
                              value={
                                insertUpdateState.recordLanguageList[index]
                                  .value
                              }
                              onChange={(e: any) =>
                                handleLanguageChange(index, e.target.value)
                              }
                              maxLength={200}
                              errorMessage={
                                insertUpdateState.descriptionErrorMessage
                              }
                            />
                          </div>
                        </div>
                      ),
                  )}
                {insertUpdateState.recordLanguageList.length > 1 && (
                  <>
                    <div className="col-1 pt-4">
                      <TPButton
                        type={TPButtonTypes.icon}
                        icon={TPIconTypes.language}
                        text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                        tooltip={languageListLabel}
                        className={"pt-3"}
                        onClick={handleOpenModalLanguageList}
                      />
                    </div>
                    <TPModalLanguageList
                      isOpen={isOpenModalLanguageList}
                      title={languageListLabel}
                      acceptLabel={saveButtonLabel}
                      cancelLabel={cancelButtonLabel}
                      saveChanges={handleSaveChangesModalLanguageList}
                      closeModal={handleCloseModalLanguageList}
                    >
                      <div
                        className="row overflow-auto"
                        style={{ height: "200px" }}
                      >
                        {TPGlobal.TPClientAvailableLanguages.map(
                          (item, index) =>
                            index > 0 && (
                              <div
                                className="col-12"
                                key={`languageItem-${item.id}`}
                              >
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={false}
                                    labelText={`${descriptionLabel} (${item.name})`}
                                    value={
                                      insertUpdateState.recordLanguageList[
                                        index
                                      ].value
                                    }
                                    onChange={(e: any) =>
                                      handleLanguageChange(
                                        index,
                                        e.target.value,
                                      )
                                    }
                                    maxLength={200}
                                    errorMessage={
                                      insertUpdateState
                                        .recordLanguageListErrorMessages[index]
                                    }
                                  />
                                </div>
                              </div>
                            ),
                        )}
                      </div>
                    </TPModalLanguageList>
                  </>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row py-2">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={(e: any) => {
                        handleInputTextChange("action", e.target.value);
                      }}
                      dataSource={mailActionToSelect}
                      value={insertUpdateState.action}
                      labelText={actionLabel}
                      isMandatory={true}
                      errorMessage={insertUpdateState.actionErrorMessage}
                    />
                  </div>
                </div>
                {insertUpdateState.action ===
                  `${RuleActionType.CREATECASE}` && (
                  <div className="col-6">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        onChange={(e: any) => {
                          handleInputTextChange("classifier", e.target.value);
                        }}
                        dataSource={quickClassifiersToSelect}
                        value={insertUpdateState.classifier}
                        labelText={classifierLabel}
                        isMandatory={true}
                        errorMessage={insertUpdateState.classifierErrorMessage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <TPPageSection>
              <div className="form-group">
                <TPCheckBox
                  id="IdCheckBox"
                  labelText={isActiveLabel}
                  checked={insertUpdateState.active}
                  onChange={(e: any) => {
                    // handleInputTextChange("active", e.target.value);
                    handleIsActiveChange(e.target.value);
                  }}
                ></TPCheckBox>
              </div>
            </TPPageSection>
          </div>
        </div>

        {/* <div className="row py-2">
          <div className="col-10 ">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      onChange={(e: any) => {
                        handleInputTextChange("isActive", e.target.value);
                      }}
                      dataSource={mailActiveToSelect}
                      value={insertUpdateState.isActive}
                      labelText={isActiveLabel}
                      isMandatory={true}
                      errorMessage={insertUpdateState.isActiveErrorMessage}
                    />
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div> */}

        <div className="row mt-2">
          <div className="col-10">
            <div>
              <h4>{conditionsDefinitionLabel}</h4>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{conditionLabel}</th>
                  <th scope="col" className="text-danger">
                    (
                  </th>
                  <th scope="col">
                    {baseFieldLabel}
                    <span className="text-danger">*</span>
                  </th>
                  <th scope="col">
                    {comparisonOperatorLabel}
                    <span className="text-danger">*</span>
                  </th>
                  <th scope="col">
                    {referenceValueLabel}
                    <span className="text-danger">*</span>
                  </th>
                  <th scope="col" className="text-danger">
                    )
                  </th>
                  <th scope="col">{addNewConditionLabel}</th>
                </tr>
              </thead>
              {/* <tbody>
                            {showRows()}
                        </tbody> */}
              <tbody>
                {insertUpdateState.jsonRule.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <div className="row mt-4">
                        <div className="col">
                          <div>{row.RuleNumber}</div>
                        </div>
                        <div className="col">
                          {index > 0 && (
                            <TPButton
                              type={TPButtonTypes.icon}
                              icon={TPIconTypes.delete}
                              tooltip={deleteConditionLabel}
                              onClick={() => handleDeleteRows(index)}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="form-group" style={{ width: "40px" }}>
                        <TPTextBox
                          id="IdTextBox"
                          value={row.ParenthesisOpen}
                          onChange={(e: any) =>
                            handleInputChange(
                              index,
                              "ParenthesisOpen",
                              e.target.value,
                            )
                          }
                          maxLength={200}
                          errorMessage={""}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <TPSelect
                          id="IdSelect"
                          onChange={(e: any) => {
                            handleInputChange(
                              index,
                              "BaseField",
                              e.target.value,
                            );
                          }}
                          dataSource={mailBaseFieldsValuesToSelect}
                          value={row.BaseField}
                          errorMessage={""}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <TPSelect
                          id="IdSelect"
                          onChange={(e: any) => {
                            handleInputChange(
                              index,
                              "ComparisonOperator",
                              e.target.value,
                            );
                          }}
                          dataSource={mailComparisionValuesToSelect}
                          value={row.ComparisonOperator}
                          errorMessage={""}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          value={row.ReferenceValue}
                          onChange={(e: any) =>
                            handleInputChange(
                              index,
                              "ReferenceValue",
                              e.target.value,
                            )
                          }
                          maxLength={200}
                          errorMessage={""}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group" style={{ width: "40px" }}>
                        <TPTextBox
                          id="IdTextBox"
                          value={row.ParenthesisClose}
                          onChange={(e: any) =>
                            handleInputChange(
                              index,
                              "ParenthesisClose",
                              e.target.value,
                            )
                          }
                          maxLength={200}
                          errorMessage={""}
                        />
                      </div>
                    </td>
                    <td>
                      <div className={"d-flex justify-content-center mt-4"}>
                        {index !== insertUpdateState.jsonRule.length - 1 ? (
                          <TPRadioGroup
                            id="IdRadioGroup"
                            value={row.Connector}
                            source={logicalOperatorToSelect}
                            onChange={(e: any) =>
                              handleInputChange(
                                index,
                                "Connector",
                                e.target.value,
                              )
                            }
                            isHorizontal={true}
                            errorMessage={""}
                          ></TPRadioGroup>
                        ) : (
                          <TPButton
                            type={TPButtonTypes.icon}
                            icon={TPIconTypes.addCircle}
                            tooltip={addConditionLabel}
                            onClick={handleAddRow}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row mt-1">
            <div className="col-10">
              <TPPageSection>
                <TPLabel
                  isMandatory={false}
                  labelText={queryExpressionTitle}
                  className="mb-1"
                />
                {/* <TPLanguage resourceSet={"AddTaskFormComponent"} resourceId={"ModalSuccessTitle"} /> */}
                <div className="row mx-1">
                  <div className="col min-vh-5">
                    {ruleAppliedComponent.length > 0 && (
                      <RuleApplied
                        inboundMailRuleJson={ruleAppliedComponent}
                        isHorizontal={true}
                      />
                    )}
                  </div>
                </div>
              </TPPageSection>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-10">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>
  );
};

export default InboundMailboxesRulesInsertUpdate;
