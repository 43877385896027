import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTreeView, {
  TPTreeViewProps,
} from "@/components/TPTreeView/TPTreeView";
import TPGlobal from "@/helpers/TPGlobal";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useEffect, useState } from "react";

export type TPModalTreeViewState = {
  isShown: boolean;
  guidSession: string;
  treeId: string;
};

interface TPModalTreeViewProps {
  title: string;
  acceptLabel: string;
  cancelLabel: string;
  isShown: boolean;
  callBackAnswer: Function;
  treeProps: TPTreeViewProps;
  showSelectRootNodeButton?: boolean;
  selectRootLabel?: string;
  selectedNodeIdPlusDescription: string;
  autoCloseWhenSelect?: boolean;
  loadChildrenFromThisParent?: string;
}

const TPModalTreeView: FC<TPModalTreeViewProps> = ({
  title,
  acceptLabel,
  cancelLabel,
  isShown,
  callBackAnswer,
  treeProps,
  showSelectRootNodeButton = false,
  selectRootLabel = "",
  selectedNodeIdPlusDescription,
  autoCloseWhenSelect = false,
  loadChildrenFromThisParent = "",
}): ReactElement => {
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);

  const [dataToReturn, setDataToReturn] = useState("");
  const [labelToReturn, setLabelToReturn] = useState("");
  const [hierarchyToReturn, setHierarchyToReturn] = useState("");

  const [
    mustSelectLastLevelBranchMessage,
    setMustSelectLastLevelBranchMessage,
  ] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  //Load Resources
  const loadResources = async () => {
    setMustSelectLastLevelBranchMessage(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "MustSelectLastLevelBranch"
      )
    );
  };

  const handleChangeNodeCallBack = (
    nodeId: string,
    label: string,
    hierachyDescription: string
  ) => {
    setErrorMessage("");
    setDataToReturn(nodeId);
    setLabelToReturn(label);
    setHierarchyToReturn(hierachyDescription);
    if (autoCloseWhenSelect) {
      callBackAnswer(true, nodeId, label, hierachyDescription);
    }
  };

  const handleTrySelectLastLevelBranch = () => {
    setErrorMessage(mustSelectLastLevelBranchMessage);
    setTimeout(function () {
      setErrorMessage("");
    }, 1500);
  };

  //run only once
  useEffect(() => {
    loadResources();
  }, []);

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setErrorMessage("");
                  callBackAnswer(false, null, null, null);
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "500px" }}>
              <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
                <TPTreeView
                  setIsLoading={setIsLoadingScreen}
                  treeId={treeProps.treeId}
                  dataSource={treeProps.dataSource}
                  setDataSource={treeProps.setDataSource}
                  selectedNode={treeProps.selectedNode}
                  setSelectedNode={treeProps.setSelectedNode}
                  selectedLabel={treeProps.selectedLabel}
                  setSelectedLabel={treeProps.setSelectedLabel}
                  selectedHierarchyLabel={treeProps.selectedHierarchyLabel}
                  setSelectedHierarchyLabel={
                    treeProps.setSelectedHierarchyLabel
                  }
                  renderParent={loadChildrenFromThisParent}
                  handleChangeNodeCallBack={(
                    nodeId: string,
                    label: string,
                    hierarchyDescription: string
                  ) =>
                    handleChangeNodeCallBack(
                      nodeId,
                      label,
                      hierarchyDescription
                    )
                  }
                  filterIsActive={treeProps.filterIsActive}
                  filterIsVisible={treeProps.filterIsVisible}
                  guidTree={treeProps.guidTree}
                  isAdminMode={treeProps.isAdminMode}
                  mustSelectLastLevelBranch={
                    treeProps.mustSelectLastLevelBranch
                  }
                  onTrySelectLastLevelBranch={handleTrySelectLastLevelBranch}
                ></TPTreeView>
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div style={{ width: "100%" }}>
                {errorMessage !== "" ? (
                  <h6 className="alert alert-danger">{errorMessage}</h6>
                ) : (
                  <span style={{ fontSize: "50px" }}>&nbsp;</span>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  {showSelectRootNodeButton && (
                    <TPButton
                      id="select-root-node"
                      type={TPButtonTypes.primary}
                      onClick={() => {
                        setErrorMessage("");
                        callBackAnswer(true, null, null, null);
                      }}
                    >
                      {selectRootLabel}
                    </TPButton>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  {!treeProps.selectedLabel && (
                    <>
                      <h6 style={{ color: "#5C636A" }}>
                        <span style={{ fontWeight: "600" }}>
                          {selectedNodeIdPlusDescription}
                        </span>
                        &nbsp;&nbsp;
                        {treeProps.selectedLabel.length > 20
                          ? treeProps.selectedLabel.substring(0, 20) + "..."
                          : treeProps.selectedLabel}
                        &nbsp;&nbsp;(
                        {treeProps.selectedNode})
                      </h6>
                    </>
                  )}

                  <TPButton
                    id="accept-md"
                    type={TPButtonTypes.primary}
                    onClick={() => {
                      setErrorMessage("");
                      callBackAnswer(
                        true,
                        dataToReturn,
                        labelToReturn,
                        hierarchyToReturn
                      );
                    }}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    id="cancel-md"
                    type={TPButtonTypes.link}
                    onClick={() => {
                      setErrorMessage("");
                      callBackAnswer(false, null, null, null);
                    }}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={backdropClass}></div>
    </>
  );
};

export default TPModalTreeView;
