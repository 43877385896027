import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  TableContainer,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModal from "@/layouts/TPModal/TPModal";
import { EmailTemplateAdminDetailInputDTO } from "@/models/EmailTemplates/EmailTemplateInputDTO";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { FC, useEffect, useRef, useState } from "react";
import "./style.css";
import allThemes from "@/assets/styles/theme";

type EmailTemplateAdminDetailProps = {
  recordInput: string;
  languageId: string;
  callBackComands: Function;
  recordDescription: string;
};

type additionalDataAutoCompleteType = {
  additionalDataAutoComplete: Array<TPKeyValue>;
  additionalDataAutoCompleteErrorMessage: string;
  [x: string]: any;
};

type LanguageEmailTemplate = {
  emailTemplateId: string;
  languageId: string;
  mode: string;
  subject: string;
  body: string;
  isShown: boolean;
  [key: string]: any;

  // Validations
  languageIdErrorMessage: string;
  subjectErrorMessage: string;
  bodyErrorMessage: string;
};

const EmailTemplatesAdminDetail: FC<EmailTemplateAdminDetailProps> = ({
  recordInput,
  recordDescription,
  languageId,
  callBackComands,
}) => {
  const editorRef = useRef<any>(null);
  const componentFileName: string = "EmailTemplatesAdminDetail.tsx";
  const multilanguageTableName: String = "EMailTEmplate";
  const multilanguageDescriptionField: String = "Description_EMTE";
  const [descriptionLanguagesList, setDescriptionLanguagesList] = useState<
    Array<EmailTemplateAdminDetailInputDTO>
  >([]);
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  //screen Resources
  const resourceSet: string = "EmailTemplatesAdminDetail";
  const [titleLabel, setTitleLabel] = useState("Email template languages");
  const [subTitleLabel, setSubTitleLabel] = useState("Email template Id");
  const [detailsColumnLabel, setDetailsColumnLabel] =
    useState("Other Languages");
  const [languageColumnLabel, setLanguageColumnLabel] = useState("Language");
  const [subjectColumnLabel, setSubjectColumnLabel] = useState("Subject");
  const [bodyColumnLabel, setBodyColumnLabel] = useState("Body");
  const [addButton, setAddButton] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [deleteLabel, setDeleteLabel] = useState("");
  const [updateLabel, setUpdateLabel] = useState("");
  //modal

  const [newTitleModal, setNewTitleModal] = useState("");
  const [updateTitleModal, setUpdateTitleModal] = useState("");
  const [titleSectionLabel, setTitleSectionLabel] = useState("");
  const [languageLabel, setLanguageLabel] = useState("");
  const [subjectLabel, setSubjectLabel] = useState("");
  const [languageList, setLanguageList] = useState<Array<TPKeyValue>>([]);
  const [isOpenTagModal, setIsOpenTagModal] = useState(false);
  const [tagGroups, setTagGroups] = useState<Array<TPKeyValue>>([]);
  const [tags, setTags] = useState<Array<TPKeyValue>>([]);
  const [isShownModalSubjectSelect, setIsShownModalSubjectSelect] =
    useState(false);
  const editorSubjectRef = useRef<HTMLTextAreaElement>(null); // Top n options for arrow icon
  const [autocompleteToTopNOptions, setAutocompleteToTopNOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [autocompleteToOptions, setAutocompleteToOptions] = useState<
    Array<TPKeyValue>
  >([]);
  const [bodyGroup, setBodyGroup] = useState({ tag: "" });
  const initialadditionalDataAutoCompleteState: additionalDataAutoCompleteType =
    {
      additionalDataAutoComplete: [],
      additionalDataAutoCompleteErrorMessage: "",
    };

  const [additionalDataAutoCompleteState, setadditionalDataAutoCompleteState] =
    useState<additionalDataAutoCompleteType>(
      initialadditionalDataAutoCompleteState,
    );

  //State modal delete
  let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
  };

  const [modalQuestionState, setModalQuestionState] = useState(
    modalQuestionInitialState,
  );

  //modal delete resources
  const [deleteQuestion, setDeleteQuestion] = useState("");
  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteOkLabel, setDeleteOkLabel] = useState("");
  const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

  const initialModalScreenState: LanguageEmailTemplate = {
    mode: "Insert",
    isShown: false,
    subject: "",
    body: "",
    emailTemplateId: "",
    languageId: "",
    languageIdErrorMessage: "",
    subjectErrorMessage: "",
    bodyErrorMessage: "",
    emailTemplateIdErrorMessage: "",
    tagGroup: "",
    tag: "",
    tagErrorMessage: "",
    tagGroupErrorMessage: "",
  };

  const [modalScreenState, setModalScreenState] =
    useState<LanguageEmailTemplate>(initialModalScreenState);

  useEffect(() => {
    loadResourcesAndGetEmailTemplate();
  }, []);

  const loadResourcesAndGetEmailTemplate = async () => {
    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubTitleLabel"));
    setDetailsColumnLabel(
      await TPI18N.GetText(resourceSet, "DetailsColumnLabel"),
    );
    setLanguageColumnLabel(
      await TPI18N.GetText(resourceSet, "LanguageColumnLabel"),
    );
    setSubjectColumnLabel(
      await TPI18N.GetText(resourceSet, "SubjectColumnLabel"),
    );
    setBodyColumnLabel(await TPI18N.GetText(resourceSet, "BodyColumnLabel"));
    setAddButton(await TPI18N.GetText(resourceSet, "AddButton"));
    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setDeleteLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
    );
    setUpdateLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
    );

    //Modal
    setNewTitleModal(await TPI18N.GetText(resourceSet, `TitleINSERTModal`));
    setUpdateTitleModal(await TPI18N.GetText(resourceSet, `TitleUPDATEModal`));

    setTitleSectionLabel(
      await TPI18N.GetText(resourceSet, `TitleSectionLabel`),
    );
    setLanguageLabel(await TPI18N.GetText(resourceSet, "LanguageLabel"));
    setSubjectLabel(await TPI18N.GetText(resourceSet, "SubjectLabel"));

    //modal delete
    setDeleteQuestion(await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"));
    setDeleteTitle(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
    );
    setDeleteOkLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );
    setDeleteCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );

    setIsLoadingScreen(true);

    let expectedcodes: Array<number> = [200];
    let descriptionLanguagesList = await getRecordLanguageList(
      recordInput,
      multilanguageTableName,
      multilanguageDescriptionField,
      expectedcodes,
    );

    if (descriptionLanguagesList.length == 0) {
      TPLog.Log(
        `Error ${componentFileName} getById getRecordLanguageList`,
        TPLogType.ERROR,
        "recordLanguagesList is empty",
      );
      console.error(
        `Error ${componentFileName} getById getRecordLanguageList is empty`,
      );
      setIsLoadingScreen(false);
      return;
    }

    await getAllLanguagesByTemplate();

    //load Languages
    let newlanguageList: TPKeyValue[] = [
      { key: '', value: '--' },
      ...TPGlobal.TPClientAvailableLanguages
      .map((lang) => {
        return { key: lang.id, value: lang.name } as TPKeyValue
      })
    ];

    setLanguageList(newlanguageList);

    await getTagGroups();
    setIsLoadingScreen(false);
  };

  const getAllLanguagesByTemplate = async () => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      let responseRequest: any =
        await serviceClient.getAllLanguagesByTemplateId(
          recordInput,
          false,
          true,
          expectedCodes,
        );
      setDescriptionLanguagesList(responseRequest);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getAllLanguagesByTemplateId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} getAllLanguagesByTemplateId ex`,
      );
      return [];
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
    _multilanguageTableName: String,
    _multilanguageFieldName: String,
    _expectedCodes: Array<number>,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];

    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          _multilanguageTableName,
          _multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfoLanguage: Array<MultilanguageFieldValueViewModel>;
      recordInfoLanguage = [...responseRequest];
      return recordInfoLanguage;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  //handler to create new language for email template
  const handleOpenModalCreateEmailTemplate = () => {
    let newModalScreenState = { ...modalScreenState };
    newModalScreenState.mode = "Insert";
    newModalScreenState.emailTemplateId = "";
    newModalScreenState.languageId = "";
    newModalScreenState.subject = "";
    newModalScreenState.body = "";
    newModalScreenState.isShown = true;
    setModalScreenState(newModalScreenState);
  };

  //called when contact modal returns insert or update
  const callbackEmailtemplateModalAnswer = async (status: boolean) => {
    let newModalScreenState = { ...modalScreenState, isShown: false };

    if (status === true) {
      // Validations

      if (!modalScreenState.languageId) {
        let newModalScreenState = { ...modalScreenState };
        newModalScreenState.languageIdErrorMessage = await TPI18N.GetText(
          resourceSet,
          "InputDTOLanguageIdEmpty",
        );
        setModalScreenState(newModalScreenState);
        return;
      }

      if (!modalScreenState.subject) {
        let newModalScreenState = { ...modalScreenState };
        newModalScreenState.subjectErrorMessage = await TPI18N.GetText(
          resourceSet,
          "InputDTOSubjectEmpty",
        );
        setModalScreenState(newModalScreenState);
        return;
      }

      if (
        !modalScreenState.body ||
        modalScreenState.body.replace(/<[^>]*>/g, "").length <= 0
      ) {
        let newModalScreenState = { ...modalScreenState };
        newModalScreenState.bodyErrorMessage = await TPI18N.GetText(
          resourceSet,
          "InputDTOBodyEmpty",
        );
        setModalScreenState(newModalScreenState);
        return;
      }

      const recordInputDTO: EmailTemplateAdminDetailInputDTO = {
        emailTemplateId:
          modalScreenState.mode === "Insert"
            ? recordInput
            : modalScreenState.emailTemplateId,
        languageId: modalScreenState.languageId,
        subject: modalScreenState.subject,
        body: TPGlobal.stringToUTF8(modalScreenState.body).toString(),
      };
      if (modalScreenState.mode === "Insert") {
        await insertOrUpdateLanguageForTemplate(recordInputDTO, true);
      } else if (modalScreenState.mode === "UPDATE") {
        await insertOrUpdateLanguageForTemplate(recordInputDTO, false);
      }
    }

    // Common code for both Insert and Update
    setModalScreenState(newModalScreenState);
  };

  const insertOrUpdateLanguageForTemplate = async (
    recordInputDTO: EmailTemplateAdminDetailInputDTO,
    isInsert: boolean,
  ) => {
    try {
      setIsLoadingScreen(true);
      if (isInsert) {
        await insertLanguageForTemplate(recordInputDTO);
      } else {
        await UpdateLanguageForTemplate(recordInputDTO);
      }
      await getAllLanguagesByTemplate();
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertOrUpdateLanguageForTemplate ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} insertOrUpdateLanguageForTemplate ex`,
      );
    } finally {
      setIsLoadingScreen(false);
    }
  };

  const UpdateLanguageForTemplate = async (
    inputDTO: EmailTemplateAdminDetailInputDTO,
  ) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateLanguageForTemplate(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackComands({ result: "OK", recordId: recordInput });
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} update ex`);
      setIsLoadingScreen(false);
    }
  };

  const insertLanguageForTemplate = async (
    inputDTO: EmailTemplateAdminDetailInputDTO,
  ) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.insertLanguageForTemplate(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackComands({ result: "OK", recordId: recordInput });
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} update ex`);
      setIsLoadingScreen(false);
    }
  };

  const getEmailTemplateById = async (languageId: string) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);
      let response = await serviceClient.getLanguageDetailByTemplateId(
        recordInput,
        languageId,
        false,
        true,
        expectedCodes,
      );

      let data = { ...response };
      let newScreenState = { ...modalScreenState };
      newScreenState.emailTemplateId = data.emailTemplateId;
      newScreenState.languageId = data.languageId;
      newScreenState.subject = data.subject;
      newScreenState.body = TPGlobal.stringToUTF8(data.body).toString();

      setModalScreenState(newScreenState);
      setIsLoadingScreen(false);
      return data;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getEmailTemplateById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getEmailTemplateById ex`);

      setIsLoadingScreen(false);
      return null;
    }
  };

  const handleInputTextChange = async (propertyName: string, newValue: any) => {
    if (propertyName === "tagGroup") {
      setModalScreenState({
        ...modalScreenState,
        [propertyName]: newValue,
        [propertyName + "ErrorMessage"]: "",
      });
      await getTagsByGroup(newValue);
    }

    if (propertyName === "tag") {
      setBodyGroup({
        tag: newValue,
      });
    }

    setModalScreenState({
      ...modalScreenState,
      [propertyName]: newValue,
      [propertyName + "ErrorMessage"]: "",
    });
  };

  const handleDeleteItem = (languageId: string) => {
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = true;
    newModalQuestionState.callBackData = {
      languageId: languageId,
      recordInput: recordInput,
    };
    setModalQuestionState(newModalQuestionState);
  };

  //Delete email template after question confirmation
  const handleCallBackDeleteModal = async (
    confirmDelete: boolean,
    callBackData: any,
  ) => {
    let expectedCodes: Array<number> = [200];
    let serviceClient = new EmailTemplateService();
    let newModalQuestionState: TPModalQuestionState;
    newModalQuestionState = { ...modalQuestionState };
    newModalQuestionState.isShown = false;
    newModalQuestionState.callBackData = {};
    setModalQuestionState(newModalQuestionState);
    if (confirmDelete) {
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.deleteEmailTemplateById(
          callBackData.recordInput,
          callBackData.languageId,
          true,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        if (responseRequest.responseData.responseCode !== 500) {
          getAllLanguagesByTemplate();
          callBackComands({
            command: "delete",
            recordId: callBackData.recordInput,
            languageId: callBackData.languageId,
          });
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} handleCallBackDeleteModal ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} handleCallBackDeleteModal ex`,
        );
        setIsLoadingScreen(false);
      }
    }
  };

  const handleUpdateItem = async (languageId: string) => {
    let data: any = await getEmailTemplateById(languageId);

    let newModalScreenState = { ...modalScreenState };
    newModalScreenState.emailTemplateId = data.emailTemplateId;
    newModalScreenState.body = data.body;
    newModalScreenState.languageId = languageId;
    newModalScreenState.subject = data.subject;
    newModalScreenState.mode = "UPDATE";
    newModalScreenState.isShown = true;
    newModalScreenState.languageIdErrorMessage = "";

    setModalScreenState(newModalScreenState);
  };

  const handleCallbackAnser = () => {
    setModalScreenState((prevState) => ({
      ...prevState,
      tag: "",
      tagGroup: "", // or whatever default value is appropriate
    }));

    setAutocompleteToOptions([]);
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);

    setIsOpenTagModal(false);
  };

  const handleOpenTagPopup = () => {
    setModalScreenState((prevState) => ({
      ...prevState,
      tag: "",
      tagGroup: "", // or whatever default value is appropriate
    }));

    setAutocompleteToOptions([]);
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);

    setIsOpenTagModal(true);
  };

  // Example for getTagGroups
  const getTagGroups = async () => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getTagGroups(
        false,
        true,
        expectedCodes,
      );
      // Handle the response accordingly
      if (responseRequest) {
        // Do something with the result...
        let tagGroupOptions = responseRequest.map((tagGroup: any) => ({
          key: tagGroup.id,
          value: tagGroup.localizedDescription,
        }));
        tagGroupOptions.unshift({
          key: "--",
          value: "--",
        });

        setTagGroups(tagGroupOptions);
      }

      setIsLoadingScreen(false);
      return;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTagGroups ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTagGroups ex`);
      setIsLoadingScreen(false);
    }
  };

  // Example for getTagsByGroup
  const getTagsByGroup = async (groupCode: string) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getTagsByGroup(
        groupCode,
        false,
        true,
        expectedCodes,
      );

      // Handle the response accordingly
      if (responseRequest) {
        // Do something with the result...
        // tags dropdown
        let tagsOptions = responseRequest.map((tags: any) => ({
          key: tags.value,
          value: tags.localizedDescription,
        }));
        tagsOptions.unshift({
          key: "--",
          value: "--",
        });
        setTags(tagsOptions);
      }

      setIsLoadingScreen(false);
      return;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTagsByGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTagsByGroup ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleToOnAutocompleteQuery = async (query: string) => {
    let tasktypeService = new EmailTemplateService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      //Load users by search
      let responseRequest = await tasktypeService.getAdditionalData(
        "S_CASEDET",
        query,
        false,
        true,
        expectedCodes,
      );
      let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item: { id: any; localizedDescription: any }) {
          return {
            key: item.id,
            value: `${item.localizedDescription}`,
          };
        },
      );
      setAutocompleteToOptions(newToKeyValueList);
      return newToKeyValueList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
      );
      return [];
    }
  };

  const handleToChange = (newSelectedValue: Array<TPKeyValue>) => {
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete =
      newSelectedValue;
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
  };

  const handleAutoCompleteTopNClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteToTopNOptions.length === 0) {
      newTopNOptions = await handleToOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteToTopNOptions([...newTopNOptions]);
        setAutocompleteToOptions([...newTopNOptions]);
        let newAdditionalDataAutoCompleteState = {
          ...additionalDataAutoCompleteState,
        };
        newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
        newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
          "";
        setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
      }
    } else {
      //use cached values;
      setAutocompleteToOptions([...autocompleteToTopNOptions]);
      let newAdditionalDataAutoCompleteState = {
        ...additionalDataAutoCompleteState,
      };
      newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
      newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
        "";
      setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
    }
  };

  const handleToOnAutocompleteKeyDown = (event: any) => {
    //left arrow 37
    //right arror 39
    //enter 13
    //home 36
    //end  35
    if (
      event.keyCode != 37 &&
      event.keyCode != 39 &&
      event.keyCode != 13 &&
      event.keyCode != 35 &&
      event.keyCode != 36
    ) {
      setAutocompleteToOptions([]);
      let newAdditionalDataAutoCompleteState = {
        ...additionalDataAutoCompleteState,
      };
      newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
      newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
        "";
      setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
    }
  };

  const handleAddTagForBody = () => {
    // Get the current content of the editor
    handleInputTextChange("body", bodyGroup.tag);

    // const currentContent = editorRef.current.getContent();

    // Get the last cursor position
    // const lastCursorPosition = editorRef.current.selection.getRng();

    // Ensure the offset is within the valid range
    // const validOffset = Math.min(lastCursorPosition.startOffset, currentContent.length);

    // Get the editor selection
    // const editorSelection = editorRef.current.selection;

    // Construct the tag to be inserted
    // const tag = additionalDataAutoCompleteState.additionalDataAutoComplete.length === 1 && modalScreenState.tag ? `ADDACASES:${additionalDataAutoCompleteState.additionalDataAutoComplete[0].key}` : modalScreenState.tag;

    // Insert the tag into the content at the valid offset
    // const updatedContent = `${currentContent.substring(0, validOffset)}[${tag}]${currentContent.substring(validOffset)}`;

    // Set the content with the updated data
    // editorRef.current.setContent(updatedContent);

    // Set the cursor position after the inserted tag
    // editorSelection.setCursorLocation(validOffset + tag.length);

    // Close the modal
    setIsOpenTagModal(false);
  };

  const handleAddTagForSubject = () => {
    // Get the current selection position
    const selectionStart = editorSubjectRef.current?.selectionStart;
    const selectionEnd = editorSubjectRef.current?.selectionEnd;

    // Ensure selectionStart and selectionEnd are not undefined
    if (selectionStart !== undefined && selectionEnd !== undefined) {
      // Get the current value of the subject
      const currentSubject = modalScreenState.subject as string; // Explicitly cast to string

      // Construct the tag to be inserted (assuming you have a tag state)
      // const tag = screenState.tag; // Assuming you have a state for the tag
      const tag =
        additionalDataAutoCompleteState.additionalDataAutoComplete.length ===
          1 && modalScreenState.tag
          ? `ADDACASES:${additionalDataAutoCompleteState.additionalDataAutoComplete[0].key}`
          : modalScreenState.tag;

      // Insert the tag into the subject at the cursor position
      const updatedSubject =
        currentSubject.slice(0, selectionStart) +
        `[${tag}]` +
        currentSubject.slice(selectionEnd);

      // Update the subject state with the updated value
      handleInputTextChange("subject", updatedSubject);

      // Close the modal
      setIsOpenTagModal(false);
      setIsShownModalSubjectSelect(false);
    }
  };

  const handleOpenSubjectModal = () => {
    setModalScreenState((prevState) => ({
      ...prevState,
      tag: "",
      tagGroup: "", // or whatever default value is appropriate
    }));

    setAutocompleteToOptions([]);
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);

    setIsOpenTagModal(true);
    setIsShownModalSubjectSelect(true);
  };

  return (
    <>
      {modalQuestionState.isShown && (
        <TPModalQuestion
          id="IDModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.languageId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackDeleteModal}
        ></TPModalQuestion>
      )}

      <TPLoadingOverlay active={isLoadingScreen}>
        {modalScreenState.isShown && (
          <TPModal
            modalState={{
              id: "IdModal",
              titleModal: modalScreenState.mode === 'UPDATE' ? updateTitleModal : newTitleModal,
              acceptLabel: saveButtonLabel,
              cancelLabel: cancelButtonLabel,
              callBackAnswer: callbackEmailtemplateModalAnswer,
              isShown: modalScreenState.isShown,
            }}
          >
            <TPLoadingOverlay active={isLoadingScreen} top={"200px"}>
              <div className="row">
                <div className="col-6">
                  <TPPageSectionTitle>{titleSectionLabel}</TPPageSectionTitle>
                </div>
              </div>
              <TPPageSection>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPSelect
                        id="IdSelect"
                        disabled={modalScreenState.mode !== "Insert"}
                        isMandatory={true}
                        labelText={languageLabel}
                        onChange={(e: any) =>
                          handleInputTextChange("languageId", e.target.value)
                        }
                        dataSource={
                          modalScreenState.mode === 'UPDATE' ?
                            languageList
                            :
                            languageList.filter(lang => !descriptionLanguagesList.find(desc => desc.languageId === lang.key))}
                        value={modalScreenState.languageId}
                        errorMessage={modalScreenState.languageIdErrorMessage}
                      ></TPSelect>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <TPTextBox
                        id="IdTextBox"
                        isMandatory={true}
                        textStyle={{
                          border: "none",
                          borderRadius: "0.375rem 0rem 0rem 0.375rem",
                        }}
                        isTag={true}
                        tagHandler={handleOpenSubjectModal}
                        inputRef={editorSubjectRef}
                        onChange={(e: any) =>
                          handleInputTextChange("subject", e.target.value)
                        }
                        value={modalScreenState.subject as string}
                        labelText={subjectLabel}
                        errorMessage={modalScreenState.subjectErrorMessage}
                        maxLength={500}
                      />
                    </div>
                  </div>
                </div>
              </TPPageSection>

              <TPPageSection>
                <div className="row mt-3">
                  <div className="col">
                    <div className="form-group">
                      <TPLanguage
                        resourceId={"BodyLabel"}
                        resourceSet={resourceSet}
                      ></TPLanguage>
                      <span style={{ color: "#dc3545" }}>*</span>
                      <TPEditor
                        referece={(editor: any) => (editorRef.current = editor)}
                        placeholder=""
                        value={modalScreenState.body}
                        popUpOpen={handleOpenTagPopup}
                        onChange={(value: any) =>
                          handleInputTextChange("body", value)
                        }
                        initialValue=""
                        isVisible={true}
                      />

                      <span style={{ color: "#dc3545", fontSize: "14px" }}>
                        {modalScreenState.bodyErrorMessage}
                      </span>
                    </div>
                  </div>
                </div>
              </TPPageSection>
            </TPLoadingOverlay>
            {isOpenTagModal && (
              <div className="custom-modal-container">
                <div className="custom-modal-content">
                  <div className="custom-modal-header">
                    <h5 className="modal-title">{`${"Available tags for selection"}`}</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCallbackAnser}
                    ></button>
                  </div>
                  <div className="custom-modal-body">
                    {/* Content of the modal body */}

                    <TPSelect
                      id="IdSelect"
                      isMandatory={true}
                      labelText={"Tag Group"}
                      onChange={(e: any) =>
                        handleInputTextChange("tagGroup", e.target.value)
                      }
                      dataSource={tagGroups}
                      value={modalScreenState.tagGroup}
                      errorMessage={modalScreenState.tagGropIdErrorMessage}
                    />
                    <TPSelect
                      id="IdSelect"
                      isMandatory={true}
                      labelText={"Tag"}
                      onChange={(e: any) =>
                        handleInputTextChange("tag", e.target.value)
                      }
                      dataSource={tags}
                      value={modalScreenState.tag}
                      errorMessage={modalScreenState.tagsErrorMessage}
                    />
                    {modalScreenState.tagGroup === "S_TGCASE" &&
                    modalScreenState.tag === "AdditionalData" ? (
                      <TPAutoComplete
                        isMandatory={false}
                        labelText={"Select additional data"}
                        onValueChange={handleToChange}
                        onSearch={(query: string) => {
                          handleToOnAutocompleteQuery(query);
                        }}
                        isLoading={false}
                        options={autocompleteToOptions}
                        withIcon={true}
                        emptyLabel={"emptyLabel"}
                        onKeyDown={handleToOnAutocompleteKeyDown}
                        selected={
                          additionalDataAutoCompleteState.additionalDataAutoComplete
                        }
                        errorMessage={
                          additionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage
                        }
                        downArrowClick={handleAutoCompleteTopNClick}
                      />
                    ) : (
                      ""
                    )}

                    <div style={{ textAlign: "right", marginTop: "18px" }}>
                      <TPButton
                        id="IdButton"
                        type={TPButtonTypes.primary}
                        onClick={
                          isOpenTagModal && isShownModalSubjectSelect
                            ? handleAddTagForSubject
                            : handleAddTagForBody
                        }
                      >
                        {"Select"}
                      </TPButton>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </TPModal>
        )}
        <div className="row">
          <div className="col">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            <TPPageSubTitle>{`${subTitleLabel} : ${recordInput}`}</TPPageSubTitle>
            <hr />
          </div>
        </div>
        <div className="tpFixedHeadTable highlightOnHover">
          <TableContainer>
            <DataTableContainer>
              <table className="tp-fixed-table">
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: "15px",
                      }}
                    >
                      {languageColumnLabel}
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: "15px",
                      }}
                    >
                      {subjectColumnLabel}
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: "15px",
                      }}
                    >
                      {bodyColumnLabel}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {descriptionLanguagesList.map((item, index) => {
                    return (
                      <>
                        <tr>
                          {/* <td style={{ width: "15px" }}>
                            {item.languageId !==
                              TPGlobal.TPClientDefaultLanguage && (
                              <div className="dropdown">
                                <TPButton
                                  id="IdButton"
                                  dataBsToggle={true}
                                  type={TPButtonTypes.empty}
                                  onClick={() => {
                                    TPGlobal.foo();
                                  }}
                                  className={"menu-button"}
                                  style={{
                                    padding: "1px 1px",
                                    minWidth: "20px",
                                  }}
                                >
                                  <TPIcon iconType={TPIconTypes.moreVert} />
                                </TPButton>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleDeleteItem(item.languageId)
                                      }
                                      className="dropdown-item"
                                      href="#"
                                    >
                                      {deleteLabel}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleUpdateItem(item.languageId)
                                      }
                                      className="dropdown-item"
                                      href="#"
                                    >
                                      {updateLabel}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </td> */}
                          {/* <td style={{ width: "15px" }}>
                            {item.languageId !==
                              TPGlobal.TPClientDefaultLanguage && (
                              <TPButton
                                id="IdButton"
                                type={TPButtonTypes.primary}
                                onClick={() =>
                                  handleUpdateItem(item.languageId)
                                }
                                className="update-button"
                                style={{
                                  padding: "1px 1px",
                                  minWidth: "20px",
                                  marginRight: "13px",
                                }}
                              >
                                <TPIcon iconType={TPIconTypes.chevronRight} />
                              </TPButton>
                            )}
                          </td> */}
                          <td
                            style={{
                              fontSize: "13px",
                              fontWeight: 400,
                              padding: "5px",
                            }}
                            className="text-start"
                          >
                            <button
                              style={{color: allThemes.base.primary, border:'none', background:'none'}}
                              onClick={() => handleUpdateItem(item.languageId)}
                            >
                              <b><u>{TPGlobal.TPClientAvailableLanguages.find(lang => lang.id === item.languageId)?.name}</u></b>
                            </button>
                          </td>
                          <td
                            style={{
                              fontSize: "13px",
                              fontWeight: 400,
                              padding: "5px",
                            }}
                            className="text-start"
                          >
                            {item.subject}
                          </td>
                          <td
                            style={{
                              fontSize: "13px",
                              fontWeight: 400,
                              padding: "5px",
                            }}
                            className="text-start"
                          >
                            {item.body}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </DataTableContainer>
          </TableContainer>
        </div>
        <div className="row mt-4">
          <div className="col">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleOpenModalCreateEmailTemplate}
              >
                {addButton}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </>
  );
};

export default EmailTemplatesAdminDetail;
