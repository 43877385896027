import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModal, { TPModalProps } from "@/layouts/TPModal/TPModal";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { OrganizationsViewModel } from "@/models/Organizations/OrganizationsModels";
import { OrganizationsRelationsViewModel } from "@/models/OrganizationsRelations/OrganizationsRelationsModels";
import {
  BaseLevelIdValidator,
  OrganizationRelationInputDTO,
} from "@/models/QuickClassifierShortcuts/QuickClassifierShortcutsInputDTO";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { OrganizationsService } from "@/services/OrganizationsService";
import { QuickClassifierShortcutService } from "@/services/QuickClassifierShortcutService";
import { RelationsService } from "@/services/RelationsService";
import { TreeService } from "@/services/TreeService";
import * as FileSaver from "file-saver";
import React, { useEffect, useReducer, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import QuickClassifierShortcutInsertUpdate from "./QuickClassfierShortcutInsertUpdate";

type AdminStateType = {
  baseLevelId: string;
  baseLevelIdErrorMessage: string;
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<any>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
  currentLabelTree1: string;
  currentLabelTree2: string;
  currentLabelTree3: string;
  currentLabelTree4: string;
  currentLabelTree5: string;
};

interface QuickClassifierShortcutsAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
  "change_current_organization_relation" = 5,
  "organization_relation_clear" = 6,
  "set_errors" = 7,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const QuickClassifierShortcutsAdmin = React.forwardRef(
  ({ callBackCommands }: QuickClassifierShortcutsAdminInterface, ref) => {
    const componentFileName: string = "QuickClassifierShortcutsAdmin.tsx";
    const resourceSet: string = "QuickClassifierShortcutsAdminComponent";
    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    const [isLoadingScreen, setIsLoadingScreen] = useState(false);
    const [titleLabel, setTitleLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [organizationsRelationsLabel, setOrganizationsRelationsLabel] =
      useState("");

    const [currentMood, setCurrentMood] = useState("insert");
    const [quickClassifierId, setquickClassifierId] = useState("");
    const [hotkey, setHotkey] = useState(0);
    const QCShortcutRef = useRef<any>(null);

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [hotkeyColumnLabel, setHotkeyColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");

    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");

    const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
      useState<Array<TPKeyValue>>([]);

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    const [titleInsertModal, setTitleInsertModal] = useState("");
    const [titleUpdateModal, setTitleUpdateModal] = useState("");
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      baseLevelId: "",
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
      baseLevelIdErrorMessage: "",
      currentLabelTree1: "",
      currentLabelTree2: "",
      currentLabelTree3: "",
      currentLabelTree4: "",
      currentLabelTree5: "",
    };

    //modal hotkey
    const initialStateModalHotkey: TPModalProps = {
      isShown: false,
      titleModal: "",
      acceptLabel: "",
      cancelLabel: "",
      callBackAnswer: TPGlobal.foo,
      modalWidth: "",
    };
    const [modalHotkey, setModalHotkey] = useState<TPModalProps>(
      initialStateModalHotkey
    );

    const handleModalHotkey = async () => {
      // new hotkey
      let recordInputDTO: OrganizationRelationInputDTO = {
        baseLevelId: adminState.baseLevelId,
      };

      let inputDTOValidator = new BaseLevelIdValidator();
      let resultValidator = inputDTOValidator.validate(recordInputDTO);

      if (!TPGlobal.TPIsEmpty(resultValidator)) {
        let validationsErrores: any = new Object();
        if (resultValidator.baseLevelId) {
          validationsErrores.baseLevelIdErrorMessage = await TPI18N.GetResource(
            resultValidator.baseLevelId
          );
        } else {
          validationsErrores.baseLevelIdErrorMessage = "";
        }

        let command: commandType = {
          type: commandsEnum.set_errors,
          payload: {
            errors: validationsErrores,
          },
        };
        dispatchCommand(command);
        return;
      }
      setCurrentMood("insert");
      let newModalHotkey: TPModalProps;
      newModalHotkey = {
        isShown: true,
        titleModal: titleInsertModal,
        acceptLabel: saveButtonLabel,
        cancelLabel: cancelButtonLabel,
        callBackAnswer: modalHotkeyCallback,
        callBackData: {
          currentMood: "insert",
        },
      };
      setModalHotkey(newModalHotkey);
    };

    const modalHotkeyCallback = async (status: boolean, callBackData: any) => {
      if (status) {
        if (QCShortcutRef) {
          const isValid = await QCShortcutRef.current.validateQCShortcut();
          if (isValid) {
            const adminStateModal = QCShortcutRef.current.getAdminState();
            let parametersService = new QuickClassifierShortcutService();
            let expectedCodes: Array<number> = [200];
            let inputDTO = {
              webserviceClassifierUserId: adminStateModal.quickClassifierId,
              hotkey: +adminStateModal.hotkey,
              guidUser: TPGlobal.currentUserGuid,
            };

            try {
              if (callBackData.currentMood === "insert") {
                let responseRequest =
                  await parametersService.insertQuickClassifierShortcut(
                    inputDTO,
                    false,
                    true,
                    expectedCodes
                  );
                setIsLoadingScreen(false);
                if (responseRequest.responseData.responseCode !== 500) {
                  reloadGridCommand();
                  let newModalHotkey: TPModalProps;
                  newModalHotkey = {
                    isShown: false,
                    titleModal: titleLabel,
                    acceptLabel: saveButtonLabel,
                    cancelLabel: cancelButtonLabel,
                    callBackAnswer: modalHotkeyCallback,
                  };
                  setModalHotkey(newModalHotkey);
                }
              } else {
                let responseRequest =
                  await parametersService.updateQuickClassifierShortcut(
                    inputDTO,
                    false,
                    true,
                    expectedCodes
                  );
                setIsLoadingScreen(false);
                if (responseRequest.responseData.responseCode !== 500) {
                  reloadGridCommand();
                  let newModalHotkey: TPModalProps;
                  newModalHotkey = {
                    isShown: false,
                    titleModal: titleUpdateModal,
                    acceptLabel: saveButtonLabel,
                    cancelLabel: cancelButtonLabel,
                    callBackAnswer: modalHotkeyCallback,
                  };
                  setModalHotkey(newModalHotkey);
                  setquickClassifierId("");
                  setHotkey(0);
                }
              }
            } catch (error) {
              TPLog.Log(
                `Error ${componentFileName} getKeyValueParams ex`,
                TPLogType.ERROR,
                error
              );
              console.error(`Error ${componentFileName} getKeyValueParams ex`);
              return null;
            }
          } else {
            return;
          }
        }
        return;
      }

      let newModalHotkey: TPModalProps;
      newModalHotkey = {
        isShown: false,
        titleModal: "tituloxxx",
        acceptLabel: "accepctxx",
        cancelLabel: "cancelxxx",
        callBackAnswer: modalHotkeyCallback,
      };
      setModalHotkey(newModalHotkey);
      setquickClassifierId("");
      setHotkey(0);
    };

    //Update quick classifier
    const handleUpdateClick = (id: string, hotkey: string) => {
      // let command: any = { command: "update", recordId: id };
      // callBackCommands(command);
      let newModalHotkey: TPModalProps;
      newModalHotkey = {
        isShown: true,
        titleModal: titleUpdateModal,
        acceptLabel: saveButtonLabel,
        cancelLabel: cancelButtonLabel,
        callBackAnswer: modalHotkeyCallback,
        callBackData: {
          currentMood: "update",
          quickClassifierId: id,
        },
      };
      setModalHotkey(newModalHotkey);
      setquickClassifierId(id);
      setHotkey(+hotkey);
      setCurrentMood("update");
    };

    //Modal Question to delete quick classifier shortcut
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    const handleHotkeyChangeInTable = async (
      e: any,
      newHotkey: number,
      quickClassifierId: any
    ) => {
      if (e.nativeEvent.data === null) {
        // User cleared the hotkey - delete it
        await deleteHotkey(quickClassifierId);
      } else if (e.nativeEvent.data >= "0" && e.nativeEvent.data <= "9") {
        // User entered a new hotkey - update it
        newHotkey = parseInt(e.nativeEvent.data);
        await updateHotkey(newHotkey, quickClassifierId);
      }
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        // //delete
        // newColumns.push({
        //   name: "",
        //   width: "50px",
        //   style: { padding: 0 },
        //   center: true,
        //   cell: (row: { [x: string]: any }) => {
        //     if (!row["isSystemRecord"]) {
        //       return (
        //         <div className="dropdown">
        //           <TPButton
        //             dataBsToggle={true}
        //             type={TPButtonTypes.empty}
        //             onClick={() => {
        //               TPGlobal.foo();
        //             }}
        //             className={"menu-button"}
        //           >
        //             <TPIcon iconType={TPIconTypes.moreVert} />
        //           </TPButton>
        //           <ul className="dropdown-menu">
        //             <li>
        //               <a
        //                 onClick={(webserviceClassifierUserId: any) =>
        //                   handleDeleteClick(row["webserviceClassifierUserId"])
        //                 }
        //                 className="dropdown-item"
        //                 href="#"
        //               >
        //                 {deleteLabel}
        //               </a>
        //             </li>
        //             <li>
        //               <a
        //                 onClick={() =>
        //                   handleUpdateClick(
        //                     row["webserviceClassifierUserId"],
        //                     row["hotkey"]
        //                   )
        //                 }
        //                 className="dropdown-item"
        //                 href="#"
        //               >
        //                 {updateLabel}
        //               </a>
        //             </li>
        //           </ul>
        //         </div>
        //       );
        //     } else {
        //       return null;
        //     }
        //   },
        // });
        // //update
        // newColumns.push({
        //   width: "50px",
        //   style: { padding: 0 },
        //   cell: (row: { [x: string]: any }) => {
        //     if (!row["isSystemRecord"]) {
        //       return (
        //         <TPButton
        //           type={TPButtonTypes.primary}
        //           onClick={() =>
        //             handleUpdateClick(
        //               row["webserviceClassifierUserId"],
        //               row["hotkey"]
        //             )
        //           }
        //           className="update-button"
        //         >
        //           <TPIcon iconType={TPIconTypes.chevronRight} />
        //         </TPButton>
        //       );
        //     } else {
        //       return null;
        //     }
        //   },
        //   selector: (row: { [x: string]: any }) => row["id"],
        //   sortable: true,
        // });

        //Hotkey
        newColumns.push({
          width: "100px",
          name: hotkeyColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return (
              <input
                type="text"
                value={
                  row["hotkey"] !== undefined && row["hotkey"] !== null
                    ? `${row["hotkey"]}`
                    : ""
                }
                className="hotkey-input"
                style={{
                  width: "100%",
                  border: "1px solid #A00095",
                  backgroundColor: "none",
                  textAlign: "center",
                }}
                onChange={(e) =>
                  handleHotkeyChangeInTable(
                    e,
                    Number(e.target.value),
                    row["webserviceClassifierUserId"]
                  )
                }
              />
            );
          },
        });

        //idColumnLabel
        newColumns.push({
          width: "300px",
          name: idColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["webserviceClassifierUserId"];
          },
          selector: (row: { [x: string]: any }) =>
            row["webserviceClassifierUserId"],
          sortable: true,
        });

        //descriptionColumnLabel
        newColumns.push({
          width: "300px",
          name: descriptionColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["localizedDescriptionWebServiceClassifier"];
          },
          selector: (row: { [x: string]: any }) =>
            row["localizedDescriptionWebServiceClassifier"],
          sortable: true,
        });

        //currentLabelTree1
        newColumns.push({
          width: "300px",
          name: newState.currentLabelTree1,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier1"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier1"],
          sortable: true,
        });

        //currentLabelTree2
        newColumns.push({
          width: "300px",
          name: newState.currentLabelTree2,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier2"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier2"],
          sortable: true,
        });
        //currentLabelTree3
        newColumns.push({
          width: "300px",
          name: newState.currentLabelTree3,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier3"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier3"],
          sortable: true,
        });
        //currentLabelTree4
        newColumns.push({
          width: "300px",
          name: newState.currentLabelTree4,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier4"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier4"],
          sortable: true,
        });

        //currentLabelTree5
        newColumns.push({
          width: "300px",
          name: newState.currentLabelTree5,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier5"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier5"],
          sortable: true,
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    //Get quick classifiers by Filter organizationRelation
    const reloadDataGrid = async () => {
      let serviceClient = new QuickClassifierShortcutService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        // load quicks classifier shortcuts
        let responseRequest =
          await serviceClient.getQuickClassifierShortcutsByGuidUser(
            TPGlobal.currentUserGuid,
            false,
            true,
            expectedCodes
          );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid()
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Get quick classifier shortcut hotkey
    const reloadDataGridShortcut = async (baseLevelId: string) => {
      let serviceClient = new QuickClassifierShortcutService();
      let expectedCodes: Array<number> = [200];
      try {
        setIsLoadingScreen(true);
        // load quicks classifier shortcuts
        let responseRequest =
          await serviceClient.getQuickClassifierShortcutHotkeyById(
            baseLevelId,
            TPGlobal.currentUserGuid,
            false,
            true,
            expectedCodes
          );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommandShortcut = () => {
      reloadDataGridShortcut(adminState.baseLevelId)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    const updateHotkey = async (newHotkey: number, quickClassifierId: any) => {
      let parametersService = new QuickClassifierShortcutService();
      let expectedCodes = [200];
      let inputDTO = {
        webserviceClassifierUserId: quickClassifierId,
        hotkey: newHotkey,
        guidUser: TPGlobal.currentUserGuid,
      };

      try {
        let responseRequest =
          await parametersService.updateQuickClassifierShortcuthotkey(
            inputDTO,
            false,
            true,
            expectedCodes
          );
        if (responseRequest.responseData.responseCode !== 500) {
          reloadGridCommandShortcut();
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} updateHotkey ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} updateHotkey ex`);
      }
    };

    const deleteHotkey = async (quickClassifierId: any) => {
      let parametersService = new QuickClassifierShortcutService();
      let expectedCodes = [200];
      try {
        let responseRequest =
          await parametersService.deleteQuickClassifierShortcut(
            quickClassifierId,
            TPGlobal.currentUserGuid,
            false,
            true,
            expectedCodes
          );
        if (responseRequest.responseData.responseCode !== 500) {
          reloadGridCommandShortcut();
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} deleteHotkey ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} deleteHotkey ex`);
      }
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "id",
        "hotkey",
        "webserviceClassifierUserId",
        "localizedDescriptionWebServiceClassifier",
      ];
      let i: number;
      let search: string = adminState.searchPattern.trim();
      const gridData: Array<any> = adminState.gridData.filter((item) =>
        Boolean(item.baseLevelId === adminState.baseLevelId)
      );
      return gridData.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //Delete function after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let serviceClient = new QuickClassifierShortcutService();
      let expectedCodes: Array<number> = [200];
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          // delete quicks classifier shortcuts
          let responseRequest =
            await serviceClient.deleteQuickClassifierShortcut(
              callBackData.recordId,
              TPGlobal.currentUserGuid,
              false,
              true,
              expectedCodes
            );

          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Load Resources and fill Active Filter
    const loadResourcesAndQuickClassifierShortcutssFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm")
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );
      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
      );

      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );

      // save and cancel button
      setTitleInsertModal(
        await TPI18N.GetText(resourceSet, `TitleInsertModal`)
      );
      setTitleUpdateModal(
        await TPI18N.GetText(resourceSet, `TitleUpdateModal`)
      );
      setSaveButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton")
      );
      setCancelButtonLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );

      setOrganizationsRelationsLabel(
        await TPI18N.GetText(resourceSet, "OrganizationsRelationsLabel")
      );

      // load organization relation
      await getOrganizationsRelationsList();

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setHotkeyColumnLabel(
        await TPI18N.GetText(resourceSet, "HotkeyColumnLabel")
      );
      setDescriptionColumnLabel(
        await TPI18N.GetText(resourceSet, "DescriptionColumnLabel")
      );

      // Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    //get relations list bases on selected organization
    const getRelationsList = async (newOrganization: string) => {
      let serviceClient = new RelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        let responseRequest =
          await serviceClient.getRelationsByOrganizationAndFilter(
            newOrganization,
            TPActiveOptions.ALL.toString(),
            false,
            false,
            expectedCodes
          );

        let newRelationsListState: Array<TPKeyValue> = [];
        let relationsListData: Array<RelationsWithRestrictionsViewModel>;
        relationsListData = responseRequest;
        for (i = 0; i <= relationsListData.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: relationsListData[i].baseLevelId,
            value: relationsListData[i].localizedName
              ? relationsListData[i].localizedName
              : relationsListData[i].name,
          };
          newRelationsListState.push(keyvalue);
        }
        return newRelationsListState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRelationsList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getRelationsList ex`);
        return [];
      }
    };

    //get Base Level list
    const getOrganizationsRelationsList = async () => {
      let serviceClient = new OrganizationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      let j: number;
      setIsLoadingScreen(true);
      try {
        let responseRequest = await serviceClient.getOrganizationsByFilter(
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes
        );
        let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
        let organizationsListData: Array<OrganizationsViewModel>;
        organizationsListData = responseRequest;
        for (i = 0; i <= organizationsListData.length - 1; i++) {
          let relationsList: Array<TPKeyValue> = [];
          try {
            relationsList = await getRelationsList(organizationsListData[i].id);
          } catch (error) {}
          if (relationsList) {
            for (j = 0; j <= relationsList.length - 1; j++) {
              let descriptionOrganization: string;

              descriptionOrganization = organizationsListData[i].localizedName
                ? organizationsListData[i].localizedName
                : organizationsListData[i].name;
              let keyvalue: TPKeyValue = {
                key: relationsList[j].key,
                value: descriptionOrganization + " / " + relationsList[j].value,
              };
              newOrganizationsRelationsListState.push(keyvalue);
            }
          }
        }
        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
        newOrganizationsRelationsListState.unshift(intitialKeyValue);

        setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(
          `Error ${componentFileName} getOrganizationsRelationsList ex`
        );
        setIsLoadingScreen(false);
      }
    };

    const getTreeInfo = async (treeId: string) => {
      let serviceClient = new TreeService();
      let expectedCodes: Array<number> = [200];
      let treeData: TreeViewModel | null = null;
      try {
        let responseRequest = await serviceClient.getTreeById(
          treeId,
          false,
          true,
          expectedCodes
        );
        treeData = responseRequest;
        return treeData;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getTreeInfo ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getTreeInfo ex`);
        return null;
      }
    };

    //get base level info (organization and relation pair)
    const getBaseLevelInfo = async (BaseLevelId: string) => {
      let serviceClient = new OrganizationsRelationsService();
      let expectedCodes: Array<number> = [200];
      let baseLevelData: OrganizationsRelationsViewModel | null = null;
      try {
        let responseRequest = await serviceClient.getById(
          BaseLevelId,
          false,
          true,
          expectedCodes
        );

        baseLevelData = responseRequest;
        return baseLevelData;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getBaseLevelInfo ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getBaseLevelInfo ex`);
        return null;
      }
    };

    const getRecordLanguageList = async (
      pRecordId: String,
      multilanguageTableName: string,
      multilanguageFieldName: string
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
      let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
      let expectedCodes: Array<number> = [200];
      try {
        let responseRequest =
          await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
            multilanguageTableName,
            multilanguageFieldName,
            pRecordId,
            false,
            true,
            expectedCodes
          );

        let recordInfo: Array<MultilanguageFieldValueViewModel>;
        recordInfo = [...responseRequest];
        return recordInfo;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRecordLanguageList ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} getRecordLanguageList ex`);
        return [];
      }
    };

    const handleOrganizationRelationChange = async (e: any) => {
      let newBaseLevelId: string = e.target.value;

      let baseLevelData: OrganizationsRelationsViewModel | null = null;
      let treeData: TreeViewModel | null = null;
      let localizedField: Array<MultilanguageFieldValueViewModel> = [];
      let found: boolean;
      let newLabelTree1: string | null = "";
      let newLabelTree2: string | null = "";
      let newLabelTree3: string | null = "";
      let newLabelTree4: string | null = "";
      let newLabelTree5: string | null = "";

      if (!newBaseLevelId) {
        dispatchCommand({
          type: commandsEnum.organization_relation_clear,
          payload: null,
        });
        return;
      }
      let newRelationId: string = "";
      setIsLoadingScreen(true);

      baseLevelData = await getBaseLevelInfo(newBaseLevelId);

      if (baseLevelData === null) {
        return;
      }

      let newTreeIds: Array<string> = [];
      newTreeIds.push(baseLevelData.tree1Id);
      newTreeIds.push(baseLevelData.tree2Id);
      newTreeIds.push(baseLevelData.tree3Id);
      newTreeIds.push(baseLevelData.tree4Id);
      newTreeIds.push(baseLevelData.tree5Id);

      newRelationId = baseLevelData.relationId;

      if (newTreeIds[0] !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 1
        treeData = await getTreeInfo(newTreeIds[0]);
        if (treeData === null) {
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C1",
            "BAseLEvel",
            "Tree1_Classify1Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            return;
          }
          found = false;
          for (let i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree1 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (let i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree1 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
          }
        }
      }
      if (newTreeIds[1] !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 2
        treeData = await getTreeInfo(newTreeIds[1]);
        if (treeData === null) {
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C2",
            "BAseLEvel",
            "Tree2_Classify2Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            return;
          }
          found = false;
          for (let i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree2 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (let i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree2 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
          }
        }
      }
      if (newTreeIds[2] !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 3
        treeData = await getTreeInfo(newTreeIds[2]);
        if (treeData === null) {
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C3",
            "BAseLEvel",
            "Tree3_Classify3Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            return;
          }
          found = false;
          for (let i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree3 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (let i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree3 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
          }
        }
      }
      if (newTreeIds[3] !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 4
        treeData = await getTreeInfo(newTreeIds[3]);
        if (treeData === null) {
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C4",
            "BAseLEvel",
            "Tree4_Classify4Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            return;
          }
          found = false;
          for (let i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree4 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (let i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree4 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
          }
        }
      }
      if (newTreeIds[4] !== TPGlobal.Tree.NA_TreeCode) {
        //get tree Info 5
        treeData = await getTreeInfo(newTreeIds[4]);
        if (treeData === null) {
          return;
        } else {
          //get label from multilanguage field value
          localizedField = await getRecordLanguageList(
            newBaseLevelId + "_C5",
            "BAseLEvel",
            "Tree5_Classify5Caption"
          );
          if (localizedField === null || localizedField.length === 0) {
            return;
          }
          found = false;
          for (let i = 0; i <= localizedField.length - 1; i++) {
            if (localizedField[i].languageId === TPGlobal.language) {
              newLabelTree5 = localizedField[i].recordDescription;
              found = true;
              break;
            }
          }
          if (!found) {
            for (let i = 0; i <= localizedField.length - 1; i++) {
              if (
                localizedField[i].languageId ===
                TPGlobal.TPClientAvailableLanguages[0].id
              ) {
                newLabelTree5 = localizedField[i].recordDescription;
                found = true;
                break;
              }
            }
          }
        }
      }

      // TODO create resouces modal

      // if (newRelationId) {
      //   reloadGridCommand();
      // }

      dispatchCommand({
        type: commandsEnum.change_current_organization_relation,
        payload: {
          newBaseLevelId: newBaseLevelId,
          newLabelTree1: newLabelTree1,
          newLabelTree2: newLabelTree2,
          newLabelTree3: newLabelTree3,
          newLabelTree4: newLabelTree4,
          newLabelTree5: newLabelTree5,
        },
      });
      if (newBaseLevelId) {
        reloadGridData(newBaseLevelId);
      }
      setIsLoadingScreen(false);
    };

    const reloadGridData = async (baseLevelId: string) => {
      const data = await reloadDataGridShortcut(baseLevelId);
      dispatchCommand({
        type: commandsEnum.reload_grid,
        payload: data,
      });
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      let newScreenState: AdminStateType;
      newScreenState = { ...prevState };
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        case commandsEnum.change_current_organization_relation:
          newScreenState.baseLevelId = command.payload.newBaseLevelId;
          newScreenState.currentLabelTree1 = command.payload.newLabelTree1;
          newScreenState.currentLabelTree2 = command.payload.newLabelTree2;
          newScreenState.currentLabelTree3 = command.payload.newLabelTree3;
          newScreenState.currentLabelTree4 = command.payload.newLabelTree4;
          newScreenState.currentLabelTree5 = command.payload.newLabelTree5;
          newScreenState.gridData = [];
          newScreenState.gridColumns = [];
          newScreenState.baseLevelIdErrorMessage = "";

          return newScreenState;
        case commandsEnum.organization_relation_clear:
          newScreenState.baseLevelId = "";
          newScreenState.currentLabelTree1 = "";
          newScreenState.currentLabelTree2 = "";
          newScreenState.currentLabelTree3 = "";
          newScreenState.currentLabelTree4 = "";
          newScreenState.currentLabelTree5 = "";
          newScreenState.gridData = [];
          newScreenState.gridColumns = [];
          return newScreenState;

        case commandsEnum.set_errors:
          newScreenState = { ...prevState };
          newScreenState.baseLevelIdErrorMessage =
            command.payload.errors.baseLevelIdErrorMessage;
          return newScreenState;

        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndQuickClassifierShortcutssFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndQuickClassifierShortcutssFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndQuickClassifierShortcutssFilter ex`
          );
        });
    }, []);

    //Run when baseLevelId exist to get columns
    useEffect(() => {
      if (adminState.baseLevelId) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.baseLevelId]);

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <TPPageFirstRow>
                <TPPageActions>
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={handleModalHotkey}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() => {
                      reloadDataGrid();
                    }}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    type={TPButtonTypes.icon}
                    onClick={() =>
                      exportToCSV(filteredData(), "quick-classifier-admin-data")
                    }
                    text={exportLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                </TPPageActions>
                <TPFilterAndSearch style={{ width: "60%" }}>
                  <div className="d-flex justify-content-end ">
                    <TPSelect
                      id="IdSelect"
                      onChange={handleOrganizationRelationChange}
                      dataSource={organizationsRelationsKeyValue}
                      value={adminState.baseLevelId}
                      labelText={organizationsRelationsLabel}
                      isMandatory={true}
                      isHorizontal={true}
                      errorMessage={adminState.baseLevelIdErrorMessage}
                    ></TPSelect>
                  </div>

                  <TPPageSearchContainer>
                    <TPTextBox
                      id="IdTextBox"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>

          <TPModal modalState={modalHotkey}>
            {adminState.baseLevelId && (
              <QuickClassifierShortcutInsertUpdate
                hotkey={hotkey}
                quickClassifierId={quickClassifierId}
                currentMood={currentMood}
                ref={QCShortcutRef}
                currentBaseLevel={adminState.baseLevelId}
                organizationsRelationsKeyValue={organizationsRelationsKeyValue}
              />
            )}
          </TPModal>
        </TPLoadingOverlay>
      </>
    );
  }
);

export default QuickClassifierShortcutsAdmin;
