import { AdditionalFilter } from "@/helpers/TPKeyValue";
import DynamicTable, {
  ColumnStyles,
  HeaderAlignment,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import { ReactElement } from "react";

type SuccessfulTableTableProps = {
  additionalFilters?: AdditionalFilter[];
  callBackCommands: Function;
};

interface SuccessfulTableData {
  project: string;
  structure: string;
  uploadedBy: string;
  uploadedTime: string;
  resolved: number;
  pending: number;
  total: number;
  active: boolean;
}

function SuccessfulTable({
  additionalFilters,
  callBackCommands,
}: SuccessfulTableTableProps): ReactElement {
  const data: SuccessfulTableData[] = [
    {
      project: "StoryfAI",
      structure: "LOB-01",
      uploadedBy: "John Doe",
      uploadedTime: "09/09/2024",
      resolved: 2,
      pending: 5,
      total: 7,
      active: true,
    },
  ];

  const goToResolvedPage = (id: string) => {
    let command: any = {
      command: "update",
      recordId: id,
    };
    callBackCommands(command);
  };

  const customColumns: ColumnStyles<SuccessfulTableData> = {
    resolved: ({ value, item }) => (
      <TableChip
        value={true}
        onLabel={`${value}`}
        offLabel={`${value}`}
        justify="flex-start"
        onClick={() => goToResolvedPage(item.project)}
        underline
        notBoolean
      />
    ),
    pending: ({ value }) => (
      <TableChip
        value={false}
        onLabel={`${value}`}
        offLabel={`${value}`}
        justify="flex-start"
        underline
        notBoolean
      />
    ),
    active: ({ value }) => (
      <TableChip
        value={value}
        onLabel={"Yes"}
        offLabel={"No"}
        justify="flex-start"
      />
    ),
  };

  const tableAlignment: HeaderAlignment<SuccessfulTableData> = {
    resolved: "center",
    pending: "center",
  };

  return (
    <DynamicTable
      id="load-history"
      data={data}
      searchPosition="right"
      additionalFilters={additionalFilters}
      columnStyles={customColumns}
      headerAlignment={tableAlignment}
    />
  );
}

export default SuccessfulTable;
