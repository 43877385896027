import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { useEffect, useState } from "react";

import { ChromePicker } from "react-color";
import TPTextBox from "../bootstrap/forms/textbox/TPTextBox";
import { Cover, Popover, TPColorPickerContainer } from "./TPColorPickerStyles";

export const TPColorPicker = ({ labelText, value, onChange, hexMode }: any) => {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState<any>(
    hexMode
      ? {
          hex: value,
        }
      : value,
  );

  useEffect(() => {
    if (value && value.hex) {
      setSelectedColor(value);
    } else setSelectedColor({ hex: value });
  }, [value]);

  const handleColorChanged = (color: any) => {
    if (color && color.hex) {
      setSelectedColor(color);
      onChange && onChange(color);
    }
  };

  return (
    <TPColorPickerContainer>
      <TPTextBox
        id="IdTextBox"
        labelText={labelText}
        onClick={() => {
          const newStatus = !showPicker;
          setShowPicker(newStatus);
        }}
        value={selectedColor?.hex}
        onChange={(e: any) => {
          handleColorChanged({
            hex: e.target.value,
          });
        }}
        disabled={false}
        withIcon={true}
        isPrefixIcon={false}
        icon={TPIconTypes.custom}
        customIcon={
          <div>
            <div
              style={{
                border: "1px solid gray",
                height: "10px",
                width: "10px",
                backgroundColor: selectedColor?.hex,
                position: "relative",
              }}
              onClick={() => {
                const newStatus = !showPicker;
                setShowPicker(newStatus);
              }}
            ></div>
            <>
              {showPicker ? (
                <>
                  <Popover>
                    <Cover onClick={() => setShowPicker(false)}></Cover>
                    <ChromePicker
                      onChange={(color, event) => {
                        handleColorChanged(color);
                      }}
                      color={selectedColor}
                    />
                  </Popover>
                </>
              ) : null}
            </>
          </div>
        }
      />
    </TPColorPickerContainer>
  );
};
