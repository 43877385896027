import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { AdditionalFilter } from "@/helpers/TPKeyValue";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useImperativeHandle, useState } from "react";
import { StyledTab, StyledTabs } from "./load-history-styles";
import SuccessfulTable from "./SuccessfulTable";
import UploadProgressTable from "./UploadProgressTable";

interface LoadHistoryCallBacks {
  callBackCommands: Function;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`panel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const LoadHistoryAdmin = React.forwardRef(
  ({ callBackCommands }: LoadHistoryCallBacks, ref) => {
    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
    const [titleLabel, setTitleLabel] = useState<string>("Load History");
    const [projectLabel, setProjectLabel] = useState<string>("Project");
    const [structureLabel, setStructureLabel] = useState<string>("Structure");
    const [projectPlaceholder, setProjectPlaceholder] =
      useState<string>("Select project");
    const [structurePlaceholder, setStructurePlaceholder] =
      useState<string>("Select structure");

    const [value, setValue] = React.useState(0);

    const loadHistoryFilters: AdditionalFilter[] = [
      {
        key: "project",
        data: [
          {
            key: "01",
            value: "StoryfAI",
          },
        ],
        label: projectLabel,
        selectedValue: undefined,
        placeholder: projectPlaceholder,
        minWidth: 254,
        onChange: (event: React.ChangeEventHandler<HTMLSelectElement>) =>
          console.log("event: ", event),
      },
      {
        key: "structure",
        data: [
          {
            key: "01",
            value: "LOB-01",
          },
        ],
        label: structureLabel,
        selectedValue: undefined,
        placeholder: structurePlaceholder,
        minWidth: 254,
        onChange: (event: React.ChangeEventHandler<HTMLSelectElement>) =>
          console.log("event: ", event),
      },
    ];

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        console.log("refreshGridFromParent called");
      },
    }));

    return (
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col">
            <CIMTitleSection isCIM>
              <TPPageTitle style={{ margin: 0 }}>{titleLabel}</TPPageTitle>
            </CIMTitleSection>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="history load tabs"
                sx={StyledTabs}
              >
                <Tab
                  label="Successful upload"
                  id="success-tab"
                  sx={StyledTab}
                  disableRipple
                />
                <Tab
                  label="Upload in progress"
                  id="progress-tab"
                  sx={StyledTab}
                  disableRipple
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box sx={{ marginTop: "3em" }}>
                <SuccessfulTable
                  additionalFilters={loadHistoryFilters}
                  callBackCommands={callBackCommands}
                />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box sx={{ marginTop: "3em" }}>
                <UploadProgressTable
                  additionalFilters={loadHistoryFilters}
                  callBackCommands={callBackCommands}
                />
              </Box>
            </CustomTabPanel>
          </div>
        </div>
      </TPLoadingOverlay>
    );
  },
);

export default LoadHistoryAdmin;
