import { useState } from "react";
import ChildrenFolder from "./ImagesAdminChildrenFolder";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import { ImagesAdminPopupMenu } from "./ImagesAdminPopupMenu";

import {
  ImagesAdminFolderDivStyle,
  IcoFolderIStyle,
  MenuItemDivStyle,
  NameFolderPStyle,
  NivelDivStyle,
  NameFolderInputStyle,
  IcoMenuIStyle,
} from "./StyleImageAdmin";
import React from "react";
import { name } from "@azure/msal-browser/dist/packageMetadata";
import { DataType, OperationMode } from "./useStatusImagesAdmin";
import Select from "react-select/dist/declarations/src/Select";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";

type ContainerFolderProps = {
  folders: RecursiveRoutes;
};

const ParentFolder: React.FC<ContainerFolderProps> = ({ folders }) => {
  const componentFileName: string = "ParentFolder.tsx";
  const [activeAction, setActiveAction] = React.useState<Boolean>(false);
  const [editNameFolder, setEditNameFolder] = React.useState<string>("");

  const value = useAppConctextImageAdmin();
  const {
    operationMode,
    selectedItem,
    selectedFolderParent,
    handleLoading,
    handleMultiSelectedFileClear,
    handleInitializeFiles,
    handleSelected,
    handleUpdateFolder,
    handleNewFolder,
    handleDisableFolder,
  } = value;

  const handleActivate = () => {
    if (folders.isActive) {
      Cancel();
      handleLoading(true);
      setActiveAction(!activeAction);
      handleSelected(folders, OperationMode.SelectFolder);
    }
  };

  const handleActivateMenu = () => {
    if (folders.id != selectedItem.id) {
      Cancel();
    }

    handleSelected(folders, OperationMode.ActiveMenu);
  };

  const handleActiveEditNameFolder = () => {
    if (operationMode == OperationMode.ActiveMenu) {
      handleSelected(folders, OperationMode.EditFolder);
    } else {
      folders.folderName = editNameFolder;

      handleUpdateFolder(folders);
      handleSelected(selectedFolderParent, OperationMode.SelectFolder);
    }
  };

  const handleAddFolder = () => {
    handleNewFolder(folders);
  };

  const Cancel = () => {
    setActiveAction(false);
    handleSelected(new RecursiveRoutes(), OperationMode.Defaul);
    handleMultiSelectedFileClear();
  };

  const CancelActionMenu = () => {
    handleSelected(folders, OperationMode.SelectFolder);
  };

  const handleDisableFolderAction = () => {
    folders.isActive = !folders.isActive;
    handleDisableFolder(folders);
  };

  return (
    <>
      <ImagesAdminFolderDivStyle onContextMenu={handleActivateMenu}>
        <IcoFolderIStyle
          onClick={handleActivate}
          className={
            selectedItem.id === folders.id
              ? "bi bi-eye-fill"
              : activeAction
                ? "bi bi-chevron-down"
                : "bi-chevron-right"
          }
        ></IcoFolderIStyle>
        <IcoFolderIStyle
          onClick={handleActivate}
          color={!folders.isActive ? "red" : "Black"}
          className={
            folders.isActive
              ? folders.childrens && folders.childrens.length
                ? "bi bi-folder-fill"
                : "bi bi-folder"
              : "bi bi-lock-fill"
          }
        ></IcoFolderIStyle>

        {selectedItem.id != null &&
        selectedItem.id === folders.id &&
        operationMode == OperationMode.EditFolder ? (
          <NameFolderInputStyle
            type="text"
            placeholder={folders.folderName}
            onChange={(e) => {
              setEditNameFolder(e.target.value);
            }}
            width={"100%"}
          />
        ) : (
          <NameFolderPStyle onClick={handleActivate}>
            {" "}
            {folders.folderName}
          </NameFolderPStyle>
        )}

        {/*  <IcoFolderIStyle onClick={handleActivateMenu} className="bi-three-dots-vertical">
        </IcoFolderIStyle> */}

        {selectedItem.id != undefined &&
        selectedItem.id === folders.id &&
        (operationMode == OperationMode.ActiveMenu ||
          operationMode == OperationMode.EditFolder) ? (
          <ImagesAdminPopupMenu
            right={"-10px"}
            top="0px"
            Cancel={CancelActionMenu}
            handleAdd={handleAddFolder}
            handleActiveEditName={handleActiveEditNameFolder}
            handleRemove={handleDisableFolderAction}
            select={selectedItem}
            operationMode={operationMode}
            context={folders}
          ></ImagesAdminPopupMenu>
        ) : (
          ""
        )}
      </ImagesAdminFolderDivStyle>

      {activeAction
        ? folders.childrens.map((folder) => (
            <NivelDivStyle>
              <ChildrenFolder folders={folder} key={folder.id}></ChildrenFolder>
            </NivelDivStyle>
          ))
        : null}
    </>
  );
};

export default ParentFolder;
function useEffect(arg0: () => void, arg1: never[]) {
  throw new Error("Function not implemented.");
}

function setSelect(selectItem: any) {
  throw new Error("Function not implemented.");
}
