import { FC, ReactElement, useEffect, useState } from "react";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { OrganizationsService } from "@/services/OrganizationsService";
import {
  SequeceGeneratorSequencesNameEnum,
  SystemParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { OrganizationsViewModel } from "@/models/Organizations/OrganizationsModels";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { RelationsService } from "@/services/RelationsService";
import { RelationsViewModel } from "@/models/Relations/RelationsModels";
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";

import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  WorkflowTypeInputDTO,
  WorkflowTypeInputDTOValidator,
} from "@/models/Workflow/WorkflowTypeInputDTO";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import { ParametersService } from "@/services/ParametersService";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { SequenceService } from "@/services/SequenceService";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  callBackResult: Function;
  onDescriptionFetched: Function;
};

type InsertUpdateStateType = {
  id: string;
  recordLanguageList: Array<TPKeyValue>;
  promiseLimit: string;
  isDirectSolution: boolean;
  isActive: boolean;
  //validator
  idErrorMessage: string;
  promiseLimitErrorMessage: string;
  promiseLimitUnitErrorMessage: string;
  descriptionErrorMessages: Array<string>;
};

enum commandsEnum {
  "create_home" = 0,
  "new_vertical_tab" = 1,
  "update_vertical_tab" = 2,
  "vertical_tab_click" = 3,
  "vertical_tab_close" = 4,
  "insertupdate_cancel_or_ok" = 5,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const WorkflowTypeInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  onDescriptionFetched,
}): ReactElement => {
  //#region  Init
  const componentFileName: string = "WorkflowTypeInsertUpdate.tsx";
  //screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  //Screen resources
  const resourceSet: string = "WorkflowTypeInsertUpdateComponent";
  const [titleLabel, setTitleLabel] = useState("");
  const [subTitleLabel, setSubTitleLabel] = useState("");
  const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
  const [parametersSectionLabel, setParametersSectionLabel] = useState("");
  const [idLabel, setIdLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [promiseLimitLabel, setPromiseLimitLabel] = useState("");
  const [promiseLimitUnitLabel, sePromiseLimitUnitLabel] = useState("");
  const [isDirectsolutionLabel, setIsDirectSolutionLabel] = useState("");
  const [isActiveLabel, setIsActiveLabel] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState("");
  const [cancelButtonLabel, setCancelButtonLabel] = useState("");
  const [languageListLabel, setLanguageListLabel] = useState("");
  const [originalRecordDescription, setOriginalRecordDescription] =
    useState("");
  const [realMode, setRealMode] = useState(mode);
  const [realRecordId, setRealRecordId] = useState(recordId);
  const [referenceId, setReferenceId] = useState("");

  //Select Organization
  const [organizationsList, setOrganizationsListState] = useState<
    Array<TPKeyValue>
  >([]);
  const [selectedOrganization, setSelectedOrganization] = useState("");

  //Select Relation
  const [relationsList, setRelationsListState] = useState<Array<TPKeyValue>>(
    [],
  );
  const [selectedRelation, setSelectedRelation] = useState("");

  //Select Time unit list
  const [promiseLimitUnitList, setPromiseLimitUnitListState] = useState<
    Array<TPKeyValue>
  >([]);
  const [selectedPromiseLimitUnit, setSelectedPromiseLimitUnit] = useState("");

  //Screen state
  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }
  let insertUpdateInitialState: InsertUpdateStateType = {
    id: recordId,
    promiseLimit: "",
    isDirectSolution: false,
    isActive: true,
    recordLanguageList: [],
    idErrorMessage: "",
    descriptionErrorMessages: [],
    promiseLimitUnitErrorMessage: "",
    promiseLimitErrorMessage: "",
  };

  //current mode
  const [currentMode, setCurrentMode] = useState(mode);

  const [insertUpdateState, setInsertUpdateState] = useState(
    insertUpdateInitialState,
  );

  //Multilanguage const
  const multilanguageTableName: String = "WOrkflowTYpe";
  const multilanguageFieldName: String = "Description_WOTY";
  //#endregion

  const loadResourcesAndLoadInfo = async () => {
    let i: number;
    //resources state
    setTitleLabel(
      await TPI18N.GetText(resourceSet, "Title" + currentMode + "Label"),
    );

    setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
    setDescriptionSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DescriptionSectionLabel",
      ),
    );
    setParametersSectionLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ParametersSectionLabel",
      ),
    );

    setSaveButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
    );
    setCancelButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
    );
    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );
    setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
    setDescriptionLabel(await TPI18N.GetText(resourceSet, "DescriptionLabel"));
    setPromiseLimitLabel(
      await TPI18N.GetText(resourceSet, "PromiseLimitLabel"),
    );
    sePromiseLimitUnitLabel(
      await TPI18N.GetText(resourceSet, "PromiseLimitUnitLabel"),
    );

    setIsDirectSolutionLabel(
      await TPI18N.GetText(resourceSet, "IsDirectSolutionLabel"),
    );
    setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));

    //Load PromiseLimit Units
    await getTermUnitList();
    //screen state
    if (realMode == "Update") {
      await getById(realRecordId);
    }
    if (realMode == "Insert") {
      let newInsertUpdateState = { ...insertUpdateState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }
  };

  const getById = async (pRecordId: string) => {
    let serviceClient = new WorkflowTypeService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.getById(
        pRecordId,
        false,
        true,
        expectedCodes,
      );

      let recordInfo: WorkflowTypeViewModel;
      recordInfo = { ...responseRequest };

      setOriginalRecordDescription(recordInfo.description);
      onDescriptionFetched(recordInfo.description);

      let newInsertUpdateState = { ...insertUpdateState };
      newInsertUpdateState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setSelectedOrganization(recordInfo.organizationId);
      setSelectedRelation(recordInfo.relationId);
      newInsertUpdateState.promiseLimit =
        recordInfo.promiseLimit == null
          ? ""
          : recordInfo.promiseLimit.toString();
      setSelectedPromiseLimitUnit(recordInfo.promiseLimitUnit);
      newInsertUpdateState.isDirectSolution = recordInfo.isDirectSolution;
      newInsertUpdateState.isActive = recordInfo.isActive;
      newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }
      for (
        i = 0;
        i <= newInsertUpdateState.recordLanguageList.length - 1;
        i++
      ) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newInsertUpdateState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      setInsertUpdateState(newInsertUpdateState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getFunctionById ex`);
      setIsLoadingScreen(false);
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageFieldName,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfo: Array<MultilanguageFieldValueViewModel>;
      recordInfo = [...responseRequest];
      return recordInfo;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const getTermUnitList = async () => {
    let parametersService = new ParametersService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await parametersService.getByParentIdAndFilterIsActive(
          SystemParametersEnum.TEUNI,
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes,
        );

      let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return {
            key: item.id,
            value: item.localizedDescription,
          };
        },
      );
      newTypeKeyValueList.unshift({ key: "", value: "--" });
      setPromiseLimitUnitListState(newTypeKeyValueList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTermUnitList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTermUnitList ex`);
      return;
    }
  };

  const handleOnIdChange = (newId: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.id = newId;
    newInsertUpdateState.idErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleOrganizationChange = (e: any, item: TPKeyValue) => {
    setSelectedOrganization(e.target.value);
  };

  const handleRelationChange = (e: any, item: TPKeyValue) => {
    setSelectedRelation(e.target.value);
  };
  const handlePromiseLimitChange = (promiseLimit: string) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.promiseLimit = promiseLimit;
    newInsertUpdateState.promiseLimitErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
  };

  const handlePromiseLimitUnitChange = (e: any, item: TPKeyValue) => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.promiseLimitErrorMessage = "";
    newInsertUpdateState.promiseLimitUnitErrorMessage = "";
    setInsertUpdateState(newInsertUpdateState);
    setSelectedPromiseLimitUnit(e.target.value);
  };

  const handleIsDirectSolutionChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isDirectSolution = !insertUpdateState.isDirectSolution;
    setInsertUpdateState(newInsertUpdateState);
  };
  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...insertUpdateState };
    newInsertUpdateState.isActive = !insertUpdateState.isActive;
    setInsertUpdateState(newInsertUpdateState);
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: recordId });
  };

  const handleOkButtonClick = async () => {
    let isInValid: boolean = false;
    let i: number;
    let n: number;

    let recordInputDTO: WorkflowTypeInputDTO = {
      // id: insertUpdateState.id,
      id: realMode === "Insert" ? referenceId : recordId,
      promiseLimit: +insertUpdateState.promiseLimit ?? null,
      promiseLimitUnit: selectedPromiseLimitUnit
        ? selectedPromiseLimitUnit
        : null,
      isDirectSolution: insertUpdateState.isDirectSolution,
      isActive: insertUpdateState.isActive,
      otherLocalizedValues: [],
      description: insertUpdateState.recordLanguageList[0].value,
    };
    n = insertUpdateState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = insertUpdateState.recordLanguageList[i];
      recordInputDTO.otherLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }

    let inputDTOValidator = new WorkflowTypeInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...insertUpdateState };
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      if (resultValidator.description) {
        newInsertUpdateState.descriptionErrorMessages[0] =
          await TPI18N.GetResource(resultValidator.description);
      } else {
        newInsertUpdateState.descriptionErrorMessages[0] = "";
      }

      if (resultValidator.promiseLimitUnit) {
        newInsertUpdateState.promiseLimitUnitErrorMessage =
          await TPI18N.GetResource(resultValidator.promiseLimitUnit);
      } else {
        newInsertUpdateState.promiseLimitUnitErrorMessage = "";
      }
      if (resultValidator.promiseLimit) {
        newInsertUpdateState.promiseLimitErrorMessage =
          await TPI18N.GetResource(resultValidator.promiseLimit);
      } else {
        newInsertUpdateState.promiseLimitErrorMessage = "";
      }

      if (resultValidator.otherLocalizedValues) {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.otherLocalizedValues[i] != null) {
            newInsertUpdateState.descriptionErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.otherLocalizedValues[i]),
              );
          }
        }
      } else {
        n = insertUpdateState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.descriptionErrorMessages[i] = "";
        }
      }

      setInsertUpdateState(newInsertUpdateState);
      isInValid = true;
    }

    if (!recordInputDTO.promiseLimitUnit) {
      recordInputDTO.promiseLimit = null;
    }

    if (!isInValid) {
      if (realMode == "Insert") {
        await insert(recordInputDTO);
      } else {
        await update(recordInputDTO);
      }
    }
  };

  const insert = async (inputDTO: WorkflowTypeInputDTO) => {
    let serviceClient = new WorkflowTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.insert(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({
          result: "save_general_insert_and_change_mode",
          recordId: inputDTO.id,
        });
        //change mode
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleUpdateLabel"));
        setRealRecordId(inputDTO.id);
        setOriginalRecordDescription(
          inputDTO.otherLocalizedValues[0].localizedValue.length > 100
            ? inputDTO.otherLocalizedValues[0].localizedValue.substring(
                0,
                100,
              ) + "..."
            : inputDTO.otherLocalizedValues[0].localizedValue,
        );
        onDescriptionFetched(inputDTO.description);
        setRealMode("Update");
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} insert ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} insert ex`);
      setIsLoadingScreen(false);
    }
  };

  const update = async (inputDTO: WorkflowTypeInputDTO) => {
    let serviceClient = new WorkflowTypeService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.update(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({ result: "OK", recordId: recordId });
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} update ex`);
      setIsLoadingScreen(false);
    }
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequeceGeneratorSequencesNameEnum.SQWOTY,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  //Only once to set resources
  useEffect(() => {
    loadResourcesAndLoadInfo();
    realMode === "Insert" && generalAutomaticId();
  }, []);
  return (
    //#region  Render
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-10">
            <TPPageTitle>{titleLabel}</TPPageTitle>
            {realMode == "Update" ? (
              <TPPageSubTitle>
                {`${subTitleLabel} ${recordId} / ${originalRecordDescription}`}
              </TPPageSubTitle>
            ) : null}
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPTextBox
                      id="IdTextBox"
                      labelText={idLabel}
                      isMandatory={true}
                      // value={insertUpdateState.id}
                      value={realMode === "Insert" ? referenceId : recordId}
                      onChange={
                        realMode === "Insert"
                          ? (e: any) => handleOnIdChange(e.target.value)
                          : () => {
                              TPGlobal.foo();
                            }
                      }
                      maxLength={20}
                      // disabled={currentMode !== "Insert"}
                      disabled
                      errorMessage={insertUpdateState.idErrorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {insertUpdateState.recordLanguageList.length > 0 &&
                  TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                      index === 0 && (
                        <div className="col-6" key={`languageItem-${item.id}`}>
                          <div className="form-group">
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={index === 0}
                              labelText={`${descriptionLabel} (${item.name})`}
                              value={
                                insertUpdateState.recordLanguageList[index]
                                  .value
                              }
                              onChange={(e: any) =>
                                handleLanguageChange(index, e.target.value)
                              }
                              maxLength={100}
                              errorMessage={
                                insertUpdateState.descriptionErrorMessages[
                                  index
                                ]
                              }
                            />
                          </div>
                        </div>
                      ),
                  )}
                {insertUpdateState.recordLanguageList.length > 1 && (
                  <>
                    <div className="col-4">
                      <div className="pt-4">
                        <TPButton
                          id="IdButton"
                          type={TPButtonTypes.icon}
                          icon={TPIconTypes.language}
                          text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                          tooltip={languageListLabel}
                          className={"pt-3"}
                          onClick={handleOpenModalLanguageList}
                        />
                      </div>
                    </div>
                    <TPModalLanguageList
                      id="IdModalLanguageList"
                      isOpen={isOpenModalLanguageList}
                      title={languageListLabel}
                      acceptLabel={saveButtonLabel}
                      cancelLabel={cancelButtonLabel}
                      saveChanges={handleSaveChangesModalLanguageList}
                      closeModal={handleCloseModalLanguageList}
                    >
                      <div
                        className="row overflow-auto"
                        style={{ height: "200px" }}
                      >
                        {TPGlobal.TPClientAvailableLanguages.map(
                          (item, index) =>
                            index > 0 && (
                              <div
                                className="col-12"
                                key={`languageItem-${item.id}`}
                              >
                                <div className="form-group">
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={index === 0}
                                    labelText={`${descriptionLabel} (${item.name})`}
                                    value={
                                      insertUpdateState.recordLanguageList[
                                        index
                                      ].value
                                    }
                                    onChange={(e: any) =>
                                      handleLanguageChange(
                                        index,
                                        e.target.value,
                                      )
                                    }
                                    maxLength={100}
                                    errorMessage={
                                      insertUpdateState
                                        .descriptionErrorMessages[index]
                                    }
                                  />
                                </div>
                              </div>
                            ),
                        )}
                      </div>
                    </TPModalLanguageList>
                  </>
                )}
              </div>
            </TPPageSection>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <TPPageSection>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPNumeric
                      id="IdTPNumeric"
                      onChange={(value: string) => {
                        handlePromiseLimitChange(value);
                      }}
                      value={insertUpdateState.promiseLimit}
                      labelText={promiseLimitLabel}
                      errorMessage={insertUpdateState.promiseLimitErrorMessage}
                    ></TPNumeric>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPSelect
                      id="IdSelect"
                      onChange={handlePromiseLimitUnitChange}
                      dataSource={promiseLimitUnitList}
                      value={selectedPromiseLimitUnit}
                      labelText={promiseLimitUnitLabel}
                      errorMessage={
                        insertUpdateState.promiseLimitUnitErrorMessage
                      }
                    ></TPSelect>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      labelText={isDirectsolutionLabel}
                      checked={insertUpdateState.isDirectSolution}
                      onChange={(e: any) => handleIsDirectSolutionChange()}
                    ></TPCheckBox>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <TPCheckBox
                      id="IdCheckBox"
                      labelText={isActiveLabel}
                      checked={insertUpdateState.isActive}
                      onChange={(e: any) => handleIsActiveChange()}
                    ></TPCheckBox>
                  </div>
                </div>
              </div>
            </TPPageSection>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-10">
            <TPPageAcceptCancelButtonsContainer>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={handleOkButtonClick}
              >
                {saveButtonLabel}
              </TPButton>
              <TPButton
                id="IdButton"
                type={TPButtonTypes.link}
                onClick={handleCancelButtonClick}
                className={"ms-2"}
              >
                {cancelButtonLabel}
              </TPButton>
            </TPPageAcceptCancelButtonsContainer>
          </div>
        </div>
      </TPLoadingOverlay>
    </ContentVerticalTabInsertUpdateStyled>

    //#endregion
  );
};

export default WorkflowTypeInsertUpdate;
