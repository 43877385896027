const dataToCSV = function (data: any[], ignore?: string[]): string {
  let headers = Object.keys(data[0]);
  headers = headers.filter((header) => !ignore?.includes(header));
  console.log(headers);

  const rows = data.map((item) =>
    headers
      .map((header) =>
        JSON.stringify(item[header], (_key, value) =>
          typeof value === "string" ? value.replace(/"/g, '""') : value
        )
      )
      .join(",")
  );

  return [headers.join(","), ...rows].join("\n");
};

export const exportToCSV = function (
  data: any[],
  filename?: string,
  ignore?: string[]
) {
  const csvFile = new Blob([dataToCSV(data, ignore)], { type: "text/csv" });
  const downloadLink = document.createElement("a");

  downloadLink.download = filename || "export.csv";
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
