import TPCheckBox from '@/components/bootstrap/forms/checkbox/TPCheckBox';
import TPSelect from '@/components/bootstrap/forms/select/TPSelect';
import TPGlobal from '@/helpers/TPGlobal';
import { AdditionalFilter } from '@/helpers/TPKeyValue';
import { TPIconTypes } from '@/models/Global/TPGlobalEnums';
import { TPI18N } from '@/services/I18nService';
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, {forwardRef, ReactElement, ReactNode, useEffect, useImperativeHandle, useRef, useState} from 'react';
import TableActionItem, {MinorOption} from "@/modules/core/components/dynamic-table/TableActionItem";
import TableIcons, {TableIcon} from "@/modules/core/components/dynamic-table/TableIcons";
import SwitchTableView, {TableViews} from "@/modules/core/components/dynamic-table/SwitchTableView";
import {
	StyledDynamicCards,
	StyledDynamicTable, StyledHeaderOptions, StyledTable,
	StyledTableCell, StyledTableChildren, StyledTableHeader,
	StyledTableRow
} from "@/modules/core/components/dynamic-table/dynamic-table-styles";
import SortTableItem from "@/modules/core/components/dynamic-table/SortTableItem";
import {camelCaseToPhrase} from "@/modules/core/utils/text-regex";
import SearchInput from "@/modules/core/design-system/inputs/SearchInput";
import TablePreferences from "@/modules/core/components/dynamic-table/TablePreferences";
import TableNotFound from "@/modules/core/components/dynamic-table/TableNotFound";
import TablePager from "@/modules/core/components/dynamic-table/TablePager";
import ProjectDetailTableIcons from "@/pages/Projects/ProjectDetail/ProjectDetailTableIcons";


type ColumnComponentProps<T> = {
	value: any;
	item: T;
};

export type CustomColumnNames<T> = {
	[K in keyof T]?: string;
};

export type CardComponentProps<T> = {
	item: T;
};

export type ColumnStyles<T> = Partial<Record<keyof T, React.ComponentType<ColumnComponentProps<T>>>>;

type TableProps<T extends object> = {
	data: T[];
	id?: string;
	columnNames?: CustomColumnNames<T>;
	columnStyles?: ColumnStyles<T>;
	minorOptions?: MinorOption<T>[];
	CustomCard?: React.ComponentType<CardComponentProps<T>>;
	icons?: TableIcon[];
	additionalFilters?: AdditionalFilter[];
	children?: ReactNode;
	selectable?: boolean;
	switchable?: boolean;
	withPreferences?: boolean;
	noDataMessage?: string;
	customHeight?: string;
	pagerStyle?: 'classic' | 'default';
	onIconClicked?: (iconType: TPIconTypes) => void;
	onSelectionChange?: (selectedItems: T[]) => void;
	onSwitchChange?: (tableView: TableViews) => void;
};

export type Short = 'ascending' | 'descending';

export interface ProjectDetailDynamicTableEvents {
	inputSearch: (value: string) => void;
	downloadIcon: () => void;
}

const ProjectDetailDynamicTable = forwardRef(({
									 data,
									 id,
									 columnNames,
									 columnStyles,
									 minorOptions,
									 CustomCard,
									 icons,
									 additionalFilters,
									 children,
									 selectable,
									 switchable,
									 withPreferences,
									 noDataMessage,
									 pagerStyle,
									 customHeight,
									 onIconClicked,
									 onSelectionChange,
									 onSwitchChange
								 }: TableProps<any>, ref) => {
	const [downloadClics, setDownloadClics] = useState<number>(-1);
	const [sortConfig, setSortConfig] = useState<{ key: keyof any; direction: Short } | null>(null);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [visibleColumns, setVisibleColumns] = useState<(keyof any)[]>(
		Object.keys(data.length > 0 ? data[0] : []) as (keyof any)[]
	);
	const [lastColumn, setLastColumn] = useState<keyof any | null>(null);
	const [tableView, setTableView] = useState<TableViews>(TableViews.TABLE);

	const [minorOptionsLabel, setMinorOptionsLabel] = useState<string>('');
	const [searchPlaceholder, setSearchPlaceholder] = useState<string>('');
	const [preferencesPlaceholder, setPreferencesPlaceholder] = useState<string>('');
	const [preferencesButtonLabel, setPreferencesButtonLabel] = useState<string>('');
	const [ofLabel, setOfLabel] = useState<string>('');
	const [allLabel, setAllLabel] = useState<string>('');
	const [pageLabel, setPageLabel] = useState<string>('');
	const [nextPageLabel, setNextPageLabel] = useState<string>('');
	const [exportTableLabel, setExportTableLabel] = useState<string>('');
	const [refreshTableLabel, setRefreshTableLabel] = useState<string>('');
	const [noResultsLabel, setNoResultsLabel] = useState<string>('');
	const [newOneLabel, setNewOneLabel] = useState<string>('');

	useImperativeHandle(ref, () => ({
		inputSearch(value: string) {
			handleSearch(value)
		},
		downloadIcon() {
			setDownloadClics(downloadClics + 1)
		},
	} as ProjectDetailDynamicTableEvents));

	const handleSearch = (value: string) => {
		setSearchTerm(value);
		setCurrentPage(1);
	};

	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		setRowsPerPage(parseInt(value));
		setCurrentPage(1);
	};

	const filteredData = React.useMemo(() => {
		if (!searchTerm) return data;

		return data.filter(item =>
			Object.values(item).some(val => String(val).toLowerCase().includes(searchTerm.toLocaleLowerCase()))
		);
	}, [data, searchTerm]);

	const sortedData = React.useMemo(() => {
		if (sortConfig !== null) {
			return [...filteredData].sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return filteredData;
	}, [filteredData, sortConfig]);

	const requestSort = (key: keyof any) => {
		let direction: Short = 'ascending';
		if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};

	const getSortBy = (key: string): Short | null => {
		return sortConfig?.key === key ? sortConfig.direction : null;
	};

	const totalPages = Math.ceil(sortedData.length / rowsPerPage);

	const paginatedData = React.useMemo(() => {
		if (rowsPerPage === -1) return sortedData;
		const start = (currentPage - 1) * rowsPerPage;
		return sortedData.slice(start, start + rowsPerPage);
	}, [sortedData, currentPage, rowsPerPage]);

	const handleCheckboxChange = (item: any) => {
		const isSelected = selectedItems.includes(item);
		const newSelectedItems = isSelected
			? selectedItems.filter(selectedItem => selectedItem !== item)
			: [...selectedItems, item];

		setSelectedItems(newSelectedItems);

		if (onSelectionChange) {
			onSelectionChange(newSelectedItems);
		}
	};

	const handleSelectAllChange = () => {
		const allSelected = selectedItems.length === filteredData.length;
		const newSelectedItems = allSelected ? [] : filteredData;
		setSelectedItems(newSelectedItems);

		if (onSelectionChange) {
			onSelectionChange(newSelectedItems);
		}
	};

	const handleColumnVisibilityChange = (column: keyof any) => {
		setVisibleColumns(prev => {
			if (prev.includes(column)) {
				let columnsFiltered = prev.filter(col => col !== column);
				columnsFiltered.length === 1 && setLastColumn(columnsFiltered[0]);
				return columnsFiltered;
			} else {
				// Insert the column back to its original position
				const allColumns = Object.keys(data[0]) as (keyof any)[];
				const updatedColumns = [...prev];
				const originalIndex = allColumns.indexOf(column);
				updatedColumns.splice(originalIndex, 0, column);
				updatedColumns.length === 1 && setLastColumn(updatedColumns[0]);
				return updatedColumns;
			}
		});
	};

	const resetColumns = () => {
		setVisibleColumns(Object.keys(data[0]) as (keyof any)[]);
	};

	const handleViewChange = (view: TableViews) => {
		if (onSwitchChange) onSwitchChange(view);
		setTableView(view);
	};

	const isCustomColumnsEnabled = (key: any): boolean => {
		return (columnNames && columnNames[key] !== undefined) || false;
	};

	const columnsManagement = (): ReactElement => {
		return (
			<>
				{minorOptions && minorOptions.length > 0 && (
					<StyledTableCell align="center">{minorOptionsLabel}</StyledTableCell>
				)}
				{selectable && (
					<StyledTableCell align="center">
						<TPCheckBox
							id={`${id}-head-check`}
							checked={selectedItems.length === filteredData.length && filteredData.length > 0}
							onChange={handleSelectAllChange}
						/>
					</StyledTableCell>
				)}
				{visibleColumns.map(key => (
					<StyledTableCell key={String(key)}>
						<SortTableItem
							isCustomColumns={isCustomColumnsEnabled(key)}
							itemName={isCustomColumnsEnabled(key) ? (columnNames![key as any] ?? '') : String(key)}
							direction={getSortBy(String(key))}
							handleClick={() => requestSort(key as keyof any)}
						/>
					</StyledTableCell>
				))}
			</>
		);
	};

	const rowsManagement = (): ReactElement => {
		return (
			<>
				{paginatedData.map((item, index) => (
					<StyledTableRow key={index}>
						{minorOptions && minorOptions.length > 0 && (
							<TableActionItem key={`${index}-option`} index={index} item={item} minorOptions={minorOptions} />
						)}
						{selectable && (
							<StyledTableCell key={`${index}-checks`} align="center">
								<TPCheckBox
									id={`${id}-${index}-check`}
									checked={selectedItems.includes(item)}
									onChange={() => handleCheckboxChange(item)}
								/>
							</StyledTableCell>
						)}
						{visibleColumns.map(key => (
							<StyledTableCell key={String(key)}>
								{columnStyles?.[key]
									? React.createElement(columnStyles[key]!, { value: item[key], item })
									: String(item[key])}
							</StyledTableCell>
						))}
					</StyledTableRow>
				))}
			</>
		);
	};

	const cardManagement = (): ReactElement => {
		return (
			<>
				{paginatedData.map((item, index) => (
					<React.Fragment key={index}>
						{CustomCard ? (
							React.createElement(CustomCard, { item })
						) : (
							<div className="default-card">
								{visibleColumns.map(key => (
									<div className="default-card-item" key={String(key)}>
										<strong>{camelCaseToPhrase(String(key))}:</strong>
										{columnStyles?.[key] ? (
											React.createElement(columnStyles[key]!, { value: item[key], item })
										) : (
											<p>{String(item[key])}</p>
										)}
									</div>
								))}
							</div>
						)}
					</React.Fragment>
				))}
			</>
		);
	};

	const loadUtilsResources = async () => {
		setMinorOptionsLabel(await TPI18N.GetText('FormDesignerComponent', 'ActionColumnLabel'));
		setPreferencesPlaceholder(await TPI18N.GetText('FormDesignerComponent', 'PreferencesLabel'));
		setPreferencesButtonLabel(await TPI18N.GetText('FormDesignerComponent', 'ResetToDefault'));
		setSearchPlaceholder(await TPI18N.GetText(TPGlobal.globalResourceSet, 'Search'));
		setOfLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'DataTableOfCounter'));
		setAllLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'IsActiveAll'));
		setPageLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'DataTablePageCounter'));
		setNextPageLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'DataTableNextPageCounter'));
		setExportTableLabel(await TPI18N.GetText('DynamicTable', 'Export'));
		setRefreshTableLabel(await TPI18N.GetText('DynamicTable', 'Refresh'));
		setNoResultsLabel(await TPI18N.GetText('DynamicTable', 'NoResultsFound'));
		setNewOneLabel(await TPI18N.GetText('DynamicTable', 'NewOne'));
	};

	useEffect(() => {
		if (data.length > 0) {
			resetColumns();
		}
	}, [data]);

	useEffect(() => {
		loadUtilsResources().then();
	}, []);

	return (
		<>
			{data.length > 0 ? (
				<StyledDynamicTable>
					<StyledTableHeader>
						<StyledHeaderOptions>
							{switchable && (
								<SwitchTableView id={id} active={tableView} handleViewChanged={(i: any) => handleViewChange(i)} />
							)}
							<SearchInput id={id} onChange={handleSearch} placeholder={searchPlaceholder} />
						</StyledHeaderOptions>
						<StyledHeaderOptions>
							<ProjectDetailTableIcons
								downloadClics={downloadClics}
								id={id}
								icons={icons}
								exportData={filteredData}
								visibleColumns={visibleColumns}
								columnNames={columnNames}
								tableView={tableView}
								exportLabel={exportTableLabel}
								refreshLabel={refreshTableLabel}
								onIconClick={onIconClicked}
							/>
							{additionalFilters && additionalFilters.length > 0 && (
								<>
									{additionalFilters.map(filter => (
										<TPSelect
											key={filter.key}
											id={`${id && `${id}-`}${filter.key}-select`}
											onChange={filter.onChange}
											dataSource={filter.data}
											value={filter.selectedValue}
											labelText={filter.label}
											isHorizontal={false}
											isDynamic={true}
										/>
									))}
								</>
							)}
							{withPreferences && tableView === TableViews.TABLE && (
								<TablePreferences
									id={id}
									tableData={data}
									visibleColumns={visibleColumns}
									columnNames={columnNames}
									placeholder={preferencesPlaceholder}
									buttonValue={preferencesButtonLabel}
									lastColumn={lastColumn}
									resetColumns={resetColumns}
									handleColumnVisibilityChange={handleColumnVisibilityChange}
								/>
							)}
						</StyledHeaderOptions>
					</StyledTableHeader>
					{<StyledTableChildren>{children}</StyledTableChildren>}
					{filteredData.length === 0 ? (
						<TableNotFound newOneLabel={newOneLabel} noResultsLabel={noResultsLabel} isSearchData={true} />
					) : (
						<>
							{tableView === TableViews.TABLE ? (
								<StyledTable height={customHeight}>
									<Table stickyHeader aria-label="sticky table" id={`${id ? id : 'dynamic'}-table`}>
										<TableHead>
											<TableRow>{columnsManagement()}</TableRow>
										</TableHead>
										<TableBody>{rowsManagement()}</TableBody>
									</Table>
								</StyledTable>
							) : (
								<StyledDynamicCards>{cardManagement()}</StyledDynamicCards>
							)}
							<TablePager
								id={id}
								page={currentPage}
								totalPages={totalPages}
								rowsPerPage={rowsPerPage}
								resultsPerPage={paginatedData.length}
								allResults={filteredData.length}
								selectedResults={selectedItems.length}
								ofLabel={ofLabel}
								allLabel={allLabel}
								pageLabel={pageLabel}
								nextPageLabel={nextPageLabel}
								pagerStyle={pagerStyle}
								onPagerChange={setCurrentPage}
								onRowsPerPageChange={handleRowsPerPageChange}
							/>
						</>
					)}
				</StyledDynamicTable>
			) : (
				<TableNotFound newOneLabel={newOneLabel} noResultsLabel={noResultsLabel} text={noDataMessage} />
			)}
		</>
	);
})

export default ProjectDetailDynamicTable;
