import { ReactNode, createContext, useContext, useEffect } from "react";
import { useStatusImagesAdmin } from "./useStatusImagesAdmin";
import { EventHandler } from "@azure/msal-browser/dist/internals";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

type AppProviderImagesAdminProps = {
  children: ReactNode;
};

class model {
  status: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
  listFilesModel: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
  listFolderModel: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
  listMultiFileSelected: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
  selectedItem: RecursiveRoutes = new RecursiveRoutes();
  selectedFolderParent: RecursiveRoutes = new RecursiveRoutes();
  optionList: Array<TPKeyValue> = new Array<TPKeyValue>();
  optionStatusList: Array<TPKeyValue> = new Array<TPKeyValue>();
  sortAscendingOrDescending: boolean = false;
  viewListAndGrid: boolean = false;
  orderBy: string = "Name";
  filterStatus: string = "All";
  operationMode: string = "";
  isLoading: boolean = false;
  handleMultiSelectedFile: (data: RecursiveRoutes) => void = (
    data: RecursiveRoutes,
  ) => void {};
  handleMultiSelectedFileClear: () => void = () => void {};
  handleAllSelectedFile: () => void = () => void {};
  handleSelected: (data: RecursiveRoutes, mode: string) => void = (
    data: RecursiveRoutes,
    mode: string,
  ) => void {};
  handleNewFolder: (data: RecursiveRoutes) => void = (data: RecursiveRoutes) =>
    void {};
  handleUpdateFolder: (data: RecursiveRoutes) => void = (
    data: RecursiveRoutes,
  ) => void {};
  handleInitializeFolders: () => void = () => void {};
  handleInitializeFiles: () => void = () => void {};
  handleUpdateFile: (data: RecursiveRoutes) => void = (data: RecursiveRoutes) =>
    void {};
  handleDisableFolder: (data: RecursiveRoutes) => void = (
    data: RecursiveRoutes,
  ) => void {};
  handleDisableFile: (data: RecursiveRoutes) => void = (
    data: RecursiveRoutes,
  ) => void {};
  handleOrderData: (mode: string) => void = (mode: string) => void {};
  handleSortFolderActive: (mode: string) => void = (mode: string) => void {};
  handleOrderBy: (mode: string) => void = (mode: string) => void {};
  handleFilter: (mode: string) => void = (mode: string) => void {};
  handleSortAscendingOrDescending: (mode: boolean) => void = (mode: boolean) =>
    void {};
  handleSortView: (mode: boolean) => void = (mode: boolean) => void {};
  handleUploadImage: (file: any) => void = (file: any) => void {};
  handleLoading: (mode: boolean) => void = (mode: boolean) => void {};
}

type modelContext = {
  status: Array<RecursiveRoutes>;
  listFilesModel: Array<RecursiveRoutes>;
  listFolderModel: Array<RecursiveRoutes>;
  listMultiFileSelected: Array<RecursiveRoutes>;
  selectedItem: RecursiveRoutes;
  selectedFolderParent: RecursiveRoutes;
  optionList: Array<TPKeyValue>;
  optionStatusList: Array<TPKeyValue>;
  sortAscendingOrDescending: boolean;
  viewListAndGrid: boolean;
  orderBy: string;
  filterStatus: string;
  operationMode: string;
  isLoading: boolean;
  handleMultiSelectedFile: (data: RecursiveRoutes) => void;
  handleMultiSelectedFileClear: () => void;
  handleAllSelectedFile: () => void;
  handleSelected: (data: RecursiveRoutes, mode: string) => void;
  handleNewFolder: (data: RecursiveRoutes) => void;
  handleUpdateFolder: (data: RecursiveRoutes) => void;
  handleInitializeFolders: () => void;
  handleInitializeFiles: () => void;
  handleUpdateFile: (data: RecursiveRoutes) => void;
  handleDisableFolder: (data: RecursiveRoutes) => void;
  handleDisableFile: (data: RecursiveRoutes) => void;
  handleOrderData: (mode: string) => void;
  handleSortFolderActive: (mode: string) => void;
  handleOrderBy: (mode: string) => void;
  handleFilter: (mode: string) => void;
  handleSortAscendingOrDescending: (mode: boolean) => void;
  handleSortView: (mode: boolean) => void;
  handleUploadImage: (file: any) => void;
  handleLoading: (mode: boolean) => void;
};

export const ImagesAdminConctext = createContext<modelContext>(new model());

export const AppProviderImagesAdmin: React.FC<AppProviderImagesAdminProps> = ({
  children,
}) => {
  const {
    status,
    listFilesModel,
    listFolderModel,
    selectedItem,
    selectedFolderParent,
    optionList,
    optionStatusList,
    sortAscendingOrDescending,
    viewListAndGrid,
    orderBy,
    filterStatus,
    listMultiFileSelected,
    operationMode,
    isLoading,
    handleMultiSelectedFile,
    handleMultiSelectedFileClear,
    handleAllSelectedFile,
    handleSelected,
    handleNewFolder,
    handleInitializeFolders,
    handleInitializeFiles,
    handleUpdateFolder,
    handleUpdateFile,
    handleDisableFolder,
    handleDisableFile,
    handleOrderData,
    handleSortAscendingOrDescending,
    handleSortFolderActive,
    handleSortView,
    handleOrderBy,
    handleFilter,
    handleUploadImage,
    handleLoading,
  } = useStatusImagesAdmin();

  useEffect(() => {
    handleInitializeFolders();
  }, []);

  useEffect(() => {}, [isLoading]);

  return (
    <TPLoadingOverlay active={isLoading}>
      <ImagesAdminConctext.Provider
        value={{
          status,
          listFilesModel,
          listFolderModel,
          selectedItem,
          selectedFolderParent,
          optionList,
          optionStatusList,
          sortAscendingOrDescending,
          viewListAndGrid,
          orderBy,
          filterStatus,
          listMultiFileSelected,
          operationMode,
          isLoading,
          handleMultiSelectedFile,
          handleMultiSelectedFileClear,
          handleAllSelectedFile,
          handleSelected,
          handleNewFolder,
          handleInitializeFolders,
          handleInitializeFiles,
          handleUpdateFolder,
          handleUpdateFile,
          handleDisableFolder,
          handleDisableFile,
          handleOrderData,
          handleSortAscendingOrDescending,
          handleSortFolderActive,
          handleSortView,
          handleOrderBy,
          handleFilter,
          handleUploadImage,
          handleLoading,
        }}
      >
        {children}
      </ImagesAdminConctext.Provider>
    </TPLoadingOverlay>
  );
};

export const useAppConctextImageAdmin = () => {
  return useContext(ImagesAdminConctext);
};
