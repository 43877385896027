import React, {CSSProperties, useEffect, useState} from "react";
import {ContentVerticalTabInsertUpdateStyled} from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import {TPPageSectionTitle, TPPageTitle} from "@/components/TPPage/tpPageStyles";
import {
    LanguageModel,
    ProjectCloneControl,
    ProjectCloneModel,
    ProjectCloneEnum as e,
} from "@/models/Project/Projects";
import {TPI18N} from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import ProjectNewStep1LanguageModal from "@/pages/Projects/ProjectNew/ProjectNewStep1LanguageModal";
import {Box} from "@mui/material";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {SequeceGeneratorSequencesNameEnum, TPButtonTypes} from "@/models/Global/TPGlobalEnums";
import {SxProps} from "@mui/system/styleFunctionSx";
import {useFormik} from "formik";
import {SequenceService} from "@/services/SequenceService";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import {EventProjectService} from "@/services/EventProjectService";
import {commandsEnum as event} from "@/layouts/VerticalTabs/VerticalTabsAdminContainer";

interface ProjectCloneProps {
    tabId: string;
    element: { [ attribute: string ]: any };
    dispatch: Function;
}

export default function ProjectClone({tabId, element, dispatch}: ProjectCloneProps) {

    const formik = useFormik({
        initialValues: {
            ...ProjectCloneControl.initialValues,
            sourceProjectId: element.id,
        } as ProjectCloneModel,
        validationSchema: ProjectCloneControl.validationSchema,
        validateOnChange: false,
        onSubmit: () => {
        },
    });

    const [m, setMessages] = useState<any>({});
    const [languages, setLanguages] = useState<any>({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        load().then(() => setLoading(false))
    }, []);

    useEffect(() => {
        const l = [
            {
                languageId: TPGlobal.language,
                localizedValue: formik.values?.name,
                order: 0
            } as LanguageModel,
            ...Object.entries(languages)
                ?.map(([languageId, localizedValue], order) => ({
                    languageId,
                    localizedValue,
                    order: order + 1
                } as LanguageModel))
        ]
        formik.setFieldValue("nameLanguages", l);
    }, [formik.values.name, languages]);

    async function load() {
        const messages = {...m};
        // title label
        messages[e.TitleLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.TitleLabel);
        messages[e.DescriptionLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.DescriptionLabel);
        // form inputs label
        messages[e.FormInputSourceIdLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormInputSourceIdLabel);
        messages[e.FormInputIdLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormInputIdLabel);
        messages[e.FormInputNameLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormInputNameLabel);
        messages[e.FormInputActiveLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormInputActiveLabel);
        // language modal label
        messages[e.LanguageButtonTooltipLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.LanguageButtonTooltipLabel);
        messages[e.LanguageModalTitleLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.LanguageModalTitleLabel);
        messages[e.LanguageModalOkButtonLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.LanguageModalOkButtonLabel);
        messages[e.LanguageModalCancelButtonLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.LanguageModalCancelButtonLabel);
        // form actions label
        messages[e.FormActionCancelLabel] = await TPI18N.GetText(TPGlobal.globalResourceSet, e.FormActionCancelLabel);
        messages[e.FormActionNewProjectLabel] = await TPI18N.GetText(e.ProjectCloneComponent, e.FormActionNewProjectLabel);

        setMessages(messages)

        if (element.id) {
            const {generalAutomaticId} = new SequenceService();
            generalAutomaticId(false, true, [200], SequeceGeneratorSequencesNameEnum.SQEVPJ)
                .then(({responseData}) => {
                    formik.setFieldValue("projectId", responseData?.data[0]?.sequenceCode)
                })
                .catch(error => console.error(error))
        }
    }

    function onClickSaveButtonHandler() {
        const {clone} = new EventProjectService()
        clone(formik.values)
            .then(project => {
                dispatch({type: event.vertical_tab_close, payload: tabId })
                dispatch({type: event.reloadGrid})
                dispatch({type: event.detail, payload: project })
            })
            .catch(error => console.error(error))
    }

    function onClickCancelButtonHandler() {
        dispatch({type: event.vertical_tab_close, payload: tabId })
        dispatch({type: event.reloadGrid})
    }

    return (
        <ContentVerticalTabInsertUpdateStyled>
            <TPLoadingOverlay active={loading}>
                <div className="row">
                    <div className="col-8">
                        <TPPageTitle style={styles.title}>{m?.[e.TitleLabel]}</TPPageTitle>

                        <form style={styles.form}>
                            <h6>{m?.[e.DescriptionLabel]}</h6>

                            <TPTextBox
                                id="sourceProjectId"
                                disabled={true}
                                labelText={m?.[e.FormInputSourceIdLabel]}
                                isMandatory={true}
                                value={`${element.name} (${element.id})`}
                                onChange={() => {
                                }}
                                errorMessage={formik.errors.sourceProjectId && m?.[formik.errors.sourceProjectId]}
                            />

                            <TPTextBox
                                id="projectId"
                                disabled={true}
                                labelText={m?.[e.FormInputIdLabel]}
                                isMandatory={true}
                                value={formik.values.projectId}
                                onChange={() => {
                                }}
                                errorMessage={formik.errors.projectId && m?.[formik.errors.projectId]}
                            />

                            <div className="row col-12" style={styles.name}>
                                <div className="col-11">
                                    <TPTextBox
                                        id="name"
                                        isMandatory={true}
                                        labelText={m?.[e.FormInputNameLabel]}
                                        value={formik.values?.name ?? ""}
                                        onChange={(e: any) => formik.setFieldValue("name", e.target.value)}
                                        maxLength={100}
                                        errorMessage={formik.errors.name && m?.[formik.errors.name]}
                                    />
                                </div>

                                <div className="col-1" style={styles.iconButton}>
                                    <ProjectNewStep1LanguageModal
                                        m={m}
                                        languages={languages}
                                        setLanguages={setLanguages}
                                    />
                                </div>
                            </div>

                            <TPCheckBox
                                id="isActive"
                                labelText={m?.[e.FormInputActiveLabel]}
                                checked={formik.values.isActive}
                                onChange={() => formik.setFieldValue("isActive", !formik.values.isActive)}
                            />
                        </form>

                        <Box sx={styles.actions}>
                            <TPButton
                                type={TPButtonTypes.link}
                                onClick={onClickCancelButtonHandler}
                            >
                                {m?.[e.FormActionCancelLabel]}
                            </TPButton>

                            <TPButton
                                type={TPButtonTypes.primary}
                                onClick={onClickSaveButtonHandler}
                            >
                                {m?.[e.FormActionNewProjectLabel]}
                            </TPButton>
                        </Box>
                    </div>
                </div>
            </TPLoadingOverlay>
        </ContentVerticalTabInsertUpdateStyled>
    );
}

const styles = {
    title: {
        textTransform: "none",
    } as CSSProperties,
    form: {
        marginBottom: "20px",
    } as CSSProperties,
    name: {
        marginBottom: "10px",
    } as CSSProperties,
    iconButton: {
        marginTop: "auto",
    } as CSSProperties,
    actions: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: "border-box",
        placeContent: "flex-end",
        alignItems: "flex-end",
    } as SxProps,
};
