import TPButton from '@/components/bootstrap/components/buttons/TPButton';
import TPLoadingOverlay from '@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay';
import TPCheckBox from '@/components/bootstrap/forms/checkbox/TPCheckBox';
import TPSelect from '@/components/bootstrap/forms/select/TPSelect';
import TPTextBox from '@/components/bootstrap/forms/textbox/TPTextBox';
import TPAutoComplete from '@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete';
import TPLabel from '@/components/bootstrap/forms/TPLabel/TPLabel';
import { TPEditor } from '@/components/TPEditor/TPEditor';
import {
  TPPageAcceptCancelButtonsContainer,
  TPPageSection,
  TPPageSectionTitle,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModal from "@/layouts/TPModal/TPModal";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
  EmailTemplateInputDTO,
  EmailTemplateInputDTOValidator,
} from "@/models/EmailTemplates/EmailTemplateInputDTO";
import { EmailTemplateViewModel } from "@/models/EmailTemplates/EmailTemplateModels";
import {
  SequeceGeneratorSequencesNameEnum,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { RecordLocalizedModel } from "@/models/Global/TPGlobalModels";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import { EmailTemplateService } from "@/services/EmailTemplateService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import { SequenceService } from "@/services/SequenceService";
import { FC, ReactElement, useEffect, useRef, useState } from "react";

type InsertUpdateProps = {
  mode: string;
  recordId: string;
  languageId: string;
  callBackResult: Function;
  preview?: boolean;
  caseNumber?: string;
  storeResponse?: any;
  handleCaseNumber?: any;
  handlePreviewAPI?: any;
};

type additionalDataAutoCompleteType = {
  additionalDataAutoComplete: Array<TPKeyValue>;
  additionalDataAutoCompleteErrorMessage: string;
  [x: string]: any;
};

type screenStateProps = {
  id: string;
  idAccount: string;
  description: string;
  isActive: boolean;
  isAddable: boolean;
  attachCaseInformation: number;
  descriptionLanguageList: Array<RecordLocalizedModel>;
  subjectLanguageList: Array<RecordLocalizedModel>;
  bodyLanguageList: Array<RecordLocalizedModel>;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  languageId: string;
  tagGroup: string;
  tag: string;
  body: string;
  [key: string]: any;
  recordLanguageList: Array<TPKeyValue>;
  // Validations
  idErrorMessage: string;
  idAccountErrorMessage: string;
  descriptionErrorMessages: Array<string>;
  attachCaseInformationErrorMessage: string;
  toErrorMessage: string;
  ccErrorMessage: string;
  bccErrorMessage: string;
  languageIdErrorMessage: string;
  bodyErrorMessage: string;
  subjectErrorMessage: string;
  tagGroupErrorMessage: string;
  tagErrorMessage: string;
  nameErrorMessages: Array<string>;
};

const EmailTemplatesInsertUpdate: FC<InsertUpdateProps> = ({
  mode,
  recordId,
  callBackResult,
  languageId,
  preview,
  caseNumber,
  storeResponse,
  handleCaseNumber,
  handlePreviewAPI,
}): ReactElement => {
  const multilanguageTableName: String = "EMailTEmplate";
  const multilanguageDescriptionField: String = "Description_EMTE";
  const componentFileName: string = "EmailTemplatesInsertUpdate.tsx";
  //#region Init
  // Screen loading
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

	// Screen resources
	const resourceSet: string = 'EmailTemplatesInsertUpdateComponent';
	const [titleLabel, setTitleLabel] = useState('');
	const [subTitleLabel, setSubTitleLabel] = useState('');
	const [bodyLabel, setBodyLabel] = useState('');
	const [parametersSectionLabel, setParametersSectionLabel] = useState('');
	const [descriptionSectionLabel, setDescriptionSectionLabel] = useState('');
	const [emailAccountLabel, setEmailAccountLabel] = useState('');
	const [attachCaseInformationLabel, setAttachCaseInformationLabel] = useState('');
	const [toLabel, setToLabel] = useState('');
	const [ccLabel, setCcLabel] = useState('');
	const [bccLabel, setBccLabel] = useState('');
	const [subjectLabel, setSubjectLabel] = useState('');
	const [languageLabel, setLanguageLabel] = useState('');
	const [isAddableLabel, setIsAddableLabel] = useState('');
	const [isActiveLabel, setIsActiveLabel] = useState('');
	const [descriptionLabel, setDescriptionLabel] = useState('');
	const [idLabel, setIdLabel] = useState('');
	const [emailAccountOptions, setEmailAccountOptions] = useState<Array<TPKeyValue>>([]);
	const [attachmentOptions, setAttachmentOptions] = useState<Array<TPKeyValue>>([]);
	const [languageOptions, setLanguageOptions] = useState<Array<TPKeyValue>>([]);
	const [saveButtonLabel, setSaveButtonLabel] = useState('');
	const [cancelButtonLabel, setCancelButtonLabel] = useState('');
	const [realMode, setRealMode] = useState(mode);
	const [realRecordId, setRealRecordId] = useState(recordId);
	const [okButtonLabel, setOkButtonLabel] = useState('');
	const [isOpenTagModal, setIsOpenTagModal] = useState(false);
	const [tagGroups, setTagGroups] = useState<Array<TPKeyValue>>([]);
	const [tags, setTags] = useState<Array<TPKeyValue>>([]);
	const [isShownModalSubjectSelect, setIsShownModalSubjectSelect] = useState(false);
	const editorSubjectRef = useRef<HTMLTextAreaElement>(null); // Top n options for arrow icon
	const [autocompleteToTopNOptions, setAutocompleteToTopNOptions] = useState<Array<TPKeyValue>>([]);
	const [autocompleteToOptions, setAutocompleteToOptions] = useState<Array<TPKeyValue>>([]);
	const [languageListLabel, setLanguageListLabel] = useState('');
	const [originalRecordDescription, setOriginalRecordDescription] = useState('');
	const [bodyGroup, setBodyGroup] = useState({ tag: '' });
	const [referenceId, setReferenceId] = useState('');
  const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false);

  const initialadditionalDataAutoCompleteState: additionalDataAutoCompleteType =
    {
      additionalDataAutoComplete: [],
      additionalDataAutoCompleteErrorMessage: "",
    };

  const [additionalDataAutoCompleteState, setadditionalDataAutoCompleteState] =
    useState<additionalDataAutoCompleteType>(
      initialadditionalDataAutoCompleteState,
    );

  let initialErrorMessages: Array<string> = [];
  for (
    let i: number = 0;
    i <= TPGlobal.TPClientAvailableLanguages.length - 1;
    i++
  ) {
    initialErrorMessages.push("");
  }

  let screenStateInitialState: screenStateProps = {
    id: "",
    idAccount: "",
    languageId: "",
    description: "",
    isActive: false,
    isAddable: false,
    attachCaseInformation: 0,
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    body: "",
    recordLanguageList: [],

    idErrorMessage: "",
    idAccountErrorMessage: "",
    attachCaseInformationErrorMessage: "",
    toErrorMessage: "",
    ccErrorMessage: "",
    bccErrorMessage: "",
    languageIdErrorMessage: "",
    bodyErrorMessage: "",
    subjectErrorMessage: "",
    nameErrorMessages: [],
    descriptionLanguageList: [],
    subjectLanguageList: [],
    bodyLanguageList: [],
    descriptionErrorMessages: [],
    tagGroup: "",
    tag: "",
    tagErrorMessage: "",
    tagGroupErrorMessage: "",
  };

  const [screenState, setScreenState] = useState<screenStateProps>(
    screenStateInitialState,
  );

  const editorRef = useRef<any>(null);

  // const [editorValue, setEditorValue] = useState("");
  // const [editorErrorMessage, setEditorErrorMessage] = useState<any>("");

  // const handleChangeEditor = (value: any) => {
  //   const filterData = value.replace(/(<([^>]+)>)/ig, '');
  //   setEditorValue(value);
  //   if (filterData.length > 0) {
  //     // setIsMessageError(false);
  //   }
  // };

	//#endregion
	const loadResourcesAndLoadEmailTemplatesInfo = async () => {
		let i: number;
		setSaveButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'SaveButton'));
		setBodyLabel(await TPI18N.GetText(resourceSet, 'BodyLabel'));
		setIdLabel(await TPI18N.GetText(resourceSet, 'IdLabel'));
		setDescriptionLabel(await TPI18N.GetText(resourceSet, 'DescriptionLabel'));
		setIsActiveLabel(await TPI18N.GetText(resourceSet, 'IsActiveLabel'));
		setIsAddableLabel(await TPI18N.GetText(resourceSet, 'IsAddableLabel'));
		setCancelButtonLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'CancelButton'));
		setParametersSectionLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ParametersSectionLabel'));
		setTitleLabel(await TPI18N.GetText(resourceSet, 'Title' + realMode + 'Label'));
		setSubTitleLabel(await TPI18N.GetText(resourceSet, 'SubtitleLabel'));
		setDescriptionSectionLabel(await TPI18N.GetText(resourceSet, 'DescriptionSectionLabel'));
		setEmailAccountLabel(await TPI18N.GetText(resourceSet, 'EmailAccountLabel'));
		setAttachCaseInformationLabel(await TPI18N.GetText(resourceSet, 'AttachCaseInformationLabel'));
		setToLabel(await TPI18N.GetText(resourceSet, 'ToLabel'));
		setCcLabel(await TPI18N.GetText(resourceSet, 'CcLabel'));
		setBccLabel(await TPI18N.GetText(resourceSet, 'BccLabel'));
		setSubjectLabel(await TPI18N.GetText(resourceSet, 'SubjectLabel'));
		setLanguageLabel(await TPI18N.GetText(resourceSet, 'LanguageLabel'));

    setLanguageListLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
    );
    setOkButtonLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
    );

    if (realMode === "Update") {
      await getEmailTemplateById(realRecordId);
    }

    if (realMode === "Insert") {
      let newInsertUpdateState = { ...screenState };
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newInsertUpdateState.recordLanguageList.push(keyValueElement);
      }
      setScreenState(newInsertUpdateState);
      setIsLoadingScreen(false);
    }

    let serviceClient = new OutboundMailAccountService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getAllMailAccounts(
        false,
        true,
        expectedCodes,
      );
      let newEmailAccountListState: Array<TPKeyValue> = [];
      responseRequest.forEach((item: any) => {
        newEmailAccountListState.push({
          key: item.idAccount,
          value: item.description,
          ...item,
        });
      });

      newEmailAccountListState.unshift({ key: "", value: "--" });
      setEmailAccountOptions(newEmailAccountListState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getAllMailAccounts ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getAllMailAccounts ex`);
      setIsLoadingScreen(false);
    }

    let _serviceClient = new EmailTemplateService();
    let _expectedCodes: Array<number> = [200, 404];
    try {
      let responseRequest = await _serviceClient.getLanguagesOption(
        "C",
        false,
        true,
        _expectedCodes,
      );

      // Language dropdown
      let languageOptions = responseRequest.map(
        (template: EmailTemplateViewModel) => ({
          key: template.id,
          value: template.name,
        }),
      );

      languageOptions.unshift({
        key: "--",
        value: "--",
      });

      setLanguageOptions(languageOptions);
    } catch (error) {
      // ... error handling ...
      console.error("error", error);
    }

    let newAttachmentOptions: Array<TPKeyValue> = [
      {
        key: "0",
        value: await TPI18N.GetText(resourceSet, "NoneItemKeyValue"),
      },
      {
        key: "1",
        value: await TPI18N.GetText(resourceSet, "CaseDetailsItemKeyValue"),
      },
      {
        key: "2",
        value: await TPI18N.GetText(
          resourceSet,
          "CaseDetailsAndTaskDetailsKeyValue",
        ),
      },
    ];

    setAttachmentOptions(newAttachmentOptions);

    await getTagGroups();

    setIsLoadingScreen(false);
    setResourcesLoaded(true);
  };

  // Function to handle changes in the number box
  const handleCase = (e: any) => {
    handleCaseNumber(e.target.value);
  };
  const handleInputTextChange = async (propertyName: string, newValue: any) => {
    if (!resourcesLoaded) return;
    if (propertyName === "tagGroup") {
      setScreenState({
        ...screenState,
        [propertyName]: newValue,
        [propertyName + "ErrorMessage"]: "",
      });
      await getTagsByGroup(newValue);
    }

    if (propertyName === "tag") {
      setBodyGroup({
        tag: newValue,
      });
    }
    setScreenState({
      ...screenState,
      [propertyName]: newValue,
      [propertyName + "ErrorMessage"]: "",
    });
  };

  const handleLanguageChange = (index: number, newName: string) => {
    let newInsertUpdateState = { ...screenState };
    newInsertUpdateState.recordLanguageList[index].value = newName;
    newInsertUpdateState.descriptionErrorMessages[index] = "";
    setScreenState(newInsertUpdateState);
  };

  const handleIsActiveChange = () => {
    let newInsertUpdateState = { ...screenState };
    newInsertUpdateState.isActive = !screenState.isActive;
    setScreenState(newInsertUpdateState);
  };
  const handleIsAddableChange = () => {
    let newInsertUpdateState = { ...screenState };
    newInsertUpdateState.isAddable = !screenState.isAddable;
    setScreenState(newInsertUpdateState);
  };

  const handleOnOkButtonClick = async () => {
    let isInValid: boolean = false;
    let i: number;
    let n: number;
    let recordInputDTO: EmailTemplateInputDTO = {
      // id: screenState.id,
      id: realMode === "Insert" ? referenceId : recordId,
      languageId: screenState.languageId,
      description: screenState.recordLanguageList[0].value,
      idAccount: screenState.idAccount,
      to: screenState.to,
      cc: screenState.cc,
      bcc: screenState.bcc,
      subject: TPGlobal.stringToUTF8(screenState.subject).toString(),
      body: TPGlobal.stringToUTF8(screenState.body).toString(),
      isAddable: screenState.isAddable,
      attachCaseInformation: screenState.attachCaseInformation,
      isActive: screenState.isActive,
      descriptionLocalizedValues: [],
    };
    n = screenState.recordLanguageList.length;
    for (i = 0; i <= n - 1; i++) {
      let item: TPKeyValue;
      item = screenState.recordLanguageList[i];
      recordInputDTO.descriptionLocalizedValues.push({
        order: i + 1,
        languageId: item.key,
        localizedValue: item.value,
      });
    }
    let inputDTOValidator = new EmailTemplateInputDTOValidator();
    let resultValidator = inputDTOValidator.validate(recordInputDTO);
    if (!TPGlobal.TPIsEmpty(resultValidator)) {
      let newInsertUpdateState = { ...screenState };

      // Handle 'id' field
      if (resultValidator.id) {
        newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
          resultValidator.id,
        );
      } else {
        newInsertUpdateState.idErrorMessage = "";
      }
      // Handle 'description' field
      if (resultValidator.description) {
        newInsertUpdateState.descriptionErrorMessages[0] =
          await TPI18N.GetResource(resultValidator.description);
      } else {
        newInsertUpdateState.descriptionErrorMessages[0] = "";
      }
      if (resultValidator.descriptionLocalizedValues) {
        n = screenState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          if (resultValidator.descriptionLocalizedValues[i] != null) {
            newInsertUpdateState.descriptionErrorMessages[i] =
              await TPI18N.GetResource(
                String(resultValidator.descriptionLocalizedValues[i]),
              );
          }
        }
      } else {
        n = screenState.recordLanguageList.length;
        for (i = 1; i <= n - 1; i++) {
          newInsertUpdateState.descriptionErrorMessages[i] = "";
        }
      }
      // Handle 'idAccount' field
      if (resultValidator.idAccount) {
        newInsertUpdateState.idAccountErrorMessage = await TPI18N.GetResource(
          resultValidator.idAccount,
        );
      } else {
        newInsertUpdateState.idAccountErrorMessage = "";
      }

      // Handle 'languageId' field
      if (resultValidator.languageId) {
        newInsertUpdateState.languageIdErrorMessage = await TPI18N.GetResource(
          resultValidator.languageId,
        );
      } else {
        newInsertUpdateState.languageIdErrorMessage = "";
      }

      // Handle 'subject' field
      if (resultValidator.subject) {
        newInsertUpdateState.subjectErrorMessage = await TPI18N.GetResource(
          resultValidator.subject,
        );
      } else {
        newInsertUpdateState.subjectErrorMessage = "";
      }
      newInsertUpdateState = {
        ...newInsertUpdateState,
        body: newInsertUpdateState.body.replace(/<[^>]*>/g, ""),
      };

      // Handle 'subject' field
      if (newInsertUpdateState.body === "") {
        resultValidator = {
          ...resultValidator,
          body: "EmailTemplateInputDTOValidator|InputDTOBodyEmpty",
        };
        if (resultValidator.body) {
          newInsertUpdateState.bodyErrorMessage = await TPI18N.GetResource(
            resultValidator.body,
          );
        }
      } else {
        newInsertUpdateState.bodyErrorMessage = "";
      }
      setScreenState(newInsertUpdateState);
      isInValid = true;
    }
    if (!isInValid) {
      if (realMode === "Insert") {
        await insertEmailTemplate(recordInputDTO);
      } else {
        await updateEmailTemplate(recordInputDTO);
      }
    }
  };

  const insertEmailTemplate = async (inputDTO: EmailTemplateInputDTO) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.insertEmailTemplate(
        inputDTO,
        true,
        true,
        expectedCodes,
      );
      if (responseRequest.responseResult) {
        callBackResult({
          result: "save_general_insert_and_change_mode",
          recordId: responseRequest.responseData.keyList[0].value,
          recordDescription: inputDTO.description,
        });
        //change mode
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleUpdateLabel"));
        setRealRecordId(responseRequest.responseData.keyList[0].value);
        setOriginalRecordDescription(
          inputDTO.description.length > 100
            ? inputDTO.description.substring(0, 100) + "..."
            : inputDTO.description,
        );
        setRealMode("Update");
      }
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} insertEmailTemplate ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} insertEmailTemplate ex`);
      setIsLoadingScreen(false);
    }
  };

  const updateEmailTemplate = async (inputDTO: EmailTemplateInputDTO) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.updateEmailTemplate(
        inputDTO,
        true,
        true,
        expectedCodes,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        callBackResult({
          result: "save_general_update",
          recordId: inputDTO.id,
        });
      }
    } catch (error) {
      TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
      console.error(`Error ${componentFileName} update ex`);
      setIsLoadingScreen(false);
    }
  };

  const getEmailTemplateById = async (pRecordId: string) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];
    let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
    let i: number;
    let j: number;

    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getEmailTemplateById(
        pRecordId,
        languageId,
        false,
        true,
        expectedCodes,
      );
      let recordInfo: EmailTemplateViewModel;
      recordInfo = { ...responseRequest };

      setOriginalRecordDescription(
        recordInfo.description.length <= 100
          ? recordInfo.description
          : recordInfo.description.substring(0, 100) + "...",
      );

      let newScreenState = { ...screenState };
      newScreenState.recordLanguageList = [];
      for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
        let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
        let keyValueElement: TPKeyValue = { key: item.id, value: "" };
        newScreenState.recordLanguageList.push(keyValueElement);
      }
      newScreenState.attachCaseInformation = recordInfo.attachCaseInformation;
      newScreenState.languageId = recordInfo.languageId;
      newScreenState.bcc = recordInfo.bcc;
      newScreenState.body = recordInfo.body;
      newScreenState.cc = recordInfo.cc;
      // newScreenState.description = recordInfo.description;use
      newScreenState.recordLanguageList[0].value = recordInfo.description;
      newScreenState.id = recordInfo.id;
      newScreenState.idAccount = recordInfo.idAccount;
      newScreenState.isActive = recordInfo.isActive;
      newScreenState.isAddable = recordInfo.isAddable;
      newScreenState.subject = recordInfo.subject;
      newScreenState.to = recordInfo.to;

      recordLanguagesList = await getRecordLanguageList(pRecordId);
      if (recordLanguagesList.length == 0) {
        TPLog.Log(
          `Error ${componentFileName} getFunctionById getRecordLanguageList`,
          TPLogType.ERROR,
          "recordLanguagesList is empty",
        );
        console.error(
          `Error ${componentFileName} getFunctionById getRecordLanguageList is empty`,
        );
        setIsLoadingScreen(false);
        return;
      }
      for (i = 0; i <= newScreenState.recordLanguageList.length - 1; i++) {
        for (j = 0; j <= recordLanguagesList.length - 1; j++) {
          if (
            newScreenState.recordLanguageList[i].key.toLowerCase() ==
            recordLanguagesList[j].languageId.toLowerCase()
          ) {
            newScreenState.recordLanguageList[i].value =
              recordLanguagesList[j].recordDescription;
            break;
          }
        }
      }
      setScreenState(newScreenState);
      setIsLoadingScreen(false);
      return;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getEmailTemplateById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getEmailTemplateById ex`);

      setIsLoadingScreen(false);
      return null;
    }
  };

  const getRecordLanguageList = async (
    pRecordId: String,
  ): Promise<Array<MultilanguageFieldValueViewModel>> => {
    let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
    let expectedCodes: Array<number> = [200];

    try {
      let responseRequest =
        await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
          multilanguageTableName,
          multilanguageDescriptionField,
          pRecordId,
          false,
          true,
          expectedCodes,
        );

      let recordInfoLanguage: Array<MultilanguageFieldValueViewModel>;
      recordInfoLanguage = [...responseRequest];
      return recordInfoLanguage;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getRecordLanguageList ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getRecordLanguageList ex`);
      return [];
    }
  };

  const handleCancelButtonClick = () => {
    callBackResult({ result: "cancel_general", recordId: realRecordId });
  };

  const {
    isOpen: isOpenModalLanguageList,
    openModal: handleOpenModalLanguageList,
    closeModal: handleCloseModalLanguageList,
    saveChanges: handleSaveChangesModalLanguageList,
  } = useModal(false);

  //Run only once to load resources and active filters
  useEffect(() => {
    if (!resourcesLoaded) {
      loadResourcesAndLoadEmailTemplatesInfo();
      realMode === "Insert" && generalAutomaticId();
    }
  }, [storeResponse]);

  const handleCallbackAnser = (status: boolean, callbackData: any) => {
    setScreenState((prevState) => ({
      ...prevState,
      tag: "",
      tagGroup: "", // or whatever default value is appropriate
    }));

    setAutocompleteToOptions([]);
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);

    setIsOpenTagModal(false);
  };

  const handleOpenTagPopup = () => {
    setScreenState((prevState) => ({
      ...prevState,
      tag: "",
      tagGroup: "", // or whatever default value is appropriate
    }));

    setAutocompleteToOptions([]);
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);

    setIsOpenTagModal(true);
  };

  // Example for getTagGroups
  const getTagGroups = async () => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getTagGroups(
        false,
        true,
        expectedCodes,
      );
      // Handle the response accordingly
      if (responseRequest) {
        // Do something with the result...
        let tagGroupOptions = responseRequest.map((tagGroup: any) => ({
          key: tagGroup.id,
          value: tagGroup.localizedDescription,
        }));
        tagGroupOptions.unshift({
          key: "--",
          value: "--",
        });

        setTagGroups(tagGroupOptions);
      }

      setIsLoadingScreen(false);
      return;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTagGroups ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTagGroups ex`);
      setIsLoadingScreen(false);
    }
  };

  // Example for getTagsByGroup
  const getTagsByGroup = async (groupCode: string) => {
    let serviceClient = new EmailTemplateService();
    let expectedCodes: Array<number> = [200];

    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getTagsByGroup(
        groupCode,
        false,
        true,
        expectedCodes,
      );

      // Handle the response accordingly
      if (responseRequest) {
        // Do something with the result...
        // tags dropdown
        let tagsOptions = responseRequest.map((tags: any) => ({
          key: tags.value,
          value: tags.localizedDescription,
        }));
        tagsOptions.unshift({
          key: "--",
          value: "--",
        });
        setTags(tagsOptions);
      }

      setIsLoadingScreen(false);
      return;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTagsByGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} getTagsByGroup ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleToOnAutocompleteQuery = async (query: string) => {
    let tasktypeService = new EmailTemplateService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      //Load users by search
      let responseRequest = await tasktypeService.getAdditionalData(
        "S_CASEDET",
        query,
        false,
        true,
        expectedCodes,
      );
      let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item: { id: any; localizedDescription: any }) {
          return {
            key: item.id,
            value: `${item.localizedDescription}`,
          };
        },
      );
      setAutocompleteToOptions(newToKeyValueList);
      return newToKeyValueList;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(
        `Error ${componentFileName} handleToOnAutocompleteQuery ex`,
      );
      return [];
    }
  };

  const handleToChange = (newSelectedValue: Array<TPKeyValue>) => {
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete =
      newSelectedValue;
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
  };

  const handleAutoCompleteTopNClick = async () => {
    let newTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteToTopNOptions.length === 0) {
      newTopNOptions = await handleToOnAutocompleteQuery("");

      if (newTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteToTopNOptions([...newTopNOptions]);
        setAutocompleteToOptions([...newTopNOptions]);
        let newAdditionalDataAutoCompleteState = {
          ...additionalDataAutoCompleteState,
        };
        newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
        newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
          "";
        setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
      }
    } else {
      //use cached values;
      setAutocompleteToOptions([...autocompleteToTopNOptions]);
      let newAdditionalDataAutoCompleteState = {
        ...additionalDataAutoCompleteState,
      };
      newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
      newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
        "";
      setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
    }
  };

  const handleToOnAutocompleteKeyDown = (event: any) => {
    //left arrow 37
    //right arror 39
    //enter 13
    //home 36
    //end  35
    if (
      event.keyCode != 37 &&
      event.keyCode != 39 &&
      event.keyCode != 13 &&
      event.keyCode != 35 &&
      event.keyCode != 36
    ) {
      setAutocompleteToOptions([]);
      let newAdditionalDataAutoCompleteState = {
        ...additionalDataAutoCompleteState,
      };
      newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
      newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
        "";
      setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);
    }
  };

  const handleAddTagForBody = () => {
    // Get the current content of the editor
    handleInputTextChange("body", bodyGroup.tag);
    // Get the current content of the editor
    // const currentContent = editorRef.current.getContent();
    // const currentContent = editorRef.current.props.value;

    // // Get the last cursor position
    // const lastCursorPosition = editorRef.current.selection.getRng();

    // // Ensure the offset is within the valid range
    // const validOffset = Math.min(
    //   lastCursorPosition.startOffset,
    //   currentContent.length
    // );

    // // Get the editor selection
    // const editorSelection = editorRef.current.selection;

    // // Construct the tag to be inserted
    // const tag =
    //   additionalDataAutoCompleteState.additionalDataAutoComplete.length === 1 &&
    //     screenState.tag
    //     ? `ADDACASES:${additionalDataAutoCompleteState.additionalDataAutoComplete[0].key}`
    //     : screenState.tag;

    // // Insert the tag into the content at the valid offset
    // const updatedContent = `${currentContent.substring(
    //   0,
    //   validOffset
    // )}[${tag}]${currentContent.substring(validOffset)}`;

    // // Set the content with the updated data
    // editorRef.current.setContent(updatedContent);

    // // Set the cursor position after the inserted tag
    // editorSelection.setCursorLocation(validOffset + tag.length);

    // Close the modal
    setIsOpenTagModal(false);
  };

  const generalAutomaticId = async () => {
    let serviceClient = new SequenceService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      let responseRequest = await serviceClient.generalAutomaticId(
        false,
        true,
        expectedCodes,
        SequeceGeneratorSequencesNameEnum.SQEMTE,
      );

      setIsLoadingScreen(false);
      if (responseRequest.responseResult) {
        // callBackResult({ result: "OK", recordId: recordId });
        let result = responseRequest?.responseData?.data[0]?.sequenceCode;
        setReferenceId(result);
      }
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updatetFunction ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${componentFileName} updatetFunction ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleAddTagForSubject = () => {
    // Get the current selection position
    const selectionStart = editorSubjectRef.current?.selectionStart;
    const selectionEnd = editorSubjectRef.current?.selectionEnd;

    // Ensure selectionStart and selectionEnd are not undefined
    if (selectionStart !== undefined && selectionEnd !== undefined) {
      // Get the current value of the subject
      const currentSubject = screenState.subject as string; // Explicitly cast to string

      // Construct the tag to be inserted (assuming you have a tag state)
      // const tag = screenState.tag; // Assuming you have a state for the tag
      const tag =
        additionalDataAutoCompleteState.additionalDataAutoComplete.length ===
          1 && screenState.tag
          ? `ADDACASES:${additionalDataAutoCompleteState.additionalDataAutoComplete[0].key}`
          : screenState.tag;

      // Insert the tag into the subject at the cursor position
      const updatedSubject =
        currentSubject.slice(0, selectionStart) +
        `[${tag}]` +
        currentSubject.slice(selectionEnd);

      // Update the subject state with the updated value
      handleInputTextChange("subject", updatedSubject);

      // Close the modal
      setIsOpenTagModal(false);
      setIsShownModalSubjectSelect(false);
    }
  };

  const handleOpenSubjectModal = () => {
    setScreenState((prevState) => ({
      ...prevState,
      tag: "",
      tagGroup: "", // or whatever default value is appropriate
    }));

    setAutocompleteToOptions([]);
    let newAdditionalDataAutoCompleteState = {
      ...additionalDataAutoCompleteState,
    };
    newAdditionalDataAutoCompleteState.additionalDataAutoComplete = [];
    newAdditionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage =
      "";
    setadditionalDataAutoCompleteState(newAdditionalDataAutoCompleteState);

    setIsOpenTagModal(true);
    setIsShownModalSubjectSelect(true);
  };

  return (
    <ContentVerticalTabInsertUpdateStyled>
      <TPLoadingOverlay active={isLoadingScreen}>
        <div className="row">
          <div className="col-10">
            <TPPageTitle>{titleLabel}</TPPageTitle>

            {realMode === "Update" ? (
              <TPPageSubTitle>{`${subTitleLabel} ${recordId} / ${originalRecordDescription}`}</TPPageSubTitle>
            ) : null}
            <hr />
          </div>
        </div>

				{/** id */}
				{preview ? (
					<div>
						<div className="row">
							<div className="col">
								<TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
							</div>
						</div>
						<div className="row">
							<div className="col-10">
								<TPPageSection>
									<div className="row">
										<div className="col-6">
											<div className="form-group">
												<TPTextBox
													id="IdTextBox"
													// disabled={realMode !== "Insert"}
													disabled
													labelText={idLabel}
													isMandatory={true}
													// value={screenState.id}
													value={realMode === 'Insert' ? referenceId : recordId}
													onChange={
														realMode === 'Insert'
															? (e: any) => handleInputTextChange('id', e.target.value)
															: () => {
																	TPGlobal.foo();
																}
													}
													maxLength={20}
													errorMessage={screenState.idErrorMessage}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										{screenState.recordLanguageList.length > 0 &&
											TPGlobal.TPClientAvailableLanguages.map(
												(item, index) =>
													index === 0 && (
														<div className="col-6" key={`languageItem-${item.id}`}>
															<div className="form-group">
																<TPTextBox
																	id="IdTextBox"
																	isMandatory={index === 0}
																	labelText={`${descriptionLabel} (${item.name})`}
																	value={screenState.recordLanguageList[index].value}
																	onChange={(e: any) => handleLanguageChange(index, e.target.value)}
																	maxLength={100}
																	errorMessage={screenState.descriptionErrorMessages[index]}
																/>
															</div>
														</div>
													)
											)}
										{screenState.recordLanguageList.length > 1 && (
											<>
												<div className="col-4">
													<div className="pt-4">
														<TPButton
															id="IdButton"
															type={TPButtonTypes.icon}
															icon={TPIconTypes.language}
															text={`+${screenState.recordLanguageList.length - 1}`}
															tooltip={languageListLabel}
															className={'pt-3'}
															onClick={handleOpenModalLanguageList}
														/>
													</div>
												</div>
												<TPModalLanguageList
													id="IdModalLanguageList"
													isOpen={isOpenModalLanguageList}
													title={languageListLabel}
													acceptLabel={saveButtonLabel}
													cancelLabel={cancelButtonLabel}
													saveChanges={handleSaveChangesModalLanguageList}
													closeModal={handleCloseModalLanguageList}
												>
													{TPGlobal.TPClientAvailableLanguages.map(
														(item, index) =>
															index > 0 && (
																<div
																	className="row overflow-auto"
																	key={`languageItem-${item.id}`}
																	style={{ height: '200px' }}
																>
																	<div className="col-12">
																		<div className="form-group">
																			<TPTextBox
																				id="IdTextBox"
																				isMandatory={index === 0}
																				labelText={`${descriptionLabel} (${item.name})`}
																				value={screenState.recordLanguageList[index].value}
																				onChange={(e: any) => handleLanguageChange(index, e.target.value)}
																				maxLength={200}
																				errorMessage={screenState.descriptionErrorMessages[index]}
																			/>
																		</div>
																	</div>
																</div>
															)
													)}
												</TPModalLanguageList>
											</>
										)}
									</div>
								</TPPageSection>
							</div>
						</div>

            <div className="row">
              <div className="col">
                <TPPageSectionTitle>
                  {parametersSectionLabel}
                </TPPageSectionTitle>
              </div>
            </div>

            <div className="row">
              <div className="col-10">
                <TPPageSection>
                  <div className="row">
                    <div className="col-6">
                      <TPSelect
                        id="IdSelect"
                        isMandatory={true}
                        labelText={emailAccountLabel}
                        onChange={(e: any) =>
                          handleInputTextChange("idAccount", e.target.value)
                        }
                        dataSource={emailAccountOptions}
                        value={screenState.idAccount}
                        errorMessage={screenState.idAccountErrorMessage}
                      ></TPSelect>
                    </div>
                    <div className="col-6">
                      <TPSelect
                        id="IdSelect"
                        // isMandatory={true}
                        labelText={attachCaseInformationLabel}
                        onChange={(e: any) =>
                          handleInputTextChange(
                            "attachCaseInformation",
                            e.target.value,
                          )
                        }
                        dataSource={attachmentOptions}
                        value={screenState.attachCaseInformation}
                        errorMessage={
                          screenState.attachCaseInformationErrorMessage
                        }
                      ></TPSelect>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <TPTextBox
                          id="IdTextBox"
                          isMandatory={false}
                          onChange={(e: any) =>
                            handleInputTextChange("to", e.target.value)
                          }
                          value={screenState.to}
                          labelText={toLabel}
                          errorMessage={screenState.toErrorMessage}
                          maxLength={3000}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        {/* Body */}
                        {/* <TPLanguage
                        resourceId={"BodyLabel"}
                        resourceSet={resourceSet}
                      ></TPLanguage> */}

												<TPTextBox
													id="IdTextBox"
													isMandatory={false}
													onChange={(e: any) => handleInputTextChange('cc', e.target.value)}
													value={screenState.cc}
													labelText={ccLabel}
													errorMessage={screenState.ccErrorMessage}
													maxLength={3000}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<div className="form-group">
												<TPTextBox
													id="IdTextBox"
													isMandatory={false}
													onChange={(e: any) => handleInputTextChange('bcc', e.target.value)}
													value={screenState.bcc}
													labelText={bccLabel}
													errorMessage={screenState.bccErrorMessage}
													maxLength={3000}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-6 mt-3">
											<div className="form-group">
												<TPCheckBox
													id="IdCheckBox"
													labelText={isAddableLabel}
													checked={screenState.isAddable}
													onChange={(e: any) => handleIsAddableChange()}
												></TPCheckBox>
											</div>
										</div>
										<div className="col-6 mt-3">
											<div className="form-group">
												<TPCheckBox
													id="IdCheckBox"
													labelText={isActiveLabel}
													checked={screenState.isActive}
													onChange={(e: any) => handleIsActiveChange()}
												></TPCheckBox>
											</div>
										</div>
									</div>
									<div
										className="detail-template"
										style={{
											boxShadow: 'none',
											border: '1px solid #CCCCCC',
											borderRadius: 0,
											padding: '30px',
											marginTop: '16px'
										}}
									>
										<div className="detailTemplateTitle" style={{ fontSize: '24px', fontWeight: 500 }}>
											Detail Template
										</div>
										<div className="row">
											<div className="col-6 mt-3">
												<TPSelect
                          disabled={ mode === "Update" }
													id="IdSelect"
													onChange={(e: any) => handleInputTextChange('languageId', e.target.value)}
													dataSource={languageOptions}
													isMandatory={true}
													value={screenState.languageId}
													labelText={languageLabel}
													errorMessage={screenState.languageIdErrorMessage}
												></TPSelect>
											</div>
										</div>
										<div className="row">
											<div className="col-6 mt-3">
												<div className="form-group">
													<TPTextBox
														id="IdTextBox"
														// textStyle={{
														//   border: "none",
														//   borderRadius: "0.375rem 0rem 0rem 0.375rem",
														// }}
														isTag={true}
														tagHandler={handleOpenSubjectModal}
														inputRef={editorSubjectRef}
														isMandatory={true}
														onChange={(e: any) => handleInputTextChange('subject', e.target.value)}
														value={screenState.subject as string}
														labelText={subjectLabel}
														errorMessage={screenState.subjectErrorMessage}
														maxLength={500}
													/>
												</div>
											</div>
										</div>
										<div className="row ">
											<div className="col mt-3">
												<div className="form-group">
													{/* Body */}
													<TPLabel labelText={bodyLabel} isMandatory={true} />
													<TPEditor
														referece={(editor: any) => (editorRef.current = editor)}
														placeholder=""
														value={screenState.body}
														popUpOpen={handleOpenTagPopup}
														onChange={(value: any) => handleInputTextChange('body', value)}
														initialValue=""
														isVisible={true}
													/>
													<span style={{ color: '#dc3545', fontSize: '14px' }}>{screenState.bodyErrorMessage}</span>
												</div>
											</div>
										</div>
									</div>
								</TPPageSection>
								<TPModal
									modalState={{
										titleModal: `${'Available tags for selection'}`,
										acceptLabel: okButtonLabel,
										hideFooterButtons: true,
										cancelLabel: cancelButtonLabel,
										callBackAnswer: handleCallbackAnser,
										callBackData: TPGlobal.foo,
										isShown: isOpenTagModal,
										modalWidth: undefined
									}}
								>
									<div style={{ height: '300px', overflow: 'auto' }}>
										<TPSelect
											id="IdSelect"
											isMandatory={true}
											labelText={'Tag Group'}
											onChange={(e: any) => handleInputTextChange('tagGroup', e.target.value)}
											dataSource={tagGroups}
											value={screenState.tagGroup}
											errorMessage={screenState.tagGropIdErrorMessage}
										/>
										<TPSelect
											id="IdSelect"
											isMandatory={true}
											labelText={'Tag'}
											onChange={(e: any) => handleInputTextChange('tag', e.target.value)}
											dataSource={tags}
											value={screenState.tag}
											errorMessage={screenState.tagsErrorMessage}
										/>
										{screenState.tagGroup === 'S_TGCASE' && screenState.tag === 'AdditionalData' ? (
											<TPAutoComplete
												isMandatory={false}
												labelText={'Select additional data'}
												onValueChange={handleToChange}
												onSearch={(query: string) => {
													handleToOnAutocompleteQuery(query);
												}}
												isLoading={false}
												options={autocompleteToOptions}
												withIcon={true}
												emptyLabel={'emptyLabel'}
												onKeyDown={handleToOnAutocompleteKeyDown}
												selected={additionalDataAutoCompleteState.additionalDataAutoComplete}
												errorMessage={additionalDataAutoCompleteState.additionalDataAutoCompleteErrorMessage}
												downArrowClick={handleAutoCompleteTopNClick}
											/>
										) : (
											''
										)}

                    <div style={{ textAlign: "right", marginTop: "18px" }}>
                      <TPButton
                        type={TPButtonTypes.primary}
                        onClick={
                          isOpenTagModal && isShownModalSubjectSelect
                            ? handleAddTagForSubject
                            : handleAddTagForBody
                        }
                      >
                        {"Select"}
                      </TPButton>
                    </div>
                  </div>
                </TPModal>
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-10">
                <TPPageAcceptCancelButtonsContainer>
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.primary}
                    onClick={handleOnOkButtonClick}
                  >
                    {saveButtonLabel}
                  </TPButton>
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.link}
                    onClick={() => handleCancelButtonClick()}
                    className={"ms-2"}
                  >
                    {cancelButtonLabel}
                  </TPButton>
                </TPPageAcceptCancelButtonsContainer>
              </div>
            </div>
          </div>
        ) : (
          //while clicking on preview button
          <div className="container col-8" style={{ marginLeft: "0" }}>
            <p>The current value is: {caseNumber}</p>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <TPTextBox
                    id="IdTextBox"
                    isMandatory={false}
                    onChange={handleCase}
                    value={caseNumber}
                    labelText={"Case Number"}
                    errorMessage={""}
                    maxLength={30}
                  />
                </div>
              </div>
              <div className="col-4 mt-4">
                <div className="form-group">
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.primary}
                    onClick={handlePreviewAPI}
                  >
                    Preview
                  </TPButton>
                </div>
              </div>
            </div>

						<div className="col">
							<div className="form-group">
								<TPTextBox
									id="IdTextBox"
									isMandatory={false}
									onChange={() => {}}
									// disabled
									value={storeResponse?.responseData?.renderedTo}
									labelText={'To'}
									errorMessage={screenState.toErrorMessage}
									maxLength={3000}
								/>
							</div>
						</div>
						<div className="col">
							<div className="form-group">
								<TPTextBox
									id="IdTextBox"
									isMandatory={false}
									// disabled
									onChange={() => {}}
									value={storeResponse?.responseData?.renderedCc}
									labelText={'Cc'}
									errorMessage={screenState.toErrorMessage}
									maxLength={3000}
								/>
							</div>
						</div>
						<div className="col">
							<div className="form-group">
								<TPTextBox
									id="IdTextBox"
									isMandatory={false}
									// disabled
									onChange={() => {}}
									value={storeResponse?.responseData?.renderedBcc}
									labelText={'Bcc'}
									errorMessage={screenState.toErrorMessage}
									maxLength={3000}
								/>
							</div>
						</div>
						<div className="row">
							<div className="form-group">
								<TPTextBox
									id="IdTextBox"
									isMandatory={false}
									// disabled
									onChange={() => {}}
									value={storeResponse?.responseData?.renderedSubject}
									labelText={'Subject'}
									errorMessage={screenState.toErrorMessage}
									maxLength={3000}
								/>
							</div>
						</div>
						<div>
							<div className="form-group">
								<TPLabel labelText={bodyLabel} isMandatory={true} />
								<TPEditor
									value={storeResponse?.responseData?.renderedBody}
								/>
								<span style={{ color: '#dc3545', fontSize: '14px' }}>{screenState.bodyErrorMessage}</span>
							</div>
						</div>
					</div>
				)}
			</TPLoadingOverlay>
		</ContentVerticalTabInsertUpdateStyled>
	);
};

export default EmailTemplatesInsertUpdate;
