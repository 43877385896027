import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { GroupsViewModel } from "@/models/Groups/GroupsModels";
import { GroupsInputDTO } from "@/models/Groups/GroupsInputDTO";
import GroupCloneFormModel from "@/pages/Groups/GroupCloneForm/models/GroupCloneFormModel";
import {ProjectType} from "@/models/Project/Projects";

export class GroupsService {
  serviceFileName: string = "GroupsService.ts";
  public async getGroupsByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<GroupsViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getGroupsByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getGroupsByFilter`);
      throw new Error(`Error ${this.serviceFileName} getGroupsByFilter`);
    }
  }

  public async getGroupsByUserId(
    userId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user-group/user/";
    url = url + userId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getGroupsByUserId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getGroupsByUserId`);
      throw new Error(`Error ${this.serviceFileName} getGroupsByUserId`);
    }
  }

  public async reassignGroups(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user-group/reassign-user";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reassignGroups ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} reassignGroups`);
      throw new Error(`Error ${this.serviceFileName} reassignGroups`);
    }
  }

  public async getGroupById(
    groupId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<GroupsViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups/";
    url = url + groupId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getGroupById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getGroupById`);
      throw new Error(`Error ${this.serviceFileName} getGroupById`);
    }
  }

  public async deleteGroupById(
    groupId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups/" + groupId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteGroupById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteGroupById`);
      throw new Error(`Error ${this.serviceFileName} deleteGroupById`);
    }
  }

  public async insertGroup(
    inputDTO: GroupsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertGroup`);
      throw new Error(`Error ${this.serviceFileName} insertGroup`);
    }
  }

  public async updateGroup(
    inputDTO: GroupsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateGroup`);
      throw new Error(`Error ${this.serviceFileName} updateGroup`);
    }
  }

  public async clone(
    group: GroupCloneFormModel,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = false,
    expectedCodes: Array<number> = [200],
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/groups/clone`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        url,
        group,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateGroup`);
      throw new Error(`Error ${this.serviceFileName} updateGroup`);
    }
  }

  public async findByType(type: ProjectType): Promise<Array<any>> {
    const {getData} = new TPHTTPService();
    try {
      const response = await getData(
          `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/groups/event-take-next-type/${type}`,
          [200, 404],
          false,
          false,
          [...TPGlobal.standardHeaders]
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
      console.error(`Error ${this.serviceFileName} find`);
      throw new Error(`Error ${this.serviceFileName} find`);
    }
  }

}
