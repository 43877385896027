import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageSubTitle,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TPModal from "@/layouts/TPModal/TPModal";

import {
  ModalSizeEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import {
  WorkflowDetailViewModel,
  WorkflowTypeViewModel,
} from "@/models/Workflow/WorkflowTypeModels";
import { TPI18N } from "@/services/I18nService";
import { WorkflowDetailPredecessorService } from "@/services/WorkflowDetailPredecessorService";
import { WorkflowDetailService } from "@/services/WorkflowDetailService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import WorkflowDetailInsertUpdate from "./WorkflowDetailInsertUpdate";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<WorkflowDetailViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

export type DiagramFullScreenModalType = {
  classModalDiagram: string;
  styleModalDiagram: any;
  backdropClass: string;
  title: string;
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

export type DetermineResponsibleStructure = {
  idWorkflowDetail: number | null;
  determinesResponsible: boolean;
  idWorkflowDetailCloseCustomer: number | null;
  closeCustomer: boolean;
};

interface WorkflowDetailAdminInterface {
  workflowTypeId: string;
  callBackCommands: Function;
  recordDescription: string;
}
const WorkflowDetailAdmin = React.forwardRef(
  (
    {
      workflowTypeId,
      callBackCommands,
      recordDescription,
    }: WorkflowDetailAdminInterface,
    ref,
  ) => {
    const componentFileName: string = "WorkflowTypeAdmin.tsx";
    //Functions called form parent WorkflowTypeAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));
    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "WorkflowDetailAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [subTitleLabel, setSubTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [addTaskToWorkflowButtonText, setAddTaskToWorkflowButtonText] =
      useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");

    const [updateLabel, setUpdateLabel] = useState("");
    const [workflowDiagramLabel, setWorkflowDiagramLabel] = useState("");
    const [noEditableLabel, setNoEditableLabel] = useState("");

    //grid columns
    const [orderColumnLabel, setOrderColumnLabel] = useState("");
    const [taskDescriptionColumnLabel, setTaskDescriptionColumnLabel] =
      useState("");
    const [writerColumnLabel, setWriterColumnLabel] = useState("");
    const [readerColumnLabel, setReaderColumnLabel] = useState("");
    const [customerSLAColumnLabel, setCustomerSLAColumnLabel] = useState("");
    const [definesResponsibleColumnLabel, setDefinesResponsibleColumnLabel] =
      useState("");
    const [closeCustomerColumnLabel, setCloseCustomerColumnLabel] =
      useState("");
    const [predecessorsColumnLabel, setPredecessorsColumnLabel] = useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [workflowTypeValueState, setWorkflowTypeValueState] =
      useState<WorkflowTypeViewModel | null>(null);

    //Detail
    const [titleDetail, setTitleDetail] = useState("");
    const [showDetail, setShowDetail] = useState(false);
    const [detailMode, setDetailMode] = useState("Insert");
    const [idWorkflowDetail, setIdWorkflowDetail] = useState("-1");

    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    const initialDiagramModal: DiagramFullScreenModalType = {
      classModalDiagram: "modal",
      styleModalDiagram: { display: "none" },
      backdropClass: "",
      title: "",
    };

    const [diagramModal, setDiagramModal] =
      useState<DiagramFullScreenModalType>(initialDiagramModal);

    const [currentWorkflowImage, setCurrentWorkflowImage] = useState("");

    let initialDetermineResponsibleStructure: DetermineResponsibleStructure = {
      idWorkflowDetail: null,
      determinesResponsible: false,
      idWorkflowDetailCloseCustomer: null,
      closeCustomer: false,
    };
    const [determineResponsibleStructure, setDetermineResponsibleStructure] =
      useState<DetermineResponsibleStructure>(
        initialDetermineResponsibleStructure,
      );

    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndOrganizationsRelationsFilter = async () => {
      await getWorkflowTypeById();
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );

      //screen
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setNoEditableLabel(await TPI18N.GetText(resourceSet, "NoEditableLabel"));
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubTitleLabel"));
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));
      setAddTaskToWorkflowButtonText(
        await TPI18N.GetText(resourceSet, "AddTaskToWorkflowButton"),
      );

      //grid columns
      setTaskDescriptionColumnLabel(
        await TPI18N.GetText(resourceSet, "TaskType"),
      );
      setOrderColumnLabel(await TPI18N.GetText(resourceSet, "Order"));
      setWriterColumnLabel(await TPI18N.GetText(resourceSet, "Writer"));
      setReaderColumnLabel(await TPI18N.GetText(resourceSet, "Reader"));
      setCustomerSLAColumnLabel(
        await TPI18N.GetText(resourceSet, "CustomerSLA"),
      );
      setCloseCustomerColumnLabel(
        await TPI18N.GetText(resourceSet, "CloseCustomerColumnLabel"),
      );
      setDefinesResponsibleColumnLabel(
        await TPI18N.GetText(resourceSet, "DefinesResponsible"),
      );
      setPredecessorsColumnLabel(
        await TPI18N.GetText(resourceSet, "PredecessorsColumnLabel"),
      );

      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );

      setWorkflowDiagramLabel(
        await TPI18N.GetText(resourceSet, "WorkflowDiagramLabel"),
      );
      setIsActiveColumnLabel(await TPI18N.GetText(resourceSet, "IsActive"));

      let newFilterKeyValue: Array<TPKeyValue> = [];

      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        //delete
        if (workflowTypeValueState?.isCasesUsed !== true) {
          newColumns.push({
            name: "",
            width: "50px",
            style: { padding: 0 },
            center: true,
            cell: (row: { [x: string]: any }) => {
              return (
                <div className="dropdown">
                  <TPButton
                    id="IdButton"
                    dataBsToggle={true}
                    type={TPButtonTypes.empty}
                    onClick={() => {
                      TPGlobal.foo();
                    }}
                    className={"menu-button"}
                  >
                    <TPIcon iconType={TPIconTypes.moreVert} />
                  </TPButton>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        onClick={(id: any) =>
                          handleDeleteClick(row["id"], row["order"])
                        }
                        className="dropdown-item"
                        href="#"
                      >
                        {deleteLabel}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={(id: any) => handleUpdateClick(row["id"])}
                        className="dropdown-item"
                        href="#"
                      >
                        {updateLabel}
                      </a>
                    </li>
                  </ul>
                </div>
              );
            },
          });
        }
        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) => {
            return (
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={(id: string) => handleUpdateClick(row["id"])}
                className="update-button"
              >
                <TPIcon iconType={TPIconTypes.chevronRight} />
              </TPButton>
            );
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });
        //Order
        newColumns.push({
          name: orderColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["order"];
          },
          selector: (row: { [x: string]: any }) => row["order"],
          sortable: true,
          width: "100px",
        });
        //Description
        newColumns.push({
          name: taskDescriptionColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["taskDescription"];
          },
          sortable: true,
          width: "200px",
        });
        //Writer
        newColumns.push({
          name: writerColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["writerDescription"];
          },
          width: "200px",
          sortable: true,
        });

        //prededecessors
        newColumns.push({
          name: predecessorsColumnLabel,
          width: "200px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            if (!row["predecessors"]) return <></>;

            return (
              <small>
                {row["predecessors"]
                  .split("<br>")
                  .map((item: string, idx: number) => {
                    return <div key={idx}>{item}</div>;
                  })}
              </small>
            );
          },
        });

        //customer SLA
        newColumns.push({
          name: customerSLAColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["term"] + row["termUnit"];
          },
          width: "150px",
          sortable: true,
        });

        //Reader
        newColumns.push({
          name: readerColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["readerDescription"];
          },
          width: "150px",
          sortable: true,
        });

        //closesCustomer
        newColumns.push({
          name: closeCustomerColumnLabel,
          selector: (row: { [x: string]: any }) => row["closesCustomer"],
          width: "230px",
          center: true,
          // conditionalCellStyles: [
          //   {
          //     when: (cell: { [x: string]: any }) => cell["closesCustomer"],
          //     style: { backgroundColor: "Orange" },
          //   },
          // ],

          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["closesCustomer"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });
        // isActive
        newColumns.push({
          name: isActiveColumnLabel,
          selector: (row: { [x: string]: any }) => row["isActive"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isActive"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });
        //DefinesResponsible
        newColumns.push({
          name: definesResponsibleColumnLabel,
          selector: (row: { [x: string]: any }) => row["definesResponsible"],
          width: "230px",
          center: true,
          // conditionalCellStyles: [
          //   {
          //     when: (cell: { [x: string]: any }) => cell["definesResponsible"],
          //     style: { backgroundColor: "Orange" },
          //   },
          // ],
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["definesResponsible"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    const getWorkflowDetailPredecessors = async (pIdWorkflowDetail: string) => {
      let serviceClient = new WorkflowDetailPredecessorService();
      let expectedCodes: Array<number> = [200, 404];
      let i: number;
      let predecessors: string = "";

      try {
        let responseRequest =
          await serviceClient.getPredecessorByWorkflowDetail(
            pIdWorkflowDetail,
            false,
            true,
            expectedCodes,
          );

        if (responseRequest) {
          const startResource: string = await TPI18N.GetText(
            resourceSet,
            "StartText",
          );
          const lagResource: string = await TPI18N.GetText(
            resourceSet,
            "LagText",
          );
          const dueDateResource: string = await TPI18N.GetText(
            resourceSet,
            "DueDateText",
          );
          const yesResource: string = await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "IsActiveYes",
          );
          const noResource: string = await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "IsActiveNo",
          );
          const minutesResource: string = await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "MinutesItemLabel",
          );
          const hoursResource: string = await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "HoursItemLabel",
          );
          const daysResource: string = await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "DaysItemLabel",
          );
          const weeksResource: string = await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "WeeksItemLabel",
          );
          const monthsResource: string = await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "IsActiveNo",
          );

          for (i = 0; i <= responseRequest.length - 1; i++) {
            if (predecessors !== "") {
              predecessors += " , ";
            }

            if (
              responseRequest[i].predecessorId ===
              TPGlobal.initialWorkflowDetail
            ) {
              predecessors += startResource;
            } else {
              predecessors += responseRequest[i].order.toString();
            }

            if (responseRequest[i].conditionalPath.trim() !== "") {
              switch (responseRequest[i].conditionalPath.trim().toUpperCase()) {
                case "Y": {
                  predecessors += " /" + yesResource;
                  break;
                }
                case "N": {
                  predecessors += " /" + noResource;
                  break;
                }
                default: {
                  predecessors += " /" + responseRequest[i].conditionalPath;
                  break;
                }
              }
            }

            if (responseRequest[i].dateField === "") {
              if ((responseRequest[i]?.lag ?? 0) > 0) {
                predecessors +=
                  " - " + lagResource + " " + responseRequest[i]?.lag + " ";

                switch (responseRequest[i]?.lagUnit.trim().toUpperCase()) {
                  case "MI": {
                    predecessors += minutesResource;
                    break;
                  }
                  case "H": {
                    predecessors += hoursResource;
                    break;
                  }
                  case "D": {
                    predecessors += daysResource;
                    break;
                  }
                  case "W": {
                    predecessors += weeksResource;
                    break;
                  }
                  case "M": {
                    predecessors += monthsResource;
                    break;
                  }
                  default: {
                    predecessors += responseRequest[i]?.lagUnit;
                    break;
                  }
                }
              }
            } else {
              predecessors +=
                " " + dueDateResource + " " + responseRequest[i].dateField;
            }
          }
        }
        return predecessors;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getWorkflowDetailPredecessors ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getWorkflowDetailPredecessors ex`,
        );
        return predecessors;
      }
    };

    //Get workflow details
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new WorkflowDetailService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getByWorkflowType(
          workflowTypeId,
          false,
          true,
          expectedCodes,
        );

        if (
          responseRequest &&
          responseRequest.length &&
          responseRequest.length > 1
        ) {
          for (let i: number = 0; i <= responseRequest.length - 1; i++) {
            let predecessorsvalue: string = await getWorkflowDetailPredecessors(
              responseRequest[i].id.toString(),
            );
            if (predecessorsvalue) {
              if (predecessorsvalue.indexOf(",") !== -1) {
                if (responseRequest[i].requiresAllPredecessors) {
                  predecessorsvalue = predecessorsvalue.replaceAll(
                    " , ",
                    " " +
                      (await TPI18N.GetText(
                        TPGlobal.globalResourceSet,
                        "AndOperatorText",
                      )) +
                      " <br>",
                  );
                  predecessorsvalue =
                    predecessorsvalue +
                    " <br>(" +
                    (await TPI18N.GetText(resourceSet, "WhenAllLabel")) +
                    ")";
                } else {
                  predecessorsvalue = predecessorsvalue.replaceAll(
                    " , ",
                    " " +
                      (await TPI18N.GetText(
                        TPGlobal.globalResourceSet,
                        "OrOperatorText",
                      )) +
                      " <br>",
                  );
                  predecessorsvalue =
                    predecessorsvalue +
                    " <br>(" +
                    (await TPI18N.GetText(resourceSet, "TheFirstLabel")) +
                    ")";
                }
              }
              responseRequest[i].predecessors = predecessorsvalue;
            } else {
              responseRequest[i].predecessors = "";
            }
          }
        }

        let idWorkflowDetailResponsible: number | null = null;
        let idWorkflowDetailCloseCustomer: number | null = null;
        //find workflowTypeId that detemrines responsible and close customer
        let foundDeterminesResponsible: boolean = false;
        let foundCloseCustomer: boolean = false;
        responseRequest.map((item, index, array) => {
          if (item.definesResponsible) {
            idWorkflowDetailResponsible = item.id;
            foundDeterminesResponsible = true;
          }
          if (item.closesCustomer) {
            idWorkflowDetailCloseCustomer = item.id;
            foundCloseCustomer = true;
          }
          if (foundDeterminesResponsible && foundCloseCustomer) return;
        });

        setDetermineResponsibleStructure({
          determinesResponsible: foundDeterminesResponsible,
          idWorkflowDetail: idWorkflowDetailResponsible,
          closeCustomer: foundCloseCustomer,
          idWorkflowDetailCloseCustomer: idWorkflowDetailCloseCustomer,
        });

        // Filter based on selectedFilter
        let filteredResponse = responseRequest.filter((item) => {
          if (selectedFilter === TPActiveOptions.ALL.toString()) {
            return true;
          }
          if (selectedFilter === TPActiveOptions.ACTIVE.toString()) {
            return item.isActive === true;
          }
          if (selectedFilter === TPActiveOptions.INACTIVE.toString()) {
            return item.isActive === false;
          }
        });

        setIsLoadingScreen(false);
        return [...filteredResponse];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New workflowDetail
    const handleNewClick = async () => {
      let command: any = { command: "new" };
      callBackCommands(command);
      setIdWorkflowDetail("-1");
      setDetailMode("Insert");
      setTitleDetail(
        await TPI18N.GetText(
          "WorkflowDetailInsertUpdateComponent",
          "TitleInsertLabel",
        ),
      );
      setShowDetail(true);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update organization relation
    const handleUpdateClick = async (id: string) => {
      // let command: any = { command: "update", recordId: id };
      // callBackCommands(command);
      //workflowTypeId
      setIdWorkflowDetail(id);
      setTitleDetail(
        await TPI18N.GetText(
          "WorkflowDetailInsertUpdateComponent",
          "TitleUpdateLabel",
        ),
      );
      setDetailMode("Update");

      setShowDetail(true);
    };

    //Modal Question to delete organization relation
    const handleDeleteClick = (id: string, order: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id, order: order };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete organization relation after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new WorkflowDetailService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.delete(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          reloadGridCommand();
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "order",
        "taskDescription",
        "writerDescription",
        "readerDescription",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item: any, index: number) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    //get workflow diagram
    const getWorkflowDiagram = async () => {
      let serviceClient = new WorkflowTypeService();
      let expectedCodes: Array<number> = [200];

      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getDiagramByWorkflowType(
          workflowTypeId,
          "TB", //top bottom
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        return responseRequest;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getWorkflowDiagram ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getWorkflowDiagram ex`);
        setIsLoadingScreen(false);
        return null;
      }
    };

    //get workflow type by id
    const getWorkflowTypeById = async () => {
      let serviceClient = new WorkflowTypeService();
      let expectedCodes: Array<number> = [200];

      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getById(
          workflowTypeId,
          false,
          true,
          expectedCodes,
        );

        let workflowtype: WorkflowTypeViewModel;
        workflowtype = { ...responseRequest };

        setWorkflowTypeValueState(workflowtype);

        setIsLoadingScreen(false);
        return responseRequest;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getWorkflowTypeById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getWorkflowTypeById ex`);
        setIsLoadingScreen(false);
        return null;
      }
    };

    //todo waiting to be used until
    //backend create another endpoint to include only workflowTypeId
    const handleViewDiagram = async () => {
      let imageData: any;
      imageData = await getWorkflowDiagram();
      if (imageData) {
        setCurrentWorkflowImage(imageData.value);
        let newState: DiagramFullScreenModalType = {
          classModalDiagram: "modal show",
          styleModalDiagram: { display: "block" },
          backdropClass: "modal-backdrop show",
          title: workflowDiagramLabel,
        };
        setDiagramModal(newState);
      }
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };
    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }
    const handleOnSaveDetailClick = () => {
      setShowDetail(false);
      reloadGridCommand();
    };

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndOrganizationsRelationsFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };
    const handleCallbackAnswerDetailModal = (
      status: boolean,
      callbackData: any,
    ) => {
      setShowDetail(false);
    };
    return (
      <>
        {showDetail && (
          <>
            <TPModal
              modalState={{
                titleModal: titleDetail,
                acceptLabel: "",
                cancelLabel: "",
                callBackAnswer: handleCallbackAnswerDetailModal,
                callBackData: null,
                isShown: showDetail,
                modalWidth: ModalSizeEnum.MODALXL,
                hideFooterButtons: true,
              }}
            >
              <WorkflowDetailInsertUpdate
                onCancelClick={() => {
                  setShowDetail(false);
                }}
                onSaveClick={handleOnSaveDetailClick}
                mode={detailMode}
                idWorkflowType={workflowTypeId}
                idWorkflowDetail={idWorkflowDetail}
                responsibleStructure={determineResponsibleStructure}
              ></WorkflowDetailInsertUpdate>
            </TPModal>
          </>
        )}

        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.order,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <TPPageSubTitle>{`${subTitleLabel} ${workflowTypeId} / ${recordDescription}`}</TPPageSubTitle>
              <hr />
              <TPPageFirstRow>
                <TPPageActions>
                  {!workflowTypeValueState?.isCasesUsed && (
                    <TPButton
                      id="IdButton"
                      type={TPButtonTypes.icon}
                      onClick={() => handleNewClick()}
                      text={addTaskToWorkflowButtonText}
                      icon={TPIconTypes.newEntity}
                    />
                  )}
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() => handleViewDiagram()}
                    text={workflowDiagramLabel}
                    icon={TPIconTypes.diagram}
                  />
                </TPPageActions>
                <TPFilterAndSearch>
                  <TPPageFilterContainer>
                    <TPSelect
                      id="IdSelect"
                      onChange={handleFilterChange}
                      dataSource={filterKeyValue}
                      value={adminState.selectedFilter}
                      labelText={filterIsActiveLabel}
                      isHorizontal={true}
                    ></TPSelect>
                  </TPPageFilterContainer>
                  <TPPageSearchContainer>
                    <TPTextBox
                      id="IdTextBox"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>
          {workflowTypeValueState?.isCasesUsed && (
            <div className="row">
              <div className="col">
                <div className="alert alert-danger" role="alert">
                  {noEditableLabel}
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              {/* TableContainer,DataTableContainer,TableHeaderContainer,TableSearchContainer,TableToolbar */}

              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    fixedHeader={true}
                    persistTableHead={true}
                    /*fixedHeaderScrollHeight={alturaGrid.toString() + "px"}*/
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    responsive={true}
                    dense={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </TPLoadingOverlay>
        <div
          className={diagramModal.classModalDiagram}
          tabIndex={-1}
          data-bs-backdrop={diagramModal.backdropClass}
          style={diagramModal.styleModalDiagram}
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header justify-content-between">
                <h5 className="modal-title">{diagramModal.title}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setDiagramModal(initialDiagramModal);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-4">
                    {currentWorkflowImage && (
                      <div className="tpw100per">
                        <img
                          src={"data:image/png;base64," + currentWorkflowImage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                TPClient Copyright &copy; {TPGlobal.currentYear}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default WorkflowDetailAdmin;
