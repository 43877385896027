import { TPPaginator } from "@/components/TPPaginator/TPPaginator";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { exportToCSV } from "@/helpers/ExportToCSV";
import { ContactViewModel } from "@/models/Contacts/ContactViewModel";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ContactsService } from "@/services/ContactsService";
import { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../../styles/Styles.css";
import useLabels from "../../styles/labels";

interface CustomerInfoContactsTab {
  customerId: number;
  subsidiaryId: number;
}

const CustomerInfoContactsTab: FC<CustomerInfoContactsTab> = function ({
  customerId,
  subsidiaryId,
}) {
  const { labels, labelsLoaded } = useLabels();
  const [loading, setLoading] = useState<boolean>(false);

  const [customerContacts, setCustomerContacts] = useState<ContactViewModel[]>(
    [],
  );
  const [query, setQuery] = useState<string>("");
  const [filteredContacts, setFilteredContacts] = useState<ContactViewModel[]>(
    [],
  );
  const loadContacts = function () {
    setLoading(true);
    const contactServiceInstance = new ContactsService();
    contactServiceInstance
      .getCustomerContacts(customerId, subsidiaryId, false, false, [200, 404])
      .then((response) => {
        console.log(response);
        if (response) setCustomerContacts(response);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    if (customerId && subsidiaryId) loadContacts();
  }, [customerId, subsidiaryId]);
  useEffect(() => {
    if (customerContacts && customerContacts.length > 0)
      setFilteredContacts(customerContacts.map((contact) => contact));
  }, [customerContacts]);
  useEffect(() => {
    setFilteredContacts([
      ...customerContacts.filter((contact) => {
        return (
          contact.address.includes(query) ||
          String(contact.contactId).includes(query) ||
          String(contact.phone).includes(query) ||
          String(contact.email).includes(query) ||
          String(contact.comments).includes(query)
        );
      }),
    ]);
  }, [query]);
  return (
    <>
      <TPLoadingOverlay active={loading}>
        <div id="merge-customers-contacts-data-table">
          <div className="customer-search-controls">
            <div>
              <TPTextBox
                onChange={(event: any) => setQuery(event.target.value)}
                value={query}
                withIcon
                icon={TPIconTypes.search}
                placeholder={labels.Search}
                containerStyle={{ width: "256px" }}
              />
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <button
                id="merge-customers-refresh-button"
                type="button"
                style={{ border: "none", backgroundColor: "white" }}
                onClick={() => loadContacts()}
              >
                <TPIcon iconType={TPIconTypes.refresh} />
              </button>
              <button
                id="merge-customers-export-csv"
                type="button"
                style={{ border: "none", backgroundColor: "white" }}
                onClick={() => {
                  if (customerContacts.length > 0)
                    exportToCSV(customerContacts, "CustomerContacts.csv");
                }}
                disabled={customerContacts.length == 0}
              >
                <TPIcon iconType={TPIconTypes.excel} />
              </button>
            </div>
          </div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="256px"
            columns={[
              {
                name: <b>{labels.ContactId}</b>,
                selector: (row: ContactViewModel) => row.contactId,
                sortable: true,
              },
              {
                name: <b>{labels.preferredPhone}</b>,
                selector: (row: ContactViewModel) => row.phone,
                sortable: true,
              },
              {
                name: <b>{labels.preferredEmail}</b>,
                selector: (row: ContactViewModel) => row.email,
                sortable: true,
              },
              {
                name: <b>{labels.preferredAddress}</b>,
                selector: (row: ContactViewModel) => row.address,
                sortable: true,
              },
              {
                name: <b>{labels.Comments}</b>,
                selector: (row: ContactViewModel) => row.comments,
                sortable: true,
              },
            ]}
            data={filteredContacts}
            pagination
            paginationComponent={TPPaginator}
            noDataComponent={<label>{labels.NoResults}</label>}
          />
        </div>
      </TPLoadingOverlay>
    </>
  );
};

export default CustomerInfoContactsTab;
